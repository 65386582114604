import { FunctionComponent, ReactNode } from 'react'
import { Button, DialogTrigger, Popover, PopoverProps } from 'react-aria-components'
import { useMediaQuery } from '../misc'
import { Backdrop } from './Backdrop'
import styles from './DropdownWrapper.module.sass'
import { Modal, ModalProps } from './Modal'

const desktopBreakpoint = JSON.parse(styles.desktopBreakpoint)

export type DropdownWrapperPropsWithChildren = {
	button: ReactNode
	children: ReactNode
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	desktopPlacement?: PopoverProps['placement']
	mobilePlacement?: ModalProps['mobileAlign']
}

export const DropdownWrapper: FunctionComponent<DropdownWrapperPropsWithChildren> = ({
	button,
	children,
	isOpen,
	setIsOpen,
	desktopPlacement = 'bottom right',
	mobilePlacement,
}) => {
	const isDesktop = useMediaQuery(desktopBreakpoint)

	return isDesktop ? (
		<DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
			<Button className={styles.button}>{button}</Button>
			<Popover placement={desktopPlacement}>
				<div className={styles.overlay}>
					<Backdrop />
				</div>
				<div className={styles.content}>{children}</div>
			</Popover>
		</DialogTrigger>
	) : (
		<>
			<button type="button" onClick={() => setIsOpen(!isOpen)} className={styles.button}>
				{button}
			</button>
			<Modal isOpen={isOpen} onRequestClose={() => setIsOpen(!isOpen)} mobileAlign={mobilePlacement}>
				<div className={styles.content}>{children}</div>
			</Modal>
		</>
	)
}
