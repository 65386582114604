"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatsWidgetTimeFrames = exports.StatsWidgetTypes = exports.StatsPageTypes = exports.DefaultTimeZone = exports.GoodlokTimetablesShiftUnassignReasons = exports.ProductAvailabilities = exports.GoodlokOrderStates = exports.GoodlokDeliveryTimelineTypes = exports.GoodlokDefaultRole = exports.GoodlokAnonymousSessionKeyVariableName = exports.GoodlokPersonIdVariableName = exports.GoodlokBackendEndpoints = exports.GoodlokChannels = exports.GoodlokRoles = exports.GoodlokUserRoles = exports.AnonymousRoles = exports.GoodlokBasicUserRoles = exports.GoodlokSystemRoles = void 0;
exports.GoodlokSystemRoles = ['public', 'website', 'mobile_app'];
// After adding new role update roles.md
exports.GoodlokBasicUserRoles = [
    'staff',
    'customer',
    'shifts_manager',
    'picker',
    'shifts_worker', // bartender, courier, cleaner, picker
    'cashier',
    'courier',
    'courier_manager',
    'cashier_manager',
    'stocks_manager',
    'internal_buyer',
    'sales_manager',
];
exports.AnonymousRoles = ['anonymous'];
exports.GoodlokUserRoles = ['admin', ...exports.GoodlokBasicUserRoles, ...exports.AnonymousRoles];
exports.GoodlokRoles = [...exports.GoodlokSystemRoles, ...exports.GoodlokUserRoles, ...exports.AnonymousRoles];
exports.GoodlokChannels = ['b2b', 'eshop', 'app', 'pos', 'web', 'all', 'legacy']; // @TODO remove cash_desk
exports.GoodlokBackendEndpoints = ['auth', 'admin', 'orders'];
exports.GoodlokPersonIdVariableName = 'personId';
exports.GoodlokAnonymousSessionKeyVariableName = 'anonymousSessionKey';
exports.GoodlokDefaultRole = 'customer';
exports.GoodlokDeliveryTimelineTypes = ['asap', 'day', 'halfAnHour'];
exports.GoodlokOrderStates = [
    'created',
    'waitingForPayment',
    'processing',
    'dispatched',
    'fulfilled',
    'canceled',
];
exports.ProductAvailabilities = ['stockDependent', 'available', 'unavailable'];
exports.GoodlokTimetablesShiftUnassignReasons = ['sickness', 'vacation', 'other'];
exports.DefaultTimeZone = 'Europe/Prague';
exports.StatsPageTypes = ['dashboard', 'sales', 'b2b', 'employees'];
exports.StatsWidgetTypes = [
    'revenue',
    'numberOfCustomers',
    'numberOfCustomersHourly',
    'averageCustomerSpending',
    'topProduct',
    'dailyGratuity',
    'salesByProductCategories',
    'productSales',
    'cashRegisterBalance',
    'revenueByHours',
    'revenueWeeklyComparison',
];
exports.StatsWidgetTimeFrames = ['day', 'week', 'month', 'year', 'allTime'];
__exportStar(require("./domains"), exports);
__exportStar(require("./groupBy"), exports);
