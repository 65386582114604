/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		getAddress:{
			by:"AddressUniqueWhere",
			filter:"AddressWhere"
		},
		listAddress:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		paginateAddress:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		validateCreateAddress:{
			data:"AddressCreateInput"
		},
		validateUpdateAddress:{
			by:"AddressUniqueWhere",
			data:"AddressUpdateInput"
		},
		getCountry:{
			by:"CountryUniqueWhere",
			filter:"CountryWhere"
		},
		listCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		paginateCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		getChannel:{
			by:"ChannelUniqueWhere",
			filter:"ChannelWhere"
		},
		listChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		getChannelPayment:{
			by:"ChannelPaymentUniqueWhere",
			filter:"ChannelPaymentWhere"
		},
		listChannelPayment:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		},
		paginateChannelPayment:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		},
		getChannelDelivery:{
			by:"ChannelDeliveryUniqueWhere",
			filter:"ChannelDeliveryWhere"
		},
		listChannelDelivery:{
			filter:"ChannelDeliveryWhere",
			orderBy:"ChannelDeliveryOrderBy"
		},
		paginateChannelDelivery:{
			filter:"ChannelDeliveryWhere",
			orderBy:"ChannelDeliveryOrderBy"
		},
		getCustomer:{
			by:"CustomerUniqueWhere",
			filter:"CustomerWhere"
		},
		listCustomer:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		paginateCustomer:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		validateUpdateCustomer:{
			by:"CustomerUniqueWhere",
			data:"CustomerUpdateInput"
		},
		getCustomerCredit:{
			by:"CustomerCreditUniqueWhere",
			filter:"CustomerCreditWhere"
		},
		listCustomerCredit:{
			filter:"CustomerCreditWhere",
			orderBy:"CustomerCreditOrderBy"
		},
		paginateCustomerCredit:{
			filter:"CustomerCreditWhere",
			orderBy:"CustomerCreditOrderBy"
		},
		getCustomerGroup:{
			by:"CustomerGroupUniqueWhere",
			filter:"CustomerGroupWhere"
		},
		listCustomerGroup:{
			filter:"CustomerGroupWhere",
			orderBy:"CustomerGroupOrderBy"
		},
		paginateCustomerGroup:{
			filter:"CustomerGroupWhere",
			orderBy:"CustomerGroupOrderBy"
		},
		getDeliveryMethod:{
			by:"DeliveryMethodUniqueWhere",
			filter:"DeliveryMethodWhere"
		},
		listDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		getOrder:{
			by:"OrderUniqueWhere",
			filter:"OrderWhere"
		},
		listOrder:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateOrder:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		validateCreateOrder:{
			data:"OrderCreateInput"
		},
		getOrderDiscount:{
			by:"OrderDiscountUniqueWhere",
			filter:"OrderDiscountWhere"
		},
		listOrderDiscount:{
			filter:"OrderDiscountWhere",
			orderBy:"OrderDiscountOrderBy"
		},
		paginateOrderDiscount:{
			filter:"OrderDiscountWhere",
			orderBy:"OrderDiscountOrderBy"
		},
		getOrderPayment:{
			by:"OrderPaymentUniqueWhere",
			filter:"OrderPaymentWhere"
		},
		listOrderPayment:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		paginateOrderPayment:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		getOrderDelivery:{
			by:"OrderDeliveryUniqueWhere",
			filter:"OrderDeliveryWhere"
		},
		listOrderDelivery:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		},
		paginateOrderDelivery:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		},
		validateCreateOrderDelivery:{
			data:"OrderDeliveryCreateInput"
		},
		getOrderItem:{
			by:"OrderItemUniqueWhere",
			filter:"OrderItemWhere"
		},
		listOrderItem:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		paginateOrderItem:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		validateCreateOrderItem:{
			data:"OrderItemCreateInput"
		},
		getPaymentMethod:{
			by:"PaymentMethodUniqueWhere",
			filter:"PaymentMethodWhere"
		},
		listPaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginatePaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		getVatRate:{
			by:"VatRateUniqueWhere",
			filter:"VatRateWhere"
		},
		listVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		paginateVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		getProduct:{
			by:"ProductUniqueWhere",
			filter:"ProductWhere"
		},
		listProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		getProductStock:{
			by:"ProductStockUniqueWhere",
			filter:"ProductStockWhere"
		},
		listProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		paginateProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		getProductPacking:{
			by:"ProductPackingUniqueWhere",
			filter:"ProductPackingWhere"
		},
		listProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		paginateProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		getProductGroupPrice:{
			by:"ProductGroupPriceUniqueWhere",
			filter:"ProductGroupPriceWhere"
		},
		listProductGroupPrice:{
			filter:"ProductGroupPriceWhere",
			orderBy:"ProductGroupPriceOrderBy"
		},
		paginateProductGroupPrice:{
			filter:"ProductGroupPriceWhere",
			orderBy:"ProductGroupPriceOrderBy"
		},
		getProductRecipe:{
			by:"ProductRecipeUniqueWhere",
			filter:"ProductRecipeWhere"
		},
		listProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		paginateProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		getProductCategory:{
			by:"ProductCategoryUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		listProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		getStore:{
			by:"StoreUniqueWhere",
			filter:"StoreWhere"
		},
		listStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		paginateStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		getTag:{
			by:"TagUniqueWhere",
			filter:"TagWhere"
		},
		listTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		getCustomerPermissions:{
			by:"CustomerPermissionsUniqueWhere",
			filter:"CustomerPermissionsWhere"
		},
		listCustomerPermissions:{
			filter:"CustomerPermissionsWhere",
			orderBy:"CustomerPermissionsOrderBy"
		},
		paginateCustomerPermissions:{
			filter:"CustomerPermissionsWhere",
			orderBy:"CustomerPermissionsOrderBy"
		},
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getBusinessCategory:{
			by:"BusinessCategoryUniqueWhere",
			filter:"BusinessCategoryWhere"
		},
		listBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		paginateBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		getProductList:{
			by:"ProductListUniqueWhere",
			filter:"ProductListWhere"
		},
		listProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		paginateProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		getProductListItem:{
			by:"ProductListItemUniqueWhere",
			filter:"ProductListItemWhere"
		},
		listProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		paginateProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		getProductCategoryList:{
			by:"ProductCategoryListUniqueWhere",
			filter:"ProductCategoryListWhere"
		},
		listProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		paginateProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		getProductCategoryListItem:{
			by:"ProductCategoryListItemUniqueWhere",
			filter:"ProductCategoryListItemWhere"
		},
		listProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		paginateProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		getBusinessCategoryLocale:{
			by:"BusinessCategoryLocaleUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		listBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getProductCategoryListLocale:{
			by:"ProductCategoryListLocaleUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		listProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		paginateProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		getCustomerName:{
			by:"CustomerNameUniqueWhere",
			filter:"CustomerNameWhere"
		},
		listCustomerName:{
			filter:"CustomerNameWhere",
			orderBy:"CustomerNameOrderBy"
		},
		paginateCustomerName:{
			filter:"CustomerNameWhere",
			orderBy:"CustomerNameOrderBy"
		},
		validateCreateCustomerName:{
			data:"CustomerNameCreateInput"
		},
		validateUpdateCustomerName:{
			by:"CustomerNameUniqueWhere",
			data:"CustomerNameUpdateInput"
		},
		getProductIngredientItem:{
			by:"ProductIngredientItemUniqueWhere",
			filter:"ProductIngredientItemWhere"
		},
		listProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		paginateProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		getProductIngredient:{
			by:"ProductIngredientUniqueWhere",
			filter:"ProductIngredientWhere"
		},
		listProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		paginateProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		getCustomerIngredientRating:{
			by:"CustomerIngredientRatingUniqueWhere",
			filter:"CustomerIngredientRatingWhere"
		},
		listCustomerIngredientRating:{
			filter:"CustomerIngredientRatingWhere",
			orderBy:"CustomerIngredientRatingOrderBy"
		},
		paginateCustomerIngredientRating:{
			filter:"CustomerIngredientRatingWhere",
			orderBy:"CustomerIngredientRatingOrderBy"
		},
		validateCreateCustomerIngredientRating:{
			data:"CustomerIngredientRatingCreateInput"
		},
		validateUpdateCustomerIngredientRating:{
			by:"CustomerIngredientRatingUniqueWhere",
			data:"CustomerIngredientRatingUpdateInput"
		},
		getProductIngredientRating:{
			by:"ProductIngredientRatingUniqueWhere",
			filter:"ProductIngredientRatingWhere"
		},
		listProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		paginateProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		getProductIngredientLocale:{
			by:"ProductIngredientLocaleUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		listProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		getOrderRecurrence:{
			by:"OrderRecurrenceUniqueWhere",
			filter:"OrderRecurrenceWhere"
		},
		listOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		paginateOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		getOrderRecurrenceLocale:{
			by:"OrderRecurrenceLocaleUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		listOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		getBusinessCustomer:{
			by:"BusinessCustomerUniqueWhere",
			filter:"BusinessCustomerWhere"
		},
		listBusinessCustomer:{
			filter:"BusinessCustomerWhere",
			orderBy:"BusinessCustomerOrderBy"
		},
		paginateBusinessCustomer:{
			filter:"BusinessCustomerWhere",
			orderBy:"BusinessCustomerOrderBy"
		},
		getStaff:{
			by:"StaffUniqueWhere",
			filter:"StaffWhere"
		},
		listStaff:{
			filter:"StaffWhere",
			orderBy:"StaffOrderBy"
		},
		paginateStaff:{
			filter:"StaffWhere",
			orderBy:"StaffOrderBy"
		},
		getBlog:{
			by:"BlogUniqueWhere",
			filter:"BlogWhere"
		},
		listBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		paginateBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		getBlogLocale:{
			by:"BlogLocaleUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		listBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		paginateBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		getBlogPost:{
			by:"BlogPostUniqueWhere",
			filter:"BlogPostWhere"
		},
		listBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		paginateBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		getBlogPostLocale:{
			by:"BlogPostLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		listBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		getColorPallete:{
			by:"ColorPalleteUniqueWhere",
			filter:"ColorPalleteWhere"
		},
		listColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		paginateColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getBlogPostList:{
			by:"BlogPostListUniqueWhere",
			filter:"BlogPostListWhere"
		},
		listBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		paginateBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		getBlogPostListItem:{
			by:"BlogPostListItemUniqueWhere",
			filter:"BlogPostListItemWhere"
		},
		listBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		paginateBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		getLinkList:{
			by:"LinkListUniqueWhere",
			filter:"LinkListWhere"
		},
		listLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		paginateLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		getLinkListItem:{
			by:"LinkListItemUniqueWhere",
			filter:"LinkListItemWhere"
		},
		listLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		getCover:{
			by:"CoverUniqueWhere",
			filter:"CoverWhere"
		},
		listCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		paginateCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getMedium:{
			by:"MediumUniqueWhere",
			filter:"MediumWhere"
		},
		listMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		paginateMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getTranslationDomain:{
			by:"TranslationDomainUniqueWhere",
			filter:"TranslationDomainWhere"
		},
		listTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		paginateTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		getTranslationCatalogue:{
			by:"TranslationCatalogueUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		listTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		getTranslationKey:{
			by:"TranslationKeyUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		listTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		paginateTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		getTranslationValue:{
			by:"TranslationValueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		listTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		paginateTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		getTranslationCataloguesIdentifier:{
			by:"TranslationCataloguesIdentifierUniqueWhere",
			filter:"TranslationCataloguesIdentifierWhere"
		},
		listTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		paginateTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		getSocialsAndApps:{
			by:"SocialsAndAppsUniqueWhere",
			filter:"SocialsAndAppsWhere"
		},
		listSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		paginateSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		getProductGrid:{
			by:"ProductGridUniqueWhere",
			filter:"ProductGridWhere"
		},
		listProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		paginateProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		getProductGridItem:{
			by:"ProductGridItemUniqueWhere",
			filter:"ProductGridItemWhere"
		},
		listProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		paginateProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		getGallery:{
			by:"GalleryUniqueWhere",
			filter:"GalleryWhere"
		},
		listGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		paginateGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		getGalleryItem:{
			by:"GalleryItemUniqueWhere",
			filter:"GalleryItemWhere"
		},
		listGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		getHero:{
			by:"HeroUniqueWhere",
			filter:"HeroWhere"
		},
		listHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		paginateHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		getPage:{
			by:"PageUniqueWhere",
			filter:"PageWhere"
		},
		listPage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		paginatePage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		getPageLocale:{
			by:"PageLocaleUniqueWhere",
			filter:"PageLocaleWhere"
		},
		listPageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		paginatePageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		getCart:{
			by:"CartUniqueWhere",
			filter:"CartWhere"
		},
		listCart:{
			filter:"CartWhere",
			orderBy:"CartOrderBy"
		},
		paginateCart:{
			filter:"CartWhere",
			orderBy:"CartOrderBy"
		},
		validateCreateCart:{
			data:"CartCreateInput"
		},
		getCartItem:{
			by:"CartItemUniqueWhere",
			filter:"CartItemWhere"
		},
		listCartItem:{
			filter:"CartItemWhere",
			orderBy:"CartItemOrderBy"
		},
		paginateCartItem:{
			filter:"CartItemWhere",
			orderBy:"CartItemOrderBy"
		},
		validateCreateCartItem:{
			data:"CartItemCreateInput"
		},
		validateUpdateCartItem:{
			by:"CartItemUniqueWhere",
			data:"CartItemUpdateInput"
		},
		getProductBanner:{
			by:"ProductBannerUniqueWhere",
			filter:"ProductBannerWhere"
		},
		listProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		paginateProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		getProductBannerItem:{
			by:"ProductBannerItemUniqueWhere",
			filter:"ProductBannerItemWhere"
		},
		listProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		paginateProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		getProductBannerList:{
			by:"ProductBannerListUniqueWhere",
			filter:"ProductBannerListWhere"
		},
		listProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		paginateProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		getUser:{
			by:"UserUniqueWhere",
			filter:"UserWhere"
		},
		listUser:{
			filter:"UserWhere",
			orderBy:"UserOrderBy"
		},
		paginateUser:{
			filter:"UserWhere",
			orderBy:"UserOrderBy"
		},
		getAllergen:{
			by:"AllergenUniqueWhere",
			filter:"AllergenWhere"
		},
		listAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		paginateAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		getAllergenLocale:{
			by:"AllergenLocaleUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		listAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		getGps:{
			by:"GpsUniqueWhere",
			filter:"GpsWhere"
		},
		listGps:{
			filter:"GpsWhere",
			orderBy:"GpsOrderBy"
		},
		paginateGps:{
			filter:"GpsWhere",
			orderBy:"GpsOrderBy"
		},
		validateCreateGps:{
			data:"GpsCreateInput"
		},
		validateUpdateGps:{
			by:"GpsUniqueWhere",
			data:"GpsUpdateInput"
		},
		getWeekHours:{
			by:"WeekHoursUniqueWhere",
			filter:"WeekHoursWhere"
		},
		listWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		paginateWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		getDeliveryZone:{
			by:"DeliveryZoneUniqueWhere",
			filter:"DeliveryZoneWhere"
		},
		listDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		getAddressMetadata:{
			by:"AddressMetadataUniqueWhere",
			filter:"AddressMetadataWhere"
		},
		listAddressMetadata:{
			filter:"AddressMetadataWhere",
			orderBy:"AddressMetadataOrderBy"
		},
		paginateAddressMetadata:{
			filter:"AddressMetadataWhere",
			orderBy:"AddressMetadataOrderBy"
		},
		getDeliveryTimelinePreset:{
			by:"DeliveryTimelinePresetUniqueWhere",
			filter:"DeliveryTimelinePresetWhere"
		},
		listDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		paginateDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		getInvoice:{
			by:"InvoiceUniqueWhere",
			filter:"InvoiceWhere"
		},
		listInvoice:{
			filter:"InvoiceWhere",
			orderBy:"InvoiceOrderBy"
		},
		paginateInvoice:{
			filter:"InvoiceWhere",
			orderBy:"InvoiceOrderBy"
		},
		getInvoicingPlan:{
			by:"InvoicingPlanUniqueWhere",
			filter:"InvoicingPlanWhere"
		},
		listInvoicingPlan:{
			filter:"InvoicingPlanWhere",
			orderBy:"InvoicingPlanOrderBy"
		},
		paginateInvoicingPlan:{
			filter:"InvoicingPlanWhere",
			orderBy:"InvoicingPlanOrderBy"
		},
		getProductPackingLocale:{
			by:"ProductPackingLocaleUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		listProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		paginateProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		getProductLocale:{
			by:"ProductLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		listProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		getProductRecipeLocale:{
			by:"ProductRecipeLocaleUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		listProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		paginateProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		getInvoicingPreset:{
			by:"InvoicingPresetUniqueWhere",
			filter:"InvoicingPresetWhere"
		},
		listInvoicingPreset:{
			filter:"InvoicingPresetWhere",
			orderBy:"InvoicingPresetOrderBy"
		},
		paginateInvoicingPreset:{
			filter:"InvoicingPresetWhere",
			orderBy:"InvoicingPresetOrderBy"
		},
		getBillingSubject:{
			by:"BillingSubjectUniqueWhere",
			filter:"BillingSubjectWhere"
		},
		listBillingSubject:{
			filter:"BillingSubjectWhere",
			orderBy:"BillingSubjectOrderBy"
		},
		paginateBillingSubject:{
			filter:"BillingSubjectWhere",
			orderBy:"BillingSubjectOrderBy"
		},
		validateCreateBillingSubject:{
			data:"BillingSubjectCreateInput"
		},
		validateUpdateBillingSubject:{
			by:"BillingSubjectUniqueWhere",
			data:"BillingSubjectUpdateInput"
		},
		getProductCustomerPrice:{
			by:"ProductCustomerPriceUniqueWhere",
			filter:"ProductCustomerPriceWhere"
		},
		listProductCustomerPrice:{
			filter:"ProductCustomerPriceWhere",
			orderBy:"ProductCustomerPriceOrderBy"
		},
		paginateProductCustomerPrice:{
			filter:"ProductCustomerPriceWhere",
			orderBy:"ProductCustomerPriceOrderBy"
		},
		getCartPriceLine:{
			by:"CartPriceLineUniqueWhere",
			filter:"CartPriceLineWhere"
		},
		listCartPriceLine:{
			filter:"CartPriceLineWhere",
			orderBy:"CartPriceLineOrderBy"
		},
		paginateCartPriceLine:{
			filter:"CartPriceLineWhere",
			orderBy:"CartPriceLineOrderBy"
		},
		getVirtualProduct:{
			by:"VirtualProductUniqueWhere",
			filter:"VirtualProductWhere"
		},
		listVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		paginateVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		getVirtualProductEffect:{
			by:"VirtualProductEffectUniqueWhere",
			filter:"VirtualProductEffectWhere"
		},
		listVirtualProductEffect:{
			filter:"VirtualProductEffectWhere",
			orderBy:"VirtualProductEffectOrderBy"
		},
		paginateVirtualProductEffect:{
			filter:"VirtualProductEffectWhere",
			orderBy:"VirtualProductEffectOrderBy"
		},
		getOrderPriceLine:{
			by:"OrderPriceLineUniqueWhere",
			filter:"OrderPriceLineWhere"
		},
		listOrderPriceLine:{
			filter:"OrderPriceLineWhere",
			orderBy:"OrderPriceLineOrderBy"
		},
		paginateOrderPriceLine:{
			filter:"OrderPriceLineWhere",
			orderBy:"OrderPriceLineOrderBy"
		},
		getProductMetadata:{
			by:"ProductMetadataUniqueWhere",
			filter:"ProductMetadataWhere"
		},
		listProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		paginateProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		getProductGridCategory:{
			by:"ProductGridCategoryUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		listProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		paginateProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		getAnonymousSession:{
			by:"AnonymousSessionUniqueWhere",
			filter:"AnonymousSessionWhere"
		},
		listAnonymousSession:{
			filter:"AnonymousSessionWhere",
			orderBy:"AnonymousSessionOrderBy"
		},
		paginateAnonymousSession:{
			filter:"AnonymousSessionWhere",
			orderBy:"AnonymousSessionOrderBy"
		},
		validateCreateAnonymousSession:{
			data:"AnonymousSessionCreateInput"
		},
		validateUpdateAnonymousSession:{
			by:"AnonymousSessionUniqueWhere",
			data:"AnonymousSessionUpdateInput"
		},
		getVoucher:{
			by:"VoucherUniqueWhere",
			filter:"VoucherWhere"
		},
		listVoucher:{
			filter:"VoucherWhere",
			orderBy:"VoucherOrderBy"
		},
		paginateVoucher:{
			filter:"VoucherWhere",
			orderBy:"VoucherOrderBy"
		},
		getVoucherRedemption:{
			by:"VoucherRedemptionUniqueWhere",
			filter:"VoucherRedemptionWhere"
		},
		listVoucherRedemption:{
			filter:"VoucherRedemptionWhere",
			orderBy:"VoucherRedemptionOrderBy"
		},
		paginateVoucherRedemption:{
			filter:"VoucherRedemptionWhere",
			orderBy:"VoucherRedemptionOrderBy"
		},
		getFreshingContainer:{
			by:"FreshingContainerUniqueWhere",
			filter:"FreshingContainerWhere"
		},
		listFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		paginateFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		getTextItem:{
			by:"TextItemUniqueWhere",
			filter:"TextItemWhere"
		},
		listTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		getTextList:{
			by:"TextListUniqueWhere",
			filter:"TextListWhere"
		},
		listTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		paginateTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		getDeliveryRegions:{
			by:"DeliveryRegionsUniqueWhere",
			filter:"DeliveryRegionsWhere"
		},
		listDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		paginateDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		getDeliveryRegionsItem:{
			by:"DeliveryRegionsItemUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		listDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		paginateDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getDeliveryMethodLocale:{
			by:"DeliveryMethodLocaleUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		listDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		paginateDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getWorkspace:{
			by:"WorkspaceUniqueWhere",
			filter:"WorkspaceWhere"
		},
		listWorkspace:{
			filter:"WorkspaceWhere",
			orderBy:"WorkspaceOrderBy"
		},
		paginateWorkspace:{
			filter:"WorkspaceWhere",
			orderBy:"WorkspaceOrderBy"
		},
		getCartSummary:{
			by:"CartSummaryUniqueWhere",
			filter:"CartSummaryWhere"
		},
		listCartSummary:{
			filter:"CartSummaryWhere",
			orderBy:"CartSummaryOrderBy"
		},
		paginateCartSummary:{
			filter:"CartSummaryWhere",
			orderBy:"CartSummaryOrderBy"
		},
		getOrderSummary:{
			by:"OrderSummaryUniqueWhere",
			filter:"OrderSummaryWhere"
		},
		listOrderSummary:{
			filter:"OrderSummaryWhere",
			orderBy:"OrderSummaryOrderBy"
		},
		paginateOrderSummary:{
			filter:"OrderSummaryWhere",
			orderBy:"OrderSummaryOrderBy"
		},
		getOrderPaymentMetadata:{
			by:"OrderPaymentMetadataUniqueWhere",
			filter:"OrderPaymentMetadataWhere"
		},
		listOrderPaymentMetadata:{
			filter:"OrderPaymentMetadataWhere",
			orderBy:"OrderPaymentMetadataOrderBy"
		},
		paginateOrderPaymentMetadata:{
			filter:"OrderPaymentMetadataWhere",
			orderBy:"OrderPaymentMetadataOrderBy"
		},
		getUserMetadata:{
			by:"UserMetadataUniqueWhere",
			filter:"UserMetadataWhere"
		},
		listUserMetadata:{
			filter:"UserMetadataWhere",
			orderBy:"UserMetadataOrderBy"
		},
		paginateUserMetadata:{
			filter:"UserMetadataWhere",
			orderBy:"UserMetadataOrderBy"
		},
		validateCreateIdentificationAttempt:{
			data:"IdentificationAttemptCreateInput"
		},
		getIdentificationCode:{
			by:"IdentificationCodeUniqueWhere",
			filter:"IdentificationCodeWhere"
		},
		listIdentificationCode:{
			filter:"IdentificationCodeWhere",
			orderBy:"IdentificationCodeOrderBy"
		},
		paginateIdentificationCode:{
			filter:"IdentificationCodeWhere",
			orderBy:"IdentificationCodeOrderBy"
		},
		getIdentificationCodeLocale:{
			by:"IdentificationCodeLocaleUniqueWhere",
			filter:"IdentificationCodeLocaleWhere"
		},
		listIdentificationCodeLocale:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		paginateIdentificationCodeLocale:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		getFulfillmentNote:{
			by:"FulfillmentNoteUniqueWhere",
			filter:"FulfillmentNoteWhere"
		},
		listFulfillmentNote:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		paginateFulfillmentNote:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		getCustomerMetadata:{
			by:"CustomerMetadataUniqueWhere",
			filter:"CustomerMetadataWhere"
		},
		listCustomerMetadata:{
			filter:"CustomerMetadataWhere",
			orderBy:"CustomerMetadataOrderBy"
		},
		paginateCustomerMetadata:{
			filter:"CustomerMetadataWhere",
			orderBy:"CustomerMetadataOrderBy"
		},
		getSubscriptionBox:{
			by:"SubscriptionBoxUniqueWhere",
			filter:"SubscriptionBoxWhere"
		},
		listSubscriptionBox:{
			filter:"SubscriptionBoxWhere",
			orderBy:"SubscriptionBoxOrderBy"
		},
		paginateSubscriptionBox:{
			filter:"SubscriptionBoxWhere",
			orderBy:"SubscriptionBoxOrderBy"
		},
		getSubscriptionBoxLocale:{
			by:"SubscriptionBoxLocaleUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		listSubscriptionBoxLocale:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		paginateSubscriptionBoxLocale:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		getGridTileItem:{
			by:"GridTileItemUniqueWhere",
			filter:"GridTileItemWhere"
		},
		listGridTileItem:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		},
		paginateGridTileItem:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		},
		getGridTiles:{
			by:"GridTilesUniqueWhere",
			filter:"GridTilesWhere"
		},
		listGridTiles:{
			filter:"GridTilesWhere",
			orderBy:"GridTilesOrderBy"
		},
		paginateGridTiles:{
			filter:"GridTilesWhere",
			orderBy:"GridTilesOrderBy"
		},
		getPosition:{
			by:"PositionUniqueWhere",
			filter:"PositionWhere"
		},
		listPosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		paginatePosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		getStatsWidget:{
			by:"StatsWidgetUniqueWhere",
			filter:"StatsWidgetWhere"
		},
		listStatsWidget:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		},
		paginateStatsWidget:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		},
		getStatsWidgetsLayout:{
			by:"StatsWidgetsLayoutUniqueWhere",
			filter:"StatsWidgetsLayoutWhere"
		},
		listStatsWidgetsLayout:{
			filter:"StatsWidgetsLayoutWhere",
			orderBy:"StatsWidgetsLayoutOrderBy"
		},
		paginateStatsWidgetsLayout:{
			filter:"StatsWidgetsLayoutWhere",
			orderBy:"StatsWidgetsLayoutOrderBy"
		},
		getStatsPage:{
			by:"StatsPageUniqueWhere",
			filter:"StatsPageWhere"
		},
		listStatsPage:{
			filter:"StatsPageWhere",
			orderBy:"StatsPageOrderBy"
		},
		paginateStatsPage:{
			filter:"StatsPageWhere",
			orderBy:"StatsPageOrderBy"
		}
	},
	Address:{
		customer:{
			filter:"CustomerWhere"
		},
		country:{
			filter:"CountryWhere"
		},
		deliveryZone:{
			filter:"DeliveryZoneWhere"
		},
		meta:{
			filter:"AddressMetadataWhere"
		},
		gps:{
			filter:"GpsWhere"
		},
		invoices:{
			filter:"InvoiceWhere",
			orderBy:"InvoiceOrderBy"
		},
		billingAddressOfOrders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		deliveryAddressOfOrderDeliveries:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		},
		defaultDeliveryAddressOfCustomer:{
			filter:"CustomerWhere"
		},
		invoicesByPublicKey:{
			by:"AddressInvoicesByPublicKeyUniqueWhere",
			filter:"InvoiceWhere"
		},
		invoicesByFakturoidId:{
			by:"AddressInvoicesByFakturoidIdUniqueWhere",
			filter:"InvoiceWhere"
		},
		invoicesByOrderPayments:{
			by:"AddressInvoicesByOrderPaymentsUniqueWhere",
			filter:"InvoiceWhere"
		},
		billingAddressOfOrdersBySeq:{
			by:"AddressBillingAddressOfOrdersBySeqUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByDoubleshotLegacyId:{
			by:"AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByDoubleShotLegacyNumber:{
			by:"AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByDiscounts:{
			by:"AddressBillingAddressOfOrdersByDiscountsUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByDelivery:{
			by:"AddressBillingAddressOfOrdersByDeliveryUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByItems:{
			by:"AddressBillingAddressOfOrdersByItemsUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByCart:{
			by:"AddressBillingAddressOfOrdersByCartUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByPriceLines:{
			by:"AddressBillingAddressOfOrdersByPriceLinesUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersByPayments:{
			by:"AddressBillingAddressOfOrdersByPaymentsUniqueWhere",
			filter:"OrderWhere"
		},
		billingAddressOfOrdersBySummary:{
			by:"AddressBillingAddressOfOrdersBySummaryUniqueWhere",
			filter:"OrderWhere"
		},
		deliveryAddressOfOrderDeliveriesByTrackingCode:{
			by:"AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere",
			filter:"OrderDeliveryWhere"
		},
		deliveryAddressOfOrderDeliveriesByOrder:{
			by:"AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere",
			filter:"OrderDeliveryWhere"
		},
		paginateInvoices:{
			filter:"InvoiceWhere",
			orderBy:"InvoiceOrderBy"
		},
		paginateBillingAddressOfOrders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateDeliveryAddressOfOrderDeliveries:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		}
	},
	UUID: "String",
	Customer:{
		addresses:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		defaultBillingAddress:{
			filter:"AddressWhere"
		},
		tags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		credits:{
			filter:"CustomerCreditWhere",
			orderBy:"CustomerCreditOrderBy"
		},
		orders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		permissions:{
			filter:"CustomerPermissionsWhere"
		},
		group:{
			filter:"CustomerGroupWhere"
		},
		photo:{
			filter:"ImageWhere"
		},
		name:{
			filter:"CustomerNameWhere"
		},
		ingredientRatings:{
			filter:"CustomerIngredientRatingWhere",
			orderBy:"CustomerIngredientRatingOrderBy"
		},
		business:{
			filter:"BusinessCustomerWhere"
		},
		ownerOfBusinesses:{
			filter:"BusinessCustomerWhere",
			orderBy:"BusinessCustomerOrderBy"
		},
		memberOfBusinesses:{
			filter:"BusinessCustomerWhere",
			orderBy:"BusinessCustomerOrderBy"
		},
		carts:{
			filter:"CartWhere",
			orderBy:"CartOrderBy"
		},
		user:{
			filter:"UserWhere"
		},
		channels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		invoices:{
			filter:"InvoiceWhere",
			orderBy:"InvoiceOrderBy"
		},
		invoicingPlan:{
			filter:"InvoicingPlanWhere"
		},
		billingSubjects:{
			filter:"BillingSubjectWhere",
			orderBy:"BillingSubjectOrderBy"
		},
		offeredProducts:{
			filter:"ProductCustomerPriceWhere",
			orderBy:"ProductCustomerPriceOrderBy"
		},
		defaultDeliveryAddress:{
			filter:"AddressWhere"
		},
		defaultBillingSubject:{
			filter:"BillingSubjectWhere"
		},
		anonymousSession:{
			filter:"AnonymousSessionWhere"
		},
		boughtVouchers:{
			filter:"VoucherWhere",
			orderBy:"VoucherOrderBy"
		},
		ownsVouchers:{
			filter:"VoucherWhere",
			orderBy:"VoucherOrderBy"
		},
		redeemedVouchers:{
			filter:"VoucherRedemptionWhere",
			orderBy:"VoucherRedemptionOrderBy"
		},
		meta:{
			filter:"CustomerMetadataWhere"
		},
		addressesByMeta:{
			by:"CustomerAddressesByMetaUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByGps:{
			by:"CustomerAddressesByGpsUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByInvoices:{
			by:"CustomerAddressesByInvoicesUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByBillingAddressOfOrders:{
			by:"CustomerAddressesByBillingAddressOfOrdersUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByDeliveryAddressOfOrderDeliveries:{
			by:"CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByDefaultDeliveryAddressOfCustomer:{
			by:"CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere",
			filter:"AddressWhere"
		},
		creditsByVoucherRedemption:{
			by:"CustomerCreditsByVoucherRedemptionUniqueWhere",
			filter:"CustomerCreditWhere"
		},
		ordersBySeq:{
			by:"CustomerOrdersBySeqUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDoubleshotLegacyId:{
			by:"CustomerOrdersByDoubleshotLegacyIdUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDoubleShotLegacyNumber:{
			by:"CustomerOrdersByDoubleShotLegacyNumberUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDiscounts:{
			by:"CustomerOrdersByDiscountsUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDelivery:{
			by:"CustomerOrdersByDeliveryUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByItems:{
			by:"CustomerOrdersByItemsUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByCart:{
			by:"CustomerOrdersByCartUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByPriceLines:{
			by:"CustomerOrdersByPriceLinesUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByPayments:{
			by:"CustomerOrdersByPaymentsUniqueWhere",
			filter:"OrderWhere"
		},
		ordersBySummary:{
			by:"CustomerOrdersBySummaryUniqueWhere",
			filter:"OrderWhere"
		},
		ingredientRatingsByIngredient:{
			by:"CustomerIngredientRatingsByIngredientUniqueWhere",
			filter:"CustomerIngredientRatingWhere"
		},
		cartsByItems:{
			by:"CustomerCartsByItemsUniqueWhere",
			filter:"CartWhere"
		},
		cartsByOrder:{
			by:"CustomerCartsByOrderUniqueWhere",
			filter:"CartWhere"
		},
		cartsByPriceLines:{
			by:"CustomerCartsByPriceLinesUniqueWhere",
			filter:"CartWhere"
		},
		cartsBySummary:{
			by:"CustomerCartsBySummaryUniqueWhere",
			filter:"CartWhere"
		},
		invoicesByPublicKey:{
			by:"CustomerInvoicesByPublicKeyUniqueWhere",
			filter:"InvoiceWhere"
		},
		invoicesByFakturoidId:{
			by:"CustomerInvoicesByFakturoidIdUniqueWhere",
			filter:"InvoiceWhere"
		},
		invoicesByOrderPayments:{
			by:"CustomerInvoicesByOrderPaymentsUniqueWhere",
			filter:"InvoiceWhere"
		},
		billingSubjectsByInvoices:{
			by:"CustomerBillingSubjectsByInvoicesUniqueWhere",
			filter:"BillingSubjectWhere"
		},
		billingSubjectsByDefaultBillingSubjectOfCustomer:{
			by:"CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere",
			filter:"BillingSubjectWhere"
		},
		billingSubjectsByOrders:{
			by:"CustomerBillingSubjectsByOrdersUniqueWhere",
			filter:"BillingSubjectWhere"
		},
		offeredProductsByProduct:{
			by:"CustomerOfferedProductsByProductUniqueWhere",
			filter:"ProductCustomerPriceWhere"
		},
		boughtVouchersByCode:{
			by:"CustomerBoughtVouchersByCodeUniqueWhere",
			filter:"VoucherWhere"
		},
		boughtVouchersByRedemption:{
			by:"CustomerBoughtVouchersByRedemptionUniqueWhere",
			filter:"VoucherWhere"
		},
		ownsVouchersByCode:{
			by:"CustomerOwnsVouchersByCodeUniqueWhere",
			filter:"VoucherWhere"
		},
		ownsVouchersByRedemption:{
			by:"CustomerOwnsVouchersByRedemptionUniqueWhere",
			filter:"VoucherWhere"
		},
		redeemedVouchersByCustomerCredit:{
			by:"CustomerRedeemedVouchersByCustomerCreditUniqueWhere",
			filter:"VoucherRedemptionWhere"
		},
		redeemedVouchersByOrderDiscount:{
			by:"CustomerRedeemedVouchersByOrderDiscountUniqueWhere",
			filter:"VoucherRedemptionWhere"
		},
		redeemedVouchersByVoucher:{
			by:"CustomerRedeemedVouchersByVoucherUniqueWhere",
			filter:"VoucherRedemptionWhere"
		},
		paginateAddresses:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		paginateTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateCredits:{
			filter:"CustomerCreditWhere",
			orderBy:"CustomerCreditOrderBy"
		},
		paginateOrders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateIngredientRatings:{
			filter:"CustomerIngredientRatingWhere",
			orderBy:"CustomerIngredientRatingOrderBy"
		},
		paginateOwnerOfBusinesses:{
			filter:"BusinessCustomerWhere",
			orderBy:"BusinessCustomerOrderBy"
		},
		paginateMemberOfBusinesses:{
			filter:"BusinessCustomerWhere",
			orderBy:"BusinessCustomerOrderBy"
		},
		paginateCarts:{
			filter:"CartWhere",
			orderBy:"CartOrderBy"
		},
		paginateChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateInvoices:{
			filter:"InvoiceWhere",
			orderBy:"InvoiceOrderBy"
		},
		paginateBillingSubjects:{
			filter:"BillingSubjectWhere",
			orderBy:"BillingSubjectOrderBy"
		},
		paginateOfferedProducts:{
			filter:"ProductCustomerPriceWhere",
			orderBy:"ProductCustomerPriceOrderBy"
		},
		paginateBoughtVouchers:{
			filter:"VoucherWhere",
			orderBy:"VoucherOrderBy"
		},
		paginateOwnsVouchers:{
			filter:"VoucherWhere",
			orderBy:"VoucherOrderBy"
		},
		paginateRedeemedVouchers:{
			filter:"VoucherRedemptionWhere",
			orderBy:"VoucherRedemptionOrderBy"
		}
	},
	AddressWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		firstName:"StringCondition",
		lastName:"StringCondition",
		addressLine2:"StringCondition",
		email:"StringCondition",
		phone:"StringCondition",
		companyName:"StringCondition",
		companyIdentifier:"StringCondition",
		vatIdentifier:"StringCondition",
		externalIdentifier:"StringCondition",
		hidden:"BooleanCondition",
		customer:"CustomerWhere",
		country:"CountryWhere",
		deletedAt:"DateTimeCondition",
		note:"StringCondition",
		googlePlaceId:"StringCondition",
		raw:"StringCondition",
		formatted:"StringCondition",
		geocodeGeneratedAt:"DateTimeCondition",
		geocodeResponse:"StringCondition",
		deliveryZone:"DeliveryZoneWhere",
		meta:"AddressMetadataWhere",
		gps:"GpsWhere",
		geocodeValid:"BooleanCondition",
		fullName:"StringCondition",
		fakturoidSubjectId:"StringCondition",
		invoices:"InvoiceWhere",
		addressLine1:"StringCondition",
		city:"StringCondition",
		postalCode:"StringCondition",
		createdAt:"DateTimeCondition",
		billingAddressOfOrders:"OrderWhere",
		deliveryAddressOfOrderDeliveries:"OrderDeliveryWhere",
		defaultDeliveryAddressOfCustomer:"CustomerWhere",
		and:"AddressWhere",
		or:"AddressWhere",
		not:"AddressWhere"
	},
	UUIDCondition:{
		and:"UUIDCondition",
		or:"UUIDCondition",
		not:"UUIDCondition",
		eq:"UUID",
		notEq:"UUID",
		in:"UUID",
		notIn:"UUID",
		lt:"UUID",
		lte:"UUID",
		gt:"UUID",
		gte:"UUID"
	},
	StringCondition:{
		and:"StringCondition",
		or:"StringCondition",
		not:"StringCondition"
	},
	BooleanCondition:{
		and:"BooleanCondition",
		or:"BooleanCondition",
		not:"BooleanCondition"
	},
	CustomerWhere:{
		id:"UUIDCondition",
		addresses:"AddressWhere",
		defaultBillingAddress:"AddressWhere",
		tags:"TagWhere",
		credits:"CustomerCreditWhere",
		orders:"OrderWhere",
		permissions:"CustomerPermissionsWhere",
		group:"CustomerGroupWhere",
		photo:"ImageWhere",
		phone:"StringCondition",
		name:"CustomerNameWhere",
		welcomeMessage:"StringCondition",
		familiarity:"CustomerFamiliarityCondition",
		ingredientRatings:"CustomerIngredientRatingWhere",
		business:"BusinessCustomerWhere",
		ownerOfBusinesses:"BusinessCustomerWhere",
		memberOfBusinesses:"BusinessCustomerWhere",
		carts:"CartWhere",
		user:"UserWhere",
		channels:"ChannelWhere",
		disabledAt:"DateTimeCondition",
		invoices:"InvoiceWhere",
		invoicingPlan:"InvoicingPlanWhere",
		email:"StringCondition",
		createdAt:"DateTimeCondition",
		billingSubjects:"BillingSubjectWhere",
		offeredProducts:"ProductCustomerPriceWhere",
		defaultDeliveryAddress:"AddressWhere",
		defaultBillingSubject:"BillingSubjectWhere",
		prefersPackingsWithoutCardboard:"BooleanCondition",
		anonymousSession:"AnonymousSessionWhere",
		boughtVouchers:"VoucherWhere",
		ownsVouchers:"VoucherWhere",
		redeemedVouchers:"VoucherRedemptionWhere",
		doubleshotLegacyId:"StringCondition",
		meta:"CustomerMetadataWhere",
		doubleshotLastUpdatedAt:"DateTimeCondition",
		and:"CustomerWhere",
		or:"CustomerWhere",
		not:"CustomerWhere"
	},
	TagWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		customers:"CustomerWhere",
		products:"ProductWhere",
		categories:"ProductCategoryWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		addTagsOnRegistration:"ChannelWhere",
		deliveryZones:"DeliveryZoneWhere",
		publicInChannels:"ChannelWhere",
		allowChannelPayments:"ChannelPaymentWhere",
		fulfillmentNotes:"FulfillmentNoteWhere",
		and:"TagWhere",
		or:"TagWhere",
		not:"TagWhere"
	},
	ProductWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		priceCents:"IntCondition",
		notDiscountable:"BooleanCondition",
		packing:"ProductPackingWhere",
		recipe:"ProductRecipeWhere",
		tags:"TagWhere",
		categories:"ProductCategoryWhere",
		vatRate:"VatRateWhere",
		stocks:"ProductStockWhere",
		groupPrices:"ProductGroupPriceWhere",
		image:"ImageWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		cartItems:"CartItemWhere",
		availability:"ProductAvailabilityCondition",
		order:"FloatCondition",
		orderItems:"OrderItemWhere",
		maximumQuantityPerOrder:"IntCondition",
		deliveryZones:"DeliveryZoneWhere",
		locales:"ProductLocaleWhere",
		archivedAt:"DateTimeCondition",
		customerPrices:"ProductCustomerPriceWhere",
		virtualProduct:"VirtualProductWhere",
		meta:"ProductMetadataWhere",
		page:"PageWhere",
		fulfillmentNotes:"FulfillmentNoteWhere",
		hasSubscription:"BooleanCondition",
		pointOfSaleTileColor:"StringCondition",
		and:"ProductWhere",
		or:"ProductWhere",
		not:"ProductWhere"
	},
	IntCondition:{
		and:"IntCondition",
		or:"IntCondition",
		not:"IntCondition"
	},
	ProductPackingWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		image:"ImageWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		volumeMl:"IntCondition",
		icon:"ImageWhere",
		shortName:"StringCondition",
		locales:"ProductPackingLocaleWhere",
		isPackedInCardboard:"BooleanCondition",
		and:"ProductPackingWhere",
		or:"ProductPackingWhere",
		not:"ProductPackingWhere"
	},
	ImageWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		alt:"StringCondition",
		and:"ImageWhere",
		or:"ImageWhere",
		not:"ImageWhere"
	},
	ProductPackingLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		shortName:"StringCondition",
		description:"StringCondition",
		root:"ProductPackingWhere",
		locale:"LocaleWhere",
		and:"ProductPackingLocaleWhere",
		or:"ProductPackingLocaleWhere",
		not:"ProductPackingLocaleWhere"
	},
	LocaleWhere:{
		id:"UUIDCondition",
		businessCategories:"BusinessCategoryLocaleWhere",
		productCategoryLists:"ProductCategoryListLocaleWhere",
		productIngredients:"ProductIngredientLocaleWhere",
		orderRecurrences:"OrderRecurrenceLocaleWhere",
		blogs:"BlogLocaleWhere",
		blogPosts:"BlogPostLocaleWhere",
		identifier:"TranslationCataloguesIdentifierWhere",
		pages:"PageLocaleWhere",
		allergens:"AllergenLocaleWhere",
		productPackings:"ProductPackingLocaleWhere",
		products:"ProductLocaleWhere",
		productRecipes:"ProductRecipeLocaleWhere",
		deliveryMethods:"DeliveryMethodLocaleWhere",
		identificationCodes:"IdentificationCodeLocaleWhere",
		subscriptionBoxes:"SubscriptionBoxLocaleWhere",
		and:"LocaleWhere",
		or:"LocaleWhere",
		not:"LocaleWhere"
	},
	BusinessCategoryLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"BusinessCategoryWhere",
		locale:"LocaleWhere",
		title:"StringCondition",
		and:"BusinessCategoryLocaleWhere",
		or:"BusinessCategoryLocaleWhere",
		not:"BusinessCategoryLocaleWhere"
	},
	BusinessCategoryWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		visibleToNewCustomers:"BooleanCondition",
		locales:"BusinessCategoryLocaleWhere",
		internalName:"StringCondition",
		businessCustomers:"BusinessCustomerWhere",
		recurrences:"OrderRecurrenceWhere",
		specificProducts:"ProductListWhere",
		seasonalProductCategories:"ProductCategoryListWhere",
		specificProductsProductCategories:"ProductCategoryListWhere",
		slug:"StringCondition",
		and:"BusinessCategoryWhere",
		or:"BusinessCategoryWhere",
		not:"BusinessCategoryWhere"
	},
	BusinessCustomerWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		customer:"CustomerWhere",
		owners:"CustomerWhere",
		members:"CustomerWhere",
		category:"BusinessCategoryWhere",
		logo:"ImageWhere",
		note:"StringCondition",
		and:"BusinessCustomerWhere",
		or:"BusinessCustomerWhere",
		not:"BusinessCustomerWhere"
	},
	OrderRecurrenceWhere:{
		id:"UUIDCondition",
		internalName:"StringCondition",
		locales:"OrderRecurrenceLocaleWhere",
		order:"IntCondition",
		businessCategory:"BusinessCategoryWhere",
		and:"OrderRecurrenceWhere",
		or:"OrderRecurrenceWhere",
		not:"OrderRecurrenceWhere"
	},
	OrderRecurrenceLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"OrderRecurrenceWhere",
		locale:"LocaleWhere",
		and:"OrderRecurrenceLocaleWhere",
		or:"OrderRecurrenceLocaleWhere",
		not:"OrderRecurrenceLocaleWhere"
	},
	ProductListWhere:{
		id:"UUIDCondition",
		items:"ProductListItemWhere",
		businessCategory:"BusinessCategoryWhere",
		and:"ProductListWhere",
		or:"ProductListWhere",
		not:"ProductListWhere"
	},
	ProductListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProductListWhere",
		product:"ProductWhere",
		defaultQuantityForOrder:"IntCondition",
		and:"ProductListItemWhere",
		or:"ProductListItemWhere",
		not:"ProductListItemWhere"
	},
	ProductCategoryListWhere:{
		id:"UUIDCondition",
		items:"ProductCategoryListItemWhere",
		locales:"ProductCategoryListLocaleWhere",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategoryWhere",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategoryWhere",
		and:"ProductCategoryListWhere",
		or:"ProductCategoryListWhere",
		not:"ProductCategoryListWhere"
	},
	ProductCategoryListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProductCategoryListWhere",
		productCategory:"ProductCategoryWhere",
		showRecipeImages:"BooleanCondition",
		showPackingImages:"BooleanCondition",
		showAllAvailableProducts:"BooleanCondition",
		and:"ProductCategoryListItemWhere",
		or:"ProductCategoryListItemWhere",
		not:"ProductCategoryListItemWhere"
	},
	ProductCategoryWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		products:"ProductWhere",
		tags:"TagWhere",
		parent:"ProductCategoryWhere",
		children:"ProductCategoryWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		color:"StringCondition",
		image:"ImageWhere",
		order:"FloatCondition",
		and:"ProductCategoryWhere",
		or:"ProductCategoryWhere",
		not:"ProductCategoryWhere"
	},
	FloatCondition:{
		and:"FloatCondition",
		or:"FloatCondition",
		not:"FloatCondition"
	},
	ProductCategoryListLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductCategoryListWhere",
		locale:"LocaleWhere",
		and:"ProductCategoryListLocaleWhere",
		or:"ProductCategoryListLocaleWhere",
		not:"ProductCategoryListLocaleWhere"
	},
	ProductIngredientLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductIngredientWhere",
		locale:"LocaleWhere",
		and:"ProductIngredientLocaleWhere",
		or:"ProductIngredientLocaleWhere",
		not:"ProductIngredientLocaleWhere"
	},
	ProductIngredientWhere:{
		id:"UUIDCondition",
		internalNote:"StringCondition",
		locales:"ProductIngredientLocaleWhere",
		internalName:"StringCondition",
		allergens:"AllergenWhere",
		needsPrepping:"BooleanCondition",
		allowCustomerRating:"BooleanCondition",
		customerRatings:"CustomerIngredientRatingWhere",
		freshingContainer:"FreshingContainerWhere",
		image:"ImageWhere",
		incrementQuantityNumber:"FloatCondition",
		and:"ProductIngredientWhere",
		or:"ProductIngredientWhere",
		not:"ProductIngredientWhere"
	},
	AllergenWhere:{
		id:"UUIDCondition",
		internalName:"StringCondition",
		locales:"AllergenLocaleWhere",
		ingredients:"ProductIngredientWhere",
		code:"IntCondition",
		and:"AllergenWhere",
		or:"AllergenWhere",
		not:"AllergenWhere"
	},
	AllergenLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"AllergenWhere",
		locale:"LocaleWhere",
		and:"AllergenLocaleWhere",
		or:"AllergenLocaleWhere",
		not:"AllergenLocaleWhere"
	},
	CustomerIngredientRatingWhere:{
		id:"UUIDCondition",
		customer:"CustomerWhere",
		rating:"ProductIngredientRatingWhere",
		ingredient:"ProductIngredientWhere",
		and:"CustomerIngredientRatingWhere",
		or:"CustomerIngredientRatingWhere",
		not:"CustomerIngredientRatingWhere"
	},
	ProductIngredientRatingWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		name:"StringCondition",
		icon:"ImageWhere",
		and:"ProductIngredientRatingWhere",
		or:"ProductIngredientRatingWhere",
		not:"ProductIngredientRatingWhere"
	},
	FreshingContainerWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		volumeMl:"IntCondition",
		workspace:"WorkspaceWhere",
		and:"FreshingContainerWhere",
		or:"FreshingContainerWhere",
		not:"FreshingContainerWhere"
	},
	WorkspaceWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		orderDeadlineTime:"StringCondition",
		ordersLoadingDurationHours:"IntCondition",
		freshingContainerVolumeMl:"IntCondition",
		freshingPrepareOrderByOffsetMinutes:"IntCondition",
		checkoutUrlTemplate:"StringCondition",
		orderUrlTemplate:"StringCondition",
		paymentUrlTemplate:"StringCondition",
		customerProfileUrlTemplate:"StringCondition",
		userProfileUrlTemplate:"StringCondition",
		messengerUrl:"StringCondition",
		messengerIosUrl:"StringCondition",
		messengerAndroidUrl:"StringCondition",
		freshingContainers:"FreshingContainerWhere",
		openingHours:"WeekHoursWhere",
		workingHours:"WeekHoursWhere",
		defaultFreshingContainer:"FreshingContainerWhere",
		creditsProduct:"VirtualProductWhere",
		discountVatRate:"VatRateWhere",
		name:"StringCondition",
		businessAddress:"StringCondition",
		and:"WorkspaceWhere",
		or:"WorkspaceWhere",
		not:"WorkspaceWhere"
	},
	OneCondition:{
		and:"OneCondition",
		or:"OneCondition",
		not:"OneCondition",
		eq:"One",
		notEq:"One",
		in:"One",
		notIn:"One",
		lt:"One",
		lte:"One",
		gt:"One",
		gte:"One"
	},
	One: true,
	WeekHoursWhere:{
		id:"UUIDCondition",
		mon:"StringCondition",
		tue:"StringCondition",
		wed:"StringCondition",
		thu:"StringCondition",
		fri:"StringCondition",
		sat:"StringCondition",
		sun:"StringCondition",
		and:"WeekHoursWhere",
		or:"WeekHoursWhere",
		not:"WeekHoursWhere"
	},
	VirtualProductWhere:{
		id:"UUIDCondition",
		type:"VirtualProductTypeCondition",
		product:"ProductWhere",
		voucherCreditCents:"IntCondition",
		physicalRepresentation:"VirtualProductPhysicalRepresentationTypeCondition",
		recipientEmail:"BooleanCondition",
		and:"VirtualProductWhere",
		or:"VirtualProductWhere",
		not:"VirtualProductWhere"
	},
	VirtualProductTypeCondition:{
		and:"VirtualProductTypeCondition",
		or:"VirtualProductTypeCondition",
		not:"VirtualProductTypeCondition",
		eq:"VirtualProductType",
		notEq:"VirtualProductType",
		in:"VirtualProductType",
		notIn:"VirtualProductType",
		lt:"VirtualProductType",
		lte:"VirtualProductType",
		gt:"VirtualProductType",
		gte:"VirtualProductType"
	},
	VirtualProductType: true,
	VirtualProductPhysicalRepresentationTypeCondition:{
		and:"VirtualProductPhysicalRepresentationTypeCondition",
		or:"VirtualProductPhysicalRepresentationTypeCondition",
		not:"VirtualProductPhysicalRepresentationTypeCondition",
		eq:"VirtualProductPhysicalRepresentationType",
		notEq:"VirtualProductPhysicalRepresentationType",
		in:"VirtualProductPhysicalRepresentationType",
		notIn:"VirtualProductPhysicalRepresentationType",
		lt:"VirtualProductPhysicalRepresentationType",
		lte:"VirtualProductPhysicalRepresentationType",
		gt:"VirtualProductPhysicalRepresentationType",
		gte:"VirtualProductPhysicalRepresentationType"
	},
	VirtualProductPhysicalRepresentationType: true,
	VatRateWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		ratePermille:"IntCondition",
		description:"StringCondition",
		internalNote:"StringCondition",
		channelDeliveries:"ChannelDeliveryWhere",
		channelPayments:"ChannelPaymentWhere",
		preset:"VatRatePresetCondition",
		and:"VatRateWhere",
		or:"VatRateWhere",
		not:"VatRateWhere"
	},
	ChannelDeliveryWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		priceCents:"IntCondition",
		channel:"ChannelWhere",
		method:"DeliveryMethodWhere",
		vatRate:"VatRateWhere",
		defaultVisible:"BooleanCondition",
		and:"ChannelDeliveryWhere",
		or:"ChannelDeliveryWhere",
		not:"ChannelDeliveryWhere"
	},
	ChannelWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		disabled:"BooleanCondition",
		payments:"ChannelPaymentWhere",
		deliveries:"ChannelDeliveryWhere",
		carts:"CartWhere",
		customers:"CustomerWhere",
		code:"ChannelCodeCondition",
		addTagsOnRegistration:"TagWhere",
		publicTags:"TagWhere",
		and:"ChannelWhere",
		or:"ChannelWhere",
		not:"ChannelWhere"
	},
	ChannelPaymentWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		channel:"ChannelWhere",
		method:"PaymentMethodWhere",
		vatRate:"VatRateWhere",
		requireBillingAddress:"BooleanCondition",
		provideReceipt:"BooleanCondition",
		requireBillingSubject:"BooleanCondition",
		allowOnlyForTags:"TagWhere",
		and:"ChannelPaymentWhere",
		or:"ChannelPaymentWhere",
		not:"ChannelPaymentWhere"
	},
	PaymentMethodWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		enabled:"BooleanCondition",
		deliveryMethods:"DeliveryMethodWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		blockingDispatch:"BooleanCondition",
		type:"PaymentMethodTypeCondition",
		channels:"ChannelPaymentWhere",
		reduceGratuityByPercentage:"IntCondition",
		and:"PaymentMethodWhere",
		or:"PaymentMethodWhere",
		not:"PaymentMethodWhere"
	},
	DeliveryMethodWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		enabled:"BooleanCondition",
		paymentMethods:"PaymentMethodWhere",
		description:"StringCondition",
		internalNote:"StringCondition",
		requireDeliveryAddress:"BooleanCondition",
		suitableForDeliveryZones:"DeliveryZoneWhere",
		channels:"ChannelDeliveryWhere",
		enableDeliveryTracking:"BooleanCondition",
		locales:"DeliveryMethodLocaleWhere",
		timelinePreset:"DeliveryTimelinePresetWhere",
		and:"DeliveryMethodWhere",
		or:"DeliveryMethodWhere",
		not:"DeliveryMethodWhere"
	},
	DeliveryZoneWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		description:"StringCondition",
		internalNote:"StringCondition",
		type:"DeliveryZoneTypeCondition",
		addresses:"AddressWhere",
		suitableDeliveryMethods:"DeliveryMethodWhere",
		deliveryZonesOfTags:"TagWhere",
		deliveryZonesOfProducts:"ProductWhere",
		addressesMetadata:"AddressMetadataWhere",
		and:"DeliveryZoneWhere",
		or:"DeliveryZoneWhere",
		not:"DeliveryZoneWhere"
	},
	DeliveryZoneTypeCondition:{
		and:"DeliveryZoneTypeCondition",
		or:"DeliveryZoneTypeCondition",
		not:"DeliveryZoneTypeCondition",
		eq:"DeliveryZoneType",
		notEq:"DeliveryZoneType",
		in:"DeliveryZoneType",
		notIn:"DeliveryZoneType",
		lt:"DeliveryZoneType",
		lte:"DeliveryZoneType",
		gt:"DeliveryZoneType",
		gte:"DeliveryZoneType"
	},
	DeliveryZoneType: true,
	AddressMetadataWhere:{
		id:"UUIDCondition",
		deliveryZoneType:"DeliveryZoneTypeCondition",
		address:"AddressWhere",
		deliveryZone:"DeliveryZoneWhere",
		and:"AddressMetadataWhere",
		or:"AddressMetadataWhere",
		not:"AddressMetadataWhere"
	},
	DeliveryMethodLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		description:"StringCondition",
		trackingCodeUrlTemplate:"StringCondition",
		processingTitle:"StringCondition",
		processingText:"StringCondition",
		processingIconUrl:"StringCondition",
		dispatchedTitle:"StringCondition",
		dispatchedText:"StringCondition",
		dispatchedIconUrl:"StringCondition",
		fulfilledTitle:"StringCondition",
		fulfilledText:"StringCondition",
		fulfilledIconUrl:"StringCondition",
		locale:"LocaleWhere",
		root:"DeliveryMethodWhere",
		and:"DeliveryMethodLocaleWhere",
		or:"DeliveryMethodLocaleWhere",
		not:"DeliveryMethodLocaleWhere"
	},
	DeliveryTimelinePresetWhere:{
		id:"UUIDCondition",
		type:"DeliveryTimelineTypeCondition",
		duration:"StringCondition",
		window:"StringCondition",
		deliveryMethod:"DeliveryMethodWhere",
		and:"DeliveryTimelinePresetWhere",
		or:"DeliveryTimelinePresetWhere",
		not:"DeliveryTimelinePresetWhere"
	},
	DeliveryTimelineTypeCondition:{
		and:"DeliveryTimelineTypeCondition",
		or:"DeliveryTimelineTypeCondition",
		not:"DeliveryTimelineTypeCondition",
		eq:"DeliveryTimelineType",
		notEq:"DeliveryTimelineType",
		in:"DeliveryTimelineType",
		notIn:"DeliveryTimelineType",
		lt:"DeliveryTimelineType",
		lte:"DeliveryTimelineType",
		gt:"DeliveryTimelineType",
		gte:"DeliveryTimelineType"
	},
	DeliveryTimelineType: true,
	PaymentMethodTypeCondition:{
		and:"PaymentMethodTypeCondition",
		or:"PaymentMethodTypeCondition",
		not:"PaymentMethodTypeCondition",
		eq:"PaymentMethodType",
		notEq:"PaymentMethodType",
		in:"PaymentMethodType",
		notIn:"PaymentMethodType",
		lt:"PaymentMethodType",
		lte:"PaymentMethodType",
		gt:"PaymentMethodType",
		gte:"PaymentMethodType"
	},
	PaymentMethodType: true,
	CartWhere:{
		id:"UUIDCondition",
		confirmedAt:"DateTimeCondition",
		deletedAt:"DateTimeCondition",
		note:"StringCondition",
		items:"CartItemWhere",
		handle:"StringCondition",
		state:"CartStateCondition",
		createdAt:"DateTimeCondition",
		channel:"ChannelWhere",
		order:"OrderWhere",
		customer:"CustomerWhere",
		deliveryAddress:"AddressWhere",
		billingAddress:"AddressWhere",
		deliveryOption:"StringCondition",
		paymentOption:"StringCondition",
		delivery:"ChannelDeliveryWhere",
		payment:"ChannelPaymentWhere",
		billingSubject:"BillingSubjectWhere",
		priceLines:"CartPriceLineWhere",
		summary:"CartSummaryWhere",
		and:"CartWhere",
		or:"CartWhere",
		not:"CartWhere"
	},
	DateTimeCondition:{
		and:"DateTimeCondition",
		or:"DateTimeCondition",
		not:"DateTimeCondition",
		eq:"DateTime",
		notEq:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime"
	},
	DateTime: "String",
	CartItemWhere:{
		id:"UUIDCondition",
		quantity:"IntCondition",
		cart:"CartWhere",
		product:"ProductWhere",
		createdAt:"DateTimeCondition",
		updatedAt:"DateTimeCondition",
		fulfillmentNote:"StringCondition",
		fulfillmentNotes:"FulfillmentNoteWhere",
		and:"CartItemWhere",
		or:"CartItemWhere",
		not:"CartItemWhere"
	},
	FulfillmentNoteWhere:{
		id:"UUIDCondition",
		note:"StringCondition",
		products:"ProductWhere",
		tags:"TagWhere",
		cartItems:"CartItemWhere",
		orderItems:"OrderItemWhere",
		orders:"OrderWhere",
		highlight:"BooleanCondition",
		createdAt:"DateTimeCondition",
		emoji:"StringCondition",
		and:"FulfillmentNoteWhere",
		or:"FulfillmentNoteWhere",
		not:"FulfillmentNoteWhere"
	},
	OrderItemWhere:{
		id:"UUIDCondition",
		unitPriceCents:"IntCondition",
		quantity:"IntCondition",
		vatRate:"VatRateWhere",
		order:"OrderWhere",
		virtualProductEffects:"VirtualProductEffectWhere",
		pickedAt:"DateTimeCondition",
		note:"StringCondition",
		fulfillmentNotes:"FulfillmentNoteWhere",
		product:"ProductWhere",
		and:"OrderItemWhere",
		or:"OrderItemWhere",
		not:"OrderItemWhere"
	},
	OrderWhere:{
		id:"UUIDCondition",
		state:"OrderStateCondition",
		fulfilledAt:"DateTimeCondition",
		canceledAt:"DateTimeCondition",
		note:"StringCondition",
		customer:"CustomerWhere",
		channel:"ChannelWhere",
		discounts:"OrderDiscountWhere",
		delivery:"OrderDeliveryWhere",
		items:"OrderItemWhere",
		cart:"CartWhere",
		createdAt:"DateTimeCondition",
		billingAddress:"AddressWhere",
		priceLines:"OrderPriceLineWhere",
		payments:"OrderPaymentWhere",
		seq:"IntCondition",
		billingSubject:"BillingSubjectWhere",
		summary:"OrderSummaryWhere",
		doubleshotLegacyId:"StringCondition",
		fulfillmentNote:"StringCondition",
		fulfillmentNotes:"FulfillmentNoteWhere",
		doubleshotTotalWeightGrams:"IntCondition",
		doubleShotLegacyNumber:"StringCondition",
		doubleshotLastUpdatedAt:"DateTimeCondition",
		doubleshotAdminUrl:"StringCondition",
		and:"OrderWhere",
		or:"OrderWhere",
		not:"OrderWhere"
	},
	OrderStateCondition:{
		and:"OrderStateCondition",
		or:"OrderStateCondition",
		not:"OrderStateCondition",
		eq:"OrderState",
		notEq:"OrderState",
		in:"OrderState",
		notIn:"OrderState",
		lt:"OrderState",
		lte:"OrderState",
		gt:"OrderState",
		gte:"OrderState"
	},
	OrderState: true,
	OrderDiscountWhere:{
		id:"UUIDCondition",
		discountCents:"IntCondition",
		vatRate:"VatRateWhere",
		order:"OrderWhere",
		description:"StringCondition",
		type:"OrderDiscountTypeCondition",
		voucherRedemption:"VoucherRedemptionWhere",
		and:"OrderDiscountWhere",
		or:"OrderDiscountWhere",
		not:"OrderDiscountWhere"
	},
	OrderDiscountTypeCondition:{
		and:"OrderDiscountTypeCondition",
		or:"OrderDiscountTypeCondition",
		not:"OrderDiscountTypeCondition",
		eq:"OrderDiscountType",
		notEq:"OrderDiscountType",
		in:"OrderDiscountType",
		notIn:"OrderDiscountType",
		lt:"OrderDiscountType",
		lte:"OrderDiscountType",
		gt:"OrderDiscountType",
		gte:"OrderDiscountType"
	},
	OrderDiscountType: true,
	VoucherRedemptionWhere:{
		id:"UUIDCondition",
		createdAt:"DateTimeCondition",
		note:"StringCondition",
		manualRedemptionNote:"StringCondition",
		customerCredit:"CustomerCreditWhere",
		orderDiscount:"OrderDiscountWhere",
		voucher:"VoucherWhere",
		customer:"CustomerWhere",
		and:"VoucherRedemptionWhere",
		or:"VoucherRedemptionWhere",
		not:"VoucherRedemptionWhere"
	},
	CustomerCreditWhere:{
		id:"UUIDCondition",
		initialCreditCents:"IntCondition",
		remainingCreditsCents:"IntCondition",
		expiresAt:"DateTimeCondition",
		reason:"StringCondition",
		customer:"CustomerWhere",
		vatRate:"VatRateWhere",
		createdAt:"DateTimeCondition",
		voucherRedemption:"VoucherRedemptionWhere",
		invoiceUrl:"StringCondition",
		and:"CustomerCreditWhere",
		or:"CustomerCreditWhere",
		not:"CustomerCreditWhere"
	},
	VoucherWhere:{
		id:"UUIDCondition",
		createdAt:"DateTimeCondition",
		activatedAt:"DateTimeCondition",
		expiresAt:"DateTimeCondition",
		note:"StringCondition",
		code:"StringCondition",
		type:"VoucherTypeCondition",
		creditCents:"IntCondition",
		buyer:"CustomerWhere",
		owner:"CustomerWhere",
		vatRate:"VatRateWhere",
		virtualProductEffect:"VirtualProductEffectWhere",
		redemption:"VoucherRedemptionWhere",
		and:"VoucherWhere",
		or:"VoucherWhere",
		not:"VoucherWhere"
	},
	VoucherTypeCondition:{
		and:"VoucherTypeCondition",
		or:"VoucherTypeCondition",
		not:"VoucherTypeCondition",
		eq:"VoucherType",
		notEq:"VoucherType",
		in:"VoucherType",
		notIn:"VoucherType",
		lt:"VoucherType",
		lte:"VoucherType",
		gt:"VoucherType",
		gte:"VoucherType"
	},
	VoucherType: true,
	VirtualProductEffectWhere:{
		id:"UUIDCondition",
		createdAt:"DateTimeCondition",
		succeededAt:"DateTimeCondition",
		orderItem:"OrderItemWhere",
		failedAt:"DateTimeCondition",
		revertedAt:"DateTimeCondition",
		resultVouchers:"VoucherWhere",
		and:"VirtualProductEffectWhere",
		or:"VirtualProductEffectWhere",
		not:"VirtualProductEffectWhere"
	},
	OrderDeliveryWhere:{
		id:"UUIDCondition",
		priceCents:"IntCondition",
		deliveredAt:"DateTimeCondition",
		method:"DeliveryMethodWhere",
		vatRate:"VatRateWhere",
		order:"OrderWhere",
		expectedAt:"DateTimeCondition",
		state:"OrderDeliveryStateCondition",
		loadingDurationHours:"IntCondition",
		dispatchAt:"DateTimeCondition",
		duration:"StringCondition",
		window:"StringCondition",
		expectedEndAt:"DateTimeCondition",
		processingAt:"DateTimeCondition",
		deliveringAt:"DateTimeCondition",
		failedAt:"DateTimeCondition",
		internalNote:"StringCondition",
		prepareAt:"DateTimeCondition",
		pickedAt:"DateTimeCondition",
		address:"AddressWhere",
		trackingCode:"StringCondition",
		and:"OrderDeliveryWhere",
		or:"OrderDeliveryWhere",
		not:"OrderDeliveryWhere"
	},
	OrderDeliveryStateCondition:{
		and:"OrderDeliveryStateCondition",
		or:"OrderDeliveryStateCondition",
		not:"OrderDeliveryStateCondition",
		eq:"OrderDeliveryState",
		notEq:"OrderDeliveryState",
		in:"OrderDeliveryState",
		notIn:"OrderDeliveryState",
		lt:"OrderDeliveryState",
		lte:"OrderDeliveryState",
		gt:"OrderDeliveryState",
		gte:"OrderDeliveryState"
	},
	OrderDeliveryState: true,
	OrderPriceLineWhere:{
		id:"UUIDCondition",
		unitPriceCents:"IntCondition",
		quantity:"IntCondition",
		type:"OrderPriceLineTypeCondition",
		orderItem:"OrderItemWhere",
		orderDiscount:"OrderDiscountWhere",
		orderPayment:"OrderPaymentWhere",
		orderDelivery:"OrderDeliveryWhere",
		vatRatePermille:"IntCondition",
		vatRate:"VatRateWhere",
		order:"OrderWhere",
		and:"OrderPriceLineWhere",
		or:"OrderPriceLineWhere",
		not:"OrderPriceLineWhere"
	},
	OrderPriceLineTypeCondition:{
		and:"OrderPriceLineTypeCondition",
		or:"OrderPriceLineTypeCondition",
		not:"OrderPriceLineTypeCondition",
		eq:"OrderPriceLineType",
		notEq:"OrderPriceLineType",
		in:"OrderPriceLineType",
		notIn:"OrderPriceLineType",
		lt:"OrderPriceLineType",
		lte:"OrderPriceLineType",
		gt:"OrderPriceLineType",
		gte:"OrderPriceLineType"
	},
	OrderPriceLineType: true,
	OrderPaymentWhere:{
		id:"UUIDCondition",
		data:"JsonCondition",
		paymentCents:"IntCondition",
		method:"PaymentMethodWhere",
		failedAt:"DateTimeCondition",
		state:"OrderPaymentStateCondition",
		approvedAt:"DateTimeCondition",
		order:"OrderWhere",
		invoice:"InvoiceWhere",
		walletType:"StringCondition",
		createdAt:"DateTimeCondition",
		publicReceiptUrl:"StringCondition",
		priceCents:"IntCondition",
		vatRate:"VatRateWhere",
		meta:"OrderPaymentMetadataWhere",
		otherCurrencyPriceCents:"StringCondition",
		and:"OrderPaymentWhere",
		or:"OrderPaymentWhere",
		not:"OrderPaymentWhere"
	},
	JsonCondition:{
		and:"JsonCondition",
		or:"JsonCondition",
		not:"JsonCondition"
	},
	OrderPaymentStateCondition:{
		and:"OrderPaymentStateCondition",
		or:"OrderPaymentStateCondition",
		not:"OrderPaymentStateCondition",
		eq:"OrderPaymentState",
		notEq:"OrderPaymentState",
		in:"OrderPaymentState",
		notIn:"OrderPaymentState",
		lt:"OrderPaymentState",
		lte:"OrderPaymentState",
		gt:"OrderPaymentState",
		gte:"OrderPaymentState"
	},
	OrderPaymentState: true,
	InvoiceWhere:{
		id:"UUIDCondition",
		createdAt:"DateTimeCondition",
		publicKey:"StringCondition",
		fakturoidId:"StringCondition",
		fakturoidData:"StringCondition",
		legacyUrl:"StringCondition",
		customer:"CustomerWhere",
		orderPayments:"OrderPaymentWhere",
		address:"AddressWhere",
		publicUrl:"StringCondition",
		billingSubject:"BillingSubjectWhere",
		and:"InvoiceWhere",
		or:"InvoiceWhere",
		not:"InvoiceWhere"
	},
	BillingSubjectWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		fullName:"StringCondition",
		addressLine1:"StringCondition",
		addressLine2:"StringCondition",
		city:"StringCondition",
		postalCode:"StringCondition",
		email:"StringCondition",
		phone:"StringCondition",
		companyName:"StringCondition",
		companyIdentifier:"StringCondition",
		vatIdentifier:"StringCondition",
		externalIdentifier:"StringCondition",
		hidden:"BooleanCondition",
		createdAt:"DateTimeCondition",
		deletedAt:"DateTimeCondition",
		note:"StringCondition",
		fakturoidSubjectId:"StringCondition",
		country:"CountryWhere",
		customer:"CustomerWhere",
		invoices:"InvoiceWhere",
		defaultBillingSubjectOfCustomer:"CustomerWhere",
		orders:"OrderWhere",
		and:"BillingSubjectWhere",
		or:"BillingSubjectWhere",
		not:"BillingSubjectWhere"
	},
	CountryWhere:{
		id:"UUIDCondition",
		code:"StringCondition",
		order:"IntCondition",
		and:"CountryWhere",
		or:"CountryWhere",
		not:"CountryWhere"
	},
	OrderPaymentMetadataWhere:{
		id:"UUIDCondition",
		paymentUrl:"StringCondition",
		orderPayment:"OrderPaymentWhere",
		and:"OrderPaymentMetadataWhere",
		or:"OrderPaymentMetadataWhere",
		not:"OrderPaymentMetadataWhere"
	},
	OrderSummaryWhere:{
		id:"UUIDCondition",
		totalPriceCents:"IntCondition",
		discountPriceCents:"IntCondition",
		undiscountedPriceCents:"IntCondition",
		itemsQuantity:"IntCondition",
		orderUrl:"StringCondition",
		orderNumber:"StringCondition",
		order:"OrderWhere",
		and:"OrderSummaryWhere",
		or:"OrderSummaryWhere",
		not:"OrderSummaryWhere"
	},
	CartStateCondition:{
		and:"CartStateCondition",
		or:"CartStateCondition",
		not:"CartStateCondition",
		eq:"CartState",
		notEq:"CartState",
		in:"CartState",
		notIn:"CartState",
		lt:"CartState",
		lte:"CartState",
		gt:"CartState",
		gte:"CartState"
	},
	CartState: true,
	CartPriceLineWhere:{
		id:"UUIDCondition",
		type:"CartPriceLineTypeCondition",
		item:"CartItemWhere",
		product:"ProductWhere",
		unitPriceCents:"IntCondition",
		priceCents:"IntCondition",
		quantity:"IntCondition",
		vatRatePermille:"IntCondition",
		vatRate:"VatRateWhere",
		cart:"CartWhere",
		and:"CartPriceLineWhere",
		or:"CartPriceLineWhere",
		not:"CartPriceLineWhere"
	},
	CartPriceLineTypeCondition:{
		and:"CartPriceLineTypeCondition",
		or:"CartPriceLineTypeCondition",
		not:"CartPriceLineTypeCondition",
		eq:"CartPriceLineType",
		notEq:"CartPriceLineType",
		in:"CartPriceLineType",
		notIn:"CartPriceLineType",
		lt:"CartPriceLineType",
		lte:"CartPriceLineType",
		gt:"CartPriceLineType",
		gte:"CartPriceLineType"
	},
	CartPriceLineType: true,
	CartSummaryWhere:{
		id:"UUIDCondition",
		itemsPriceCents:"IntCondition",
		deliveryPriceCents:"IntCondition",
		totalPriceCents:"IntCondition",
		itemsCount:"IntCondition",
		itemsQuantity:"IntCondition",
		checkoutUrl:"StringCondition",
		cart:"CartWhere",
		and:"CartSummaryWhere",
		or:"CartSummaryWhere",
		not:"CartSummaryWhere"
	},
	ChannelCodeCondition:{
		and:"ChannelCodeCondition",
		or:"ChannelCodeCondition",
		not:"ChannelCodeCondition",
		eq:"ChannelCode",
		notEq:"ChannelCode",
		in:"ChannelCode",
		notIn:"ChannelCode",
		lt:"ChannelCode",
		lte:"ChannelCode",
		gt:"ChannelCode",
		gte:"ChannelCode"
	},
	ChannelCode: true,
	VatRatePresetCondition:{
		and:"VatRatePresetCondition",
		or:"VatRatePresetCondition",
		not:"VatRatePresetCondition",
		eq:"VatRatePreset",
		notEq:"VatRatePreset",
		in:"VatRatePreset",
		notIn:"VatRatePreset",
		lt:"VatRatePreset",
		lte:"VatRatePreset",
		gt:"VatRatePreset",
		gte:"VatRatePreset"
	},
	VatRatePreset: true,
	BlogLocaleWhere:{
		id:"UUIDCondition",
		pageName:"StringCondition",
		title:"StringCondition",
		lead:"StringCondition",
		root:"BlogWhere",
		locale:"LocaleWhere",
		and:"BlogLocaleWhere",
		or:"BlogLocaleWhere",
		not:"BlogLocaleWhere"
	},
	BlogWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"BlogLocaleWhere",
		and:"BlogWhere",
		or:"BlogWhere",
		not:"BlogWhere"
	},
	BlogPostLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		availableForWeb:"BooleanCondition",
		availableForMobile:"BooleanCondition",
		root:"BlogPostWhere",
		locale:"LocaleWhere",
		cover:"CoverWhere",
		content:"ContentWhere",
		link:"LinkableWhere",
		seo:"SeoWhere",
		products:"ProductWhere",
		and:"BlogPostLocaleWhere",
		or:"BlogPostLocaleWhere",
		not:"BlogPostLocaleWhere"
	},
	BlogPostWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		locales:"BlogPostLocaleWhere",
		and:"BlogPostWhere",
		or:"BlogPostWhere",
		not:"BlogPostWhere"
	},
	CoverWhere:{
		id:"UUIDCondition",
		primaryTitle:"StringCondition",
		secondaryTitle:"StringCondition",
		medium:"MediumWhere",
		buttonColors:"ColorPalleteWhere",
		and:"CoverWhere",
		or:"CoverWhere",
		not:"CoverWhere"
	},
	MediumWhere:{
		id:"UUIDCondition",
		type:"MediumTypeCondition",
		colorTheme:"ColorThemeCondition",
		image:"ImageWhere",
		video:"VideoWhere",
		and:"MediumWhere",
		or:"MediumWhere",
		not:"MediumWhere"
	},
	MediumTypeCondition:{
		and:"MediumTypeCondition",
		or:"MediumTypeCondition",
		not:"MediumTypeCondition",
		eq:"MediumType",
		notEq:"MediumType",
		in:"MediumType",
		notIn:"MediumType",
		lt:"MediumType",
		lte:"MediumType",
		gt:"MediumType",
		gte:"MediumType"
	},
	MediumType: true,
	ColorThemeCondition:{
		and:"ColorThemeCondition",
		or:"ColorThemeCondition",
		not:"ColorThemeCondition",
		eq:"ColorTheme",
		notEq:"ColorTheme",
		in:"ColorTheme",
		notIn:"ColorTheme",
		lt:"ColorTheme",
		lte:"ColorTheme",
		gt:"ColorTheme",
		gte:"ColorTheme"
	},
	ColorTheme: true,
	VideoWhere:{
		id:"UUIDCondition",
		src:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		duration:"FloatCondition",
		poster:"ImageWhere",
		and:"VideoWhere",
		or:"VideoWhere",
		not:"VideoWhere"
	},
	ColorPalleteWhere:{
		id:"UUIDCondition",
		background:"StringCondition",
		text:"StringCondition",
		and:"ColorPalleteWhere",
		or:"ColorPalleteWhere",
		not:"ColorPalleteWhere"
	},
	ContentWhere:{
		id:"UUIDCondition",
		blocks:"ContentBlockWhere",
		and:"ContentWhere",
		or:"ContentWhere",
		not:"ContentWhere"
	},
	ContentBlockWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		json:"StringCondition",
		content:"ContentWhere",
		references:"ContentReferenceWhere",
		and:"ContentBlockWhere",
		or:"ContentBlockWhere",
		not:"ContentBlockWhere"
	},
	ContentReferenceWhere:{
		id:"UUIDCondition",
		type:"ContentReferenceTypeCondition",
		primaryText:"StringCondition",
		secondaryText:"StringCondition",
		jsonContent:"StringCondition",
		block:"ContentBlockWhere",
		colorPallete:"ColorPalleteWhere",
		medium:"MediumWhere",
		link:"LinkWhere",
		blogPosts:"BlogPostListWhere",
		links:"LinkListWhere",
		products:"ProductListWhere",
		contentSize:"ContentSizeCondition",
		misc:"StringCondition",
		hero:"HeroWhere",
		gallery:"GalleryWhere",
		socialsAndApps:"SocialsAndAppsWhere",
		productGrid:"ProductGridWhere",
		productBanners:"ProductBannerListWhere",
		deliveryRegions:"DeliveryRegionsWhere",
		textList:"TextListWhere",
		gridTiles:"GridTilesWhere",
		and:"ContentReferenceWhere",
		or:"ContentReferenceWhere",
		not:"ContentReferenceWhere"
	},
	ContentReferenceTypeCondition:{
		and:"ContentReferenceTypeCondition",
		or:"ContentReferenceTypeCondition",
		not:"ContentReferenceTypeCondition",
		eq:"ContentReferenceType",
		notEq:"ContentReferenceType",
		in:"ContentReferenceType",
		notIn:"ContentReferenceType",
		lt:"ContentReferenceType",
		lte:"ContentReferenceType",
		gt:"ContentReferenceType",
		gte:"ContentReferenceType"
	},
	ContentReferenceType: true,
	LinkWhere:{
		id:"UUIDCondition",
		type:"LinkTypeCondition",
		title:"StringCondition",
		externalLink:"StringCondition",
		internalLink:"LinkableWhere",
		and:"LinkWhere",
		or:"LinkWhere",
		not:"LinkWhere"
	},
	LinkTypeCondition:{
		and:"LinkTypeCondition",
		or:"LinkTypeCondition",
		not:"LinkTypeCondition",
		eq:"LinkType",
		notEq:"LinkType",
		in:"LinkType",
		notIn:"LinkType",
		lt:"LinkType",
		lte:"LinkType",
		gt:"LinkType",
		gte:"LinkType"
	},
	LinkType: true,
	LinkableWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		blogPost:"BlogPostLocaleWhere",
		page:"PageLocaleWhere",
		redirect:"RedirectWhere",
		and:"LinkableWhere",
		or:"LinkableWhere",
		not:"LinkableWhere"
	},
	PageLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		link:"LinkableWhere",
		root:"PageWhere",
		locale:"LocaleWhere",
		seo:"SeoWhere",
		content:"ContentWhere",
		cover:"CoverWhere",
		theme:"PageThemeCondition",
		and:"PageLocaleWhere",
		or:"PageLocaleWhere",
		not:"PageLocaleWhere"
	},
	PageWhere:{
		id:"UUIDCondition",
		locales:"PageLocaleWhere",
		and:"PageWhere",
		or:"PageWhere",
		not:"PageWhere"
	},
	SeoWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		description:"StringCondition",
		ogTitle:"StringCondition",
		ogDescription:"StringCondition",
		ogImage:"ImageWhere",
		and:"SeoWhere",
		or:"SeoWhere",
		not:"SeoWhere"
	},
	PageThemeCondition:{
		and:"PageThemeCondition",
		or:"PageThemeCondition",
		not:"PageThemeCondition",
		eq:"PageTheme",
		notEq:"PageTheme",
		in:"PageTheme",
		notIn:"PageTheme",
		lt:"PageTheme",
		lte:"PageTheme",
		gt:"PageTheme",
		gte:"PageTheme"
	},
	PageTheme: true,
	RedirectWhere:{
		id:"UUIDCondition",
		link:"LinkableWhere",
		target:"LinkWhere",
		and:"RedirectWhere",
		or:"RedirectWhere",
		not:"RedirectWhere"
	},
	BlogPostListWhere:{
		id:"UUIDCondition",
		items:"BlogPostListItemWhere",
		and:"BlogPostListWhere",
		or:"BlogPostListWhere",
		not:"BlogPostListWhere"
	},
	BlogPostListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		caption:"StringCondition",
		list:"BlogPostListWhere",
		blogPost:"BlogPostLocaleWhere",
		and:"BlogPostListItemWhere",
		or:"BlogPostListItemWhere",
		not:"BlogPostListItemWhere"
	},
	LinkListWhere:{
		id:"UUIDCondition",
		items:"LinkListItemWhere",
		and:"LinkListWhere",
		or:"LinkListWhere",
		not:"LinkListWhere"
	},
	LinkListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		isPrimary:"BooleanCondition",
		list:"LinkListWhere",
		link:"LinkWhere",
		and:"LinkListItemWhere",
		or:"LinkListItemWhere",
		not:"LinkListItemWhere"
	},
	ContentSizeCondition:{
		and:"ContentSizeCondition",
		or:"ContentSizeCondition",
		not:"ContentSizeCondition",
		eq:"ContentSize",
		notEq:"ContentSize",
		in:"ContentSize",
		notIn:"ContentSize",
		lt:"ContentSize",
		lte:"ContentSize",
		gt:"ContentSize",
		gte:"ContentSize"
	},
	ContentSize: true,
	HeroWhere:{
		id:"UUIDCondition",
		showLogo:"BooleanCondition",
		middleContent:"StringCondition",
		medium:"MediumWhere",
		and:"HeroWhere",
		or:"HeroWhere",
		not:"HeroWhere"
	},
	GalleryWhere:{
		id:"UUIDCondition",
		items:"GalleryItemWhere",
		and:"GalleryWhere",
		or:"GalleryWhere",
		not:"GalleryWhere"
	},
	GalleryItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		caption:"StringCondition",
		list:"GalleryWhere",
		medium:"MediumWhere",
		and:"GalleryItemWhere",
		or:"GalleryItemWhere",
		not:"GalleryItemWhere"
	},
	SocialsAndAppsWhere:{
		id:"UUIDCondition",
		instagram:"BooleanCondition",
		appStore:"BooleanCondition",
		googlePlay:"BooleanCondition",
		and:"SocialsAndAppsWhere",
		or:"SocialsAndAppsWhere",
		not:"SocialsAndAppsWhere"
	},
	ProductGridWhere:{
		id:"UUIDCondition",
		isCarousel:"BooleanCondition",
		categories:"ProductGridCategoryWhere",
		type:"ProductGridTypeCondition",
		and:"ProductGridWhere",
		or:"ProductGridWhere",
		not:"ProductGridWhere"
	},
	ProductGridCategoryWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		root:"ProductGridWhere",
		items:"ProductGridItemWhere",
		images:"ImageListWhere",
		and:"ProductGridCategoryWhere",
		or:"ProductGridCategoryWhere",
		not:"ProductGridCategoryWhere"
	},
	ProductGridItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		lead:"StringCondition",
		product:"ProductWhere",
		medium:"MediumWhere",
		link:"LinkWhere",
		category:"ProductGridCategoryWhere",
		description:"StringCondition",
		and:"ProductGridItemWhere",
		or:"ProductGridItemWhere",
		not:"ProductGridItemWhere"
	},
	ImageListWhere:{
		id:"UUIDCondition",
		items:"ImageListItemWhere",
		and:"ImageListWhere",
		or:"ImageListWhere",
		not:"ImageListWhere"
	},
	ImageListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ImageListWhere",
		image:"ImageWhere",
		and:"ImageListItemWhere",
		or:"ImageListItemWhere",
		not:"ImageListItemWhere"
	},
	ProductGridTypeCondition:{
		and:"ProductGridTypeCondition",
		or:"ProductGridTypeCondition",
		not:"ProductGridTypeCondition",
		eq:"ProductGridType",
		notEq:"ProductGridType",
		in:"ProductGridType",
		notIn:"ProductGridType",
		lt:"ProductGridType",
		lte:"ProductGridType",
		gt:"ProductGridType",
		gte:"ProductGridType"
	},
	ProductGridType: true,
	ProductBannerListWhere:{
		id:"UUIDCondition",
		items:"ProductBannerItemWhere",
		and:"ProductBannerListWhere",
		or:"ProductBannerListWhere",
		not:"ProductBannerListWhere"
	},
	ProductBannerItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProductBannerListWhere",
		banner:"ProductBannerWhere",
		size:"ProductBannerItemSizeCondition",
		and:"ProductBannerItemWhere",
		or:"ProductBannerItemWhere",
		not:"ProductBannerItemWhere"
	},
	ProductBannerWhere:{
		id:"UUIDCondition",
		beforeTitle:"StringCondition",
		title:"StringCondition",
		label:"StringCondition",
		button:"LinkWhere",
		backgroundImage:"ImageWhere",
		productImage:"ImageWhere",
		product:"ProductWhere",
		and:"ProductBannerWhere",
		or:"ProductBannerWhere",
		not:"ProductBannerWhere"
	},
	ProductBannerItemSizeCondition:{
		and:"ProductBannerItemSizeCondition",
		or:"ProductBannerItemSizeCondition",
		not:"ProductBannerItemSizeCondition",
		eq:"ProductBannerItemSize",
		notEq:"ProductBannerItemSize",
		in:"ProductBannerItemSize",
		notIn:"ProductBannerItemSize",
		lt:"ProductBannerItemSize",
		lte:"ProductBannerItemSize",
		gt:"ProductBannerItemSize",
		gte:"ProductBannerItemSize"
	},
	ProductBannerItemSize: true,
	DeliveryRegionsWhere:{
		id:"UUIDCondition",
		items:"DeliveryRegionsItemWhere",
		and:"DeliveryRegionsWhere",
		or:"DeliveryRegionsWhere",
		not:"DeliveryRegionsWhere"
	},
	DeliveryRegionsItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		offerLabel:"StringCondition",
		list:"DeliveryRegionsWhere",
		image:"ImageWhere",
		textList:"TextListWhere",
		gallery:"ImageListWhere",
		and:"DeliveryRegionsItemWhere",
		or:"DeliveryRegionsItemWhere",
		not:"DeliveryRegionsItemWhere"
	},
	TextListWhere:{
		id:"UUIDCondition",
		items:"TextItemWhere",
		and:"TextListWhere",
		or:"TextListWhere",
		not:"TextListWhere"
	},
	TextItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		text:"StringCondition",
		list:"TextListWhere",
		title:"StringCondition",
		and:"TextItemWhere",
		or:"TextItemWhere",
		not:"TextItemWhere"
	},
	GridTilesWhere:{
		id:"UUIDCondition",
		items:"GridTileItemWhere",
		and:"GridTilesWhere",
		or:"GridTilesWhere",
		not:"GridTilesWhere"
	},
	GridTileItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		text:"StringCondition",
		list:"GridTilesWhere",
		medium:"MediumWhere",
		link:"LinkWhere",
		and:"GridTileItemWhere",
		or:"GridTileItemWhere",
		not:"GridTileItemWhere"
	},
	TranslationCataloguesIdentifierWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		catalogue:"TranslationCatalogueWhere",
		and:"TranslationCataloguesIdentifierWhere",
		or:"TranslationCataloguesIdentifierWhere",
		not:"TranslationCataloguesIdentifierWhere"
	},
	TranslationCatalogueWhere:{
		id:"UUIDCondition",
		domain:"TranslationDomainWhere",
		fallback:"TranslationCatalogueWhere",
		values:"TranslationValueWhere",
		identifier:"TranslationCataloguesIdentifierWhere",
		and:"TranslationCatalogueWhere",
		or:"TranslationCatalogueWhere",
		not:"TranslationCatalogueWhere"
	},
	TranslationDomainWhere:{
		id:"UUIDCondition",
		identifier:"StringCondition",
		name:"StringCondition",
		catalogues:"TranslationCatalogueWhere",
		keys:"TranslationKeyWhere",
		and:"TranslationDomainWhere",
		or:"TranslationDomainWhere",
		not:"TranslationDomainWhere"
	},
	TranslationKeyWhere:{
		id:"UUIDCondition",
		identifier:"StringCondition",
		contentType:"TranslationContentTypeCondition",
		note:"StringCondition",
		domain:"TranslationDomainWhere",
		values:"TranslationValueWhere",
		and:"TranslationKeyWhere",
		or:"TranslationKeyWhere",
		not:"TranslationKeyWhere"
	},
	TranslationContentTypeCondition:{
		and:"TranslationContentTypeCondition",
		or:"TranslationContentTypeCondition",
		not:"TranslationContentTypeCondition",
		eq:"TranslationContentType",
		notEq:"TranslationContentType",
		in:"TranslationContentType",
		notIn:"TranslationContentType",
		lt:"TranslationContentType",
		lte:"TranslationContentType",
		gt:"TranslationContentType",
		gte:"TranslationContentType"
	},
	TranslationContentType: true,
	TranslationValueWhere:{
		id:"UUIDCondition",
		value:"StringCondition",
		catalogue:"TranslationCatalogueWhere",
		key:"TranslationKeyWhere",
		and:"TranslationValueWhere",
		or:"TranslationValueWhere",
		not:"TranslationValueWhere"
	},
	ProductLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		description:"StringCondition",
		root:"ProductWhere",
		locale:"LocaleWhere",
		featureList:"TextListWhere",
		printableName:"StringCondition",
		firstSticker:"StringCondition",
		secondSticker:"StringCondition",
		and:"ProductLocaleWhere",
		or:"ProductLocaleWhere",
		not:"ProductLocaleWhere"
	},
	ProductRecipeLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductRecipeWhere",
		locale:"LocaleWhere",
		description:"StringCondition",
		and:"ProductRecipeLocaleWhere",
		or:"ProductRecipeLocaleWhere",
		not:"ProductRecipeLocaleWhere"
	},
	ProductRecipeWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		products:"ProductWhere",
		internalNote:"StringCondition",
		color:"StringCondition",
		image:"ImageWhere",
		icon:"ImageWhere",
		ingredients:"ProductIngredientItemWhere",
		needsPrepping:"BooleanCondition",
		locales:"ProductRecipeLocaleWhere",
		gutTuning:"FloatCondition",
		freshingContainer:"FreshingContainerWhere",
		description:"StringCondition",
		and:"ProductRecipeWhere",
		or:"ProductRecipeWhere",
		not:"ProductRecipeWhere"
	},
	ProductIngredientItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		internalOrder:"IntCondition",
		recipe:"ProductRecipeWhere",
		ingredient:"ProductIngredientWhere",
		and:"ProductIngredientItemWhere",
		or:"ProductIngredientItemWhere",
		not:"ProductIngredientItemWhere"
	},
	IdentificationCodeLocaleWhere:{
		id:"UUIDCondition",
		successMessage:"StringCondition",
		root:"IdentificationCodeWhere",
		locale:"LocaleWhere",
		and:"IdentificationCodeLocaleWhere",
		or:"IdentificationCodeLocaleWhere",
		not:"IdentificationCodeLocaleWhere"
	},
	IdentificationCodeWhere:{
		id:"UUIDCondition",
		code:"StringCondition",
		createdAt:"DateTimeCondition",
		locales:"IdentificationCodeLocaleWhere",
		internalName:"StringCondition",
		and:"IdentificationCodeWhere",
		or:"IdentificationCodeWhere",
		not:"IdentificationCodeWhere"
	},
	SubscriptionBoxLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		stickerText:"StringCondition",
		descriptionText:"StringCondition",
		root:"SubscriptionBoxWhere",
		locale:"LocaleWhere",
		secondaryTitle:"StringCondition",
		texts:"TextListWhere",
		secondaryTexts:"TextListWhere",
		and:"SubscriptionBoxLocaleWhere",
		or:"SubscriptionBoxLocaleWhere",
		not:"SubscriptionBoxLocaleWhere"
	},
	SubscriptionBoxWhere:{
		id:"UUIDCondition",
		locales:"SubscriptionBoxLocaleWhere",
		unique:"OneCondition",
		and:"SubscriptionBoxWhere",
		or:"SubscriptionBoxWhere",
		not:"SubscriptionBoxWhere"
	},
	ProductStockWhere:{
		id:"UUIDCondition",
		quantity:"IntCondition",
		product:"ProductWhere",
		store:"StoreWhere",
		and:"ProductStockWhere",
		or:"ProductStockWhere",
		not:"ProductStockWhere"
	},
	StoreWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		description:"StringCondition",
		internalNote:"StringCondition",
		and:"StoreWhere",
		or:"StoreWhere",
		not:"StoreWhere"
	},
	ProductGroupPriceWhere:{
		id:"UUIDCondition",
		priceCents:"IntCondition",
		group:"CustomerGroupWhere",
		product:"ProductWhere",
		and:"ProductGroupPriceWhere",
		or:"ProductGroupPriceWhere",
		not:"ProductGroupPriceWhere"
	},
	CustomerGroupWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		productPrices:"ProductGroupPriceWhere",
		customers:"CustomerWhere",
		discountPermille:"IntCondition",
		and:"CustomerGroupWhere",
		or:"CustomerGroupWhere",
		not:"CustomerGroupWhere"
	},
	ProductAvailabilityCondition:{
		and:"ProductAvailabilityCondition",
		or:"ProductAvailabilityCondition",
		not:"ProductAvailabilityCondition",
		eq:"ProductAvailability",
		notEq:"ProductAvailability",
		in:"ProductAvailability",
		notIn:"ProductAvailability",
		lt:"ProductAvailability",
		lte:"ProductAvailability",
		gt:"ProductAvailability",
		gte:"ProductAvailability"
	},
	ProductAvailability: true,
	ProductCustomerPriceWhere:{
		id:"UUIDCondition",
		groupDiscountPermille:"IntCondition",
		groupPriceCents:"IntCondition",
		fullPriceCents:"IntCondition",
		discountedPriceCents:"IntCondition",
		priceCents:"IntCondition",
		offered:"BooleanCondition",
		notDiscountable:"BooleanCondition",
		customer:"CustomerWhere",
		product:"ProductWhere",
		and:"ProductCustomerPriceWhere",
		or:"ProductCustomerPriceWhere",
		not:"ProductCustomerPriceWhere"
	},
	ProductMetadataWhere:{
		id:"UUIDCondition",
		archived:"BooleanCondition",
		available:"BooleanCondition",
		availableQuantity:"IntCondition",
		product:"ProductWhere",
		and:"ProductMetadataWhere",
		or:"ProductMetadataWhere",
		not:"ProductMetadataWhere"
	},
	CustomerPermissionsWhere:{
		id:"UUIDCondition",
		canPlaceOrder:"BooleanCondition",
		customer:"CustomerWhere",
		and:"CustomerPermissionsWhere",
		or:"CustomerPermissionsWhere",
		not:"CustomerPermissionsWhere"
	},
	CustomerNameWhere:{
		id:"UUIDCondition",
		nominative:"StringCondition",
		vocative:"StringCondition",
		customer:"CustomerWhere",
		and:"CustomerNameWhere",
		or:"CustomerNameWhere",
		not:"CustomerNameWhere"
	},
	CustomerFamiliarityCondition:{
		and:"CustomerFamiliarityCondition",
		or:"CustomerFamiliarityCondition",
		not:"CustomerFamiliarityCondition",
		eq:"CustomerFamiliarity",
		notEq:"CustomerFamiliarity",
		in:"CustomerFamiliarity",
		notIn:"CustomerFamiliarity",
		lt:"CustomerFamiliarity",
		lte:"CustomerFamiliarity",
		gt:"CustomerFamiliarity",
		gte:"CustomerFamiliarity"
	},
	CustomerFamiliarity: true,
	UserWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		staff:"StaffWhere",
		customer:"CustomerWhere",
		tenantPersonId:"UUIDCondition",
		disabledAt:"DateTimeCondition",
		verifiedAt:"DateTimeCondition",
		email:"StringCondition",
		createdAt:"DateTimeCondition",
		deletionRequestedAt:"DateTimeCondition",
		deletionExpectedAt:"DateTimeCondition",
		deletedAt:"DateTimeCondition",
		meta:"UserMetadataWhere",
		and:"UserWhere",
		or:"UserWhere",
		not:"UserWhere"
	},
	StaffWhere:{
		id:"UUIDCondition",
		user:"UserWhere",
		photo:"ImageWhere",
		createdAt:"DateTimeCondition",
		and:"StaffWhere",
		or:"StaffWhere",
		not:"StaffWhere"
	},
	UserMetadataWhere:{
		id:"UUIDCondition",
		userProfileUrl:"StringCondition",
		tenantEmail:"StringCondition",
		tenantPersonId:"UUIDCondition",
		tenantIdentityId:"UUIDCondition",
		globalRoles:"JsonCondition",
		projectRoles:"JsonCondition",
		projectRolesString:"StringCondition",
		externalIdentifiers:"JsonCondition",
		externalIdentifiersString:"StringCondition",
		idpSlugs:"JsonCondition",
		idpSlugsString:"StringCondition",
		user:"UserWhere",
		and:"UserMetadataWhere",
		or:"UserMetadataWhere",
		not:"UserMetadataWhere"
	},
	InvoicingPlanWhere:{
		id:"UUIDCondition",
		customer:"CustomerWhere",
		preset:"InvoicingPresetWhere",
		and:"InvoicingPlanWhere",
		or:"InvoicingPlanWhere",
		not:"InvoicingPlanWhere"
	},
	InvoicingPresetWhere:{
		id:"UUIDCondition",
		type:"InvoicingPresetTypeCondition",
		startsAt:"DateTimeCondition",
		endsAt:"DateTimeCondition",
		intervalOfDays:"IntCondition",
		invoicingPlan:"InvoicingPlanWhere",
		and:"InvoicingPresetWhere",
		or:"InvoicingPresetWhere",
		not:"InvoicingPresetWhere"
	},
	InvoicingPresetTypeCondition:{
		and:"InvoicingPresetTypeCondition",
		or:"InvoicingPresetTypeCondition",
		not:"InvoicingPresetTypeCondition",
		eq:"InvoicingPresetType",
		notEq:"InvoicingPresetType",
		in:"InvoicingPresetType",
		notIn:"InvoicingPresetType",
		lt:"InvoicingPresetType",
		lte:"InvoicingPresetType",
		gt:"InvoicingPresetType",
		gte:"InvoicingPresetType"
	},
	InvoicingPresetType: true,
	AnonymousSessionWhere:{
		id:"UUIDCondition",
		createdAt:"DateTimeCondition",
		lastActiveAt:"DateTimeCondition",
		anonymousSessionKey:"StringCondition",
		email:"StringCondition",
		name:"StringCondition",
		internalNote:"StringCondition",
		customer:"CustomerWhere",
		and:"AnonymousSessionWhere",
		or:"AnonymousSessionWhere",
		not:"AnonymousSessionWhere"
	},
	CustomerMetadataWhere:{
		id:"UUIDCondition",
		isBusiness:"BooleanCondition",
		name:"StringCondition",
		fallbackName:"StringCondition",
		remainingCreditsCents:"IntCondition",
		ordersTotalCents:"IntCondition",
		customerProfileUrl:"StringCondition",
		customer:"CustomerWhere",
		and:"CustomerMetadataWhere",
		or:"CustomerMetadataWhere",
		not:"CustomerMetadataWhere"
	},
	GpsWhere:{
		id:"UUIDCondition",
		latitude:"FloatCondition",
		longitude:"FloatCondition",
		address:"AddressWhere",
		and:"GpsWhere",
		or:"GpsWhere",
		not:"GpsWhere"
	},
	AddressOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		firstName:"OrderDirection",
		lastName:"OrderDirection",
		addressLine2:"OrderDirection",
		email:"OrderDirection",
		phone:"OrderDirection",
		companyName:"OrderDirection",
		companyIdentifier:"OrderDirection",
		vatIdentifier:"OrderDirection",
		externalIdentifier:"OrderDirection",
		hidden:"OrderDirection",
		customer:"CustomerOrderBy",
		country:"CountryOrderBy",
		deletedAt:"OrderDirection",
		note:"OrderDirection",
		googlePlaceId:"OrderDirection",
		raw:"OrderDirection",
		formatted:"OrderDirection",
		geocodeGeneratedAt:"OrderDirection",
		geocodeResponse:"OrderDirection",
		deliveryZone:"DeliveryZoneOrderBy",
		meta:"AddressMetadataOrderBy",
		gps:"GpsOrderBy",
		geocodeValid:"OrderDirection",
		fullName:"OrderDirection",
		fakturoidSubjectId:"OrderDirection",
		addressLine1:"OrderDirection",
		city:"OrderDirection",
		postalCode:"OrderDirection",
		createdAt:"OrderDirection",
		defaultDeliveryAddressOfCustomer:"CustomerOrderBy"
	},
	OrderDirection: true,
	CustomerOrderBy:{
		id:"OrderDirection",
		defaultBillingAddress:"AddressOrderBy",
		permissions:"CustomerPermissionsOrderBy",
		group:"CustomerGroupOrderBy",
		photo:"ImageOrderBy",
		phone:"OrderDirection",
		name:"CustomerNameOrderBy",
		welcomeMessage:"OrderDirection",
		familiarity:"OrderDirection",
		business:"BusinessCustomerOrderBy",
		user:"UserOrderBy",
		disabledAt:"OrderDirection",
		invoicingPlan:"InvoicingPlanOrderBy",
		email:"OrderDirection",
		createdAt:"OrderDirection",
		defaultDeliveryAddress:"AddressOrderBy",
		defaultBillingSubject:"BillingSubjectOrderBy",
		prefersPackingsWithoutCardboard:"OrderDirection",
		anonymousSession:"AnonymousSessionOrderBy",
		doubleshotLegacyId:"OrderDirection",
		meta:"CustomerMetadataOrderBy",
		doubleshotLastUpdatedAt:"OrderDirection"
	},
	CustomerPermissionsOrderBy:{
		id:"OrderDirection",
		canPlaceOrder:"OrderDirection",
		customer:"CustomerOrderBy"
	},
	CustomerGroupOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		discountPermille:"OrderDirection"
	},
	ImageOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		alt:"OrderDirection"
	},
	CustomerNameOrderBy:{
		id:"OrderDirection",
		nominative:"OrderDirection",
		vocative:"OrderDirection",
		customer:"CustomerOrderBy"
	},
	BusinessCustomerOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		customer:"CustomerOrderBy",
		category:"BusinessCategoryOrderBy",
		logo:"ImageOrderBy",
		note:"OrderDirection"
	},
	BusinessCategoryOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		visibleToNewCustomers:"OrderDirection",
		internalName:"OrderDirection",
		specificProducts:"ProductListOrderBy",
		seasonalProductCategories:"ProductCategoryListOrderBy",
		specificProductsProductCategories:"ProductCategoryListOrderBy",
		slug:"OrderDirection"
	},
	ProductListOrderBy:{
		id:"OrderDirection",
		businessCategory:"BusinessCategoryOrderBy"
	},
	ProductCategoryListOrderBy:{
		id:"OrderDirection",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategoryOrderBy",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategoryOrderBy"
	},
	UserOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		staff:"StaffOrderBy",
		customer:"CustomerOrderBy",
		tenantPersonId:"OrderDirection",
		disabledAt:"OrderDirection",
		verifiedAt:"OrderDirection",
		email:"OrderDirection",
		createdAt:"OrderDirection",
		deletionRequestedAt:"OrderDirection",
		deletionExpectedAt:"OrderDirection",
		deletedAt:"OrderDirection",
		meta:"UserMetadataOrderBy"
	},
	StaffOrderBy:{
		id:"OrderDirection",
		user:"UserOrderBy",
		photo:"ImageOrderBy",
		createdAt:"OrderDirection"
	},
	UserMetadataOrderBy:{
		id:"OrderDirection",
		userProfileUrl:"OrderDirection",
		tenantEmail:"OrderDirection",
		tenantPersonId:"OrderDirection",
		tenantIdentityId:"OrderDirection",
		globalRoles:"OrderDirection",
		projectRoles:"OrderDirection",
		projectRolesString:"OrderDirection",
		externalIdentifiers:"OrderDirection",
		externalIdentifiersString:"OrderDirection",
		idpSlugs:"OrderDirection",
		idpSlugsString:"OrderDirection",
		user:"UserOrderBy"
	},
	InvoicingPlanOrderBy:{
		id:"OrderDirection",
		customer:"CustomerOrderBy",
		preset:"InvoicingPresetOrderBy"
	},
	InvoicingPresetOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		startsAt:"OrderDirection",
		endsAt:"OrderDirection",
		intervalOfDays:"OrderDirection",
		invoicingPlan:"InvoicingPlanOrderBy"
	},
	BillingSubjectOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		fullName:"OrderDirection",
		addressLine1:"OrderDirection",
		addressLine2:"OrderDirection",
		city:"OrderDirection",
		postalCode:"OrderDirection",
		email:"OrderDirection",
		phone:"OrderDirection",
		companyName:"OrderDirection",
		companyIdentifier:"OrderDirection",
		vatIdentifier:"OrderDirection",
		externalIdentifier:"OrderDirection",
		hidden:"OrderDirection",
		createdAt:"OrderDirection",
		deletedAt:"OrderDirection",
		note:"OrderDirection",
		fakturoidSubjectId:"OrderDirection",
		country:"CountryOrderBy",
		customer:"CustomerOrderBy",
		defaultBillingSubjectOfCustomer:"CustomerOrderBy"
	},
	CountryOrderBy:{
		id:"OrderDirection",
		code:"OrderDirection",
		order:"OrderDirection"
	},
	AnonymousSessionOrderBy:{
		id:"OrderDirection",
		createdAt:"OrderDirection",
		lastActiveAt:"OrderDirection",
		anonymousSessionKey:"OrderDirection",
		email:"OrderDirection",
		name:"OrderDirection",
		internalNote:"OrderDirection",
		customer:"CustomerOrderBy"
	},
	CustomerMetadataOrderBy:{
		id:"OrderDirection",
		isBusiness:"OrderDirection",
		name:"OrderDirection",
		fallbackName:"OrderDirection",
		remainingCreditsCents:"OrderDirection",
		ordersTotalCents:"OrderDirection",
		customerProfileUrl:"OrderDirection",
		customer:"CustomerOrderBy"
	},
	DeliveryZoneOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		type:"OrderDirection"
	},
	AddressMetadataOrderBy:{
		id:"OrderDirection",
		deliveryZoneType:"OrderDirection",
		address:"AddressOrderBy",
		deliveryZone:"DeliveryZoneOrderBy"
	},
	GpsOrderBy:{
		id:"OrderDirection",
		latitude:"OrderDirection",
		longitude:"OrderDirection",
		address:"AddressOrderBy"
	},
	Tag:{
		customers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		categories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		addTagsOnRegistration:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		deliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		publicInChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		allowChannelPayments:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		},
		fulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		paginateCustomers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateCategories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateAddTagsOnRegistration:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginatePublicInChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateAllowChannelPayments:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		}
	},
	Product:{
		packing:{
			filter:"ProductPackingWhere"
		},
		recipe:{
			filter:"ProductRecipeWhere"
		},
		tags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		categories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		stocks:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		groupPrices:{
			filter:"ProductGroupPriceWhere",
			orderBy:"ProductGroupPriceOrderBy"
		},
		image:{
			filter:"ImageWhere"
		},
		cartItems:{
			filter:"CartItemWhere",
			orderBy:"CartItemOrderBy"
		},
		orderItems:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		deliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		locales:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		customerPrices:{
			filter:"ProductCustomerPriceWhere",
			orderBy:"ProductCustomerPriceOrderBy"
		},
		virtualProduct:{
			filter:"VirtualProductWhere"
		},
		meta:{
			filter:"ProductMetadataWhere"
		},
		page:{
			filter:"PageWhere"
		},
		fulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		stocksByStore:{
			by:"ProductStocksByStoreUniqueWhere",
			filter:"ProductStockWhere"
		},
		groupPricesByGroup:{
			by:"ProductGroupPricesByGroupUniqueWhere",
			filter:"ProductGroupPriceWhere"
		},
		cartItemsByCart:{
			by:"ProductCartItemsByCartUniqueWhere",
			filter:"CartItemWhere"
		},
		orderItemsByVirtualProductEffects:{
			by:"ProductOrderItemsByVirtualProductEffectsUniqueWhere",
			filter:"OrderItemWhere"
		},
		localesByLocale:{
			by:"ProductLocalesByLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		localesByFeatureList:{
			by:"ProductLocalesByFeatureListUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		customerPricesByCustomer:{
			by:"ProductCustomerPricesByCustomerUniqueWhere",
			filter:"ProductCustomerPriceWhere"
		},
		paginateTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateCategories:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateStocks:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		paginateGroupPrices:{
			filter:"ProductGroupPriceWhere",
			orderBy:"ProductGroupPriceOrderBy"
		},
		paginateCartItems:{
			filter:"CartItemWhere",
			orderBy:"CartItemOrderBy"
		},
		paginateOrderItems:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		paginateDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateLocales:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateCustomerPrices:{
			filter:"ProductCustomerPriceWhere",
			orderBy:"ProductCustomerPriceOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		}
	},
	ProductPacking:{
		image:{
			filter:"ImageWhere"
		},
		icon:{
			filter:"ImageWhere"
		},
		locales:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		localesByLocale:{
			by:"ProductPackingLocalesByLocaleUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		paginateLocales:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		}
	},
	ProductPackingLocale:{
		root:{
			filter:"ProductPackingWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	Locale:{
		businessCategories:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		productCategoryLists:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		productIngredients:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		orderRecurrences:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		blogs:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		blogPosts:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		identifier:{
			filter:"TranslationCataloguesIdentifierWhere"
		},
		pages:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		allergens:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		productPackings:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		products:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		productRecipes:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		deliveryMethods:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		identificationCodes:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		subscriptionBoxes:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		businessCategoriesByRoot:{
			by:"LocaleBusinessCategoriesByRootUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		productCategoryListsByRoot:{
			by:"LocaleProductCategoryListsByRootUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		productIngredientsByRoot:{
			by:"LocaleProductIngredientsByRootUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		orderRecurrencesByRoot:{
			by:"LocaleOrderRecurrencesByRootUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		blogsByRoot:{
			by:"LocaleBlogsByRootUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		blogPostsByRoot:{
			by:"LocaleBlogPostsByRootUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByCover:{
			by:"LocaleBlogPostsByCoverUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByContent:{
			by:"LocaleBlogPostsByContentUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsByLink:{
			by:"LocaleBlogPostsByLinkUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		blogPostsBySeo:{
			by:"LocaleBlogPostsBySeoUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		pagesByRoot:{
			by:"LocalePagesByRootUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesByLink:{
			by:"LocalePagesByLinkUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesBySeo:{
			by:"LocalePagesBySeoUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesByContent:{
			by:"LocalePagesByContentUniqueWhere",
			filter:"PageLocaleWhere"
		},
		pagesByCover:{
			by:"LocalePagesByCoverUniqueWhere",
			filter:"PageLocaleWhere"
		},
		allergensByRoot:{
			by:"LocaleAllergensByRootUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		productPackingsByRoot:{
			by:"LocaleProductPackingsByRootUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		productsByRoot:{
			by:"LocaleProductsByRootUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		productsByFeatureList:{
			by:"LocaleProductsByFeatureListUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		productRecipesByRoot:{
			by:"LocaleProductRecipesByRootUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		deliveryMethodsByRoot:{
			by:"LocaleDeliveryMethodsByRootUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		identificationCodesByRoot:{
			by:"LocaleIdentificationCodesByRootUniqueWhere",
			filter:"IdentificationCodeLocaleWhere"
		},
		subscriptionBoxesByRoot:{
			by:"LocaleSubscriptionBoxesByRootUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		subscriptionBoxesByTexts:{
			by:"LocaleSubscriptionBoxesByTextsUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		subscriptionBoxesBySecondaryTexts:{
			by:"LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		paginateBusinessCategories:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateProductCategoryLists:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		paginateProductIngredients:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateOrderRecurrences:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateBlogs:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		paginateBlogPosts:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginatePages:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		paginateAllergens:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateProductPackings:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		paginateProducts:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductRecipes:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		paginateDeliveryMethods:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		paginateIdentificationCodes:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		paginateSubscriptionBoxes:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		}
	},
	BusinessCategoryLocale:{
		root:{
			filter:"BusinessCategoryWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	BusinessCategory:{
		locales:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		businessCustomers:{
			filter:"BusinessCustomerWhere",
			orderBy:"BusinessCustomerOrderBy"
		},
		recurrences:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		specificProducts:{
			filter:"ProductListWhere"
		},
		seasonalProductCategories:{
			filter:"ProductCategoryListWhere"
		},
		specificProductsProductCategories:{
			filter:"ProductCategoryListWhere"
		},
		localesByLocale:{
			by:"BusinessCategoryLocalesByLocaleUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		businessCustomersByCustomer:{
			by:"BusinessCategoryBusinessCustomersByCustomerUniqueWhere",
			filter:"BusinessCustomerWhere"
		},
		paginateLocales:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateBusinessCustomers:{
			filter:"BusinessCustomerWhere",
			orderBy:"BusinessCustomerOrderBy"
		},
		paginateRecurrences:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		}
	},
	BusinessCategoryLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"BusinessCategoryOrderBy",
		locale:"LocaleOrderBy",
		title:"OrderDirection"
	},
	LocaleOrderBy:{
		id:"OrderDirection",
		identifier:"TranslationCataloguesIdentifierOrderBy"
	},
	TranslationCataloguesIdentifierOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection"
	},
	BusinessCustomer:{
		customer:{
			filter:"CustomerWhere"
		},
		owners:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		members:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		category:{
			filter:"BusinessCategoryWhere"
		},
		logo:{
			filter:"ImageWhere"
		},
		paginateOwners:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		paginateMembers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		}
	},
	OrderRecurrence:{
		locales:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		businessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		localesByLocale:{
			by:"OrderRecurrenceLocalesByLocaleUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		paginateLocales:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		}
	},
	OrderRecurrenceLocale:{
		root:{
			filter:"OrderRecurrenceWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	OrderRecurrenceLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"OrderRecurrenceOrderBy",
		locale:"LocaleOrderBy"
	},
	OrderRecurrenceOrderBy:{
		id:"OrderDirection",
		internalName:"OrderDirection",
		order:"OrderDirection"
	},
	OrderRecurrenceLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	LocaleUniqueWhere:{
		id:"UUID",
		businessCategories:"BusinessCategoryLocaleUniqueWhere",
		productCategoryLists:"ProductCategoryListLocaleUniqueWhere",
		productIngredients:"ProductIngredientLocaleUniqueWhere",
		orderRecurrences:"OrderRecurrenceLocaleUniqueWhere",
		blogs:"BlogLocaleUniqueWhere",
		blogPosts:"BlogPostLocaleUniqueWhere",
		identifier:"TranslationCataloguesIdentifierUniqueWhere",
		pages:"PageLocaleUniqueWhere",
		allergens:"AllergenLocaleUniqueWhere",
		productPackings:"ProductPackingLocaleUniqueWhere",
		products:"ProductLocaleUniqueWhere",
		productRecipes:"ProductRecipeLocaleUniqueWhere",
		deliveryMethods:"DeliveryMethodLocaleUniqueWhere",
		identificationCodes:"IdentificationCodeLocaleUniqueWhere",
		subscriptionBoxes:"SubscriptionBoxLocaleUniqueWhere"
	},
	BusinessCategoryLocaleUniqueWhere:{
		id:"UUID",
		root:"BusinessCategoryUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	BusinessCategoryUniqueWhere:{
		id:"UUID",
		locales:"BusinessCategoryLocaleUniqueWhere",
		businessCustomers:"BusinessCustomerUniqueWhere",
		specificProducts:"ProductListUniqueWhere",
		seasonalProductCategories:"ProductCategoryListUniqueWhere",
		specificProductsProductCategories:"ProductCategoryListUniqueWhere"
	},
	BusinessCustomerUniqueWhere:{
		id:"UUID",
		customer:"CustomerUniqueWhere"
	},
	CustomerUniqueWhere:{
		id:"UUID",
		addresses:"AddressUniqueWhere",
		defaultBillingAddress:"AddressUniqueWhere",
		credits:"CustomerCreditUniqueWhere",
		orders:"OrderUniqueWhere",
		permissions:"CustomerPermissionsUniqueWhere",
		name:"CustomerNameUniqueWhere",
		ingredientRatings:"CustomerIngredientRatingUniqueWhere",
		business:"BusinessCustomerUniqueWhere",
		carts:"CartUniqueWhere",
		user:"UserUniqueWhere",
		invoices:"InvoiceUniqueWhere",
		invoicingPlan:"InvoicingPlanUniqueWhere",
		billingSubjects:"BillingSubjectUniqueWhere",
		offeredProducts:"ProductCustomerPriceUniqueWhere",
		defaultDeliveryAddress:"AddressUniqueWhere",
		defaultBillingSubject:"BillingSubjectUniqueWhere",
		anonymousSession:"AnonymousSessionUniqueWhere",
		boughtVouchers:"VoucherUniqueWhere",
		ownsVouchers:"VoucherUniqueWhere",
		redeemedVouchers:"VoucherRedemptionUniqueWhere",
		meta:"CustomerMetadataUniqueWhere"
	},
	AddressUniqueWhere:{
		id:"UUID",
		meta:"AddressMetadataUniqueWhere",
		gps:"GpsUniqueWhere",
		invoices:"InvoiceUniqueWhere",
		billingAddressOfOrders:"OrderUniqueWhere",
		deliveryAddressOfOrderDeliveries:"OrderDeliveryUniqueWhere",
		defaultDeliveryAddressOfCustomer:"CustomerUniqueWhere"
	},
	AddressMetadataUniqueWhere:{
		id:"UUID",
		address:"AddressUniqueWhere"
	},
	GpsUniqueWhere:{
		id:"UUID",
		address:"AddressUniqueWhere"
	},
	InvoiceUniqueWhere:{
		id:"UUID",
		orderPayments:"OrderPaymentUniqueWhere"
	},
	OrderPaymentUniqueWhere:{
		id:"UUID",
		meta:"OrderPaymentMetadataUniqueWhere"
	},
	OrderPaymentMetadataUniqueWhere:{
		id:"UUID",
		orderPayment:"OrderPaymentUniqueWhere"
	},
	OrderUniqueWhere:{
		id:"UUID",
		discounts:"OrderDiscountUniqueWhere",
		delivery:"OrderDeliveryUniqueWhere",
		items:"OrderItemUniqueWhere",
		cart:"CartUniqueWhere",
		priceLines:"OrderPriceLineUniqueWhere",
		payments:"OrderPaymentUniqueWhere",
		summary:"OrderSummaryUniqueWhere"
	},
	OrderDiscountUniqueWhere:{
		id:"UUID",
		voucherRedemption:"VoucherRedemptionUniqueWhere"
	},
	VoucherRedemptionUniqueWhere:{
		id:"UUID",
		customerCredit:"CustomerCreditUniqueWhere",
		orderDiscount:"OrderDiscountUniqueWhere",
		voucher:"VoucherUniqueWhere"
	},
	CustomerCreditUniqueWhere:{
		id:"UUID",
		voucherRedemption:"VoucherRedemptionUniqueWhere"
	},
	VoucherUniqueWhere:{
		id:"UUID",
		redemption:"VoucherRedemptionUniqueWhere"
	},
	OrderDeliveryUniqueWhere:{
		id:"UUID",
		order:"OrderUniqueWhere"
	},
	OrderItemUniqueWhere:{
		id:"UUID",
		virtualProductEffects:"VirtualProductEffectUniqueWhere"
	},
	VirtualProductEffectUniqueWhere:{
		id:"UUID",
		resultVouchers:"VoucherUniqueWhere"
	},
	CartUniqueWhere:{
		id:"UUID",
		items:"CartItemUniqueWhere",
		order:"OrderUniqueWhere",
		priceLines:"CartPriceLineUniqueWhere",
		summary:"CartSummaryUniqueWhere"
	},
	CartItemUniqueWhere:{
		id:"UUID",
		cart:"CartUniqueWhere",
		product:"ProductUniqueWhere"
	},
	ProductUniqueWhere:{
		id:"UUID",
		stocks:"ProductStockUniqueWhere",
		groupPrices:"ProductGroupPriceUniqueWhere",
		cartItems:"CartItemUniqueWhere",
		orderItems:"OrderItemUniqueWhere",
		locales:"ProductLocaleUniqueWhere",
		customerPrices:"ProductCustomerPriceUniqueWhere",
		virtualProduct:"VirtualProductUniqueWhere",
		meta:"ProductMetadataUniqueWhere",
		page:"PageUniqueWhere"
	},
	ProductStockUniqueWhere:{
		id:"UUID",
		product:"ProductUniqueWhere",
		store:"StoreUniqueWhere"
	},
	StoreUniqueWhere:{
		id:"UUID"
	},
	ProductGroupPriceUniqueWhere:{
		id:"UUID",
		group:"CustomerGroupUniqueWhere",
		product:"ProductUniqueWhere"
	},
	CustomerGroupUniqueWhere:{
		id:"UUID",
		productPrices:"ProductGroupPriceUniqueWhere",
		customers:"CustomerUniqueWhere"
	},
	ProductLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductUniqueWhere",
		locale:"LocaleUniqueWhere",
		featureList:"TextListUniqueWhere"
	},
	TextListUniqueWhere:{
		id:"UUID",
		items:"TextItemUniqueWhere"
	},
	TextItemUniqueWhere:{
		id:"UUID"
	},
	ProductCustomerPriceUniqueWhere:{
		id:"UUID",
		product:"ProductUniqueWhere",
		customer:"CustomerUniqueWhere"
	},
	VirtualProductUniqueWhere:{
		id:"UUID",
		product:"ProductUniqueWhere"
	},
	ProductMetadataUniqueWhere:{
		id:"UUID",
		product:"ProductUniqueWhere"
	},
	PageUniqueWhere:{
		id:"UUID",
		locales:"PageLocaleUniqueWhere"
	},
	PageLocaleUniqueWhere:{
		id:"UUID",
		root:"PageUniqueWhere",
		locale:"LocaleUniqueWhere",
		link:"LinkableUniqueWhere",
		seo:"SeoUniqueWhere",
		content:"ContentUniqueWhere",
		cover:"CoverUniqueWhere"
	},
	LinkableUniqueWhere:{
		id:"UUID",
		blogPost:"BlogPostLocaleUniqueWhere",
		page:"PageLocaleUniqueWhere",
		redirect:"RedirectUniqueWhere"
	},
	BlogPostLocaleUniqueWhere:{
		id:"UUID",
		root:"BlogPostUniqueWhere",
		locale:"LocaleUniqueWhere",
		cover:"CoverUniqueWhere",
		content:"ContentUniqueWhere",
		link:"LinkableUniqueWhere",
		seo:"SeoUniqueWhere"
	},
	BlogPostUniqueWhere:{
		id:"UUID",
		locales:"BlogPostLocaleUniqueWhere"
	},
	CoverUniqueWhere:{
		id:"UUID"
	},
	ContentUniqueWhere:{
		id:"UUID",
		blocks:"ContentBlockUniqueWhere"
	},
	ContentBlockUniqueWhere:{
		id:"UUID",
		references:"ContentReferenceUniqueWhere"
	},
	ContentReferenceUniqueWhere:{
		id:"UUID",
		products:"ProductListUniqueWhere",
		productBanners:"ProductBannerListUniqueWhere",
		deliveryRegions:"DeliveryRegionsUniqueWhere"
	},
	ProductListUniqueWhere:{
		id:"UUID",
		items:"ProductListItemUniqueWhere",
		businessCategory:"BusinessCategoryUniqueWhere"
	},
	ProductListItemUniqueWhere:{
		id:"UUID"
	},
	ProductBannerListUniqueWhere:{
		id:"UUID",
		items:"ProductBannerItemUniqueWhere"
	},
	ProductBannerItemUniqueWhere:{
		id:"UUID"
	},
	DeliveryRegionsUniqueWhere:{
		id:"UUID",
		items:"DeliveryRegionsItemUniqueWhere"
	},
	DeliveryRegionsItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere",
		textList:"TextListUniqueWhere",
		gallery:"ImageListUniqueWhere"
	},
	ImageUniqueWhere:{
		id:"UUID"
	},
	ImageListUniqueWhere:{
		id:"UUID",
		items:"ImageListItemUniqueWhere"
	},
	ImageListItemUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere"
	},
	SeoUniqueWhere:{
		id:"UUID",
		ogImage:"ImageUniqueWhere"
	},
	RedirectUniqueWhere:{
		id:"UUID",
		link:"LinkableUniqueWhere"
	},
	CartPriceLineUniqueWhere:{
		id:"UUID"
	},
	CartSummaryUniqueWhere:{
		id:"UUID",
		cart:"CartUniqueWhere"
	},
	OrderPriceLineUniqueWhere:{
		id:"UUID",
		orderItem:"OrderItemUniqueWhere",
		orderDiscount:"OrderDiscountUniqueWhere",
		orderPayment:"OrderPaymentUniqueWhere",
		orderDelivery:"OrderDeliveryUniqueWhere"
	},
	OrderSummaryUniqueWhere:{
		id:"UUID",
		order:"OrderUniqueWhere"
	},
	CustomerPermissionsUniqueWhere:{
		id:"UUID",
		customer:"CustomerUniqueWhere"
	},
	CustomerNameUniqueWhere:{
		id:"UUID",
		customer:"CustomerUniqueWhere"
	},
	CustomerIngredientRatingUniqueWhere:{
		id:"UUID",
		customer:"CustomerUniqueWhere",
		ingredient:"ProductIngredientUniqueWhere"
	},
	ProductIngredientUniqueWhere:{
		id:"UUID",
		locales:"ProductIngredientLocaleUniqueWhere",
		customerRatings:"CustomerIngredientRatingUniqueWhere"
	},
	ProductIngredientLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductIngredientUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	UserUniqueWhere:{
		id:"UUID",
		tenantPersonId:"UUID",
		staff:"StaffUniqueWhere",
		customer:"CustomerUniqueWhere",
		meta:"UserMetadataUniqueWhere"
	},
	StaffUniqueWhere:{
		id:"UUID",
		user:"UserUniqueWhere"
	},
	UserMetadataUniqueWhere:{
		id:"UUID",
		tenantPersonId:"UUID",
		tenantIdentityId:"UUID",
		user:"UserUniqueWhere"
	},
	InvoicingPlanUniqueWhere:{
		id:"UUID",
		customer:"CustomerUniqueWhere",
		preset:"InvoicingPresetUniqueWhere"
	},
	InvoicingPresetUniqueWhere:{
		id:"UUID",
		invoicingPlan:"InvoicingPlanUniqueWhere"
	},
	BillingSubjectUniqueWhere:{
		id:"UUID",
		invoices:"InvoiceUniqueWhere",
		defaultBillingSubjectOfCustomer:"CustomerUniqueWhere",
		orders:"OrderUniqueWhere"
	},
	AnonymousSessionUniqueWhere:{
		id:"UUID",
		customer:"CustomerUniqueWhere"
	},
	CustomerMetadataUniqueWhere:{
		id:"UUID",
		customer:"CustomerUniqueWhere"
	},
	ProductCategoryListUniqueWhere:{
		id:"UUID",
		items:"ProductCategoryListItemUniqueWhere",
		locales:"ProductCategoryListLocaleUniqueWhere",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategoryUniqueWhere",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategoryUniqueWhere"
	},
	ProductCategoryListItemUniqueWhere:{
		id:"UUID"
	},
	ProductCategoryListLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductCategoryListUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	OrderRecurrenceLocaleUniqueWhere:{
		id:"UUID",
		root:"OrderRecurrenceUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	OrderRecurrenceUniqueWhere:{
		id:"UUID",
		locales:"OrderRecurrenceLocaleUniqueWhere"
	},
	BlogLocaleUniqueWhere:{
		id:"UUID",
		root:"BlogUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	BlogUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"BlogLocaleUniqueWhere"
	},
	TranslationCataloguesIdentifierUniqueWhere:{
		id:"UUID",
		catalogue:"TranslationCatalogueUniqueWhere"
	},
	TranslationCatalogueUniqueWhere:{
		id:"UUID",
		domain:"TranslationDomainUniqueWhere",
		identifier:"TranslationCataloguesIdentifierUniqueWhere",
		values:"TranslationValueUniqueWhere"
	},
	TranslationDomainUniqueWhere:{
		id:"UUID",
		catalogues:"TranslationCatalogueUniqueWhere",
		keys:"TranslationKeyUniqueWhere"
	},
	TranslationKeyUniqueWhere:{
		id:"UUID",
		domain:"TranslationDomainUniqueWhere",
		values:"TranslationValueUniqueWhere"
	},
	TranslationValueUniqueWhere:{
		id:"UUID",
		catalogue:"TranslationCatalogueUniqueWhere",
		key:"TranslationKeyUniqueWhere"
	},
	AllergenLocaleUniqueWhere:{
		id:"UUID",
		root:"AllergenUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	AllergenUniqueWhere:{
		id:"UUID",
		locales:"AllergenLocaleUniqueWhere"
	},
	ProductPackingLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductPackingUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductPackingUniqueWhere:{
		id:"UUID",
		locales:"ProductPackingLocaleUniqueWhere"
	},
	ProductRecipeLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductRecipeUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductRecipeUniqueWhere:{
		id:"UUID",
		products:"ProductUniqueWhere",
		ingredients:"ProductIngredientItemUniqueWhere",
		locales:"ProductRecipeLocaleUniqueWhere"
	},
	ProductIngredientItemUniqueWhere:{
		id:"UUID"
	},
	DeliveryMethodLocaleUniqueWhere:{
		id:"UUID",
		locale:"LocaleUniqueWhere",
		root:"DeliveryMethodUniqueWhere"
	},
	DeliveryMethodUniqueWhere:{
		id:"UUID",
		channels:"ChannelDeliveryUniqueWhere",
		locales:"DeliveryMethodLocaleUniqueWhere",
		timelinePreset:"DeliveryTimelinePresetUniqueWhere"
	},
	ChannelDeliveryUniqueWhere:{
		id:"UUID"
	},
	DeliveryTimelinePresetUniqueWhere:{
		id:"UUID",
		deliveryMethod:"DeliveryMethodUniqueWhere"
	},
	IdentificationCodeLocaleUniqueWhere:{
		id:"UUID",
		root:"IdentificationCodeUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	IdentificationCodeUniqueWhere:{
		id:"UUID",
		locales:"IdentificationCodeLocaleUniqueWhere"
	},
	SubscriptionBoxLocaleUniqueWhere:{
		id:"UUID",
		root:"SubscriptionBoxUniqueWhere",
		locale:"LocaleUniqueWhere",
		texts:"TextListUniqueWhere",
		secondaryTexts:"TextListUniqueWhere"
	},
	SubscriptionBoxUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"SubscriptionBoxLocaleUniqueWhere"
	},
	ProductList:{
		items:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		businessCategory:{
			filter:"BusinessCategoryWhere"
		},
		paginateItems:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		}
	},
	ProductListItem:{
		list:{
			filter:"ProductListWhere"
		},
		product:{
			filter:"ProductWhere"
		}
	},
	ProductListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProductListOrderBy",
		product:"ProductOrderBy",
		defaultQuantityForOrder:"OrderDirection"
	},
	ProductOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		priceCents:"OrderDirection",
		notDiscountable:"OrderDirection",
		packing:"ProductPackingOrderBy",
		recipe:"ProductRecipeOrderBy",
		vatRate:"VatRateOrderBy",
		image:"ImageOrderBy",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		availability:"OrderDirection",
		order:"OrderDirection",
		maximumQuantityPerOrder:"OrderDirection",
		archivedAt:"OrderDirection",
		virtualProduct:"VirtualProductOrderBy",
		meta:"ProductMetadataOrderBy",
		page:"PageOrderBy",
		hasSubscription:"OrderDirection",
		pointOfSaleTileColor:"OrderDirection"
	},
	ProductPackingOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		image:"ImageOrderBy",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		volumeMl:"OrderDirection",
		icon:"ImageOrderBy",
		shortName:"OrderDirection",
		isPackedInCardboard:"OrderDirection"
	},
	ProductRecipeOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		internalNote:"OrderDirection",
		color:"OrderDirection",
		image:"ImageOrderBy",
		icon:"ImageOrderBy",
		needsPrepping:"OrderDirection",
		gutTuning:"OrderDirection",
		freshingContainer:"FreshingContainerOrderBy",
		description:"OrderDirection"
	},
	FreshingContainerOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		volumeMl:"OrderDirection",
		workspace:"WorkspaceOrderBy"
	},
	WorkspaceOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection",
		orderDeadlineTime:"OrderDirection",
		ordersLoadingDurationHours:"OrderDirection",
		freshingContainerVolumeMl:"OrderDirection",
		freshingPrepareOrderByOffsetMinutes:"OrderDirection",
		checkoutUrlTemplate:"OrderDirection",
		orderUrlTemplate:"OrderDirection",
		paymentUrlTemplate:"OrderDirection",
		customerProfileUrlTemplate:"OrderDirection",
		userProfileUrlTemplate:"OrderDirection",
		messengerUrl:"OrderDirection",
		messengerIosUrl:"OrderDirection",
		messengerAndroidUrl:"OrderDirection",
		openingHours:"WeekHoursOrderBy",
		workingHours:"WeekHoursOrderBy",
		defaultFreshingContainer:"FreshingContainerOrderBy",
		creditsProduct:"VirtualProductOrderBy",
		discountVatRate:"VatRateOrderBy",
		name:"OrderDirection",
		businessAddress:"OrderDirection"
	},
	WeekHoursOrderBy:{
		id:"OrderDirection",
		mon:"OrderDirection",
		tue:"OrderDirection",
		wed:"OrderDirection",
		thu:"OrderDirection",
		fri:"OrderDirection",
		sat:"OrderDirection",
		sun:"OrderDirection"
	},
	VirtualProductOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		product:"ProductOrderBy",
		voucherCreditCents:"OrderDirection",
		physicalRepresentation:"OrderDirection",
		recipientEmail:"OrderDirection"
	},
	VatRateOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		ratePermille:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		preset:"OrderDirection"
	},
	ProductMetadataOrderBy:{
		id:"OrderDirection",
		archived:"OrderDirection",
		available:"OrderDirection",
		availableQuantity:"OrderDirection",
		product:"ProductOrderBy"
	},
	PageOrderBy:{
		id:"OrderDirection"
	},
	ProductCategoryList:{
		items:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		locales:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		seasonalProductCategoriesOfBusinessCategory:{
			filter:"BusinessCategoryWhere"
		},
		specificProductsProductCategoriesOfBusinessCategory:{
			filter:"BusinessCategoryWhere"
		},
		localesByLocale:{
			by:"ProductCategoryListLocalesByLocaleUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		paginateItems:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		paginateLocales:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		}
	},
	ProductCategoryListItem:{
		list:{
			filter:"ProductCategoryListWhere"
		},
		productCategory:{
			filter:"ProductCategoryWhere"
		}
	},
	ProductCategory:{
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		tags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		parent:{
			filter:"ProductCategoryWhere"
		},
		children:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		image:{
			filter:"ImageWhere"
		},
		childrenByCode:{
			by:"ProductCategoryChildrenByCodeUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		childrenByChildren:{
			by:"ProductCategoryChildrenByChildrenUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateChildren:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		}
	},
	TagOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection"
	},
	ProductCategoryOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		parent:"ProductCategoryOrderBy",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		color:"OrderDirection",
		image:"ImageOrderBy",
		order:"OrderDirection"
	},
	ProductCategoryChildrenByCodeUniqueWhere:{

	},
	ProductCategoryChildrenByChildrenUniqueWhere:{
		children:"ProductCategoryUniqueWhere"
	},
	ProductCategoryUniqueWhere:{
		id:"UUID",
		children:"ProductCategoryUniqueWhere"
	},
	ProductCategoryListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProductCategoryListOrderBy",
		productCategory:"ProductCategoryOrderBy",
		showRecipeImages:"OrderDirection",
		showPackingImages:"OrderDirection",
		showAllAvailableProducts:"OrderDirection"
	},
	ProductCategoryListLocale:{
		root:{
			filter:"ProductCategoryListWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductCategoryListLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductCategoryListOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductCategoryListLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BusinessCategoryLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BusinessCategoryBusinessCustomersByCustomerUniqueWhere:{
		customer:"CustomerUniqueWhere"
	},
	ProductIngredientLocale:{
		root:{
			filter:"ProductIngredientWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductIngredient:{
		locales:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		allergens:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		customerRatings:{
			filter:"CustomerIngredientRatingWhere",
			orderBy:"CustomerIngredientRatingOrderBy"
		},
		freshingContainer:{
			filter:"FreshingContainerWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		localesByLocale:{
			by:"ProductIngredientLocalesByLocaleUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		customerRatingsByCustomer:{
			by:"ProductIngredientCustomerRatingsByCustomerUniqueWhere",
			filter:"CustomerIngredientRatingWhere"
		},
		paginateLocales:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateAllergens:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		paginateCustomerRatings:{
			filter:"CustomerIngredientRatingWhere",
			orderBy:"CustomerIngredientRatingOrderBy"
		}
	},
	ProductIngredientLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductIngredientOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductIngredientOrderBy:{
		id:"OrderDirection",
		internalNote:"OrderDirection",
		internalName:"OrderDirection",
		needsPrepping:"OrderDirection",
		allowCustomerRating:"OrderDirection",
		freshingContainer:"FreshingContainerOrderBy",
		image:"ImageOrderBy",
		incrementQuantityNumber:"OrderDirection"
	},
	Allergen:{
		locales:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		ingredients:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		localesByLocale:{
			by:"AllergenLocalesByLocaleUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		paginateLocales:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateIngredients:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		}
	},
	AllergenLocale:{
		root:{
			filter:"AllergenWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	AllergenLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"AllergenOrderBy",
		locale:"LocaleOrderBy"
	},
	AllergenOrderBy:{
		id:"OrderDirection",
		internalName:"OrderDirection",
		code:"OrderDirection"
	},
	AllergenLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	CustomerIngredientRating:{
		customer:{
			filter:"CustomerWhere"
		},
		rating:{
			filter:"ProductIngredientRatingWhere"
		},
		ingredient:{
			filter:"ProductIngredientWhere"
		}
	},
	ProductIngredientRating:{
		icon:{
			filter:"ImageWhere"
		}
	},
	CustomerIngredientRatingOrderBy:{
		id:"OrderDirection",
		customer:"CustomerOrderBy",
		rating:"ProductIngredientRatingOrderBy",
		ingredient:"ProductIngredientOrderBy"
	},
	ProductIngredientRatingOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		name:"OrderDirection",
		icon:"ImageOrderBy"
	},
	FreshingContainer:{
		workspace:{
			filter:"WorkspaceWhere"
		}
	},
	Workspace:{
		freshingContainers:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		openingHours:{
			filter:"WeekHoursWhere"
		},
		workingHours:{
			filter:"WeekHoursWhere"
		},
		defaultFreshingContainer:{
			filter:"FreshingContainerWhere"
		},
		creditsProduct:{
			filter:"VirtualProductWhere"
		},
		discountVatRate:{
			filter:"VatRateWhere"
		},
		paginateFreshingContainers:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		}
	},
	VirtualProduct:{
		product:{
			filter:"ProductWhere"
		}
	},
	VatRate:{
		channelDeliveries:{
			filter:"ChannelDeliveryWhere",
			orderBy:"ChannelDeliveryOrderBy"
		},
		channelPayments:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		},
		paginateChannelDeliveries:{
			filter:"ChannelDeliveryWhere",
			orderBy:"ChannelDeliveryOrderBy"
		},
		paginateChannelPayments:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		}
	},
	ChannelDelivery:{
		channel:{
			filter:"ChannelWhere"
		},
		method:{
			filter:"DeliveryMethodWhere"
		},
		vatRate:{
			filter:"VatRateWhere"
		}
	},
	Channel:{
		payments:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		},
		deliveries:{
			filter:"ChannelDeliveryWhere",
			orderBy:"ChannelDeliveryOrderBy"
		},
		carts:{
			filter:"CartWhere",
			orderBy:"CartOrderBy"
		},
		customers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		addTagsOnRegistration:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		publicTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		cartsByItems:{
			by:"ChannelCartsByItemsUniqueWhere",
			filter:"CartWhere"
		},
		cartsByOrder:{
			by:"ChannelCartsByOrderUniqueWhere",
			filter:"CartWhere"
		},
		cartsByPriceLines:{
			by:"ChannelCartsByPriceLinesUniqueWhere",
			filter:"CartWhere"
		},
		cartsBySummary:{
			by:"ChannelCartsBySummaryUniqueWhere",
			filter:"CartWhere"
		},
		paginatePayments:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		},
		paginateDeliveries:{
			filter:"ChannelDeliveryWhere",
			orderBy:"ChannelDeliveryOrderBy"
		},
		paginateCarts:{
			filter:"CartWhere",
			orderBy:"CartOrderBy"
		},
		paginateCustomers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		paginateAddTagsOnRegistration:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginatePublicTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		}
	},
	ChannelPayment:{
		channel:{
			filter:"ChannelWhere"
		},
		method:{
			filter:"PaymentMethodWhere"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		allowOnlyForTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateAllowOnlyForTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		}
	},
	PaymentMethod:{
		deliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		channels:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		},
		paginateDeliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateChannels:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		}
	},
	DeliveryMethod:{
		paymentMethods:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		suitableForDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		channels:{
			filter:"ChannelDeliveryWhere",
			orderBy:"ChannelDeliveryOrderBy"
		},
		locales:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		timelinePreset:{
			filter:"DeliveryTimelinePresetWhere"
		},
		localesByLocale:{
			by:"DeliveryMethodLocalesByLocaleUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		paginatePaymentMethods:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginateSuitableForDeliveryZones:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateChannels:{
			filter:"ChannelDeliveryWhere",
			orderBy:"ChannelDeliveryOrderBy"
		},
		paginateLocales:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		}
	},
	PaymentMethodOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		enabled:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		blockingDispatch:"OrderDirection",
		type:"OrderDirection",
		reduceGratuityByPercentage:"OrderDirection"
	},
	DeliveryZone:{
		addresses:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		suitableDeliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		deliveryZonesOfTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		deliveryZonesOfProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		addressesMetadata:{
			filter:"AddressMetadataWhere",
			orderBy:"AddressMetadataOrderBy"
		},
		addressesByMeta:{
			by:"DeliveryZoneAddressesByMetaUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByGps:{
			by:"DeliveryZoneAddressesByGpsUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByInvoices:{
			by:"DeliveryZoneAddressesByInvoicesUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByBillingAddressOfOrders:{
			by:"DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByDeliveryAddressOfOrderDeliveries:{
			by:"DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere",
			filter:"AddressWhere"
		},
		addressesByDefaultDeliveryAddressOfCustomer:{
			by:"DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere",
			filter:"AddressWhere"
		},
		addressesMetadataByAddress:{
			by:"DeliveryZoneAddressesMetadataByAddressUniqueWhere",
			filter:"AddressMetadataWhere"
		},
		paginateAddresses:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		paginateSuitableDeliveryMethods:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryZonesOfTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateDeliveryZonesOfProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateAddressesMetadata:{
			filter:"AddressMetadataWhere",
			orderBy:"AddressMetadataOrderBy"
		}
	},
	DeliveryMethodOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		enabled:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection",
		requireDeliveryAddress:"OrderDirection",
		enableDeliveryTracking:"OrderDirection",
		timelinePreset:"DeliveryTimelinePresetOrderBy"
	},
	DeliveryTimelinePresetOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		duration:"OrderDirection",
		window:"OrderDirection",
		deliveryMethod:"DeliveryMethodOrderBy"
	},
	AddressMetadata:{
		address:{
			filter:"AddressWhere"
		},
		deliveryZone:{
			filter:"DeliveryZoneWhere"
		}
	},
	DeliveryZoneAddressesByMetaUniqueWhere:{
		meta:"AddressMetadataUniqueWhere"
	},
	DeliveryZoneAddressesByGpsUniqueWhere:{
		gps:"GpsUniqueWhere"
	},
	DeliveryZoneAddressesByInvoicesUniqueWhere:{
		invoices:"InvoiceUniqueWhere"
	},
	DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere:{
		billingAddressOfOrders:"OrderUniqueWhere"
	},
	DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere:{
		deliveryAddressOfOrderDeliveries:"OrderDeliveryUniqueWhere"
	},
	DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere:{
		defaultDeliveryAddressOfCustomer:"CustomerUniqueWhere"
	},
	DeliveryZoneAddressesMetadataByAddressUniqueWhere:{
		address:"AddressUniqueWhere"
	},
	ChannelDeliveryOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		priceCents:"OrderDirection",
		channel:"ChannelOrderBy",
		method:"DeliveryMethodOrderBy",
		vatRate:"VatRateOrderBy",
		defaultVisible:"OrderDirection"
	},
	ChannelOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		disabled:"OrderDirection",
		code:"OrderDirection"
	},
	DeliveryMethodLocale:{
		locale:{
			filter:"LocaleWhere"
		},
		root:{
			filter:"DeliveryMethodWhere"
		}
	},
	DeliveryMethodLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		description:"OrderDirection",
		trackingCodeUrlTemplate:"OrderDirection",
		processingTitle:"OrderDirection",
		processingText:"OrderDirection",
		processingIconUrl:"OrderDirection",
		dispatchedTitle:"OrderDirection",
		dispatchedText:"OrderDirection",
		dispatchedIconUrl:"OrderDirection",
		fulfilledTitle:"OrderDirection",
		fulfilledText:"OrderDirection",
		fulfilledIconUrl:"OrderDirection",
		locale:"LocaleOrderBy",
		root:"DeliveryMethodOrderBy"
	},
	DeliveryTimelinePreset:{
		deliveryMethod:{
			filter:"DeliveryMethodWhere"
		}
	},
	DeliveryMethodLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ChannelPaymentOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		channel:"ChannelOrderBy",
		method:"PaymentMethodOrderBy",
		vatRate:"VatRateOrderBy",
		requireBillingAddress:"OrderDirection",
		provideReceipt:"OrderDirection",
		requireBillingSubject:"OrderDirection"
	},
	Cart:{
		items:{
			filter:"CartItemWhere",
			orderBy:"CartItemOrderBy"
		},
		channel:{
			filter:"ChannelWhere"
		},
		order:{
			filter:"OrderWhere"
		},
		customer:{
			filter:"CustomerWhere"
		},
		deliveryAddress:{
			filter:"AddressWhere"
		},
		billingAddress:{
			filter:"AddressWhere"
		},
		delivery:{
			filter:"ChannelDeliveryWhere"
		},
		payment:{
			filter:"ChannelPaymentWhere"
		},
		billingSubject:{
			filter:"BillingSubjectWhere"
		},
		priceLines:{
			filter:"CartPriceLineWhere",
			orderBy:"CartPriceLineOrderBy"
		},
		summary:{
			filter:"CartSummaryWhere"
		},
		itemsByProduct:{
			by:"CartItemsByProductUniqueWhere",
			filter:"CartItemWhere"
		},
		paginateItems:{
			filter:"CartItemWhere",
			orderBy:"CartItemOrderBy"
		},
		paginatePriceLines:{
			filter:"CartPriceLineWhere",
			orderBy:"CartPriceLineOrderBy"
		}
	},
	CartItem:{
		cart:{
			filter:"CartWhere"
		},
		product:{
			filter:"ProductWhere"
		},
		fulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		}
	},
	FulfillmentNote:{
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		tags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		cartItems:{
			filter:"CartItemWhere",
			orderBy:"CartItemOrderBy"
		},
		orderItems:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		orders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateTags:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateCartItems:{
			filter:"CartItemWhere",
			orderBy:"CartItemOrderBy"
		},
		paginateOrderItems:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		paginateOrders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		}
	},
	CartItemOrderBy:{
		id:"OrderDirection",
		quantity:"OrderDirection",
		cart:"CartOrderBy",
		product:"ProductOrderBy",
		createdAt:"OrderDirection",
		updatedAt:"OrderDirection",
		fulfillmentNote:"OrderDirection"
	},
	CartOrderBy:{
		id:"OrderDirection",
		confirmedAt:"OrderDirection",
		deletedAt:"OrderDirection",
		note:"OrderDirection",
		handle:"OrderDirection",
		state:"OrderDirection",
		createdAt:"OrderDirection",
		channel:"ChannelOrderBy",
		order:"OrderOrderBy",
		customer:"CustomerOrderBy",
		deliveryAddress:"AddressOrderBy",
		billingAddress:"AddressOrderBy",
		deliveryOption:"OrderDirection",
		paymentOption:"OrderDirection",
		delivery:"ChannelDeliveryOrderBy",
		payment:"ChannelPaymentOrderBy",
		billingSubject:"BillingSubjectOrderBy",
		summary:"CartSummaryOrderBy"
	},
	OrderOrderBy:{
		id:"OrderDirection",
		state:"OrderDirection",
		fulfilledAt:"OrderDirection",
		canceledAt:"OrderDirection",
		note:"OrderDirection",
		customer:"CustomerOrderBy",
		channel:"ChannelOrderBy",
		delivery:"OrderDeliveryOrderBy",
		cart:"CartOrderBy",
		createdAt:"OrderDirection",
		billingAddress:"AddressOrderBy",
		seq:"OrderDirection",
		billingSubject:"BillingSubjectOrderBy",
		summary:"OrderSummaryOrderBy",
		doubleshotLegacyId:"OrderDirection",
		fulfillmentNote:"OrderDirection",
		doubleshotTotalWeightGrams:"OrderDirection",
		doubleShotLegacyNumber:"OrderDirection",
		doubleshotLastUpdatedAt:"OrderDirection",
		doubleshotAdminUrl:"OrderDirection"
	},
	OrderDeliveryOrderBy:{
		id:"OrderDirection",
		priceCents:"OrderDirection",
		deliveredAt:"OrderDirection",
		method:"DeliveryMethodOrderBy",
		vatRate:"VatRateOrderBy",
		order:"OrderOrderBy",
		expectedAt:"OrderDirection",
		state:"OrderDirection",
		loadingDurationHours:"OrderDirection",
		dispatchAt:"OrderDirection",
		duration:"OrderDirection",
		window:"OrderDirection",
		expectedEndAt:"OrderDirection",
		processingAt:"OrderDirection",
		deliveringAt:"OrderDirection",
		failedAt:"OrderDirection",
		internalNote:"OrderDirection",
		prepareAt:"OrderDirection",
		pickedAt:"OrderDirection",
		address:"AddressOrderBy",
		trackingCode:"OrderDirection"
	},
	OrderSummaryOrderBy:{
		id:"OrderDirection",
		totalPriceCents:"OrderDirection",
		discountPriceCents:"OrderDirection",
		undiscountedPriceCents:"OrderDirection",
		itemsQuantity:"OrderDirection",
		orderUrl:"OrderDirection",
		orderNumber:"OrderDirection",
		order:"OrderOrderBy"
	},
	CartSummaryOrderBy:{
		id:"OrderDirection",
		itemsPriceCents:"OrderDirection",
		deliveryPriceCents:"OrderDirection",
		totalPriceCents:"OrderDirection",
		itemsCount:"OrderDirection",
		itemsQuantity:"OrderDirection",
		checkoutUrl:"OrderDirection",
		cart:"CartOrderBy"
	},
	OrderItem:{
		vatRate:{
			filter:"VatRateWhere"
		},
		order:{
			filter:"OrderWhere"
		},
		virtualProductEffects:{
			filter:"VirtualProductEffectWhere",
			orderBy:"VirtualProductEffectOrderBy"
		},
		fulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		product:{
			filter:"ProductWhere"
		},
		virtualProductEffectsByResultVouchers:{
			by:"OrderItemVirtualProductEffectsByResultVouchersUniqueWhere",
			filter:"VirtualProductEffectWhere"
		},
		paginateVirtualProductEffects:{
			filter:"VirtualProductEffectWhere",
			orderBy:"VirtualProductEffectOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		}
	},
	Order:{
		customer:{
			filter:"CustomerWhere"
		},
		channel:{
			filter:"ChannelWhere"
		},
		discounts:{
			filter:"OrderDiscountWhere",
			orderBy:"OrderDiscountOrderBy"
		},
		delivery:{
			filter:"OrderDeliveryWhere"
		},
		items:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		cart:{
			filter:"CartWhere"
		},
		billingAddress:{
			filter:"AddressWhere"
		},
		priceLines:{
			filter:"OrderPriceLineWhere",
			orderBy:"OrderPriceLineOrderBy"
		},
		payments:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		billingSubject:{
			filter:"BillingSubjectWhere"
		},
		summary:{
			filter:"OrderSummaryWhere"
		},
		fulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		discountsByVoucherRedemption:{
			by:"OrderDiscountsByVoucherRedemptionUniqueWhere",
			filter:"OrderDiscountWhere"
		},
		itemsByVirtualProductEffects:{
			by:"OrderItemsByVirtualProductEffectsUniqueWhere",
			filter:"OrderItemWhere"
		},
		priceLinesByOrderItem:{
			by:"OrderPriceLinesByOrderItemUniqueWhere",
			filter:"OrderPriceLineWhere"
		},
		priceLinesByOrderDiscount:{
			by:"OrderPriceLinesByOrderDiscountUniqueWhere",
			filter:"OrderPriceLineWhere"
		},
		priceLinesByOrderPayment:{
			by:"OrderPriceLinesByOrderPaymentUniqueWhere",
			filter:"OrderPriceLineWhere"
		},
		priceLinesByOrderDelivery:{
			by:"OrderPriceLinesByOrderDeliveryUniqueWhere",
			filter:"OrderPriceLineWhere"
		},
		paymentsByMeta:{
			by:"OrderPaymentsByMetaUniqueWhere",
			filter:"OrderPaymentWhere"
		},
		paginateDiscounts:{
			filter:"OrderDiscountWhere",
			orderBy:"OrderDiscountOrderBy"
		},
		paginateItems:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		paginatePriceLines:{
			filter:"OrderPriceLineWhere",
			orderBy:"OrderPriceLineOrderBy"
		},
		paginatePayments:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		}
	},
	OrderDiscount:{
		vatRate:{
			filter:"VatRateWhere"
		},
		order:{
			filter:"OrderWhere"
		},
		voucherRedemption:{
			filter:"VoucherRedemptionWhere"
		}
	},
	VoucherRedemption:{
		customerCredit:{
			filter:"CustomerCreditWhere"
		},
		orderDiscount:{
			filter:"OrderDiscountWhere"
		},
		voucher:{
			filter:"VoucherWhere"
		},
		customer:{
			filter:"CustomerWhere"
		}
	},
	CustomerCredit:{
		customer:{
			filter:"CustomerWhere"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		voucherRedemption:{
			filter:"VoucherRedemptionWhere"
		}
	},
	Voucher:{
		buyer:{
			filter:"CustomerWhere"
		},
		owner:{
			filter:"CustomerWhere"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		virtualProductEffect:{
			filter:"VirtualProductEffectWhere"
		},
		redemption:{
			filter:"VoucherRedemptionWhere"
		}
	},
	VirtualProductEffect:{
		orderItem:{
			filter:"OrderItemWhere"
		},
		resultVouchers:{
			filter:"VoucherWhere",
			orderBy:"VoucherOrderBy"
		},
		resultVouchersByCode:{
			by:"VirtualProductEffectResultVouchersByCodeUniqueWhere",
			filter:"VoucherWhere"
		},
		resultVouchersByRedemption:{
			by:"VirtualProductEffectResultVouchersByRedemptionUniqueWhere",
			filter:"VoucherWhere"
		},
		paginateResultVouchers:{
			filter:"VoucherWhere",
			orderBy:"VoucherOrderBy"
		}
	},
	VoucherOrderBy:{
		id:"OrderDirection",
		createdAt:"OrderDirection",
		activatedAt:"OrderDirection",
		expiresAt:"OrderDirection",
		note:"OrderDirection",
		code:"OrderDirection",
		type:"OrderDirection",
		creditCents:"OrderDirection",
		buyer:"CustomerOrderBy",
		owner:"CustomerOrderBy",
		vatRate:"VatRateOrderBy",
		virtualProductEffect:"VirtualProductEffectOrderBy",
		redemption:"VoucherRedemptionOrderBy"
	},
	VirtualProductEffectOrderBy:{
		id:"OrderDirection",
		createdAt:"OrderDirection",
		succeededAt:"OrderDirection",
		orderItem:"OrderItemOrderBy",
		failedAt:"OrderDirection",
		revertedAt:"OrderDirection"
	},
	OrderItemOrderBy:{
		id:"OrderDirection",
		unitPriceCents:"OrderDirection",
		quantity:"OrderDirection",
		vatRate:"VatRateOrderBy",
		order:"OrderOrderBy",
		pickedAt:"OrderDirection",
		note:"OrderDirection",
		product:"ProductOrderBy"
	},
	VoucherRedemptionOrderBy:{
		id:"OrderDirection",
		createdAt:"OrderDirection",
		note:"OrderDirection",
		manualRedemptionNote:"OrderDirection",
		customerCredit:"CustomerCreditOrderBy",
		orderDiscount:"OrderDiscountOrderBy",
		voucher:"VoucherOrderBy",
		customer:"CustomerOrderBy"
	},
	CustomerCreditOrderBy:{
		id:"OrderDirection",
		initialCreditCents:"OrderDirection",
		remainingCreditsCents:"OrderDirection",
		expiresAt:"OrderDirection",
		reason:"OrderDirection",
		customer:"CustomerOrderBy",
		vatRate:"VatRateOrderBy",
		createdAt:"OrderDirection",
		voucherRedemption:"VoucherRedemptionOrderBy",
		invoiceUrl:"OrderDirection"
	},
	OrderDiscountOrderBy:{
		id:"OrderDirection",
		discountCents:"OrderDirection",
		vatRate:"VatRateOrderBy",
		order:"OrderOrderBy",
		description:"OrderDirection",
		type:"OrderDirection",
		voucherRedemption:"VoucherRedemptionOrderBy"
	},
	VirtualProductEffectResultVouchersByCodeUniqueWhere:{

	},
	VirtualProductEffectResultVouchersByRedemptionUniqueWhere:{
		redemption:"VoucherRedemptionUniqueWhere"
	},
	OrderDelivery:{
		method:{
			filter:"DeliveryMethodWhere"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		order:{
			filter:"OrderWhere"
		},
		address:{
			filter:"AddressWhere"
		}
	},
	OrderPriceLine:{
		orderItem:{
			filter:"OrderItemWhere"
		},
		orderDiscount:{
			filter:"OrderDiscountWhere"
		},
		orderPayment:{
			filter:"OrderPaymentWhere"
		},
		orderDelivery:{
			filter:"OrderDeliveryWhere"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		order:{
			filter:"OrderWhere"
		}
	},
	OrderPayment:{
		method:{
			filter:"PaymentMethodWhere"
		},
		order:{
			filter:"OrderWhere"
		},
		invoice:{
			filter:"InvoiceWhere"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		meta:{
			filter:"OrderPaymentMetadataWhere"
		}
	},
	Json: "String",
	Invoice:{
		customer:{
			filter:"CustomerWhere"
		},
		orderPayments:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		address:{
			filter:"AddressWhere"
		},
		billingSubject:{
			filter:"BillingSubjectWhere"
		},
		orderPaymentsByMeta:{
			by:"InvoiceOrderPaymentsByMetaUniqueWhere",
			filter:"OrderPaymentWhere"
		},
		paginateOrderPayments:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		}
	},
	OrderPaymentOrderBy:{
		id:"OrderDirection",
		data:"OrderDirection",
		paymentCents:"OrderDirection",
		method:"PaymentMethodOrderBy",
		failedAt:"OrderDirection",
		state:"OrderDirection",
		approvedAt:"OrderDirection",
		order:"OrderOrderBy",
		invoice:"InvoiceOrderBy",
		walletType:"OrderDirection",
		createdAt:"OrderDirection",
		publicReceiptUrl:"OrderDirection",
		priceCents:"OrderDirection",
		vatRate:"VatRateOrderBy",
		meta:"OrderPaymentMetadataOrderBy",
		otherCurrencyPriceCents:"OrderDirection"
	},
	InvoiceOrderBy:{
		id:"OrderDirection",
		createdAt:"OrderDirection",
		publicKey:"OrderDirection",
		fakturoidId:"OrderDirection",
		fakturoidData:"OrderDirection",
		legacyUrl:"OrderDirection",
		customer:"CustomerOrderBy",
		address:"AddressOrderBy",
		publicUrl:"OrderDirection",
		billingSubject:"BillingSubjectOrderBy"
	},
	OrderPaymentMetadataOrderBy:{
		id:"OrderDirection",
		paymentUrl:"OrderDirection",
		orderPayment:"OrderPaymentOrderBy"
	},
	BillingSubject:{
		country:{
			filter:"CountryWhere"
		},
		customer:{
			filter:"CustomerWhere"
		},
		invoices:{
			filter:"InvoiceWhere",
			orderBy:"InvoiceOrderBy"
		},
		defaultBillingSubjectOfCustomer:{
			filter:"CustomerWhere"
		},
		orders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		invoicesByPublicKey:{
			by:"BillingSubjectInvoicesByPublicKeyUniqueWhere",
			filter:"InvoiceWhere"
		},
		invoicesByFakturoidId:{
			by:"BillingSubjectInvoicesByFakturoidIdUniqueWhere",
			filter:"InvoiceWhere"
		},
		invoicesByOrderPayments:{
			by:"BillingSubjectInvoicesByOrderPaymentsUniqueWhere",
			filter:"InvoiceWhere"
		},
		ordersBySeq:{
			by:"BillingSubjectOrdersBySeqUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDoubleshotLegacyId:{
			by:"BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDoubleShotLegacyNumber:{
			by:"BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDiscounts:{
			by:"BillingSubjectOrdersByDiscountsUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByDelivery:{
			by:"BillingSubjectOrdersByDeliveryUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByItems:{
			by:"BillingSubjectOrdersByItemsUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByCart:{
			by:"BillingSubjectOrdersByCartUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByPriceLines:{
			by:"BillingSubjectOrdersByPriceLinesUniqueWhere",
			filter:"OrderWhere"
		},
		ordersByPayments:{
			by:"BillingSubjectOrdersByPaymentsUniqueWhere",
			filter:"OrderWhere"
		},
		ordersBySummary:{
			by:"BillingSubjectOrdersBySummaryUniqueWhere",
			filter:"OrderWhere"
		},
		paginateInvoices:{
			filter:"InvoiceWhere",
			orderBy:"InvoiceOrderBy"
		},
		paginateOrders:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		}
	},
	BillingSubjectInvoicesByPublicKeyUniqueWhere:{

	},
	BillingSubjectInvoicesByFakturoidIdUniqueWhere:{

	},
	BillingSubjectInvoicesByOrderPaymentsUniqueWhere:{
		orderPayments:"OrderPaymentUniqueWhere"
	},
	BillingSubjectOrdersBySeqUniqueWhere:{

	},
	BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere:{

	},
	BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere:{

	},
	BillingSubjectOrdersByDiscountsUniqueWhere:{
		discounts:"OrderDiscountUniqueWhere"
	},
	BillingSubjectOrdersByDeliveryUniqueWhere:{
		delivery:"OrderDeliveryUniqueWhere"
	},
	BillingSubjectOrdersByItemsUniqueWhere:{
		items:"OrderItemUniqueWhere"
	},
	BillingSubjectOrdersByCartUniqueWhere:{
		cart:"CartUniqueWhere"
	},
	BillingSubjectOrdersByPriceLinesUniqueWhere:{
		priceLines:"OrderPriceLineUniqueWhere"
	},
	BillingSubjectOrdersByPaymentsUniqueWhere:{
		payments:"OrderPaymentUniqueWhere"
	},
	BillingSubjectOrdersBySummaryUniqueWhere:{
		summary:"OrderSummaryUniqueWhere"
	},
	InvoiceOrderPaymentsByMetaUniqueWhere:{
		meta:"OrderPaymentMetadataUniqueWhere"
	},
	OrderPaymentMetadata:{
		orderPayment:{
			filter:"OrderPaymentWhere"
		}
	},
	OrderPriceLineOrderBy:{
		id:"OrderDirection",
		unitPriceCents:"OrderDirection",
		quantity:"OrderDirection",
		type:"OrderDirection",
		orderItem:"OrderItemOrderBy",
		orderDiscount:"OrderDiscountOrderBy",
		orderPayment:"OrderPaymentOrderBy",
		orderDelivery:"OrderDeliveryOrderBy",
		vatRatePermille:"OrderDirection",
		vatRate:"VatRateOrderBy",
		order:"OrderOrderBy"
	},
	OrderSummary:{
		order:{
			filter:"OrderWhere"
		}
	},
	FulfillmentNoteOrderBy:{
		id:"OrderDirection",
		note:"OrderDirection",
		highlight:"OrderDirection",
		createdAt:"OrderDirection",
		emoji:"OrderDirection"
	},
	OrderDiscountsByVoucherRedemptionUniqueWhere:{
		voucherRedemption:"VoucherRedemptionUniqueWhere"
	},
	OrderItemsByVirtualProductEffectsUniqueWhere:{
		virtualProductEffects:"VirtualProductEffectUniqueWhere"
	},
	OrderPriceLinesByOrderItemUniqueWhere:{
		orderItem:"OrderItemUniqueWhere"
	},
	OrderPriceLinesByOrderDiscountUniqueWhere:{
		orderDiscount:"OrderDiscountUniqueWhere"
	},
	OrderPriceLinesByOrderPaymentUniqueWhere:{
		orderPayment:"OrderPaymentUniqueWhere"
	},
	OrderPriceLinesByOrderDeliveryUniqueWhere:{
		orderDelivery:"OrderDeliveryUniqueWhere"
	},
	OrderPaymentsByMetaUniqueWhere:{
		meta:"OrderPaymentMetadataUniqueWhere"
	},
	OrderItemVirtualProductEffectsByResultVouchersUniqueWhere:{
		resultVouchers:"VoucherUniqueWhere"
	},
	CartPriceLine:{
		item:{
			filter:"CartItemWhere"
		},
		product:{
			filter:"ProductWhere"
		},
		vatRate:{
			filter:"VatRateWhere"
		},
		cart:{
			filter:"CartWhere"
		}
	},
	CartPriceLineOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		item:"CartItemOrderBy",
		product:"ProductOrderBy",
		unitPriceCents:"OrderDirection",
		priceCents:"OrderDirection",
		quantity:"OrderDirection",
		vatRatePermille:"OrderDirection",
		vatRate:"VatRateOrderBy",
		cart:"CartOrderBy"
	},
	CartSummary:{
		cart:{
			filter:"CartWhere"
		}
	},
	CartItemsByProductUniqueWhere:{
		product:"ProductUniqueWhere"
	},
	ChannelCartsByItemsUniqueWhere:{
		items:"CartItemUniqueWhere"
	},
	ChannelCartsByOrderUniqueWhere:{
		order:"OrderUniqueWhere"
	},
	ChannelCartsByPriceLinesUniqueWhere:{
		priceLines:"CartPriceLineUniqueWhere"
	},
	ChannelCartsBySummaryUniqueWhere:{
		summary:"CartSummaryUniqueWhere"
	},
	ProductIngredientLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductIngredientCustomerRatingsByCustomerUniqueWhere:{
		customer:"CustomerUniqueWhere"
	},
	BlogLocale:{
		root:{
			filter:"BlogWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	Blog:{
		locales:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		localesByLocale:{
			by:"BlogLocalesByLocaleUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		paginateLocales:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		}
	},
	BlogLocaleOrderBy:{
		id:"OrderDirection",
		pageName:"OrderDirection",
		title:"OrderDirection",
		lead:"OrderDirection",
		root:"BlogOrderBy",
		locale:"LocaleOrderBy"
	},
	BlogOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	BlogLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BlogPostLocale:{
		root:{
			filter:"BlogPostWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		cover:{
			filter:"CoverWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		}
	},
	BlogPost:{
		locales:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		localesByLocale:{
			by:"BlogPostLocalesByLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByCover:{
			by:"BlogPostLocalesByCoverUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByContent:{
			by:"BlogPostLocalesByContentUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesByLink:{
			by:"BlogPostLocalesByLinkUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		localesBySeo:{
			by:"BlogPostLocalesBySeoUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		paginateLocales:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		}
	},
	BlogPostLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		availableForWeb:"OrderDirection",
		availableForMobile:"OrderDirection",
		root:"BlogPostOrderBy",
		locale:"LocaleOrderBy",
		cover:"CoverOrderBy",
		content:"ContentOrderBy",
		link:"LinkableOrderBy",
		seo:"SeoOrderBy"
	},
	BlogPostOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection"
	},
	CoverOrderBy:{
		id:"OrderDirection",
		primaryTitle:"OrderDirection",
		secondaryTitle:"OrderDirection",
		medium:"MediumOrderBy",
		buttonColors:"ColorPalleteOrderBy"
	},
	MediumOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		colorTheme:"OrderDirection",
		image:"ImageOrderBy",
		video:"VideoOrderBy"
	},
	VideoOrderBy:{
		id:"OrderDirection",
		src:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		duration:"OrderDirection",
		poster:"ImageOrderBy"
	},
	ColorPalleteOrderBy:{
		id:"OrderDirection",
		background:"OrderDirection",
		text:"OrderDirection"
	},
	ContentOrderBy:{
		id:"OrderDirection"
	},
	LinkableOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		blogPost:"BlogPostLocaleOrderBy",
		page:"PageLocaleOrderBy",
		redirect:"RedirectOrderBy"
	},
	PageLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		link:"LinkableOrderBy",
		root:"PageOrderBy",
		locale:"LocaleOrderBy",
		seo:"SeoOrderBy",
		content:"ContentOrderBy",
		cover:"CoverOrderBy",
		theme:"OrderDirection"
	},
	SeoOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		description:"OrderDirection",
		ogTitle:"OrderDirection",
		ogDescription:"OrderDirection",
		ogImage:"ImageOrderBy"
	},
	RedirectOrderBy:{
		id:"OrderDirection",
		link:"LinkableOrderBy",
		target:"LinkOrderBy"
	},
	LinkOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		title:"OrderDirection",
		externalLink:"OrderDirection",
		internalLink:"LinkableOrderBy"
	},
	BlogPostLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	BlogPostLocalesByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	BlogPostLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	BlogPostLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	BlogPostLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	Cover:{
		medium:{
			filter:"MediumWhere"
		},
		buttonColors:{
			filter:"ColorPalleteWhere"
		}
	},
	Medium:{
		image:{
			filter:"ImageWhere"
		},
		video:{
			filter:"VideoWhere"
		}
	},
	Video:{
		poster:{
			filter:"ImageWhere"
		}
	},
	Content:{
		blocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		blocksByReferences:{
			by:"ContentBlocksByReferencesUniqueWhere",
			filter:"ContentBlockWhere"
		},
		paginateBlocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		}
	},
	ContentBlock:{
		content:{
			filter:"ContentWhere"
		},
		references:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		referencesByProducts:{
			by:"ContentBlockReferencesByProductsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByProductBanners:{
			by:"ContentBlockReferencesByProductBannersUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByDeliveryRegions:{
			by:"ContentBlockReferencesByDeliveryRegionsUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		paginateReferences:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		}
	},
	ContentReference:{
		block:{
			filter:"ContentBlockWhere"
		},
		colorPallete:{
			filter:"ColorPalleteWhere"
		},
		medium:{
			filter:"MediumWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		blogPosts:{
			filter:"BlogPostListWhere"
		},
		links:{
			filter:"LinkListWhere"
		},
		products:{
			filter:"ProductListWhere"
		},
		hero:{
			filter:"HeroWhere"
		},
		gallery:{
			filter:"GalleryWhere"
		},
		socialsAndApps:{
			filter:"SocialsAndAppsWhere"
		},
		productGrid:{
			filter:"ProductGridWhere"
		},
		productBanners:{
			filter:"ProductBannerListWhere"
		},
		deliveryRegions:{
			filter:"DeliveryRegionsWhere"
		},
		textList:{
			filter:"TextListWhere"
		},
		gridTiles:{
			filter:"GridTilesWhere"
		}
	},
	Link:{
		internalLink:{
			filter:"LinkableWhere"
		}
	},
	Linkable:{
		blogPost:{
			filter:"BlogPostLocaleWhere"
		},
		page:{
			filter:"PageLocaleWhere"
		},
		redirect:{
			filter:"RedirectWhere"
		}
	},
	PageLocale:{
		link:{
			filter:"LinkableWhere"
		},
		root:{
			filter:"PageWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		cover:{
			filter:"CoverWhere"
		}
	},
	Page:{
		locales:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		localesByLocale:{
			by:"PageLocalesByLocaleUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesByLink:{
			by:"PageLocalesByLinkUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesBySeo:{
			by:"PageLocalesBySeoUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesByContent:{
			by:"PageLocalesByContentUniqueWhere",
			filter:"PageLocaleWhere"
		},
		localesByCover:{
			by:"PageLocalesByCoverUniqueWhere",
			filter:"PageLocaleWhere"
		},
		paginateLocales:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		}
	},
	PageLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	PageLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	PageLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	PageLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	PageLocalesByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	Seo:{
		ogImage:{
			filter:"ImageWhere"
		}
	},
	Redirect:{
		link:{
			filter:"LinkableWhere"
		},
		target:{
			filter:"LinkWhere"
		}
	},
	BlogPostList:{
		items:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		paginateItems:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		}
	},
	BlogPostListItem:{
		list:{
			filter:"BlogPostListWhere"
		},
		blogPost:{
			filter:"BlogPostLocaleWhere"
		}
	},
	BlogPostListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		caption:"OrderDirection",
		list:"BlogPostListOrderBy",
		blogPost:"BlogPostLocaleOrderBy"
	},
	BlogPostListOrderBy:{
		id:"OrderDirection"
	},
	LinkList:{
		items:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateItems:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		}
	},
	LinkListItem:{
		list:{
			filter:"LinkListWhere"
		},
		link:{
			filter:"LinkWhere"
		}
	},
	LinkListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		isPrimary:"OrderDirection",
		list:"LinkListOrderBy",
		link:"LinkOrderBy"
	},
	LinkListOrderBy:{
		id:"OrderDirection"
	},
	Hero:{
		medium:{
			filter:"MediumWhere"
		}
	},
	Gallery:{
		items:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateItems:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		}
	},
	GalleryItem:{
		list:{
			filter:"GalleryWhere"
		},
		medium:{
			filter:"MediumWhere"
		}
	},
	GalleryItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		caption:"OrderDirection",
		list:"GalleryOrderBy",
		medium:"MediumOrderBy"
	},
	GalleryOrderBy:{
		id:"OrderDirection"
	},
	ProductGrid:{
		categories:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		categoriesByItems:{
			by:"ProductGridCategoriesByItemsUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		categoriesByImages:{
			by:"ProductGridCategoriesByImagesUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		paginateCategories:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		}
	},
	ProductGridCategory:{
		root:{
			filter:"ProductGridWhere"
		},
		items:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		images:{
			filter:"ImageListWhere"
		},
		paginateItems:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		}
	},
	ProductGridItem:{
		product:{
			filter:"ProductWhere"
		},
		medium:{
			filter:"MediumWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		category:{
			filter:"ProductGridCategoryWhere"
		}
	},
	ProductGridItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		lead:"OrderDirection",
		product:"ProductOrderBy",
		medium:"MediumOrderBy",
		link:"LinkOrderBy",
		category:"ProductGridCategoryOrderBy",
		description:"OrderDirection"
	},
	ProductGridCategoryOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		root:"ProductGridOrderBy",
		images:"ImageListOrderBy"
	},
	ProductGridOrderBy:{
		id:"OrderDirection",
		isCarousel:"OrderDirection",
		type:"OrderDirection"
	},
	ImageListOrderBy:{
		id:"OrderDirection"
	},
	ImageList:{
		items:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		itemsByImage:{
			by:"ImageListItemsByImageUniqueWhere",
			filter:"ImageListItemWhere"
		},
		paginateItems:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		}
	},
	ImageListItem:{
		list:{
			filter:"ImageListWhere"
		},
		image:{
			filter:"ImageWhere"
		}
	},
	ImageListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ImageListOrderBy",
		image:"ImageOrderBy"
	},
	ImageListItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	ProductGridCategoriesByItemsUniqueWhere:{
		items:"ProductGridItemUniqueWhere"
	},
	ProductGridItemUniqueWhere:{
		id:"UUID"
	},
	ProductGridCategoriesByImagesUniqueWhere:{
		images:"ImageListUniqueWhere"
	},
	ProductBannerList:{
		items:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		paginateItems:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		}
	},
	ProductBannerItem:{
		list:{
			filter:"ProductBannerListWhere"
		},
		banner:{
			filter:"ProductBannerWhere"
		}
	},
	ProductBanner:{
		button:{
			filter:"LinkWhere"
		},
		backgroundImage:{
			filter:"ImageWhere"
		},
		productImage:{
			filter:"ImageWhere"
		},
		product:{
			filter:"ProductWhere"
		}
	},
	ProductBannerItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProductBannerListOrderBy",
		banner:"ProductBannerOrderBy",
		size:"OrderDirection"
	},
	ProductBannerListOrderBy:{
		id:"OrderDirection"
	},
	ProductBannerOrderBy:{
		id:"OrderDirection",
		beforeTitle:"OrderDirection",
		title:"OrderDirection",
		label:"OrderDirection",
		button:"LinkOrderBy",
		backgroundImage:"ImageOrderBy",
		productImage:"ImageOrderBy",
		product:"ProductOrderBy"
	},
	DeliveryRegions:{
		items:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		itemsByImage:{
			by:"DeliveryRegionsItemsByImageUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		itemsByTextList:{
			by:"DeliveryRegionsItemsByTextListUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		itemsByGallery:{
			by:"DeliveryRegionsItemsByGalleryUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		paginateItems:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		}
	},
	DeliveryRegionsItem:{
		list:{
			filter:"DeliveryRegionsWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		textList:{
			filter:"TextListWhere"
		},
		gallery:{
			filter:"ImageListWhere"
		}
	},
	TextList:{
		items:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateItems:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		}
	},
	TextItem:{
		list:{
			filter:"TextListWhere"
		}
	},
	TextItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		text:"OrderDirection",
		list:"TextListOrderBy",
		title:"OrderDirection"
	},
	TextListOrderBy:{
		id:"OrderDirection"
	},
	DeliveryRegionsItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		offerLabel:"OrderDirection",
		list:"DeliveryRegionsOrderBy",
		image:"ImageOrderBy",
		textList:"TextListOrderBy",
		gallery:"ImageListOrderBy"
	},
	DeliveryRegionsOrderBy:{
		id:"OrderDirection"
	},
	DeliveryRegionsItemsByImageUniqueWhere:{
		image:"ImageUniqueWhere"
	},
	DeliveryRegionsItemsByTextListUniqueWhere:{
		textList:"TextListUniqueWhere"
	},
	DeliveryRegionsItemsByGalleryUniqueWhere:{
		gallery:"ImageListUniqueWhere"
	},
	GridTiles:{
		items:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		},
		itemsByLink:{
			by:"GridTilesItemsByLinkUniqueWhere",
			filter:"GridTileItemWhere"
		},
		paginateItems:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		}
	},
	GridTileItem:{
		list:{
			filter:"GridTilesWhere"
		},
		medium:{
			filter:"MediumWhere"
		},
		link:{
			filter:"LinkWhere"
		}
	},
	GridTileItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		text:"OrderDirection",
		list:"GridTilesOrderBy",
		medium:"MediumOrderBy",
		link:"LinkOrderBy"
	},
	GridTilesOrderBy:{
		id:"OrderDirection"
	},
	GridTilesItemsByLinkUniqueWhere:{
		link:"LinkUniqueWhere"
	},
	LinkUniqueWhere:{
		id:"UUID"
	},
	ContentReferenceOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		primaryText:"OrderDirection",
		secondaryText:"OrderDirection",
		jsonContent:"OrderDirection",
		block:"ContentBlockOrderBy",
		colorPallete:"ColorPalleteOrderBy",
		medium:"MediumOrderBy",
		link:"LinkOrderBy",
		blogPosts:"BlogPostListOrderBy",
		links:"LinkListOrderBy",
		products:"ProductListOrderBy",
		contentSize:"OrderDirection",
		misc:"OrderDirection",
		hero:"HeroOrderBy",
		gallery:"GalleryOrderBy",
		socialsAndApps:"SocialsAndAppsOrderBy",
		productGrid:"ProductGridOrderBy",
		productBanners:"ProductBannerListOrderBy",
		deliveryRegions:"DeliveryRegionsOrderBy",
		textList:"TextListOrderBy",
		gridTiles:"GridTilesOrderBy"
	},
	ContentBlockOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		json:"OrderDirection",
		content:"ContentOrderBy"
	},
	HeroOrderBy:{
		id:"OrderDirection",
		showLogo:"OrderDirection",
		middleContent:"OrderDirection",
		medium:"MediumOrderBy"
	},
	SocialsAndAppsOrderBy:{
		id:"OrderDirection",
		instagram:"OrderDirection",
		appStore:"OrderDirection",
		googlePlay:"OrderDirection"
	},
	ContentBlockReferencesByProductsUniqueWhere:{
		products:"ProductListUniqueWhere"
	},
	ContentBlockReferencesByProductBannersUniqueWhere:{
		productBanners:"ProductBannerListUniqueWhere"
	},
	ContentBlockReferencesByDeliveryRegionsUniqueWhere:{
		deliveryRegions:"DeliveryRegionsUniqueWhere"
	},
	ContentBlocksByReferencesUniqueWhere:{
		references:"ContentReferenceUniqueWhere"
	},
	TranslationCataloguesIdentifier:{
		catalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		catalogueByDomain:{
			by:"TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		catalogueByValues:{
			by:"TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		paginateCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		}
	},
	TranslationCatalogue:{
		domain:{
			filter:"TranslationDomainWhere"
		},
		fallback:{
			filter:"TranslationCatalogueWhere"
		},
		values:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		identifier:{
			filter:"TranslationCataloguesIdentifierWhere"
		},
		valuesByKey:{
			by:"TranslationCatalogueValuesByKeyUniqueWhere",
			filter:"TranslationValueWhere"
		},
		paginateValues:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		}
	},
	TranslationDomain:{
		catalogues:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		keys:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		cataloguesByIdentifier:{
			by:"TranslationDomainCataloguesByIdentifierUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		cataloguesByValues:{
			by:"TranslationDomainCataloguesByValuesUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		keysByIdentifier:{
			by:"TranslationDomainKeysByIdentifierUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		keysByValues:{
			by:"TranslationDomainKeysByValuesUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		paginateCatalogues:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateKeys:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		}
	},
	TranslationCatalogueOrderBy:{
		id:"OrderDirection",
		domain:"TranslationDomainOrderBy",
		fallback:"TranslationCatalogueOrderBy",
		identifier:"TranslationCataloguesIdentifierOrderBy"
	},
	TranslationDomainOrderBy:{
		id:"OrderDirection",
		identifier:"OrderDirection",
		name:"OrderDirection"
	},
	TranslationKey:{
		domain:{
			filter:"TranslationDomainWhere"
		},
		values:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		valuesByCatalogue:{
			by:"TranslationKeyValuesByCatalogueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		paginateValues:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		}
	},
	TranslationValue:{
		catalogue:{
			filter:"TranslationCatalogueWhere"
		},
		key:{
			filter:"TranslationKeyWhere"
		}
	},
	TranslationValueOrderBy:{
		id:"OrderDirection",
		value:"OrderDirection",
		catalogue:"TranslationCatalogueOrderBy",
		key:"TranslationKeyOrderBy"
	},
	TranslationKeyOrderBy:{
		id:"OrderDirection",
		identifier:"OrderDirection",
		contentType:"OrderDirection",
		note:"OrderDirection",
		domain:"TranslationDomainOrderBy"
	},
	TranslationKeyValuesByCatalogueUniqueWhere:{
		catalogue:"TranslationCatalogueUniqueWhere"
	},
	TranslationDomainCataloguesByIdentifierUniqueWhere:{
		identifier:"TranslationCataloguesIdentifierUniqueWhere"
	},
	TranslationDomainCataloguesByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationDomainKeysByIdentifierUniqueWhere:{

	},
	TranslationDomainKeysByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationCatalogueValuesByKeyUniqueWhere:{
		key:"TranslationKeyUniqueWhere"
	},
	TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere:{
		domain:"TranslationDomainUniqueWhere"
	},
	TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	ProductPackingLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		shortName:"OrderDirection",
		description:"OrderDirection",
		root:"ProductPackingOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductLocale:{
		root:{
			filter:"ProductWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		featureList:{
			filter:"TextListWhere"
		}
	},
	ProductLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		description:"OrderDirection",
		root:"ProductOrderBy",
		locale:"LocaleOrderBy",
		featureList:"TextListOrderBy",
		printableName:"OrderDirection",
		firstSticker:"OrderDirection",
		secondSticker:"OrderDirection"
	},
	ProductRecipeLocale:{
		root:{
			filter:"ProductRecipeWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductRecipe:{
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		image:{
			filter:"ImageWhere"
		},
		icon:{
			filter:"ImageWhere"
		},
		ingredients:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		locales:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		freshingContainer:{
			filter:"FreshingContainerWhere"
		},
		productsByCode:{
			by:"ProductRecipeProductsByCodeUniqueWhere",
			filter:"ProductWhere"
		},
		productsByStocks:{
			by:"ProductRecipeProductsByStocksUniqueWhere",
			filter:"ProductWhere"
		},
		productsByGroupPrices:{
			by:"ProductRecipeProductsByGroupPricesUniqueWhere",
			filter:"ProductWhere"
		},
		productsByCartItems:{
			by:"ProductRecipeProductsByCartItemsUniqueWhere",
			filter:"ProductWhere"
		},
		productsByOrderItems:{
			by:"ProductRecipeProductsByOrderItemsUniqueWhere",
			filter:"ProductWhere"
		},
		productsByLocales:{
			by:"ProductRecipeProductsByLocalesUniqueWhere",
			filter:"ProductWhere"
		},
		productsByCustomerPrices:{
			by:"ProductRecipeProductsByCustomerPricesUniqueWhere",
			filter:"ProductWhere"
		},
		productsByVirtualProduct:{
			by:"ProductRecipeProductsByVirtualProductUniqueWhere",
			filter:"ProductWhere"
		},
		productsByMeta:{
			by:"ProductRecipeProductsByMetaUniqueWhere",
			filter:"ProductWhere"
		},
		productsByPage:{
			by:"ProductRecipeProductsByPageUniqueWhere",
			filter:"ProductWhere"
		},
		localesByLocale:{
			by:"ProductRecipeLocalesByLocaleUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateIngredients:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		paginateLocales:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		}
	},
	ProductIngredientItem:{
		recipe:{
			filter:"ProductRecipeWhere"
		},
		ingredient:{
			filter:"ProductIngredientWhere"
		}
	},
	ProductIngredientItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		internalOrder:"OrderDirection",
		recipe:"ProductRecipeOrderBy",
		ingredient:"ProductIngredientOrderBy"
	},
	ProductRecipeLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductRecipeOrderBy",
		locale:"LocaleOrderBy",
		description:"OrderDirection"
	},
	ProductRecipeProductsByCodeUniqueWhere:{

	},
	ProductRecipeProductsByStocksUniqueWhere:{
		stocks:"ProductStockUniqueWhere"
	},
	ProductRecipeProductsByGroupPricesUniqueWhere:{
		groupPrices:"ProductGroupPriceUniqueWhere"
	},
	ProductRecipeProductsByCartItemsUniqueWhere:{
		cartItems:"CartItemUniqueWhere"
	},
	ProductRecipeProductsByOrderItemsUniqueWhere:{
		orderItems:"OrderItemUniqueWhere"
	},
	ProductRecipeProductsByLocalesUniqueWhere:{
		locales:"ProductLocaleUniqueWhere"
	},
	ProductRecipeProductsByCustomerPricesUniqueWhere:{
		customerPrices:"ProductCustomerPriceUniqueWhere"
	},
	ProductRecipeProductsByVirtualProductUniqueWhere:{
		virtualProduct:"VirtualProductUniqueWhere"
	},
	ProductRecipeProductsByMetaUniqueWhere:{
		meta:"ProductMetadataUniqueWhere"
	},
	ProductRecipeProductsByPageUniqueWhere:{
		page:"PageUniqueWhere"
	},
	ProductRecipeLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	IdentificationCodeLocale:{
		root:{
			filter:"IdentificationCodeWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	IdentificationCode:{
		locales:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		localesByLocale:{
			by:"IdentificationCodeLocalesByLocaleUniqueWhere",
			filter:"IdentificationCodeLocaleWhere"
		},
		paginateLocales:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		}
	},
	IdentificationCodeLocaleOrderBy:{
		id:"OrderDirection",
		successMessage:"OrderDirection",
		root:"IdentificationCodeOrderBy",
		locale:"LocaleOrderBy"
	},
	IdentificationCodeOrderBy:{
		id:"OrderDirection",
		code:"OrderDirection",
		createdAt:"OrderDirection",
		internalName:"OrderDirection"
	},
	IdentificationCodeLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	SubscriptionBoxLocale:{
		root:{
			filter:"SubscriptionBoxWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		texts:{
			filter:"TextListWhere"
		},
		secondaryTexts:{
			filter:"TextListWhere"
		}
	},
	SubscriptionBox:{
		locales:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		localesByLocale:{
			by:"SubscriptionBoxLocalesByLocaleUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		localesByTexts:{
			by:"SubscriptionBoxLocalesByTextsUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		localesBySecondaryTexts:{
			by:"SubscriptionBoxLocalesBySecondaryTextsUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		paginateLocales:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		}
	},
	SubscriptionBoxLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		stickerText:"OrderDirection",
		descriptionText:"OrderDirection",
		root:"SubscriptionBoxOrderBy",
		locale:"LocaleOrderBy",
		secondaryTitle:"OrderDirection",
		texts:"TextListOrderBy",
		secondaryTexts:"TextListOrderBy"
	},
	SubscriptionBoxOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	SubscriptionBoxLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	SubscriptionBoxLocalesByTextsUniqueWhere:{
		texts:"TextListUniqueWhere"
	},
	SubscriptionBoxLocalesBySecondaryTextsUniqueWhere:{
		secondaryTexts:"TextListUniqueWhere"
	},
	LocaleBusinessCategoriesByRootUniqueWhere:{
		root:"BusinessCategoryUniqueWhere"
	},
	LocaleProductCategoryListsByRootUniqueWhere:{
		root:"ProductCategoryListUniqueWhere"
	},
	LocaleProductIngredientsByRootUniqueWhere:{
		root:"ProductIngredientUniqueWhere"
	},
	LocaleOrderRecurrencesByRootUniqueWhere:{
		root:"OrderRecurrenceUniqueWhere"
	},
	LocaleBlogsByRootUniqueWhere:{
		root:"BlogUniqueWhere"
	},
	LocaleBlogPostsByRootUniqueWhere:{
		root:"BlogPostUniqueWhere"
	},
	LocaleBlogPostsByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	LocaleBlogPostsByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleBlogPostsByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleBlogPostsBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocalePagesByRootUniqueWhere:{
		root:"PageUniqueWhere"
	},
	LocalePagesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocalePagesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocalePagesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocalePagesByCoverUniqueWhere:{
		cover:"CoverUniqueWhere"
	},
	LocaleAllergensByRootUniqueWhere:{
		root:"AllergenUniqueWhere"
	},
	LocaleProductPackingsByRootUniqueWhere:{
		root:"ProductPackingUniqueWhere"
	},
	LocaleProductsByRootUniqueWhere:{
		root:"ProductUniqueWhere"
	},
	LocaleProductsByFeatureListUniqueWhere:{
		featureList:"TextListUniqueWhere"
	},
	LocaleProductRecipesByRootUniqueWhere:{
		root:"ProductRecipeUniqueWhere"
	},
	LocaleDeliveryMethodsByRootUniqueWhere:{
		root:"DeliveryMethodUniqueWhere"
	},
	LocaleIdentificationCodesByRootUniqueWhere:{
		root:"IdentificationCodeUniqueWhere"
	},
	LocaleSubscriptionBoxesByRootUniqueWhere:{
		root:"SubscriptionBoxUniqueWhere"
	},
	LocaleSubscriptionBoxesByTextsUniqueWhere:{
		texts:"TextListUniqueWhere"
	},
	LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere:{
		secondaryTexts:"TextListUniqueWhere"
	},
	ProductPackingLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductStock:{
		product:{
			filter:"ProductWhere"
		},
		store:{
			filter:"StoreWhere"
		}
	},
	ProductStockOrderBy:{
		id:"OrderDirection",
		quantity:"OrderDirection",
		product:"ProductOrderBy",
		store:"StoreOrderBy"
	},
	StoreOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection",
		description:"OrderDirection",
		internalNote:"OrderDirection"
	},
	ProductGroupPrice:{
		group:{
			filter:"CustomerGroupWhere"
		},
		product:{
			filter:"ProductWhere"
		}
	},
	CustomerGroup:{
		productPrices:{
			filter:"ProductGroupPriceWhere",
			orderBy:"ProductGroupPriceOrderBy"
		},
		customers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		productPricesByProduct:{
			by:"CustomerGroupProductPricesByProductUniqueWhere",
			filter:"ProductGroupPriceWhere"
		},
		customersByDoubleshotLegacyId:{
			by:"CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByAddresses:{
			by:"CustomerGroupCustomersByAddressesUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByDefaultBillingAddress:{
			by:"CustomerGroupCustomersByDefaultBillingAddressUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByCredits:{
			by:"CustomerGroupCustomersByCreditsUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByOrders:{
			by:"CustomerGroupCustomersByOrdersUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByPermissions:{
			by:"CustomerGroupCustomersByPermissionsUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByName:{
			by:"CustomerGroupCustomersByNameUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByIngredientRatings:{
			by:"CustomerGroupCustomersByIngredientRatingsUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByBusiness:{
			by:"CustomerGroupCustomersByBusinessUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByCarts:{
			by:"CustomerGroupCustomersByCartsUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByUser:{
			by:"CustomerGroupCustomersByUserUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByInvoices:{
			by:"CustomerGroupCustomersByInvoicesUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByInvoicingPlan:{
			by:"CustomerGroupCustomersByInvoicingPlanUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByBillingSubjects:{
			by:"CustomerGroupCustomersByBillingSubjectsUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByOfferedProducts:{
			by:"CustomerGroupCustomersByOfferedProductsUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByDefaultDeliveryAddress:{
			by:"CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByDefaultBillingSubject:{
			by:"CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByAnonymousSession:{
			by:"CustomerGroupCustomersByAnonymousSessionUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByBoughtVouchers:{
			by:"CustomerGroupCustomersByBoughtVouchersUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByOwnsVouchers:{
			by:"CustomerGroupCustomersByOwnsVouchersUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByRedeemedVouchers:{
			by:"CustomerGroupCustomersByRedeemedVouchersUniqueWhere",
			filter:"CustomerWhere"
		},
		customersByMeta:{
			by:"CustomerGroupCustomersByMetaUniqueWhere",
			filter:"CustomerWhere"
		},
		paginateProductPrices:{
			filter:"ProductGroupPriceWhere",
			orderBy:"ProductGroupPriceOrderBy"
		},
		paginateCustomers:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		}
	},
	ProductGroupPriceOrderBy:{
		id:"OrderDirection",
		priceCents:"OrderDirection",
		group:"CustomerGroupOrderBy",
		product:"ProductOrderBy"
	},
	CustomerGroupProductPricesByProductUniqueWhere:{
		product:"ProductUniqueWhere"
	},
	CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere:{

	},
	CustomerGroupCustomersByAddressesUniqueWhere:{
		addresses:"AddressUniqueWhere"
	},
	CustomerGroupCustomersByDefaultBillingAddressUniqueWhere:{
		defaultBillingAddress:"AddressUniqueWhere"
	},
	CustomerGroupCustomersByCreditsUniqueWhere:{
		credits:"CustomerCreditUniqueWhere"
	},
	CustomerGroupCustomersByOrdersUniqueWhere:{
		orders:"OrderUniqueWhere"
	},
	CustomerGroupCustomersByPermissionsUniqueWhere:{
		permissions:"CustomerPermissionsUniqueWhere"
	},
	CustomerGroupCustomersByNameUniqueWhere:{
		name:"CustomerNameUniqueWhere"
	},
	CustomerGroupCustomersByIngredientRatingsUniqueWhere:{
		ingredientRatings:"CustomerIngredientRatingUniqueWhere"
	},
	CustomerGroupCustomersByBusinessUniqueWhere:{
		business:"BusinessCustomerUniqueWhere"
	},
	CustomerGroupCustomersByCartsUniqueWhere:{
		carts:"CartUniqueWhere"
	},
	CustomerGroupCustomersByUserUniqueWhere:{
		user:"UserUniqueWhere"
	},
	CustomerGroupCustomersByInvoicesUniqueWhere:{
		invoices:"InvoiceUniqueWhere"
	},
	CustomerGroupCustomersByInvoicingPlanUniqueWhere:{
		invoicingPlan:"InvoicingPlanUniqueWhere"
	},
	CustomerGroupCustomersByBillingSubjectsUniqueWhere:{
		billingSubjects:"BillingSubjectUniqueWhere"
	},
	CustomerGroupCustomersByOfferedProductsUniqueWhere:{
		offeredProducts:"ProductCustomerPriceUniqueWhere"
	},
	CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere:{
		defaultDeliveryAddress:"AddressUniqueWhere"
	},
	CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere:{
		defaultBillingSubject:"BillingSubjectUniqueWhere"
	},
	CustomerGroupCustomersByAnonymousSessionUniqueWhere:{
		anonymousSession:"AnonymousSessionUniqueWhere"
	},
	CustomerGroupCustomersByBoughtVouchersUniqueWhere:{
		boughtVouchers:"VoucherUniqueWhere"
	},
	CustomerGroupCustomersByOwnsVouchersUniqueWhere:{
		ownsVouchers:"VoucherUniqueWhere"
	},
	CustomerGroupCustomersByRedeemedVouchersUniqueWhere:{
		redeemedVouchers:"VoucherRedemptionUniqueWhere"
	},
	CustomerGroupCustomersByMetaUniqueWhere:{
		meta:"CustomerMetadataUniqueWhere"
	},
	ProductCustomerPrice:{
		customer:{
			filter:"CustomerWhere"
		},
		product:{
			filter:"ProductWhere"
		}
	},
	ProductCustomerPriceOrderBy:{
		id:"OrderDirection",
		groupDiscountPermille:"OrderDirection",
		groupPriceCents:"OrderDirection",
		fullPriceCents:"OrderDirection",
		discountedPriceCents:"OrderDirection",
		priceCents:"OrderDirection",
		offered:"OrderDirection",
		notDiscountable:"OrderDirection",
		customer:"CustomerOrderBy",
		product:"ProductOrderBy"
	},
	ProductMetadata:{
		product:{
			filter:"ProductWhere"
		}
	},
	ProductStocksByStoreUniqueWhere:{
		store:"StoreUniqueWhere"
	},
	ProductGroupPricesByGroupUniqueWhere:{
		group:"CustomerGroupUniqueWhere"
	},
	ProductCartItemsByCartUniqueWhere:{
		cart:"CartUniqueWhere"
	},
	ProductOrderItemsByVirtualProductEffectsUniqueWhere:{
		virtualProductEffects:"VirtualProductEffectUniqueWhere"
	},
	ProductLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductLocalesByFeatureListUniqueWhere:{
		featureList:"TextListUniqueWhere"
	},
	ProductCustomerPricesByCustomerUniqueWhere:{
		customer:"CustomerUniqueWhere"
	},
	CustomerPermissions:{
		customer:{
			filter:"CustomerWhere"
		}
	},
	CustomerName:{
		customer:{
			filter:"CustomerWhere"
		}
	},
	User:{
		staff:{
			filter:"StaffWhere"
		},
		customer:{
			filter:"CustomerWhere"
		},
		meta:{
			filter:"UserMetadataWhere"
		}
	},
	Staff:{
		user:{
			filter:"UserWhere"
		},
		photo:{
			filter:"ImageWhere"
		}
	},
	UserMetadata:{
		user:{
			filter:"UserWhere"
		}
	},
	InvoicingPlan:{
		customer:{
			filter:"CustomerWhere"
		},
		preset:{
			filter:"InvoicingPresetWhere"
		}
	},
	InvoicingPreset:{
		invoicingPlan:{
			filter:"InvoicingPlanWhere"
		}
	},
	AnonymousSession:{
		customer:{
			filter:"CustomerWhere"
		}
	},
	CustomerMetadata:{
		customer:{
			filter:"CustomerWhere"
		}
	},
	CustomerAddressesByMetaUniqueWhere:{
		meta:"AddressMetadataUniqueWhere"
	},
	CustomerAddressesByGpsUniqueWhere:{
		gps:"GpsUniqueWhere"
	},
	CustomerAddressesByInvoicesUniqueWhere:{
		invoices:"InvoiceUniqueWhere"
	},
	CustomerAddressesByBillingAddressOfOrdersUniqueWhere:{
		billingAddressOfOrders:"OrderUniqueWhere"
	},
	CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere:{
		deliveryAddressOfOrderDeliveries:"OrderDeliveryUniqueWhere"
	},
	CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere:{
		defaultDeliveryAddressOfCustomer:"CustomerUniqueWhere"
	},
	CustomerCreditsByVoucherRedemptionUniqueWhere:{
		voucherRedemption:"VoucherRedemptionUniqueWhere"
	},
	CustomerOrdersBySeqUniqueWhere:{

	},
	CustomerOrdersByDoubleshotLegacyIdUniqueWhere:{

	},
	CustomerOrdersByDoubleShotLegacyNumberUniqueWhere:{

	},
	CustomerOrdersByDiscountsUniqueWhere:{
		discounts:"OrderDiscountUniqueWhere"
	},
	CustomerOrdersByDeliveryUniqueWhere:{
		delivery:"OrderDeliveryUniqueWhere"
	},
	CustomerOrdersByItemsUniqueWhere:{
		items:"OrderItemUniqueWhere"
	},
	CustomerOrdersByCartUniqueWhere:{
		cart:"CartUniqueWhere"
	},
	CustomerOrdersByPriceLinesUniqueWhere:{
		priceLines:"OrderPriceLineUniqueWhere"
	},
	CustomerOrdersByPaymentsUniqueWhere:{
		payments:"OrderPaymentUniqueWhere"
	},
	CustomerOrdersBySummaryUniqueWhere:{
		summary:"OrderSummaryUniqueWhere"
	},
	CustomerIngredientRatingsByIngredientUniqueWhere:{
		ingredient:"ProductIngredientUniqueWhere"
	},
	CustomerCartsByItemsUniqueWhere:{
		items:"CartItemUniqueWhere"
	},
	CustomerCartsByOrderUniqueWhere:{
		order:"OrderUniqueWhere"
	},
	CustomerCartsByPriceLinesUniqueWhere:{
		priceLines:"CartPriceLineUniqueWhere"
	},
	CustomerCartsBySummaryUniqueWhere:{
		summary:"CartSummaryUniqueWhere"
	},
	CustomerInvoicesByPublicKeyUniqueWhere:{

	},
	CustomerInvoicesByFakturoidIdUniqueWhere:{

	},
	CustomerInvoicesByOrderPaymentsUniqueWhere:{
		orderPayments:"OrderPaymentUniqueWhere"
	},
	CustomerBillingSubjectsByInvoicesUniqueWhere:{
		invoices:"InvoiceUniqueWhere"
	},
	CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere:{
		defaultBillingSubjectOfCustomer:"CustomerUniqueWhere"
	},
	CustomerBillingSubjectsByOrdersUniqueWhere:{
		orders:"OrderUniqueWhere"
	},
	CustomerOfferedProductsByProductUniqueWhere:{
		product:"ProductUniqueWhere"
	},
	CustomerBoughtVouchersByCodeUniqueWhere:{

	},
	CustomerBoughtVouchersByRedemptionUniqueWhere:{
		redemption:"VoucherRedemptionUniqueWhere"
	},
	CustomerOwnsVouchersByCodeUniqueWhere:{

	},
	CustomerOwnsVouchersByRedemptionUniqueWhere:{
		redemption:"VoucherRedemptionUniqueWhere"
	},
	CustomerRedeemedVouchersByCustomerCreditUniqueWhere:{
		customerCredit:"CustomerCreditUniqueWhere"
	},
	CustomerRedeemedVouchersByOrderDiscountUniqueWhere:{
		orderDiscount:"OrderDiscountUniqueWhere"
	},
	CustomerRedeemedVouchersByVoucherUniqueWhere:{
		voucher:"VoucherUniqueWhere"
	},
	Gps:{
		address:{
			filter:"AddressWhere"
		}
	},
	AddressInvoicesByPublicKeyUniqueWhere:{

	},
	AddressInvoicesByFakturoidIdUniqueWhere:{

	},
	AddressInvoicesByOrderPaymentsUniqueWhere:{
		orderPayments:"OrderPaymentUniqueWhere"
	},
	AddressBillingAddressOfOrdersBySeqUniqueWhere:{

	},
	AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere:{

	},
	AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere:{

	},
	AddressBillingAddressOfOrdersByDiscountsUniqueWhere:{
		discounts:"OrderDiscountUniqueWhere"
	},
	AddressBillingAddressOfOrdersByDeliveryUniqueWhere:{
		delivery:"OrderDeliveryUniqueWhere"
	},
	AddressBillingAddressOfOrdersByItemsUniqueWhere:{
		items:"OrderItemUniqueWhere"
	},
	AddressBillingAddressOfOrdersByCartUniqueWhere:{
		cart:"CartUniqueWhere"
	},
	AddressBillingAddressOfOrdersByPriceLinesUniqueWhere:{
		priceLines:"OrderPriceLineUniqueWhere"
	},
	AddressBillingAddressOfOrdersByPaymentsUniqueWhere:{
		payments:"OrderPaymentUniqueWhere"
	},
	AddressBillingAddressOfOrdersBySummaryUniqueWhere:{
		summary:"OrderSummaryUniqueWhere"
	},
	AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere:{

	},
	AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere:{
		order:"OrderUniqueWhere"
	},
	AddressCreateInput:{
		customer:"AddressCreateCustomerEntityRelationInput",
		country:"AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"AddressCreateDeliveryZoneEntityRelationInput",
		gps:"AddressCreateGpsEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"AddressCreateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"
	},
	AddressCreateCustomerEntityRelationInput:{
		connect:"CustomerUniqueWhere"
	},
	AddressCreateCountryEntityRelationInput:{
		connect:"CountryUniqueWhere"
	},
	CountryUniqueWhere:{
		id:"UUID"
	},
	AddressCreateDeliveryZoneEntityRelationInput:{
		connect:"DeliveryZoneUniqueWhere"
	},
	DeliveryZoneUniqueWhere:{
		id:"UUID",
		addresses:"AddressUniqueWhere",
		addressesMetadata:"AddressMetadataUniqueWhere"
	},
	AddressCreateGpsEntityRelationInput:{
		connect:"GpsUniqueWhere",
		create:"GpsWithoutAddressCreateInput"
	},
	GpsWithoutAddressCreateInput:{

	},
	AddressCreateBillingAddressOfOrdersEntityRelationInput:{
		connect:"OrderUniqueWhere",
		create:"OrderWithoutBillingAddressCreateInput"
	},
	OrderWithoutBillingAddressCreateInput:{
		state:"OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"OrderCreateCustomerEntityRelationInput",
		channel:"OrderCreateChannelEntityRelationInput",
		delivery:"OrderCreateDeliveryEntityRelationInput",
		items:"OrderCreateItemsEntityRelationInput",
		cart:"OrderCreateCartEntityRelationInput",
		createdAt:"DateTime",
		billingSubject:"OrderCreateBillingSubjectEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	OrderCreateCustomerEntityRelationInput:{
		connect:"CustomerUniqueWhere"
	},
	OrderCreateChannelEntityRelationInput:{
		connect:"ChannelUniqueWhere"
	},
	ChannelUniqueWhere:{
		id:"UUID",
		code:"ChannelCode",
		payments:"ChannelPaymentUniqueWhere",
		deliveries:"ChannelDeliveryUniqueWhere",
		carts:"CartUniqueWhere"
	},
	ChannelPaymentUniqueWhere:{
		id:"UUID"
	},
	OrderCreateDeliveryEntityRelationInput:{
		connect:"OrderDeliveryUniqueWhere",
		create:"OrderDeliveryWithoutOrderCreateInput"
	},
	OrderDeliveryWithoutOrderCreateInput:{
		deliveredAt:"DateTime",
		method:"OrderDeliveryCreateMethodEntityRelationInput",
		vatRate:"OrderDeliveryCreateVatRateEntityRelationInput",
		expectedAt:"DateTime",
		state:"OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		prepareAt:"DateTime",
		pickedAt:"DateTime",
		address:"OrderDeliveryCreateAddressEntityRelationInput"
	},
	OrderDeliveryCreateMethodEntityRelationInput:{
		connect:"DeliveryMethodUniqueWhere"
	},
	OrderDeliveryCreateVatRateEntityRelationInput:{
		connect:"VatRateUniqueWhere"
	},
	VatRateUniqueWhere:{
		id:"UUID",
		preset:"VatRatePreset",
		channelDeliveries:"ChannelDeliveryUniqueWhere",
		channelPayments:"ChannelPaymentUniqueWhere"
	},
	OrderDeliveryCreateAddressEntityRelationInput:{
		connect:"AddressUniqueWhere",
		create:"AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"
	},
	AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput:{
		customer:"AddressCreateCustomerEntityRelationInput",
		country:"AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"AddressCreateDeliveryZoneEntityRelationInput",
		gps:"AddressCreateGpsEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"AddressCreateBillingAddressOfOrdersEntityRelationInput"
	},
	OrderCreateItemsEntityRelationInput:{
		connect:"OrderItemUniqueWhere",
		create:"OrderItemWithoutOrderCreateInput"
	},
	OrderItemWithoutOrderCreateInput:{
		vatRate:"OrderItemCreateVatRateEntityRelationInput",
		pickedAt:"DateTime",
		product:"OrderItemCreateProductEntityRelationInput"
	},
	OrderItemCreateVatRateEntityRelationInput:{
		connect:"VatRateUniqueWhere"
	},
	OrderItemCreateProductEntityRelationInput:{
		connect:"ProductUniqueWhere"
	},
	OrderCreateCartEntityRelationInput:{
		connect:"CartUniqueWhere",
		create:"CartWithoutOrderCreateInput"
	},
	CartWithoutOrderCreateInput:{
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		items:"CartCreateItemsEntityRelationInput",
		state:"CartState",
		createdAt:"DateTime",
		channel:"CartCreateChannelEntityRelationInput",
		customer:"CartCreateCustomerEntityRelationInput",
		deliveryAddress:"CartCreateDeliveryAddressEntityRelationInput",
		billingAddress:"CartCreateBillingAddressEntityRelationInput",
		delivery:"CartCreateDeliveryEntityRelationInput",
		payment:"CartCreatePaymentEntityRelationInput",
		billingSubject:"CartCreateBillingSubjectEntityRelationInput"
	},
	CartCreateItemsEntityRelationInput:{
		connect:"CartItemUniqueWhere",
		create:"CartItemWithoutCartCreateInput"
	},
	CartItemWithoutCartCreateInput:{
		product:"CartItemCreateProductEntityRelationInput",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	CartItemCreateProductEntityRelationInput:{
		connect:"ProductUniqueWhere"
	},
	CartCreateChannelEntityRelationInput:{
		connect:"ChannelUniqueWhere"
	},
	CartCreateCustomerEntityRelationInput:{
		connect:"CustomerUniqueWhere"
	},
	CartCreateDeliveryAddressEntityRelationInput:{
		connect:"AddressUniqueWhere",
		create:"AddressCreateInput"
	},
	CartCreateBillingAddressEntityRelationInput:{
		connect:"AddressUniqueWhere",
		create:"AddressCreateInput"
	},
	CartCreateDeliveryEntityRelationInput:{
		connect:"ChannelDeliveryUniqueWhere"
	},
	CartCreatePaymentEntityRelationInput:{
		connect:"ChannelPaymentUniqueWhere"
	},
	CartCreateBillingSubjectEntityRelationInput:{
		connect:"BillingSubjectUniqueWhere",
		create:"BillingSubjectCreateInput"
	},
	BillingSubjectCreateInput:{
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"BillingSubjectCreateCountryEntityRelationInput",
		customer:"BillingSubjectCreateCustomerEntityRelationInput",
		orders:"BillingSubjectCreateOrdersEntityRelationInput"
	},
	BillingSubjectCreateCountryEntityRelationInput:{
		connect:"CountryUniqueWhere"
	},
	BillingSubjectCreateCustomerEntityRelationInput:{
		connect:"CustomerUniqueWhere"
	},
	BillingSubjectCreateOrdersEntityRelationInput:{
		connect:"OrderUniqueWhere",
		create:"OrderWithoutBillingSubjectCreateInput"
	},
	OrderWithoutBillingSubjectCreateInput:{
		state:"OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"OrderCreateCustomerEntityRelationInput",
		channel:"OrderCreateChannelEntityRelationInput",
		delivery:"OrderCreateDeliveryEntityRelationInput",
		items:"OrderCreateItemsEntityRelationInput",
		cart:"OrderCreateCartEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"OrderCreateBillingAddressEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	OrderCreateBillingAddressEntityRelationInput:{
		connect:"AddressUniqueWhere",
		create:"AddressWithoutBillingAddressOfOrdersCreateInput"
	},
	AddressWithoutBillingAddressOfOrdersCreateInput:{
		customer:"AddressCreateCustomerEntityRelationInput",
		country:"AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"AddressCreateDeliveryZoneEntityRelationInput",
		gps:"AddressCreateGpsEntityRelationInput",
		createdAt:"DateTime",
		deliveryAddressOfOrderDeliveries:"AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"
	},
	AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput:{
		connect:"OrderDeliveryUniqueWhere",
		create:"OrderDeliveryWithoutAddressCreateInput"
	},
	OrderDeliveryWithoutAddressCreateInput:{
		deliveredAt:"DateTime",
		method:"OrderDeliveryCreateMethodEntityRelationInput",
		vatRate:"OrderDeliveryCreateVatRateEntityRelationInput",
		order:"OrderDeliveryCreateOrderEntityRelationInput",
		expectedAt:"DateTime",
		state:"OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		prepareAt:"DateTime",
		pickedAt:"DateTime"
	},
	OrderDeliveryCreateOrderEntityRelationInput:{
		connect:"OrderUniqueWhere",
		create:"OrderWithoutDeliveryCreateInput"
	},
	OrderWithoutDeliveryCreateInput:{
		state:"OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"OrderCreateCustomerEntityRelationInput",
		channel:"OrderCreateChannelEntityRelationInput",
		items:"OrderCreateItemsEntityRelationInput",
		cart:"OrderCreateCartEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"OrderCreateBillingAddressEntityRelationInput",
		billingSubject:"OrderCreateBillingSubjectEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	OrderCreateBillingSubjectEntityRelationInput:{
		connect:"BillingSubjectUniqueWhere",
		create:"BillingSubjectWithoutOrdersCreateInput"
	},
	BillingSubjectWithoutOrdersCreateInput:{
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"BillingSubjectCreateCountryEntityRelationInput",
		customer:"BillingSubjectCreateCustomerEntityRelationInput"
	},
	AddressUpdateInput:{
		customer:"AddressUpdateCustomerEntityRelationInput",
		country:"AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"AddressUpdateDeliveryZoneEntityRelationInput",
		gps:"AddressUpdateGpsEntityRelationInput",
		createdAt:"DateTime",
		defaultDeliveryAddressOfCustomer:"AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	AddressUpdateCustomerEntityRelationInput:{
		update:"CustomerWithoutAddressesUpdateInput",
		connect:"CustomerUniqueWhere"
	},
	CustomerWithoutAddressesUpdateInput:{
		defaultBillingAddress:"CustomerUpdateDefaultBillingAddressEntityRelationInput",
		name:"CustomerUpdateNameEntityRelationInput",
		ingredientRatings:"CustomerUpdateIngredientRatingsEntityRelationInput",
		billingSubjects:"CustomerUpdateBillingSubjectsEntityRelationInput",
		defaultDeliveryAddress:"CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		anonymousSession:"CustomerUpdateAnonymousSessionEntityRelationInput"
	},
	CustomerUpdateDefaultBillingAddressEntityRelationInput:{
		create:"AddressCreateInput",
		update:"AddressUpdateInput",
		upsert:"CustomerUpsertDefaultBillingAddressRelationInput",
		connect:"AddressUniqueWhere"
	},
	CustomerUpsertDefaultBillingAddressRelationInput:{
		update:"AddressUpdateInput",
		create:"AddressCreateInput"
	},
	CustomerUpdateNameEntityRelationInput:{
		create:"CustomerNameWithoutCustomerCreateInput",
		update:"CustomerNameWithoutCustomerUpdateInput",
		upsert:"CustomerUpsertNameRelationInput",
		connect:"CustomerNameUniqueWhere"
	},
	CustomerNameWithoutCustomerCreateInput:{

	},
	CustomerNameWithoutCustomerUpdateInput:{

	},
	CustomerUpsertNameRelationInput:{
		update:"CustomerNameWithoutCustomerUpdateInput",
		create:"CustomerNameWithoutCustomerCreateInput"
	},
	CustomerUpdateIngredientRatingsEntityRelationInput:{
		create:"CustomerIngredientRatingWithoutCustomerCreateInput",
		update:"CustomerUpdateIngredientRatingsRelationInput",
		upsert:"CustomerUpsertIngredientRatingsRelationInput",
		connect:"CustomerIngredientRatingUniqueWhere",
		disconnect:"CustomerIngredientRatingUniqueWhere",
		delete:"CustomerIngredientRatingUniqueWhere"
	},
	CustomerIngredientRatingWithoutCustomerCreateInput:{
		rating:"CustomerIngredientRatingCreateRatingEntityRelationInput",
		ingredient:"CustomerIngredientRatingCreateIngredientEntityRelationInput"
	},
	CustomerIngredientRatingCreateRatingEntityRelationInput:{
		connect:"ProductIngredientRatingUniqueWhere"
	},
	ProductIngredientRatingUniqueWhere:{
		id:"UUID"
	},
	CustomerIngredientRatingCreateIngredientEntityRelationInput:{
		connect:"ProductIngredientUniqueWhere"
	},
	CustomerUpdateIngredientRatingsRelationInput:{
		by:"CustomerIngredientRatingUniqueWhere",
		data:"CustomerIngredientRatingWithoutCustomerUpdateInput"
	},
	CustomerIngredientRatingWithoutCustomerUpdateInput:{
		rating:"CustomerIngredientRatingUpdateRatingEntityRelationInput",
		ingredient:"CustomerIngredientRatingUpdateIngredientEntityRelationInput"
	},
	CustomerIngredientRatingUpdateRatingEntityRelationInput:{
		connect:"ProductIngredientRatingUniqueWhere"
	},
	CustomerIngredientRatingUpdateIngredientEntityRelationInput:{
		connect:"ProductIngredientUniqueWhere"
	},
	CustomerUpsertIngredientRatingsRelationInput:{
		by:"CustomerIngredientRatingUniqueWhere",
		update:"CustomerIngredientRatingWithoutCustomerUpdateInput",
		create:"CustomerIngredientRatingWithoutCustomerCreateInput"
	},
	CustomerUpdateBillingSubjectsEntityRelationInput:{
		create:"BillingSubjectWithoutCustomerCreateInput",
		update:"CustomerUpdateBillingSubjectsRelationInput",
		upsert:"CustomerUpsertBillingSubjectsRelationInput",
		connect:"BillingSubjectUniqueWhere",
		disconnect:"BillingSubjectUniqueWhere"
	},
	BillingSubjectWithoutCustomerCreateInput:{
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"BillingSubjectCreateCountryEntityRelationInput",
		orders:"BillingSubjectCreateOrdersEntityRelationInput"
	},
	CustomerUpdateBillingSubjectsRelationInput:{
		by:"BillingSubjectUniqueWhere",
		data:"BillingSubjectWithoutCustomerUpdateInput"
	},
	BillingSubjectWithoutCustomerUpdateInput:{
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"BillingSubjectUpdateCountryEntityRelationInput"
	},
	BillingSubjectUpdateCountryEntityRelationInput:{
		connect:"CountryUniqueWhere"
	},
	CustomerUpsertBillingSubjectsRelationInput:{
		by:"BillingSubjectUniqueWhere",
		update:"BillingSubjectWithoutCustomerUpdateInput",
		create:"BillingSubjectWithoutCustomerCreateInput"
	},
	CustomerUpdateDefaultDeliveryAddressEntityRelationInput:{
		create:"AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput",
		update:"AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput",
		upsert:"CustomerUpsertDefaultDeliveryAddressRelationInput",
		connect:"AddressUniqueWhere"
	},
	AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput:{
		customer:"AddressCreateCustomerEntityRelationInput",
		country:"AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"AddressCreateDeliveryZoneEntityRelationInput",
		gps:"AddressCreateGpsEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"AddressCreateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"
	},
	AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput:{
		customer:"AddressUpdateCustomerEntityRelationInput",
		country:"AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"AddressUpdateDeliveryZoneEntityRelationInput",
		gps:"AddressUpdateGpsEntityRelationInput",
		createdAt:"DateTime"
	},
	AddressUpdateCountryEntityRelationInput:{
		connect:"CountryUniqueWhere"
	},
	AddressUpdateDeliveryZoneEntityRelationInput:{
		connect:"DeliveryZoneUniqueWhere"
	},
	AddressUpdateGpsEntityRelationInput:{
		create:"GpsWithoutAddressCreateInput",
		update:"GpsWithoutAddressUpdateInput",
		upsert:"AddressUpsertGpsRelationInput",
		connect:"GpsUniqueWhere"
	},
	GpsWithoutAddressUpdateInput:{

	},
	AddressUpsertGpsRelationInput:{
		update:"GpsWithoutAddressUpdateInput",
		create:"GpsWithoutAddressCreateInput"
	},
	CustomerUpsertDefaultDeliveryAddressRelationInput:{
		update:"AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput",
		create:"AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"
	},
	CustomerUpdateAnonymousSessionEntityRelationInput:{
		create:"AnonymousSessionWithoutCustomerCreateInput",
		update:"AnonymousSessionWithoutCustomerUpdateInput",
		upsert:"CustomerUpsertAnonymousSessionRelationInput",
		connect:"AnonymousSessionUniqueWhere"
	},
	AnonymousSessionWithoutCustomerCreateInput:{
		createdAt:"DateTime",
		lastActiveAt:"DateTime"
	},
	AnonymousSessionWithoutCustomerUpdateInput:{
		createdAt:"DateTime",
		lastActiveAt:"DateTime"
	},
	CustomerUpsertAnonymousSessionRelationInput:{
		update:"AnonymousSessionWithoutCustomerUpdateInput",
		create:"AnonymousSessionWithoutCustomerCreateInput"
	},
	AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput:{
		update:"CustomerWithoutDefaultDeliveryAddressUpdateInput",
		connect:"CustomerUniqueWhere"
	},
	CustomerWithoutDefaultDeliveryAddressUpdateInput:{
		addresses:"CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"CustomerUpdateDefaultBillingAddressEntityRelationInput",
		name:"CustomerUpdateNameEntityRelationInput",
		ingredientRatings:"CustomerUpdateIngredientRatingsEntityRelationInput",
		billingSubjects:"CustomerUpdateBillingSubjectsEntityRelationInput",
		anonymousSession:"CustomerUpdateAnonymousSessionEntityRelationInput"
	},
	CustomerUpdateAddressesEntityRelationInput:{
		create:"AddressWithoutCustomerCreateInput",
		update:"CustomerUpdateAddressesRelationInput",
		upsert:"CustomerUpsertAddressesRelationInput",
		connect:"AddressUniqueWhere",
		disconnect:"AddressUniqueWhere",
		delete:"AddressUniqueWhere"
	},
	AddressWithoutCustomerCreateInput:{
		country:"AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"AddressCreateDeliveryZoneEntityRelationInput",
		gps:"AddressCreateGpsEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"AddressCreateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"
	},
	CustomerUpdateAddressesRelationInput:{
		by:"AddressUniqueWhere",
		data:"AddressWithoutCustomerUpdateInput"
	},
	AddressWithoutCustomerUpdateInput:{
		country:"AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"AddressUpdateDeliveryZoneEntityRelationInput",
		gps:"AddressUpdateGpsEntityRelationInput",
		createdAt:"DateTime",
		defaultDeliveryAddressOfCustomer:"AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	CustomerUpsertAddressesRelationInput:{
		by:"AddressUniqueWhere",
		update:"AddressWithoutCustomerUpdateInput",
		create:"AddressWithoutCustomerCreateInput"
	},
	CustomerUpdateInput:{
		addresses:"CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"CustomerUpdateDefaultBillingAddressEntityRelationInput",
		name:"CustomerUpdateNameEntityRelationInput",
		ingredientRatings:"CustomerUpdateIngredientRatingsEntityRelationInput",
		billingSubjects:"CustomerUpdateBillingSubjectsEntityRelationInput",
		defaultDeliveryAddress:"CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		anonymousSession:"CustomerUpdateAnonymousSessionEntityRelationInput"
	},
	OrderCreateInput:{
		state:"OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"OrderCreateCustomerEntityRelationInput",
		channel:"OrderCreateChannelEntityRelationInput",
		delivery:"OrderCreateDeliveryEntityRelationInput",
		items:"OrderCreateItemsEntityRelationInput",
		cart:"OrderCreateCartEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"OrderCreateBillingAddressEntityRelationInput",
		billingSubject:"OrderCreateBillingSubjectEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	OrderDeliveryCreateInput:{
		deliveredAt:"DateTime",
		method:"OrderDeliveryCreateMethodEntityRelationInput",
		vatRate:"OrderDeliveryCreateVatRateEntityRelationInput",
		order:"OrderDeliveryCreateOrderEntityRelationInput",
		expectedAt:"DateTime",
		state:"OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		prepareAt:"DateTime",
		pickedAt:"DateTime",
		address:"OrderDeliveryCreateAddressEntityRelationInput"
	},
	OrderItemCreateInput:{
		vatRate:"OrderItemCreateVatRateEntityRelationInput",
		order:"OrderItemCreateOrderEntityRelationInput",
		pickedAt:"DateTime",
		product:"OrderItemCreateProductEntityRelationInput"
	},
	OrderItemCreateOrderEntityRelationInput:{
		connect:"OrderUniqueWhere",
		create:"OrderWithoutItemsCreateInput"
	},
	OrderWithoutItemsCreateInput:{
		state:"OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"OrderCreateCustomerEntityRelationInput",
		channel:"OrderCreateChannelEntityRelationInput",
		delivery:"OrderCreateDeliveryEntityRelationInput",
		cart:"OrderCreateCartEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"OrderCreateBillingAddressEntityRelationInput",
		billingSubject:"OrderCreateBillingSubjectEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	PaymentMethodUniqueWhere:{
		id:"UUID",
		channels:"ChannelPaymentUniqueWhere"
	},
	TagUniqueWhere:{
		id:"UUID"
	},
	CustomerNameCreateInput:{

	},
	CustomerNameUpdateInput:{
		customer:"CustomerNameUpdateCustomerEntityRelationInput"
	},
	CustomerNameUpdateCustomerEntityRelationInput:{
		update:"CustomerWithoutNameUpdateInput",
		connect:"CustomerUniqueWhere"
	},
	CustomerWithoutNameUpdateInput:{
		addresses:"CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"CustomerUpdateDefaultBillingAddressEntityRelationInput",
		ingredientRatings:"CustomerUpdateIngredientRatingsEntityRelationInput",
		billingSubjects:"CustomerUpdateBillingSubjectsEntityRelationInput",
		defaultDeliveryAddress:"CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		anonymousSession:"CustomerUpdateAnonymousSessionEntityRelationInput"
	},
	CustomerIngredientRatingCreateInput:{
		customer:"CustomerIngredientRatingCreateCustomerEntityRelationInput",
		rating:"CustomerIngredientRatingCreateRatingEntityRelationInput",
		ingredient:"CustomerIngredientRatingCreateIngredientEntityRelationInput"
	},
	CustomerIngredientRatingCreateCustomerEntityRelationInput:{
		connect:"CustomerUniqueWhere"
	},
	CustomerIngredientRatingUpdateInput:{
		customer:"CustomerIngredientRatingUpdateCustomerEntityRelationInput",
		rating:"CustomerIngredientRatingUpdateRatingEntityRelationInput",
		ingredient:"CustomerIngredientRatingUpdateIngredientEntityRelationInput"
	},
	CustomerIngredientRatingUpdateCustomerEntityRelationInput:{
		update:"CustomerWithoutIngredientRatingsUpdateInput",
		connect:"CustomerUniqueWhere"
	},
	CustomerWithoutIngredientRatingsUpdateInput:{
		addresses:"CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"CustomerUpdateDefaultBillingAddressEntityRelationInput",
		name:"CustomerUpdateNameEntityRelationInput",
		billingSubjects:"CustomerUpdateBillingSubjectsEntityRelationInput",
		defaultDeliveryAddress:"CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		anonymousSession:"CustomerUpdateAnonymousSessionEntityRelationInput"
	},
	ColorPalleteUniqueWhere:{
		id:"UUID"
	},
	BlogPostListUniqueWhere:{
		id:"UUID",
		items:"BlogPostListItemUniqueWhere"
	},
	BlogPostListItemUniqueWhere:{
		id:"UUID"
	},
	LinkListUniqueWhere:{
		id:"UUID",
		items:"LinkListItemUniqueWhere"
	},
	LinkListItemUniqueWhere:{
		id:"UUID"
	},
	MediumUniqueWhere:{
		id:"UUID"
	},
	VideoUniqueWhere:{
		id:"UUID"
	},
	SocialsAndAppsUniqueWhere:{
		id:"UUID"
	},
	ProductGridUniqueWhere:{
		id:"UUID",
		categories:"ProductGridCategoryUniqueWhere"
	},
	ProductGridCategoryUniqueWhere:{
		id:"UUID",
		items:"ProductGridItemUniqueWhere",
		images:"ImageListUniqueWhere"
	},
	GalleryUniqueWhere:{
		id:"UUID",
		items:"GalleryItemUniqueWhere"
	},
	GalleryItemUniqueWhere:{
		id:"UUID"
	},
	HeroUniqueWhere:{
		id:"UUID"
	},
	CartCreateInput:{
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		items:"CartCreateItemsEntityRelationInput",
		state:"CartState",
		createdAt:"DateTime",
		channel:"CartCreateChannelEntityRelationInput",
		order:"CartCreateOrderEntityRelationInput",
		customer:"CartCreateCustomerEntityRelationInput",
		deliveryAddress:"CartCreateDeliveryAddressEntityRelationInput",
		billingAddress:"CartCreateBillingAddressEntityRelationInput",
		delivery:"CartCreateDeliveryEntityRelationInput",
		payment:"CartCreatePaymentEntityRelationInput",
		billingSubject:"CartCreateBillingSubjectEntityRelationInput"
	},
	CartCreateOrderEntityRelationInput:{
		connect:"OrderUniqueWhere",
		create:"OrderWithoutCartCreateInput"
	},
	OrderWithoutCartCreateInput:{
		state:"OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"OrderCreateCustomerEntityRelationInput",
		channel:"OrderCreateChannelEntityRelationInput",
		delivery:"OrderCreateDeliveryEntityRelationInput",
		items:"OrderCreateItemsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"OrderCreateBillingAddressEntityRelationInput",
		billingSubject:"OrderCreateBillingSubjectEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	CartItemCreateInput:{
		cart:"CartItemCreateCartEntityRelationInput",
		product:"CartItemCreateProductEntityRelationInput",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	CartItemCreateCartEntityRelationInput:{
		connect:"CartUniqueWhere",
		create:"CartWithoutItemsCreateInput"
	},
	CartWithoutItemsCreateInput:{
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		state:"CartState",
		createdAt:"DateTime",
		channel:"CartCreateChannelEntityRelationInput",
		order:"CartCreateOrderEntityRelationInput",
		customer:"CartCreateCustomerEntityRelationInput",
		deliveryAddress:"CartCreateDeliveryAddressEntityRelationInput",
		billingAddress:"CartCreateBillingAddressEntityRelationInput",
		delivery:"CartCreateDeliveryEntityRelationInput",
		payment:"CartCreatePaymentEntityRelationInput",
		billingSubject:"CartCreateBillingSubjectEntityRelationInput"
	},
	CartItemUpdateInput:{
		cart:"CartItemUpdateCartEntityRelationInput",
		product:"CartItemUpdateProductEntityRelationInput",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	CartItemUpdateCartEntityRelationInput:{
		create:"CartWithoutItemsCreateInput",
		connect:"CartUniqueWhere"
	},
	CartItemUpdateProductEntityRelationInput:{
		connect:"ProductUniqueWhere"
	},
	ProductBannerUniqueWhere:{
		id:"UUID",
		button:"LinkUniqueWhere"
	},
	GpsCreateInput:{
		address:"GpsCreateAddressEntityRelationInput"
	},
	GpsCreateAddressEntityRelationInput:{
		connect:"AddressUniqueWhere",
		create:"AddressWithoutGpsCreateInput"
	},
	AddressWithoutGpsCreateInput:{
		customer:"AddressCreateCustomerEntityRelationInput",
		country:"AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"AddressCreateDeliveryZoneEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"AddressCreateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"
	},
	GpsUpdateInput:{
		address:"GpsUpdateAddressEntityRelationInput"
	},
	GpsUpdateAddressEntityRelationInput:{
		create:"AddressWithoutGpsCreateInput",
		update:"AddressWithoutGpsUpdateInput",
		upsert:"GpsUpsertAddressRelationInput",
		connect:"AddressUniqueWhere"
	},
	AddressWithoutGpsUpdateInput:{
		customer:"AddressUpdateCustomerEntityRelationInput",
		country:"AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"AddressUpdateDeliveryZoneEntityRelationInput",
		createdAt:"DateTime",
		defaultDeliveryAddressOfCustomer:"AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	GpsUpsertAddressRelationInput:{
		update:"AddressWithoutGpsUpdateInput",
		create:"AddressWithoutGpsCreateInput"
	},
	WeekHoursUniqueWhere:{
		id:"UUID"
	},
	BillingSubjectUpdateInput:{
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"BillingSubjectUpdateCountryEntityRelationInput",
		customer:"BillingSubjectUpdateCustomerEntityRelationInput"
	},
	BillingSubjectUpdateCustomerEntityRelationInput:{
		update:"CustomerWithoutBillingSubjectsUpdateInput",
		connect:"CustomerUniqueWhere"
	},
	CustomerWithoutBillingSubjectsUpdateInput:{
		addresses:"CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"CustomerUpdateDefaultBillingAddressEntityRelationInput",
		name:"CustomerUpdateNameEntityRelationInput",
		ingredientRatings:"CustomerUpdateIngredientRatingsEntityRelationInput",
		defaultDeliveryAddress:"CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		anonymousSession:"CustomerUpdateAnonymousSessionEntityRelationInput"
	},
	AnonymousSessionCreateInput:{
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		customer:"AnonymousSessionCreateCustomerEntityRelationInput"
	},
	AnonymousSessionCreateCustomerEntityRelationInput:{
		connect:"CustomerUniqueWhere"
	},
	AnonymousSessionUpdateInput:{
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		customer:"AnonymousSessionUpdateCustomerEntityRelationInput"
	},
	AnonymousSessionUpdateCustomerEntityRelationInput:{
		update:"CustomerWithoutAnonymousSessionUpdateInput",
		connect:"CustomerUniqueWhere"
	},
	CustomerWithoutAnonymousSessionUpdateInput:{
		addresses:"CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"CustomerUpdateDefaultBillingAddressEntityRelationInput",
		name:"CustomerUpdateNameEntityRelationInput",
		ingredientRatings:"CustomerUpdateIngredientRatingsEntityRelationInput",
		billingSubjects:"CustomerUpdateBillingSubjectsEntityRelationInput",
		defaultDeliveryAddress:"CustomerUpdateDefaultDeliveryAddressEntityRelationInput"
	},
	FreshingContainerUniqueWhere:{
		id:"UUID"
	},
	WorkspaceUniqueWhere:{
		id:"UUID",
		unique:"One",
		freshingContainers:"FreshingContainerUniqueWhere",
		openingHours:"WeekHoursUniqueWhere",
		workingHours:"WeekHoursUniqueWhere",
		creditsProduct:"VirtualProductUniqueWhere",
		discountVatRate:"VatRateUniqueWhere"
	},
	IdentificationAttemptCreateInput:{
		createdAt:"DateTime",
		code:"IdentificationAttemptCreateCodeEntityRelationInput",
		user:"IdentificationAttemptCreateUserEntityRelationInput"
	},
	IdentificationAttemptCreateCodeEntityRelationInput:{
		connect:"IdentificationCodeUniqueWhere"
	},
	IdentificationAttemptCreateUserEntityRelationInput:{
		connect:"UserUniqueWhere"
	},
	FulfillmentNoteUniqueWhere:{
		id:"UUID"
	},
	GridTileItemUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere"
	},
	GridTilesUniqueWhere:{
		id:"UUID",
		items:"GridTileItemUniqueWhere"
	},
	PositionUniqueWhere:{
		id:"UUID"
	},
	PositionWhere:{
		id:"UUIDCondition",
		x:"IntCondition",
		y:"IntCondition",
		and:"PositionWhere",
		or:"PositionWhere",
		not:"PositionWhere"
	},
	PositionOrderBy:{
		id:"OrderDirection",
		x:"OrderDirection",
		y:"OrderDirection"
	},
	StatsWidget:{
		layout:{
			filter:"StatsWidgetsLayoutWhere"
		},
		icon:{
			filter:"ImageWhere"
		},
		channels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		productCategory:{
			filter:"ProductCategoryWhere"
		},
		paymentMethods:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginateChannels:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginatePaymentMethods:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		}
	},
	StatsEnabledWidgetTypeType: true,
	StatsWidgetTimeFrame: true,
	StatsWidgetsLayout:{
		items:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		},
		paginateItems:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		}
	},
	StatsWidgetWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		size:"IntCondition",
		type:"StatsEnabledWidgetTypeTypeCondition",
		link:"StringCondition",
		title:"StringCondition",
		timeFrame:"StatsWidgetTimeFrameCondition",
		layout:"StatsWidgetsLayoutWhere",
		icon:"ImageWhere",
		channels:"ChannelWhere",
		productCategory:"ProductCategoryWhere",
		paymentMethods:"PaymentMethodWhere",
		hoursFrom:"StringCondition",
		hoursTo:"StringCondition",
		and:"StatsWidgetWhere",
		or:"StatsWidgetWhere",
		not:"StatsWidgetWhere"
	},
	StatsEnabledWidgetTypeTypeCondition:{
		and:"StatsEnabledWidgetTypeTypeCondition",
		or:"StatsEnabledWidgetTypeTypeCondition",
		not:"StatsEnabledWidgetTypeTypeCondition",
		eq:"StatsEnabledWidgetTypeType",
		notEq:"StatsEnabledWidgetTypeType",
		in:"StatsEnabledWidgetTypeType",
		notIn:"StatsEnabledWidgetTypeType",
		lt:"StatsEnabledWidgetTypeType",
		lte:"StatsEnabledWidgetTypeType",
		gt:"StatsEnabledWidgetTypeType",
		gte:"StatsEnabledWidgetTypeType"
	},
	StatsWidgetTimeFrameCondition:{
		and:"StatsWidgetTimeFrameCondition",
		or:"StatsWidgetTimeFrameCondition",
		not:"StatsWidgetTimeFrameCondition",
		eq:"StatsWidgetTimeFrame",
		notEq:"StatsWidgetTimeFrame",
		in:"StatsWidgetTimeFrame",
		notIn:"StatsWidgetTimeFrame",
		lt:"StatsWidgetTimeFrame",
		lte:"StatsWidgetTimeFrame",
		gt:"StatsWidgetTimeFrame",
		gte:"StatsWidgetTimeFrame"
	},
	StatsWidgetsLayoutWhere:{
		id:"UUIDCondition",
		items:"StatsWidgetWhere",
		and:"StatsWidgetsLayoutWhere",
		or:"StatsWidgetsLayoutWhere",
		not:"StatsWidgetsLayoutWhere"
	},
	StatsWidgetOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		link:"OrderDirection",
		title:"OrderDirection",
		timeFrame:"OrderDirection",
		layout:"StatsWidgetsLayoutOrderBy",
		icon:"ImageOrderBy",
		productCategory:"ProductCategoryOrderBy",
		hoursFrom:"OrderDirection",
		hoursTo:"OrderDirection"
	},
	StatsWidgetsLayoutOrderBy:{
		id:"OrderDirection"
	},
	StatsWidgetUniqueWhere:{
		id:"UUID"
	},
	StatsWidgetsLayoutUniqueWhere:{
		id:"UUID",
		items:"StatsWidgetUniqueWhere"
	},
	StatsPage:{
		widgets:{
			filter:"StatsWidgetsLayoutWhere"
		}
	},
	StatsPageType: true,
	StatsPageUniqueWhere:{
		id:"UUID",
		type:"StatsPageType",
		widgets:"StatsWidgetsLayoutUniqueWhere"
	},
	StatsPageWhere:{
		id:"UUIDCondition",
		type:"StatsPageTypeCondition",
		enabled:"BooleanCondition",
		widgets:"StatsWidgetsLayoutWhere",
		and:"StatsPageWhere",
		or:"StatsPageWhere",
		not:"StatsPageWhere"
	},
	StatsPageTypeCondition:{
		and:"StatsPageTypeCondition",
		or:"StatsPageTypeCondition",
		not:"StatsPageTypeCondition",
		eq:"StatsPageType",
		notEq:"StatsPageType",
		in:"StatsPageType",
		notIn:"StatsPageType",
		lt:"StatsPageType",
		lte:"StatsPageType",
		gt:"StatsPageType",
		gte:"StatsPageType"
	},
	StatsPageOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		enabled:"OrderDirection",
		widgets:"StatsWidgetsLayoutOrderBy"
	},
	QueryTransaction:{
		getAddress:{
			by:"AddressUniqueWhere",
			filter:"AddressWhere"
		},
		listAddress:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		paginateAddress:{
			filter:"AddressWhere",
			orderBy:"AddressOrderBy"
		},
		validateCreateAddress:{
			data:"AddressCreateInput"
		},
		validateUpdateAddress:{
			by:"AddressUniqueWhere",
			data:"AddressUpdateInput"
		},
		getCountry:{
			by:"CountryUniqueWhere",
			filter:"CountryWhere"
		},
		listCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		paginateCountry:{
			filter:"CountryWhere",
			orderBy:"CountryOrderBy"
		},
		getChannel:{
			by:"ChannelUniqueWhere",
			filter:"ChannelWhere"
		},
		listChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		paginateChannel:{
			filter:"ChannelWhere",
			orderBy:"ChannelOrderBy"
		},
		getChannelPayment:{
			by:"ChannelPaymentUniqueWhere",
			filter:"ChannelPaymentWhere"
		},
		listChannelPayment:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		},
		paginateChannelPayment:{
			filter:"ChannelPaymentWhere",
			orderBy:"ChannelPaymentOrderBy"
		},
		getChannelDelivery:{
			by:"ChannelDeliveryUniqueWhere",
			filter:"ChannelDeliveryWhere"
		},
		listChannelDelivery:{
			filter:"ChannelDeliveryWhere",
			orderBy:"ChannelDeliveryOrderBy"
		},
		paginateChannelDelivery:{
			filter:"ChannelDeliveryWhere",
			orderBy:"ChannelDeliveryOrderBy"
		},
		getCustomer:{
			by:"CustomerUniqueWhere",
			filter:"CustomerWhere"
		},
		listCustomer:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		paginateCustomer:{
			filter:"CustomerWhere",
			orderBy:"CustomerOrderBy"
		},
		validateUpdateCustomer:{
			by:"CustomerUniqueWhere",
			data:"CustomerUpdateInput"
		},
		getCustomerCredit:{
			by:"CustomerCreditUniqueWhere",
			filter:"CustomerCreditWhere"
		},
		listCustomerCredit:{
			filter:"CustomerCreditWhere",
			orderBy:"CustomerCreditOrderBy"
		},
		paginateCustomerCredit:{
			filter:"CustomerCreditWhere",
			orderBy:"CustomerCreditOrderBy"
		},
		getCustomerGroup:{
			by:"CustomerGroupUniqueWhere",
			filter:"CustomerGroupWhere"
		},
		listCustomerGroup:{
			filter:"CustomerGroupWhere",
			orderBy:"CustomerGroupOrderBy"
		},
		paginateCustomerGroup:{
			filter:"CustomerGroupWhere",
			orderBy:"CustomerGroupOrderBy"
		},
		getDeliveryMethod:{
			by:"DeliveryMethodUniqueWhere",
			filter:"DeliveryMethodWhere"
		},
		listDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		paginateDeliveryMethod:{
			filter:"DeliveryMethodWhere",
			orderBy:"DeliveryMethodOrderBy"
		},
		getOrder:{
			by:"OrderUniqueWhere",
			filter:"OrderWhere"
		},
		listOrder:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		paginateOrder:{
			filter:"OrderWhere",
			orderBy:"OrderOrderBy"
		},
		validateCreateOrder:{
			data:"OrderCreateInput"
		},
		getOrderDiscount:{
			by:"OrderDiscountUniqueWhere",
			filter:"OrderDiscountWhere"
		},
		listOrderDiscount:{
			filter:"OrderDiscountWhere",
			orderBy:"OrderDiscountOrderBy"
		},
		paginateOrderDiscount:{
			filter:"OrderDiscountWhere",
			orderBy:"OrderDiscountOrderBy"
		},
		getOrderPayment:{
			by:"OrderPaymentUniqueWhere",
			filter:"OrderPaymentWhere"
		},
		listOrderPayment:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		paginateOrderPayment:{
			filter:"OrderPaymentWhere",
			orderBy:"OrderPaymentOrderBy"
		},
		getOrderDelivery:{
			by:"OrderDeliveryUniqueWhere",
			filter:"OrderDeliveryWhere"
		},
		listOrderDelivery:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		},
		paginateOrderDelivery:{
			filter:"OrderDeliveryWhere",
			orderBy:"OrderDeliveryOrderBy"
		},
		validateCreateOrderDelivery:{
			data:"OrderDeliveryCreateInput"
		},
		getOrderItem:{
			by:"OrderItemUniqueWhere",
			filter:"OrderItemWhere"
		},
		listOrderItem:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		paginateOrderItem:{
			filter:"OrderItemWhere",
			orderBy:"OrderItemOrderBy"
		},
		validateCreateOrderItem:{
			data:"OrderItemCreateInput"
		},
		getPaymentMethod:{
			by:"PaymentMethodUniqueWhere",
			filter:"PaymentMethodWhere"
		},
		listPaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		paginatePaymentMethod:{
			filter:"PaymentMethodWhere",
			orderBy:"PaymentMethodOrderBy"
		},
		getVatRate:{
			by:"VatRateUniqueWhere",
			filter:"VatRateWhere"
		},
		listVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		paginateVatRate:{
			filter:"VatRateWhere",
			orderBy:"VatRateOrderBy"
		},
		getProduct:{
			by:"ProductUniqueWhere",
			filter:"ProductWhere"
		},
		listProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		getProductStock:{
			by:"ProductStockUniqueWhere",
			filter:"ProductStockWhere"
		},
		listProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		paginateProductStock:{
			filter:"ProductStockWhere",
			orderBy:"ProductStockOrderBy"
		},
		getProductPacking:{
			by:"ProductPackingUniqueWhere",
			filter:"ProductPackingWhere"
		},
		listProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		paginateProductPacking:{
			filter:"ProductPackingWhere",
			orderBy:"ProductPackingOrderBy"
		},
		getProductGroupPrice:{
			by:"ProductGroupPriceUniqueWhere",
			filter:"ProductGroupPriceWhere"
		},
		listProductGroupPrice:{
			filter:"ProductGroupPriceWhere",
			orderBy:"ProductGroupPriceOrderBy"
		},
		paginateProductGroupPrice:{
			filter:"ProductGroupPriceWhere",
			orderBy:"ProductGroupPriceOrderBy"
		},
		getProductRecipe:{
			by:"ProductRecipeUniqueWhere",
			filter:"ProductRecipeWhere"
		},
		listProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		paginateProductRecipe:{
			filter:"ProductRecipeWhere",
			orderBy:"ProductRecipeOrderBy"
		},
		getProductCategory:{
			by:"ProductCategoryUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		listProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		getStore:{
			by:"StoreUniqueWhere",
			filter:"StoreWhere"
		},
		listStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		paginateStore:{
			filter:"StoreWhere",
			orderBy:"StoreOrderBy"
		},
		getTag:{
			by:"TagUniqueWhere",
			filter:"TagWhere"
		},
		listTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		paginateTag:{
			filter:"TagWhere",
			orderBy:"TagOrderBy"
		},
		getCustomerPermissions:{
			by:"CustomerPermissionsUniqueWhere",
			filter:"CustomerPermissionsWhere"
		},
		listCustomerPermissions:{
			filter:"CustomerPermissionsWhere",
			orderBy:"CustomerPermissionsOrderBy"
		},
		paginateCustomerPermissions:{
			filter:"CustomerPermissionsWhere",
			orderBy:"CustomerPermissionsOrderBy"
		},
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getBusinessCategory:{
			by:"BusinessCategoryUniqueWhere",
			filter:"BusinessCategoryWhere"
		},
		listBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		paginateBusinessCategory:{
			filter:"BusinessCategoryWhere",
			orderBy:"BusinessCategoryOrderBy"
		},
		getProductList:{
			by:"ProductListUniqueWhere",
			filter:"ProductListWhere"
		},
		listProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		paginateProductList:{
			filter:"ProductListWhere",
			orderBy:"ProductListOrderBy"
		},
		getProductListItem:{
			by:"ProductListItemUniqueWhere",
			filter:"ProductListItemWhere"
		},
		listProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		paginateProductListItem:{
			filter:"ProductListItemWhere",
			orderBy:"ProductListItemOrderBy"
		},
		getProductCategoryList:{
			by:"ProductCategoryListUniqueWhere",
			filter:"ProductCategoryListWhere"
		},
		listProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		paginateProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		getProductCategoryListItem:{
			by:"ProductCategoryListItemUniqueWhere",
			filter:"ProductCategoryListItemWhere"
		},
		listProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		paginateProductCategoryListItem:{
			filter:"ProductCategoryListItemWhere",
			orderBy:"ProductCategoryListItemOrderBy"
		},
		getBusinessCategoryLocale:{
			by:"BusinessCategoryLocaleUniqueWhere",
			filter:"BusinessCategoryLocaleWhere"
		},
		listBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		paginateBusinessCategoryLocale:{
			filter:"BusinessCategoryLocaleWhere",
			orderBy:"BusinessCategoryLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getProductCategoryListLocale:{
			by:"ProductCategoryListLocaleUniqueWhere",
			filter:"ProductCategoryListLocaleWhere"
		},
		listProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		paginateProductCategoryListLocale:{
			filter:"ProductCategoryListLocaleWhere",
			orderBy:"ProductCategoryListLocaleOrderBy"
		},
		getCustomerName:{
			by:"CustomerNameUniqueWhere",
			filter:"CustomerNameWhere"
		},
		listCustomerName:{
			filter:"CustomerNameWhere",
			orderBy:"CustomerNameOrderBy"
		},
		paginateCustomerName:{
			filter:"CustomerNameWhere",
			orderBy:"CustomerNameOrderBy"
		},
		validateCreateCustomerName:{
			data:"CustomerNameCreateInput"
		},
		validateUpdateCustomerName:{
			by:"CustomerNameUniqueWhere",
			data:"CustomerNameUpdateInput"
		},
		getProductIngredientItem:{
			by:"ProductIngredientItemUniqueWhere",
			filter:"ProductIngredientItemWhere"
		},
		listProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		paginateProductIngredientItem:{
			filter:"ProductIngredientItemWhere",
			orderBy:"ProductIngredientItemOrderBy"
		},
		getProductIngredient:{
			by:"ProductIngredientUniqueWhere",
			filter:"ProductIngredientWhere"
		},
		listProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		paginateProductIngredient:{
			filter:"ProductIngredientWhere",
			orderBy:"ProductIngredientOrderBy"
		},
		getCustomerIngredientRating:{
			by:"CustomerIngredientRatingUniqueWhere",
			filter:"CustomerIngredientRatingWhere"
		},
		listCustomerIngredientRating:{
			filter:"CustomerIngredientRatingWhere",
			orderBy:"CustomerIngredientRatingOrderBy"
		},
		paginateCustomerIngredientRating:{
			filter:"CustomerIngredientRatingWhere",
			orderBy:"CustomerIngredientRatingOrderBy"
		},
		validateCreateCustomerIngredientRating:{
			data:"CustomerIngredientRatingCreateInput"
		},
		validateUpdateCustomerIngredientRating:{
			by:"CustomerIngredientRatingUniqueWhere",
			data:"CustomerIngredientRatingUpdateInput"
		},
		getProductIngredientRating:{
			by:"ProductIngredientRatingUniqueWhere",
			filter:"ProductIngredientRatingWhere"
		},
		listProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		paginateProductIngredientRating:{
			filter:"ProductIngredientRatingWhere",
			orderBy:"ProductIngredientRatingOrderBy"
		},
		getProductIngredientLocale:{
			by:"ProductIngredientLocaleUniqueWhere",
			filter:"ProductIngredientLocaleWhere"
		},
		listProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		paginateProductIngredientLocale:{
			filter:"ProductIngredientLocaleWhere",
			orderBy:"ProductIngredientLocaleOrderBy"
		},
		getOrderRecurrence:{
			by:"OrderRecurrenceUniqueWhere",
			filter:"OrderRecurrenceWhere"
		},
		listOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		paginateOrderRecurrence:{
			filter:"OrderRecurrenceWhere",
			orderBy:"OrderRecurrenceOrderBy"
		},
		getOrderRecurrenceLocale:{
			by:"OrderRecurrenceLocaleUniqueWhere",
			filter:"OrderRecurrenceLocaleWhere"
		},
		listOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		paginateOrderRecurrenceLocale:{
			filter:"OrderRecurrenceLocaleWhere",
			orderBy:"OrderRecurrenceLocaleOrderBy"
		},
		getBusinessCustomer:{
			by:"BusinessCustomerUniqueWhere",
			filter:"BusinessCustomerWhere"
		},
		listBusinessCustomer:{
			filter:"BusinessCustomerWhere",
			orderBy:"BusinessCustomerOrderBy"
		},
		paginateBusinessCustomer:{
			filter:"BusinessCustomerWhere",
			orderBy:"BusinessCustomerOrderBy"
		},
		getStaff:{
			by:"StaffUniqueWhere",
			filter:"StaffWhere"
		},
		listStaff:{
			filter:"StaffWhere",
			orderBy:"StaffOrderBy"
		},
		paginateStaff:{
			filter:"StaffWhere",
			orderBy:"StaffOrderBy"
		},
		getBlog:{
			by:"BlogUniqueWhere",
			filter:"BlogWhere"
		},
		listBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		paginateBlog:{
			filter:"BlogWhere",
			orderBy:"BlogOrderBy"
		},
		getBlogLocale:{
			by:"BlogLocaleUniqueWhere",
			filter:"BlogLocaleWhere"
		},
		listBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		paginateBlogLocale:{
			filter:"BlogLocaleWhere",
			orderBy:"BlogLocaleOrderBy"
		},
		getBlogPost:{
			by:"BlogPostUniqueWhere",
			filter:"BlogPostWhere"
		},
		listBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		paginateBlogPost:{
			filter:"BlogPostWhere",
			orderBy:"BlogPostOrderBy"
		},
		getBlogPostLocale:{
			by:"BlogPostLocaleUniqueWhere",
			filter:"BlogPostLocaleWhere"
		},
		listBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		paginateBlogPostLocale:{
			filter:"BlogPostLocaleWhere",
			orderBy:"BlogPostLocaleOrderBy"
		},
		getColorPallete:{
			by:"ColorPalleteUniqueWhere",
			filter:"ColorPalleteWhere"
		},
		listColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		paginateColorPallete:{
			filter:"ColorPalleteWhere",
			orderBy:"ColorPalleteOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getBlogPostList:{
			by:"BlogPostListUniqueWhere",
			filter:"BlogPostListWhere"
		},
		listBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		paginateBlogPostList:{
			filter:"BlogPostListWhere",
			orderBy:"BlogPostListOrderBy"
		},
		getBlogPostListItem:{
			by:"BlogPostListItemUniqueWhere",
			filter:"BlogPostListItemWhere"
		},
		listBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		paginateBlogPostListItem:{
			filter:"BlogPostListItemWhere",
			orderBy:"BlogPostListItemOrderBy"
		},
		getLinkList:{
			by:"LinkListUniqueWhere",
			filter:"LinkListWhere"
		},
		listLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		paginateLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		getLinkListItem:{
			by:"LinkListItemUniqueWhere",
			filter:"LinkListItemWhere"
		},
		listLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		paginateLinkListItem:{
			filter:"LinkListItemWhere",
			orderBy:"LinkListItemOrderBy"
		},
		getCover:{
			by:"CoverUniqueWhere",
			filter:"CoverWhere"
		},
		listCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		paginateCover:{
			filter:"CoverWhere",
			orderBy:"CoverOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getMedium:{
			by:"MediumUniqueWhere",
			filter:"MediumWhere"
		},
		listMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		paginateMedium:{
			filter:"MediumWhere",
			orderBy:"MediumOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getTranslationDomain:{
			by:"TranslationDomainUniqueWhere",
			filter:"TranslationDomainWhere"
		},
		listTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		paginateTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		getTranslationCatalogue:{
			by:"TranslationCatalogueUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		listTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		getTranslationKey:{
			by:"TranslationKeyUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		listTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		paginateTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		getTranslationValue:{
			by:"TranslationValueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		listTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		paginateTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		getTranslationCataloguesIdentifier:{
			by:"TranslationCataloguesIdentifierUniqueWhere",
			filter:"TranslationCataloguesIdentifierWhere"
		},
		listTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		paginateTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		getSocialsAndApps:{
			by:"SocialsAndAppsUniqueWhere",
			filter:"SocialsAndAppsWhere"
		},
		listSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		paginateSocialsAndApps:{
			filter:"SocialsAndAppsWhere",
			orderBy:"SocialsAndAppsOrderBy"
		},
		getProductGrid:{
			by:"ProductGridUniqueWhere",
			filter:"ProductGridWhere"
		},
		listProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		paginateProductGrid:{
			filter:"ProductGridWhere",
			orderBy:"ProductGridOrderBy"
		},
		getProductGridItem:{
			by:"ProductGridItemUniqueWhere",
			filter:"ProductGridItemWhere"
		},
		listProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		paginateProductGridItem:{
			filter:"ProductGridItemWhere",
			orderBy:"ProductGridItemOrderBy"
		},
		getGallery:{
			by:"GalleryUniqueWhere",
			filter:"GalleryWhere"
		},
		listGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		paginateGallery:{
			filter:"GalleryWhere",
			orderBy:"GalleryOrderBy"
		},
		getGalleryItem:{
			by:"GalleryItemUniqueWhere",
			filter:"GalleryItemWhere"
		},
		listGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		paginateGalleryItem:{
			filter:"GalleryItemWhere",
			orderBy:"GalleryItemOrderBy"
		},
		getHero:{
			by:"HeroUniqueWhere",
			filter:"HeroWhere"
		},
		listHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		paginateHero:{
			filter:"HeroWhere",
			orderBy:"HeroOrderBy"
		},
		getPage:{
			by:"PageUniqueWhere",
			filter:"PageWhere"
		},
		listPage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		paginatePage:{
			filter:"PageWhere",
			orderBy:"PageOrderBy"
		},
		getPageLocale:{
			by:"PageLocaleUniqueWhere",
			filter:"PageLocaleWhere"
		},
		listPageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		paginatePageLocale:{
			filter:"PageLocaleWhere",
			orderBy:"PageLocaleOrderBy"
		},
		getCart:{
			by:"CartUniqueWhere",
			filter:"CartWhere"
		},
		listCart:{
			filter:"CartWhere",
			orderBy:"CartOrderBy"
		},
		paginateCart:{
			filter:"CartWhere",
			orderBy:"CartOrderBy"
		},
		validateCreateCart:{
			data:"CartCreateInput"
		},
		getCartItem:{
			by:"CartItemUniqueWhere",
			filter:"CartItemWhere"
		},
		listCartItem:{
			filter:"CartItemWhere",
			orderBy:"CartItemOrderBy"
		},
		paginateCartItem:{
			filter:"CartItemWhere",
			orderBy:"CartItemOrderBy"
		},
		validateCreateCartItem:{
			data:"CartItemCreateInput"
		},
		validateUpdateCartItem:{
			by:"CartItemUniqueWhere",
			data:"CartItemUpdateInput"
		},
		getProductBanner:{
			by:"ProductBannerUniqueWhere",
			filter:"ProductBannerWhere"
		},
		listProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		paginateProductBanner:{
			filter:"ProductBannerWhere",
			orderBy:"ProductBannerOrderBy"
		},
		getProductBannerItem:{
			by:"ProductBannerItemUniqueWhere",
			filter:"ProductBannerItemWhere"
		},
		listProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		paginateProductBannerItem:{
			filter:"ProductBannerItemWhere",
			orderBy:"ProductBannerItemOrderBy"
		},
		getProductBannerList:{
			by:"ProductBannerListUniqueWhere",
			filter:"ProductBannerListWhere"
		},
		listProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		paginateProductBannerList:{
			filter:"ProductBannerListWhere",
			orderBy:"ProductBannerListOrderBy"
		},
		getUser:{
			by:"UserUniqueWhere",
			filter:"UserWhere"
		},
		listUser:{
			filter:"UserWhere",
			orderBy:"UserOrderBy"
		},
		paginateUser:{
			filter:"UserWhere",
			orderBy:"UserOrderBy"
		},
		getAllergen:{
			by:"AllergenUniqueWhere",
			filter:"AllergenWhere"
		},
		listAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		paginateAllergen:{
			filter:"AllergenWhere",
			orderBy:"AllergenOrderBy"
		},
		getAllergenLocale:{
			by:"AllergenLocaleUniqueWhere",
			filter:"AllergenLocaleWhere"
		},
		listAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		paginateAllergenLocale:{
			filter:"AllergenLocaleWhere",
			orderBy:"AllergenLocaleOrderBy"
		},
		getGps:{
			by:"GpsUniqueWhere",
			filter:"GpsWhere"
		},
		listGps:{
			filter:"GpsWhere",
			orderBy:"GpsOrderBy"
		},
		paginateGps:{
			filter:"GpsWhere",
			orderBy:"GpsOrderBy"
		},
		validateCreateGps:{
			data:"GpsCreateInput"
		},
		validateUpdateGps:{
			by:"GpsUniqueWhere",
			data:"GpsUpdateInput"
		},
		getWeekHours:{
			by:"WeekHoursUniqueWhere",
			filter:"WeekHoursWhere"
		},
		listWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		paginateWeekHours:{
			filter:"WeekHoursWhere",
			orderBy:"WeekHoursOrderBy"
		},
		getDeliveryZone:{
			by:"DeliveryZoneUniqueWhere",
			filter:"DeliveryZoneWhere"
		},
		listDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		paginateDeliveryZone:{
			filter:"DeliveryZoneWhere",
			orderBy:"DeliveryZoneOrderBy"
		},
		getAddressMetadata:{
			by:"AddressMetadataUniqueWhere",
			filter:"AddressMetadataWhere"
		},
		listAddressMetadata:{
			filter:"AddressMetadataWhere",
			orderBy:"AddressMetadataOrderBy"
		},
		paginateAddressMetadata:{
			filter:"AddressMetadataWhere",
			orderBy:"AddressMetadataOrderBy"
		},
		getDeliveryTimelinePreset:{
			by:"DeliveryTimelinePresetUniqueWhere",
			filter:"DeliveryTimelinePresetWhere"
		},
		listDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		paginateDeliveryTimelinePreset:{
			filter:"DeliveryTimelinePresetWhere",
			orderBy:"DeliveryTimelinePresetOrderBy"
		},
		getInvoice:{
			by:"InvoiceUniqueWhere",
			filter:"InvoiceWhere"
		},
		listInvoice:{
			filter:"InvoiceWhere",
			orderBy:"InvoiceOrderBy"
		},
		paginateInvoice:{
			filter:"InvoiceWhere",
			orderBy:"InvoiceOrderBy"
		},
		getInvoicingPlan:{
			by:"InvoicingPlanUniqueWhere",
			filter:"InvoicingPlanWhere"
		},
		listInvoicingPlan:{
			filter:"InvoicingPlanWhere",
			orderBy:"InvoicingPlanOrderBy"
		},
		paginateInvoicingPlan:{
			filter:"InvoicingPlanWhere",
			orderBy:"InvoicingPlanOrderBy"
		},
		getProductPackingLocale:{
			by:"ProductPackingLocaleUniqueWhere",
			filter:"ProductPackingLocaleWhere"
		},
		listProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		paginateProductPackingLocale:{
			filter:"ProductPackingLocaleWhere",
			orderBy:"ProductPackingLocaleOrderBy"
		},
		getProductLocale:{
			by:"ProductLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		listProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		getProductRecipeLocale:{
			by:"ProductRecipeLocaleUniqueWhere",
			filter:"ProductRecipeLocaleWhere"
		},
		listProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		paginateProductRecipeLocale:{
			filter:"ProductRecipeLocaleWhere",
			orderBy:"ProductRecipeLocaleOrderBy"
		},
		getInvoicingPreset:{
			by:"InvoicingPresetUniqueWhere",
			filter:"InvoicingPresetWhere"
		},
		listInvoicingPreset:{
			filter:"InvoicingPresetWhere",
			orderBy:"InvoicingPresetOrderBy"
		},
		paginateInvoicingPreset:{
			filter:"InvoicingPresetWhere",
			orderBy:"InvoicingPresetOrderBy"
		},
		getBillingSubject:{
			by:"BillingSubjectUniqueWhere",
			filter:"BillingSubjectWhere"
		},
		listBillingSubject:{
			filter:"BillingSubjectWhere",
			orderBy:"BillingSubjectOrderBy"
		},
		paginateBillingSubject:{
			filter:"BillingSubjectWhere",
			orderBy:"BillingSubjectOrderBy"
		},
		validateCreateBillingSubject:{
			data:"BillingSubjectCreateInput"
		},
		validateUpdateBillingSubject:{
			by:"BillingSubjectUniqueWhere",
			data:"BillingSubjectUpdateInput"
		},
		getProductCustomerPrice:{
			by:"ProductCustomerPriceUniqueWhere",
			filter:"ProductCustomerPriceWhere"
		},
		listProductCustomerPrice:{
			filter:"ProductCustomerPriceWhere",
			orderBy:"ProductCustomerPriceOrderBy"
		},
		paginateProductCustomerPrice:{
			filter:"ProductCustomerPriceWhere",
			orderBy:"ProductCustomerPriceOrderBy"
		},
		getCartPriceLine:{
			by:"CartPriceLineUniqueWhere",
			filter:"CartPriceLineWhere"
		},
		listCartPriceLine:{
			filter:"CartPriceLineWhere",
			orderBy:"CartPriceLineOrderBy"
		},
		paginateCartPriceLine:{
			filter:"CartPriceLineWhere",
			orderBy:"CartPriceLineOrderBy"
		},
		getVirtualProduct:{
			by:"VirtualProductUniqueWhere",
			filter:"VirtualProductWhere"
		},
		listVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		paginateVirtualProduct:{
			filter:"VirtualProductWhere",
			orderBy:"VirtualProductOrderBy"
		},
		getVirtualProductEffect:{
			by:"VirtualProductEffectUniqueWhere",
			filter:"VirtualProductEffectWhere"
		},
		listVirtualProductEffect:{
			filter:"VirtualProductEffectWhere",
			orderBy:"VirtualProductEffectOrderBy"
		},
		paginateVirtualProductEffect:{
			filter:"VirtualProductEffectWhere",
			orderBy:"VirtualProductEffectOrderBy"
		},
		getOrderPriceLine:{
			by:"OrderPriceLineUniqueWhere",
			filter:"OrderPriceLineWhere"
		},
		listOrderPriceLine:{
			filter:"OrderPriceLineWhere",
			orderBy:"OrderPriceLineOrderBy"
		},
		paginateOrderPriceLine:{
			filter:"OrderPriceLineWhere",
			orderBy:"OrderPriceLineOrderBy"
		},
		getProductMetadata:{
			by:"ProductMetadataUniqueWhere",
			filter:"ProductMetadataWhere"
		},
		listProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		paginateProductMetadata:{
			filter:"ProductMetadataWhere",
			orderBy:"ProductMetadataOrderBy"
		},
		getProductGridCategory:{
			by:"ProductGridCategoryUniqueWhere",
			filter:"ProductGridCategoryWhere"
		},
		listProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		paginateProductGridCategory:{
			filter:"ProductGridCategoryWhere",
			orderBy:"ProductGridCategoryOrderBy"
		},
		getAnonymousSession:{
			by:"AnonymousSessionUniqueWhere",
			filter:"AnonymousSessionWhere"
		},
		listAnonymousSession:{
			filter:"AnonymousSessionWhere",
			orderBy:"AnonymousSessionOrderBy"
		},
		paginateAnonymousSession:{
			filter:"AnonymousSessionWhere",
			orderBy:"AnonymousSessionOrderBy"
		},
		validateCreateAnonymousSession:{
			data:"AnonymousSessionCreateInput"
		},
		validateUpdateAnonymousSession:{
			by:"AnonymousSessionUniqueWhere",
			data:"AnonymousSessionUpdateInput"
		},
		getVoucher:{
			by:"VoucherUniqueWhere",
			filter:"VoucherWhere"
		},
		listVoucher:{
			filter:"VoucherWhere",
			orderBy:"VoucherOrderBy"
		},
		paginateVoucher:{
			filter:"VoucherWhere",
			orderBy:"VoucherOrderBy"
		},
		getVoucherRedemption:{
			by:"VoucherRedemptionUniqueWhere",
			filter:"VoucherRedemptionWhere"
		},
		listVoucherRedemption:{
			filter:"VoucherRedemptionWhere",
			orderBy:"VoucherRedemptionOrderBy"
		},
		paginateVoucherRedemption:{
			filter:"VoucherRedemptionWhere",
			orderBy:"VoucherRedemptionOrderBy"
		},
		getFreshingContainer:{
			by:"FreshingContainerUniqueWhere",
			filter:"FreshingContainerWhere"
		},
		listFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		paginateFreshingContainer:{
			filter:"FreshingContainerWhere",
			orderBy:"FreshingContainerOrderBy"
		},
		getTextItem:{
			by:"TextItemUniqueWhere",
			filter:"TextItemWhere"
		},
		listTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		paginateTextItem:{
			filter:"TextItemWhere",
			orderBy:"TextItemOrderBy"
		},
		getTextList:{
			by:"TextListUniqueWhere",
			filter:"TextListWhere"
		},
		listTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		paginateTextList:{
			filter:"TextListWhere",
			orderBy:"TextListOrderBy"
		},
		getDeliveryRegions:{
			by:"DeliveryRegionsUniqueWhere",
			filter:"DeliveryRegionsWhere"
		},
		listDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		paginateDeliveryRegions:{
			filter:"DeliveryRegionsWhere",
			orderBy:"DeliveryRegionsOrderBy"
		},
		getDeliveryRegionsItem:{
			by:"DeliveryRegionsItemUniqueWhere",
			filter:"DeliveryRegionsItemWhere"
		},
		listDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		paginateDeliveryRegionsItem:{
			filter:"DeliveryRegionsItemWhere",
			orderBy:"DeliveryRegionsItemOrderBy"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getDeliveryMethodLocale:{
			by:"DeliveryMethodLocaleUniqueWhere",
			filter:"DeliveryMethodLocaleWhere"
		},
		listDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		paginateDeliveryMethodLocale:{
			filter:"DeliveryMethodLocaleWhere",
			orderBy:"DeliveryMethodLocaleOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getWorkspace:{
			by:"WorkspaceUniqueWhere",
			filter:"WorkspaceWhere"
		},
		listWorkspace:{
			filter:"WorkspaceWhere",
			orderBy:"WorkspaceOrderBy"
		},
		paginateWorkspace:{
			filter:"WorkspaceWhere",
			orderBy:"WorkspaceOrderBy"
		},
		getCartSummary:{
			by:"CartSummaryUniqueWhere",
			filter:"CartSummaryWhere"
		},
		listCartSummary:{
			filter:"CartSummaryWhere",
			orderBy:"CartSummaryOrderBy"
		},
		paginateCartSummary:{
			filter:"CartSummaryWhere",
			orderBy:"CartSummaryOrderBy"
		},
		getOrderSummary:{
			by:"OrderSummaryUniqueWhere",
			filter:"OrderSummaryWhere"
		},
		listOrderSummary:{
			filter:"OrderSummaryWhere",
			orderBy:"OrderSummaryOrderBy"
		},
		paginateOrderSummary:{
			filter:"OrderSummaryWhere",
			orderBy:"OrderSummaryOrderBy"
		},
		getOrderPaymentMetadata:{
			by:"OrderPaymentMetadataUniqueWhere",
			filter:"OrderPaymentMetadataWhere"
		},
		listOrderPaymentMetadata:{
			filter:"OrderPaymentMetadataWhere",
			orderBy:"OrderPaymentMetadataOrderBy"
		},
		paginateOrderPaymentMetadata:{
			filter:"OrderPaymentMetadataWhere",
			orderBy:"OrderPaymentMetadataOrderBy"
		},
		getUserMetadata:{
			by:"UserMetadataUniqueWhere",
			filter:"UserMetadataWhere"
		},
		listUserMetadata:{
			filter:"UserMetadataWhere",
			orderBy:"UserMetadataOrderBy"
		},
		paginateUserMetadata:{
			filter:"UserMetadataWhere",
			orderBy:"UserMetadataOrderBy"
		},
		validateCreateIdentificationAttempt:{
			data:"IdentificationAttemptCreateInput"
		},
		getIdentificationCode:{
			by:"IdentificationCodeUniqueWhere",
			filter:"IdentificationCodeWhere"
		},
		listIdentificationCode:{
			filter:"IdentificationCodeWhere",
			orderBy:"IdentificationCodeOrderBy"
		},
		paginateIdentificationCode:{
			filter:"IdentificationCodeWhere",
			orderBy:"IdentificationCodeOrderBy"
		},
		getIdentificationCodeLocale:{
			by:"IdentificationCodeLocaleUniqueWhere",
			filter:"IdentificationCodeLocaleWhere"
		},
		listIdentificationCodeLocale:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		paginateIdentificationCodeLocale:{
			filter:"IdentificationCodeLocaleWhere",
			orderBy:"IdentificationCodeLocaleOrderBy"
		},
		getFulfillmentNote:{
			by:"FulfillmentNoteUniqueWhere",
			filter:"FulfillmentNoteWhere"
		},
		listFulfillmentNote:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		paginateFulfillmentNote:{
			filter:"FulfillmentNoteWhere",
			orderBy:"FulfillmentNoteOrderBy"
		},
		getCustomerMetadata:{
			by:"CustomerMetadataUniqueWhere",
			filter:"CustomerMetadataWhere"
		},
		listCustomerMetadata:{
			filter:"CustomerMetadataWhere",
			orderBy:"CustomerMetadataOrderBy"
		},
		paginateCustomerMetadata:{
			filter:"CustomerMetadataWhere",
			orderBy:"CustomerMetadataOrderBy"
		},
		getSubscriptionBox:{
			by:"SubscriptionBoxUniqueWhere",
			filter:"SubscriptionBoxWhere"
		},
		listSubscriptionBox:{
			filter:"SubscriptionBoxWhere",
			orderBy:"SubscriptionBoxOrderBy"
		},
		paginateSubscriptionBox:{
			filter:"SubscriptionBoxWhere",
			orderBy:"SubscriptionBoxOrderBy"
		},
		getSubscriptionBoxLocale:{
			by:"SubscriptionBoxLocaleUniqueWhere",
			filter:"SubscriptionBoxLocaleWhere"
		},
		listSubscriptionBoxLocale:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		paginateSubscriptionBoxLocale:{
			filter:"SubscriptionBoxLocaleWhere",
			orderBy:"SubscriptionBoxLocaleOrderBy"
		},
		getGridTileItem:{
			by:"GridTileItemUniqueWhere",
			filter:"GridTileItemWhere"
		},
		listGridTileItem:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		},
		paginateGridTileItem:{
			filter:"GridTileItemWhere",
			orderBy:"GridTileItemOrderBy"
		},
		getGridTiles:{
			by:"GridTilesUniqueWhere",
			filter:"GridTilesWhere"
		},
		listGridTiles:{
			filter:"GridTilesWhere",
			orderBy:"GridTilesOrderBy"
		},
		paginateGridTiles:{
			filter:"GridTilesWhere",
			orderBy:"GridTilesOrderBy"
		},
		getPosition:{
			by:"PositionUniqueWhere",
			filter:"PositionWhere"
		},
		listPosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		paginatePosition:{
			filter:"PositionWhere",
			orderBy:"PositionOrderBy"
		},
		getStatsWidget:{
			by:"StatsWidgetUniqueWhere",
			filter:"StatsWidgetWhere"
		},
		listStatsWidget:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		},
		paginateStatsWidget:{
			filter:"StatsWidgetWhere",
			orderBy:"StatsWidgetOrderBy"
		},
		getStatsWidgetsLayout:{
			by:"StatsWidgetsLayoutUniqueWhere",
			filter:"StatsWidgetsLayoutWhere"
		},
		listStatsWidgetsLayout:{
			filter:"StatsWidgetsLayoutWhere",
			orderBy:"StatsWidgetsLayoutOrderBy"
		},
		paginateStatsWidgetsLayout:{
			filter:"StatsWidgetsLayoutWhere",
			orderBy:"StatsWidgetsLayoutOrderBy"
		},
		getStatsPage:{
			by:"StatsPageUniqueWhere",
			filter:"StatsPageWhere"
		},
		listStatsPage:{
			filter:"StatsPageWhere",
			orderBy:"StatsPageOrderBy"
		},
		paginateStatsPage:{
			filter:"StatsPageWhere",
			orderBy:"StatsPageOrderBy"
		}
	},
	Mutation:{
		createAddress:{
			data:"AddressCreateInput"
		},
		deleteAddress:{
			by:"AddressUniqueWhere",
			filter:"AddressWhere"
		},
		updateAddress:{
			by:"AddressUniqueWhere",
			filter:"AddressWhere",
			data:"AddressUpdateInput"
		},
		upsertAddress:{
			by:"AddressUniqueWhere",
			filter:"AddressWhere",
			update:"AddressUpdateInput",
			create:"AddressCreateInput"
		},
		updateCustomer:{
			by:"CustomerUniqueWhere",
			filter:"CustomerWhere",
			data:"CustomerUpdateInput"
		},
		createOrder:{
			data:"OrderCreateInput"
		},
		createOrderDelivery:{
			data:"OrderDeliveryCreateInput"
		},
		createOrderItem:{
			data:"OrderItemCreateInput"
		},
		createCustomerName:{
			data:"CustomerNameCreateInput"
		},
		updateCustomerName:{
			by:"CustomerNameUniqueWhere",
			filter:"CustomerNameWhere",
			data:"CustomerNameUpdateInput"
		},
		upsertCustomerName:{
			by:"CustomerNameUniqueWhere",
			filter:"CustomerNameWhere",
			update:"CustomerNameUpdateInput",
			create:"CustomerNameCreateInput"
		},
		createCustomerIngredientRating:{
			data:"CustomerIngredientRatingCreateInput"
		},
		deleteCustomerIngredientRating:{
			by:"CustomerIngredientRatingUniqueWhere",
			filter:"CustomerIngredientRatingWhere"
		},
		updateCustomerIngredientRating:{
			by:"CustomerIngredientRatingUniqueWhere",
			filter:"CustomerIngredientRatingWhere",
			data:"CustomerIngredientRatingUpdateInput"
		},
		upsertCustomerIngredientRating:{
			by:"CustomerIngredientRatingUniqueWhere",
			filter:"CustomerIngredientRatingWhere",
			update:"CustomerIngredientRatingUpdateInput",
			create:"CustomerIngredientRatingCreateInput"
		},
		createCart:{
			data:"CartCreateInput"
		},
		createCartItem:{
			data:"CartItemCreateInput"
		},
		deleteCartItem:{
			by:"CartItemUniqueWhere",
			filter:"CartItemWhere"
		},
		updateCartItem:{
			by:"CartItemUniqueWhere",
			filter:"CartItemWhere",
			data:"CartItemUpdateInput"
		},
		upsertCartItem:{
			by:"CartItemUniqueWhere",
			filter:"CartItemWhere",
			update:"CartItemUpdateInput",
			create:"CartItemCreateInput"
		},
		createGps:{
			data:"GpsCreateInput"
		},
		updateGps:{
			by:"GpsUniqueWhere",
			filter:"GpsWhere",
			data:"GpsUpdateInput"
		},
		upsertGps:{
			by:"GpsUniqueWhere",
			filter:"GpsWhere",
			update:"GpsUpdateInput",
			create:"GpsCreateInput"
		},
		createBillingSubject:{
			data:"BillingSubjectCreateInput"
		},
		updateBillingSubject:{
			by:"BillingSubjectUniqueWhere",
			filter:"BillingSubjectWhere",
			data:"BillingSubjectUpdateInput"
		},
		upsertBillingSubject:{
			by:"BillingSubjectUniqueWhere",
			filter:"BillingSubjectWhere",
			update:"BillingSubjectUpdateInput",
			create:"BillingSubjectCreateInput"
		},
		createAnonymousSession:{
			data:"AnonymousSessionCreateInput"
		},
		deleteAnonymousSession:{
			by:"AnonymousSessionUniqueWhere",
			filter:"AnonymousSessionWhere"
		},
		updateAnonymousSession:{
			by:"AnonymousSessionUniqueWhere",
			filter:"AnonymousSessionWhere",
			data:"AnonymousSessionUpdateInput"
		},
		upsertAnonymousSession:{
			by:"AnonymousSessionUniqueWhere",
			filter:"AnonymousSessionWhere",
			update:"AnonymousSessionUpdateInput",
			create:"AnonymousSessionCreateInput"
		},
		createIdentificationAttempt:{
			data:"IdentificationAttemptCreateInput"
		},
		transaction:{
			options:"MutationTransactionOptions"
		}
	},
	_MutationErrorType: true,
	MutationTransaction:{
		createAddress:{
			data:"AddressCreateInput"
		},
		deleteAddress:{
			by:"AddressUniqueWhere",
			filter:"AddressWhere"
		},
		updateAddress:{
			by:"AddressUniqueWhere",
			filter:"AddressWhere",
			data:"AddressUpdateInput"
		},
		upsertAddress:{
			by:"AddressUniqueWhere",
			filter:"AddressWhere",
			update:"AddressUpdateInput",
			create:"AddressCreateInput"
		},
		updateCustomer:{
			by:"CustomerUniqueWhere",
			filter:"CustomerWhere",
			data:"CustomerUpdateInput"
		},
		createOrder:{
			data:"OrderCreateInput"
		},
		createOrderDelivery:{
			data:"OrderDeliveryCreateInput"
		},
		createOrderItem:{
			data:"OrderItemCreateInput"
		},
		createCustomerName:{
			data:"CustomerNameCreateInput"
		},
		updateCustomerName:{
			by:"CustomerNameUniqueWhere",
			filter:"CustomerNameWhere",
			data:"CustomerNameUpdateInput"
		},
		upsertCustomerName:{
			by:"CustomerNameUniqueWhere",
			filter:"CustomerNameWhere",
			update:"CustomerNameUpdateInput",
			create:"CustomerNameCreateInput"
		},
		createCustomerIngredientRating:{
			data:"CustomerIngredientRatingCreateInput"
		},
		deleteCustomerIngredientRating:{
			by:"CustomerIngredientRatingUniqueWhere",
			filter:"CustomerIngredientRatingWhere"
		},
		updateCustomerIngredientRating:{
			by:"CustomerIngredientRatingUniqueWhere",
			filter:"CustomerIngredientRatingWhere",
			data:"CustomerIngredientRatingUpdateInput"
		},
		upsertCustomerIngredientRating:{
			by:"CustomerIngredientRatingUniqueWhere",
			filter:"CustomerIngredientRatingWhere",
			update:"CustomerIngredientRatingUpdateInput",
			create:"CustomerIngredientRatingCreateInput"
		},
		createCart:{
			data:"CartCreateInput"
		},
		createCartItem:{
			data:"CartItemCreateInput"
		},
		deleteCartItem:{
			by:"CartItemUniqueWhere",
			filter:"CartItemWhere"
		},
		updateCartItem:{
			by:"CartItemUniqueWhere",
			filter:"CartItemWhere",
			data:"CartItemUpdateInput"
		},
		upsertCartItem:{
			by:"CartItemUniqueWhere",
			filter:"CartItemWhere",
			update:"CartItemUpdateInput",
			create:"CartItemCreateInput"
		},
		createGps:{
			data:"GpsCreateInput"
		},
		updateGps:{
			by:"GpsUniqueWhere",
			filter:"GpsWhere",
			data:"GpsUpdateInput"
		},
		upsertGps:{
			by:"GpsUniqueWhere",
			filter:"GpsWhere",
			update:"GpsUpdateInput",
			create:"GpsCreateInput"
		},
		createBillingSubject:{
			data:"BillingSubjectCreateInput"
		},
		updateBillingSubject:{
			by:"BillingSubjectUniqueWhere",
			filter:"BillingSubjectWhere",
			data:"BillingSubjectUpdateInput"
		},
		upsertBillingSubject:{
			by:"BillingSubjectUniqueWhere",
			filter:"BillingSubjectWhere",
			update:"BillingSubjectUpdateInput",
			create:"BillingSubjectCreateInput"
		},
		createAnonymousSession:{
			data:"AnonymousSessionCreateInput"
		},
		deleteAnonymousSession:{
			by:"AnonymousSessionUniqueWhere",
			filter:"AnonymousSessionWhere"
		},
		updateAnonymousSession:{
			by:"AnonymousSessionUniqueWhere",
			filter:"AnonymousSessionWhere",
			data:"AnonymousSessionUpdateInput"
		},
		upsertAnonymousSession:{
			by:"AnonymousSessionUniqueWhere",
			filter:"AnonymousSessionWhere",
			update:"AnonymousSessionUpdateInput",
			create:"AnonymousSessionCreateInput"
		},
		createIdentificationAttempt:{
			data:"IdentificationAttemptCreateInput"
		}
	},
	MutationTransactionOptions:{

	},
	_OnDeleteBehaviour: true,
	_RelationSide: true,
	_OrderByDirection: true
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		getAddress:"Address",
		listAddress:"Address",
		paginateAddress:"AddressConnection",
		validateCreateAddress:"_ValidationResult",
		validateUpdateAddress:"_ValidationResult",
		getCountry:"Country",
		listCountry:"Country",
		paginateCountry:"CountryConnection",
		getChannel:"Channel",
		listChannel:"Channel",
		paginateChannel:"ChannelConnection",
		getChannelPayment:"ChannelPayment",
		listChannelPayment:"ChannelPayment",
		paginateChannelPayment:"ChannelPaymentConnection",
		getChannelDelivery:"ChannelDelivery",
		listChannelDelivery:"ChannelDelivery",
		paginateChannelDelivery:"ChannelDeliveryConnection",
		getCustomer:"Customer",
		listCustomer:"Customer",
		paginateCustomer:"CustomerConnection",
		validateUpdateCustomer:"_ValidationResult",
		getCustomerCredit:"CustomerCredit",
		listCustomerCredit:"CustomerCredit",
		paginateCustomerCredit:"CustomerCreditConnection",
		getCustomerGroup:"CustomerGroup",
		listCustomerGroup:"CustomerGroup",
		paginateCustomerGroup:"CustomerGroupConnection",
		getDeliveryMethod:"DeliveryMethod",
		listDeliveryMethod:"DeliveryMethod",
		paginateDeliveryMethod:"DeliveryMethodConnection",
		getOrder:"Order",
		listOrder:"Order",
		paginateOrder:"OrderConnection",
		validateCreateOrder:"_ValidationResult",
		getOrderDiscount:"OrderDiscount",
		listOrderDiscount:"OrderDiscount",
		paginateOrderDiscount:"OrderDiscountConnection",
		getOrderPayment:"OrderPayment",
		listOrderPayment:"OrderPayment",
		paginateOrderPayment:"OrderPaymentConnection",
		getOrderDelivery:"OrderDelivery",
		listOrderDelivery:"OrderDelivery",
		paginateOrderDelivery:"OrderDeliveryConnection",
		validateCreateOrderDelivery:"_ValidationResult",
		getOrderItem:"OrderItem",
		listOrderItem:"OrderItem",
		paginateOrderItem:"OrderItemConnection",
		validateCreateOrderItem:"_ValidationResult",
		getPaymentMethod:"PaymentMethod",
		listPaymentMethod:"PaymentMethod",
		paginatePaymentMethod:"PaymentMethodConnection",
		getVatRate:"VatRate",
		listVatRate:"VatRate",
		paginateVatRate:"VatRateConnection",
		getProduct:"Product",
		listProduct:"Product",
		paginateProduct:"ProductConnection",
		getProductStock:"ProductStock",
		listProductStock:"ProductStock",
		paginateProductStock:"ProductStockConnection",
		getProductPacking:"ProductPacking",
		listProductPacking:"ProductPacking",
		paginateProductPacking:"ProductPackingConnection",
		getProductGroupPrice:"ProductGroupPrice",
		listProductGroupPrice:"ProductGroupPrice",
		paginateProductGroupPrice:"ProductGroupPriceConnection",
		getProductRecipe:"ProductRecipe",
		listProductRecipe:"ProductRecipe",
		paginateProductRecipe:"ProductRecipeConnection",
		getProductCategory:"ProductCategory",
		listProductCategory:"ProductCategory",
		paginateProductCategory:"ProductCategoryConnection",
		getStore:"Store",
		listStore:"Store",
		paginateStore:"StoreConnection",
		getTag:"Tag",
		listTag:"Tag",
		paginateTag:"TagConnection",
		getCustomerPermissions:"CustomerPermissions",
		listCustomerPermissions:"CustomerPermissions",
		paginateCustomerPermissions:"CustomerPermissionsConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getBusinessCategory:"BusinessCategory",
		listBusinessCategory:"BusinessCategory",
		paginateBusinessCategory:"BusinessCategoryConnection",
		getProductList:"ProductList",
		listProductList:"ProductList",
		paginateProductList:"ProductListConnection",
		getProductListItem:"ProductListItem",
		listProductListItem:"ProductListItem",
		paginateProductListItem:"ProductListItemConnection",
		getProductCategoryList:"ProductCategoryList",
		listProductCategoryList:"ProductCategoryList",
		paginateProductCategoryList:"ProductCategoryListConnection",
		getProductCategoryListItem:"ProductCategoryListItem",
		listProductCategoryListItem:"ProductCategoryListItem",
		paginateProductCategoryListItem:"ProductCategoryListItemConnection",
		getBusinessCategoryLocale:"BusinessCategoryLocale",
		listBusinessCategoryLocale:"BusinessCategoryLocale",
		paginateBusinessCategoryLocale:"BusinessCategoryLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getProductCategoryListLocale:"ProductCategoryListLocale",
		listProductCategoryListLocale:"ProductCategoryListLocale",
		paginateProductCategoryListLocale:"ProductCategoryListLocaleConnection",
		getCustomerName:"CustomerName",
		listCustomerName:"CustomerName",
		paginateCustomerName:"CustomerNameConnection",
		validateCreateCustomerName:"_ValidationResult",
		validateUpdateCustomerName:"_ValidationResult",
		getProductIngredientItem:"ProductIngredientItem",
		listProductIngredientItem:"ProductIngredientItem",
		paginateProductIngredientItem:"ProductIngredientItemConnection",
		getProductIngredient:"ProductIngredient",
		listProductIngredient:"ProductIngredient",
		paginateProductIngredient:"ProductIngredientConnection",
		getCustomerIngredientRating:"CustomerIngredientRating",
		listCustomerIngredientRating:"CustomerIngredientRating",
		paginateCustomerIngredientRating:"CustomerIngredientRatingConnection",
		validateCreateCustomerIngredientRating:"_ValidationResult",
		validateUpdateCustomerIngredientRating:"_ValidationResult",
		getProductIngredientRating:"ProductIngredientRating",
		listProductIngredientRating:"ProductIngredientRating",
		paginateProductIngredientRating:"ProductIngredientRatingConnection",
		getProductIngredientLocale:"ProductIngredientLocale",
		listProductIngredientLocale:"ProductIngredientLocale",
		paginateProductIngredientLocale:"ProductIngredientLocaleConnection",
		getOrderRecurrence:"OrderRecurrence",
		listOrderRecurrence:"OrderRecurrence",
		paginateOrderRecurrence:"OrderRecurrenceConnection",
		getOrderRecurrenceLocale:"OrderRecurrenceLocale",
		listOrderRecurrenceLocale:"OrderRecurrenceLocale",
		paginateOrderRecurrenceLocale:"OrderRecurrenceLocaleConnection",
		getBusinessCustomer:"BusinessCustomer",
		listBusinessCustomer:"BusinessCustomer",
		paginateBusinessCustomer:"BusinessCustomerConnection",
		getStaff:"Staff",
		listStaff:"Staff",
		paginateStaff:"StaffConnection",
		getBlog:"Blog",
		listBlog:"Blog",
		paginateBlog:"BlogConnection",
		getBlogLocale:"BlogLocale",
		listBlogLocale:"BlogLocale",
		paginateBlogLocale:"BlogLocaleConnection",
		getBlogPost:"BlogPost",
		listBlogPost:"BlogPost",
		paginateBlogPost:"BlogPostConnection",
		getBlogPostLocale:"BlogPostLocale",
		listBlogPostLocale:"BlogPostLocale",
		paginateBlogPostLocale:"BlogPostLocaleConnection",
		getColorPallete:"ColorPallete",
		listColorPallete:"ColorPallete",
		paginateColorPallete:"ColorPalleteConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getBlogPostList:"BlogPostList",
		listBlogPostList:"BlogPostList",
		paginateBlogPostList:"BlogPostListConnection",
		getBlogPostListItem:"BlogPostListItem",
		listBlogPostListItem:"BlogPostListItem",
		paginateBlogPostListItem:"BlogPostListItemConnection",
		getLinkList:"LinkList",
		listLinkList:"LinkList",
		paginateLinkList:"LinkListConnection",
		getLinkListItem:"LinkListItem",
		listLinkListItem:"LinkListItem",
		paginateLinkListItem:"LinkListItemConnection",
		getCover:"Cover",
		listCover:"Cover",
		paginateCover:"CoverConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getMedium:"Medium",
		listMedium:"Medium",
		paginateMedium:"MediumConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		getTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		listTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		paginateTranslationCataloguesIdentifier:"TranslationCataloguesIdentifierConnection",
		getSocialsAndApps:"SocialsAndApps",
		listSocialsAndApps:"SocialsAndApps",
		paginateSocialsAndApps:"SocialsAndAppsConnection",
		getProductGrid:"ProductGrid",
		listProductGrid:"ProductGrid",
		paginateProductGrid:"ProductGridConnection",
		getProductGridItem:"ProductGridItem",
		listProductGridItem:"ProductGridItem",
		paginateProductGridItem:"ProductGridItemConnection",
		getGallery:"Gallery",
		listGallery:"Gallery",
		paginateGallery:"GalleryConnection",
		getGalleryItem:"GalleryItem",
		listGalleryItem:"GalleryItem",
		paginateGalleryItem:"GalleryItemConnection",
		getHero:"Hero",
		listHero:"Hero",
		paginateHero:"HeroConnection",
		getPage:"Page",
		listPage:"Page",
		paginatePage:"PageConnection",
		getPageLocale:"PageLocale",
		listPageLocale:"PageLocale",
		paginatePageLocale:"PageLocaleConnection",
		getCart:"Cart",
		listCart:"Cart",
		paginateCart:"CartConnection",
		validateCreateCart:"_ValidationResult",
		getCartItem:"CartItem",
		listCartItem:"CartItem",
		paginateCartItem:"CartItemConnection",
		validateCreateCartItem:"_ValidationResult",
		validateUpdateCartItem:"_ValidationResult",
		getProductBanner:"ProductBanner",
		listProductBanner:"ProductBanner",
		paginateProductBanner:"ProductBannerConnection",
		getProductBannerItem:"ProductBannerItem",
		listProductBannerItem:"ProductBannerItem",
		paginateProductBannerItem:"ProductBannerItemConnection",
		getProductBannerList:"ProductBannerList",
		listProductBannerList:"ProductBannerList",
		paginateProductBannerList:"ProductBannerListConnection",
		getUser:"User",
		listUser:"User",
		paginateUser:"UserConnection",
		getAllergen:"Allergen",
		listAllergen:"Allergen",
		paginateAllergen:"AllergenConnection",
		getAllergenLocale:"AllergenLocale",
		listAllergenLocale:"AllergenLocale",
		paginateAllergenLocale:"AllergenLocaleConnection",
		getGps:"Gps",
		listGps:"Gps",
		paginateGps:"GpsConnection",
		validateCreateGps:"_ValidationResult",
		validateUpdateGps:"_ValidationResult",
		getWeekHours:"WeekHours",
		listWeekHours:"WeekHours",
		paginateWeekHours:"WeekHoursConnection",
		getDeliveryZone:"DeliveryZone",
		listDeliveryZone:"DeliveryZone",
		paginateDeliveryZone:"DeliveryZoneConnection",
		getAddressMetadata:"AddressMetadata",
		listAddressMetadata:"AddressMetadata",
		paginateAddressMetadata:"AddressMetadataConnection",
		getDeliveryTimelinePreset:"DeliveryTimelinePreset",
		listDeliveryTimelinePreset:"DeliveryTimelinePreset",
		paginateDeliveryTimelinePreset:"DeliveryTimelinePresetConnection",
		getInvoice:"Invoice",
		listInvoice:"Invoice",
		paginateInvoice:"InvoiceConnection",
		getInvoicingPlan:"InvoicingPlan",
		listInvoicingPlan:"InvoicingPlan",
		paginateInvoicingPlan:"InvoicingPlanConnection",
		getProductPackingLocale:"ProductPackingLocale",
		listProductPackingLocale:"ProductPackingLocale",
		paginateProductPackingLocale:"ProductPackingLocaleConnection",
		getProductLocale:"ProductLocale",
		listProductLocale:"ProductLocale",
		paginateProductLocale:"ProductLocaleConnection",
		getProductRecipeLocale:"ProductRecipeLocale",
		listProductRecipeLocale:"ProductRecipeLocale",
		paginateProductRecipeLocale:"ProductRecipeLocaleConnection",
		getInvoicingPreset:"InvoicingPreset",
		listInvoicingPreset:"InvoicingPreset",
		paginateInvoicingPreset:"InvoicingPresetConnection",
		getBillingSubject:"BillingSubject",
		listBillingSubject:"BillingSubject",
		paginateBillingSubject:"BillingSubjectConnection",
		validateCreateBillingSubject:"_ValidationResult",
		validateUpdateBillingSubject:"_ValidationResult",
		getProductCustomerPrice:"ProductCustomerPrice",
		listProductCustomerPrice:"ProductCustomerPrice",
		paginateProductCustomerPrice:"ProductCustomerPriceConnection",
		getCartPriceLine:"CartPriceLine",
		listCartPriceLine:"CartPriceLine",
		paginateCartPriceLine:"CartPriceLineConnection",
		getVirtualProduct:"VirtualProduct",
		listVirtualProduct:"VirtualProduct",
		paginateVirtualProduct:"VirtualProductConnection",
		getVirtualProductEffect:"VirtualProductEffect",
		listVirtualProductEffect:"VirtualProductEffect",
		paginateVirtualProductEffect:"VirtualProductEffectConnection",
		getOrderPriceLine:"OrderPriceLine",
		listOrderPriceLine:"OrderPriceLine",
		paginateOrderPriceLine:"OrderPriceLineConnection",
		getProductMetadata:"ProductMetadata",
		listProductMetadata:"ProductMetadata",
		paginateProductMetadata:"ProductMetadataConnection",
		getProductGridCategory:"ProductGridCategory",
		listProductGridCategory:"ProductGridCategory",
		paginateProductGridCategory:"ProductGridCategoryConnection",
		getAnonymousSession:"AnonymousSession",
		listAnonymousSession:"AnonymousSession",
		paginateAnonymousSession:"AnonymousSessionConnection",
		validateCreateAnonymousSession:"_ValidationResult",
		validateUpdateAnonymousSession:"_ValidationResult",
		getVoucher:"Voucher",
		listVoucher:"Voucher",
		paginateVoucher:"VoucherConnection",
		getVoucherRedemption:"VoucherRedemption",
		listVoucherRedemption:"VoucherRedemption",
		paginateVoucherRedemption:"VoucherRedemptionConnection",
		getFreshingContainer:"FreshingContainer",
		listFreshingContainer:"FreshingContainer",
		paginateFreshingContainer:"FreshingContainerConnection",
		getTextItem:"TextItem",
		listTextItem:"TextItem",
		paginateTextItem:"TextItemConnection",
		getTextList:"TextList",
		listTextList:"TextList",
		paginateTextList:"TextListConnection",
		getDeliveryRegions:"DeliveryRegions",
		listDeliveryRegions:"DeliveryRegions",
		paginateDeliveryRegions:"DeliveryRegionsConnection",
		getDeliveryRegionsItem:"DeliveryRegionsItem",
		listDeliveryRegionsItem:"DeliveryRegionsItem",
		paginateDeliveryRegionsItem:"DeliveryRegionsItemConnection",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getDeliveryMethodLocale:"DeliveryMethodLocale",
		listDeliveryMethodLocale:"DeliveryMethodLocale",
		paginateDeliveryMethodLocale:"DeliveryMethodLocaleConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getWorkspace:"Workspace",
		listWorkspace:"Workspace",
		paginateWorkspace:"WorkspaceConnection",
		getCartSummary:"CartSummary",
		listCartSummary:"CartSummary",
		paginateCartSummary:"CartSummaryConnection",
		getOrderSummary:"OrderSummary",
		listOrderSummary:"OrderSummary",
		paginateOrderSummary:"OrderSummaryConnection",
		getOrderPaymentMetadata:"OrderPaymentMetadata",
		listOrderPaymentMetadata:"OrderPaymentMetadata",
		paginateOrderPaymentMetadata:"OrderPaymentMetadataConnection",
		getUserMetadata:"UserMetadata",
		listUserMetadata:"UserMetadata",
		paginateUserMetadata:"UserMetadataConnection",
		validateCreateIdentificationAttempt:"_ValidationResult",
		getIdentificationCode:"IdentificationCode",
		listIdentificationCode:"IdentificationCode",
		paginateIdentificationCode:"IdentificationCodeConnection",
		getIdentificationCodeLocale:"IdentificationCodeLocale",
		listIdentificationCodeLocale:"IdentificationCodeLocale",
		paginateIdentificationCodeLocale:"IdentificationCodeLocaleConnection",
		getFulfillmentNote:"FulfillmentNote",
		listFulfillmentNote:"FulfillmentNote",
		paginateFulfillmentNote:"FulfillmentNoteConnection",
		getCustomerMetadata:"CustomerMetadata",
		listCustomerMetadata:"CustomerMetadata",
		paginateCustomerMetadata:"CustomerMetadataConnection",
		getSubscriptionBox:"SubscriptionBox",
		listSubscriptionBox:"SubscriptionBox",
		paginateSubscriptionBox:"SubscriptionBoxConnection",
		getSubscriptionBoxLocale:"SubscriptionBoxLocale",
		listSubscriptionBoxLocale:"SubscriptionBoxLocale",
		paginateSubscriptionBoxLocale:"SubscriptionBoxLocaleConnection",
		getGridTileItem:"GridTileItem",
		listGridTileItem:"GridTileItem",
		paginateGridTileItem:"GridTileItemConnection",
		getGridTiles:"GridTiles",
		listGridTiles:"GridTiles",
		paginateGridTiles:"GridTilesConnection",
		getPosition:"Position",
		listPosition:"Position",
		paginatePosition:"PositionConnection",
		getStatsWidget:"StatsWidget",
		listStatsWidget:"StatsWidget",
		paginateStatsWidget:"StatsWidgetConnection",
		getStatsWidgetsLayout:"StatsWidgetsLayout",
		listStatsWidgetsLayout:"StatsWidgetsLayout",
		paginateStatsWidgetsLayout:"StatsWidgetsLayoutConnection",
		getStatsPage:"StatsPage",
		listStatsPage:"StatsPage",
		paginateStatsPage:"StatsPageConnection",
		transaction:"QueryTransaction",
		_info:"Info",
		schema:"_Schema"
	},
	Address:{
		_meta:"AddressMeta",
		id:"UUID",
		name:"String",
		firstName:"String",
		lastName:"String",
		addressLine2:"String",
		email:"String",
		phone:"String",
		companyName:"String",
		companyIdentifier:"String",
		vatIdentifier:"String",
		externalIdentifier:"String",
		hidden:"Boolean",
		customer:"Customer",
		country:"Country",
		deletedAt:"DateTime",
		note:"String",
		googlePlaceId:"String",
		raw:"String",
		formatted:"String",
		geocodeGeneratedAt:"DateTime",
		geocodeResponse:"String",
		deliveryZone:"DeliveryZone",
		meta:"AddressMetadata",
		gps:"Gps",
		geocodeValid:"Boolean",
		fullName:"String",
		fakturoidSubjectId:"String",
		invoices:"Invoice",
		addressLine1:"String",
		city:"String",
		postalCode:"String",
		createdAt:"DateTime",
		billingAddressOfOrders:"Order",
		deliveryAddressOfOrderDeliveries:"OrderDelivery",
		defaultDeliveryAddressOfCustomer:"Customer",
		invoicesByPublicKey:"Invoice",
		invoicesByFakturoidId:"Invoice",
		invoicesByOrderPayments:"Invoice",
		billingAddressOfOrdersBySeq:"Order",
		billingAddressOfOrdersByDoubleshotLegacyId:"Order",
		billingAddressOfOrdersByDoubleShotLegacyNumber:"Order",
		billingAddressOfOrdersByDiscounts:"Order",
		billingAddressOfOrdersByDelivery:"Order",
		billingAddressOfOrdersByItems:"Order",
		billingAddressOfOrdersByCart:"Order",
		billingAddressOfOrdersByPriceLines:"Order",
		billingAddressOfOrdersByPayments:"Order",
		billingAddressOfOrdersBySummary:"Order",
		deliveryAddressOfOrderDeliveriesByTrackingCode:"OrderDelivery",
		deliveryAddressOfOrderDeliveriesByOrder:"OrderDelivery",
		paginateInvoices:"InvoiceConnection",
		paginateBillingAddressOfOrders:"OrderConnection",
		paginateDeliveryAddressOfOrderDeliveries:"OrderDeliveryConnection"
	},
	AddressMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		firstName:"FieldMeta",
		lastName:"FieldMeta",
		addressLine2:"FieldMeta",
		email:"FieldMeta",
		phone:"FieldMeta",
		companyName:"FieldMeta",
		companyIdentifier:"FieldMeta",
		vatIdentifier:"FieldMeta",
		externalIdentifier:"FieldMeta",
		hidden:"FieldMeta",
		customer:"FieldMeta",
		country:"FieldMeta",
		deletedAt:"FieldMeta",
		note:"FieldMeta",
		googlePlaceId:"FieldMeta",
		raw:"FieldMeta",
		formatted:"FieldMeta",
		geocodeGeneratedAt:"FieldMeta",
		geocodeResponse:"FieldMeta",
		deliveryZone:"FieldMeta",
		meta:"FieldMeta",
		gps:"FieldMeta",
		geocodeValid:"FieldMeta",
		fullName:"FieldMeta",
		fakturoidSubjectId:"FieldMeta",
		invoices:"FieldMeta",
		addressLine1:"FieldMeta",
		city:"FieldMeta",
		postalCode:"FieldMeta",
		createdAt:"FieldMeta",
		billingAddressOfOrders:"FieldMeta",
		deliveryAddressOfOrderDeliveries:"FieldMeta",
		defaultDeliveryAddressOfCustomer:"FieldMeta"
	},
	FieldMeta:{
		readable:"Boolean",
		updatable:"Boolean"
	},
	Customer:{
		_meta:"CustomerMeta",
		id:"UUID",
		addresses:"Address",
		defaultBillingAddress:"Address",
		tags:"Tag",
		credits:"CustomerCredit",
		orders:"Order",
		permissions:"CustomerPermissions",
		group:"CustomerGroup",
		photo:"Image",
		phone:"String",
		name:"CustomerName",
		welcomeMessage:"String",
		familiarity:"CustomerFamiliarity",
		ingredientRatings:"CustomerIngredientRating",
		business:"BusinessCustomer",
		ownerOfBusinesses:"BusinessCustomer",
		memberOfBusinesses:"BusinessCustomer",
		carts:"Cart",
		user:"User",
		channels:"Channel",
		disabledAt:"DateTime",
		invoices:"Invoice",
		invoicingPlan:"InvoicingPlan",
		email:"String",
		createdAt:"DateTime",
		billingSubjects:"BillingSubject",
		offeredProducts:"ProductCustomerPrice",
		defaultDeliveryAddress:"Address",
		defaultBillingSubject:"BillingSubject",
		prefersPackingsWithoutCardboard:"Boolean",
		anonymousSession:"AnonymousSession",
		boughtVouchers:"Voucher",
		ownsVouchers:"Voucher",
		redeemedVouchers:"VoucherRedemption",
		doubleshotLegacyId:"String",
		meta:"CustomerMetadata",
		doubleshotLastUpdatedAt:"DateTime",
		addressesByMeta:"Address",
		addressesByGps:"Address",
		addressesByInvoices:"Address",
		addressesByBillingAddressOfOrders:"Address",
		addressesByDeliveryAddressOfOrderDeliveries:"Address",
		addressesByDefaultDeliveryAddressOfCustomer:"Address",
		creditsByVoucherRedemption:"CustomerCredit",
		ordersBySeq:"Order",
		ordersByDoubleshotLegacyId:"Order",
		ordersByDoubleShotLegacyNumber:"Order",
		ordersByDiscounts:"Order",
		ordersByDelivery:"Order",
		ordersByItems:"Order",
		ordersByCart:"Order",
		ordersByPriceLines:"Order",
		ordersByPayments:"Order",
		ordersBySummary:"Order",
		ingredientRatingsByIngredient:"CustomerIngredientRating",
		cartsByItems:"Cart",
		cartsByOrder:"Cart",
		cartsByPriceLines:"Cart",
		cartsBySummary:"Cart",
		invoicesByPublicKey:"Invoice",
		invoicesByFakturoidId:"Invoice",
		invoicesByOrderPayments:"Invoice",
		billingSubjectsByInvoices:"BillingSubject",
		billingSubjectsByDefaultBillingSubjectOfCustomer:"BillingSubject",
		billingSubjectsByOrders:"BillingSubject",
		offeredProductsByProduct:"ProductCustomerPrice",
		boughtVouchersByCode:"Voucher",
		boughtVouchersByRedemption:"Voucher",
		ownsVouchersByCode:"Voucher",
		ownsVouchersByRedemption:"Voucher",
		redeemedVouchersByCustomerCredit:"VoucherRedemption",
		redeemedVouchersByOrderDiscount:"VoucherRedemption",
		redeemedVouchersByVoucher:"VoucherRedemption",
		paginateAddresses:"AddressConnection",
		paginateTags:"TagConnection",
		paginateCredits:"CustomerCreditConnection",
		paginateOrders:"OrderConnection",
		paginateIngredientRatings:"CustomerIngredientRatingConnection",
		paginateOwnerOfBusinesses:"BusinessCustomerConnection",
		paginateMemberOfBusinesses:"BusinessCustomerConnection",
		paginateCarts:"CartConnection",
		paginateChannels:"ChannelConnection",
		paginateInvoices:"InvoiceConnection",
		paginateBillingSubjects:"BillingSubjectConnection",
		paginateOfferedProducts:"ProductCustomerPriceConnection",
		paginateBoughtVouchers:"VoucherConnection",
		paginateOwnsVouchers:"VoucherConnection",
		paginateRedeemedVouchers:"VoucherRedemptionConnection"
	},
	CustomerMeta:{
		id:"FieldMeta",
		addresses:"FieldMeta",
		defaultBillingAddress:"FieldMeta",
		tags:"FieldMeta",
		credits:"FieldMeta",
		orders:"FieldMeta",
		permissions:"FieldMeta",
		group:"FieldMeta",
		photo:"FieldMeta",
		phone:"FieldMeta",
		name:"FieldMeta",
		welcomeMessage:"FieldMeta",
		familiarity:"FieldMeta",
		ingredientRatings:"FieldMeta",
		business:"FieldMeta",
		ownerOfBusinesses:"FieldMeta",
		memberOfBusinesses:"FieldMeta",
		carts:"FieldMeta",
		user:"FieldMeta",
		channels:"FieldMeta",
		disabledAt:"FieldMeta",
		invoices:"FieldMeta",
		invoicingPlan:"FieldMeta",
		email:"FieldMeta",
		createdAt:"FieldMeta",
		billingSubjects:"FieldMeta",
		offeredProducts:"FieldMeta",
		defaultDeliveryAddress:"FieldMeta",
		defaultBillingSubject:"FieldMeta",
		prefersPackingsWithoutCardboard:"FieldMeta",
		anonymousSession:"FieldMeta",
		boughtVouchers:"FieldMeta",
		ownsVouchers:"FieldMeta",
		redeemedVouchers:"FieldMeta",
		doubleshotLegacyId:"FieldMeta",
		meta:"FieldMeta",
		doubleshotLastUpdatedAt:"FieldMeta"
	},
	Tag:{
		_meta:"TagMeta",
		id:"UUID",
		name:"String",
		code:"String",
		customers:"Customer",
		products:"Product",
		categories:"ProductCategory",
		description:"String",
		internalNote:"String",
		addTagsOnRegistration:"Channel",
		deliveryZones:"DeliveryZone",
		publicInChannels:"Channel",
		allowChannelPayments:"ChannelPayment",
		fulfillmentNotes:"FulfillmentNote",
		paginateCustomers:"CustomerConnection",
		paginateProducts:"ProductConnection",
		paginateCategories:"ProductCategoryConnection",
		paginateAddTagsOnRegistration:"ChannelConnection",
		paginateDeliveryZones:"DeliveryZoneConnection",
		paginatePublicInChannels:"ChannelConnection",
		paginateAllowChannelPayments:"ChannelPaymentConnection",
		paginateFulfillmentNotes:"FulfillmentNoteConnection"
	},
	TagMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		customers:"FieldMeta",
		products:"FieldMeta",
		categories:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		addTagsOnRegistration:"FieldMeta",
		deliveryZones:"FieldMeta",
		publicInChannels:"FieldMeta",
		allowChannelPayments:"FieldMeta",
		fulfillmentNotes:"FieldMeta"
	},
	Product:{
		_meta:"ProductMeta",
		id:"UUID",
		name:"String",
		code:"String",
		priceCents:"Int",
		notDiscountable:"Boolean",
		packing:"ProductPacking",
		recipe:"ProductRecipe",
		tags:"Tag",
		categories:"ProductCategory",
		vatRate:"VatRate",
		stocks:"ProductStock",
		groupPrices:"ProductGroupPrice",
		image:"Image",
		description:"String",
		internalNote:"String",
		cartItems:"CartItem",
		availability:"ProductAvailability",
		order:"Float",
		orderItems:"OrderItem",
		maximumQuantityPerOrder:"Int",
		deliveryZones:"DeliveryZone",
		locales:"ProductLocale",
		archivedAt:"DateTime",
		customerPrices:"ProductCustomerPrice",
		virtualProduct:"VirtualProduct",
		meta:"ProductMetadata",
		page:"Page",
		fulfillmentNotes:"FulfillmentNote",
		hasSubscription:"Boolean",
		pointOfSaleTileColor:"String",
		stocksByStore:"ProductStock",
		groupPricesByGroup:"ProductGroupPrice",
		cartItemsByCart:"CartItem",
		orderItemsByVirtualProductEffects:"OrderItem",
		localesByLocale:"ProductLocale",
		localesByFeatureList:"ProductLocale",
		customerPricesByCustomer:"ProductCustomerPrice",
		paginateTags:"TagConnection",
		paginateCategories:"ProductCategoryConnection",
		paginateStocks:"ProductStockConnection",
		paginateGroupPrices:"ProductGroupPriceConnection",
		paginateCartItems:"CartItemConnection",
		paginateOrderItems:"OrderItemConnection",
		paginateDeliveryZones:"DeliveryZoneConnection",
		paginateLocales:"ProductLocaleConnection",
		paginateCustomerPrices:"ProductCustomerPriceConnection",
		paginateFulfillmentNotes:"FulfillmentNoteConnection"
	},
	ProductMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		priceCents:"FieldMeta",
		notDiscountable:"FieldMeta",
		packing:"FieldMeta",
		recipe:"FieldMeta",
		tags:"FieldMeta",
		categories:"FieldMeta",
		vatRate:"FieldMeta",
		stocks:"FieldMeta",
		groupPrices:"FieldMeta",
		image:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		cartItems:"FieldMeta",
		availability:"FieldMeta",
		order:"FieldMeta",
		orderItems:"FieldMeta",
		maximumQuantityPerOrder:"FieldMeta",
		deliveryZones:"FieldMeta",
		locales:"FieldMeta",
		archivedAt:"FieldMeta",
		customerPrices:"FieldMeta",
		virtualProduct:"FieldMeta",
		meta:"FieldMeta",
		page:"FieldMeta",
		fulfillmentNotes:"FieldMeta",
		hasSubscription:"FieldMeta",
		pointOfSaleTileColor:"FieldMeta"
	},
	ProductPacking:{
		_meta:"ProductPackingMeta",
		id:"UUID",
		name:"String",
		image:"Image",
		description:"String",
		internalNote:"String",
		volumeMl:"Int",
		icon:"Image",
		shortName:"String",
		locales:"ProductPackingLocale",
		isPackedInCardboard:"Boolean",
		localesByLocale:"ProductPackingLocale",
		paginateLocales:"ProductPackingLocaleConnection"
	},
	ProductPackingMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		image:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		volumeMl:"FieldMeta",
		icon:"FieldMeta",
		shortName:"FieldMeta",
		locales:"FieldMeta",
		isPackedInCardboard:"FieldMeta"
	},
	Image:{
		_meta:"ImageMeta",
		id:"UUID",
		url:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		alt:"String"
	},
	ImageMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		alt:"FieldMeta"
	},
	ProductPackingLocale:{
		_meta:"ProductPackingLocaleMeta",
		id:"UUID",
		name:"String",
		shortName:"String",
		description:"String",
		root:"ProductPacking",
		locale:"Locale"
	},
	ProductPackingLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		shortName:"FieldMeta",
		description:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	Locale:{
		_meta:"LocaleMeta",
		id:"UUID",
		businessCategories:"BusinessCategoryLocale",
		productCategoryLists:"ProductCategoryListLocale",
		productIngredients:"ProductIngredientLocale",
		orderRecurrences:"OrderRecurrenceLocale",
		blogs:"BlogLocale",
		blogPosts:"BlogPostLocale",
		identifier:"TranslationCataloguesIdentifier",
		pages:"PageLocale",
		allergens:"AllergenLocale",
		productPackings:"ProductPackingLocale",
		products:"ProductLocale",
		productRecipes:"ProductRecipeLocale",
		deliveryMethods:"DeliveryMethodLocale",
		identificationCodes:"IdentificationCodeLocale",
		subscriptionBoxes:"SubscriptionBoxLocale",
		businessCategoriesByRoot:"BusinessCategoryLocale",
		productCategoryListsByRoot:"ProductCategoryListLocale",
		productIngredientsByRoot:"ProductIngredientLocale",
		orderRecurrencesByRoot:"OrderRecurrenceLocale",
		blogsByRoot:"BlogLocale",
		blogPostsByRoot:"BlogPostLocale",
		blogPostsByCover:"BlogPostLocale",
		blogPostsByContent:"BlogPostLocale",
		blogPostsByLink:"BlogPostLocale",
		blogPostsBySeo:"BlogPostLocale",
		pagesByRoot:"PageLocale",
		pagesByLink:"PageLocale",
		pagesBySeo:"PageLocale",
		pagesByContent:"PageLocale",
		pagesByCover:"PageLocale",
		allergensByRoot:"AllergenLocale",
		productPackingsByRoot:"ProductPackingLocale",
		productsByRoot:"ProductLocale",
		productsByFeatureList:"ProductLocale",
		productRecipesByRoot:"ProductRecipeLocale",
		deliveryMethodsByRoot:"DeliveryMethodLocale",
		identificationCodesByRoot:"IdentificationCodeLocale",
		subscriptionBoxesByRoot:"SubscriptionBoxLocale",
		subscriptionBoxesByTexts:"SubscriptionBoxLocale",
		subscriptionBoxesBySecondaryTexts:"SubscriptionBoxLocale",
		paginateBusinessCategories:"BusinessCategoryLocaleConnection",
		paginateProductCategoryLists:"ProductCategoryListLocaleConnection",
		paginateProductIngredients:"ProductIngredientLocaleConnection",
		paginateOrderRecurrences:"OrderRecurrenceLocaleConnection",
		paginateBlogs:"BlogLocaleConnection",
		paginateBlogPosts:"BlogPostLocaleConnection",
		paginatePages:"PageLocaleConnection",
		paginateAllergens:"AllergenLocaleConnection",
		paginateProductPackings:"ProductPackingLocaleConnection",
		paginateProducts:"ProductLocaleConnection",
		paginateProductRecipes:"ProductRecipeLocaleConnection",
		paginateDeliveryMethods:"DeliveryMethodLocaleConnection",
		paginateIdentificationCodes:"IdentificationCodeLocaleConnection",
		paginateSubscriptionBoxes:"SubscriptionBoxLocaleConnection"
	},
	LocaleMeta:{
		id:"FieldMeta",
		businessCategories:"FieldMeta",
		productCategoryLists:"FieldMeta",
		productIngredients:"FieldMeta",
		orderRecurrences:"FieldMeta",
		blogs:"FieldMeta",
		blogPosts:"FieldMeta",
		identifier:"FieldMeta",
		pages:"FieldMeta",
		allergens:"FieldMeta",
		productPackings:"FieldMeta",
		products:"FieldMeta",
		productRecipes:"FieldMeta",
		deliveryMethods:"FieldMeta",
		identificationCodes:"FieldMeta",
		subscriptionBoxes:"FieldMeta"
	},
	BusinessCategoryLocale:{
		_meta:"BusinessCategoryLocaleMeta",
		id:"UUID",
		name:"String",
		root:"BusinessCategory",
		locale:"Locale",
		title:"String"
	},
	BusinessCategoryLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		title:"FieldMeta"
	},
	BusinessCategory:{
		_meta:"BusinessCategoryMeta",
		id:"UUID",
		order:"Int",
		visibleToNewCustomers:"Boolean",
		locales:"BusinessCategoryLocale",
		internalName:"String",
		businessCustomers:"BusinessCustomer",
		recurrences:"OrderRecurrence",
		specificProducts:"ProductList",
		seasonalProductCategories:"ProductCategoryList",
		specificProductsProductCategories:"ProductCategoryList",
		slug:"String",
		localesByLocale:"BusinessCategoryLocale",
		businessCustomersByCustomer:"BusinessCustomer",
		paginateLocales:"BusinessCategoryLocaleConnection",
		paginateBusinessCustomers:"BusinessCustomerConnection",
		paginateRecurrences:"OrderRecurrenceConnection"
	},
	BusinessCategoryMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		visibleToNewCustomers:"FieldMeta",
		locales:"FieldMeta",
		internalName:"FieldMeta",
		businessCustomers:"FieldMeta",
		recurrences:"FieldMeta",
		specificProducts:"FieldMeta",
		seasonalProductCategories:"FieldMeta",
		specificProductsProductCategories:"FieldMeta",
		slug:"FieldMeta"
	},
	BusinessCustomer:{
		_meta:"BusinessCustomerMeta",
		id:"UUID",
		name:"String",
		customer:"Customer",
		owners:"Customer",
		members:"Customer",
		category:"BusinessCategory",
		logo:"Image",
		note:"String",
		paginateOwners:"CustomerConnection",
		paginateMembers:"CustomerConnection"
	},
	BusinessCustomerMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		customer:"FieldMeta",
		owners:"FieldMeta",
		members:"FieldMeta",
		category:"FieldMeta",
		logo:"FieldMeta",
		note:"FieldMeta"
	},
	CustomerConnection:{
		pageInfo:"PageInfo",
		edges:"CustomerEdge"
	},
	PageInfo:{
		totalCount:"Int"
	},
	CustomerEdge:{
		node:"Customer"
	},
	OrderRecurrence:{
		_meta:"OrderRecurrenceMeta",
		id:"UUID",
		internalName:"String",
		locales:"OrderRecurrenceLocale",
		order:"Int",
		businessCategory:"BusinessCategory",
		localesByLocale:"OrderRecurrenceLocale",
		paginateLocales:"OrderRecurrenceLocaleConnection",
		paginateBusinessCategory:"BusinessCategoryConnection"
	},
	OrderRecurrenceMeta:{
		id:"FieldMeta",
		internalName:"FieldMeta",
		locales:"FieldMeta",
		order:"FieldMeta",
		businessCategory:"FieldMeta"
	},
	OrderRecurrenceLocale:{
		_meta:"OrderRecurrenceLocaleMeta",
		id:"UUID",
		name:"String",
		root:"OrderRecurrence",
		locale:"Locale"
	},
	OrderRecurrenceLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	OrderRecurrenceLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"OrderRecurrenceLocaleEdge"
	},
	OrderRecurrenceLocaleEdge:{
		node:"OrderRecurrenceLocale"
	},
	BusinessCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessCategoryEdge"
	},
	BusinessCategoryEdge:{
		node:"BusinessCategory"
	},
	ProductList:{
		_meta:"ProductListMeta",
		id:"UUID",
		items:"ProductListItem",
		businessCategory:"BusinessCategory",
		paginateItems:"ProductListItemConnection"
	},
	ProductListMeta:{
		id:"FieldMeta",
		items:"FieldMeta",
		businessCategory:"FieldMeta"
	},
	ProductListItem:{
		_meta:"ProductListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ProductList",
		product:"Product",
		defaultQuantityForOrder:"Int"
	},
	ProductListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		product:"FieldMeta",
		defaultQuantityForOrder:"FieldMeta"
	},
	ProductListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductListItemEdge"
	},
	ProductListItemEdge:{
		node:"ProductListItem"
	},
	ProductCategoryList:{
		_meta:"ProductCategoryListMeta",
		id:"UUID",
		items:"ProductCategoryListItem",
		locales:"ProductCategoryListLocale",
		seasonalProductCategoriesOfBusinessCategory:"BusinessCategory",
		specificProductsProductCategoriesOfBusinessCategory:"BusinessCategory",
		localesByLocale:"ProductCategoryListLocale",
		paginateItems:"ProductCategoryListItemConnection",
		paginateLocales:"ProductCategoryListLocaleConnection"
	},
	ProductCategoryListMeta:{
		id:"FieldMeta",
		items:"FieldMeta",
		locales:"FieldMeta",
		seasonalProductCategoriesOfBusinessCategory:"FieldMeta",
		specificProductsProductCategoriesOfBusinessCategory:"FieldMeta"
	},
	ProductCategoryListItem:{
		_meta:"ProductCategoryListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ProductCategoryList",
		productCategory:"ProductCategory",
		showRecipeImages:"Boolean",
		showPackingImages:"Boolean",
		showAllAvailableProducts:"Boolean"
	},
	ProductCategoryListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		productCategory:"FieldMeta",
		showRecipeImages:"FieldMeta",
		showPackingImages:"FieldMeta",
		showAllAvailableProducts:"FieldMeta"
	},
	ProductCategory:{
		_meta:"ProductCategoryMeta",
		id:"UUID",
		name:"String",
		code:"String",
		products:"Product",
		tags:"Tag",
		parent:"ProductCategory",
		children:"ProductCategory",
		description:"String",
		internalNote:"String",
		color:"String",
		image:"Image",
		order:"Float",
		childrenByCode:"ProductCategory",
		childrenByChildren:"ProductCategory",
		paginateProducts:"ProductConnection",
		paginateTags:"TagConnection",
		paginateChildren:"ProductCategoryConnection"
	},
	ProductCategoryMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		products:"FieldMeta",
		tags:"FieldMeta",
		parent:"FieldMeta",
		children:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		color:"FieldMeta",
		image:"FieldMeta",
		order:"FieldMeta"
	},
	ProductConnection:{
		pageInfo:"PageInfo",
		edges:"ProductEdge"
	},
	ProductEdge:{
		node:"Product"
	},
	TagConnection:{
		pageInfo:"PageInfo",
		edges:"TagEdge"
	},
	TagEdge:{
		node:"Tag"
	},
	ProductCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryEdge"
	},
	ProductCategoryEdge:{
		node:"ProductCategory"
	},
	ProductCategoryListLocale:{
		_meta:"ProductCategoryListLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductCategoryList",
		locale:"Locale"
	},
	ProductCategoryListLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	ProductCategoryListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryListItemEdge"
	},
	ProductCategoryListItemEdge:{
		node:"ProductCategoryListItem"
	},
	ProductCategoryListLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryListLocaleEdge"
	},
	ProductCategoryListLocaleEdge:{
		node:"ProductCategoryListLocale"
	},
	BusinessCategoryLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessCategoryLocaleEdge"
	},
	BusinessCategoryLocaleEdge:{
		node:"BusinessCategoryLocale"
	},
	BusinessCustomerConnection:{
		pageInfo:"PageInfo",
		edges:"BusinessCustomerEdge"
	},
	BusinessCustomerEdge:{
		node:"BusinessCustomer"
	},
	OrderRecurrenceConnection:{
		pageInfo:"PageInfo",
		edges:"OrderRecurrenceEdge"
	},
	OrderRecurrenceEdge:{
		node:"OrderRecurrence"
	},
	ProductIngredientLocale:{
		_meta:"ProductIngredientLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductIngredient",
		locale:"Locale"
	},
	ProductIngredientLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	ProductIngredient:{
		_meta:"ProductIngredientMeta",
		id:"UUID",
		internalNote:"String",
		locales:"ProductIngredientLocale",
		internalName:"String",
		allergens:"Allergen",
		needsPrepping:"Boolean",
		allowCustomerRating:"Boolean",
		customerRatings:"CustomerIngredientRating",
		freshingContainer:"FreshingContainer",
		image:"Image",
		incrementQuantityNumber:"Float",
		localesByLocale:"ProductIngredientLocale",
		customerRatingsByCustomer:"CustomerIngredientRating",
		paginateLocales:"ProductIngredientLocaleConnection",
		paginateAllergens:"AllergenConnection",
		paginateCustomerRatings:"CustomerIngredientRatingConnection"
	},
	ProductIngredientMeta:{
		id:"FieldMeta",
		internalNote:"FieldMeta",
		locales:"FieldMeta",
		internalName:"FieldMeta",
		allergens:"FieldMeta",
		needsPrepping:"FieldMeta",
		allowCustomerRating:"FieldMeta",
		customerRatings:"FieldMeta",
		freshingContainer:"FieldMeta",
		image:"FieldMeta",
		incrementQuantityNumber:"FieldMeta"
	},
	Allergen:{
		_meta:"AllergenMeta",
		id:"UUID",
		internalName:"String",
		locales:"AllergenLocale",
		ingredients:"ProductIngredient",
		code:"Int",
		localesByLocale:"AllergenLocale",
		paginateLocales:"AllergenLocaleConnection",
		paginateIngredients:"ProductIngredientConnection"
	},
	AllergenMeta:{
		id:"FieldMeta",
		internalName:"FieldMeta",
		locales:"FieldMeta",
		ingredients:"FieldMeta",
		code:"FieldMeta"
	},
	AllergenLocale:{
		_meta:"AllergenLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Allergen",
		locale:"Locale"
	},
	AllergenLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	AllergenLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"AllergenLocaleEdge"
	},
	AllergenLocaleEdge:{
		node:"AllergenLocale"
	},
	ProductIngredientConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientEdge"
	},
	ProductIngredientEdge:{
		node:"ProductIngredient"
	},
	CustomerIngredientRating:{
		_meta:"CustomerIngredientRatingMeta",
		id:"UUID",
		customer:"Customer",
		rating:"ProductIngredientRating",
		ingredient:"ProductIngredient"
	},
	CustomerIngredientRatingMeta:{
		id:"FieldMeta",
		customer:"FieldMeta",
		rating:"FieldMeta",
		ingredient:"FieldMeta"
	},
	ProductIngredientRating:{
		_meta:"ProductIngredientRatingMeta",
		id:"UUID",
		order:"Int",
		name:"String",
		icon:"Image"
	},
	ProductIngredientRatingMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		name:"FieldMeta",
		icon:"FieldMeta"
	},
	FreshingContainer:{
		_meta:"FreshingContainerMeta",
		id:"UUID",
		order:"Int",
		volumeMl:"Int",
		workspace:"Workspace"
	},
	FreshingContainerMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		volumeMl:"FieldMeta",
		workspace:"FieldMeta"
	},
	Workspace:{
		_meta:"WorkspaceMeta",
		id:"UUID",
		unique:"One",
		orderDeadlineTime:"String",
		ordersLoadingDurationHours:"Int",
		freshingContainerVolumeMl:"Int",
		freshingPrepareOrderByOffsetMinutes:"Int",
		checkoutUrlTemplate:"String",
		orderUrlTemplate:"String",
		paymentUrlTemplate:"String",
		customerProfileUrlTemplate:"String",
		userProfileUrlTemplate:"String",
		messengerUrl:"String",
		messengerIosUrl:"String",
		messengerAndroidUrl:"String",
		freshingContainers:"FreshingContainer",
		openingHours:"WeekHours",
		workingHours:"WeekHours",
		defaultFreshingContainer:"FreshingContainer",
		creditsProduct:"VirtualProduct",
		discountVatRate:"VatRate",
		name:"String",
		businessAddress:"String",
		paginateFreshingContainers:"FreshingContainerConnection"
	},
	WorkspaceMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		orderDeadlineTime:"FieldMeta",
		ordersLoadingDurationHours:"FieldMeta",
		freshingContainerVolumeMl:"FieldMeta",
		freshingPrepareOrderByOffsetMinutes:"FieldMeta",
		checkoutUrlTemplate:"FieldMeta",
		orderUrlTemplate:"FieldMeta",
		paymentUrlTemplate:"FieldMeta",
		customerProfileUrlTemplate:"FieldMeta",
		userProfileUrlTemplate:"FieldMeta",
		messengerUrl:"FieldMeta",
		messengerIosUrl:"FieldMeta",
		messengerAndroidUrl:"FieldMeta",
		freshingContainers:"FieldMeta",
		openingHours:"FieldMeta",
		workingHours:"FieldMeta",
		defaultFreshingContainer:"FieldMeta",
		creditsProduct:"FieldMeta",
		discountVatRate:"FieldMeta",
		name:"FieldMeta",
		businessAddress:"FieldMeta"
	},
	WeekHours:{
		_meta:"WeekHoursMeta",
		id:"UUID",
		mon:"String",
		tue:"String",
		wed:"String",
		thu:"String",
		fri:"String",
		sat:"String",
		sun:"String"
	},
	WeekHoursMeta:{
		id:"FieldMeta",
		mon:"FieldMeta",
		tue:"FieldMeta",
		wed:"FieldMeta",
		thu:"FieldMeta",
		fri:"FieldMeta",
		sat:"FieldMeta",
		sun:"FieldMeta"
	},
	VirtualProduct:{
		_meta:"VirtualProductMeta",
		id:"UUID",
		type:"VirtualProductType",
		product:"Product",
		voucherCreditCents:"Int",
		physicalRepresentation:"VirtualProductPhysicalRepresentationType",
		recipientEmail:"Boolean"
	},
	VirtualProductMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		product:"FieldMeta",
		voucherCreditCents:"FieldMeta",
		physicalRepresentation:"FieldMeta",
		recipientEmail:"FieldMeta"
	},
	VatRate:{
		_meta:"VatRateMeta",
		id:"UUID",
		name:"String",
		ratePermille:"Int",
		description:"String",
		internalNote:"String",
		channelDeliveries:"ChannelDelivery",
		channelPayments:"ChannelPayment",
		preset:"VatRatePreset",
		paginateChannelDeliveries:"ChannelDeliveryConnection",
		paginateChannelPayments:"ChannelPaymentConnection"
	},
	VatRateMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		ratePermille:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		channelDeliveries:"FieldMeta",
		channelPayments:"FieldMeta",
		preset:"FieldMeta"
	},
	ChannelDelivery:{
		_meta:"ChannelDeliveryMeta",
		id:"UUID",
		order:"Int",
		priceCents:"Int",
		channel:"Channel",
		method:"DeliveryMethod",
		vatRate:"VatRate",
		defaultVisible:"Boolean"
	},
	ChannelDeliveryMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		priceCents:"FieldMeta",
		channel:"FieldMeta",
		method:"FieldMeta",
		vatRate:"FieldMeta",
		defaultVisible:"FieldMeta"
	},
	Channel:{
		_meta:"ChannelMeta",
		id:"UUID",
		name:"String",
		disabled:"Boolean",
		payments:"ChannelPayment",
		deliveries:"ChannelDelivery",
		carts:"Cart",
		customers:"Customer",
		code:"ChannelCode",
		addTagsOnRegistration:"Tag",
		publicTags:"Tag",
		cartsByItems:"Cart",
		cartsByOrder:"Cart",
		cartsByPriceLines:"Cart",
		cartsBySummary:"Cart",
		paginatePayments:"ChannelPaymentConnection",
		paginateDeliveries:"ChannelDeliveryConnection",
		paginateCarts:"CartConnection",
		paginateCustomers:"CustomerConnection",
		paginateAddTagsOnRegistration:"TagConnection",
		paginatePublicTags:"TagConnection"
	},
	ChannelMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		disabled:"FieldMeta",
		payments:"FieldMeta",
		deliveries:"FieldMeta",
		carts:"FieldMeta",
		customers:"FieldMeta",
		code:"FieldMeta",
		addTagsOnRegistration:"FieldMeta",
		publicTags:"FieldMeta"
	},
	ChannelPayment:{
		_meta:"ChannelPaymentMeta",
		id:"UUID",
		order:"Int",
		channel:"Channel",
		method:"PaymentMethod",
		vatRate:"VatRate",
		requireBillingAddress:"Boolean",
		provideReceipt:"Boolean",
		requireBillingSubject:"Boolean",
		allowOnlyForTags:"Tag",
		paginateAllowOnlyForTags:"TagConnection"
	},
	ChannelPaymentMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		channel:"FieldMeta",
		method:"FieldMeta",
		vatRate:"FieldMeta",
		requireBillingAddress:"FieldMeta",
		provideReceipt:"FieldMeta",
		requireBillingSubject:"FieldMeta",
		allowOnlyForTags:"FieldMeta"
	},
	PaymentMethod:{
		_meta:"PaymentMethodMeta",
		id:"UUID",
		name:"String",
		code:"String",
		enabled:"Boolean",
		deliveryMethods:"DeliveryMethod",
		description:"String",
		internalNote:"String",
		blockingDispatch:"Boolean",
		type:"PaymentMethodType",
		channels:"ChannelPayment",
		reduceGratuityByPercentage:"Int",
		paginateDeliveryMethods:"DeliveryMethodConnection",
		paginateChannels:"ChannelPaymentConnection"
	},
	PaymentMethodMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		enabled:"FieldMeta",
		deliveryMethods:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		blockingDispatch:"FieldMeta",
		type:"FieldMeta",
		channels:"FieldMeta",
		reduceGratuityByPercentage:"FieldMeta"
	},
	DeliveryMethod:{
		_meta:"DeliveryMethodMeta",
		id:"UUID",
		name:"String",
		code:"String",
		enabled:"Boolean",
		paymentMethods:"PaymentMethod",
		description:"String",
		internalNote:"String",
		requireDeliveryAddress:"Boolean",
		suitableForDeliveryZones:"DeliveryZone",
		channels:"ChannelDelivery",
		enableDeliveryTracking:"Boolean",
		locales:"DeliveryMethodLocale",
		timelinePreset:"DeliveryTimelinePreset",
		localesByLocale:"DeliveryMethodLocale",
		paginatePaymentMethods:"PaymentMethodConnection",
		paginateSuitableForDeliveryZones:"DeliveryZoneConnection",
		paginateChannels:"ChannelDeliveryConnection",
		paginateLocales:"DeliveryMethodLocaleConnection"
	},
	DeliveryMethodMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		enabled:"FieldMeta",
		paymentMethods:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		requireDeliveryAddress:"FieldMeta",
		suitableForDeliveryZones:"FieldMeta",
		channels:"FieldMeta",
		enableDeliveryTracking:"FieldMeta",
		locales:"FieldMeta",
		timelinePreset:"FieldMeta"
	},
	DeliveryZone:{
		_meta:"DeliveryZoneMeta",
		id:"UUID",
		name:"String",
		code:"String",
		description:"String",
		internalNote:"String",
		type:"DeliveryZoneType",
		addresses:"Address",
		suitableDeliveryMethods:"DeliveryMethod",
		deliveryZonesOfTags:"Tag",
		deliveryZonesOfProducts:"Product",
		addressesMetadata:"AddressMetadata",
		addressesByMeta:"Address",
		addressesByGps:"Address",
		addressesByInvoices:"Address",
		addressesByBillingAddressOfOrders:"Address",
		addressesByDeliveryAddressOfOrderDeliveries:"Address",
		addressesByDefaultDeliveryAddressOfCustomer:"Address",
		addressesMetadataByAddress:"AddressMetadata",
		paginateAddresses:"AddressConnection",
		paginateSuitableDeliveryMethods:"DeliveryMethodConnection",
		paginateDeliveryZonesOfTags:"TagConnection",
		paginateDeliveryZonesOfProducts:"ProductConnection",
		paginateAddressesMetadata:"AddressMetadataConnection"
	},
	DeliveryZoneMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta",
		type:"FieldMeta",
		addresses:"FieldMeta",
		suitableDeliveryMethods:"FieldMeta",
		deliveryZonesOfTags:"FieldMeta",
		deliveryZonesOfProducts:"FieldMeta",
		addressesMetadata:"FieldMeta"
	},
	AddressMetadata:{
		_meta:"AddressMetadataMeta",
		id:"UUID",
		deliveryZoneType:"DeliveryZoneType",
		address:"Address",
		deliveryZone:"DeliveryZone"
	},
	AddressMetadataMeta:{
		id:"FieldMeta",
		deliveryZoneType:"FieldMeta",
		address:"FieldMeta",
		deliveryZone:"FieldMeta"
	},
	AddressConnection:{
		pageInfo:"PageInfo",
		edges:"AddressEdge"
	},
	AddressEdge:{
		node:"Address"
	},
	DeliveryMethodConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryMethodEdge"
	},
	DeliveryMethodEdge:{
		node:"DeliveryMethod"
	},
	AddressMetadataConnection:{
		pageInfo:"PageInfo",
		edges:"AddressMetadataEdge"
	},
	AddressMetadataEdge:{
		node:"AddressMetadata"
	},
	DeliveryMethodLocale:{
		_meta:"DeliveryMethodLocaleMeta",
		id:"UUID",
		name:"String",
		description:"String",
		trackingCodeUrlTemplate:"String",
		processingTitle:"String",
		processingText:"String",
		processingIconUrl:"String",
		dispatchedTitle:"String",
		dispatchedText:"String",
		dispatchedIconUrl:"String",
		fulfilledTitle:"String",
		fulfilledText:"String",
		fulfilledIconUrl:"String",
		locale:"Locale",
		root:"DeliveryMethod"
	},
	DeliveryMethodLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		description:"FieldMeta",
		trackingCodeUrlTemplate:"FieldMeta",
		processingTitle:"FieldMeta",
		processingText:"FieldMeta",
		processingIconUrl:"FieldMeta",
		dispatchedTitle:"FieldMeta",
		dispatchedText:"FieldMeta",
		dispatchedIconUrl:"FieldMeta",
		fulfilledTitle:"FieldMeta",
		fulfilledText:"FieldMeta",
		fulfilledIconUrl:"FieldMeta",
		locale:"FieldMeta",
		root:"FieldMeta"
	},
	DeliveryTimelinePreset:{
		_meta:"DeliveryTimelinePresetMeta",
		id:"UUID",
		type:"DeliveryTimelineType",
		duration:"String",
		window:"String",
		deliveryMethod:"DeliveryMethod"
	},
	DeliveryTimelinePresetMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		duration:"FieldMeta",
		window:"FieldMeta",
		deliveryMethod:"FieldMeta"
	},
	PaymentMethodConnection:{
		pageInfo:"PageInfo",
		edges:"PaymentMethodEdge"
	},
	PaymentMethodEdge:{
		node:"PaymentMethod"
	},
	DeliveryZoneConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryZoneEdge"
	},
	DeliveryZoneEdge:{
		node:"DeliveryZone"
	},
	ChannelDeliveryConnection:{
		pageInfo:"PageInfo",
		edges:"ChannelDeliveryEdge"
	},
	ChannelDeliveryEdge:{
		node:"ChannelDelivery"
	},
	DeliveryMethodLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryMethodLocaleEdge"
	},
	DeliveryMethodLocaleEdge:{
		node:"DeliveryMethodLocale"
	},
	ChannelPaymentConnection:{
		pageInfo:"PageInfo",
		edges:"ChannelPaymentEdge"
	},
	ChannelPaymentEdge:{
		node:"ChannelPayment"
	},
	Cart:{
		_meta:"CartMeta",
		id:"UUID",
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		note:"String",
		items:"CartItem",
		handle:"String",
		state:"CartState",
		createdAt:"DateTime",
		channel:"Channel",
		order:"Order",
		customer:"Customer",
		deliveryAddress:"Address",
		billingAddress:"Address",
		deliveryOption:"String",
		paymentOption:"String",
		delivery:"ChannelDelivery",
		payment:"ChannelPayment",
		billingSubject:"BillingSubject",
		priceLines:"CartPriceLine",
		summary:"CartSummary",
		itemsByProduct:"CartItem",
		paginateItems:"CartItemConnection",
		paginatePriceLines:"CartPriceLineConnection"
	},
	CartMeta:{
		id:"FieldMeta",
		confirmedAt:"FieldMeta",
		deletedAt:"FieldMeta",
		note:"FieldMeta",
		items:"FieldMeta",
		handle:"FieldMeta",
		state:"FieldMeta",
		createdAt:"FieldMeta",
		channel:"FieldMeta",
		order:"FieldMeta",
		customer:"FieldMeta",
		deliveryAddress:"FieldMeta",
		billingAddress:"FieldMeta",
		deliveryOption:"FieldMeta",
		paymentOption:"FieldMeta",
		delivery:"FieldMeta",
		payment:"FieldMeta",
		billingSubject:"FieldMeta",
		priceLines:"FieldMeta",
		summary:"FieldMeta"
	},
	CartItem:{
		_meta:"CartItemMeta",
		id:"UUID",
		quantity:"Int",
		cart:"Cart",
		product:"Product",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		fulfillmentNote:"String",
		fulfillmentNotes:"FulfillmentNote",
		paginateFulfillmentNotes:"FulfillmentNoteConnection"
	},
	CartItemMeta:{
		id:"FieldMeta",
		quantity:"FieldMeta",
		cart:"FieldMeta",
		product:"FieldMeta",
		createdAt:"FieldMeta",
		updatedAt:"FieldMeta",
		fulfillmentNote:"FieldMeta",
		fulfillmentNotes:"FieldMeta"
	},
	FulfillmentNote:{
		_meta:"FulfillmentNoteMeta",
		id:"UUID",
		note:"String",
		products:"Product",
		tags:"Tag",
		cartItems:"CartItem",
		orderItems:"OrderItem",
		orders:"Order",
		highlight:"Boolean",
		createdAt:"DateTime",
		emoji:"String",
		paginateProducts:"ProductConnection",
		paginateTags:"TagConnection",
		paginateCartItems:"CartItemConnection",
		paginateOrderItems:"OrderItemConnection",
		paginateOrders:"OrderConnection"
	},
	FulfillmentNoteMeta:{
		id:"FieldMeta",
		note:"FieldMeta",
		products:"FieldMeta",
		tags:"FieldMeta",
		cartItems:"FieldMeta",
		orderItems:"FieldMeta",
		orders:"FieldMeta",
		highlight:"FieldMeta",
		createdAt:"FieldMeta",
		emoji:"FieldMeta"
	},
	OrderItem:{
		_meta:"OrderItemMeta",
		id:"UUID",
		unitPriceCents:"Int",
		quantity:"Int",
		vatRate:"VatRate",
		order:"Order",
		virtualProductEffects:"VirtualProductEffect",
		pickedAt:"DateTime",
		note:"String",
		fulfillmentNotes:"FulfillmentNote",
		product:"Product",
		virtualProductEffectsByResultVouchers:"VirtualProductEffect",
		paginateVirtualProductEffects:"VirtualProductEffectConnection",
		paginateFulfillmentNotes:"FulfillmentNoteConnection"
	},
	OrderItemMeta:{
		id:"FieldMeta",
		unitPriceCents:"FieldMeta",
		quantity:"FieldMeta",
		vatRate:"FieldMeta",
		order:"FieldMeta",
		virtualProductEffects:"FieldMeta",
		pickedAt:"FieldMeta",
		note:"FieldMeta",
		fulfillmentNotes:"FieldMeta",
		product:"FieldMeta"
	},
	Order:{
		_meta:"OrderMeta",
		id:"UUID",
		state:"OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		note:"String",
		customer:"Customer",
		channel:"Channel",
		discounts:"OrderDiscount",
		delivery:"OrderDelivery",
		items:"OrderItem",
		cart:"Cart",
		createdAt:"DateTime",
		billingAddress:"Address",
		priceLines:"OrderPriceLine",
		payments:"OrderPayment",
		seq:"Int",
		billingSubject:"BillingSubject",
		summary:"OrderSummary",
		doubleshotLegacyId:"String",
		fulfillmentNote:"String",
		fulfillmentNotes:"FulfillmentNote",
		doubleshotTotalWeightGrams:"Int",
		doubleShotLegacyNumber:"String",
		doubleshotLastUpdatedAt:"DateTime",
		doubleshotAdminUrl:"String",
		discountsByVoucherRedemption:"OrderDiscount",
		itemsByVirtualProductEffects:"OrderItem",
		priceLinesByOrderItem:"OrderPriceLine",
		priceLinesByOrderDiscount:"OrderPriceLine",
		priceLinesByOrderPayment:"OrderPriceLine",
		priceLinesByOrderDelivery:"OrderPriceLine",
		paymentsByMeta:"OrderPayment",
		paginateDiscounts:"OrderDiscountConnection",
		paginateItems:"OrderItemConnection",
		paginatePriceLines:"OrderPriceLineConnection",
		paginatePayments:"OrderPaymentConnection",
		paginateFulfillmentNotes:"FulfillmentNoteConnection"
	},
	OrderMeta:{
		id:"FieldMeta",
		state:"FieldMeta",
		fulfilledAt:"FieldMeta",
		canceledAt:"FieldMeta",
		note:"FieldMeta",
		customer:"FieldMeta",
		channel:"FieldMeta",
		discounts:"FieldMeta",
		delivery:"FieldMeta",
		items:"FieldMeta",
		cart:"FieldMeta",
		createdAt:"FieldMeta",
		billingAddress:"FieldMeta",
		priceLines:"FieldMeta",
		payments:"FieldMeta",
		seq:"FieldMeta",
		billingSubject:"FieldMeta",
		summary:"FieldMeta",
		doubleshotLegacyId:"FieldMeta",
		fulfillmentNote:"FieldMeta",
		fulfillmentNotes:"FieldMeta",
		doubleshotTotalWeightGrams:"FieldMeta",
		doubleShotLegacyNumber:"FieldMeta",
		doubleshotLastUpdatedAt:"FieldMeta",
		doubleshotAdminUrl:"FieldMeta"
	},
	OrderDiscount:{
		_meta:"OrderDiscountMeta",
		id:"UUID",
		discountCents:"Int",
		vatRate:"VatRate",
		order:"Order",
		description:"String",
		type:"OrderDiscountType",
		voucherRedemption:"VoucherRedemption"
	},
	OrderDiscountMeta:{
		id:"FieldMeta",
		discountCents:"FieldMeta",
		vatRate:"FieldMeta",
		order:"FieldMeta",
		description:"FieldMeta",
		type:"FieldMeta",
		voucherRedemption:"FieldMeta"
	},
	VoucherRedemption:{
		_meta:"VoucherRedemptionMeta",
		id:"UUID",
		createdAt:"DateTime",
		note:"String",
		manualRedemptionNote:"String",
		customerCredit:"CustomerCredit",
		orderDiscount:"OrderDiscount",
		voucher:"Voucher",
		customer:"Customer"
	},
	VoucherRedemptionMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		note:"FieldMeta",
		manualRedemptionNote:"FieldMeta",
		customerCredit:"FieldMeta",
		orderDiscount:"FieldMeta",
		voucher:"FieldMeta",
		customer:"FieldMeta"
	},
	CustomerCredit:{
		_meta:"CustomerCreditMeta",
		id:"UUID",
		initialCreditCents:"Int",
		remainingCreditsCents:"Int",
		expiresAt:"DateTime",
		reason:"String",
		customer:"Customer",
		vatRate:"VatRate",
		createdAt:"DateTime",
		voucherRedemption:"VoucherRedemption",
		invoiceUrl:"String"
	},
	CustomerCreditMeta:{
		id:"FieldMeta",
		initialCreditCents:"FieldMeta",
		remainingCreditsCents:"FieldMeta",
		expiresAt:"FieldMeta",
		reason:"FieldMeta",
		customer:"FieldMeta",
		vatRate:"FieldMeta",
		createdAt:"FieldMeta",
		voucherRedemption:"FieldMeta",
		invoiceUrl:"FieldMeta"
	},
	Voucher:{
		_meta:"VoucherMeta",
		id:"UUID",
		createdAt:"DateTime",
		activatedAt:"DateTime",
		expiresAt:"DateTime",
		note:"String",
		code:"String",
		type:"VoucherType",
		creditCents:"Int",
		buyer:"Customer",
		owner:"Customer",
		vatRate:"VatRate",
		virtualProductEffect:"VirtualProductEffect",
		redemption:"VoucherRedemption"
	},
	VoucherMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		activatedAt:"FieldMeta",
		expiresAt:"FieldMeta",
		note:"FieldMeta",
		code:"FieldMeta",
		type:"FieldMeta",
		creditCents:"FieldMeta",
		buyer:"FieldMeta",
		owner:"FieldMeta",
		vatRate:"FieldMeta",
		virtualProductEffect:"FieldMeta",
		redemption:"FieldMeta"
	},
	VirtualProductEffect:{
		_meta:"VirtualProductEffectMeta",
		id:"UUID",
		createdAt:"DateTime",
		succeededAt:"DateTime",
		orderItem:"OrderItem",
		failedAt:"DateTime",
		revertedAt:"DateTime",
		resultVouchers:"Voucher",
		resultVouchersByCode:"Voucher",
		resultVouchersByRedemption:"Voucher",
		paginateResultVouchers:"VoucherConnection"
	},
	VirtualProductEffectMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		succeededAt:"FieldMeta",
		orderItem:"FieldMeta",
		failedAt:"FieldMeta",
		revertedAt:"FieldMeta",
		resultVouchers:"FieldMeta"
	},
	VoucherConnection:{
		pageInfo:"PageInfo",
		edges:"VoucherEdge"
	},
	VoucherEdge:{
		node:"Voucher"
	},
	OrderDelivery:{
		_meta:"OrderDeliveryMeta",
		id:"UUID",
		priceCents:"Int",
		deliveredAt:"DateTime",
		method:"DeliveryMethod",
		vatRate:"VatRate",
		order:"Order",
		expectedAt:"DateTime",
		state:"OrderDeliveryState",
		loadingDurationHours:"Int",
		dispatchAt:"DateTime",
		duration:"String",
		window:"String",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		internalNote:"String",
		prepareAt:"DateTime",
		pickedAt:"DateTime",
		address:"Address",
		trackingCode:"String"
	},
	OrderDeliveryMeta:{
		id:"FieldMeta",
		priceCents:"FieldMeta",
		deliveredAt:"FieldMeta",
		method:"FieldMeta",
		vatRate:"FieldMeta",
		order:"FieldMeta",
		expectedAt:"FieldMeta",
		state:"FieldMeta",
		loadingDurationHours:"FieldMeta",
		dispatchAt:"FieldMeta",
		duration:"FieldMeta",
		window:"FieldMeta",
		expectedEndAt:"FieldMeta",
		processingAt:"FieldMeta",
		deliveringAt:"FieldMeta",
		failedAt:"FieldMeta",
		internalNote:"FieldMeta",
		prepareAt:"FieldMeta",
		pickedAt:"FieldMeta",
		address:"FieldMeta",
		trackingCode:"FieldMeta"
	},
	OrderPriceLine:{
		_meta:"OrderPriceLineMeta",
		id:"UUID",
		unitPriceCents:"Int",
		quantity:"Int",
		type:"OrderPriceLineType",
		orderItem:"OrderItem",
		orderDiscount:"OrderDiscount",
		orderPayment:"OrderPayment",
		orderDelivery:"OrderDelivery",
		vatRatePermille:"Int",
		vatRate:"VatRate",
		order:"Order"
	},
	OrderPriceLineMeta:{
		id:"FieldMeta",
		unitPriceCents:"FieldMeta",
		quantity:"FieldMeta",
		type:"FieldMeta",
		orderItem:"FieldMeta",
		orderDiscount:"FieldMeta",
		orderPayment:"FieldMeta",
		orderDelivery:"FieldMeta",
		vatRatePermille:"FieldMeta",
		vatRate:"FieldMeta",
		order:"FieldMeta"
	},
	OrderPayment:{
		_meta:"OrderPaymentMeta",
		id:"UUID",
		data:"Json",
		paymentCents:"Int",
		method:"PaymentMethod",
		failedAt:"DateTime",
		state:"OrderPaymentState",
		approvedAt:"DateTime",
		order:"Order",
		invoice:"Invoice",
		walletType:"String",
		createdAt:"DateTime",
		publicReceiptUrl:"String",
		priceCents:"Int",
		vatRate:"VatRate",
		meta:"OrderPaymentMetadata",
		otherCurrencyPriceCents:"String"
	},
	OrderPaymentMeta:{
		id:"FieldMeta",
		data:"FieldMeta",
		paymentCents:"FieldMeta",
		method:"FieldMeta",
		failedAt:"FieldMeta",
		state:"FieldMeta",
		approvedAt:"FieldMeta",
		order:"FieldMeta",
		invoice:"FieldMeta",
		walletType:"FieldMeta",
		createdAt:"FieldMeta",
		publicReceiptUrl:"FieldMeta",
		priceCents:"FieldMeta",
		vatRate:"FieldMeta",
		meta:"FieldMeta",
		otherCurrencyPriceCents:"FieldMeta"
	},
	Invoice:{
		_meta:"InvoiceMeta",
		id:"UUID",
		createdAt:"DateTime",
		publicKey:"String",
		fakturoidId:"String",
		fakturoidData:"String",
		legacyUrl:"String",
		customer:"Customer",
		orderPayments:"OrderPayment",
		address:"Address",
		publicUrl:"String",
		billingSubject:"BillingSubject",
		orderPaymentsByMeta:"OrderPayment",
		paginateOrderPayments:"OrderPaymentConnection"
	},
	InvoiceMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		publicKey:"FieldMeta",
		fakturoidId:"FieldMeta",
		fakturoidData:"FieldMeta",
		legacyUrl:"FieldMeta",
		customer:"FieldMeta",
		orderPayments:"FieldMeta",
		address:"FieldMeta",
		publicUrl:"FieldMeta",
		billingSubject:"FieldMeta"
	},
	BillingSubject:{
		_meta:"BillingSubjectMeta",
		id:"UUID",
		name:"String",
		fullName:"String",
		addressLine1:"String",
		addressLine2:"String",
		city:"String",
		postalCode:"String",
		email:"String",
		phone:"String",
		companyName:"String",
		companyIdentifier:"String",
		vatIdentifier:"String",
		externalIdentifier:"String",
		hidden:"Boolean",
		createdAt:"DateTime",
		deletedAt:"DateTime",
		note:"String",
		fakturoidSubjectId:"String",
		country:"Country",
		customer:"Customer",
		invoices:"Invoice",
		defaultBillingSubjectOfCustomer:"Customer",
		orders:"Order",
		invoicesByPublicKey:"Invoice",
		invoicesByFakturoidId:"Invoice",
		invoicesByOrderPayments:"Invoice",
		ordersBySeq:"Order",
		ordersByDoubleshotLegacyId:"Order",
		ordersByDoubleShotLegacyNumber:"Order",
		ordersByDiscounts:"Order",
		ordersByDelivery:"Order",
		ordersByItems:"Order",
		ordersByCart:"Order",
		ordersByPriceLines:"Order",
		ordersByPayments:"Order",
		ordersBySummary:"Order",
		paginateInvoices:"InvoiceConnection",
		paginateOrders:"OrderConnection"
	},
	BillingSubjectMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		fullName:"FieldMeta",
		addressLine1:"FieldMeta",
		addressLine2:"FieldMeta",
		city:"FieldMeta",
		postalCode:"FieldMeta",
		email:"FieldMeta",
		phone:"FieldMeta",
		companyName:"FieldMeta",
		companyIdentifier:"FieldMeta",
		vatIdentifier:"FieldMeta",
		externalIdentifier:"FieldMeta",
		hidden:"FieldMeta",
		createdAt:"FieldMeta",
		deletedAt:"FieldMeta",
		note:"FieldMeta",
		fakturoidSubjectId:"FieldMeta",
		country:"FieldMeta",
		customer:"FieldMeta",
		invoices:"FieldMeta",
		defaultBillingSubjectOfCustomer:"FieldMeta",
		orders:"FieldMeta"
	},
	Country:{
		_meta:"CountryMeta",
		id:"UUID",
		code:"String",
		order:"Int"
	},
	CountryMeta:{
		id:"FieldMeta",
		code:"FieldMeta",
		order:"FieldMeta"
	},
	InvoiceConnection:{
		pageInfo:"PageInfo",
		edges:"InvoiceEdge"
	},
	InvoiceEdge:{
		node:"Invoice"
	},
	OrderConnection:{
		pageInfo:"PageInfo",
		edges:"OrderEdge"
	},
	OrderEdge:{
		node:"Order"
	},
	OrderPaymentConnection:{
		pageInfo:"PageInfo",
		edges:"OrderPaymentEdge"
	},
	OrderPaymentEdge:{
		node:"OrderPayment"
	},
	OrderPaymentMetadata:{
		_meta:"OrderPaymentMetadataMeta",
		id:"UUID",
		paymentUrl:"String",
		orderPayment:"OrderPayment"
	},
	OrderPaymentMetadataMeta:{
		id:"FieldMeta",
		paymentUrl:"FieldMeta",
		orderPayment:"FieldMeta"
	},
	OrderSummary:{
		_meta:"OrderSummaryMeta",
		id:"UUID",
		totalPriceCents:"Int",
		discountPriceCents:"Int",
		undiscountedPriceCents:"Int",
		itemsQuantity:"Int",
		orderUrl:"String",
		orderNumber:"String",
		order:"Order"
	},
	OrderSummaryMeta:{
		id:"FieldMeta",
		totalPriceCents:"FieldMeta",
		discountPriceCents:"FieldMeta",
		undiscountedPriceCents:"FieldMeta",
		itemsQuantity:"FieldMeta",
		orderUrl:"FieldMeta",
		orderNumber:"FieldMeta",
		order:"FieldMeta"
	},
	OrderDiscountConnection:{
		pageInfo:"PageInfo",
		edges:"OrderDiscountEdge"
	},
	OrderDiscountEdge:{
		node:"OrderDiscount"
	},
	OrderItemConnection:{
		pageInfo:"PageInfo",
		edges:"OrderItemEdge"
	},
	OrderItemEdge:{
		node:"OrderItem"
	},
	OrderPriceLineConnection:{
		pageInfo:"PageInfo",
		edges:"OrderPriceLineEdge"
	},
	OrderPriceLineEdge:{
		node:"OrderPriceLine"
	},
	FulfillmentNoteConnection:{
		pageInfo:"PageInfo",
		edges:"FulfillmentNoteEdge"
	},
	FulfillmentNoteEdge:{
		node:"FulfillmentNote"
	},
	VirtualProductEffectConnection:{
		pageInfo:"PageInfo",
		edges:"VirtualProductEffectEdge"
	},
	VirtualProductEffectEdge:{
		node:"VirtualProductEffect"
	},
	CartItemConnection:{
		pageInfo:"PageInfo",
		edges:"CartItemEdge"
	},
	CartItemEdge:{
		node:"CartItem"
	},
	CartPriceLine:{
		_meta:"CartPriceLineMeta",
		id:"UUID",
		type:"CartPriceLineType",
		item:"CartItem",
		product:"Product",
		unitPriceCents:"Int",
		priceCents:"Int",
		quantity:"Int",
		vatRatePermille:"Int",
		vatRate:"VatRate",
		cart:"Cart"
	},
	CartPriceLineMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		item:"FieldMeta",
		product:"FieldMeta",
		unitPriceCents:"FieldMeta",
		priceCents:"FieldMeta",
		quantity:"FieldMeta",
		vatRatePermille:"FieldMeta",
		vatRate:"FieldMeta",
		cart:"FieldMeta"
	},
	CartSummary:{
		_meta:"CartSummaryMeta",
		id:"UUID",
		itemsPriceCents:"Int",
		deliveryPriceCents:"Int",
		totalPriceCents:"Int",
		itemsCount:"Int",
		itemsQuantity:"Int",
		checkoutUrl:"String",
		cart:"Cart"
	},
	CartSummaryMeta:{
		id:"FieldMeta",
		itemsPriceCents:"FieldMeta",
		deliveryPriceCents:"FieldMeta",
		totalPriceCents:"FieldMeta",
		itemsCount:"FieldMeta",
		itemsQuantity:"FieldMeta",
		checkoutUrl:"FieldMeta",
		cart:"FieldMeta"
	},
	CartPriceLineConnection:{
		pageInfo:"PageInfo",
		edges:"CartPriceLineEdge"
	},
	CartPriceLineEdge:{
		node:"CartPriceLine"
	},
	CartConnection:{
		pageInfo:"PageInfo",
		edges:"CartEdge"
	},
	CartEdge:{
		node:"Cart"
	},
	FreshingContainerConnection:{
		pageInfo:"PageInfo",
		edges:"FreshingContainerEdge"
	},
	FreshingContainerEdge:{
		node:"FreshingContainer"
	},
	ProductIngredientLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientLocaleEdge"
	},
	ProductIngredientLocaleEdge:{
		node:"ProductIngredientLocale"
	},
	AllergenConnection:{
		pageInfo:"PageInfo",
		edges:"AllergenEdge"
	},
	AllergenEdge:{
		node:"Allergen"
	},
	CustomerIngredientRatingConnection:{
		pageInfo:"PageInfo",
		edges:"CustomerIngredientRatingEdge"
	},
	CustomerIngredientRatingEdge:{
		node:"CustomerIngredientRating"
	},
	BlogLocale:{
		_meta:"BlogLocaleMeta",
		id:"UUID",
		pageName:"String",
		title:"String",
		lead:"String",
		root:"Blog",
		locale:"Locale"
	},
	BlogLocaleMeta:{
		id:"FieldMeta",
		pageName:"FieldMeta",
		title:"FieldMeta",
		lead:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	Blog:{
		_meta:"BlogMeta",
		id:"UUID",
		unique:"One",
		locales:"BlogLocale",
		localesByLocale:"BlogLocale",
		paginateLocales:"BlogLocaleConnection"
	},
	BlogMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	BlogLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BlogLocaleEdge"
	},
	BlogLocaleEdge:{
		node:"BlogLocale"
	},
	BlogPostLocale:{
		_meta:"BlogPostLocaleMeta",
		id:"UUID",
		title:"String",
		availableForWeb:"Boolean",
		availableForMobile:"Boolean",
		root:"BlogPost",
		locale:"Locale",
		cover:"Cover",
		content:"Content",
		link:"Linkable",
		seo:"Seo",
		products:"Product",
		paginateProducts:"ProductConnection"
	},
	BlogPostLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		availableForWeb:"FieldMeta",
		availableForMobile:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		cover:"FieldMeta",
		content:"FieldMeta",
		link:"FieldMeta",
		seo:"FieldMeta",
		products:"FieldMeta"
	},
	BlogPost:{
		_meta:"BlogPostMeta",
		id:"UUID",
		order:"Int",
		locales:"BlogPostLocale",
		localesByLocale:"BlogPostLocale",
		localesByCover:"BlogPostLocale",
		localesByContent:"BlogPostLocale",
		localesByLink:"BlogPostLocale",
		localesBySeo:"BlogPostLocale",
		paginateLocales:"BlogPostLocaleConnection"
	},
	BlogPostMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		locales:"FieldMeta"
	},
	BlogPostLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostLocaleEdge"
	},
	BlogPostLocaleEdge:{
		node:"BlogPostLocale"
	},
	Cover:{
		_meta:"CoverMeta",
		id:"UUID",
		primaryTitle:"String",
		secondaryTitle:"String",
		medium:"Medium",
		buttonColors:"ColorPallete"
	},
	CoverMeta:{
		id:"FieldMeta",
		primaryTitle:"FieldMeta",
		secondaryTitle:"FieldMeta",
		medium:"FieldMeta",
		buttonColors:"FieldMeta"
	},
	Medium:{
		_meta:"MediumMeta",
		id:"UUID",
		type:"MediumType",
		colorTheme:"ColorTheme",
		image:"Image",
		video:"Video"
	},
	MediumMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		colorTheme:"FieldMeta",
		image:"FieldMeta",
		video:"FieldMeta"
	},
	Video:{
		_meta:"VideoMeta",
		id:"UUID",
		src:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		duration:"Float",
		poster:"Image"
	},
	VideoMeta:{
		id:"FieldMeta",
		src:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		duration:"FieldMeta",
		poster:"FieldMeta"
	},
	ColorPallete:{
		_meta:"ColorPalleteMeta",
		id:"UUID",
		background:"String",
		text:"String"
	},
	ColorPalleteMeta:{
		id:"FieldMeta",
		background:"FieldMeta",
		text:"FieldMeta"
	},
	Content:{
		_meta:"ContentMeta",
		id:"UUID",
		blocks:"ContentBlock",
		blocksByReferences:"ContentBlock",
		paginateBlocks:"ContentBlockConnection"
	},
	ContentMeta:{
		id:"FieldMeta",
		blocks:"FieldMeta"
	},
	ContentBlock:{
		_meta:"ContentBlockMeta",
		id:"UUID",
		order:"Int",
		json:"String",
		content:"Content",
		references:"ContentReference",
		referencesByProducts:"ContentReference",
		referencesByProductBanners:"ContentReference",
		referencesByDeliveryRegions:"ContentReference",
		paginateReferences:"ContentReferenceConnection"
	},
	ContentBlockMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		json:"FieldMeta",
		content:"FieldMeta",
		references:"FieldMeta"
	},
	ContentReference:{
		_meta:"ContentReferenceMeta",
		id:"UUID",
		type:"ContentReferenceType",
		primaryText:"String",
		secondaryText:"String",
		jsonContent:"String",
		block:"ContentBlock",
		colorPallete:"ColorPallete",
		medium:"Medium",
		link:"Link",
		blogPosts:"BlogPostList",
		links:"LinkList",
		products:"ProductList",
		contentSize:"ContentSize",
		misc:"String",
		hero:"Hero",
		gallery:"Gallery",
		socialsAndApps:"SocialsAndApps",
		productGrid:"ProductGrid",
		productBanners:"ProductBannerList",
		deliveryRegions:"DeliveryRegions",
		textList:"TextList",
		gridTiles:"GridTiles"
	},
	ContentReferenceMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		primaryText:"FieldMeta",
		secondaryText:"FieldMeta",
		jsonContent:"FieldMeta",
		block:"FieldMeta",
		colorPallete:"FieldMeta",
		medium:"FieldMeta",
		link:"FieldMeta",
		blogPosts:"FieldMeta",
		links:"FieldMeta",
		products:"FieldMeta",
		contentSize:"FieldMeta",
		misc:"FieldMeta",
		hero:"FieldMeta",
		gallery:"FieldMeta",
		socialsAndApps:"FieldMeta",
		productGrid:"FieldMeta",
		productBanners:"FieldMeta",
		deliveryRegions:"FieldMeta",
		textList:"FieldMeta",
		gridTiles:"FieldMeta"
	},
	Link:{
		_meta:"LinkMeta",
		id:"UUID",
		type:"LinkType",
		title:"String",
		externalLink:"String",
		internalLink:"Linkable"
	},
	LinkMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		title:"FieldMeta",
		externalLink:"FieldMeta",
		internalLink:"FieldMeta"
	},
	Linkable:{
		_meta:"LinkableMeta",
		id:"UUID",
		url:"String",
		blogPost:"BlogPostLocale",
		page:"PageLocale",
		redirect:"Redirect"
	},
	LinkableMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		blogPost:"FieldMeta",
		page:"FieldMeta",
		redirect:"FieldMeta"
	},
	PageLocale:{
		_meta:"PageLocaleMeta",
		id:"UUID",
		title:"String",
		link:"Linkable",
		root:"Page",
		locale:"Locale",
		seo:"Seo",
		content:"Content",
		cover:"Cover",
		theme:"PageTheme"
	},
	PageLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		link:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		seo:"FieldMeta",
		content:"FieldMeta",
		cover:"FieldMeta",
		theme:"FieldMeta"
	},
	Page:{
		_meta:"PageMeta",
		id:"UUID",
		locales:"PageLocale",
		localesByLocale:"PageLocale",
		localesByLink:"PageLocale",
		localesBySeo:"PageLocale",
		localesByContent:"PageLocale",
		localesByCover:"PageLocale",
		paginateLocales:"PageLocaleConnection"
	},
	PageMeta:{
		id:"FieldMeta",
		locales:"FieldMeta"
	},
	PageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"PageLocaleEdge"
	},
	PageLocaleEdge:{
		node:"PageLocale"
	},
	Seo:{
		_meta:"SeoMeta",
		id:"UUID",
		title:"String",
		description:"String",
		ogTitle:"String",
		ogDescription:"String",
		ogImage:"Image"
	},
	SeoMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		ogTitle:"FieldMeta",
		ogDescription:"FieldMeta",
		ogImage:"FieldMeta"
	},
	Redirect:{
		_meta:"RedirectMeta",
		id:"UUID",
		link:"Linkable",
		target:"Link"
	},
	RedirectMeta:{
		id:"FieldMeta",
		link:"FieldMeta",
		target:"FieldMeta"
	},
	BlogPostList:{
		_meta:"BlogPostListMeta",
		id:"UUID",
		items:"BlogPostListItem",
		paginateItems:"BlogPostListItemConnection"
	},
	BlogPostListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	BlogPostListItem:{
		_meta:"BlogPostListItemMeta",
		id:"UUID",
		order:"Int",
		caption:"String",
		list:"BlogPostList",
		blogPost:"BlogPostLocale"
	},
	BlogPostListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		caption:"FieldMeta",
		list:"FieldMeta",
		blogPost:"FieldMeta"
	},
	BlogPostListItemConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostListItemEdge"
	},
	BlogPostListItemEdge:{
		node:"BlogPostListItem"
	},
	LinkList:{
		_meta:"LinkListMeta",
		id:"UUID",
		items:"LinkListItem",
		paginateItems:"LinkListItemConnection"
	},
	LinkListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	LinkListItem:{
		_meta:"LinkListItemMeta",
		id:"UUID",
		order:"Int",
		isPrimary:"Boolean",
		list:"LinkList",
		link:"Link"
	},
	LinkListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		isPrimary:"FieldMeta",
		list:"FieldMeta",
		link:"FieldMeta"
	},
	LinkListItemConnection:{
		pageInfo:"PageInfo",
		edges:"LinkListItemEdge"
	},
	LinkListItemEdge:{
		node:"LinkListItem"
	},
	Hero:{
		_meta:"HeroMeta",
		id:"UUID",
		showLogo:"Boolean",
		middleContent:"String",
		medium:"Medium"
	},
	HeroMeta:{
		id:"FieldMeta",
		showLogo:"FieldMeta",
		middleContent:"FieldMeta",
		medium:"FieldMeta"
	},
	Gallery:{
		_meta:"GalleryMeta",
		id:"UUID",
		items:"GalleryItem",
		paginateItems:"GalleryItemConnection"
	},
	GalleryMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	GalleryItem:{
		_meta:"GalleryItemMeta",
		id:"UUID",
		order:"Int",
		caption:"String",
		list:"Gallery",
		medium:"Medium"
	},
	GalleryItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		caption:"FieldMeta",
		list:"FieldMeta",
		medium:"FieldMeta"
	},
	GalleryItemConnection:{
		pageInfo:"PageInfo",
		edges:"GalleryItemEdge"
	},
	GalleryItemEdge:{
		node:"GalleryItem"
	},
	SocialsAndApps:{
		_meta:"SocialsAndAppsMeta",
		id:"UUID",
		instagram:"Boolean",
		appStore:"Boolean",
		googlePlay:"Boolean"
	},
	SocialsAndAppsMeta:{
		id:"FieldMeta",
		instagram:"FieldMeta",
		appStore:"FieldMeta",
		googlePlay:"FieldMeta"
	},
	ProductGrid:{
		_meta:"ProductGridMeta",
		id:"UUID",
		isCarousel:"Boolean",
		categories:"ProductGridCategory",
		type:"ProductGridType",
		categoriesByItems:"ProductGridCategory",
		categoriesByImages:"ProductGridCategory",
		paginateCategories:"ProductGridCategoryConnection"
	},
	ProductGridMeta:{
		id:"FieldMeta",
		isCarousel:"FieldMeta",
		categories:"FieldMeta",
		type:"FieldMeta"
	},
	ProductGridCategory:{
		_meta:"ProductGridCategoryMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		root:"ProductGrid",
		items:"ProductGridItem",
		images:"ImageList",
		paginateItems:"ProductGridItemConnection"
	},
	ProductGridCategoryMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		items:"FieldMeta",
		images:"FieldMeta"
	},
	ProductGridItem:{
		_meta:"ProductGridItemMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		lead:"String",
		product:"Product",
		medium:"Medium",
		link:"Link",
		category:"ProductGridCategory",
		description:"String"
	},
	ProductGridItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		lead:"FieldMeta",
		product:"FieldMeta",
		medium:"FieldMeta",
		link:"FieldMeta",
		category:"FieldMeta",
		description:"FieldMeta"
	},
	ImageList:{
		_meta:"ImageListMeta",
		id:"UUID",
		items:"ImageListItem",
		itemsByImage:"ImageListItem",
		paginateItems:"ImageListItemConnection"
	},
	ImageListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ImageListItem:{
		_meta:"ImageListItemMeta",
		id:"UUID",
		order:"Int",
		list:"ImageList",
		image:"Image"
	},
	ImageListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta"
	},
	ImageListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListItemEdge"
	},
	ImageListItemEdge:{
		node:"ImageListItem"
	},
	ProductGridItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductGridItemEdge"
	},
	ProductGridItemEdge:{
		node:"ProductGridItem"
	},
	ProductGridCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"ProductGridCategoryEdge"
	},
	ProductGridCategoryEdge:{
		node:"ProductGridCategory"
	},
	ProductBannerList:{
		_meta:"ProductBannerListMeta",
		id:"UUID",
		items:"ProductBannerItem",
		paginateItems:"ProductBannerItemConnection"
	},
	ProductBannerListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ProductBannerItem:{
		_meta:"ProductBannerItemMeta",
		id:"UUID",
		order:"Int",
		list:"ProductBannerList",
		banner:"ProductBanner",
		size:"ProductBannerItemSize"
	},
	ProductBannerItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		banner:"FieldMeta",
		size:"FieldMeta"
	},
	ProductBanner:{
		_meta:"ProductBannerMeta",
		id:"UUID",
		beforeTitle:"String",
		title:"String",
		label:"String",
		button:"Link",
		backgroundImage:"Image",
		productImage:"Image",
		product:"Product"
	},
	ProductBannerMeta:{
		id:"FieldMeta",
		beforeTitle:"FieldMeta",
		title:"FieldMeta",
		label:"FieldMeta",
		button:"FieldMeta",
		backgroundImage:"FieldMeta",
		productImage:"FieldMeta",
		product:"FieldMeta"
	},
	ProductBannerItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductBannerItemEdge"
	},
	ProductBannerItemEdge:{
		node:"ProductBannerItem"
	},
	DeliveryRegions:{
		_meta:"DeliveryRegionsMeta",
		id:"UUID",
		items:"DeliveryRegionsItem",
		itemsByImage:"DeliveryRegionsItem",
		itemsByTextList:"DeliveryRegionsItem",
		itemsByGallery:"DeliveryRegionsItem",
		paginateItems:"DeliveryRegionsItemConnection"
	},
	DeliveryRegionsMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	DeliveryRegionsItem:{
		_meta:"DeliveryRegionsItemMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		offerLabel:"String",
		list:"DeliveryRegions",
		image:"Image",
		textList:"TextList",
		gallery:"ImageList"
	},
	DeliveryRegionsItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		offerLabel:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta",
		textList:"FieldMeta",
		gallery:"FieldMeta"
	},
	TextList:{
		_meta:"TextListMeta",
		id:"UUID",
		items:"TextItem",
		paginateItems:"TextItemConnection"
	},
	TextListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	TextItem:{
		_meta:"TextItemMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		list:"TextList",
		title:"String"
	},
	TextItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		text:"FieldMeta",
		list:"FieldMeta",
		title:"FieldMeta"
	},
	TextItemConnection:{
		pageInfo:"PageInfo",
		edges:"TextItemEdge"
	},
	TextItemEdge:{
		node:"TextItem"
	},
	DeliveryRegionsItemConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryRegionsItemEdge"
	},
	DeliveryRegionsItemEdge:{
		node:"DeliveryRegionsItem"
	},
	GridTiles:{
		_meta:"GridTilesMeta",
		id:"UUID",
		items:"GridTileItem",
		itemsByLink:"GridTileItem",
		paginateItems:"GridTileItemConnection"
	},
	GridTilesMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	GridTileItem:{
		_meta:"GridTileItemMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		list:"GridTiles",
		medium:"Medium",
		link:"Link"
	},
	GridTileItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		text:"FieldMeta",
		list:"FieldMeta",
		medium:"FieldMeta",
		link:"FieldMeta"
	},
	GridTileItemConnection:{
		pageInfo:"PageInfo",
		edges:"GridTileItemEdge"
	},
	GridTileItemEdge:{
		node:"GridTileItem"
	},
	ContentReferenceConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceEdge"
	},
	ContentReferenceEdge:{
		node:"ContentReference"
	},
	ContentBlockConnection:{
		pageInfo:"PageInfo",
		edges:"ContentBlockEdge"
	},
	ContentBlockEdge:{
		node:"ContentBlock"
	},
	TranslationCataloguesIdentifier:{
		_meta:"TranslationCataloguesIdentifierMeta",
		id:"UUID",
		name:"String",
		code:"String",
		catalogue:"TranslationCatalogue",
		catalogueByDomain:"TranslationCatalogue",
		catalogueByValues:"TranslationCatalogue",
		paginateCatalogue:"TranslationCatalogueConnection"
	},
	TranslationCataloguesIdentifierMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		catalogue:"FieldMeta"
	},
	TranslationCatalogue:{
		_meta:"TranslationCatalogueMeta",
		id:"UUID",
		domain:"TranslationDomain",
		fallback:"TranslationCatalogue",
		values:"TranslationValue",
		identifier:"TranslationCataloguesIdentifier",
		valuesByKey:"TranslationValue",
		paginateValues:"TranslationValueConnection"
	},
	TranslationCatalogueMeta:{
		id:"FieldMeta",
		domain:"FieldMeta",
		fallback:"FieldMeta",
		values:"FieldMeta",
		identifier:"FieldMeta"
	},
	TranslationDomain:{
		_meta:"TranslationDomainMeta",
		id:"UUID",
		identifier:"String",
		name:"String",
		catalogues:"TranslationCatalogue",
		keys:"TranslationKey",
		cataloguesByIdentifier:"TranslationCatalogue",
		cataloguesByValues:"TranslationCatalogue",
		keysByIdentifier:"TranslationKey",
		keysByValues:"TranslationKey",
		paginateCatalogues:"TranslationCatalogueConnection",
		paginateKeys:"TranslationKeyConnection"
	},
	TranslationDomainMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		name:"FieldMeta",
		catalogues:"FieldMeta",
		keys:"FieldMeta"
	},
	TranslationKey:{
		_meta:"TranslationKeyMeta",
		id:"UUID",
		identifier:"String",
		contentType:"TranslationContentType",
		note:"String",
		domain:"TranslationDomain",
		values:"TranslationValue",
		valuesByCatalogue:"TranslationValue",
		paginateValues:"TranslationValueConnection"
	},
	TranslationKeyMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		contentType:"FieldMeta",
		note:"FieldMeta",
		domain:"FieldMeta",
		values:"FieldMeta"
	},
	TranslationValue:{
		_meta:"TranslationValueMeta",
		id:"UUID",
		value:"String",
		catalogue:"TranslationCatalogue",
		key:"TranslationKey"
	},
	TranslationValueMeta:{
		id:"FieldMeta",
		value:"FieldMeta",
		catalogue:"FieldMeta",
		key:"FieldMeta"
	},
	TranslationValueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationValueEdge"
	},
	TranslationValueEdge:{
		node:"TranslationValue"
	},
	TranslationCatalogueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCatalogueEdge"
	},
	TranslationCatalogueEdge:{
		node:"TranslationCatalogue"
	},
	TranslationKeyConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationKeyEdge"
	},
	TranslationKeyEdge:{
		node:"TranslationKey"
	},
	ProductLocale:{
		_meta:"ProductLocaleMeta",
		id:"UUID",
		name:"String",
		description:"String",
		root:"Product",
		locale:"Locale",
		featureList:"TextList",
		printableName:"String",
		firstSticker:"String",
		secondSticker:"String"
	},
	ProductLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		description:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		featureList:"FieldMeta",
		printableName:"FieldMeta",
		firstSticker:"FieldMeta",
		secondSticker:"FieldMeta"
	},
	ProductRecipeLocale:{
		_meta:"ProductRecipeLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductRecipe",
		locale:"Locale",
		description:"String"
	},
	ProductRecipeLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		description:"FieldMeta"
	},
	ProductRecipe:{
		_meta:"ProductRecipeMeta",
		id:"UUID",
		name:"String",
		products:"Product",
		internalNote:"String",
		color:"String",
		image:"Image",
		icon:"Image",
		ingredients:"ProductIngredientItem",
		needsPrepping:"Boolean",
		locales:"ProductRecipeLocale",
		gutTuning:"Float",
		freshingContainer:"FreshingContainer",
		description:"String",
		productsByCode:"Product",
		productsByStocks:"Product",
		productsByGroupPrices:"Product",
		productsByCartItems:"Product",
		productsByOrderItems:"Product",
		productsByLocales:"Product",
		productsByCustomerPrices:"Product",
		productsByVirtualProduct:"Product",
		productsByMeta:"Product",
		productsByPage:"Product",
		localesByLocale:"ProductRecipeLocale",
		paginateProducts:"ProductConnection",
		paginateIngredients:"ProductIngredientItemConnection",
		paginateLocales:"ProductRecipeLocaleConnection"
	},
	ProductRecipeMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		products:"FieldMeta",
		internalNote:"FieldMeta",
		color:"FieldMeta",
		image:"FieldMeta",
		icon:"FieldMeta",
		ingredients:"FieldMeta",
		needsPrepping:"FieldMeta",
		locales:"FieldMeta",
		gutTuning:"FieldMeta",
		freshingContainer:"FieldMeta",
		description:"FieldMeta"
	},
	ProductIngredientItem:{
		_meta:"ProductIngredientItemMeta",
		id:"UUID",
		order:"Int",
		internalOrder:"Int",
		recipe:"ProductRecipe",
		ingredient:"ProductIngredient"
	},
	ProductIngredientItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		internalOrder:"FieldMeta",
		recipe:"FieldMeta",
		ingredient:"FieldMeta"
	},
	ProductIngredientItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientItemEdge"
	},
	ProductIngredientItemEdge:{
		node:"ProductIngredientItem"
	},
	ProductRecipeLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductRecipeLocaleEdge"
	},
	ProductRecipeLocaleEdge:{
		node:"ProductRecipeLocale"
	},
	IdentificationCodeLocale:{
		_meta:"IdentificationCodeLocaleMeta",
		id:"UUID",
		successMessage:"String",
		root:"IdentificationCode",
		locale:"Locale"
	},
	IdentificationCodeLocaleMeta:{
		id:"FieldMeta",
		successMessage:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	IdentificationCode:{
		_meta:"IdentificationCodeMeta",
		id:"UUID",
		code:"String",
		createdAt:"DateTime",
		locales:"IdentificationCodeLocale",
		internalName:"String",
		localesByLocale:"IdentificationCodeLocale",
		paginateLocales:"IdentificationCodeLocaleConnection"
	},
	IdentificationCodeMeta:{
		id:"FieldMeta",
		code:"FieldMeta",
		createdAt:"FieldMeta",
		locales:"FieldMeta",
		internalName:"FieldMeta"
	},
	IdentificationCodeLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"IdentificationCodeLocaleEdge"
	},
	IdentificationCodeLocaleEdge:{
		node:"IdentificationCodeLocale"
	},
	SubscriptionBoxLocale:{
		_meta:"SubscriptionBoxLocaleMeta",
		id:"UUID",
		title:"String",
		stickerText:"String",
		descriptionText:"String",
		root:"SubscriptionBox",
		locale:"Locale",
		secondaryTitle:"String",
		texts:"TextList",
		secondaryTexts:"TextList"
	},
	SubscriptionBoxLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		stickerText:"FieldMeta",
		descriptionText:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		secondaryTitle:"FieldMeta",
		texts:"FieldMeta",
		secondaryTexts:"FieldMeta"
	},
	SubscriptionBox:{
		_meta:"SubscriptionBoxMeta",
		id:"UUID",
		locales:"SubscriptionBoxLocale",
		unique:"One",
		localesByLocale:"SubscriptionBoxLocale",
		localesByTexts:"SubscriptionBoxLocale",
		localesBySecondaryTexts:"SubscriptionBoxLocale",
		paginateLocales:"SubscriptionBoxLocaleConnection"
	},
	SubscriptionBoxMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		unique:"FieldMeta"
	},
	SubscriptionBoxLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"SubscriptionBoxLocaleEdge"
	},
	SubscriptionBoxLocaleEdge:{
		node:"SubscriptionBoxLocale"
	},
	ProductPackingLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductPackingLocaleEdge"
	},
	ProductPackingLocaleEdge:{
		node:"ProductPackingLocale"
	},
	ProductLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductLocaleEdge"
	},
	ProductLocaleEdge:{
		node:"ProductLocale"
	},
	ProductStock:{
		_meta:"ProductStockMeta",
		id:"UUID",
		quantity:"Int",
		product:"Product",
		store:"Store"
	},
	ProductStockMeta:{
		id:"FieldMeta",
		quantity:"FieldMeta",
		product:"FieldMeta",
		store:"FieldMeta"
	},
	Store:{
		_meta:"StoreMeta",
		id:"UUID",
		name:"String",
		code:"String",
		description:"String",
		internalNote:"String"
	},
	StoreMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		description:"FieldMeta",
		internalNote:"FieldMeta"
	},
	ProductGroupPrice:{
		_meta:"ProductGroupPriceMeta",
		id:"UUID",
		priceCents:"Int",
		group:"CustomerGroup",
		product:"Product"
	},
	ProductGroupPriceMeta:{
		id:"FieldMeta",
		priceCents:"FieldMeta",
		group:"FieldMeta",
		product:"FieldMeta"
	},
	CustomerGroup:{
		_meta:"CustomerGroupMeta",
		id:"UUID",
		name:"String",
		productPrices:"ProductGroupPrice",
		customers:"Customer",
		discountPermille:"Int",
		productPricesByProduct:"ProductGroupPrice",
		customersByDoubleshotLegacyId:"Customer",
		customersByAddresses:"Customer",
		customersByDefaultBillingAddress:"Customer",
		customersByCredits:"Customer",
		customersByOrders:"Customer",
		customersByPermissions:"Customer",
		customersByName:"Customer",
		customersByIngredientRatings:"Customer",
		customersByBusiness:"Customer",
		customersByCarts:"Customer",
		customersByUser:"Customer",
		customersByInvoices:"Customer",
		customersByInvoicingPlan:"Customer",
		customersByBillingSubjects:"Customer",
		customersByOfferedProducts:"Customer",
		customersByDefaultDeliveryAddress:"Customer",
		customersByDefaultBillingSubject:"Customer",
		customersByAnonymousSession:"Customer",
		customersByBoughtVouchers:"Customer",
		customersByOwnsVouchers:"Customer",
		customersByRedeemedVouchers:"Customer",
		customersByMeta:"Customer",
		paginateProductPrices:"ProductGroupPriceConnection",
		paginateCustomers:"CustomerConnection"
	},
	CustomerGroupMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		productPrices:"FieldMeta",
		customers:"FieldMeta",
		discountPermille:"FieldMeta"
	},
	ProductGroupPriceConnection:{
		pageInfo:"PageInfo",
		edges:"ProductGroupPriceEdge"
	},
	ProductGroupPriceEdge:{
		node:"ProductGroupPrice"
	},
	ProductCustomerPrice:{
		_meta:"ProductCustomerPriceMeta",
		id:"UUID",
		groupDiscountPermille:"Int",
		groupPriceCents:"Int",
		fullPriceCents:"Int",
		discountedPriceCents:"Int",
		priceCents:"Int",
		offered:"Boolean",
		notDiscountable:"Boolean",
		customer:"Customer",
		product:"Product"
	},
	ProductCustomerPriceMeta:{
		id:"FieldMeta",
		groupDiscountPermille:"FieldMeta",
		groupPriceCents:"FieldMeta",
		fullPriceCents:"FieldMeta",
		discountedPriceCents:"FieldMeta",
		priceCents:"FieldMeta",
		offered:"FieldMeta",
		notDiscountable:"FieldMeta",
		customer:"FieldMeta",
		product:"FieldMeta"
	},
	ProductMetadata:{
		_meta:"ProductMetadataMeta",
		id:"UUID",
		archived:"Boolean",
		available:"Boolean",
		availableQuantity:"Int",
		product:"Product"
	},
	ProductMetadataMeta:{
		id:"FieldMeta",
		archived:"FieldMeta",
		available:"FieldMeta",
		availableQuantity:"FieldMeta",
		product:"FieldMeta"
	},
	ProductStockConnection:{
		pageInfo:"PageInfo",
		edges:"ProductStockEdge"
	},
	ProductStockEdge:{
		node:"ProductStock"
	},
	ProductCustomerPriceConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCustomerPriceEdge"
	},
	ProductCustomerPriceEdge:{
		node:"ProductCustomerPrice"
	},
	ChannelConnection:{
		pageInfo:"PageInfo",
		edges:"ChannelEdge"
	},
	ChannelEdge:{
		node:"Channel"
	},
	CustomerPermissions:{
		_meta:"CustomerPermissionsMeta",
		id:"UUID",
		canPlaceOrder:"Boolean",
		customer:"Customer"
	},
	CustomerPermissionsMeta:{
		id:"FieldMeta",
		canPlaceOrder:"FieldMeta",
		customer:"FieldMeta"
	},
	CustomerName:{
		_meta:"CustomerNameMeta",
		id:"UUID",
		nominative:"String",
		vocative:"String",
		customer:"Customer"
	},
	CustomerNameMeta:{
		id:"FieldMeta",
		nominative:"FieldMeta",
		vocative:"FieldMeta",
		customer:"FieldMeta"
	},
	User:{
		_meta:"UserMeta",
		id:"UUID",
		name:"String",
		staff:"Staff",
		customer:"Customer",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		verifiedAt:"DateTime",
		email:"String",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"UserMetadata"
	},
	UserMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		staff:"FieldMeta",
		customer:"FieldMeta",
		tenantPersonId:"FieldMeta",
		disabledAt:"FieldMeta",
		verifiedAt:"FieldMeta",
		email:"FieldMeta",
		createdAt:"FieldMeta",
		deletionRequestedAt:"FieldMeta",
		deletionExpectedAt:"FieldMeta",
		deletedAt:"FieldMeta",
		meta:"FieldMeta"
	},
	Staff:{
		_meta:"StaffMeta",
		id:"UUID",
		user:"User",
		photo:"Image",
		createdAt:"DateTime"
	},
	StaffMeta:{
		id:"FieldMeta",
		user:"FieldMeta",
		photo:"FieldMeta",
		createdAt:"FieldMeta"
	},
	UserMetadata:{
		_meta:"UserMetadataMeta",
		id:"UUID",
		userProfileUrl:"String",
		tenantEmail:"String",
		tenantPersonId:"UUID",
		tenantIdentityId:"UUID",
		globalRoles:"Json",
		projectRoles:"Json",
		projectRolesString:"String",
		externalIdentifiers:"Json",
		externalIdentifiersString:"String",
		idpSlugs:"Json",
		idpSlugsString:"String",
		user:"User"
	},
	UserMetadataMeta:{
		id:"FieldMeta",
		userProfileUrl:"FieldMeta",
		tenantEmail:"FieldMeta",
		tenantPersonId:"FieldMeta",
		tenantIdentityId:"FieldMeta",
		globalRoles:"FieldMeta",
		projectRoles:"FieldMeta",
		projectRolesString:"FieldMeta",
		externalIdentifiers:"FieldMeta",
		externalIdentifiersString:"FieldMeta",
		idpSlugs:"FieldMeta",
		idpSlugsString:"FieldMeta",
		user:"FieldMeta"
	},
	InvoicingPlan:{
		_meta:"InvoicingPlanMeta",
		id:"UUID",
		customer:"Customer",
		preset:"InvoicingPreset"
	},
	InvoicingPlanMeta:{
		id:"FieldMeta",
		customer:"FieldMeta",
		preset:"FieldMeta"
	},
	InvoicingPreset:{
		_meta:"InvoicingPresetMeta",
		id:"UUID",
		type:"InvoicingPresetType",
		startsAt:"DateTime",
		endsAt:"DateTime",
		intervalOfDays:"Int",
		invoicingPlan:"InvoicingPlan"
	},
	InvoicingPresetMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		startsAt:"FieldMeta",
		endsAt:"FieldMeta",
		intervalOfDays:"FieldMeta",
		invoicingPlan:"FieldMeta"
	},
	AnonymousSession:{
		_meta:"AnonymousSessionMeta",
		id:"UUID",
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		anonymousSessionKey:"String",
		email:"String",
		name:"String",
		internalNote:"String",
		customer:"Customer"
	},
	AnonymousSessionMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		lastActiveAt:"FieldMeta",
		anonymousSessionKey:"FieldMeta",
		email:"FieldMeta",
		name:"FieldMeta",
		internalNote:"FieldMeta",
		customer:"FieldMeta"
	},
	CustomerMetadata:{
		_meta:"CustomerMetadataMeta",
		id:"UUID",
		isBusiness:"Boolean",
		name:"String",
		fallbackName:"String",
		remainingCreditsCents:"Int",
		ordersTotalCents:"Int",
		customerProfileUrl:"String",
		customer:"Customer"
	},
	CustomerMetadataMeta:{
		id:"FieldMeta",
		isBusiness:"FieldMeta",
		name:"FieldMeta",
		fallbackName:"FieldMeta",
		remainingCreditsCents:"FieldMeta",
		ordersTotalCents:"FieldMeta",
		customerProfileUrl:"FieldMeta",
		customer:"FieldMeta"
	},
	CustomerCreditConnection:{
		pageInfo:"PageInfo",
		edges:"CustomerCreditEdge"
	},
	CustomerCreditEdge:{
		node:"CustomerCredit"
	},
	BillingSubjectConnection:{
		pageInfo:"PageInfo",
		edges:"BillingSubjectEdge"
	},
	BillingSubjectEdge:{
		node:"BillingSubject"
	},
	VoucherRedemptionConnection:{
		pageInfo:"PageInfo",
		edges:"VoucherRedemptionEdge"
	},
	VoucherRedemptionEdge:{
		node:"VoucherRedemption"
	},
	Gps:{
		_meta:"GpsMeta",
		id:"UUID",
		latitude:"Float",
		longitude:"Float",
		address:"Address"
	},
	GpsMeta:{
		id:"FieldMeta",
		latitude:"FieldMeta",
		longitude:"FieldMeta",
		address:"FieldMeta"
	},
	OrderDeliveryConnection:{
		pageInfo:"PageInfo",
		edges:"OrderDeliveryEdge"
	},
	OrderDeliveryEdge:{
		node:"OrderDelivery"
	},
	_ValidationResult:{
		valid:"Boolean",
		errors:"_ValidationError"
	},
	_ValidationError:{
		path:"_PathFragment",
		message:"_ValidationMessage"
	},
	_PathFragment:{
		"...on _FieldPathFragment":"_FieldPathFragment",
		"...on _IndexPathFragment":"_IndexPathFragment"
	},
	_FieldPathFragment:{
		field:"String"
	},
	_IndexPathFragment:{
		index:"Int",
		alias:"String"
	},
	_ValidationMessage:{
		text:"String"
	},
	CountryConnection:{
		pageInfo:"PageInfo",
		edges:"CountryEdge"
	},
	CountryEdge:{
		node:"Country"
	},
	CustomerGroupConnection:{
		pageInfo:"PageInfo",
		edges:"CustomerGroupEdge"
	},
	CustomerGroupEdge:{
		node:"CustomerGroup"
	},
	VatRateConnection:{
		pageInfo:"PageInfo",
		edges:"VatRateEdge"
	},
	VatRateEdge:{
		node:"VatRate"
	},
	ProductPackingConnection:{
		pageInfo:"PageInfo",
		edges:"ProductPackingEdge"
	},
	ProductPackingEdge:{
		node:"ProductPacking"
	},
	ProductRecipeConnection:{
		pageInfo:"PageInfo",
		edges:"ProductRecipeEdge"
	},
	ProductRecipeEdge:{
		node:"ProductRecipe"
	},
	StoreConnection:{
		pageInfo:"PageInfo",
		edges:"StoreEdge"
	},
	StoreEdge:{
		node:"Store"
	},
	CustomerPermissionsConnection:{
		pageInfo:"PageInfo",
		edges:"CustomerPermissionsEdge"
	},
	CustomerPermissionsEdge:{
		node:"CustomerPermissions"
	},
	ImageConnection:{
		pageInfo:"PageInfo",
		edges:"ImageEdge"
	},
	ImageEdge:{
		node:"Image"
	},
	ProductListConnection:{
		pageInfo:"PageInfo",
		edges:"ProductListEdge"
	},
	ProductListEdge:{
		node:"ProductList"
	},
	ProductCategoryListConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryListEdge"
	},
	ProductCategoryListEdge:{
		node:"ProductCategoryList"
	},
	LocaleConnection:{
		pageInfo:"PageInfo",
		edges:"LocaleEdge"
	},
	LocaleEdge:{
		node:"Locale"
	},
	CustomerNameConnection:{
		pageInfo:"PageInfo",
		edges:"CustomerNameEdge"
	},
	CustomerNameEdge:{
		node:"CustomerName"
	},
	ProductIngredientRatingConnection:{
		pageInfo:"PageInfo",
		edges:"ProductIngredientRatingEdge"
	},
	ProductIngredientRatingEdge:{
		node:"ProductIngredientRating"
	},
	StaffConnection:{
		pageInfo:"PageInfo",
		edges:"StaffEdge"
	},
	StaffEdge:{
		node:"Staff"
	},
	BlogConnection:{
		pageInfo:"PageInfo",
		edges:"BlogEdge"
	},
	BlogEdge:{
		node:"Blog"
	},
	BlogPostConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostEdge"
	},
	BlogPostEdge:{
		node:"BlogPost"
	},
	ColorPalleteConnection:{
		pageInfo:"PageInfo",
		edges:"ColorPalleteEdge"
	},
	ColorPalleteEdge:{
		node:"ColorPallete"
	},
	ContentConnection:{
		pageInfo:"PageInfo",
		edges:"ContentEdge"
	},
	ContentEdge:{
		node:"Content"
	},
	BlogPostListConnection:{
		pageInfo:"PageInfo",
		edges:"BlogPostListEdge"
	},
	BlogPostListEdge:{
		node:"BlogPostList"
	},
	LinkListConnection:{
		pageInfo:"PageInfo",
		edges:"LinkListEdge"
	},
	LinkListEdge:{
		node:"LinkList"
	},
	CoverConnection:{
		pageInfo:"PageInfo",
		edges:"CoverEdge"
	},
	CoverEdge:{
		node:"Cover"
	},
	LinkConnection:{
		pageInfo:"PageInfo",
		edges:"LinkEdge"
	},
	LinkEdge:{
		node:"Link"
	},
	LinkableConnection:{
		pageInfo:"PageInfo",
		edges:"LinkableEdge"
	},
	LinkableEdge:{
		node:"Linkable"
	},
	MediumConnection:{
		pageInfo:"PageInfo",
		edges:"MediumEdge"
	},
	MediumEdge:{
		node:"Medium"
	},
	VideoConnection:{
		pageInfo:"PageInfo",
		edges:"VideoEdge"
	},
	VideoEdge:{
		node:"Video"
	},
	SeoConnection:{
		pageInfo:"PageInfo",
		edges:"SeoEdge"
	},
	SeoEdge:{
		node:"Seo"
	},
	TranslationDomainConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationDomainEdge"
	},
	TranslationDomainEdge:{
		node:"TranslationDomain"
	},
	TranslationCataloguesIdentifierConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCataloguesIdentifierEdge"
	},
	TranslationCataloguesIdentifierEdge:{
		node:"TranslationCataloguesIdentifier"
	},
	SocialsAndAppsConnection:{
		pageInfo:"PageInfo",
		edges:"SocialsAndAppsEdge"
	},
	SocialsAndAppsEdge:{
		node:"SocialsAndApps"
	},
	ProductGridConnection:{
		pageInfo:"PageInfo",
		edges:"ProductGridEdge"
	},
	ProductGridEdge:{
		node:"ProductGrid"
	},
	GalleryConnection:{
		pageInfo:"PageInfo",
		edges:"GalleryEdge"
	},
	GalleryEdge:{
		node:"Gallery"
	},
	HeroConnection:{
		pageInfo:"PageInfo",
		edges:"HeroEdge"
	},
	HeroEdge:{
		node:"Hero"
	},
	PageConnection:{
		pageInfo:"PageInfo",
		edges:"PageEdge"
	},
	PageEdge:{
		node:"Page"
	},
	ProductBannerConnection:{
		pageInfo:"PageInfo",
		edges:"ProductBannerEdge"
	},
	ProductBannerEdge:{
		node:"ProductBanner"
	},
	ProductBannerListConnection:{
		pageInfo:"PageInfo",
		edges:"ProductBannerListEdge"
	},
	ProductBannerListEdge:{
		node:"ProductBannerList"
	},
	UserConnection:{
		pageInfo:"PageInfo",
		edges:"UserEdge"
	},
	UserEdge:{
		node:"User"
	},
	GpsConnection:{
		pageInfo:"PageInfo",
		edges:"GpsEdge"
	},
	GpsEdge:{
		node:"Gps"
	},
	WeekHoursConnection:{
		pageInfo:"PageInfo",
		edges:"WeekHoursEdge"
	},
	WeekHoursEdge:{
		node:"WeekHours"
	},
	DeliveryTimelinePresetConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryTimelinePresetEdge"
	},
	DeliveryTimelinePresetEdge:{
		node:"DeliveryTimelinePreset"
	},
	InvoicingPlanConnection:{
		pageInfo:"PageInfo",
		edges:"InvoicingPlanEdge"
	},
	InvoicingPlanEdge:{
		node:"InvoicingPlan"
	},
	InvoicingPresetConnection:{
		pageInfo:"PageInfo",
		edges:"InvoicingPresetEdge"
	},
	InvoicingPresetEdge:{
		node:"InvoicingPreset"
	},
	VirtualProductConnection:{
		pageInfo:"PageInfo",
		edges:"VirtualProductEdge"
	},
	VirtualProductEdge:{
		node:"VirtualProduct"
	},
	ProductMetadataConnection:{
		pageInfo:"PageInfo",
		edges:"ProductMetadataEdge"
	},
	ProductMetadataEdge:{
		node:"ProductMetadata"
	},
	AnonymousSessionConnection:{
		pageInfo:"PageInfo",
		edges:"AnonymousSessionEdge"
	},
	AnonymousSessionEdge:{
		node:"AnonymousSession"
	},
	TextListConnection:{
		pageInfo:"PageInfo",
		edges:"TextListEdge"
	},
	TextListEdge:{
		node:"TextList"
	},
	DeliveryRegionsConnection:{
		pageInfo:"PageInfo",
		edges:"DeliveryRegionsEdge"
	},
	DeliveryRegionsEdge:{
		node:"DeliveryRegions"
	},
	ImageListConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListEdge"
	},
	ImageListEdge:{
		node:"ImageList"
	},
	RedirectConnection:{
		pageInfo:"PageInfo",
		edges:"RedirectEdge"
	},
	RedirectEdge:{
		node:"Redirect"
	},
	WorkspaceConnection:{
		pageInfo:"PageInfo",
		edges:"WorkspaceEdge"
	},
	WorkspaceEdge:{
		node:"Workspace"
	},
	CartSummaryConnection:{
		pageInfo:"PageInfo",
		edges:"CartSummaryEdge"
	},
	CartSummaryEdge:{
		node:"CartSummary"
	},
	OrderSummaryConnection:{
		pageInfo:"PageInfo",
		edges:"OrderSummaryEdge"
	},
	OrderSummaryEdge:{
		node:"OrderSummary"
	},
	OrderPaymentMetadataConnection:{
		pageInfo:"PageInfo",
		edges:"OrderPaymentMetadataEdge"
	},
	OrderPaymentMetadataEdge:{
		node:"OrderPaymentMetadata"
	},
	UserMetadataConnection:{
		pageInfo:"PageInfo",
		edges:"UserMetadataEdge"
	},
	UserMetadataEdge:{
		node:"UserMetadata"
	},
	IdentificationCodeConnection:{
		pageInfo:"PageInfo",
		edges:"IdentificationCodeEdge"
	},
	IdentificationCodeEdge:{
		node:"IdentificationCode"
	},
	CustomerMetadataConnection:{
		pageInfo:"PageInfo",
		edges:"CustomerMetadataEdge"
	},
	CustomerMetadataEdge:{
		node:"CustomerMetadata"
	},
	SubscriptionBoxConnection:{
		pageInfo:"PageInfo",
		edges:"SubscriptionBoxEdge"
	},
	SubscriptionBoxEdge:{
		node:"SubscriptionBox"
	},
	GridTilesConnection:{
		pageInfo:"PageInfo",
		edges:"GridTilesEdge"
	},
	GridTilesEdge:{
		node:"GridTiles"
	},
	Position:{
		_meta:"PositionMeta",
		id:"UUID",
		x:"Int",
		y:"Int"
	},
	PositionMeta:{
		id:"FieldMeta",
		x:"FieldMeta",
		y:"FieldMeta"
	},
	PositionConnection:{
		pageInfo:"PageInfo",
		edges:"PositionEdge"
	},
	PositionEdge:{
		node:"Position"
	},
	StatsWidget:{
		_meta:"StatsWidgetMeta",
		id:"UUID",
		order:"Int",
		size:"Int",
		type:"StatsEnabledWidgetTypeType",
		link:"String",
		title:"String",
		timeFrame:"StatsWidgetTimeFrame",
		layout:"StatsWidgetsLayout",
		icon:"Image",
		channels:"Channel",
		productCategory:"ProductCategory",
		paymentMethods:"PaymentMethod",
		hoursFrom:"String",
		hoursTo:"String",
		paginateChannels:"ChannelConnection",
		paginatePaymentMethods:"PaymentMethodConnection"
	},
	StatsWidgetMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		link:"FieldMeta",
		title:"FieldMeta",
		timeFrame:"FieldMeta",
		layout:"FieldMeta",
		icon:"FieldMeta",
		channels:"FieldMeta",
		productCategory:"FieldMeta",
		paymentMethods:"FieldMeta",
		hoursFrom:"FieldMeta",
		hoursTo:"FieldMeta"
	},
	StatsWidgetsLayout:{
		_meta:"StatsWidgetsLayoutMeta",
		id:"UUID",
		items:"StatsWidget",
		paginateItems:"StatsWidgetConnection"
	},
	StatsWidgetsLayoutMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	StatsWidgetConnection:{
		pageInfo:"PageInfo",
		edges:"StatsWidgetEdge"
	},
	StatsWidgetEdge:{
		node:"StatsWidget"
	},
	StatsWidgetsLayoutConnection:{
		pageInfo:"PageInfo",
		edges:"StatsWidgetsLayoutEdge"
	},
	StatsWidgetsLayoutEdge:{
		node:"StatsWidgetsLayout"
	},
	StatsPage:{
		_meta:"StatsPageMeta",
		id:"UUID",
		type:"StatsPageType",
		enabled:"Boolean",
		widgets:"StatsWidgetsLayout"
	},
	StatsPageMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		enabled:"FieldMeta",
		widgets:"FieldMeta"
	},
	StatsPageConnection:{
		pageInfo:"PageInfo",
		edges:"StatsPageEdge"
	},
	StatsPageEdge:{
		node:"StatsPage"
	},
	QueryTransaction:{
		getAddress:"Address",
		listAddress:"Address",
		paginateAddress:"AddressConnection",
		validateCreateAddress:"_ValidationResult",
		validateUpdateAddress:"_ValidationResult",
		getCountry:"Country",
		listCountry:"Country",
		paginateCountry:"CountryConnection",
		getChannel:"Channel",
		listChannel:"Channel",
		paginateChannel:"ChannelConnection",
		getChannelPayment:"ChannelPayment",
		listChannelPayment:"ChannelPayment",
		paginateChannelPayment:"ChannelPaymentConnection",
		getChannelDelivery:"ChannelDelivery",
		listChannelDelivery:"ChannelDelivery",
		paginateChannelDelivery:"ChannelDeliveryConnection",
		getCustomer:"Customer",
		listCustomer:"Customer",
		paginateCustomer:"CustomerConnection",
		validateUpdateCustomer:"_ValidationResult",
		getCustomerCredit:"CustomerCredit",
		listCustomerCredit:"CustomerCredit",
		paginateCustomerCredit:"CustomerCreditConnection",
		getCustomerGroup:"CustomerGroup",
		listCustomerGroup:"CustomerGroup",
		paginateCustomerGroup:"CustomerGroupConnection",
		getDeliveryMethod:"DeliveryMethod",
		listDeliveryMethod:"DeliveryMethod",
		paginateDeliveryMethod:"DeliveryMethodConnection",
		getOrder:"Order",
		listOrder:"Order",
		paginateOrder:"OrderConnection",
		validateCreateOrder:"_ValidationResult",
		getOrderDiscount:"OrderDiscount",
		listOrderDiscount:"OrderDiscount",
		paginateOrderDiscount:"OrderDiscountConnection",
		getOrderPayment:"OrderPayment",
		listOrderPayment:"OrderPayment",
		paginateOrderPayment:"OrderPaymentConnection",
		getOrderDelivery:"OrderDelivery",
		listOrderDelivery:"OrderDelivery",
		paginateOrderDelivery:"OrderDeliveryConnection",
		validateCreateOrderDelivery:"_ValidationResult",
		getOrderItem:"OrderItem",
		listOrderItem:"OrderItem",
		paginateOrderItem:"OrderItemConnection",
		validateCreateOrderItem:"_ValidationResult",
		getPaymentMethod:"PaymentMethod",
		listPaymentMethod:"PaymentMethod",
		paginatePaymentMethod:"PaymentMethodConnection",
		getVatRate:"VatRate",
		listVatRate:"VatRate",
		paginateVatRate:"VatRateConnection",
		getProduct:"Product",
		listProduct:"Product",
		paginateProduct:"ProductConnection",
		getProductStock:"ProductStock",
		listProductStock:"ProductStock",
		paginateProductStock:"ProductStockConnection",
		getProductPacking:"ProductPacking",
		listProductPacking:"ProductPacking",
		paginateProductPacking:"ProductPackingConnection",
		getProductGroupPrice:"ProductGroupPrice",
		listProductGroupPrice:"ProductGroupPrice",
		paginateProductGroupPrice:"ProductGroupPriceConnection",
		getProductRecipe:"ProductRecipe",
		listProductRecipe:"ProductRecipe",
		paginateProductRecipe:"ProductRecipeConnection",
		getProductCategory:"ProductCategory",
		listProductCategory:"ProductCategory",
		paginateProductCategory:"ProductCategoryConnection",
		getStore:"Store",
		listStore:"Store",
		paginateStore:"StoreConnection",
		getTag:"Tag",
		listTag:"Tag",
		paginateTag:"TagConnection",
		getCustomerPermissions:"CustomerPermissions",
		listCustomerPermissions:"CustomerPermissions",
		paginateCustomerPermissions:"CustomerPermissionsConnection",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getBusinessCategory:"BusinessCategory",
		listBusinessCategory:"BusinessCategory",
		paginateBusinessCategory:"BusinessCategoryConnection",
		getProductList:"ProductList",
		listProductList:"ProductList",
		paginateProductList:"ProductListConnection",
		getProductListItem:"ProductListItem",
		listProductListItem:"ProductListItem",
		paginateProductListItem:"ProductListItemConnection",
		getProductCategoryList:"ProductCategoryList",
		listProductCategoryList:"ProductCategoryList",
		paginateProductCategoryList:"ProductCategoryListConnection",
		getProductCategoryListItem:"ProductCategoryListItem",
		listProductCategoryListItem:"ProductCategoryListItem",
		paginateProductCategoryListItem:"ProductCategoryListItemConnection",
		getBusinessCategoryLocale:"BusinessCategoryLocale",
		listBusinessCategoryLocale:"BusinessCategoryLocale",
		paginateBusinessCategoryLocale:"BusinessCategoryLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getProductCategoryListLocale:"ProductCategoryListLocale",
		listProductCategoryListLocale:"ProductCategoryListLocale",
		paginateProductCategoryListLocale:"ProductCategoryListLocaleConnection",
		getCustomerName:"CustomerName",
		listCustomerName:"CustomerName",
		paginateCustomerName:"CustomerNameConnection",
		validateCreateCustomerName:"_ValidationResult",
		validateUpdateCustomerName:"_ValidationResult",
		getProductIngredientItem:"ProductIngredientItem",
		listProductIngredientItem:"ProductIngredientItem",
		paginateProductIngredientItem:"ProductIngredientItemConnection",
		getProductIngredient:"ProductIngredient",
		listProductIngredient:"ProductIngredient",
		paginateProductIngredient:"ProductIngredientConnection",
		getCustomerIngredientRating:"CustomerIngredientRating",
		listCustomerIngredientRating:"CustomerIngredientRating",
		paginateCustomerIngredientRating:"CustomerIngredientRatingConnection",
		validateCreateCustomerIngredientRating:"_ValidationResult",
		validateUpdateCustomerIngredientRating:"_ValidationResult",
		getProductIngredientRating:"ProductIngredientRating",
		listProductIngredientRating:"ProductIngredientRating",
		paginateProductIngredientRating:"ProductIngredientRatingConnection",
		getProductIngredientLocale:"ProductIngredientLocale",
		listProductIngredientLocale:"ProductIngredientLocale",
		paginateProductIngredientLocale:"ProductIngredientLocaleConnection",
		getOrderRecurrence:"OrderRecurrence",
		listOrderRecurrence:"OrderRecurrence",
		paginateOrderRecurrence:"OrderRecurrenceConnection",
		getOrderRecurrenceLocale:"OrderRecurrenceLocale",
		listOrderRecurrenceLocale:"OrderRecurrenceLocale",
		paginateOrderRecurrenceLocale:"OrderRecurrenceLocaleConnection",
		getBusinessCustomer:"BusinessCustomer",
		listBusinessCustomer:"BusinessCustomer",
		paginateBusinessCustomer:"BusinessCustomerConnection",
		getStaff:"Staff",
		listStaff:"Staff",
		paginateStaff:"StaffConnection",
		getBlog:"Blog",
		listBlog:"Blog",
		paginateBlog:"BlogConnection",
		getBlogLocale:"BlogLocale",
		listBlogLocale:"BlogLocale",
		paginateBlogLocale:"BlogLocaleConnection",
		getBlogPost:"BlogPost",
		listBlogPost:"BlogPost",
		paginateBlogPost:"BlogPostConnection",
		getBlogPostLocale:"BlogPostLocale",
		listBlogPostLocale:"BlogPostLocale",
		paginateBlogPostLocale:"BlogPostLocaleConnection",
		getColorPallete:"ColorPallete",
		listColorPallete:"ColorPallete",
		paginateColorPallete:"ColorPalleteConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getBlogPostList:"BlogPostList",
		listBlogPostList:"BlogPostList",
		paginateBlogPostList:"BlogPostListConnection",
		getBlogPostListItem:"BlogPostListItem",
		listBlogPostListItem:"BlogPostListItem",
		paginateBlogPostListItem:"BlogPostListItemConnection",
		getLinkList:"LinkList",
		listLinkList:"LinkList",
		paginateLinkList:"LinkListConnection",
		getLinkListItem:"LinkListItem",
		listLinkListItem:"LinkListItem",
		paginateLinkListItem:"LinkListItemConnection",
		getCover:"Cover",
		listCover:"Cover",
		paginateCover:"CoverConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getMedium:"Medium",
		listMedium:"Medium",
		paginateMedium:"MediumConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		getTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		listTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		paginateTranslationCataloguesIdentifier:"TranslationCataloguesIdentifierConnection",
		getSocialsAndApps:"SocialsAndApps",
		listSocialsAndApps:"SocialsAndApps",
		paginateSocialsAndApps:"SocialsAndAppsConnection",
		getProductGrid:"ProductGrid",
		listProductGrid:"ProductGrid",
		paginateProductGrid:"ProductGridConnection",
		getProductGridItem:"ProductGridItem",
		listProductGridItem:"ProductGridItem",
		paginateProductGridItem:"ProductGridItemConnection",
		getGallery:"Gallery",
		listGallery:"Gallery",
		paginateGallery:"GalleryConnection",
		getGalleryItem:"GalleryItem",
		listGalleryItem:"GalleryItem",
		paginateGalleryItem:"GalleryItemConnection",
		getHero:"Hero",
		listHero:"Hero",
		paginateHero:"HeroConnection",
		getPage:"Page",
		listPage:"Page",
		paginatePage:"PageConnection",
		getPageLocale:"PageLocale",
		listPageLocale:"PageLocale",
		paginatePageLocale:"PageLocaleConnection",
		getCart:"Cart",
		listCart:"Cart",
		paginateCart:"CartConnection",
		validateCreateCart:"_ValidationResult",
		getCartItem:"CartItem",
		listCartItem:"CartItem",
		paginateCartItem:"CartItemConnection",
		validateCreateCartItem:"_ValidationResult",
		validateUpdateCartItem:"_ValidationResult",
		getProductBanner:"ProductBanner",
		listProductBanner:"ProductBanner",
		paginateProductBanner:"ProductBannerConnection",
		getProductBannerItem:"ProductBannerItem",
		listProductBannerItem:"ProductBannerItem",
		paginateProductBannerItem:"ProductBannerItemConnection",
		getProductBannerList:"ProductBannerList",
		listProductBannerList:"ProductBannerList",
		paginateProductBannerList:"ProductBannerListConnection",
		getUser:"User",
		listUser:"User",
		paginateUser:"UserConnection",
		getAllergen:"Allergen",
		listAllergen:"Allergen",
		paginateAllergen:"AllergenConnection",
		getAllergenLocale:"AllergenLocale",
		listAllergenLocale:"AllergenLocale",
		paginateAllergenLocale:"AllergenLocaleConnection",
		getGps:"Gps",
		listGps:"Gps",
		paginateGps:"GpsConnection",
		validateCreateGps:"_ValidationResult",
		validateUpdateGps:"_ValidationResult",
		getWeekHours:"WeekHours",
		listWeekHours:"WeekHours",
		paginateWeekHours:"WeekHoursConnection",
		getDeliveryZone:"DeliveryZone",
		listDeliveryZone:"DeliveryZone",
		paginateDeliveryZone:"DeliveryZoneConnection",
		getAddressMetadata:"AddressMetadata",
		listAddressMetadata:"AddressMetadata",
		paginateAddressMetadata:"AddressMetadataConnection",
		getDeliveryTimelinePreset:"DeliveryTimelinePreset",
		listDeliveryTimelinePreset:"DeliveryTimelinePreset",
		paginateDeliveryTimelinePreset:"DeliveryTimelinePresetConnection",
		getInvoice:"Invoice",
		listInvoice:"Invoice",
		paginateInvoice:"InvoiceConnection",
		getInvoicingPlan:"InvoicingPlan",
		listInvoicingPlan:"InvoicingPlan",
		paginateInvoicingPlan:"InvoicingPlanConnection",
		getProductPackingLocale:"ProductPackingLocale",
		listProductPackingLocale:"ProductPackingLocale",
		paginateProductPackingLocale:"ProductPackingLocaleConnection",
		getProductLocale:"ProductLocale",
		listProductLocale:"ProductLocale",
		paginateProductLocale:"ProductLocaleConnection",
		getProductRecipeLocale:"ProductRecipeLocale",
		listProductRecipeLocale:"ProductRecipeLocale",
		paginateProductRecipeLocale:"ProductRecipeLocaleConnection",
		getInvoicingPreset:"InvoicingPreset",
		listInvoicingPreset:"InvoicingPreset",
		paginateInvoicingPreset:"InvoicingPresetConnection",
		getBillingSubject:"BillingSubject",
		listBillingSubject:"BillingSubject",
		paginateBillingSubject:"BillingSubjectConnection",
		validateCreateBillingSubject:"_ValidationResult",
		validateUpdateBillingSubject:"_ValidationResult",
		getProductCustomerPrice:"ProductCustomerPrice",
		listProductCustomerPrice:"ProductCustomerPrice",
		paginateProductCustomerPrice:"ProductCustomerPriceConnection",
		getCartPriceLine:"CartPriceLine",
		listCartPriceLine:"CartPriceLine",
		paginateCartPriceLine:"CartPriceLineConnection",
		getVirtualProduct:"VirtualProduct",
		listVirtualProduct:"VirtualProduct",
		paginateVirtualProduct:"VirtualProductConnection",
		getVirtualProductEffect:"VirtualProductEffect",
		listVirtualProductEffect:"VirtualProductEffect",
		paginateVirtualProductEffect:"VirtualProductEffectConnection",
		getOrderPriceLine:"OrderPriceLine",
		listOrderPriceLine:"OrderPriceLine",
		paginateOrderPriceLine:"OrderPriceLineConnection",
		getProductMetadata:"ProductMetadata",
		listProductMetadata:"ProductMetadata",
		paginateProductMetadata:"ProductMetadataConnection",
		getProductGridCategory:"ProductGridCategory",
		listProductGridCategory:"ProductGridCategory",
		paginateProductGridCategory:"ProductGridCategoryConnection",
		getAnonymousSession:"AnonymousSession",
		listAnonymousSession:"AnonymousSession",
		paginateAnonymousSession:"AnonymousSessionConnection",
		validateCreateAnonymousSession:"_ValidationResult",
		validateUpdateAnonymousSession:"_ValidationResult",
		getVoucher:"Voucher",
		listVoucher:"Voucher",
		paginateVoucher:"VoucherConnection",
		getVoucherRedemption:"VoucherRedemption",
		listVoucherRedemption:"VoucherRedemption",
		paginateVoucherRedemption:"VoucherRedemptionConnection",
		getFreshingContainer:"FreshingContainer",
		listFreshingContainer:"FreshingContainer",
		paginateFreshingContainer:"FreshingContainerConnection",
		getTextItem:"TextItem",
		listTextItem:"TextItem",
		paginateTextItem:"TextItemConnection",
		getTextList:"TextList",
		listTextList:"TextList",
		paginateTextList:"TextListConnection",
		getDeliveryRegions:"DeliveryRegions",
		listDeliveryRegions:"DeliveryRegions",
		paginateDeliveryRegions:"DeliveryRegionsConnection",
		getDeliveryRegionsItem:"DeliveryRegionsItem",
		listDeliveryRegionsItem:"DeliveryRegionsItem",
		paginateDeliveryRegionsItem:"DeliveryRegionsItemConnection",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getDeliveryMethodLocale:"DeliveryMethodLocale",
		listDeliveryMethodLocale:"DeliveryMethodLocale",
		paginateDeliveryMethodLocale:"DeliveryMethodLocaleConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getWorkspace:"Workspace",
		listWorkspace:"Workspace",
		paginateWorkspace:"WorkspaceConnection",
		getCartSummary:"CartSummary",
		listCartSummary:"CartSummary",
		paginateCartSummary:"CartSummaryConnection",
		getOrderSummary:"OrderSummary",
		listOrderSummary:"OrderSummary",
		paginateOrderSummary:"OrderSummaryConnection",
		getOrderPaymentMetadata:"OrderPaymentMetadata",
		listOrderPaymentMetadata:"OrderPaymentMetadata",
		paginateOrderPaymentMetadata:"OrderPaymentMetadataConnection",
		getUserMetadata:"UserMetadata",
		listUserMetadata:"UserMetadata",
		paginateUserMetadata:"UserMetadataConnection",
		validateCreateIdentificationAttempt:"_ValidationResult",
		getIdentificationCode:"IdentificationCode",
		listIdentificationCode:"IdentificationCode",
		paginateIdentificationCode:"IdentificationCodeConnection",
		getIdentificationCodeLocale:"IdentificationCodeLocale",
		listIdentificationCodeLocale:"IdentificationCodeLocale",
		paginateIdentificationCodeLocale:"IdentificationCodeLocaleConnection",
		getFulfillmentNote:"FulfillmentNote",
		listFulfillmentNote:"FulfillmentNote",
		paginateFulfillmentNote:"FulfillmentNoteConnection",
		getCustomerMetadata:"CustomerMetadata",
		listCustomerMetadata:"CustomerMetadata",
		paginateCustomerMetadata:"CustomerMetadataConnection",
		getSubscriptionBox:"SubscriptionBox",
		listSubscriptionBox:"SubscriptionBox",
		paginateSubscriptionBox:"SubscriptionBoxConnection",
		getSubscriptionBoxLocale:"SubscriptionBoxLocale",
		listSubscriptionBoxLocale:"SubscriptionBoxLocale",
		paginateSubscriptionBoxLocale:"SubscriptionBoxLocaleConnection",
		getGridTileItem:"GridTileItem",
		listGridTileItem:"GridTileItem",
		paginateGridTileItem:"GridTileItemConnection",
		getGridTiles:"GridTiles",
		listGridTiles:"GridTiles",
		paginateGridTiles:"GridTilesConnection",
		getPosition:"Position",
		listPosition:"Position",
		paginatePosition:"PositionConnection",
		getStatsWidget:"StatsWidget",
		listStatsWidget:"StatsWidget",
		paginateStatsWidget:"StatsWidgetConnection",
		getStatsWidgetsLayout:"StatsWidgetsLayout",
		listStatsWidgetsLayout:"StatsWidgetsLayout",
		paginateStatsWidgetsLayout:"StatsWidgetsLayoutConnection",
		getStatsPage:"StatsPage",
		listStatsPage:"StatsPage",
		paginateStatsPage:"StatsPageConnection"
	},
	Info:{
		description:"String"
	},
	Mutation:{
		createAddress:"AddressCreateResult",
		deleteAddress:"AddressDeleteResult",
		updateAddress:"AddressUpdateResult",
		upsertAddress:"AddressUpsertResult",
		updateCustomer:"CustomerUpdateResult",
		createOrder:"OrderCreateResult",
		createOrderDelivery:"OrderDeliveryCreateResult",
		createOrderItem:"OrderItemCreateResult",
		createCustomerName:"CustomerNameCreateResult",
		updateCustomerName:"CustomerNameUpdateResult",
		upsertCustomerName:"CustomerNameUpsertResult",
		createCustomerIngredientRating:"CustomerIngredientRatingCreateResult",
		deleteCustomerIngredientRating:"CustomerIngredientRatingDeleteResult",
		updateCustomerIngredientRating:"CustomerIngredientRatingUpdateResult",
		upsertCustomerIngredientRating:"CustomerIngredientRatingUpsertResult",
		createCart:"CartCreateResult",
		createCartItem:"CartItemCreateResult",
		deleteCartItem:"CartItemDeleteResult",
		updateCartItem:"CartItemUpdateResult",
		upsertCartItem:"CartItemUpsertResult",
		createGps:"GpsCreateResult",
		updateGps:"GpsUpdateResult",
		upsertGps:"GpsUpsertResult",
		createBillingSubject:"BillingSubjectCreateResult",
		updateBillingSubject:"BillingSubjectUpdateResult",
		upsertBillingSubject:"BillingSubjectUpsertResult",
		createAnonymousSession:"AnonymousSessionCreateResult",
		deleteAnonymousSession:"AnonymousSessionDeleteResult",
		updateAnonymousSession:"AnonymousSessionUpdateResult",
		upsertAnonymousSession:"AnonymousSessionUpsertResult",
		createIdentificationAttempt:"IdentificationAttemptCreateResult",
		transaction:"MutationTransaction",
		query:"Query"
	},
	AddressCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Address",
		validation:"_ValidationResult"
	},
	MutationResult:{
		"...on AddressCreateResult": "AddressCreateResult",
		"...on AddressDeleteResult": "AddressDeleteResult",
		"...on AddressUpdateResult": "AddressUpdateResult",
		"...on AddressUpsertResult": "AddressUpsertResult",
		"...on CustomerUpdateResult": "CustomerUpdateResult",
		"...on OrderCreateResult": "OrderCreateResult",
		"...on OrderDeliveryCreateResult": "OrderDeliveryCreateResult",
		"...on OrderItemCreateResult": "OrderItemCreateResult",
		"...on CustomerNameCreateResult": "CustomerNameCreateResult",
		"...on CustomerNameUpdateResult": "CustomerNameUpdateResult",
		"...on CustomerNameUpsertResult": "CustomerNameUpsertResult",
		"...on CustomerIngredientRatingCreateResult": "CustomerIngredientRatingCreateResult",
		"...on CustomerIngredientRatingDeleteResult": "CustomerIngredientRatingDeleteResult",
		"...on CustomerIngredientRatingUpdateResult": "CustomerIngredientRatingUpdateResult",
		"...on CustomerIngredientRatingUpsertResult": "CustomerIngredientRatingUpsertResult",
		"...on CartCreateResult": "CartCreateResult",
		"...on CartItemCreateResult": "CartItemCreateResult",
		"...on CartItemDeleteResult": "CartItemDeleteResult",
		"...on CartItemUpdateResult": "CartItemUpdateResult",
		"...on CartItemUpsertResult": "CartItemUpsertResult",
		"...on GpsCreateResult": "GpsCreateResult",
		"...on GpsUpdateResult": "GpsUpdateResult",
		"...on GpsUpsertResult": "GpsUpsertResult",
		"...on BillingSubjectCreateResult": "BillingSubjectCreateResult",
		"...on BillingSubjectUpdateResult": "BillingSubjectUpdateResult",
		"...on BillingSubjectUpsertResult": "BillingSubjectUpsertResult",
		"...on AnonymousSessionCreateResult": "AnonymousSessionCreateResult",
		"...on AnonymousSessionDeleteResult": "AnonymousSessionDeleteResult",
		"...on AnonymousSessionUpdateResult": "AnonymousSessionUpdateResult",
		"...on AnonymousSessionUpsertResult": "AnonymousSessionUpsertResult",
		"...on IdentificationAttemptCreateResult": "IdentificationAttemptCreateResult",
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError"
	},
	_MutationError:{
		path:"_PathFragment",
		paths:"_PathFragment",
		type:"_MutationErrorType",
		message:"String"
	},
	AddressDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Address"
	},
	AddressUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Address",
		validation:"_ValidationResult"
	},
	AddressUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Address",
		validation:"_ValidationResult"
	},
	CustomerUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Customer",
		validation:"_ValidationResult"
	},
	OrderCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Order",
		validation:"_ValidationResult"
	},
	OrderDeliveryCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"OrderDelivery",
		validation:"_ValidationResult"
	},
	OrderItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"OrderItem",
		validation:"_ValidationResult"
	},
	CustomerNameCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CustomerName",
		validation:"_ValidationResult"
	},
	CustomerNameUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CustomerName",
		validation:"_ValidationResult"
	},
	CustomerNameUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CustomerName",
		validation:"_ValidationResult"
	},
	CustomerIngredientRatingCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CustomerIngredientRating",
		validation:"_ValidationResult"
	},
	CustomerIngredientRatingDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CustomerIngredientRating"
	},
	CustomerIngredientRatingUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CustomerIngredientRating",
		validation:"_ValidationResult"
	},
	CustomerIngredientRatingUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CustomerIngredientRating",
		validation:"_ValidationResult"
	},
	CartCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Cart",
		validation:"_ValidationResult"
	},
	CartItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CartItem",
		validation:"_ValidationResult"
	},
	CartItemDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CartItem"
	},
	CartItemUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CartItem",
		validation:"_ValidationResult"
	},
	CartItemUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"CartItem",
		validation:"_ValidationResult"
	},
	GpsCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Gps",
		validation:"_ValidationResult"
	},
	GpsUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Gps",
		validation:"_ValidationResult"
	},
	GpsUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Gps",
		validation:"_ValidationResult"
	},
	BillingSubjectCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"BillingSubject",
		validation:"_ValidationResult"
	},
	BillingSubjectUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"BillingSubject",
		validation:"_ValidationResult"
	},
	BillingSubjectUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"BillingSubject",
		validation:"_ValidationResult"
	},
	AnonymousSessionCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AnonymousSession",
		validation:"_ValidationResult"
	},
	AnonymousSessionDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AnonymousSession"
	},
	AnonymousSessionUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AnonymousSession",
		validation:"_ValidationResult"
	},
	AnonymousSessionUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AnonymousSession",
		validation:"_ValidationResult"
	},
	IdentificationAttemptCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult"
	},
	MutationTransaction:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult",
		createAddress:"AddressCreateResult",
		deleteAddress:"AddressDeleteResult",
		updateAddress:"AddressUpdateResult",
		upsertAddress:"AddressUpsertResult",
		updateCustomer:"CustomerUpdateResult",
		createOrder:"OrderCreateResult",
		createOrderDelivery:"OrderDeliveryCreateResult",
		createOrderItem:"OrderItemCreateResult",
		createCustomerName:"CustomerNameCreateResult",
		updateCustomerName:"CustomerNameUpdateResult",
		upsertCustomerName:"CustomerNameUpsertResult",
		createCustomerIngredientRating:"CustomerIngredientRatingCreateResult",
		deleteCustomerIngredientRating:"CustomerIngredientRatingDeleteResult",
		updateCustomerIngredientRating:"CustomerIngredientRatingUpdateResult",
		upsertCustomerIngredientRating:"CustomerIngredientRatingUpsertResult",
		createCart:"CartCreateResult",
		createCartItem:"CartItemCreateResult",
		deleteCartItem:"CartItemDeleteResult",
		updateCartItem:"CartItemUpdateResult",
		upsertCartItem:"CartItemUpsertResult",
		createGps:"GpsCreateResult",
		updateGps:"GpsUpdateResult",
		upsertGps:"GpsUpsertResult",
		createBillingSubject:"BillingSubjectCreateResult",
		updateBillingSubject:"BillingSubjectUpdateResult",
		upsertBillingSubject:"BillingSubjectUpsertResult",
		createAnonymousSession:"AnonymousSessionCreateResult",
		deleteAnonymousSession:"AnonymousSessionDeleteResult",
		updateAnonymousSession:"AnonymousSessionUpdateResult",
		upsertAnonymousSession:"AnonymousSessionUpsertResult",
		createIdentificationAttempt:"IdentificationAttemptCreateResult",
		query:"Query"
	},
	_Schema:{
		enums:"_Enum",
		entities:"_Entity"
	},
	_Entity:{
		name:"String",
		customPrimaryAllowed:"Boolean",
		fields:"_Field",
		unique:"_UniqueConstraint"
	},
	_UniqueConstraint:{
		fields:"String"
	},
	_Field:{
		"...on _Column": "_Column",
		"...on _Relation": "_Relation",
		name:"String",
		type:"String",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Column:{
		name:"String",
		type:"String",
		enumName:"String",
		defaultValue:"Json",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_OrderBy:{
		path:"String",
		direction:"_OrderByDirection"
	},
	_Relation:{
		name:"String",
		type:"String",
		side:"_RelationSide",
		targetEntity:"String",
		ownedBy:"String",
		inversedBy:"String",
		nullable:"Boolean",
		onDelete:"_OnDeleteBehaviour",
		orphanRemoval:"Boolean",
		orderBy:"_OrderBy",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Rule:{
		message:"_RuleMessage",
		validator:"Int"
	},
	_Validator:{
		operation:"String",
		arguments:"_Argument"
	},
	_Argument:{
		"...on _ValidatorArgument":"_ValidatorArgument",
		"...on _PathArgument":"_PathArgument",
		"...on _LiteralArgument":"_LiteralArgument"
	},
	_ValidatorArgument:{
		validator:"Int"
	},
	_PathArgument:{
		path:"String"
	},
	_LiteralArgument:{
		value:"Json"
	},
	_RuleMessage:{
		text:"String"
	},
	_Enum:{
		name:"String",
		values:"String"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}


export const HOST="Specify host"



const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, unknown> = {}) => {
  const fetchOptions = options[1] || {};
  if (fetchOptions.method && fetchOptions.method === 'GET') {
    return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  }
  return fetch(`${options[0]}`, {
    body: JSON.stringify({ query, variables }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...fetchOptions,
  })
    .then(handleFetchResponse)
    .then((response: GraphQLResponse) => {
      if (response.errors) {
        throw new GraphQLError(response);
      }
      return response.data;
    });
};




export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};






export const InternalsBuildQuery = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  options?: OperationOptions,
) => {
  const ibb = (k: string, o: InputValueType | VType, p = '', root = true): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt(props, returns, ops, options?.variables?.values)(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const hasVariables = root && options?.variables?.$params ? `(${options.variables?.$params})` : '';
    const keyForDirectives = o.__directives ?? '';
    return `${k} ${keyForDirectives}${hasOperationName}${hasVariables}{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false))
      .join('\n')}}`;
  };
  return ibb;
};










export const Thunder = (fn: FetchFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation)(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullSubscriptionConstruct(fn)(operation)(o as any, ops) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: OperationOptions,
) => InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, ops)(operation, o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();

export const Gql = Chain(HOST);






export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o), options?.variables?.values);
};






export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o));
};





export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | boolean
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string | undefined>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions<Z extends Record<string, unknown> = Record<string, unknown>> = {
  variables?: VariableInput<Z>;
  operationName?: string;
};

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;


export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');




const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (typeof propsP1 === 'boolean' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'object') {
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  variables?: Record<string, unknown>,
) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (a.startsWith('$') && variables?.[a.slice(1)]) {
        return a;
      }
      const checkType = ResolveFromPath(props, returns, ops)(p);
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};




export const resolverFor = <X, T extends keyof ValueTypes, Z extends keyof ValueTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;


export type SelectionFunction<V> = <T>(t: T | V) => T;
export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;




export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : Record<string, unknown>
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>[keyof MapType<SRC, R[P]>];
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};


export const useZeusVariables =
  <T extends Record<string, unknown>>(variables: T) =>
  <
    Z extends {
      [P in keyof T]: unknown;
    },
  >(
    values: Z,
  ) => {
    return {
      $params: Object.keys(variables)
        .map((k) => `$${k}: ${variables[k as keyof T]}`)
        .join(', '),
      $: <U extends keyof Z>(variable: U) => {
        return `$${String(variable)}` as unknown as Z[U];
      },
      values,
    };
  };

export type VariableInput<Z extends Record<string, unknown>> = {
  $params: ReturnType<ReturnType<typeof useZeusVariables>>['$params'];
  values: Z;
};


type ZEUS_INTERFACES = GraphQLTypes["MutationResult"] | GraphQLTypes["_Field"]
type ZEUS_UNIONS = GraphQLTypes["_PathFragment"] | GraphQLTypes["_Argument"]

export type ValueTypes = {
    ["Query"]: AliasType<{
getAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
listAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Address"]],
paginateAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressConnection"]],
validateCreateAddress?: [{	data: ValueTypes["AddressCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	data: ValueTypes["AddressUpdateInput"]},ValueTypes["_ValidationResult"]],
getCountry?: [{	by: ValueTypes["CountryUniqueWhere"],	filter?: ValueTypes["CountryWhere"] | undefined | null},ValueTypes["Country"]],
listCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Country"]],
paginateCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CountryConnection"]],
getChannel?: [{	by: ValueTypes["ChannelUniqueWhere"],	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
listChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
paginateChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
getChannelPayment?: [{	by: ValueTypes["ChannelPaymentUniqueWhere"],	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null},ValueTypes["ChannelPayment"]],
listChannelPayment?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelPayment"]],
paginateChannelPayment?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelPaymentConnection"]],
getChannelDelivery?: [{	by: ValueTypes["ChannelDeliveryUniqueWhere"],	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null},ValueTypes["ChannelDelivery"]],
listChannelDelivery?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelDelivery"]],
paginateChannelDelivery?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelDeliveryConnection"]],
getCustomer?: [{	by: ValueTypes["CustomerUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
listCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
paginateCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
validateUpdateCustomer?: [{	by: ValueTypes["CustomerUniqueWhere"],	data: ValueTypes["CustomerUpdateInput"]},ValueTypes["_ValidationResult"]],
getCustomerCredit?: [{	by: ValueTypes["CustomerCreditUniqueWhere"],	filter?: ValueTypes["CustomerCreditWhere"] | undefined | null},ValueTypes["CustomerCredit"]],
listCustomerCredit?: [{	filter?: ValueTypes["CustomerCreditWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerCreditOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerCredit"]],
paginateCustomerCredit?: [{	filter?: ValueTypes["CustomerCreditWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerCreditOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerCreditConnection"]],
getCustomerGroup?: [{	by: ValueTypes["CustomerGroupUniqueWhere"],	filter?: ValueTypes["CustomerGroupWhere"] | undefined | null},ValueTypes["CustomerGroup"]],
listCustomerGroup?: [{	filter?: ValueTypes["CustomerGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerGroupOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerGroup"]],
paginateCustomerGroup?: [{	filter?: ValueTypes["CustomerGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerGroupOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerGroupConnection"]],
getDeliveryMethod?: [{	by: ValueTypes["DeliveryMethodUniqueWhere"],	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
listDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
paginateDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
getOrder?: [{	by: ValueTypes["OrderUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
listOrder?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
paginateOrder?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
validateCreateOrder?: [{	data: ValueTypes["OrderCreateInput"]},ValueTypes["_ValidationResult"]],
getOrderDiscount?: [{	by: ValueTypes["OrderDiscountUniqueWhere"],	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null},ValueTypes["OrderDiscount"]],
listOrderDiscount?: [{	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDiscountOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDiscount"]],
paginateOrderDiscount?: [{	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDiscountOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDiscountConnection"]],
getOrderPayment?: [{	by: ValueTypes["OrderPaymentUniqueWhere"],	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
listOrderPayment?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPayment"]],
paginateOrderPayment?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentConnection"]],
getOrderDelivery?: [{	by: ValueTypes["OrderDeliveryUniqueWhere"],	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
listOrderDelivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDelivery"]],
paginateOrderDelivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDeliveryConnection"]],
validateCreateOrderDelivery?: [{	data: ValueTypes["OrderDeliveryCreateInput"]},ValueTypes["_ValidationResult"]],
getOrderItem?: [{	by: ValueTypes["OrderItemUniqueWhere"],	filter?: ValueTypes["OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
listOrderItem?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
paginateOrderItem?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
validateCreateOrderItem?: [{	data: ValueTypes["OrderItemCreateInput"]},ValueTypes["_ValidationResult"]],
getPaymentMethod?: [{	by: ValueTypes["PaymentMethodUniqueWhere"],	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
listPaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
paginatePaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
getVatRate?: [{	by: ValueTypes["VatRateUniqueWhere"],	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
listVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VatRate"]],
paginateVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VatRateConnection"]],
getProduct?: [{	by: ValueTypes["ProductUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
listProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
getProductStock?: [{	by: ValueTypes["ProductStockUniqueWhere"],	filter?: ValueTypes["ProductStockWhere"] | undefined | null},ValueTypes["ProductStock"]],
listProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductStock"]],
paginateProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductStockConnection"]],
getProductPacking?: [{	by: ValueTypes["ProductPackingUniqueWhere"],	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
listProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPacking"]],
paginateProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingConnection"]],
getProductGroupPrice?: [{	by: ValueTypes["ProductGroupPriceUniqueWhere"],	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null},ValueTypes["ProductGroupPrice"]],
listProductGroupPrice?: [{	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGroupPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGroupPrice"]],
paginateProductGroupPrice?: [{	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGroupPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGroupPriceConnection"]],
getProductRecipe?: [{	by: ValueTypes["ProductRecipeUniqueWhere"],	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
listProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipe"]],
paginateProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeConnection"]],
getProductCategory?: [{	by: ValueTypes["ProductCategoryUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
listProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
paginateProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
getStore?: [{	by: ValueTypes["StoreUniqueWhere"],	filter?: ValueTypes["StoreWhere"] | undefined | null},ValueTypes["Store"]],
listStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Store"]],
paginateStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StoreConnection"]],
getTag?: [{	by: ValueTypes["TagUniqueWhere"],	filter?: ValueTypes["TagWhere"] | undefined | null},ValueTypes["Tag"]],
listTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
paginateTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
getCustomerPermissions?: [{	by: ValueTypes["CustomerPermissionsUniqueWhere"],	filter?: ValueTypes["CustomerPermissionsWhere"] | undefined | null},ValueTypes["CustomerPermissions"]],
listCustomerPermissions?: [{	filter?: ValueTypes["CustomerPermissionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerPermissionsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerPermissions"]],
paginateCustomerPermissions?: [{	filter?: ValueTypes["CustomerPermissionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerPermissionsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerPermissionsConnection"]],
getImage?: [{	by: ValueTypes["ImageUniqueWhere"],	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageConnection"]],
getBusinessCategory?: [{	by: ValueTypes["BusinessCategoryUniqueWhere"],	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
listBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategory"]],
paginateBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryConnection"]],
getProductList?: [{	by: ValueTypes["ProductListUniqueWhere"],	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
listProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductList"]],
paginateProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListConnection"]],
getProductListItem?: [{	by: ValueTypes["ProductListItemUniqueWhere"],	filter?: ValueTypes["ProductListItemWhere"] | undefined | null},ValueTypes["ProductListItem"]],
listProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductListItem"]],
paginateProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListItemConnection"]],
getProductCategoryList?: [{	by: ValueTypes["ProductCategoryListUniqueWhere"],	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
listProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryList"]],
paginateProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListConnection"]],
getProductCategoryListItem?: [{	by: ValueTypes["ProductCategoryListItemUniqueWhere"],	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null},ValueTypes["ProductCategoryListItem"]],
listProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListItem"]],
paginateProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListItemConnection"]],
getBusinessCategoryLocale?: [{	by: ValueTypes["BusinessCategoryLocaleUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
listBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
paginateBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"],	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LocaleConnection"]],
getProductCategoryListLocale?: [{	by: ValueTypes["ProductCategoryListLocaleUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
listProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
paginateProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
getCustomerName?: [{	by: ValueTypes["CustomerNameUniqueWhere"],	filter?: ValueTypes["CustomerNameWhere"] | undefined | null},ValueTypes["CustomerName"]],
listCustomerName?: [{	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerNameOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerName"]],
paginateCustomerName?: [{	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerNameOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerNameConnection"]],
validateCreateCustomerName?: [{	data: ValueTypes["CustomerNameCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateCustomerName?: [{	by: ValueTypes["CustomerNameUniqueWhere"],	data: ValueTypes["CustomerNameUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductIngredientItem?: [{	by: ValueTypes["ProductIngredientItemUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null},ValueTypes["ProductIngredientItem"]],
listProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItem"]],
paginateProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemConnection"]],
getProductIngredient?: [{	by: ValueTypes["ProductIngredientUniqueWhere"],	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
listProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredient"]],
paginateProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientConnection"]],
getCustomerIngredientRating?: [{	by: ValueTypes["CustomerIngredientRatingUniqueWhere"],	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null},ValueTypes["CustomerIngredientRating"]],
listCustomerIngredientRating?: [{	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerIngredientRating"]],
paginateCustomerIngredientRating?: [{	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerIngredientRatingConnection"]],
validateCreateCustomerIngredientRating?: [{	data: ValueTypes["CustomerIngredientRatingCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateCustomerIngredientRating?: [{	by: ValueTypes["CustomerIngredientRatingUniqueWhere"],	data: ValueTypes["CustomerIngredientRatingUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductIngredientRating?: [{	by: ValueTypes["ProductIngredientRatingUniqueWhere"],	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null},ValueTypes["ProductIngredientRating"]],
listProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientRating"]],
paginateProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientRatingConnection"]],
getProductIngredientLocale?: [{	by: ValueTypes["ProductIngredientLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
listProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
paginateProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
getOrderRecurrence?: [{	by: ValueTypes["OrderRecurrenceUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null},ValueTypes["OrderRecurrence"]],
listOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrence"]],
paginateOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceConnection"]],
getOrderRecurrenceLocale?: [{	by: ValueTypes["OrderRecurrenceLocaleUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
listOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
paginateOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
getBusinessCustomer?: [{	by: ValueTypes["BusinessCustomerUniqueWhere"],	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null},ValueTypes["BusinessCustomer"]],
listBusinessCustomer?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCustomer"]],
paginateBusinessCustomer?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCustomerConnection"]],
getStaff?: [{	by: ValueTypes["StaffUniqueWhere"],	filter?: ValueTypes["StaffWhere"] | undefined | null},ValueTypes["Staff"]],
listStaff?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StaffOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Staff"]],
paginateStaff?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StaffOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StaffConnection"]],
getBlog?: [{	by: ValueTypes["BlogUniqueWhere"],	filter?: ValueTypes["BlogWhere"] | undefined | null},ValueTypes["Blog"]],
listBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Blog"]],
paginateBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogConnection"]],
getBlogLocale?: [{	by: ValueTypes["BlogLocaleUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
listBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
paginateBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
getBlogPost?: [{	by: ValueTypes["BlogPostUniqueWhere"],	filter?: ValueTypes["BlogPostWhere"] | undefined | null},ValueTypes["BlogPost"]],
listBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPost"]],
paginateBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostConnection"]],
getBlogPostLocale?: [{	by: ValueTypes["BlogPostLocaleUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
listBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
paginateBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
getColorPallete?: [{	by: ValueTypes["ColorPalleteUniqueWhere"],	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
listColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ColorPallete"]],
paginateColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ColorPalleteConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"],	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"],	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentReferenceConnection"]],
getBlogPostList?: [{	by: ValueTypes["BlogPostListUniqueWhere"],	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
listBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostList"]],
paginateBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListConnection"]],
getBlogPostListItem?: [{	by: ValueTypes["BlogPostListItemUniqueWhere"],	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null},ValueTypes["BlogPostListItem"]],
listBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostListItem"]],
paginateBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListItemConnection"]],
getLinkList?: [{	by: ValueTypes["LinkListUniqueWhere"],	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
listLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkList"]],
paginateLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListConnection"]],
getLinkListItem?: [{	by: ValueTypes["LinkListItemUniqueWhere"],	filter?: ValueTypes["LinkListItemWhere"] | undefined | null},ValueTypes["LinkListItem"]],
listLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkListItem"]],
paginateLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListItemConnection"]],
getCover?: [{	by: ValueTypes["CoverUniqueWhere"],	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
listCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Cover"]],
paginateCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CoverConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"],	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"],	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkableConnection"]],
getMedium?: [{	by: ValueTypes["MediumUniqueWhere"],	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
listMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Medium"]],
paginateMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MediumConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"],	filter?: ValueTypes["VideoWhere"] | undefined | null},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VideoConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"],	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SeoConnection"]],
getTranslationDomain?: [{	by: ValueTypes["TranslationDomainUniqueWhere"],	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationDomainConnection"]],
getTranslationCatalogue?: [{	by: ValueTypes["TranslationCatalogueUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
getTranslationKey?: [{	by: ValueTypes["TranslationKeyUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ValueTypes["TranslationValueUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifierConnection"]],
getSocialsAndApps?: [{	by: ValueTypes["SocialsAndAppsUniqueWhere"],	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null},ValueTypes["SocialsAndApps"]],
listSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SocialsAndApps"]],
paginateSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SocialsAndAppsConnection"]],
getProductGrid?: [{	by: ValueTypes["ProductGridUniqueWhere"],	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
listProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGrid"]],
paginateProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridConnection"]],
getProductGridItem?: [{	by: ValueTypes["ProductGridItemUniqueWhere"],	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null},ValueTypes["ProductGridItem"]],
listProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridItem"]],
paginateProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridItemConnection"]],
getGallery?: [{	by: ValueTypes["GalleryUniqueWhere"],	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
listGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Gallery"]],
paginateGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryConnection"]],
getGalleryItem?: [{	by: ValueTypes["GalleryItemUniqueWhere"],	filter?: ValueTypes["GalleryItemWhere"] | undefined | null},ValueTypes["GalleryItem"]],
listGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryItemConnection"]],
getHero?: [{	by: ValueTypes["HeroUniqueWhere"],	filter?: ValueTypes["HeroWhere"] | undefined | null},ValueTypes["Hero"]],
listHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Hero"]],
paginateHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["HeroConnection"]],
getPage?: [{	by: ValueTypes["PageUniqueWhere"],	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
listPage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Page"]],
paginatePage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageConnection"]],
getPageLocale?: [{	by: ValueTypes["PageLocaleUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
listPageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
paginatePageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
getCart?: [{	by: ValueTypes["CartUniqueWhere"],	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
listCart?: [{	filter?: ValueTypes["CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Cart"]],
paginateCart?: [{	filter?: ValueTypes["CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartConnection"]],
validateCreateCart?: [{	data: ValueTypes["CartCreateInput"]},ValueTypes["_ValidationResult"]],
getCartItem?: [{	by: ValueTypes["CartItemUniqueWhere"],	filter?: ValueTypes["CartItemWhere"] | undefined | null},ValueTypes["CartItem"]],
listCartItem?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CartItem"]],
paginateCartItem?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartItemConnection"]],
validateCreateCartItem?: [{	data: ValueTypes["CartItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateCartItem?: [{	by: ValueTypes["CartItemUniqueWhere"],	data: ValueTypes["CartItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductBanner?: [{	by: ValueTypes["ProductBannerUniqueWhere"],	filter?: ValueTypes["ProductBannerWhere"] | undefined | null},ValueTypes["ProductBanner"]],
listProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBanner"]],
paginateProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerConnection"]],
getProductBannerItem?: [{	by: ValueTypes["ProductBannerItemUniqueWhere"],	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null},ValueTypes["ProductBannerItem"]],
listProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerItem"]],
paginateProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerItemConnection"]],
getProductBannerList?: [{	by: ValueTypes["ProductBannerListUniqueWhere"],	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
listProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerList"]],
paginateProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerListConnection"]],
getUser?: [{	by: ValueTypes["UserUniqueWhere"],	filter?: ValueTypes["UserWhere"] | undefined | null},ValueTypes["User"]],
listUser?: [{	filter?: ValueTypes["UserWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UserOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["User"]],
paginateUser?: [{	filter?: ValueTypes["UserWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UserOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["UserConnection"]],
getAllergen?: [{	by: ValueTypes["AllergenUniqueWhere"],	filter?: ValueTypes["AllergenWhere"] | undefined | null},ValueTypes["Allergen"]],
listAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Allergen"]],
paginateAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenConnection"]],
getAllergenLocale?: [{	by: ValueTypes["AllergenLocaleUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
listAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
paginateAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
getGps?: [{	by: ValueTypes["GpsUniqueWhere"],	filter?: ValueTypes["GpsWhere"] | undefined | null},ValueTypes["Gps"]],
listGps?: [{	filter?: ValueTypes["GpsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GpsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Gps"]],
paginateGps?: [{	filter?: ValueTypes["GpsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GpsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GpsConnection"]],
validateCreateGps?: [{	data: ValueTypes["GpsCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateGps?: [{	by: ValueTypes["GpsUniqueWhere"],	data: ValueTypes["GpsUpdateInput"]},ValueTypes["_ValidationResult"]],
getWeekHours?: [{	by: ValueTypes["WeekHoursUniqueWhere"],	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
listWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["WeekHours"]],
paginateWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WeekHoursConnection"]],
getDeliveryZone?: [{	by: ValueTypes["DeliveryZoneUniqueWhere"],	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null},ValueTypes["DeliveryZone"]],
listDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
paginateDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
getAddressMetadata?: [{	by: ValueTypes["AddressMetadataUniqueWhere"],	filter?: ValueTypes["AddressMetadataWhere"] | undefined | null},ValueTypes["AddressMetadata"]],
listAddressMetadata?: [{	filter?: ValueTypes["AddressMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AddressMetadata"]],
paginateAddressMetadata?: [{	filter?: ValueTypes["AddressMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressMetadataConnection"]],
getDeliveryTimelinePreset?: [{	by: ValueTypes["DeliveryTimelinePresetUniqueWhere"],	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
listDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
paginateDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryTimelinePresetConnection"]],
getInvoice?: [{	by: ValueTypes["InvoiceUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
listInvoice?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoiceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Invoice"]],
paginateInvoice?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoiceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["InvoiceConnection"]],
getInvoicingPlan?: [{	by: ValueTypes["InvoicingPlanUniqueWhere"],	filter?: ValueTypes["InvoicingPlanWhere"] | undefined | null},ValueTypes["InvoicingPlan"]],
listInvoicingPlan?: [{	filter?: ValueTypes["InvoicingPlanWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoicingPlanOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["InvoicingPlan"]],
paginateInvoicingPlan?: [{	filter?: ValueTypes["InvoicingPlanWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoicingPlanOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["InvoicingPlanConnection"]],
getProductPackingLocale?: [{	by: ValueTypes["ProductPackingLocaleUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
listProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
paginateProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
getProductLocale?: [{	by: ValueTypes["ProductLocaleUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
listProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
getProductRecipeLocale?: [{	by: ValueTypes["ProductRecipeLocaleUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
listProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
paginateProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
getInvoicingPreset?: [{	by: ValueTypes["InvoicingPresetUniqueWhere"],	filter?: ValueTypes["InvoicingPresetWhere"] | undefined | null},ValueTypes["InvoicingPreset"]],
listInvoicingPreset?: [{	filter?: ValueTypes["InvoicingPresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoicingPresetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["InvoicingPreset"]],
paginateInvoicingPreset?: [{	filter?: ValueTypes["InvoicingPresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoicingPresetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["InvoicingPresetConnection"]],
getBillingSubject?: [{	by: ValueTypes["BillingSubjectUniqueWhere"],	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null},ValueTypes["BillingSubject"]],
listBillingSubject?: [{	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BillingSubjectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BillingSubject"]],
paginateBillingSubject?: [{	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BillingSubjectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BillingSubjectConnection"]],
validateCreateBillingSubject?: [{	data: ValueTypes["BillingSubjectCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateBillingSubject?: [{	by: ValueTypes["BillingSubjectUniqueWhere"],	data: ValueTypes["BillingSubjectUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductCustomerPrice?: [{	by: ValueTypes["ProductCustomerPriceUniqueWhere"],	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null},ValueTypes["ProductCustomerPrice"]],
listProductCustomerPrice?: [{	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCustomerPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCustomerPrice"]],
paginateProductCustomerPrice?: [{	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCustomerPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCustomerPriceConnection"]],
getCartPriceLine?: [{	by: ValueTypes["CartPriceLineUniqueWhere"],	filter?: ValueTypes["CartPriceLineWhere"] | undefined | null},ValueTypes["CartPriceLine"]],
listCartPriceLine?: [{	filter?: ValueTypes["CartPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartPriceLineOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CartPriceLine"]],
paginateCartPriceLine?: [{	filter?: ValueTypes["CartPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartPriceLineOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartPriceLineConnection"]],
getVirtualProduct?: [{	by: ValueTypes["VirtualProductUniqueWhere"],	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
listVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VirtualProduct"]],
paginateVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VirtualProductConnection"]],
getVirtualProductEffect?: [{	by: ValueTypes["VirtualProductEffectUniqueWhere"],	filter?: ValueTypes["VirtualProductEffectWhere"] | undefined | null},ValueTypes["VirtualProductEffect"]],
listVirtualProductEffect?: [{	filter?: ValueTypes["VirtualProductEffectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductEffectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VirtualProductEffect"]],
paginateVirtualProductEffect?: [{	filter?: ValueTypes["VirtualProductEffectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductEffectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VirtualProductEffectConnection"]],
getOrderPriceLine?: [{	by: ValueTypes["OrderPriceLineUniqueWhere"],	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null},ValueTypes["OrderPriceLine"]],
listOrderPriceLine?: [{	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPriceLineOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPriceLine"]],
paginateOrderPriceLine?: [{	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPriceLineOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPriceLineConnection"]],
getProductMetadata?: [{	by: ValueTypes["ProductMetadataUniqueWhere"],	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null},ValueTypes["ProductMetadata"]],
listProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductMetadata"]],
paginateProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductMetadataConnection"]],
getProductGridCategory?: [{	by: ValueTypes["ProductGridCategoryUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
listProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridCategory"]],
paginateProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridCategoryConnection"]],
getAnonymousSession?: [{	by: ValueTypes["AnonymousSessionUniqueWhere"],	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null},ValueTypes["AnonymousSession"]],
listAnonymousSession?: [{	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AnonymousSessionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AnonymousSession"]],
paginateAnonymousSession?: [{	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AnonymousSessionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AnonymousSessionConnection"]],
validateCreateAnonymousSession?: [{	data: ValueTypes["AnonymousSessionCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateAnonymousSession?: [{	by: ValueTypes["AnonymousSessionUniqueWhere"],	data: ValueTypes["AnonymousSessionUpdateInput"]},ValueTypes["_ValidationResult"]],
getVoucher?: [{	by: ValueTypes["VoucherUniqueWhere"],	filter?: ValueTypes["VoucherWhere"] | undefined | null},ValueTypes["Voucher"]],
listVoucher?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Voucher"]],
paginateVoucher?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VoucherConnection"]],
getVoucherRedemption?: [{	by: ValueTypes["VoucherRedemptionUniqueWhere"],	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null},ValueTypes["VoucherRedemption"]],
listVoucherRedemption?: [{	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherRedemptionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VoucherRedemption"]],
paginateVoucherRedemption?: [{	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherRedemptionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VoucherRedemptionConnection"]],
getFreshingContainer?: [{	by: ValueTypes["FreshingContainerUniqueWhere"],	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
listFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FreshingContainer"]],
paginateFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FreshingContainerConnection"]],
getTextItem?: [{	by: ValueTypes["TextItemUniqueWhere"],	filter?: ValueTypes["TextItemWhere"] | undefined | null},ValueTypes["TextItem"]],
listTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextItem"]],
paginateTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextItemConnection"]],
getTextList?: [{	by: ValueTypes["TextListUniqueWhere"],	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
listTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextList"]],
paginateTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextListConnection"]],
getDeliveryRegions?: [{	by: ValueTypes["DeliveryRegionsUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
listDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegions"]],
paginateDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsConnection"]],
getDeliveryRegionsItem?: [{	by: ValueTypes["DeliveryRegionsItemUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
listDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegionsItem"]],
paginateDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsItemConnection"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"],	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"],	filter?: ValueTypes["ImageListItemWhere"] | undefined | null},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListItemConnection"]],
getDeliveryMethodLocale?: [{	by: ValueTypes["DeliveryMethodLocaleUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
listDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
paginateDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"],	filter?: ValueTypes["RedirectWhere"] | undefined | null},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["RedirectConnection"]],
getWorkspace?: [{	by: ValueTypes["WorkspaceUniqueWhere"],	filter?: ValueTypes["WorkspaceWhere"] | undefined | null},ValueTypes["Workspace"]],
listWorkspace?: [{	filter?: ValueTypes["WorkspaceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WorkspaceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Workspace"]],
paginateWorkspace?: [{	filter?: ValueTypes["WorkspaceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WorkspaceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WorkspaceConnection"]],
getCartSummary?: [{	by: ValueTypes["CartSummaryUniqueWhere"],	filter?: ValueTypes["CartSummaryWhere"] | undefined | null},ValueTypes["CartSummary"]],
listCartSummary?: [{	filter?: ValueTypes["CartSummaryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartSummaryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CartSummary"]],
paginateCartSummary?: [{	filter?: ValueTypes["CartSummaryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartSummaryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartSummaryConnection"]],
getOrderSummary?: [{	by: ValueTypes["OrderSummaryUniqueWhere"],	filter?: ValueTypes["OrderSummaryWhere"] | undefined | null},ValueTypes["OrderSummary"]],
listOrderSummary?: [{	filter?: ValueTypes["OrderSummaryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderSummaryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderSummary"]],
paginateOrderSummary?: [{	filter?: ValueTypes["OrderSummaryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderSummaryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderSummaryConnection"]],
getOrderPaymentMetadata?: [{	by: ValueTypes["OrderPaymentMetadataUniqueWhere"],	filter?: ValueTypes["OrderPaymentMetadataWhere"] | undefined | null},ValueTypes["OrderPaymentMetadata"]],
listOrderPaymentMetadata?: [{	filter?: ValueTypes["OrderPaymentMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPaymentMetadata"]],
paginateOrderPaymentMetadata?: [{	filter?: ValueTypes["OrderPaymentMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentMetadataConnection"]],
getUserMetadata?: [{	by: ValueTypes["UserMetadataUniqueWhere"],	filter?: ValueTypes["UserMetadataWhere"] | undefined | null},ValueTypes["UserMetadata"]],
listUserMetadata?: [{	filter?: ValueTypes["UserMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UserMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["UserMetadata"]],
paginateUserMetadata?: [{	filter?: ValueTypes["UserMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UserMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["UserMetadataConnection"]],
validateCreateIdentificationAttempt?: [{	data: ValueTypes["IdentificationAttemptCreateInput"]},ValueTypes["_ValidationResult"]],
getIdentificationCode?: [{	by: ValueTypes["IdentificationCodeUniqueWhere"],	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null},ValueTypes["IdentificationCode"]],
listIdentificationCode?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCode"]],
paginateIdentificationCode?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeConnection"]],
getIdentificationCodeLocale?: [{	by: ValueTypes["IdentificationCodeLocaleUniqueWhere"],	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null},ValueTypes["IdentificationCodeLocale"]],
listIdentificationCodeLocale?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCodeLocale"]],
paginateIdentificationCodeLocale?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeLocaleConnection"]],
getFulfillmentNote?: [{	by: ValueTypes["FulfillmentNoteUniqueWhere"],	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null},ValueTypes["FulfillmentNote"]],
listFulfillmentNote?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
paginateFulfillmentNote?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
getCustomerMetadata?: [{	by: ValueTypes["CustomerMetadataUniqueWhere"],	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null},ValueTypes["CustomerMetadata"]],
listCustomerMetadata?: [{	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerMetadata"]],
paginateCustomerMetadata?: [{	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerMetadataConnection"]],
getSubscriptionBox?: [{	by: ValueTypes["SubscriptionBoxUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null},ValueTypes["SubscriptionBox"]],
listSubscriptionBox?: [{	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBox"]],
paginateSubscriptionBox?: [{	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxConnection"]],
getSubscriptionBoxLocale?: [{	by: ValueTypes["SubscriptionBoxLocaleUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
listSubscriptionBoxLocale?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
paginateSubscriptionBoxLocale?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxLocaleConnection"]],
getGridTileItem?: [{	by: ValueTypes["GridTileItemUniqueWhere"],	filter?: ValueTypes["GridTileItemWhere"] | undefined | null},ValueTypes["GridTileItem"]],
listGridTileItem?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GridTileItem"]],
paginateGridTileItem?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GridTileItemConnection"]],
getGridTiles?: [{	by: ValueTypes["GridTilesUniqueWhere"],	filter?: ValueTypes["GridTilesWhere"] | undefined | null},ValueTypes["GridTiles"]],
listGridTiles?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTilesOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GridTiles"]],
paginateGridTiles?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTilesOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GridTilesConnection"]],
getPosition?: [{	by: ValueTypes["PositionUniqueWhere"],	filter?: ValueTypes["PositionWhere"] | undefined | null},ValueTypes["Position"]],
listPosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Position"]],
paginatePosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PositionConnection"]],
getStatsWidget?: [{	by: ValueTypes["StatsWidgetUniqueWhere"],	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null},ValueTypes["StatsWidget"]],
listStatsWidget?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsWidget"]],
paginateStatsWidget?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsWidgetConnection"]],
getStatsWidgetsLayout?: [{	by: ValueTypes["StatsWidgetsLayoutUniqueWhere"],	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null},ValueTypes["StatsWidgetsLayout"]],
listStatsWidgetsLayout?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetsLayoutOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsWidgetsLayout"]],
paginateStatsWidgetsLayout?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetsLayoutOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsWidgetsLayoutConnection"]],
getStatsPage?: [{	by: ValueTypes["StatsPageUniqueWhere"],	filter?: ValueTypes["StatsPageWhere"] | undefined | null},ValueTypes["StatsPage"]],
listStatsPage?: [{	filter?: ValueTypes["StatsPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsPage"]],
paginateStatsPage?: [{	filter?: ValueTypes["StatsPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsPageConnection"]],
	transaction?:ValueTypes["QueryTransaction"],
	_info?:ValueTypes["Info"],
	schema?:ValueTypes["_Schema"],
		__typename?: boolean | `@${string}`
}>;
	["Address"]: AliasType<{
	_meta?:ValueTypes["AddressMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	addressLine2?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	companyName?:boolean | `@${string}`,
	companyIdentifier?:boolean | `@${string}`,
	vatIdentifier?:boolean | `@${string}`,
	externalIdentifier?:boolean | `@${string}`,
	hidden?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
country?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null},ValueTypes["Country"]],
	deletedAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	googlePlaceId?:boolean | `@${string}`,
	raw?:boolean | `@${string}`,
	formatted?:boolean | `@${string}`,
	geocodeGeneratedAt?:boolean | `@${string}`,
	geocodeResponse?:boolean | `@${string}`,
deliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null},ValueTypes["DeliveryZone"]],
meta?: [{	filter?: ValueTypes["AddressMetadataWhere"] | undefined | null},ValueTypes["AddressMetadata"]],
gps?: [{	filter?: ValueTypes["GpsWhere"] | undefined | null},ValueTypes["Gps"]],
	geocodeValid?:boolean | `@${string}`,
	fullName?:boolean | `@${string}`,
	fakturoidSubjectId?:boolean | `@${string}`,
invoices?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoiceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Invoice"]],
	addressLine1?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	postalCode?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
billingAddressOfOrders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
deliveryAddressOfOrderDeliveries?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDelivery"]],
defaultDeliveryAddressOfCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
invoicesByPublicKey?: [{	by: ValueTypes["AddressInvoicesByPublicKeyUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
invoicesByFakturoidId?: [{	by: ValueTypes["AddressInvoicesByFakturoidIdUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
invoicesByOrderPayments?: [{	by: ValueTypes["AddressInvoicesByOrderPaymentsUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
billingAddressOfOrdersBySeq?: [{	by: ValueTypes["AddressBillingAddressOfOrdersBySeqUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDoubleshotLegacyId?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDoubleShotLegacyNumber?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDiscounts?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByDiscountsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDelivery?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByDeliveryUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByItems?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByItemsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByCart?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByCartUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByPriceLines?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByPriceLinesUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByPayments?: [{	by: ValueTypes["AddressBillingAddressOfOrdersByPaymentsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersBySummary?: [{	by: ValueTypes["AddressBillingAddressOfOrdersBySummaryUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
deliveryAddressOfOrderDeliveriesByTrackingCode?: [{	by: ValueTypes["AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"],	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
deliveryAddressOfOrderDeliveriesByOrder?: [{	by: ValueTypes["AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"],	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
paginateInvoices?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoiceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["InvoiceConnection"]],
paginateBillingAddressOfOrders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
paginateDeliveryAddressOfOrderDeliveries?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDeliveryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["AddressMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	firstName?:ValueTypes["FieldMeta"],
	lastName?:ValueTypes["FieldMeta"],
	addressLine2?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	phone?:ValueTypes["FieldMeta"],
	companyName?:ValueTypes["FieldMeta"],
	companyIdentifier?:ValueTypes["FieldMeta"],
	vatIdentifier?:ValueTypes["FieldMeta"],
	externalIdentifier?:ValueTypes["FieldMeta"],
	hidden?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	country?:ValueTypes["FieldMeta"],
	deletedAt?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	googlePlaceId?:ValueTypes["FieldMeta"],
	raw?:ValueTypes["FieldMeta"],
	formatted?:ValueTypes["FieldMeta"],
	geocodeGeneratedAt?:ValueTypes["FieldMeta"],
	geocodeResponse?:ValueTypes["FieldMeta"],
	deliveryZone?:ValueTypes["FieldMeta"],
	meta?:ValueTypes["FieldMeta"],
	gps?:ValueTypes["FieldMeta"],
	geocodeValid?:ValueTypes["FieldMeta"],
	fullName?:ValueTypes["FieldMeta"],
	fakturoidSubjectId?:ValueTypes["FieldMeta"],
	invoices?:ValueTypes["FieldMeta"],
	addressLine1?:ValueTypes["FieldMeta"],
	city?:ValueTypes["FieldMeta"],
	postalCode?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	billingAddressOfOrders?:ValueTypes["FieldMeta"],
	deliveryAddressOfOrderDeliveries?:ValueTypes["FieldMeta"],
	defaultDeliveryAddressOfCustomer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FieldMeta"]: AliasType<{
	readable?:boolean | `@${string}`,
	updatable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:string;
	["Customer"]: AliasType<{
	_meta?:ValueTypes["CustomerMeta"],
	id?:boolean | `@${string}`,
addresses?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Address"]],
defaultBillingAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
tags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
credits?: [{	filter?: ValueTypes["CustomerCreditWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerCreditOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerCredit"]],
orders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
permissions?: [{	filter?: ValueTypes["CustomerPermissionsWhere"] | undefined | null},ValueTypes["CustomerPermissions"]],
group?: [{	filter?: ValueTypes["CustomerGroupWhere"] | undefined | null},ValueTypes["CustomerGroup"]],
photo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	phone?:boolean | `@${string}`,
name?: [{	filter?: ValueTypes["CustomerNameWhere"] | undefined | null},ValueTypes["CustomerName"]],
	welcomeMessage?:boolean | `@${string}`,
	familiarity?:boolean | `@${string}`,
ingredientRatings?: [{	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerIngredientRating"]],
business?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null},ValueTypes["BusinessCustomer"]],
ownerOfBusinesses?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCustomer"]],
memberOfBusinesses?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCustomer"]],
carts?: [{	filter?: ValueTypes["CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Cart"]],
user?: [{	filter?: ValueTypes["UserWhere"] | undefined | null},ValueTypes["User"]],
channels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
	disabledAt?:boolean | `@${string}`,
invoices?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoiceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Invoice"]],
invoicingPlan?: [{	filter?: ValueTypes["InvoicingPlanWhere"] | undefined | null},ValueTypes["InvoicingPlan"]],
	email?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
billingSubjects?: [{	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BillingSubjectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BillingSubject"]],
offeredProducts?: [{	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCustomerPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCustomerPrice"]],
defaultDeliveryAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
defaultBillingSubject?: [{	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null},ValueTypes["BillingSubject"]],
	prefersPackingsWithoutCardboard?:boolean | `@${string}`,
anonymousSession?: [{	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null},ValueTypes["AnonymousSession"]],
boughtVouchers?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Voucher"]],
ownsVouchers?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Voucher"]],
redeemedVouchers?: [{	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherRedemptionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VoucherRedemption"]],
	doubleshotLegacyId?:boolean | `@${string}`,
meta?: [{	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null},ValueTypes["CustomerMetadata"]],
	doubleshotLastUpdatedAt?:boolean | `@${string}`,
addressesByMeta?: [{	by: ValueTypes["CustomerAddressesByMetaUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByGps?: [{	by: ValueTypes["CustomerAddressesByGpsUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByInvoices?: [{	by: ValueTypes["CustomerAddressesByInvoicesUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByBillingAddressOfOrders?: [{	by: ValueTypes["CustomerAddressesByBillingAddressOfOrdersUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDeliveryAddressOfOrderDeliveries?: [{	by: ValueTypes["CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDefaultDeliveryAddressOfCustomer?: [{	by: ValueTypes["CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
creditsByVoucherRedemption?: [{	by: ValueTypes["CustomerCreditsByVoucherRedemptionUniqueWhere"],	filter?: ValueTypes["CustomerCreditWhere"] | undefined | null},ValueTypes["CustomerCredit"]],
ordersBySeq?: [{	by: ValueTypes["CustomerOrdersBySeqUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleshotLegacyId?: [{	by: ValueTypes["CustomerOrdersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleShotLegacyNumber?: [{	by: ValueTypes["CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDiscounts?: [{	by: ValueTypes["CustomerOrdersByDiscountsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDelivery?: [{	by: ValueTypes["CustomerOrdersByDeliveryUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByItems?: [{	by: ValueTypes["CustomerOrdersByItemsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByCart?: [{	by: ValueTypes["CustomerOrdersByCartUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPriceLines?: [{	by: ValueTypes["CustomerOrdersByPriceLinesUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPayments?: [{	by: ValueTypes["CustomerOrdersByPaymentsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersBySummary?: [{	by: ValueTypes["CustomerOrdersBySummaryUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ingredientRatingsByIngredient?: [{	by: ValueTypes["CustomerIngredientRatingsByIngredientUniqueWhere"],	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null},ValueTypes["CustomerIngredientRating"]],
cartsByItems?: [{	by: ValueTypes["CustomerCartsByItemsUniqueWhere"],	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
cartsByOrder?: [{	by: ValueTypes["CustomerCartsByOrderUniqueWhere"],	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
cartsByPriceLines?: [{	by: ValueTypes["CustomerCartsByPriceLinesUniqueWhere"],	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
cartsBySummary?: [{	by: ValueTypes["CustomerCartsBySummaryUniqueWhere"],	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
invoicesByPublicKey?: [{	by: ValueTypes["CustomerInvoicesByPublicKeyUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
invoicesByFakturoidId?: [{	by: ValueTypes["CustomerInvoicesByFakturoidIdUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
invoicesByOrderPayments?: [{	by: ValueTypes["CustomerInvoicesByOrderPaymentsUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
billingSubjectsByInvoices?: [{	by: ValueTypes["CustomerBillingSubjectsByInvoicesUniqueWhere"],	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null},ValueTypes["BillingSubject"]],
billingSubjectsByDefaultBillingSubjectOfCustomer?: [{	by: ValueTypes["CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere"],	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null},ValueTypes["BillingSubject"]],
billingSubjectsByOrders?: [{	by: ValueTypes["CustomerBillingSubjectsByOrdersUniqueWhere"],	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null},ValueTypes["BillingSubject"]],
offeredProductsByProduct?: [{	by: ValueTypes["CustomerOfferedProductsByProductUniqueWhere"],	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null},ValueTypes["ProductCustomerPrice"]],
boughtVouchersByCode?: [{	by: ValueTypes["CustomerBoughtVouchersByCodeUniqueWhere"],	filter?: ValueTypes["VoucherWhere"] | undefined | null},ValueTypes["Voucher"]],
boughtVouchersByRedemption?: [{	by: ValueTypes["CustomerBoughtVouchersByRedemptionUniqueWhere"],	filter?: ValueTypes["VoucherWhere"] | undefined | null},ValueTypes["Voucher"]],
ownsVouchersByCode?: [{	by: ValueTypes["CustomerOwnsVouchersByCodeUniqueWhere"],	filter?: ValueTypes["VoucherWhere"] | undefined | null},ValueTypes["Voucher"]],
ownsVouchersByRedemption?: [{	by: ValueTypes["CustomerOwnsVouchersByRedemptionUniqueWhere"],	filter?: ValueTypes["VoucherWhere"] | undefined | null},ValueTypes["Voucher"]],
redeemedVouchersByCustomerCredit?: [{	by: ValueTypes["CustomerRedeemedVouchersByCustomerCreditUniqueWhere"],	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null},ValueTypes["VoucherRedemption"]],
redeemedVouchersByOrderDiscount?: [{	by: ValueTypes["CustomerRedeemedVouchersByOrderDiscountUniqueWhere"],	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null},ValueTypes["VoucherRedemption"]],
redeemedVouchersByVoucher?: [{	by: ValueTypes["CustomerRedeemedVouchersByVoucherUniqueWhere"],	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null},ValueTypes["VoucherRedemption"]],
paginateAddresses?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressConnection"]],
paginateTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateCredits?: [{	filter?: ValueTypes["CustomerCreditWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerCreditOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerCreditConnection"]],
paginateOrders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
paginateIngredientRatings?: [{	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerIngredientRatingConnection"]],
paginateOwnerOfBusinesses?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCustomerConnection"]],
paginateMemberOfBusinesses?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCustomerConnection"]],
paginateCarts?: [{	filter?: ValueTypes["CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartConnection"]],
paginateChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
paginateInvoices?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoiceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["InvoiceConnection"]],
paginateBillingSubjects?: [{	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BillingSubjectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BillingSubjectConnection"]],
paginateOfferedProducts?: [{	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCustomerPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCustomerPriceConnection"]],
paginateBoughtVouchers?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VoucherConnection"]],
paginateOwnsVouchers?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VoucherConnection"]],
paginateRedeemedVouchers?: [{	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherRedemptionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VoucherRedemptionConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CustomerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	addresses?:ValueTypes["FieldMeta"],
	defaultBillingAddress?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	credits?:ValueTypes["FieldMeta"],
	orders?:ValueTypes["FieldMeta"],
	permissions?:ValueTypes["FieldMeta"],
	group?:ValueTypes["FieldMeta"],
	photo?:ValueTypes["FieldMeta"],
	phone?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	welcomeMessage?:ValueTypes["FieldMeta"],
	familiarity?:ValueTypes["FieldMeta"],
	ingredientRatings?:ValueTypes["FieldMeta"],
	business?:ValueTypes["FieldMeta"],
	ownerOfBusinesses?:ValueTypes["FieldMeta"],
	memberOfBusinesses?:ValueTypes["FieldMeta"],
	carts?:ValueTypes["FieldMeta"],
	user?:ValueTypes["FieldMeta"],
	channels?:ValueTypes["FieldMeta"],
	disabledAt?:ValueTypes["FieldMeta"],
	invoices?:ValueTypes["FieldMeta"],
	invoicingPlan?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	billingSubjects?:ValueTypes["FieldMeta"],
	offeredProducts?:ValueTypes["FieldMeta"],
	defaultDeliveryAddress?:ValueTypes["FieldMeta"],
	defaultBillingSubject?:ValueTypes["FieldMeta"],
	prefersPackingsWithoutCardboard?:ValueTypes["FieldMeta"],
	anonymousSession?:ValueTypes["FieldMeta"],
	boughtVouchers?:ValueTypes["FieldMeta"],
	ownsVouchers?:ValueTypes["FieldMeta"],
	redeemedVouchers?:ValueTypes["FieldMeta"],
	doubleshotLegacyId?:ValueTypes["FieldMeta"],
	meta?:ValueTypes["FieldMeta"],
	doubleshotLastUpdatedAt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AddressWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	firstName?: ValueTypes["StringCondition"] | undefined | null,
	lastName?: ValueTypes["StringCondition"] | undefined | null,
	addressLine2?: ValueTypes["StringCondition"] | undefined | null,
	email?: ValueTypes["StringCondition"] | undefined | null,
	phone?: ValueTypes["StringCondition"] | undefined | null,
	companyName?: ValueTypes["StringCondition"] | undefined | null,
	companyIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	vatIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	externalIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	hidden?: ValueTypes["BooleanCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	country?: ValueTypes["CountryWhere"] | undefined | null,
	deletedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	googlePlaceId?: ValueTypes["StringCondition"] | undefined | null,
	raw?: ValueTypes["StringCondition"] | undefined | null,
	formatted?: ValueTypes["StringCondition"] | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	geocodeResponse?: ValueTypes["StringCondition"] | undefined | null,
	deliveryZone?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	meta?: ValueTypes["AddressMetadataWhere"] | undefined | null,
	gps?: ValueTypes["GpsWhere"] | undefined | null,
	geocodeValid?: ValueTypes["BooleanCondition"] | undefined | null,
	fullName?: ValueTypes["StringCondition"] | undefined | null,
	fakturoidSubjectId?: ValueTypes["StringCondition"] | undefined | null,
	invoices?: ValueTypes["InvoiceWhere"] | undefined | null,
	addressLine1?: ValueTypes["StringCondition"] | undefined | null,
	city?: ValueTypes["StringCondition"] | undefined | null,
	postalCode?: ValueTypes["StringCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	billingAddressOfOrders?: ValueTypes["OrderWhere"] | undefined | null,
	deliveryAddressOfOrderDeliveries?: ValueTypes["OrderDeliveryWhere"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["AddressWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["AddressWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["AddressWhere"] | undefined | null
};
	["UUIDCondition"]: {
	and?: Array<ValueTypes["UUIDCondition"]> | undefined | null,
	or?: Array<ValueTypes["UUIDCondition"]> | undefined | null,
	not?: ValueTypes["UUIDCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["UUID"] | undefined | null,
	notEq?: ValueTypes["UUID"] | undefined | null,
	in?: Array<ValueTypes["UUID"]> | undefined | null,
	notIn?: Array<ValueTypes["UUID"]> | undefined | null,
	lt?: ValueTypes["UUID"] | undefined | null,
	lte?: ValueTypes["UUID"] | undefined | null,
	gt?: ValueTypes["UUID"] | undefined | null,
	gte?: ValueTypes["UUID"] | undefined | null
};
	["StringCondition"]: {
	and?: Array<ValueTypes["StringCondition"]> | undefined | null,
	or?: Array<ValueTypes["StringCondition"]> | undefined | null,
	not?: ValueTypes["StringCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: string | undefined | null,
	notEq?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	containsCI?: string | undefined | null,
	startsWithCI?: string | undefined | null,
	endsWithCI?: string | undefined | null
};
	["BooleanCondition"]: {
	and?: Array<ValueTypes["BooleanCondition"]> | undefined | null,
	or?: Array<ValueTypes["BooleanCondition"]> | undefined | null,
	not?: ValueTypes["BooleanCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: boolean | undefined | null,
	notEq?: boolean | undefined | null,
	in?: Array<boolean> | undefined | null,
	notIn?: Array<boolean> | undefined | null,
	lt?: boolean | undefined | null,
	lte?: boolean | undefined | null,
	gt?: boolean | undefined | null,
	gte?: boolean | undefined | null
};
	["CustomerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	addresses?: ValueTypes["AddressWhere"] | undefined | null,
	defaultBillingAddress?: ValueTypes["AddressWhere"] | undefined | null,
	tags?: ValueTypes["TagWhere"] | undefined | null,
	credits?: ValueTypes["CustomerCreditWhere"] | undefined | null,
	orders?: ValueTypes["OrderWhere"] | undefined | null,
	permissions?: ValueTypes["CustomerPermissionsWhere"] | undefined | null,
	group?: ValueTypes["CustomerGroupWhere"] | undefined | null,
	photo?: ValueTypes["ImageWhere"] | undefined | null,
	phone?: ValueTypes["StringCondition"] | undefined | null,
	name?: ValueTypes["CustomerNameWhere"] | undefined | null,
	welcomeMessage?: ValueTypes["StringCondition"] | undefined | null,
	familiarity?: ValueTypes["CustomerFamiliarityCondition"] | undefined | null,
	ingredientRatings?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,
	business?: ValueTypes["BusinessCustomerWhere"] | undefined | null,
	ownerOfBusinesses?: ValueTypes["BusinessCustomerWhere"] | undefined | null,
	memberOfBusinesses?: ValueTypes["BusinessCustomerWhere"] | undefined | null,
	carts?: ValueTypes["CartWhere"] | undefined | null,
	user?: ValueTypes["UserWhere"] | undefined | null,
	channels?: ValueTypes["ChannelWhere"] | undefined | null,
	disabledAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	invoices?: ValueTypes["InvoiceWhere"] | undefined | null,
	invoicingPlan?: ValueTypes["InvoicingPlanWhere"] | undefined | null,
	email?: ValueTypes["StringCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	billingSubjects?: ValueTypes["BillingSubjectWhere"] | undefined | null,
	offeredProducts?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null,
	defaultDeliveryAddress?: ValueTypes["AddressWhere"] | undefined | null,
	defaultBillingSubject?: ValueTypes["BillingSubjectWhere"] | undefined | null,
	prefersPackingsWithoutCardboard?: ValueTypes["BooleanCondition"] | undefined | null,
	anonymousSession?: ValueTypes["AnonymousSessionWhere"] | undefined | null,
	boughtVouchers?: ValueTypes["VoucherWhere"] | undefined | null,
	ownsVouchers?: ValueTypes["VoucherWhere"] | undefined | null,
	redeemedVouchers?: ValueTypes["VoucherRedemptionWhere"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["StringCondition"] | undefined | null,
	meta?: ValueTypes["CustomerMetadataWhere"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["CustomerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CustomerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CustomerWhere"] | undefined | null
};
	["TagWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	customers?: ValueTypes["CustomerWhere"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	categories?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	addTagsOnRegistration?: ValueTypes["ChannelWhere"] | undefined | null,
	deliveryZones?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	publicInChannels?: ValueTypes["ChannelWhere"] | undefined | null,
	allowChannelPayments?: ValueTypes["ChannelPaymentWhere"] | undefined | null,
	fulfillmentNotes?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,
	and?: Array<ValueTypes["TagWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TagWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TagWhere"] | undefined | null
};
	["ProductWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	notDiscountable?: ValueTypes["BooleanCondition"] | undefined | null,
	packing?: ValueTypes["ProductPackingWhere"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	tags?: ValueTypes["TagWhere"] | undefined | null,
	categories?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	stocks?: ValueTypes["ProductStockWhere"] | undefined | null,
	groupPrices?: ValueTypes["ProductGroupPriceWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	cartItems?: ValueTypes["CartItemWhere"] | undefined | null,
	availability?: ValueTypes["ProductAvailabilityCondition"] | undefined | null,
	order?: ValueTypes["FloatCondition"] | undefined | null,
	orderItems?: ValueTypes["OrderItemWhere"] | undefined | null,
	maximumQuantityPerOrder?: ValueTypes["IntCondition"] | undefined | null,
	deliveryZones?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	locales?: ValueTypes["ProductLocaleWhere"] | undefined | null,
	archivedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	customerPrices?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null,
	virtualProduct?: ValueTypes["VirtualProductWhere"] | undefined | null,
	meta?: ValueTypes["ProductMetadataWhere"] | undefined | null,
	page?: ValueTypes["PageWhere"] | undefined | null,
	fulfillmentNotes?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,
	hasSubscription?: ValueTypes["BooleanCondition"] | undefined | null,
	pointOfSaleTileColor?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductWhere"] | undefined | null
};
	["IntCondition"]: {
	and?: Array<ValueTypes["IntCondition"]> | undefined | null,
	or?: Array<ValueTypes["IntCondition"]> | undefined | null,
	not?: ValueTypes["IntCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["ProductPackingWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	volumeMl?: ValueTypes["IntCondition"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	shortName?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,
	isPackedInCardboard?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductPackingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductPackingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductPackingWhere"] | undefined | null
};
	["ImageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	url?: ValueTypes["StringCondition"] | undefined | null,
	width?: ValueTypes["IntCondition"] | undefined | null,
	height?: ValueTypes["IntCondition"] | undefined | null,
	size?: ValueTypes["IntCondition"] | undefined | null,
	type?: ValueTypes["StringCondition"] | undefined | null,
	alt?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ImageWhere"] | undefined | null
};
	["ProductPackingLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	shortName?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductPackingWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductPackingLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductPackingLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null
};
	["LocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	businessCategories?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,
	productCategoryLists?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,
	productIngredients?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,
	orderRecurrences?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,
	blogs?: ValueTypes["BlogLocaleWhere"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,
	pages?: ValueTypes["PageLocaleWhere"] | undefined | null,
	allergens?: ValueTypes["AllergenLocaleWhere"] | undefined | null,
	productPackings?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,
	products?: ValueTypes["ProductLocaleWhere"] | undefined | null,
	productRecipes?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,
	deliveryMethods?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,
	identificationCodes?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,
	subscriptionBoxes?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LocaleWhere"] | undefined | null
};
	["BusinessCategoryLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null
};
	["BusinessCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	visibleToNewCustomers?: ValueTypes["BooleanCondition"] | undefined | null,
	locales?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	businessCustomers?: ValueTypes["BusinessCustomerWhere"] | undefined | null,
	recurrences?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,
	specificProducts?: ValueTypes["ProductListWhere"] | undefined | null,
	seasonalProductCategories?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	slug?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["BusinessCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BusinessCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BusinessCategoryWhere"] | undefined | null
};
	["BusinessCustomerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	owners?: ValueTypes["CustomerWhere"] | undefined | null,
	members?: ValueTypes["CustomerWhere"] | undefined | null,
	category?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	logo?: ValueTypes["ImageWhere"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["BusinessCustomerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BusinessCustomerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BusinessCustomerWhere"] | undefined | null
};
	["OrderRecurrenceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderRecurrenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderRecurrenceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderRecurrenceWhere"] | undefined | null
};
	["OrderRecurrenceLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null
};
	["ProductListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ProductListItemWhere"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductListWhere"] | undefined | null
};
	["ProductListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ProductListWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	defaultQuantityForOrder?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductListItemWhere"] | undefined | null
};
	["ProductCategoryListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,
	locales?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryListWhere"] | undefined | null
};
	["ProductCategoryListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	productCategory?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	showRecipeImages?: ValueTypes["BooleanCondition"] | undefined | null,
	showPackingImages?: ValueTypes["BooleanCondition"] | undefined | null,
	showAllAvailableProducts?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null
};
	["ProductCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	tags?: ValueTypes["TagWhere"] | undefined | null,
	parent?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	children?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	color?: ValueTypes["StringCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	order?: ValueTypes["FloatCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryWhere"] | undefined | null
};
	["FloatCondition"]: {
	and?: Array<ValueTypes["FloatCondition"]> | undefined | null,
	or?: Array<ValueTypes["FloatCondition"]> | undefined | null,
	not?: ValueTypes["FloatCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["ProductCategoryListLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductCategoryListWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null
};
	["ProductIngredientLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null
};
	["ProductIngredientWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	allergens?: ValueTypes["AllergenWhere"] | undefined | null,
	needsPrepping?: ValueTypes["BooleanCondition"] | undefined | null,
	allowCustomerRating?: ValueTypes["BooleanCondition"] | undefined | null,
	customerRatings?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	incrementQuantityNumber?: ValueTypes["FloatCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientWhere"] | undefined | null
};
	["AllergenWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	locales?: ValueTypes["AllergenLocaleWhere"] | undefined | null,
	ingredients?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	code?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["AllergenWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["AllergenWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["AllergenWhere"] | undefined | null
};
	["AllergenLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["AllergenWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["AllergenLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["AllergenLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["AllergenLocaleWhere"] | undefined | null
};
	["CustomerIngredientRatingWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	rating?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	and?: Array<ValueTypes["CustomerIngredientRatingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CustomerIngredientRatingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null
};
	["ProductIngredientRatingWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientRatingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientRatingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null
};
	["FreshingContainerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	volumeMl?: ValueTypes["IntCondition"] | undefined | null,
	workspace?: ValueTypes["WorkspaceWhere"] | undefined | null,
	and?: Array<ValueTypes["FreshingContainerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["FreshingContainerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["FreshingContainerWhere"] | undefined | null
};
	["WorkspaceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	unique?: ValueTypes["OneCondition"] | undefined | null,
	orderDeadlineTime?: ValueTypes["StringCondition"] | undefined | null,
	ordersLoadingDurationHours?: ValueTypes["IntCondition"] | undefined | null,
	freshingContainerVolumeMl?: ValueTypes["IntCondition"] | undefined | null,
	freshingPrepareOrderByOffsetMinutes?: ValueTypes["IntCondition"] | undefined | null,
	checkoutUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	orderUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	paymentUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	customerProfileUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	userProfileUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	messengerUrl?: ValueTypes["StringCondition"] | undefined | null,
	messengerIosUrl?: ValueTypes["StringCondition"] | undefined | null,
	messengerAndroidUrl?: ValueTypes["StringCondition"] | undefined | null,
	freshingContainers?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	openingHours?: ValueTypes["WeekHoursWhere"] | undefined | null,
	workingHours?: ValueTypes["WeekHoursWhere"] | undefined | null,
	defaultFreshingContainer?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	creditsProduct?: ValueTypes["VirtualProductWhere"] | undefined | null,
	discountVatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	businessAddress?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["WorkspaceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["WorkspaceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["WorkspaceWhere"] | undefined | null
};
	["OneCondition"]: {
	and?: Array<ValueTypes["OneCondition"]> | undefined | null,
	or?: Array<ValueTypes["OneCondition"]> | undefined | null,
	not?: ValueTypes["OneCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["One"] | undefined | null,
	notEq?: ValueTypes["One"] | undefined | null,
	in?: Array<ValueTypes["One"]> | undefined | null,
	notIn?: Array<ValueTypes["One"]> | undefined | null,
	lt?: ValueTypes["One"] | undefined | null,
	lte?: ValueTypes["One"] | undefined | null,
	gt?: ValueTypes["One"] | undefined | null,
	gte?: ValueTypes["One"] | undefined | null
};
	["One"]:One;
	["WeekHoursWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	mon?: ValueTypes["StringCondition"] | undefined | null,
	tue?: ValueTypes["StringCondition"] | undefined | null,
	wed?: ValueTypes["StringCondition"] | undefined | null,
	thu?: ValueTypes["StringCondition"] | undefined | null,
	fri?: ValueTypes["StringCondition"] | undefined | null,
	sat?: ValueTypes["StringCondition"] | undefined | null,
	sun?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["WeekHoursWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["WeekHoursWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["WeekHoursWhere"] | undefined | null
};
	["VirtualProductWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["VirtualProductTypeCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	voucherCreditCents?: ValueTypes["IntCondition"] | undefined | null,
	physicalRepresentation?: ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined | null,
	recipientEmail?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["VirtualProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VirtualProductWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VirtualProductWhere"] | undefined | null
};
	["VirtualProductTypeCondition"]: {
	and?: Array<ValueTypes["VirtualProductTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["VirtualProductTypeCondition"]> | undefined | null,
	not?: ValueTypes["VirtualProductTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["VirtualProductType"] | undefined | null,
	notEq?: ValueTypes["VirtualProductType"] | undefined | null,
	in?: Array<ValueTypes["VirtualProductType"]> | undefined | null,
	notIn?: Array<ValueTypes["VirtualProductType"]> | undefined | null,
	lt?: ValueTypes["VirtualProductType"] | undefined | null,
	lte?: ValueTypes["VirtualProductType"] | undefined | null,
	gt?: ValueTypes["VirtualProductType"] | undefined | null,
	gte?: ValueTypes["VirtualProductType"] | undefined | null
};
	["VirtualProductType"]:VirtualProductType;
	["VirtualProductPhysicalRepresentationTypeCondition"]: {
	and?: Array<ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined | null,
	not?: ValueTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	notEq?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	in?: Array<ValueTypes["VirtualProductPhysicalRepresentationType"]> | undefined | null,
	notIn?: Array<ValueTypes["VirtualProductPhysicalRepresentationType"]> | undefined | null,
	lt?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	lte?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	gt?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null,
	gte?: ValueTypes["VirtualProductPhysicalRepresentationType"] | undefined | null
};
	["VirtualProductPhysicalRepresentationType"]:VirtualProductPhysicalRepresentationType;
	["VatRateWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	ratePermille?: ValueTypes["IntCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	channelDeliveries?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,
	channelPayments?: ValueTypes["ChannelPaymentWhere"] | undefined | null,
	preset?: ValueTypes["VatRatePresetCondition"] | undefined | null,
	and?: Array<ValueTypes["VatRateWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VatRateWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VatRateWhere"] | undefined | null
};
	["ChannelDeliveryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	channel?: ValueTypes["ChannelWhere"] | undefined | null,
	method?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	defaultVisible?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["ChannelDeliveryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ChannelDeliveryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ChannelDeliveryWhere"] | undefined | null
};
	["ChannelWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	disabled?: ValueTypes["BooleanCondition"] | undefined | null,
	payments?: ValueTypes["ChannelPaymentWhere"] | undefined | null,
	deliveries?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,
	carts?: ValueTypes["CartWhere"] | undefined | null,
	customers?: ValueTypes["CustomerWhere"] | undefined | null,
	code?: ValueTypes["ChannelCodeCondition"] | undefined | null,
	addTagsOnRegistration?: ValueTypes["TagWhere"] | undefined | null,
	publicTags?: ValueTypes["TagWhere"] | undefined | null,
	and?: Array<ValueTypes["ChannelWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ChannelWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ChannelWhere"] | undefined | null
};
	["ChannelPaymentWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	channel?: ValueTypes["ChannelWhere"] | undefined | null,
	method?: ValueTypes["PaymentMethodWhere"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	requireBillingAddress?: ValueTypes["BooleanCondition"] | undefined | null,
	provideReceipt?: ValueTypes["BooleanCondition"] | undefined | null,
	requireBillingSubject?: ValueTypes["BooleanCondition"] | undefined | null,
	allowOnlyForTags?: ValueTypes["TagWhere"] | undefined | null,
	and?: Array<ValueTypes["ChannelPaymentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ChannelPaymentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ChannelPaymentWhere"] | undefined | null
};
	["PaymentMethodWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	enabled?: ValueTypes["BooleanCondition"] | undefined | null,
	deliveryMethods?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	blockingDispatch?: ValueTypes["BooleanCondition"] | undefined | null,
	type?: ValueTypes["PaymentMethodTypeCondition"] | undefined | null,
	channels?: ValueTypes["ChannelPaymentWhere"] | undefined | null,
	reduceGratuityByPercentage?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["PaymentMethodWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PaymentMethodWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PaymentMethodWhere"] | undefined | null
};
	["DeliveryMethodWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	enabled?: ValueTypes["BooleanCondition"] | undefined | null,
	paymentMethods?: ValueTypes["PaymentMethodWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	requireDeliveryAddress?: ValueTypes["BooleanCondition"] | undefined | null,
	suitableForDeliveryZones?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	channels?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,
	enableDeliveryTracking?: ValueTypes["BooleanCondition"] | undefined | null,
	locales?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,
	timelinePreset?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryMethodWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryMethodWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryMethodWhere"] | undefined | null
};
	["DeliveryZoneWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	type?: ValueTypes["DeliveryZoneTypeCondition"] | undefined | null,
	addresses?: ValueTypes["AddressWhere"] | undefined | null,
	suitableDeliveryMethods?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	deliveryZonesOfTags?: ValueTypes["TagWhere"] | undefined | null,
	deliveryZonesOfProducts?: ValueTypes["ProductWhere"] | undefined | null,
	addressesMetadata?: ValueTypes["AddressMetadataWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryZoneWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryZoneWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryZoneWhere"] | undefined | null
};
	["DeliveryZoneTypeCondition"]: {
	and?: Array<ValueTypes["DeliveryZoneTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["DeliveryZoneTypeCondition"]> | undefined | null,
	not?: ValueTypes["DeliveryZoneTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["DeliveryZoneType"] | undefined | null,
	notEq?: ValueTypes["DeliveryZoneType"] | undefined | null,
	in?: Array<ValueTypes["DeliveryZoneType"]> | undefined | null,
	notIn?: Array<ValueTypes["DeliveryZoneType"]> | undefined | null,
	lt?: ValueTypes["DeliveryZoneType"] | undefined | null,
	lte?: ValueTypes["DeliveryZoneType"] | undefined | null,
	gt?: ValueTypes["DeliveryZoneType"] | undefined | null,
	gte?: ValueTypes["DeliveryZoneType"] | undefined | null
};
	["DeliveryZoneType"]:DeliveryZoneType;
	["AddressMetadataWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	deliveryZoneType?: ValueTypes["DeliveryZoneTypeCondition"] | undefined | null,
	address?: ValueTypes["AddressWhere"] | undefined | null,
	deliveryZone?: ValueTypes["DeliveryZoneWhere"] | undefined | null,
	and?: Array<ValueTypes["AddressMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["AddressMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["AddressMetadataWhere"] | undefined | null
};
	["DeliveryMethodLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	trackingCodeUrlTemplate?: ValueTypes["StringCondition"] | undefined | null,
	processingTitle?: ValueTypes["StringCondition"] | undefined | null,
	processingText?: ValueTypes["StringCondition"] | undefined | null,
	processingIconUrl?: ValueTypes["StringCondition"] | undefined | null,
	dispatchedTitle?: ValueTypes["StringCondition"] | undefined | null,
	dispatchedText?: ValueTypes["StringCondition"] | undefined | null,
	dispatchedIconUrl?: ValueTypes["StringCondition"] | undefined | null,
	fulfilledTitle?: ValueTypes["StringCondition"] | undefined | null,
	fulfilledText?: ValueTypes["StringCondition"] | undefined | null,
	fulfilledIconUrl?: ValueTypes["StringCondition"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	root?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null
};
	["DeliveryTimelinePresetWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["DeliveryTimelineTypeCondition"] | undefined | null,
	duration?: ValueTypes["StringCondition"] | undefined | null,
	window?: ValueTypes["StringCondition"] | undefined | null,
	deliveryMethod?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null
};
	["DeliveryTimelineTypeCondition"]: {
	and?: Array<ValueTypes["DeliveryTimelineTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["DeliveryTimelineTypeCondition"]> | undefined | null,
	not?: ValueTypes["DeliveryTimelineTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	notEq?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	in?: Array<ValueTypes["DeliveryTimelineType"]> | undefined | null,
	notIn?: Array<ValueTypes["DeliveryTimelineType"]> | undefined | null,
	lt?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	lte?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	gt?: ValueTypes["DeliveryTimelineType"] | undefined | null,
	gte?: ValueTypes["DeliveryTimelineType"] | undefined | null
};
	["DeliveryTimelineType"]:DeliveryTimelineType;
	["PaymentMethodTypeCondition"]: {
	and?: Array<ValueTypes["PaymentMethodTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["PaymentMethodTypeCondition"]> | undefined | null,
	not?: ValueTypes["PaymentMethodTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["PaymentMethodType"] | undefined | null,
	notEq?: ValueTypes["PaymentMethodType"] | undefined | null,
	in?: Array<ValueTypes["PaymentMethodType"]> | undefined | null,
	notIn?: Array<ValueTypes["PaymentMethodType"]> | undefined | null,
	lt?: ValueTypes["PaymentMethodType"] | undefined | null,
	lte?: ValueTypes["PaymentMethodType"] | undefined | null,
	gt?: ValueTypes["PaymentMethodType"] | undefined | null,
	gte?: ValueTypes["PaymentMethodType"] | undefined | null
};
	["PaymentMethodType"]:PaymentMethodType;
	["CartWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	confirmedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	deletedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	items?: ValueTypes["CartItemWhere"] | undefined | null,
	handle?: ValueTypes["StringCondition"] | undefined | null,
	state?: ValueTypes["CartStateCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	channel?: ValueTypes["ChannelWhere"] | undefined | null,
	order?: ValueTypes["OrderWhere"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	deliveryAddress?: ValueTypes["AddressWhere"] | undefined | null,
	billingAddress?: ValueTypes["AddressWhere"] | undefined | null,
	deliveryOption?: ValueTypes["StringCondition"] | undefined | null,
	paymentOption?: ValueTypes["StringCondition"] | undefined | null,
	delivery?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,
	payment?: ValueTypes["ChannelPaymentWhere"] | undefined | null,
	billingSubject?: ValueTypes["BillingSubjectWhere"] | undefined | null,
	priceLines?: ValueTypes["CartPriceLineWhere"] | undefined | null,
	summary?: ValueTypes["CartSummaryWhere"] | undefined | null,
	and?: Array<ValueTypes["CartWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CartWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CartWhere"] | undefined | null
};
	["DateTimeCondition"]: {
	and?: Array<ValueTypes["DateTimeCondition"]> | undefined | null,
	or?: Array<ValueTypes["DateTimeCondition"]> | undefined | null,
	not?: ValueTypes["DateTimeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["DateTime"] | undefined | null,
	notEq?: ValueTypes["DateTime"] | undefined | null,
	in?: Array<ValueTypes["DateTime"]> | undefined | null,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null,
	lt?: ValueTypes["DateTime"] | undefined | null,
	lte?: ValueTypes["DateTime"] | undefined | null,
	gt?: ValueTypes["DateTime"] | undefined | null,
	gte?: ValueTypes["DateTime"] | undefined | null
};
	["DateTime"]:string;
	["CartItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	quantity?: ValueTypes["IntCondition"] | undefined | null,
	cart?: ValueTypes["CartWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	updatedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	fulfillmentNote?: ValueTypes["StringCondition"] | undefined | null,
	fulfillmentNotes?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,
	and?: Array<ValueTypes["CartItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CartItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CartItemWhere"] | undefined | null
};
	["FulfillmentNoteWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	tags?: ValueTypes["TagWhere"] | undefined | null,
	cartItems?: ValueTypes["CartItemWhere"] | undefined | null,
	orderItems?: ValueTypes["OrderItemWhere"] | undefined | null,
	orders?: ValueTypes["OrderWhere"] | undefined | null,
	highlight?: ValueTypes["BooleanCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	emoji?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["FulfillmentNoteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["FulfillmentNoteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["FulfillmentNoteWhere"] | undefined | null
};
	["OrderItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	unitPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	quantity?: ValueTypes["IntCondition"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	order?: ValueTypes["OrderWhere"] | undefined | null,
	virtualProductEffects?: ValueTypes["VirtualProductEffectWhere"] | undefined | null,
	pickedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	fulfillmentNotes?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderItemWhere"] | undefined | null
};
	["OrderWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	state?: ValueTypes["OrderStateCondition"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	canceledAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	channel?: ValueTypes["ChannelWhere"] | undefined | null,
	discounts?: ValueTypes["OrderDiscountWhere"] | undefined | null,
	delivery?: ValueTypes["OrderDeliveryWhere"] | undefined | null,
	items?: ValueTypes["OrderItemWhere"] | undefined | null,
	cart?: ValueTypes["CartWhere"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	billingAddress?: ValueTypes["AddressWhere"] | undefined | null,
	priceLines?: ValueTypes["OrderPriceLineWhere"] | undefined | null,
	payments?: ValueTypes["OrderPaymentWhere"] | undefined | null,
	seq?: ValueTypes["IntCondition"] | undefined | null,
	billingSubject?: ValueTypes["BillingSubjectWhere"] | undefined | null,
	summary?: ValueTypes["OrderSummaryWhere"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["StringCondition"] | undefined | null,
	fulfillmentNote?: ValueTypes["StringCondition"] | undefined | null,
	fulfillmentNotes?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,
	doubleshotTotalWeightGrams?: ValueTypes["IntCondition"] | undefined | null,
	doubleShotLegacyNumber?: ValueTypes["StringCondition"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	doubleshotAdminUrl?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["OrderWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderWhere"] | undefined | null
};
	["OrderStateCondition"]: {
	and?: Array<ValueTypes["OrderStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["OrderStateCondition"]> | undefined | null,
	not?: ValueTypes["OrderStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["OrderState"] | undefined | null,
	notEq?: ValueTypes["OrderState"] | undefined | null,
	in?: Array<ValueTypes["OrderState"]> | undefined | null,
	notIn?: Array<ValueTypes["OrderState"]> | undefined | null,
	lt?: ValueTypes["OrderState"] | undefined | null,
	lte?: ValueTypes["OrderState"] | undefined | null,
	gt?: ValueTypes["OrderState"] | undefined | null,
	gte?: ValueTypes["OrderState"] | undefined | null
};
	["OrderState"]:OrderState;
	["OrderDiscountWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	discountCents?: ValueTypes["IntCondition"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	order?: ValueTypes["OrderWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	type?: ValueTypes["OrderDiscountTypeCondition"] | undefined | null,
	voucherRedemption?: ValueTypes["VoucherRedemptionWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderDiscountWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderDiscountWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderDiscountWhere"] | undefined | null
};
	["OrderDiscountTypeCondition"]: {
	and?: Array<ValueTypes["OrderDiscountTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["OrderDiscountTypeCondition"]> | undefined | null,
	not?: ValueTypes["OrderDiscountTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["OrderDiscountType"] | undefined | null,
	notEq?: ValueTypes["OrderDiscountType"] | undefined | null,
	in?: Array<ValueTypes["OrderDiscountType"]> | undefined | null,
	notIn?: Array<ValueTypes["OrderDiscountType"]> | undefined | null,
	lt?: ValueTypes["OrderDiscountType"] | undefined | null,
	lte?: ValueTypes["OrderDiscountType"] | undefined | null,
	gt?: ValueTypes["OrderDiscountType"] | undefined | null,
	gte?: ValueTypes["OrderDiscountType"] | undefined | null
};
	["OrderDiscountType"]:OrderDiscountType;
	["VoucherRedemptionWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	manualRedemptionNote?: ValueTypes["StringCondition"] | undefined | null,
	customerCredit?: ValueTypes["CustomerCreditWhere"] | undefined | null,
	orderDiscount?: ValueTypes["OrderDiscountWhere"] | undefined | null,
	voucher?: ValueTypes["VoucherWhere"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["VoucherRedemptionWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VoucherRedemptionWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VoucherRedemptionWhere"] | undefined | null
};
	["CustomerCreditWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	initialCreditCents?: ValueTypes["IntCondition"] | undefined | null,
	remainingCreditsCents?: ValueTypes["IntCondition"] | undefined | null,
	expiresAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	reason?: ValueTypes["StringCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	voucherRedemption?: ValueTypes["VoucherRedemptionWhere"] | undefined | null,
	invoiceUrl?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["CustomerCreditWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CustomerCreditWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CustomerCreditWhere"] | undefined | null
};
	["VoucherWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	activatedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	expiresAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	type?: ValueTypes["VoucherTypeCondition"] | undefined | null,
	creditCents?: ValueTypes["IntCondition"] | undefined | null,
	buyer?: ValueTypes["CustomerWhere"] | undefined | null,
	owner?: ValueTypes["CustomerWhere"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	virtualProductEffect?: ValueTypes["VirtualProductEffectWhere"] | undefined | null,
	redemption?: ValueTypes["VoucherRedemptionWhere"] | undefined | null,
	and?: Array<ValueTypes["VoucherWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VoucherWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VoucherWhere"] | undefined | null
};
	["VoucherTypeCondition"]: {
	and?: Array<ValueTypes["VoucherTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["VoucherTypeCondition"]> | undefined | null,
	not?: ValueTypes["VoucherTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["VoucherType"] | undefined | null,
	notEq?: ValueTypes["VoucherType"] | undefined | null,
	in?: Array<ValueTypes["VoucherType"]> | undefined | null,
	notIn?: Array<ValueTypes["VoucherType"]> | undefined | null,
	lt?: ValueTypes["VoucherType"] | undefined | null,
	lte?: ValueTypes["VoucherType"] | undefined | null,
	gt?: ValueTypes["VoucherType"] | undefined | null,
	gte?: ValueTypes["VoucherType"] | undefined | null
};
	["VoucherType"]:VoucherType;
	["VirtualProductEffectWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	succeededAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	orderItem?: ValueTypes["OrderItemWhere"] | undefined | null,
	failedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	revertedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	resultVouchers?: ValueTypes["VoucherWhere"] | undefined | null,
	and?: Array<ValueTypes["VirtualProductEffectWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VirtualProductEffectWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VirtualProductEffectWhere"] | undefined | null
};
	["OrderDeliveryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	deliveredAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	method?: ValueTypes["DeliveryMethodWhere"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	order?: ValueTypes["OrderWhere"] | undefined | null,
	expectedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	state?: ValueTypes["OrderDeliveryStateCondition"] | undefined | null,
	loadingDurationHours?: ValueTypes["IntCondition"] | undefined | null,
	dispatchAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	duration?: ValueTypes["StringCondition"] | undefined | null,
	window?: ValueTypes["StringCondition"] | undefined | null,
	expectedEndAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	processingAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	deliveringAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	failedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	prepareAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	pickedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	address?: ValueTypes["AddressWhere"] | undefined | null,
	trackingCode?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["OrderDeliveryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderDeliveryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderDeliveryWhere"] | undefined | null
};
	["OrderDeliveryStateCondition"]: {
	and?: Array<ValueTypes["OrderDeliveryStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["OrderDeliveryStateCondition"]> | undefined | null,
	not?: ValueTypes["OrderDeliveryStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["OrderDeliveryState"] | undefined | null,
	notEq?: ValueTypes["OrderDeliveryState"] | undefined | null,
	in?: Array<ValueTypes["OrderDeliveryState"]> | undefined | null,
	notIn?: Array<ValueTypes["OrderDeliveryState"]> | undefined | null,
	lt?: ValueTypes["OrderDeliveryState"] | undefined | null,
	lte?: ValueTypes["OrderDeliveryState"] | undefined | null,
	gt?: ValueTypes["OrderDeliveryState"] | undefined | null,
	gte?: ValueTypes["OrderDeliveryState"] | undefined | null
};
	["OrderDeliveryState"]:OrderDeliveryState;
	["OrderPriceLineWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	unitPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	quantity?: ValueTypes["IntCondition"] | undefined | null,
	type?: ValueTypes["OrderPriceLineTypeCondition"] | undefined | null,
	orderItem?: ValueTypes["OrderItemWhere"] | undefined | null,
	orderDiscount?: ValueTypes["OrderDiscountWhere"] | undefined | null,
	orderPayment?: ValueTypes["OrderPaymentWhere"] | undefined | null,
	orderDelivery?: ValueTypes["OrderDeliveryWhere"] | undefined | null,
	vatRatePermille?: ValueTypes["IntCondition"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	order?: ValueTypes["OrderWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderPriceLineWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderPriceLineWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderPriceLineWhere"] | undefined | null
};
	["OrderPriceLineTypeCondition"]: {
	and?: Array<ValueTypes["OrderPriceLineTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["OrderPriceLineTypeCondition"]> | undefined | null,
	not?: ValueTypes["OrderPriceLineTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["OrderPriceLineType"] | undefined | null,
	notEq?: ValueTypes["OrderPriceLineType"] | undefined | null,
	in?: Array<ValueTypes["OrderPriceLineType"]> | undefined | null,
	notIn?: Array<ValueTypes["OrderPriceLineType"]> | undefined | null,
	lt?: ValueTypes["OrderPriceLineType"] | undefined | null,
	lte?: ValueTypes["OrderPriceLineType"] | undefined | null,
	gt?: ValueTypes["OrderPriceLineType"] | undefined | null,
	gte?: ValueTypes["OrderPriceLineType"] | undefined | null
};
	["OrderPriceLineType"]:OrderPriceLineType;
	["OrderPaymentWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	data?: ValueTypes["JsonCondition"] | undefined | null,
	paymentCents?: ValueTypes["IntCondition"] | undefined | null,
	method?: ValueTypes["PaymentMethodWhere"] | undefined | null,
	failedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	state?: ValueTypes["OrderPaymentStateCondition"] | undefined | null,
	approvedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	order?: ValueTypes["OrderWhere"] | undefined | null,
	invoice?: ValueTypes["InvoiceWhere"] | undefined | null,
	walletType?: ValueTypes["StringCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	publicReceiptUrl?: ValueTypes["StringCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	meta?: ValueTypes["OrderPaymentMetadataWhere"] | undefined | null,
	otherCurrencyPriceCents?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["OrderPaymentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderPaymentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderPaymentWhere"] | undefined | null
};
	["JsonCondition"]: {
	and?: Array<ValueTypes["JsonCondition"]> | undefined | null,
	or?: Array<ValueTypes["JsonCondition"]> | undefined | null,
	not?: ValueTypes["JsonCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null
};
	["OrderPaymentStateCondition"]: {
	and?: Array<ValueTypes["OrderPaymentStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["OrderPaymentStateCondition"]> | undefined | null,
	not?: ValueTypes["OrderPaymentStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["OrderPaymentState"] | undefined | null,
	notEq?: ValueTypes["OrderPaymentState"] | undefined | null,
	in?: Array<ValueTypes["OrderPaymentState"]> | undefined | null,
	notIn?: Array<ValueTypes["OrderPaymentState"]> | undefined | null,
	lt?: ValueTypes["OrderPaymentState"] | undefined | null,
	lte?: ValueTypes["OrderPaymentState"] | undefined | null,
	gt?: ValueTypes["OrderPaymentState"] | undefined | null,
	gte?: ValueTypes["OrderPaymentState"] | undefined | null
};
	["OrderPaymentState"]:OrderPaymentState;
	["InvoiceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	publicKey?: ValueTypes["StringCondition"] | undefined | null,
	fakturoidId?: ValueTypes["StringCondition"] | undefined | null,
	fakturoidData?: ValueTypes["StringCondition"] | undefined | null,
	legacyUrl?: ValueTypes["StringCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	orderPayments?: ValueTypes["OrderPaymentWhere"] | undefined | null,
	address?: ValueTypes["AddressWhere"] | undefined | null,
	publicUrl?: ValueTypes["StringCondition"] | undefined | null,
	billingSubject?: ValueTypes["BillingSubjectWhere"] | undefined | null,
	and?: Array<ValueTypes["InvoiceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["InvoiceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["InvoiceWhere"] | undefined | null
};
	["BillingSubjectWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	fullName?: ValueTypes["StringCondition"] | undefined | null,
	addressLine1?: ValueTypes["StringCondition"] | undefined | null,
	addressLine2?: ValueTypes["StringCondition"] | undefined | null,
	city?: ValueTypes["StringCondition"] | undefined | null,
	postalCode?: ValueTypes["StringCondition"] | undefined | null,
	email?: ValueTypes["StringCondition"] | undefined | null,
	phone?: ValueTypes["StringCondition"] | undefined | null,
	companyName?: ValueTypes["StringCondition"] | undefined | null,
	companyIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	vatIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	externalIdentifier?: ValueTypes["StringCondition"] | undefined | null,
	hidden?: ValueTypes["BooleanCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	deletedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	fakturoidSubjectId?: ValueTypes["StringCondition"] | undefined | null,
	country?: ValueTypes["CountryWhere"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	invoices?: ValueTypes["InvoiceWhere"] | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["CustomerWhere"] | undefined | null,
	orders?: ValueTypes["OrderWhere"] | undefined | null,
	and?: Array<ValueTypes["BillingSubjectWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BillingSubjectWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BillingSubjectWhere"] | undefined | null
};
	["CountryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["CountryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CountryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CountryWhere"] | undefined | null
};
	["OrderPaymentMetadataWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	paymentUrl?: ValueTypes["StringCondition"] | undefined | null,
	orderPayment?: ValueTypes["OrderPaymentWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderPaymentMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderPaymentMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderPaymentMetadataWhere"] | undefined | null
};
	["OrderSummaryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	totalPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	discountPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	undiscountedPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	itemsQuantity?: ValueTypes["IntCondition"] | undefined | null,
	orderUrl?: ValueTypes["StringCondition"] | undefined | null,
	orderNumber?: ValueTypes["StringCondition"] | undefined | null,
	order?: ValueTypes["OrderWhere"] | undefined | null,
	and?: Array<ValueTypes["OrderSummaryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["OrderSummaryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["OrderSummaryWhere"] | undefined | null
};
	["CartStateCondition"]: {
	and?: Array<ValueTypes["CartStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["CartStateCondition"]> | undefined | null,
	not?: ValueTypes["CartStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["CartState"] | undefined | null,
	notEq?: ValueTypes["CartState"] | undefined | null,
	in?: Array<ValueTypes["CartState"]> | undefined | null,
	notIn?: Array<ValueTypes["CartState"]> | undefined | null,
	lt?: ValueTypes["CartState"] | undefined | null,
	lte?: ValueTypes["CartState"] | undefined | null,
	gt?: ValueTypes["CartState"] | undefined | null,
	gte?: ValueTypes["CartState"] | undefined | null
};
	["CartState"]:CartState;
	["CartPriceLineWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["CartPriceLineTypeCondition"] | undefined | null,
	item?: ValueTypes["CartItemWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	unitPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	quantity?: ValueTypes["IntCondition"] | undefined | null,
	vatRatePermille?: ValueTypes["IntCondition"] | undefined | null,
	vatRate?: ValueTypes["VatRateWhere"] | undefined | null,
	cart?: ValueTypes["CartWhere"] | undefined | null,
	and?: Array<ValueTypes["CartPriceLineWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CartPriceLineWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CartPriceLineWhere"] | undefined | null
};
	["CartPriceLineTypeCondition"]: {
	and?: Array<ValueTypes["CartPriceLineTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["CartPriceLineTypeCondition"]> | undefined | null,
	not?: ValueTypes["CartPriceLineTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["CartPriceLineType"] | undefined | null,
	notEq?: ValueTypes["CartPriceLineType"] | undefined | null,
	in?: Array<ValueTypes["CartPriceLineType"]> | undefined | null,
	notIn?: Array<ValueTypes["CartPriceLineType"]> | undefined | null,
	lt?: ValueTypes["CartPriceLineType"] | undefined | null,
	lte?: ValueTypes["CartPriceLineType"] | undefined | null,
	gt?: ValueTypes["CartPriceLineType"] | undefined | null,
	gte?: ValueTypes["CartPriceLineType"] | undefined | null
};
	["CartPriceLineType"]:CartPriceLineType;
	["CartSummaryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	itemsPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	deliveryPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	totalPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	itemsCount?: ValueTypes["IntCondition"] | undefined | null,
	itemsQuantity?: ValueTypes["IntCondition"] | undefined | null,
	checkoutUrl?: ValueTypes["StringCondition"] | undefined | null,
	cart?: ValueTypes["CartWhere"] | undefined | null,
	and?: Array<ValueTypes["CartSummaryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CartSummaryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CartSummaryWhere"] | undefined | null
};
	["ChannelCodeCondition"]: {
	and?: Array<ValueTypes["ChannelCodeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ChannelCodeCondition"]> | undefined | null,
	not?: ValueTypes["ChannelCodeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ChannelCode"] | undefined | null,
	notEq?: ValueTypes["ChannelCode"] | undefined | null,
	in?: Array<ValueTypes["ChannelCode"]> | undefined | null,
	notIn?: Array<ValueTypes["ChannelCode"]> | undefined | null,
	lt?: ValueTypes["ChannelCode"] | undefined | null,
	lte?: ValueTypes["ChannelCode"] | undefined | null,
	gt?: ValueTypes["ChannelCode"] | undefined | null,
	gte?: ValueTypes["ChannelCode"] | undefined | null
};
	["ChannelCode"]:ChannelCode;
	["VatRatePresetCondition"]: {
	and?: Array<ValueTypes["VatRatePresetCondition"]> | undefined | null,
	or?: Array<ValueTypes["VatRatePresetCondition"]> | undefined | null,
	not?: ValueTypes["VatRatePresetCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["VatRatePreset"] | undefined | null,
	notEq?: ValueTypes["VatRatePreset"] | undefined | null,
	in?: Array<ValueTypes["VatRatePreset"]> | undefined | null,
	notIn?: Array<ValueTypes["VatRatePreset"]> | undefined | null,
	lt?: ValueTypes["VatRatePreset"] | undefined | null,
	lte?: ValueTypes["VatRatePreset"] | undefined | null,
	gt?: ValueTypes["VatRatePreset"] | undefined | null,
	gte?: ValueTypes["VatRatePreset"] | undefined | null
};
	["VatRatePreset"]:VatRatePreset;
	["BlogLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	pageName?: ValueTypes["StringCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	lead?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["BlogWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogLocaleWhere"] | undefined | null
};
	["BlogWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	unique?: ValueTypes["OneCondition"] | undefined | null,
	locales?: ValueTypes["BlogLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogWhere"] | undefined | null
};
	["BlogPostLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	availableForWeb?: ValueTypes["BooleanCondition"] | undefined | null,
	availableForMobile?: ValueTypes["BooleanCondition"] | undefined | null,
	root?: ValueTypes["BlogPostWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	cover?: ValueTypes["CoverWhere"] | undefined | null,
	content?: ValueTypes["ContentWhere"] | undefined | null,
	link?: ValueTypes["LinkableWhere"] | undefined | null,
	seo?: ValueTypes["SeoWhere"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostLocaleWhere"] | undefined | null
};
	["BlogPostWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	locales?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostWhere"] | undefined | null
};
	["CoverWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	primaryTitle?: ValueTypes["StringCondition"] | undefined | null,
	secondaryTitle?: ValueTypes["StringCondition"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	buttonColors?: ValueTypes["ColorPalleteWhere"] | undefined | null,
	and?: Array<ValueTypes["CoverWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CoverWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CoverWhere"] | undefined | null
};
	["MediumWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["MediumTypeCondition"] | undefined | null,
	colorTheme?: ValueTypes["ColorThemeCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	video?: ValueTypes["VideoWhere"] | undefined | null,
	and?: Array<ValueTypes["MediumWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["MediumWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["MediumWhere"] | undefined | null
};
	["MediumTypeCondition"]: {
	and?: Array<ValueTypes["MediumTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["MediumTypeCondition"]> | undefined | null,
	not?: ValueTypes["MediumTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["MediumType"] | undefined | null,
	notEq?: ValueTypes["MediumType"] | undefined | null,
	in?: Array<ValueTypes["MediumType"]> | undefined | null,
	notIn?: Array<ValueTypes["MediumType"]> | undefined | null,
	lt?: ValueTypes["MediumType"] | undefined | null,
	lte?: ValueTypes["MediumType"] | undefined | null,
	gt?: ValueTypes["MediumType"] | undefined | null,
	gte?: ValueTypes["MediumType"] | undefined | null
};
	["MediumType"]:MediumType;
	["ColorThemeCondition"]: {
	and?: Array<ValueTypes["ColorThemeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ColorThemeCondition"]> | undefined | null,
	not?: ValueTypes["ColorThemeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ColorTheme"] | undefined | null,
	notEq?: ValueTypes["ColorTheme"] | undefined | null,
	in?: Array<ValueTypes["ColorTheme"]> | undefined | null,
	notIn?: Array<ValueTypes["ColorTheme"]> | undefined | null,
	lt?: ValueTypes["ColorTheme"] | undefined | null,
	lte?: ValueTypes["ColorTheme"] | undefined | null,
	gt?: ValueTypes["ColorTheme"] | undefined | null,
	gte?: ValueTypes["ColorTheme"] | undefined | null
};
	["ColorTheme"]:ColorTheme;
	["VideoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	src?: ValueTypes["StringCondition"] | undefined | null,
	width?: ValueTypes["IntCondition"] | undefined | null,
	height?: ValueTypes["IntCondition"] | undefined | null,
	size?: ValueTypes["IntCondition"] | undefined | null,
	type?: ValueTypes["StringCondition"] | undefined | null,
	duration?: ValueTypes["FloatCondition"] | undefined | null,
	poster?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["VideoWhere"] | undefined | null
};
	["ColorPalleteWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	background?: ValueTypes["StringCondition"] | undefined | null,
	text?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ColorPalleteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ColorPalleteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ColorPalleteWhere"] | undefined | null
};
	["ContentWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	blocks?: ValueTypes["ContentBlockWhere"] | undefined | null,
	and?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ContentWhere"] | undefined | null
};
	["ContentBlockWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	json?: ValueTypes["StringCondition"] | undefined | null,
	content?: ValueTypes["ContentWhere"] | undefined | null,
	references?: ValueTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ContentBlockWhere"] | undefined | null
};
	["ContentReferenceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null,
	primaryText?: ValueTypes["StringCondition"] | undefined | null,
	secondaryText?: ValueTypes["StringCondition"] | undefined | null,
	jsonContent?: ValueTypes["StringCondition"] | undefined | null,
	block?: ValueTypes["ContentBlockWhere"] | undefined | null,
	colorPallete?: ValueTypes["ColorPalleteWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostListWhere"] | undefined | null,
	links?: ValueTypes["LinkListWhere"] | undefined | null,
	products?: ValueTypes["ProductListWhere"] | undefined | null,
	contentSize?: ValueTypes["ContentSizeCondition"] | undefined | null,
	misc?: ValueTypes["StringCondition"] | undefined | null,
	hero?: ValueTypes["HeroWhere"] | undefined | null,
	gallery?: ValueTypes["GalleryWhere"] | undefined | null,
	socialsAndApps?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,
	productGrid?: ValueTypes["ProductGridWhere"] | undefined | null,
	productBanners?: ValueTypes["ProductBannerListWhere"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,
	textList?: ValueTypes["TextListWhere"] | undefined | null,
	gridTiles?: ValueTypes["GridTilesWhere"] | undefined | null,
	and?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ContentReferenceWhere"] | undefined | null
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null,
	not?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ContentReferenceType"] | undefined | null,
	notEq?: ValueTypes["ContentReferenceType"] | undefined | null,
	in?: Array<ValueTypes["ContentReferenceType"]> | undefined | null,
	notIn?: Array<ValueTypes["ContentReferenceType"]> | undefined | null,
	lt?: ValueTypes["ContentReferenceType"] | undefined | null,
	lte?: ValueTypes["ContentReferenceType"] | undefined | null,
	gt?: ValueTypes["ContentReferenceType"] | undefined | null,
	gte?: ValueTypes["ContentReferenceType"] | undefined | null
};
	["ContentReferenceType"]:ContentReferenceType;
	["LinkWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["LinkTypeCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	externalLink?: ValueTypes["StringCondition"] | undefined | null,
	internalLink?: ValueTypes["LinkableWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkWhere"] | undefined | null
};
	["LinkTypeCondition"]: {
	and?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null,
	not?: ValueTypes["LinkTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["LinkType"] | undefined | null,
	notEq?: ValueTypes["LinkType"] | undefined | null,
	in?: Array<ValueTypes["LinkType"]> | undefined | null,
	notIn?: Array<ValueTypes["LinkType"]> | undefined | null,
	lt?: ValueTypes["LinkType"] | undefined | null,
	lte?: ValueTypes["LinkType"] | undefined | null,
	gt?: ValueTypes["LinkType"] | undefined | null,
	gte?: ValueTypes["LinkType"] | undefined | null
};
	["LinkType"]:LinkType;
	["LinkableWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	url?: ValueTypes["StringCondition"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	page?: ValueTypes["PageLocaleWhere"] | undefined | null,
	redirect?: ValueTypes["RedirectWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkableWhere"] | undefined | null
};
	["PageLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	link?: ValueTypes["LinkableWhere"] | undefined | null,
	root?: ValueTypes["PageWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	seo?: ValueTypes["SeoWhere"] | undefined | null,
	content?: ValueTypes["ContentWhere"] | undefined | null,
	cover?: ValueTypes["CoverWhere"] | undefined | null,
	theme?: ValueTypes["PageThemeCondition"] | undefined | null,
	and?: Array<ValueTypes["PageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PageLocaleWhere"] | undefined | null
};
	["PageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	locales?: ValueTypes["PageLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["PageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PageWhere"] | undefined | null
};
	["SeoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	ogTitle?: ValueTypes["StringCondition"] | undefined | null,
	ogDescription?: ValueTypes["StringCondition"] | undefined | null,
	ogImage?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SeoWhere"] | undefined | null
};
	["PageThemeCondition"]: {
	and?: Array<ValueTypes["PageThemeCondition"]> | undefined | null,
	or?: Array<ValueTypes["PageThemeCondition"]> | undefined | null,
	not?: ValueTypes["PageThemeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["PageTheme"] | undefined | null,
	notEq?: ValueTypes["PageTheme"] | undefined | null,
	in?: Array<ValueTypes["PageTheme"]> | undefined | null,
	notIn?: Array<ValueTypes["PageTheme"]> | undefined | null,
	lt?: ValueTypes["PageTheme"] | undefined | null,
	lte?: ValueTypes["PageTheme"] | undefined | null,
	gt?: ValueTypes["PageTheme"] | undefined | null,
	gte?: ValueTypes["PageTheme"] | undefined | null
};
	["PageTheme"]:PageTheme;
	["RedirectWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	link?: ValueTypes["LinkableWhere"] | undefined | null,
	target?: ValueTypes["LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["RedirectWhere"] | undefined | null
};
	["BlogPostListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["BlogPostListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostListWhere"] | undefined | null
};
	["BlogPostListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	caption?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["BlogPostListWhere"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["BlogPostListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["BlogPostListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["BlogPostListItemWhere"] | undefined | null
};
	["LinkListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["LinkListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkListWhere"] | undefined | null
};
	["LinkListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	isPrimary?: ValueTypes["BooleanCondition"] | undefined | null,
	list?: ValueTypes["LinkListWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["LinkListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["LinkListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["LinkListItemWhere"] | undefined | null
};
	["ContentSizeCondition"]: {
	and?: Array<ValueTypes["ContentSizeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ContentSizeCondition"]> | undefined | null,
	not?: ValueTypes["ContentSizeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ContentSize"] | undefined | null,
	notEq?: ValueTypes["ContentSize"] | undefined | null,
	in?: Array<ValueTypes["ContentSize"]> | undefined | null,
	notIn?: Array<ValueTypes["ContentSize"]> | undefined | null,
	lt?: ValueTypes["ContentSize"] | undefined | null,
	lte?: ValueTypes["ContentSize"] | undefined | null,
	gt?: ValueTypes["ContentSize"] | undefined | null,
	gte?: ValueTypes["ContentSize"] | undefined | null
};
	["ContentSize"]:ContentSize;
	["HeroWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	showLogo?: ValueTypes["BooleanCondition"] | undefined | null,
	middleContent?: ValueTypes["StringCondition"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	and?: Array<ValueTypes["HeroWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["HeroWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["HeroWhere"] | undefined | null
};
	["GalleryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["GalleryItemWhere"] | undefined | null,
	and?: Array<ValueTypes["GalleryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GalleryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GalleryWhere"] | undefined | null
};
	["GalleryItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	caption?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["GalleryWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	and?: Array<ValueTypes["GalleryItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GalleryItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GalleryItemWhere"] | undefined | null
};
	["SocialsAndAppsWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	instagram?: ValueTypes["BooleanCondition"] | undefined | null,
	appStore?: ValueTypes["BooleanCondition"] | undefined | null,
	googlePlay?: ValueTypes["BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["SocialsAndAppsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SocialsAndAppsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SocialsAndAppsWhere"] | undefined | null
};
	["ProductGridWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	isCarousel?: ValueTypes["BooleanCondition"] | undefined | null,
	categories?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,
	type?: ValueTypes["ProductGridTypeCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductGridWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductGridWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductGridWhere"] | undefined | null
};
	["ProductGridCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductGridWhere"] | undefined | null,
	items?: ValueTypes["ProductGridItemWhere"] | undefined | null,
	images?: ValueTypes["ImageListWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductGridCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductGridCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductGridCategoryWhere"] | undefined | null
};
	["ProductGridItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	lead?: ValueTypes["StringCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	category?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductGridItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductGridItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductGridItemWhere"] | undefined | null
};
	["ImageListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ImageListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ImageListWhere"] | undefined | null
};
	["ImageListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ImageListWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ImageListItemWhere"] | undefined | null
};
	["ProductGridTypeCondition"]: {
	and?: Array<ValueTypes["ProductGridTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ProductGridTypeCondition"]> | undefined | null,
	not?: ValueTypes["ProductGridTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ProductGridType"] | undefined | null,
	notEq?: ValueTypes["ProductGridType"] | undefined | null,
	in?: Array<ValueTypes["ProductGridType"]> | undefined | null,
	notIn?: Array<ValueTypes["ProductGridType"]> | undefined | null,
	lt?: ValueTypes["ProductGridType"] | undefined | null,
	lte?: ValueTypes["ProductGridType"] | undefined | null,
	gt?: ValueTypes["ProductGridType"] | undefined | null,
	gte?: ValueTypes["ProductGridType"] | undefined | null
};
	["ProductGridType"]:ProductGridType;
	["ProductBannerListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["ProductBannerItemWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductBannerListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductBannerListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductBannerListWhere"] | undefined | null
};
	["ProductBannerItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	list?: ValueTypes["ProductBannerListWhere"] | undefined | null,
	banner?: ValueTypes["ProductBannerWhere"] | undefined | null,
	size?: ValueTypes["ProductBannerItemSizeCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductBannerItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductBannerItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductBannerItemWhere"] | undefined | null
};
	["ProductBannerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	beforeTitle?: ValueTypes["StringCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	label?: ValueTypes["StringCondition"] | undefined | null,
	button?: ValueTypes["LinkWhere"] | undefined | null,
	backgroundImage?: ValueTypes["ImageWhere"] | undefined | null,
	productImage?: ValueTypes["ImageWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductBannerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductBannerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductBannerWhere"] | undefined | null
};
	["ProductBannerItemSizeCondition"]: {
	and?: Array<ValueTypes["ProductBannerItemSizeCondition"]> | undefined | null,
	or?: Array<ValueTypes["ProductBannerItemSizeCondition"]> | undefined | null,
	not?: ValueTypes["ProductBannerItemSizeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	notEq?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	in?: Array<ValueTypes["ProductBannerItemSize"]> | undefined | null,
	notIn?: Array<ValueTypes["ProductBannerItemSize"]> | undefined | null,
	lt?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	lte?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	gt?: ValueTypes["ProductBannerItemSize"] | undefined | null,
	gte?: ValueTypes["ProductBannerItemSize"] | undefined | null
};
	["ProductBannerItemSize"]:ProductBannerItemSize;
	["DeliveryRegionsWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryRegionsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryRegionsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryRegionsWhere"] | undefined | null
};
	["DeliveryRegionsItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	offerLabel?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	textList?: ValueTypes["TextListWhere"] | undefined | null,
	gallery?: ValueTypes["ImageListWhere"] | undefined | null,
	and?: Array<ValueTypes["DeliveryRegionsItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["DeliveryRegionsItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null
};
	["TextListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["TextItemWhere"] | undefined | null,
	and?: Array<ValueTypes["TextListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TextListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TextListWhere"] | undefined | null
};
	["TextItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	text?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["TextListWhere"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["TextItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TextItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TextItemWhere"] | undefined | null
};
	["GridTilesWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["GridTileItemWhere"] | undefined | null,
	and?: Array<ValueTypes["GridTilesWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GridTilesWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GridTilesWhere"] | undefined | null
};
	["GridTileItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	text?: ValueTypes["StringCondition"] | undefined | null,
	list?: ValueTypes["GridTilesWhere"] | undefined | null,
	medium?: ValueTypes["MediumWhere"] | undefined | null,
	link?: ValueTypes["LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["GridTileItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GridTileItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GridTileItemWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null
};
	["TranslationCatalogueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	domain?: ValueTypes["TranslationDomainWhere"] | undefined | null,
	fallback?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	values?: ValueTypes["TranslationValueWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationCatalogueWhere"] | undefined | null
};
	["TranslationDomainWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	identifier?: ValueTypes["StringCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	catalogues?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	keys?: ValueTypes["TranslationKeyWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationDomainWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationDomainWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationDomainWhere"] | undefined | null
};
	["TranslationKeyWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	identifier?: ValueTypes["StringCondition"] | undefined | null,
	contentType?: ValueTypes["TranslationContentTypeCondition"] | undefined | null,
	note?: ValueTypes["StringCondition"] | undefined | null,
	domain?: ValueTypes["TranslationDomainWhere"] | undefined | null,
	values?: ValueTypes["TranslationValueWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationKeyWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationKeyWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationKeyWhere"] | undefined | null
};
	["TranslationContentTypeCondition"]: {
	and?: Array<ValueTypes["TranslationContentTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["TranslationContentTypeCondition"]> | undefined | null,
	not?: ValueTypes["TranslationContentTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["TranslationContentType"] | undefined | null,
	notEq?: ValueTypes["TranslationContentType"] | undefined | null,
	in?: Array<ValueTypes["TranslationContentType"]> | undefined | null,
	notIn?: Array<ValueTypes["TranslationContentType"]> | undefined | null,
	lt?: ValueTypes["TranslationContentType"] | undefined | null,
	lte?: ValueTypes["TranslationContentType"] | undefined | null,
	gt?: ValueTypes["TranslationContentType"] | undefined | null,
	gte?: ValueTypes["TranslationContentType"] | undefined | null
};
	["TranslationContentType"]:TranslationContentType;
	["TranslationValueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	value?: ValueTypes["StringCondition"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,
	key?: ValueTypes["TranslationKeyWhere"] | undefined | null,
	and?: Array<ValueTypes["TranslationValueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["TranslationValueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["TranslationValueWhere"] | undefined | null
};
	["ProductLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	featureList?: ValueTypes["TextListWhere"] | undefined | null,
	printableName?: ValueTypes["StringCondition"] | undefined | null,
	firstSticker?: ValueTypes["StringCondition"] | undefined | null,
	secondSticker?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductLocaleWhere"] | undefined | null
};
	["ProductRecipeLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductRecipeLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductRecipeLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null
};
	["ProductRecipeWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	products?: ValueTypes["ProductWhere"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	color?: ValueTypes["StringCondition"] | undefined | null,
	image?: ValueTypes["ImageWhere"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	ingredients?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,
	needsPrepping?: ValueTypes["BooleanCondition"] | undefined | null,
	locales?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,
	gutTuning?: ValueTypes["FloatCondition"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerWhere"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["ProductRecipeWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductRecipeWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductRecipeWhere"] | undefined | null
};
	["ProductIngredientItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	internalOrder?: ValueTypes["IntCondition"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeWhere"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductIngredientItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductIngredientItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductIngredientItemWhere"] | undefined | null
};
	["IdentificationCodeLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	successMessage?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["IdentificationCodeWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null
};
	["IdentificationCodeWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	locales?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["IdentificationCodeWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["IdentificationCodeWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["IdentificationCodeWhere"] | undefined | null
};
	["SubscriptionBoxLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	stickerText?: ValueTypes["StringCondition"] | undefined | null,
	descriptionText?: ValueTypes["StringCondition"] | undefined | null,
	root?: ValueTypes["SubscriptionBoxWhere"] | undefined | null,
	locale?: ValueTypes["LocaleWhere"] | undefined | null,
	secondaryTitle?: ValueTypes["StringCondition"] | undefined | null,
	texts?: ValueTypes["TextListWhere"] | undefined | null,
	secondaryTexts?: ValueTypes["TextListWhere"] | undefined | null,
	and?: Array<ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null
};
	["SubscriptionBoxWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	locales?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,
	unique?: ValueTypes["OneCondition"] | undefined | null,
	and?: Array<ValueTypes["SubscriptionBoxWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["SubscriptionBoxWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["SubscriptionBoxWhere"] | undefined | null
};
	["ProductStockWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	quantity?: ValueTypes["IntCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	store?: ValueTypes["StoreWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductStockWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductStockWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductStockWhere"] | undefined | null
};
	["StoreWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	code?: ValueTypes["StringCondition"] | undefined | null,
	description?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["StoreWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StoreWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StoreWhere"] | undefined | null
};
	["ProductGroupPriceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	group?: ValueTypes["CustomerGroupWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductGroupPriceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductGroupPriceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductGroupPriceWhere"] | undefined | null
};
	["CustomerGroupWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	productPrices?: ValueTypes["ProductGroupPriceWhere"] | undefined | null,
	customers?: ValueTypes["CustomerWhere"] | undefined | null,
	discountPermille?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["CustomerGroupWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CustomerGroupWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CustomerGroupWhere"] | undefined | null
};
	["ProductAvailabilityCondition"]: {
	and?: Array<ValueTypes["ProductAvailabilityCondition"]> | undefined | null,
	or?: Array<ValueTypes["ProductAvailabilityCondition"]> | undefined | null,
	not?: ValueTypes["ProductAvailabilityCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["ProductAvailability"] | undefined | null,
	notEq?: ValueTypes["ProductAvailability"] | undefined | null,
	in?: Array<ValueTypes["ProductAvailability"]> | undefined | null,
	notIn?: Array<ValueTypes["ProductAvailability"]> | undefined | null,
	lt?: ValueTypes["ProductAvailability"] | undefined | null,
	lte?: ValueTypes["ProductAvailability"] | undefined | null,
	gt?: ValueTypes["ProductAvailability"] | undefined | null,
	gte?: ValueTypes["ProductAvailability"] | undefined | null
};
	["ProductAvailability"]:ProductAvailability;
	["ProductCustomerPriceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	groupDiscountPermille?: ValueTypes["IntCondition"] | undefined | null,
	groupPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	fullPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	discountedPriceCents?: ValueTypes["IntCondition"] | undefined | null,
	priceCents?: ValueTypes["IntCondition"] | undefined | null,
	offered?: ValueTypes["BooleanCondition"] | undefined | null,
	notDiscountable?: ValueTypes["BooleanCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductCustomerPriceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductCustomerPriceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null
};
	["ProductMetadataWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	archived?: ValueTypes["BooleanCondition"] | undefined | null,
	available?: ValueTypes["BooleanCondition"] | undefined | null,
	availableQuantity?: ValueTypes["IntCondition"] | undefined | null,
	product?: ValueTypes["ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["ProductMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["ProductMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["ProductMetadataWhere"] | undefined | null
};
	["CustomerPermissionsWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	canPlaceOrder?: ValueTypes["BooleanCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["CustomerPermissionsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CustomerPermissionsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CustomerPermissionsWhere"] | undefined | null
};
	["CustomerNameWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	nominative?: ValueTypes["StringCondition"] | undefined | null,
	vocative?: ValueTypes["StringCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["CustomerNameWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CustomerNameWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CustomerNameWhere"] | undefined | null
};
	["CustomerFamiliarityCondition"]: {
	and?: Array<ValueTypes["CustomerFamiliarityCondition"]> | undefined | null,
	or?: Array<ValueTypes["CustomerFamiliarityCondition"]> | undefined | null,
	not?: ValueTypes["CustomerFamiliarityCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["CustomerFamiliarity"] | undefined | null,
	notEq?: ValueTypes["CustomerFamiliarity"] | undefined | null,
	in?: Array<ValueTypes["CustomerFamiliarity"]> | undefined | null,
	notIn?: Array<ValueTypes["CustomerFamiliarity"]> | undefined | null,
	lt?: ValueTypes["CustomerFamiliarity"] | undefined | null,
	lte?: ValueTypes["CustomerFamiliarity"] | undefined | null,
	gt?: ValueTypes["CustomerFamiliarity"] | undefined | null,
	gte?: ValueTypes["CustomerFamiliarity"] | undefined | null
};
	["CustomerFamiliarity"]:CustomerFamiliarity;
	["UserWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	staff?: ValueTypes["StaffWhere"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	tenantPersonId?: ValueTypes["UUIDCondition"] | undefined | null,
	disabledAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	verifiedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	email?: ValueTypes["StringCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	deletedAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	meta?: ValueTypes["UserMetadataWhere"] | undefined | null,
	and?: Array<ValueTypes["UserWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["UserWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["UserWhere"] | undefined | null
};
	["StaffWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	user?: ValueTypes["UserWhere"] | undefined | null,
	photo?: ValueTypes["ImageWhere"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["StaffWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StaffWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StaffWhere"] | undefined | null
};
	["UserMetadataWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	userProfileUrl?: ValueTypes["StringCondition"] | undefined | null,
	tenantEmail?: ValueTypes["StringCondition"] | undefined | null,
	tenantPersonId?: ValueTypes["UUIDCondition"] | undefined | null,
	tenantIdentityId?: ValueTypes["UUIDCondition"] | undefined | null,
	globalRoles?: ValueTypes["JsonCondition"] | undefined | null,
	projectRoles?: ValueTypes["JsonCondition"] | undefined | null,
	projectRolesString?: ValueTypes["StringCondition"] | undefined | null,
	externalIdentifiers?: ValueTypes["JsonCondition"] | undefined | null,
	externalIdentifiersString?: ValueTypes["StringCondition"] | undefined | null,
	idpSlugs?: ValueTypes["JsonCondition"] | undefined | null,
	idpSlugsString?: ValueTypes["StringCondition"] | undefined | null,
	user?: ValueTypes["UserWhere"] | undefined | null,
	and?: Array<ValueTypes["UserMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["UserMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["UserMetadataWhere"] | undefined | null
};
	["InvoicingPlanWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	preset?: ValueTypes["InvoicingPresetWhere"] | undefined | null,
	and?: Array<ValueTypes["InvoicingPlanWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["InvoicingPlanWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["InvoicingPlanWhere"] | undefined | null
};
	["InvoicingPresetWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["InvoicingPresetTypeCondition"] | undefined | null,
	startsAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	endsAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	intervalOfDays?: ValueTypes["IntCondition"] | undefined | null,
	invoicingPlan?: ValueTypes["InvoicingPlanWhere"] | undefined | null,
	and?: Array<ValueTypes["InvoicingPresetWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["InvoicingPresetWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["InvoicingPresetWhere"] | undefined | null
};
	["InvoicingPresetTypeCondition"]: {
	and?: Array<ValueTypes["InvoicingPresetTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["InvoicingPresetTypeCondition"]> | undefined | null,
	not?: ValueTypes["InvoicingPresetTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["InvoicingPresetType"] | undefined | null,
	notEq?: ValueTypes["InvoicingPresetType"] | undefined | null,
	in?: Array<ValueTypes["InvoicingPresetType"]> | undefined | null,
	notIn?: Array<ValueTypes["InvoicingPresetType"]> | undefined | null,
	lt?: ValueTypes["InvoicingPresetType"] | undefined | null,
	lte?: ValueTypes["InvoicingPresetType"] | undefined | null,
	gt?: ValueTypes["InvoicingPresetType"] | undefined | null,
	gte?: ValueTypes["InvoicingPresetType"] | undefined | null
};
	["InvoicingPresetType"]:InvoicingPresetType;
	["AnonymousSessionWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTimeCondition"] | undefined | null,
	anonymousSessionKey?: ValueTypes["StringCondition"] | undefined | null,
	email?: ValueTypes["StringCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	internalNote?: ValueTypes["StringCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["AnonymousSessionWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["AnonymousSessionWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["AnonymousSessionWhere"] | undefined | null
};
	["CustomerMetadataWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	isBusiness?: ValueTypes["BooleanCondition"] | undefined | null,
	name?: ValueTypes["StringCondition"] | undefined | null,
	fallbackName?: ValueTypes["StringCondition"] | undefined | null,
	remainingCreditsCents?: ValueTypes["IntCondition"] | undefined | null,
	ordersTotalCents?: ValueTypes["IntCondition"] | undefined | null,
	customerProfileUrl?: ValueTypes["StringCondition"] | undefined | null,
	customer?: ValueTypes["CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["CustomerMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["CustomerMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["CustomerMetadataWhere"] | undefined | null
};
	["GpsWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	latitude?: ValueTypes["FloatCondition"] | undefined | null,
	longitude?: ValueTypes["FloatCondition"] | undefined | null,
	address?: ValueTypes["AddressWhere"] | undefined | null,
	and?: Array<ValueTypes["GpsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["GpsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["GpsWhere"] | undefined | null
};
	["AddressOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	firstName?: ValueTypes["OrderDirection"] | undefined | null,
	lastName?: ValueTypes["OrderDirection"] | undefined | null,
	addressLine2?: ValueTypes["OrderDirection"] | undefined | null,
	email?: ValueTypes["OrderDirection"] | undefined | null,
	phone?: ValueTypes["OrderDirection"] | undefined | null,
	companyName?: ValueTypes["OrderDirection"] | undefined | null,
	companyIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	vatIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	externalIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	hidden?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	country?: ValueTypes["CountryOrderBy"] | undefined | null,
	deletedAt?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	googlePlaceId?: ValueTypes["OrderDirection"] | undefined | null,
	raw?: ValueTypes["OrderDirection"] | undefined | null,
	formatted?: ValueTypes["OrderDirection"] | undefined | null,
	geocodeGeneratedAt?: ValueTypes["OrderDirection"] | undefined | null,
	geocodeResponse?: ValueTypes["OrderDirection"] | undefined | null,
	deliveryZone?: ValueTypes["DeliveryZoneOrderBy"] | undefined | null,
	meta?: ValueTypes["AddressMetadataOrderBy"] | undefined | null,
	gps?: ValueTypes["GpsOrderBy"] | undefined | null,
	geocodeValid?: ValueTypes["OrderDirection"] | undefined | null,
	fullName?: ValueTypes["OrderDirection"] | undefined | null,
	fakturoidSubjectId?: ValueTypes["OrderDirection"] | undefined | null,
	addressLine1?: ValueTypes["OrderDirection"] | undefined | null,
	city?: ValueTypes["OrderDirection"] | undefined | null,
	postalCode?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["CustomerOrderBy"] | undefined | null
};
	["OrderDirection"]:OrderDirection;
	["CustomerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	defaultBillingAddress?: ValueTypes["AddressOrderBy"] | undefined | null,
	permissions?: ValueTypes["CustomerPermissionsOrderBy"] | undefined | null,
	group?: ValueTypes["CustomerGroupOrderBy"] | undefined | null,
	photo?: ValueTypes["ImageOrderBy"] | undefined | null,
	phone?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["CustomerNameOrderBy"] | undefined | null,
	welcomeMessage?: ValueTypes["OrderDirection"] | undefined | null,
	familiarity?: ValueTypes["OrderDirection"] | undefined | null,
	business?: ValueTypes["BusinessCustomerOrderBy"] | undefined | null,
	user?: ValueTypes["UserOrderBy"] | undefined | null,
	disabledAt?: ValueTypes["OrderDirection"] | undefined | null,
	invoicingPlan?: ValueTypes["InvoicingPlanOrderBy"] | undefined | null,
	email?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	defaultDeliveryAddress?: ValueTypes["AddressOrderBy"] | undefined | null,
	defaultBillingSubject?: ValueTypes["BillingSubjectOrderBy"] | undefined | null,
	prefersPackingsWithoutCardboard?: ValueTypes["OrderDirection"] | undefined | null,
	anonymousSession?: ValueTypes["AnonymousSessionOrderBy"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["OrderDirection"] | undefined | null,
	meta?: ValueTypes["CustomerMetadataOrderBy"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["OrderDirection"] | undefined | null
};
	["CustomerPermissionsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	canPlaceOrder?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null
};
	["CustomerGroupOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	discountPermille?: ValueTypes["OrderDirection"] | undefined | null
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	url?: ValueTypes["OrderDirection"] | undefined | null,
	width?: ValueTypes["OrderDirection"] | undefined | null,
	height?: ValueTypes["OrderDirection"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	alt?: ValueTypes["OrderDirection"] | undefined | null
};
	["CustomerNameOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	nominative?: ValueTypes["OrderDirection"] | undefined | null,
	vocative?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null
};
	["BusinessCustomerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	category?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null
};
	["BusinessCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	visibleToNewCustomers?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	specificProducts?: ValueTypes["ProductListOrderBy"] | undefined | null,
	seasonalProductCategories?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	slug?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null
};
	["ProductCategoryListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null
};
	["UserOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	staff?: ValueTypes["StaffOrderBy"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	tenantPersonId?: ValueTypes["OrderDirection"] | undefined | null,
	disabledAt?: ValueTypes["OrderDirection"] | undefined | null,
	verifiedAt?: ValueTypes["OrderDirection"] | undefined | null,
	email?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	deletionRequestedAt?: ValueTypes["OrderDirection"] | undefined | null,
	deletionExpectedAt?: ValueTypes["OrderDirection"] | undefined | null,
	deletedAt?: ValueTypes["OrderDirection"] | undefined | null,
	meta?: ValueTypes["UserMetadataOrderBy"] | undefined | null
};
	["StaffOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	user?: ValueTypes["UserOrderBy"] | undefined | null,
	photo?: ValueTypes["ImageOrderBy"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null
};
	["UserMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	userProfileUrl?: ValueTypes["OrderDirection"] | undefined | null,
	tenantEmail?: ValueTypes["OrderDirection"] | undefined | null,
	tenantPersonId?: ValueTypes["OrderDirection"] | undefined | null,
	tenantIdentityId?: ValueTypes["OrderDirection"] | undefined | null,
	globalRoles?: ValueTypes["OrderDirection"] | undefined | null,
	projectRoles?: ValueTypes["OrderDirection"] | undefined | null,
	projectRolesString?: ValueTypes["OrderDirection"] | undefined | null,
	externalIdentifiers?: ValueTypes["OrderDirection"] | undefined | null,
	externalIdentifiersString?: ValueTypes["OrderDirection"] | undefined | null,
	idpSlugs?: ValueTypes["OrderDirection"] | undefined | null,
	idpSlugsString?: ValueTypes["OrderDirection"] | undefined | null,
	user?: ValueTypes["UserOrderBy"] | undefined | null
};
	["InvoicingPlanOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	preset?: ValueTypes["InvoicingPresetOrderBy"] | undefined | null
};
	["InvoicingPresetOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	startsAt?: ValueTypes["OrderDirection"] | undefined | null,
	endsAt?: ValueTypes["OrderDirection"] | undefined | null,
	intervalOfDays?: ValueTypes["OrderDirection"] | undefined | null,
	invoicingPlan?: ValueTypes["InvoicingPlanOrderBy"] | undefined | null
};
	["BillingSubjectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	fullName?: ValueTypes["OrderDirection"] | undefined | null,
	addressLine1?: ValueTypes["OrderDirection"] | undefined | null,
	addressLine2?: ValueTypes["OrderDirection"] | undefined | null,
	city?: ValueTypes["OrderDirection"] | undefined | null,
	postalCode?: ValueTypes["OrderDirection"] | undefined | null,
	email?: ValueTypes["OrderDirection"] | undefined | null,
	phone?: ValueTypes["OrderDirection"] | undefined | null,
	companyName?: ValueTypes["OrderDirection"] | undefined | null,
	companyIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	vatIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	externalIdentifier?: ValueTypes["OrderDirection"] | undefined | null,
	hidden?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	deletedAt?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	fakturoidSubjectId?: ValueTypes["OrderDirection"] | undefined | null,
	country?: ValueTypes["CountryOrderBy"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["CustomerOrderBy"] | undefined | null
};
	["CountryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["AnonymousSessionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	lastActiveAt?: ValueTypes["OrderDirection"] | undefined | null,
	anonymousSessionKey?: ValueTypes["OrderDirection"] | undefined | null,
	email?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null
};
	["CustomerMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	isBusiness?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	fallbackName?: ValueTypes["OrderDirection"] | undefined | null,
	remainingCreditsCents?: ValueTypes["OrderDirection"] | undefined | null,
	ordersTotalCents?: ValueTypes["OrderDirection"] | undefined | null,
	customerProfileUrl?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null
};
	["DeliveryZoneOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null
};
	["AddressMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	deliveryZoneType?: ValueTypes["OrderDirection"] | undefined | null,
	address?: ValueTypes["AddressOrderBy"] | undefined | null,
	deliveryZone?: ValueTypes["DeliveryZoneOrderBy"] | undefined | null
};
	["GpsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	latitude?: ValueTypes["OrderDirection"] | undefined | null,
	longitude?: ValueTypes["OrderDirection"] | undefined | null,
	address?: ValueTypes["AddressOrderBy"] | undefined | null
};
	["Tag"]: AliasType<{
	_meta?:ValueTypes["TagMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
customers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
categories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
addTagsOnRegistration?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
deliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
publicInChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
allowChannelPayments?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelPayment"]],
fulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
paginateCustomers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateCategories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
paginateAddTagsOnRegistration?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
paginateDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
paginatePublicInChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
paginateAllowChannelPayments?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelPaymentConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TagMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	customers?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	addTagsOnRegistration?:ValueTypes["FieldMeta"],
	deliveryZones?:ValueTypes["FieldMeta"],
	publicInChannels?:ValueTypes["FieldMeta"],
	allowChannelPayments?:ValueTypes["FieldMeta"],
	fulfillmentNotes?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Product"]: AliasType<{
	_meta?:ValueTypes["ProductMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	notDiscountable?:boolean | `@${string}`,
packing?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
recipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
tags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
categories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
stocks?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductStock"]],
groupPrices?: [{	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGroupPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGroupPrice"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
cartItems?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CartItem"]],
	availability?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
orderItems?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
	maximumQuantityPerOrder?:boolean | `@${string}`,
deliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
locales?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
	archivedAt?:boolean | `@${string}`,
customerPrices?: [{	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCustomerPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCustomerPrice"]],
virtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
meta?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null},ValueTypes["ProductMetadata"]],
page?: [{	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
fulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
	hasSubscription?:boolean | `@${string}`,
	pointOfSaleTileColor?:boolean | `@${string}`,
stocksByStore?: [{	by: ValueTypes["ProductStocksByStoreUniqueWhere"],	filter?: ValueTypes["ProductStockWhere"] | undefined | null},ValueTypes["ProductStock"]],
groupPricesByGroup?: [{	by: ValueTypes["ProductGroupPricesByGroupUniqueWhere"],	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null},ValueTypes["ProductGroupPrice"]],
cartItemsByCart?: [{	by: ValueTypes["ProductCartItemsByCartUniqueWhere"],	filter?: ValueTypes["CartItemWhere"] | undefined | null},ValueTypes["CartItem"]],
orderItemsByVirtualProductEffects?: [{	by: ValueTypes["ProductOrderItemsByVirtualProductEffectsUniqueWhere"],	filter?: ValueTypes["OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
localesByLocale?: [{	by: ValueTypes["ProductLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
localesByFeatureList?: [{	by: ValueTypes["ProductLocalesByFeatureListUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
customerPricesByCustomer?: [{	by: ValueTypes["ProductCustomerPricesByCustomerUniqueWhere"],	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null},ValueTypes["ProductCustomerPrice"]],
paginateTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateCategories?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
paginateStocks?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductStockConnection"]],
paginateGroupPrices?: [{	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGroupPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGroupPriceConnection"]],
paginateCartItems?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartItemConnection"]],
paginateOrderItems?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
paginateDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
paginateCustomerPrices?: [{	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCustomerPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCustomerPriceConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	notDiscountable?:ValueTypes["FieldMeta"],
	packing?:ValueTypes["FieldMeta"],
	recipe?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	stocks?:ValueTypes["FieldMeta"],
	groupPrices?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	cartItems?:ValueTypes["FieldMeta"],
	availability?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	orderItems?:ValueTypes["FieldMeta"],
	maximumQuantityPerOrder?:ValueTypes["FieldMeta"],
	deliveryZones?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	archivedAt?:ValueTypes["FieldMeta"],
	customerPrices?:ValueTypes["FieldMeta"],
	virtualProduct?:ValueTypes["FieldMeta"],
	meta?:ValueTypes["FieldMeta"],
	page?:ValueTypes["FieldMeta"],
	fulfillmentNotes?:ValueTypes["FieldMeta"],
	hasSubscription?:ValueTypes["FieldMeta"],
	pointOfSaleTileColor?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPacking"]: AliasType<{
	_meta?:ValueTypes["ProductPackingMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	volumeMl?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	shortName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
	isPackedInCardboard?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["ProductPackingLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
paginateLocales?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	volumeMl?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
	shortName?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	isPackedInCardboard?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	_meta?:ValueTypes["ImageMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	alt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocale"]: AliasType<{
	_meta?:ValueTypes["ProductPackingLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	shortName?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	shortName?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Locale"]: AliasType<{
	_meta?:ValueTypes["LocaleMeta"],
	id?:boolean | `@${string}`,
businessCategories?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
productCategoryLists?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
productIngredients?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
orderRecurrences?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
blogs?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
blogPosts?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
identifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
pages?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
allergens?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
productPackings?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
products?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
productRecipes?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
deliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
identificationCodes?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCodeLocale"]],
subscriptionBoxes?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
businessCategoriesByRoot?: [{	by: ValueTypes["LocaleBusinessCategoriesByRootUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
productCategoryListsByRoot?: [{	by: ValueTypes["LocaleProductCategoryListsByRootUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
productIngredientsByRoot?: [{	by: ValueTypes["LocaleProductIngredientsByRootUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
orderRecurrencesByRoot?: [{	by: ValueTypes["LocaleOrderRecurrencesByRootUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
blogsByRoot?: [{	by: ValueTypes["LocaleBlogsByRootUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
blogPostsByRoot?: [{	by: ValueTypes["LocaleBlogPostsByRootUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsByCover?: [{	by: ValueTypes["LocaleBlogPostsByCoverUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsByContent?: [{	by: ValueTypes["LocaleBlogPostsByContentUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsByLink?: [{	by: ValueTypes["LocaleBlogPostsByLinkUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
blogPostsBySeo?: [{	by: ValueTypes["LocaleBlogPostsBySeoUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
pagesByRoot?: [{	by: ValueTypes["LocalePagesByRootUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesByLink?: [{	by: ValueTypes["LocalePagesByLinkUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesBySeo?: [{	by: ValueTypes["LocalePagesBySeoUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesByContent?: [{	by: ValueTypes["LocalePagesByContentUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
pagesByCover?: [{	by: ValueTypes["LocalePagesByCoverUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
allergensByRoot?: [{	by: ValueTypes["LocaleAllergensByRootUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
productPackingsByRoot?: [{	by: ValueTypes["LocaleProductPackingsByRootUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
productsByRoot?: [{	by: ValueTypes["LocaleProductsByRootUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
productsByFeatureList?: [{	by: ValueTypes["LocaleProductsByFeatureListUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
productRecipesByRoot?: [{	by: ValueTypes["LocaleProductRecipesByRootUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
deliveryMethodsByRoot?: [{	by: ValueTypes["LocaleDeliveryMethodsByRootUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
identificationCodesByRoot?: [{	by: ValueTypes["LocaleIdentificationCodesByRootUniqueWhere"],	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null},ValueTypes["IdentificationCodeLocale"]],
subscriptionBoxesByRoot?: [{	by: ValueTypes["LocaleSubscriptionBoxesByRootUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
subscriptionBoxesByTexts?: [{	by: ValueTypes["LocaleSubscriptionBoxesByTextsUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
subscriptionBoxesBySecondaryTexts?: [{	by: ValueTypes["LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
paginateBusinessCategories?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
paginateProductCategoryLists?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
paginateProductIngredients?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
paginateOrderRecurrences?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
paginateBlogs?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
paginateBlogPosts?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
paginatePages?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
paginateAllergens?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
paginateProductPackings?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
paginateProducts?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
paginateProductRecipes?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
paginateDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
paginateIdentificationCodes?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeLocaleConnection"]],
paginateSubscriptionBoxes?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	businessCategories?:ValueTypes["FieldMeta"],
	productCategoryLists?:ValueTypes["FieldMeta"],
	productIngredients?:ValueTypes["FieldMeta"],
	orderRecurrences?:ValueTypes["FieldMeta"],
	blogs?:ValueTypes["FieldMeta"],
	blogPosts?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	pages?:ValueTypes["FieldMeta"],
	allergens?:ValueTypes["FieldMeta"],
	productPackings?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	productRecipes?:ValueTypes["FieldMeta"],
	deliveryMethods?:ValueTypes["FieldMeta"],
	identificationCodes?:ValueTypes["FieldMeta"],
	subscriptionBoxes?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocale"]: AliasType<{
	_meta?:ValueTypes["BusinessCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategory"]: AliasType<{
	_meta?:ValueTypes["BusinessCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	visibleToNewCustomers?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
	internalName?:boolean | `@${string}`,
businessCustomers?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCustomer"]],
recurrences?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrence"]],
specificProducts?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
seasonalProductCategories?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
specificProductsProductCategories?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
	slug?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["BusinessCategoryLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
businessCustomersByCustomer?: [{	by: ValueTypes["BusinessCategoryBusinessCustomersByCustomerUniqueWhere"],	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null},ValueTypes["BusinessCustomer"]],
paginateLocales?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
paginateBusinessCustomers?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCustomerConnection"]],
paginateRecurrences?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	visibleToNewCustomers?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	businessCustomers?:ValueTypes["FieldMeta"],
	recurrences?:ValueTypes["FieldMeta"],
	specificProducts?:ValueTypes["FieldMeta"],
	seasonalProductCategories?:ValueTypes["FieldMeta"],
	specificProductsProductCategories?:ValueTypes["FieldMeta"],
	slug?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["BusinessCategoryOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierOrderBy"] | undefined | null
};
	["TranslationCataloguesIdentifierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null
};
	["BusinessCustomer"]: AliasType<{
	_meta?:ValueTypes["BusinessCustomerMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
owners?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
members?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
category?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	note?:boolean | `@${string}`,
paginateOwners?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
paginateMembers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCustomerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	owners?:ValueTypes["FieldMeta"],
	members?:ValueTypes["FieldMeta"],
	category?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CustomerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageInfo"]: AliasType<{
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerEdge"]: AliasType<{
	node?:ValueTypes["Customer"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrence"]: AliasType<{
	_meta?:ValueTypes["OrderRecurrenceMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
	order?:boolean | `@${string}`,
businessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategory"]],
localesByLocale?: [{	by: ValueTypes["OrderRecurrenceLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
paginateLocales?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
paginateBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	businessCategory?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocale"]: AliasType<{
	_meta?:ValueTypes["OrderRecurrenceLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null},ValueTypes["OrderRecurrence"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["OrderRecurrenceOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["OrderRecurrenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderRecurrenceLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["LocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: ValueTypes["BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	productCategoryLists?: ValueTypes["ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	productIngredients?: ValueTypes["ProductIngredientLocaleUniqueWhere"] | undefined | null,
	orderRecurrences?: ValueTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	blogs?: ValueTypes["BlogLocaleUniqueWhere"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	pages?: ValueTypes["PageLocaleUniqueWhere"] | undefined | null,
	allergens?: ValueTypes["AllergenLocaleUniqueWhere"] | undefined | null,
	productPackings?: ValueTypes["ProductPackingLocaleUniqueWhere"] | undefined | null,
	products?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null,
	productRecipes?: ValueTypes["ProductRecipeLocaleUniqueWhere"] | undefined | null,
	deliveryMethods?: ValueTypes["DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	identificationCodes?: ValueTypes["IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	subscriptionBoxes?: ValueTypes["SubscriptionBoxLocaleUniqueWhere"] | undefined | null
};
	["BusinessCategoryLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BusinessCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	slug?: string | undefined | null,
	locales?: ValueTypes["BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	businessCustomers?: ValueTypes["BusinessCustomerUniqueWhere"] | undefined | null,
	specificProducts?: ValueTypes["ProductListUniqueWhere"] | undefined | null,
	seasonalProductCategories?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null
};
	["BusinessCustomerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	addresses?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	defaultBillingAddress?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	credits?: ValueTypes["CustomerCreditUniqueWhere"] | undefined | null,
	orders?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	permissions?: ValueTypes["CustomerPermissionsUniqueWhere"] | undefined | null,
	name?: ValueTypes["CustomerNameUniqueWhere"] | undefined | null,
	ingredientRatings?: ValueTypes["CustomerIngredientRatingUniqueWhere"] | undefined | null,
	business?: ValueTypes["BusinessCustomerUniqueWhere"] | undefined | null,
	carts?: ValueTypes["CartUniqueWhere"] | undefined | null,
	user?: ValueTypes["UserUniqueWhere"] | undefined | null,
	invoices?: ValueTypes["InvoiceUniqueWhere"] | undefined | null,
	invoicingPlan?: ValueTypes["InvoicingPlanUniqueWhere"] | undefined | null,
	billingSubjects?: ValueTypes["BillingSubjectUniqueWhere"] | undefined | null,
	offeredProducts?: ValueTypes["ProductCustomerPriceUniqueWhere"] | undefined | null,
	defaultDeliveryAddress?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	defaultBillingSubject?: ValueTypes["BillingSubjectUniqueWhere"] | undefined | null,
	anonymousSession?: ValueTypes["AnonymousSessionUniqueWhere"] | undefined | null,
	boughtVouchers?: ValueTypes["VoucherUniqueWhere"] | undefined | null,
	ownsVouchers?: ValueTypes["VoucherUniqueWhere"] | undefined | null,
	redeemedVouchers?: ValueTypes["VoucherRedemptionUniqueWhere"] | undefined | null,
	meta?: ValueTypes["CustomerMetadataUniqueWhere"] | undefined | null
};
	["AddressUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	meta?: ValueTypes["AddressMetadataUniqueWhere"] | undefined | null,
	gps?: ValueTypes["GpsUniqueWhere"] | undefined | null,
	invoices?: ValueTypes["InvoiceUniqueWhere"] | undefined | null,
	billingAddressOfOrders?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	deliveryAddressOfOrderDeliveries?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["AddressMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	address?: ValueTypes["AddressUniqueWhere"] | undefined | null
};
	["GpsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	address?: ValueTypes["AddressUniqueWhere"] | undefined | null
};
	["InvoiceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	publicKey?: string | undefined | null,
	fakturoidId?: string | undefined | null,
	orderPayments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["OrderPaymentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	meta?: ValueTypes["OrderPaymentMetadataUniqueWhere"] | undefined | null
};
	["OrderPaymentMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	orderPayment?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["OrderUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	seq?: number | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	discounts?: ValueTypes["OrderDiscountUniqueWhere"] | undefined | null,
	delivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null,
	items?: ValueTypes["OrderItemUniqueWhere"] | undefined | null,
	cart?: ValueTypes["CartUniqueWhere"] | undefined | null,
	priceLines?: ValueTypes["OrderPriceLineUniqueWhere"] | undefined | null,
	payments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null,
	summary?: ValueTypes["OrderSummaryUniqueWhere"] | undefined | null
};
	["OrderDiscountUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	voucherRedemption?: ValueTypes["VoucherRedemptionUniqueWhere"] | undefined | null
};
	["VoucherRedemptionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customerCredit?: ValueTypes["CustomerCreditUniqueWhere"] | undefined | null,
	orderDiscount?: ValueTypes["OrderDiscountUniqueWhere"] | undefined | null,
	voucher?: ValueTypes["VoucherUniqueWhere"] | undefined | null
};
	["CustomerCreditUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	voucherRedemption?: ValueTypes["VoucherRedemptionUniqueWhere"] | undefined | null
};
	["VoucherUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	redemption?: ValueTypes["VoucherRedemptionUniqueWhere"] | undefined | null
};
	["OrderDeliveryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	trackingCode?: string | undefined | null,
	order?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["OrderItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	virtualProductEffects?: ValueTypes["VirtualProductEffectUniqueWhere"] | undefined | null
};
	["VirtualProductEffectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	resultVouchers?: ValueTypes["VoucherUniqueWhere"] | undefined | null
};
	["CartUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["CartItemUniqueWhere"] | undefined | null,
	order?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	priceLines?: ValueTypes["CartPriceLineUniqueWhere"] | undefined | null,
	summary?: ValueTypes["CartSummaryUniqueWhere"] | undefined | null
};
	["CartItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	cart?: ValueTypes["CartUniqueWhere"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["ProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	stocks?: ValueTypes["ProductStockUniqueWhere"] | undefined | null,
	groupPrices?: ValueTypes["ProductGroupPriceUniqueWhere"] | undefined | null,
	cartItems?: ValueTypes["CartItemUniqueWhere"] | undefined | null,
	orderItems?: ValueTypes["OrderItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null,
	customerPrices?: ValueTypes["ProductCustomerPriceUniqueWhere"] | undefined | null,
	virtualProduct?: ValueTypes["VirtualProductUniqueWhere"] | undefined | null,
	meta?: ValueTypes["ProductMetadataUniqueWhere"] | undefined | null,
	page?: ValueTypes["PageUniqueWhere"] | undefined | null
};
	["ProductStockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	store?: ValueTypes["StoreUniqueWhere"] | undefined | null
};
	["StoreUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["ProductGroupPriceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	group?: ValueTypes["CustomerGroupUniqueWhere"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["CustomerGroupUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	productPrices?: ValueTypes["ProductGroupPriceUniqueWhere"] | undefined | null,
	customers?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["ProductLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	featureList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["TextListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["TextItemUniqueWhere"] | undefined | null
};
	["TextItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductCustomerPriceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["VirtualProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["ProductMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["PageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["PageLocaleUniqueWhere"] | undefined | null
};
	["PageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["PageUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null,
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["LinkableUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null,
	page?: ValueTypes["PageLocaleUniqueWhere"] | undefined | null,
	redirect?: ValueTypes["RedirectUniqueWhere"] | undefined | null
};
	["BlogPostLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["BlogPostUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["BlogPostUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["BlogPostLocaleUniqueWhere"] | undefined | null
};
	["CoverUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ContentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	blocks?: ValueTypes["ContentBlockUniqueWhere"] | undefined | null
};
	["ContentBlockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentReferenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	products?: ValueTypes["ProductListUniqueWhere"] | undefined | null,
	productBanners?: ValueTypes["ProductBannerListUniqueWhere"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsUniqueWhere"] | undefined | null
};
	["ProductListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductListItemUniqueWhere"] | undefined | null,
	businessCategory?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null
};
	["ProductListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductBannerListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductBannerItemUniqueWhere"] | undefined | null
};
	["ProductBannerItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["DeliveryRegionsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["DeliveryRegionsItemUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null,
	textList?: ValueTypes["TextListUniqueWhere"] | undefined | null,
	gallery?: ValueTypes["ImageListUniqueWhere"] | undefined | null
};
	["ImageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ImageListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ImageListItemUniqueWhere"] | undefined | null
};
	["ImageListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["SeoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	ogImage?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["RedirectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["CartPriceLineUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["CartSummaryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	cart?: ValueTypes["CartUniqueWhere"] | undefined | null
};
	["OrderPriceLineUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	orderItem?: ValueTypes["OrderItemUniqueWhere"] | undefined | null,
	orderDiscount?: ValueTypes["OrderDiscountUniqueWhere"] | undefined | null,
	orderPayment?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null,
	orderDelivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["OrderSummaryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["CustomerPermissionsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerNameUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerIngredientRatingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["ProductIngredientUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["ProductIngredientLocaleUniqueWhere"] | undefined | null,
	customerRatings?: ValueTypes["CustomerIngredientRatingUniqueWhere"] | undefined | null
};
	["ProductIngredientLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["UserUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["StaffUniqueWhere"] | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null,
	meta?: ValueTypes["UserMetadataUniqueWhere"] | undefined | null
};
	["StaffUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	user?: ValueTypes["UserUniqueWhere"] | undefined | null
};
	["UserMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	tenantEmail?: string | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	tenantIdentityId?: ValueTypes["UUID"] | undefined | null,
	user?: ValueTypes["UserUniqueWhere"] | undefined | null
};
	["InvoicingPlanUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null,
	preset?: ValueTypes["InvoicingPresetUniqueWhere"] | undefined | null
};
	["InvoicingPresetUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	invoicingPlan?: ValueTypes["InvoicingPlanUniqueWhere"] | undefined | null
};
	["BillingSubjectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	invoices?: ValueTypes["InvoiceUniqueWhere"] | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["CustomerUniqueWhere"] | undefined | null,
	orders?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["AnonymousSessionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	anonymousSessionKey?: string | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["ProductCategoryListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductCategoryListItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null
};
	["ProductCategoryListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductCategoryListLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["OrderRecurrenceLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["OrderRecurrenceUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["OrderRecurrenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined | null
};
	["BlogLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["BlogUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["One"] | undefined | null,
	locales?: ValueTypes["BlogLocaleUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationDomainUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	catalogues?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null,
	keys?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationKeyUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: string | undefined | null,
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationValueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null,
	key?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["AllergenLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["AllergenUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["AllergenUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: number | undefined | null,
	locales?: ValueTypes["AllergenLocaleUniqueWhere"] | undefined | null
};
	["ProductPackingLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductPackingUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductPackingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["ProductPackingLocaleUniqueWhere"] | undefined | null
};
	["ProductRecipeLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductRecipeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	products?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	ingredients?: ValueTypes["ProductIngredientItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["ProductRecipeLocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["DeliveryMethodLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	root?: ValueTypes["DeliveryMethodUniqueWhere"] | undefined | null
};
	["DeliveryMethodUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	channels?: ValueTypes["ChannelDeliveryUniqueWhere"] | undefined | null,
	locales?: ValueTypes["DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	timelinePreset?: ValueTypes["DeliveryTimelinePresetUniqueWhere"] | undefined | null
};
	["ChannelDeliveryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["DeliveryTimelinePresetUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deliveryMethod?: ValueTypes["DeliveryMethodUniqueWhere"] | undefined | null
};
	["IdentificationCodeLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["IdentificationCodeUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["IdentificationCodeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	locales?: ValueTypes["IdentificationCodeLocaleUniqueWhere"] | undefined | null
};
	["SubscriptionBoxLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["SubscriptionBoxUniqueWhere"] | undefined | null,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null,
	texts?: ValueTypes["TextListUniqueWhere"] | undefined | null,
	secondaryTexts?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["SubscriptionBoxUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["One"] | undefined | null,
	locales?: ValueTypes["SubscriptionBoxLocaleUniqueWhere"] | undefined | null
};
	["OrderRecurrenceLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderRecurrenceLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceLocaleEdge"]: AliasType<{
	node?:ValueTypes["OrderRecurrenceLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryEdge"]: AliasType<{
	node?:ValueTypes["BusinessCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductList"]: AliasType<{
	_meta?:ValueTypes["ProductListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductListItem"]],
businessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
paginateItems?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	businessCategory?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListItem"]: AliasType<{
	_meta?:ValueTypes["ProductListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
	defaultQuantityForOrder?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	defaultQuantityForOrder?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ProductListOrderBy"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	defaultQuantityForOrder?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	notDiscountable?: ValueTypes["OrderDirection"] | undefined | null,
	packing?: ValueTypes["ProductPackingOrderBy"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	availability?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	maximumQuantityPerOrder?: ValueTypes["OrderDirection"] | undefined | null,
	archivedAt?: ValueTypes["OrderDirection"] | undefined | null,
	virtualProduct?: ValueTypes["VirtualProductOrderBy"] | undefined | null,
	meta?: ValueTypes["ProductMetadataOrderBy"] | undefined | null,
	page?: ValueTypes["PageOrderBy"] | undefined | null,
	hasSubscription?: ValueTypes["OrderDirection"] | undefined | null,
	pointOfSaleTileColor?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductPackingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	volumeMl?: ValueTypes["OrderDirection"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null,
	shortName?: ValueTypes["OrderDirection"] | undefined | null,
	isPackedInCardboard?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductRecipeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	color?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null,
	needsPrepping?: ValueTypes["OrderDirection"] | undefined | null,
	gutTuning?: ValueTypes["OrderDirection"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null
};
	["FreshingContainerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	volumeMl?: ValueTypes["OrderDirection"] | undefined | null,
	workspace?: ValueTypes["WorkspaceOrderBy"] | undefined | null
};
	["WorkspaceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unique?: ValueTypes["OrderDirection"] | undefined | null,
	orderDeadlineTime?: ValueTypes["OrderDirection"] | undefined | null,
	ordersLoadingDurationHours?: ValueTypes["OrderDirection"] | undefined | null,
	freshingContainerVolumeMl?: ValueTypes["OrderDirection"] | undefined | null,
	freshingPrepareOrderByOffsetMinutes?: ValueTypes["OrderDirection"] | undefined | null,
	checkoutUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	orderUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	paymentUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	customerProfileUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	userProfileUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	messengerUrl?: ValueTypes["OrderDirection"] | undefined | null,
	messengerIosUrl?: ValueTypes["OrderDirection"] | undefined | null,
	messengerAndroidUrl?: ValueTypes["OrderDirection"] | undefined | null,
	openingHours?: ValueTypes["WeekHoursOrderBy"] | undefined | null,
	workingHours?: ValueTypes["WeekHoursOrderBy"] | undefined | null,
	defaultFreshingContainer?: ValueTypes["FreshingContainerOrderBy"] | undefined | null,
	creditsProduct?: ValueTypes["VirtualProductOrderBy"] | undefined | null,
	discountVatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	businessAddress?: ValueTypes["OrderDirection"] | undefined | null
};
	["WeekHoursOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	mon?: ValueTypes["OrderDirection"] | undefined | null,
	tue?: ValueTypes["OrderDirection"] | undefined | null,
	wed?: ValueTypes["OrderDirection"] | undefined | null,
	thu?: ValueTypes["OrderDirection"] | undefined | null,
	fri?: ValueTypes["OrderDirection"] | undefined | null,
	sat?: ValueTypes["OrderDirection"] | undefined | null,
	sun?: ValueTypes["OrderDirection"] | undefined | null
};
	["VirtualProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	voucherCreditCents?: ValueTypes["OrderDirection"] | undefined | null,
	physicalRepresentation?: ValueTypes["OrderDirection"] | undefined | null,
	recipientEmail?: ValueTypes["OrderDirection"] | undefined | null
};
	["VatRateOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	ratePermille?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	preset?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	archived?: ValueTypes["OrderDirection"] | undefined | null,
	available?: ValueTypes["OrderDirection"] | undefined | null,
	availableQuantity?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null
};
	["PageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListItemEdge"]: AliasType<{
	node?:ValueTypes["ProductListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryList"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListItem"]],
locales?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
seasonalProductCategoriesOfBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
specificProductsProductCategoriesOfBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
localesByLocale?: [{	by: ValueTypes["ProductCategoryListLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
paginateItems?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListItemConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	seasonalProductCategoriesOfBusinessCategory?:ValueTypes["FieldMeta"],
	specificProductsProductCategoriesOfBusinessCategory?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItem"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
productCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
	showRecipeImages?:boolean | `@${string}`,
	showPackingImages?:boolean | `@${string}`,
	showAllAvailableProducts?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	productCategory?:ValueTypes["FieldMeta"],
	showRecipeImages?:ValueTypes["FieldMeta"],
	showPackingImages?:ValueTypes["FieldMeta"],
	showAllAvailableProducts?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategory"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
tags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
parent?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
children?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	order?:boolean | `@${string}`,
childrenByCode?: [{	by: ValueTypes["ProductCategoryChildrenByCodeUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
childrenByChildren?: [{	by: ValueTypes["ProductCategoryChildrenByChildrenUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateChildren?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	parent?:ValueTypes["FieldMeta"],
	children?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	color?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TagOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	parent?: ValueTypes["ProductCategoryOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	color?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryChildrenByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["ProductCategoryChildrenByChildrenUniqueWhere"]: {
	children?: ValueTypes["ProductCategoryUniqueWhere"] | undefined | null
};
	["ProductCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	children?: ValueTypes["ProductCategoryUniqueWhere"] | undefined | null
};
	["ProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductEdge"]: AliasType<{
	node?:ValueTypes["Product"],
		__typename?: boolean | `@${string}`
}>;
	["TagConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TagEdge"]: AliasType<{
	node?:ValueTypes["Tag"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryEdge"]: AliasType<{
	node?:ValueTypes["ProductCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	productCategory?: ValueTypes["ProductCategoryOrderBy"] | undefined | null,
	showRecipeImages?: ValueTypes["OrderDirection"] | undefined | null,
	showPackingImages?: ValueTypes["OrderDirection"] | undefined | null,
	showAllAvailableProducts?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryListLocale"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryListLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["ProductCategoryListLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductCategoryListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListItemEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryListLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryListLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BusinessCategoryBusinessCustomersByCustomerUniqueWhere"]: {
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["BusinessCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCategoryLocaleEdge"]: AliasType<{
	node?:ValueTypes["BusinessCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCustomerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BusinessCustomerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BusinessCustomerEdge"]: AliasType<{
	node?:ValueTypes["BusinessCustomer"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderRecurrenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderRecurrenceEdge"]: AliasType<{
	node?:ValueTypes["OrderRecurrence"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocale"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredient"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientMeta"],
	id?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
	internalName?:boolean | `@${string}`,
allergens?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Allergen"]],
	needsPrepping?:boolean | `@${string}`,
	allowCustomerRating?:boolean | `@${string}`,
customerRatings?: [{	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerIngredientRating"]],
freshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	incrementQuantityNumber?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["ProductIngredientLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
customerRatingsByCustomer?: [{	by: ValueTypes["ProductIngredientCustomerRatingsByCustomerUniqueWhere"],	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null},ValueTypes["CustomerIngredientRating"]],
paginateLocales?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
paginateAllergens?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenConnection"]],
paginateCustomerRatings?: [{	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerIngredientRatingConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	allergens?:ValueTypes["FieldMeta"],
	needsPrepping?:ValueTypes["FieldMeta"],
	allowCustomerRating?:ValueTypes["FieldMeta"],
	customerRatings?:ValueTypes["FieldMeta"],
	freshingContainer?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	incrementQuantityNumber?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductIngredientOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["ProductIngredientOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	needsPrepping?: ValueTypes["OrderDirection"] | undefined | null,
	allowCustomerRating?: ValueTypes["OrderDirection"] | undefined | null,
	freshingContainer?: ValueTypes["FreshingContainerOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	incrementQuantityNumber?: ValueTypes["OrderDirection"] | undefined | null
};
	["Allergen"]: AliasType<{
	_meta?:ValueTypes["AllergenMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
ingredients?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredient"]],
	code?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["AllergenLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
paginateLocales?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
paginateIngredients?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["AllergenMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	ingredients?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocale"]: AliasType<{
	_meta?:ValueTypes["AllergenLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null},ValueTypes["Allergen"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["AllergenOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["AllergenOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null
};
	["AllergenLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["AllergenLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AllergenLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenLocaleEdge"]: AliasType<{
	node?:ValueTypes["AllergenLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredient"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerIngredientRating"]: AliasType<{
	_meta?:ValueTypes["CustomerIngredientRatingMeta"],
	id?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
rating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null},ValueTypes["ProductIngredientRating"]],
ingredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
		__typename?: boolean | `@${string}`
}>;
	["CustomerIngredientRatingMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	rating?:ValueTypes["FieldMeta"],
	ingredient?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRating"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientRatingMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRatingMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerIngredientRatingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	rating?: ValueTypes["ProductIngredientRatingOrderBy"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientOrderBy"] | undefined | null
};
	["ProductIngredientRatingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["FreshingContainer"]: AliasType<{
	_meta?:ValueTypes["FreshingContainerMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	volumeMl?:boolean | `@${string}`,
workspace?: [{	filter?: ValueTypes["WorkspaceWhere"] | undefined | null},ValueTypes["Workspace"]],
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	volumeMl?:ValueTypes["FieldMeta"],
	workspace?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Workspace"]: AliasType<{
	_meta?:ValueTypes["WorkspaceMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
	orderDeadlineTime?:boolean | `@${string}`,
	ordersLoadingDurationHours?:boolean | `@${string}`,
	freshingContainerVolumeMl?:boolean | `@${string}`,
	freshingPrepareOrderByOffsetMinutes?:boolean | `@${string}`,
	checkoutUrlTemplate?:boolean | `@${string}`,
	orderUrlTemplate?:boolean | `@${string}`,
	paymentUrlTemplate?:boolean | `@${string}`,
	customerProfileUrlTemplate?:boolean | `@${string}`,
	userProfileUrlTemplate?:boolean | `@${string}`,
	messengerUrl?:boolean | `@${string}`,
	messengerIosUrl?:boolean | `@${string}`,
	messengerAndroidUrl?:boolean | `@${string}`,
freshingContainers?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FreshingContainer"]],
openingHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
workingHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
defaultFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
creditsProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
discountVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
	name?:boolean | `@${string}`,
	businessAddress?:boolean | `@${string}`,
paginateFreshingContainers?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FreshingContainerConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	orderDeadlineTime?:ValueTypes["FieldMeta"],
	ordersLoadingDurationHours?:ValueTypes["FieldMeta"],
	freshingContainerVolumeMl?:ValueTypes["FieldMeta"],
	freshingPrepareOrderByOffsetMinutes?:ValueTypes["FieldMeta"],
	checkoutUrlTemplate?:ValueTypes["FieldMeta"],
	orderUrlTemplate?:ValueTypes["FieldMeta"],
	paymentUrlTemplate?:ValueTypes["FieldMeta"],
	customerProfileUrlTemplate?:ValueTypes["FieldMeta"],
	userProfileUrlTemplate?:ValueTypes["FieldMeta"],
	messengerUrl?:ValueTypes["FieldMeta"],
	messengerIosUrl?:ValueTypes["FieldMeta"],
	messengerAndroidUrl?:ValueTypes["FieldMeta"],
	freshingContainers?:ValueTypes["FieldMeta"],
	openingHours?:ValueTypes["FieldMeta"],
	workingHours?:ValueTypes["FieldMeta"],
	defaultFreshingContainer?:ValueTypes["FieldMeta"],
	creditsProduct?:ValueTypes["FieldMeta"],
	discountVatRate?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	businessAddress?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["WeekHours"]: AliasType<{
	_meta?:ValueTypes["WeekHoursMeta"],
	id?:boolean | `@${string}`,
	mon?:boolean | `@${string}`,
	tue?:boolean | `@${string}`,
	wed?:boolean | `@${string}`,
	thu?:boolean | `@${string}`,
	fri?:boolean | `@${string}`,
	sat?:boolean | `@${string}`,
	sun?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WeekHoursMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	mon?:ValueTypes["FieldMeta"],
	tue?:ValueTypes["FieldMeta"],
	wed?:ValueTypes["FieldMeta"],
	thu?:ValueTypes["FieldMeta"],
	fri?:ValueTypes["FieldMeta"],
	sat?:ValueTypes["FieldMeta"],
	sun?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VirtualProduct"]: AliasType<{
	_meta?:ValueTypes["VirtualProductMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
	voucherCreditCents?:boolean | `@${string}`,
	physicalRepresentation?:boolean | `@${string}`,
	recipientEmail?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VirtualProductMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	voucherCreditCents?:ValueTypes["FieldMeta"],
	physicalRepresentation?:ValueTypes["FieldMeta"],
	recipientEmail?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VatRate"]: AliasType<{
	_meta?:ValueTypes["VatRateMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	ratePermille?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
channelDeliveries?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelDelivery"]],
channelPayments?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelPayment"]],
	preset?:boolean | `@${string}`,
paginateChannelDeliveries?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelDeliveryConnection"]],
paginateChannelPayments?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelPaymentConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["VatRateMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	ratePermille?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	channelDeliveries?:ValueTypes["FieldMeta"],
	channelPayments?:ValueTypes["FieldMeta"],
	preset?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelDelivery"]: AliasType<{
	_meta?:ValueTypes["ChannelDeliveryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
channel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
method?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
	defaultVisible?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChannelDeliveryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	channel?:ValueTypes["FieldMeta"],
	method?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	defaultVisible?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Channel"]: AliasType<{
	_meta?:ValueTypes["ChannelMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	disabled?:boolean | `@${string}`,
payments?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelPayment"]],
deliveries?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelDelivery"]],
carts?: [{	filter?: ValueTypes["CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Cart"]],
customers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
	code?:boolean | `@${string}`,
addTagsOnRegistration?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
publicTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
cartsByItems?: [{	by: ValueTypes["ChannelCartsByItemsUniqueWhere"],	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
cartsByOrder?: [{	by: ValueTypes["ChannelCartsByOrderUniqueWhere"],	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
cartsByPriceLines?: [{	by: ValueTypes["ChannelCartsByPriceLinesUniqueWhere"],	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
cartsBySummary?: [{	by: ValueTypes["ChannelCartsBySummaryUniqueWhere"],	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
paginatePayments?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelPaymentConnection"]],
paginateDeliveries?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelDeliveryConnection"]],
paginateCarts?: [{	filter?: ValueTypes["CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartConnection"]],
paginateCustomers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
paginateAddTagsOnRegistration?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginatePublicTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ChannelMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	disabled?:ValueTypes["FieldMeta"],
	payments?:ValueTypes["FieldMeta"],
	deliveries?:ValueTypes["FieldMeta"],
	carts?:ValueTypes["FieldMeta"],
	customers?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	addTagsOnRegistration?:ValueTypes["FieldMeta"],
	publicTags?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelPayment"]: AliasType<{
	_meta?:ValueTypes["ChannelPaymentMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
channel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
method?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
	requireBillingAddress?:boolean | `@${string}`,
	provideReceipt?:boolean | `@${string}`,
	requireBillingSubject?:boolean | `@${string}`,
allowOnlyForTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
paginateAllowOnlyForTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ChannelPaymentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	channel?:ValueTypes["FieldMeta"],
	method?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	requireBillingAddress?:ValueTypes["FieldMeta"],
	provideReceipt?:ValueTypes["FieldMeta"],
	requireBillingSubject?:ValueTypes["FieldMeta"],
	allowOnlyForTags?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethod"]: AliasType<{
	_meta?:ValueTypes["PaymentMethodMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
deliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	blockingDispatch?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
channels?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelPayment"]],
	reduceGratuityByPercentage?:boolean | `@${string}`,
paginateDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
paginateChannels?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelPaymentConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	enabled?:ValueTypes["FieldMeta"],
	deliveryMethods?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	blockingDispatch?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	channels?:ValueTypes["FieldMeta"],
	reduceGratuityByPercentage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethod"]: AliasType<{
	_meta?:ValueTypes["DeliveryMethodMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
paymentMethods?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	requireDeliveryAddress?:boolean | `@${string}`,
suitableForDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
channels?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelDelivery"]],
	enableDeliveryTracking?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
timelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
localesByLocale?: [{	by: ValueTypes["DeliveryMethodLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
paginatePaymentMethods?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
paginateSuitableForDeliveryZones?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
paginateChannels?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelDeliveryConnection"]],
paginateLocales?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	enabled?:ValueTypes["FieldMeta"],
	paymentMethods?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	requireDeliveryAddress?:ValueTypes["FieldMeta"],
	suitableForDeliveryZones?:ValueTypes["FieldMeta"],
	channels?:ValueTypes["FieldMeta"],
	enableDeliveryTracking?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	timelinePreset?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	enabled?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	blockingDispatch?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	reduceGratuityByPercentage?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryZone"]: AliasType<{
	_meta?:ValueTypes["DeliveryZoneMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
addresses?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Address"]],
suitableDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
deliveryZonesOfTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
deliveryZonesOfProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
addressesMetadata?: [{	filter?: ValueTypes["AddressMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AddressMetadata"]],
addressesByMeta?: [{	by: ValueTypes["DeliveryZoneAddressesByMetaUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByGps?: [{	by: ValueTypes["DeliveryZoneAddressesByGpsUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByInvoices?: [{	by: ValueTypes["DeliveryZoneAddressesByInvoicesUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByBillingAddressOfOrders?: [{	by: ValueTypes["DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDeliveryAddressOfOrderDeliveries?: [{	by: ValueTypes["DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDefaultDeliveryAddressOfCustomer?: [{	by: ValueTypes["DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesMetadataByAddress?: [{	by: ValueTypes["DeliveryZoneAddressesMetadataByAddressUniqueWhere"],	filter?: ValueTypes["AddressMetadataWhere"] | undefined | null},ValueTypes["AddressMetadata"]],
paginateAddresses?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressConnection"]],
paginateSuitableDeliveryMethods?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
paginateDeliveryZonesOfTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateDeliveryZonesOfProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateAddressesMetadata?: [{	filter?: ValueTypes["AddressMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressMetadataConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	addresses?:ValueTypes["FieldMeta"],
	suitableDeliveryMethods?:ValueTypes["FieldMeta"],
	deliveryZonesOfTags?:ValueTypes["FieldMeta"],
	deliveryZonesOfProducts?:ValueTypes["FieldMeta"],
	addressesMetadata?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	enabled?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	requireDeliveryAddress?: ValueTypes["OrderDirection"] | undefined | null,
	enableDeliveryTracking?: ValueTypes["OrderDirection"] | undefined | null,
	timelinePreset?: ValueTypes["DeliveryTimelinePresetOrderBy"] | undefined | null
};
	["DeliveryTimelinePresetOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	duration?: ValueTypes["OrderDirection"] | undefined | null,
	window?: ValueTypes["OrderDirection"] | undefined | null,
	deliveryMethod?: ValueTypes["DeliveryMethodOrderBy"] | undefined | null
};
	["AddressMetadata"]: AliasType<{
	_meta?:ValueTypes["AddressMetadataMeta"],
	id?:boolean | `@${string}`,
	deliveryZoneType?:boolean | `@${string}`,
address?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
deliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null},ValueTypes["DeliveryZone"]],
		__typename?: boolean | `@${string}`
}>;
	["AddressMetadataMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	deliveryZoneType?:ValueTypes["FieldMeta"],
	address?:ValueTypes["FieldMeta"],
	deliveryZone?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneAddressesByMetaUniqueWhere"]: {
	meta?: ValueTypes["AddressMetadataUniqueWhere"] | undefined | null
};
	["DeliveryZoneAddressesByGpsUniqueWhere"]: {
	gps?: ValueTypes["GpsUniqueWhere"] | undefined | null
};
	["DeliveryZoneAddressesByInvoicesUniqueWhere"]: {
	invoices?: ValueTypes["InvoiceUniqueWhere"] | undefined | null
};
	["DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"]: {
	billingAddressOfOrders?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
	deliveryAddressOfOrderDeliveries?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
	defaultDeliveryAddressOfCustomer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["DeliveryZoneAddressesMetadataByAddressUniqueWhere"]: {
	address?: ValueTypes["AddressUniqueWhere"] | undefined | null
};
	["AddressConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AddressEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AddressEdge"]: AliasType<{
	node?:ValueTypes["Address"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryMethodEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodEdge"]: AliasType<{
	node?:ValueTypes["DeliveryMethod"],
		__typename?: boolean | `@${string}`
}>;
	["AddressMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AddressMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AddressMetadataEdge"]: AliasType<{
	node?:ValueTypes["AddressMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelDeliveryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	channel?: ValueTypes["ChannelOrderBy"] | undefined | null,
	method?: ValueTypes["DeliveryMethodOrderBy"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	defaultVisible?: ValueTypes["OrderDirection"] | undefined | null
};
	["ChannelOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	disabled?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryMethodLocale"]: AliasType<{
	_meta?:ValueTypes["DeliveryMethodLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	trackingCodeUrlTemplate?:boolean | `@${string}`,
	processingTitle?:boolean | `@${string}`,
	processingText?:boolean | `@${string}`,
	processingIconUrl?:boolean | `@${string}`,
	dispatchedTitle?:boolean | `@${string}`,
	dispatchedText?:boolean | `@${string}`,
	dispatchedIconUrl?:boolean | `@${string}`,
	fulfilledTitle?:boolean | `@${string}`,
	fulfilledText?:boolean | `@${string}`,
	fulfilledIconUrl?:boolean | `@${string}`,
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
root?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	trackingCodeUrlTemplate?:ValueTypes["FieldMeta"],
	processingTitle?:ValueTypes["FieldMeta"],
	processingText?:ValueTypes["FieldMeta"],
	processingIconUrl?:ValueTypes["FieldMeta"],
	dispatchedTitle?:ValueTypes["FieldMeta"],
	dispatchedText?:ValueTypes["FieldMeta"],
	dispatchedIconUrl?:ValueTypes["FieldMeta"],
	fulfilledTitle?:ValueTypes["FieldMeta"],
	fulfilledText?:ValueTypes["FieldMeta"],
	fulfilledIconUrl?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	trackingCodeUrlTemplate?: ValueTypes["OrderDirection"] | undefined | null,
	processingTitle?: ValueTypes["OrderDirection"] | undefined | null,
	processingText?: ValueTypes["OrderDirection"] | undefined | null,
	processingIconUrl?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchedTitle?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchedText?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchedIconUrl?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledTitle?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledText?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledIconUrl?: ValueTypes["OrderDirection"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	root?: ValueTypes["DeliveryMethodOrderBy"] | undefined | null
};
	["DeliveryTimelinePreset"]: AliasType<{
	_meta?:ValueTypes["DeliveryTimelinePresetMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	window?:boolean | `@${string}`,
deliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryTimelinePresetMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	window?:ValueTypes["FieldMeta"],
	deliveryMethod?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["PaymentMethodConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PaymentMethodEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodEdge"]: AliasType<{
	node?:ValueTypes["PaymentMethod"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryZoneEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryZoneEdge"]: AliasType<{
	node?:ValueTypes["DeliveryZone"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelDeliveryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ChannelDeliveryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelDeliveryEdge"]: AliasType<{
	node?:ValueTypes["ChannelDelivery"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryMethodLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodLocaleEdge"]: AliasType<{
	node?:ValueTypes["DeliveryMethodLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelPaymentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	channel?: ValueTypes["ChannelOrderBy"] | undefined | null,
	method?: ValueTypes["PaymentMethodOrderBy"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	requireBillingAddress?: ValueTypes["OrderDirection"] | undefined | null,
	provideReceipt?: ValueTypes["OrderDirection"] | undefined | null,
	requireBillingSubject?: ValueTypes["OrderDirection"] | undefined | null
};
	["ChannelPaymentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ChannelPaymentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelPaymentEdge"]: AliasType<{
	node?:ValueTypes["ChannelPayment"],
		__typename?: boolean | `@${string}`
}>;
	["Cart"]: AliasType<{
	_meta?:ValueTypes["CartMeta"],
	id?:boolean | `@${string}`,
	confirmedAt?:boolean | `@${string}`,
	deletedAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CartItem"]],
	handle?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
channel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
order?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
deliveryAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
billingAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
	deliveryOption?:boolean | `@${string}`,
	paymentOption?:boolean | `@${string}`,
delivery?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null},ValueTypes["ChannelDelivery"]],
payment?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null},ValueTypes["ChannelPayment"]],
billingSubject?: [{	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null},ValueTypes["BillingSubject"]],
priceLines?: [{	filter?: ValueTypes["CartPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartPriceLineOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CartPriceLine"]],
summary?: [{	filter?: ValueTypes["CartSummaryWhere"] | undefined | null},ValueTypes["CartSummary"]],
itemsByProduct?: [{	by: ValueTypes["CartItemsByProductUniqueWhere"],	filter?: ValueTypes["CartItemWhere"] | undefined | null},ValueTypes["CartItem"]],
paginateItems?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartItemConnection"]],
paginatePriceLines?: [{	filter?: ValueTypes["CartPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartPriceLineOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartPriceLineConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CartMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	confirmedAt?:ValueTypes["FieldMeta"],
	deletedAt?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	handle?:ValueTypes["FieldMeta"],
	state?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	channel?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	deliveryAddress?:ValueTypes["FieldMeta"],
	billingAddress?:ValueTypes["FieldMeta"],
	deliveryOption?:ValueTypes["FieldMeta"],
	paymentOption?:ValueTypes["FieldMeta"],
	delivery?:ValueTypes["FieldMeta"],
	payment?:ValueTypes["FieldMeta"],
	billingSubject?:ValueTypes["FieldMeta"],
	priceLines?:ValueTypes["FieldMeta"],
	summary?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CartItem"]: AliasType<{
	_meta?:ValueTypes["CartItemMeta"],
	id?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
cart?: [{	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	fulfillmentNote?:boolean | `@${string}`,
fulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CartItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	cart?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	updatedAt?:ValueTypes["FieldMeta"],
	fulfillmentNote?:ValueTypes["FieldMeta"],
	fulfillmentNotes?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNote"]: AliasType<{
	_meta?:ValueTypes["FulfillmentNoteMeta"],
	id?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
tags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
cartItems?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CartItem"]],
orderItems?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
orders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
	highlight?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	emoji?:boolean | `@${string}`,
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateTags?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
paginateCartItems?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartItemConnection"]],
paginateOrderItems?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
paginateOrders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNoteMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	tags?:ValueTypes["FieldMeta"],
	cartItems?:ValueTypes["FieldMeta"],
	orderItems?:ValueTypes["FieldMeta"],
	orders?:ValueTypes["FieldMeta"],
	highlight?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	emoji?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CartItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	cart?: ValueTypes["CartOrderBy"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	updatedAt?: ValueTypes["OrderDirection"] | undefined | null,
	fulfillmentNote?: ValueTypes["OrderDirection"] | undefined | null
};
	["CartOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	confirmedAt?: ValueTypes["OrderDirection"] | undefined | null,
	deletedAt?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	handle?: ValueTypes["OrderDirection"] | undefined | null,
	state?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	channel?: ValueTypes["ChannelOrderBy"] | undefined | null,
	order?: ValueTypes["OrderOrderBy"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	deliveryAddress?: ValueTypes["AddressOrderBy"] | undefined | null,
	billingAddress?: ValueTypes["AddressOrderBy"] | undefined | null,
	deliveryOption?: ValueTypes["OrderDirection"] | undefined | null,
	paymentOption?: ValueTypes["OrderDirection"] | undefined | null,
	delivery?: ValueTypes["ChannelDeliveryOrderBy"] | undefined | null,
	payment?: ValueTypes["ChannelPaymentOrderBy"] | undefined | null,
	billingSubject?: ValueTypes["BillingSubjectOrderBy"] | undefined | null,
	summary?: ValueTypes["CartSummaryOrderBy"] | undefined | null
};
	["OrderOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	state?: ValueTypes["OrderDirection"] | undefined | null,
	fulfilledAt?: ValueTypes["OrderDirection"] | undefined | null,
	canceledAt?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	channel?: ValueTypes["ChannelOrderBy"] | undefined | null,
	delivery?: ValueTypes["OrderDeliveryOrderBy"] | undefined | null,
	cart?: ValueTypes["CartOrderBy"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	billingAddress?: ValueTypes["AddressOrderBy"] | undefined | null,
	seq?: ValueTypes["OrderDirection"] | undefined | null,
	billingSubject?: ValueTypes["BillingSubjectOrderBy"] | undefined | null,
	summary?: ValueTypes["OrderSummaryOrderBy"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["OrderDirection"] | undefined | null,
	fulfillmentNote?: ValueTypes["OrderDirection"] | undefined | null,
	doubleshotTotalWeightGrams?: ValueTypes["OrderDirection"] | undefined | null,
	doubleShotLegacyNumber?: ValueTypes["OrderDirection"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["OrderDirection"] | undefined | null,
	doubleshotAdminUrl?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderDeliveryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	deliveredAt?: ValueTypes["OrderDirection"] | undefined | null,
	method?: ValueTypes["DeliveryMethodOrderBy"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	order?: ValueTypes["OrderOrderBy"] | undefined | null,
	expectedAt?: ValueTypes["OrderDirection"] | undefined | null,
	state?: ValueTypes["OrderDirection"] | undefined | null,
	loadingDurationHours?: ValueTypes["OrderDirection"] | undefined | null,
	dispatchAt?: ValueTypes["OrderDirection"] | undefined | null,
	duration?: ValueTypes["OrderDirection"] | undefined | null,
	window?: ValueTypes["OrderDirection"] | undefined | null,
	expectedEndAt?: ValueTypes["OrderDirection"] | undefined | null,
	processingAt?: ValueTypes["OrderDirection"] | undefined | null,
	deliveringAt?: ValueTypes["OrderDirection"] | undefined | null,
	failedAt?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null,
	prepareAt?: ValueTypes["OrderDirection"] | undefined | null,
	pickedAt?: ValueTypes["OrderDirection"] | undefined | null,
	address?: ValueTypes["AddressOrderBy"] | undefined | null,
	trackingCode?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderSummaryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	totalPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	discountPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	undiscountedPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	itemsQuantity?: ValueTypes["OrderDirection"] | undefined | null,
	orderUrl?: ValueTypes["OrderDirection"] | undefined | null,
	orderNumber?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderOrderBy"] | undefined | null
};
	["CartSummaryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	itemsPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	deliveryPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	totalPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	itemsCount?: ValueTypes["OrderDirection"] | undefined | null,
	itemsQuantity?: ValueTypes["OrderDirection"] | undefined | null,
	checkoutUrl?: ValueTypes["OrderDirection"] | undefined | null,
	cart?: ValueTypes["CartOrderBy"] | undefined | null
};
	["OrderItem"]: AliasType<{
	_meta?:ValueTypes["OrderItemMeta"],
	id?:boolean | `@${string}`,
	unitPriceCents?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
order?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
virtualProductEffects?: [{	filter?: ValueTypes["VirtualProductEffectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductEffectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VirtualProductEffect"]],
	pickedAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
fulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
virtualProductEffectsByResultVouchers?: [{	by: ValueTypes["OrderItemVirtualProductEffectsByResultVouchersUniqueWhere"],	filter?: ValueTypes["VirtualProductEffectWhere"] | undefined | null},ValueTypes["VirtualProductEffect"]],
paginateVirtualProductEffects?: [{	filter?: ValueTypes["VirtualProductEffectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductEffectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VirtualProductEffectConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unitPriceCents?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	virtualProductEffects?:ValueTypes["FieldMeta"],
	pickedAt?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	fulfillmentNotes?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Order"]: AliasType<{
	_meta?:ValueTypes["OrderMeta"],
	id?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	fulfilledAt?:boolean | `@${string}`,
	canceledAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
channel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
discounts?: [{	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDiscountOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDiscount"]],
delivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
items?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
cart?: [{	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
	createdAt?:boolean | `@${string}`,
billingAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
priceLines?: [{	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPriceLineOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPriceLine"]],
payments?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPayment"]],
	seq?:boolean | `@${string}`,
billingSubject?: [{	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null},ValueTypes["BillingSubject"]],
summary?: [{	filter?: ValueTypes["OrderSummaryWhere"] | undefined | null},ValueTypes["OrderSummary"]],
	doubleshotLegacyId?:boolean | `@${string}`,
	fulfillmentNote?:boolean | `@${string}`,
fulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
	doubleshotTotalWeightGrams?:boolean | `@${string}`,
	doubleShotLegacyNumber?:boolean | `@${string}`,
	doubleshotLastUpdatedAt?:boolean | `@${string}`,
	doubleshotAdminUrl?:boolean | `@${string}`,
discountsByVoucherRedemption?: [{	by: ValueTypes["OrderDiscountsByVoucherRedemptionUniqueWhere"],	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null},ValueTypes["OrderDiscount"]],
itemsByVirtualProductEffects?: [{	by: ValueTypes["OrderItemsByVirtualProductEffectsUniqueWhere"],	filter?: ValueTypes["OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
priceLinesByOrderItem?: [{	by: ValueTypes["OrderPriceLinesByOrderItemUniqueWhere"],	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null},ValueTypes["OrderPriceLine"]],
priceLinesByOrderDiscount?: [{	by: ValueTypes["OrderPriceLinesByOrderDiscountUniqueWhere"],	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null},ValueTypes["OrderPriceLine"]],
priceLinesByOrderPayment?: [{	by: ValueTypes["OrderPriceLinesByOrderPaymentUniqueWhere"],	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null},ValueTypes["OrderPriceLine"]],
priceLinesByOrderDelivery?: [{	by: ValueTypes["OrderPriceLinesByOrderDeliveryUniqueWhere"],	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null},ValueTypes["OrderPriceLine"]],
paymentsByMeta?: [{	by: ValueTypes["OrderPaymentsByMetaUniqueWhere"],	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
paginateDiscounts?: [{	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDiscountOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDiscountConnection"]],
paginateItems?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
paginatePriceLines?: [{	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPriceLineOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPriceLineConnection"]],
paginatePayments?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	state?:ValueTypes["FieldMeta"],
	fulfilledAt?:ValueTypes["FieldMeta"],
	canceledAt?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	channel?:ValueTypes["FieldMeta"],
	discounts?:ValueTypes["FieldMeta"],
	delivery?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	cart?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	billingAddress?:ValueTypes["FieldMeta"],
	priceLines?:ValueTypes["FieldMeta"],
	payments?:ValueTypes["FieldMeta"],
	seq?:ValueTypes["FieldMeta"],
	billingSubject?:ValueTypes["FieldMeta"],
	summary?:ValueTypes["FieldMeta"],
	doubleshotLegacyId?:ValueTypes["FieldMeta"],
	fulfillmentNote?:ValueTypes["FieldMeta"],
	fulfillmentNotes?:ValueTypes["FieldMeta"],
	doubleshotTotalWeightGrams?:ValueTypes["FieldMeta"],
	doubleShotLegacyNumber?:ValueTypes["FieldMeta"],
	doubleshotLastUpdatedAt?:ValueTypes["FieldMeta"],
	doubleshotAdminUrl?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDiscount"]: AliasType<{
	_meta?:ValueTypes["OrderDiscountMeta"],
	id?:boolean | `@${string}`,
	discountCents?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
order?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
	description?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
voucherRedemption?: [{	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null},ValueTypes["VoucherRedemption"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderDiscountMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	discountCents?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	voucherRedemption?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VoucherRedemption"]: AliasType<{
	_meta?:ValueTypes["VoucherRedemptionMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	manualRedemptionNote?:boolean | `@${string}`,
customerCredit?: [{	filter?: ValueTypes["CustomerCreditWhere"] | undefined | null},ValueTypes["CustomerCredit"]],
orderDiscount?: [{	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null},ValueTypes["OrderDiscount"]],
voucher?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null},ValueTypes["Voucher"]],
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["VoucherRedemptionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	manualRedemptionNote?:ValueTypes["FieldMeta"],
	customerCredit?:ValueTypes["FieldMeta"],
	orderDiscount?:ValueTypes["FieldMeta"],
	voucher?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerCredit"]: AliasType<{
	_meta?:ValueTypes["CustomerCreditMeta"],
	id?:boolean | `@${string}`,
	initialCreditCents?:boolean | `@${string}`,
	remainingCreditsCents?:boolean | `@${string}`,
	expiresAt?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
	createdAt?:boolean | `@${string}`,
voucherRedemption?: [{	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null},ValueTypes["VoucherRedemption"]],
	invoiceUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomerCreditMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	initialCreditCents?:ValueTypes["FieldMeta"],
	remainingCreditsCents?:ValueTypes["FieldMeta"],
	expiresAt?:ValueTypes["FieldMeta"],
	reason?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	voucherRedemption?:ValueTypes["FieldMeta"],
	invoiceUrl?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Voucher"]: AliasType<{
	_meta?:ValueTypes["VoucherMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	activatedAt?:boolean | `@${string}`,
	expiresAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	creditCents?:boolean | `@${string}`,
buyer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
owner?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
virtualProductEffect?: [{	filter?: ValueTypes["VirtualProductEffectWhere"] | undefined | null},ValueTypes["VirtualProductEffect"]],
redemption?: [{	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null},ValueTypes["VoucherRedemption"]],
		__typename?: boolean | `@${string}`
}>;
	["VoucherMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	activatedAt?:ValueTypes["FieldMeta"],
	expiresAt?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	creditCents?:ValueTypes["FieldMeta"],
	buyer?:ValueTypes["FieldMeta"],
	owner?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	virtualProductEffect?:ValueTypes["FieldMeta"],
	redemption?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VirtualProductEffect"]: AliasType<{
	_meta?:ValueTypes["VirtualProductEffectMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	succeededAt?:boolean | `@${string}`,
orderItem?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
	failedAt?:boolean | `@${string}`,
	revertedAt?:boolean | `@${string}`,
resultVouchers?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Voucher"]],
resultVouchersByCode?: [{	by: ValueTypes["VirtualProductEffectResultVouchersByCodeUniqueWhere"],	filter?: ValueTypes["VoucherWhere"] | undefined | null},ValueTypes["Voucher"]],
resultVouchersByRedemption?: [{	by: ValueTypes["VirtualProductEffectResultVouchersByRedemptionUniqueWhere"],	filter?: ValueTypes["VoucherWhere"] | undefined | null},ValueTypes["Voucher"]],
paginateResultVouchers?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VoucherConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["VirtualProductEffectMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	succeededAt?:ValueTypes["FieldMeta"],
	orderItem?:ValueTypes["FieldMeta"],
	failedAt?:ValueTypes["FieldMeta"],
	revertedAt?:ValueTypes["FieldMeta"],
	resultVouchers?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VoucherOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	activatedAt?: ValueTypes["OrderDirection"] | undefined | null,
	expiresAt?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	creditCents?: ValueTypes["OrderDirection"] | undefined | null,
	buyer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	owner?: ValueTypes["CustomerOrderBy"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	virtualProductEffect?: ValueTypes["VirtualProductEffectOrderBy"] | undefined | null,
	redemption?: ValueTypes["VoucherRedemptionOrderBy"] | undefined | null
};
	["VirtualProductEffectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	succeededAt?: ValueTypes["OrderDirection"] | undefined | null,
	orderItem?: ValueTypes["OrderItemOrderBy"] | undefined | null,
	failedAt?: ValueTypes["OrderDirection"] | undefined | null,
	revertedAt?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unitPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	order?: ValueTypes["OrderOrderBy"] | undefined | null,
	pickedAt?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null
};
	["VoucherRedemptionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	manualRedemptionNote?: ValueTypes["OrderDirection"] | undefined | null,
	customerCredit?: ValueTypes["CustomerCreditOrderBy"] | undefined | null,
	orderDiscount?: ValueTypes["OrderDiscountOrderBy"] | undefined | null,
	voucher?: ValueTypes["VoucherOrderBy"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null
};
	["CustomerCreditOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	initialCreditCents?: ValueTypes["OrderDirection"] | undefined | null,
	remainingCreditsCents?: ValueTypes["OrderDirection"] | undefined | null,
	expiresAt?: ValueTypes["OrderDirection"] | undefined | null,
	reason?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	voucherRedemption?: ValueTypes["VoucherRedemptionOrderBy"] | undefined | null,
	invoiceUrl?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderDiscountOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	discountCents?: ValueTypes["OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	order?: ValueTypes["OrderOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	voucherRedemption?: ValueTypes["VoucherRedemptionOrderBy"] | undefined | null
};
	["VirtualProductEffectResultVouchersByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["VirtualProductEffectResultVouchersByRedemptionUniqueWhere"]: {
	redemption?: ValueTypes["VoucherRedemptionUniqueWhere"] | undefined | null
};
	["VoucherConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VoucherEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VoucherEdge"]: AliasType<{
	node?:ValueTypes["Voucher"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDelivery"]: AliasType<{
	_meta?:ValueTypes["OrderDeliveryMeta"],
	id?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	deliveredAt?:boolean | `@${string}`,
method?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
order?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
	expectedAt?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	loadingDurationHours?:boolean | `@${string}`,
	dispatchAt?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	window?:boolean | `@${string}`,
	expectedEndAt?:boolean | `@${string}`,
	processingAt?:boolean | `@${string}`,
	deliveringAt?:boolean | `@${string}`,
	failedAt?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	prepareAt?:boolean | `@${string}`,
	pickedAt?:boolean | `@${string}`,
address?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
	trackingCode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderDeliveryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	deliveredAt?:ValueTypes["FieldMeta"],
	method?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	expectedAt?:ValueTypes["FieldMeta"],
	state?:ValueTypes["FieldMeta"],
	loadingDurationHours?:ValueTypes["FieldMeta"],
	dispatchAt?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	window?:ValueTypes["FieldMeta"],
	expectedEndAt?:ValueTypes["FieldMeta"],
	processingAt?:ValueTypes["FieldMeta"],
	deliveringAt?:ValueTypes["FieldMeta"],
	failedAt?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	prepareAt?:ValueTypes["FieldMeta"],
	pickedAt?:ValueTypes["FieldMeta"],
	address?:ValueTypes["FieldMeta"],
	trackingCode?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPriceLine"]: AliasType<{
	_meta?:ValueTypes["OrderPriceLineMeta"],
	id?:boolean | `@${string}`,
	unitPriceCents?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
orderItem?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
orderDiscount?: [{	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null},ValueTypes["OrderDiscount"]],
orderPayment?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
orderDelivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
	vatRatePermille?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
order?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderPriceLineMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unitPriceCents?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	orderItem?:ValueTypes["FieldMeta"],
	orderDiscount?:ValueTypes["FieldMeta"],
	orderPayment?:ValueTypes["FieldMeta"],
	orderDelivery?:ValueTypes["FieldMeta"],
	vatRatePermille?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPayment"]: AliasType<{
	_meta?:ValueTypes["OrderPaymentMeta"],
	id?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
	paymentCents?:boolean | `@${string}`,
method?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
	failedAt?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	approvedAt?:boolean | `@${string}`,
order?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
invoice?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
	walletType?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	publicReceiptUrl?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
meta?: [{	filter?: ValueTypes["OrderPaymentMetadataWhere"] | undefined | null},ValueTypes["OrderPaymentMetadata"]],
	otherCurrencyPriceCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	data?:ValueTypes["FieldMeta"],
	paymentCents?:ValueTypes["FieldMeta"],
	method?:ValueTypes["FieldMeta"],
	failedAt?:ValueTypes["FieldMeta"],
	state?:ValueTypes["FieldMeta"],
	approvedAt?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	invoice?:ValueTypes["FieldMeta"],
	walletType?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	publicReceiptUrl?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	meta?:ValueTypes["FieldMeta"],
	otherCurrencyPriceCents?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Json"]:unknown;
	["Invoice"]: AliasType<{
	_meta?:ValueTypes["InvoiceMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	publicKey?:boolean | `@${string}`,
	fakturoidId?:boolean | `@${string}`,
	fakturoidData?:boolean | `@${string}`,
	legacyUrl?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
orderPayments?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPayment"]],
address?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
	publicUrl?:boolean | `@${string}`,
billingSubject?: [{	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null},ValueTypes["BillingSubject"]],
orderPaymentsByMeta?: [{	by: ValueTypes["InvoiceOrderPaymentsByMetaUniqueWhere"],	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
paginateOrderPayments?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["InvoiceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	publicKey?:ValueTypes["FieldMeta"],
	fakturoidId?:ValueTypes["FieldMeta"],
	fakturoidData?:ValueTypes["FieldMeta"],
	legacyUrl?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	orderPayments?:ValueTypes["FieldMeta"],
	address?:ValueTypes["FieldMeta"],
	publicUrl?:ValueTypes["FieldMeta"],
	billingSubject?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	data?: ValueTypes["OrderDirection"] | undefined | null,
	paymentCents?: ValueTypes["OrderDirection"] | undefined | null,
	method?: ValueTypes["PaymentMethodOrderBy"] | undefined | null,
	failedAt?: ValueTypes["OrderDirection"] | undefined | null,
	state?: ValueTypes["OrderDirection"] | undefined | null,
	approvedAt?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderOrderBy"] | undefined | null,
	invoice?: ValueTypes["InvoiceOrderBy"] | undefined | null,
	walletType?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	publicReceiptUrl?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	meta?: ValueTypes["OrderPaymentMetadataOrderBy"] | undefined | null,
	otherCurrencyPriceCents?: ValueTypes["OrderDirection"] | undefined | null
};
	["InvoiceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	publicKey?: ValueTypes["OrderDirection"] | undefined | null,
	fakturoidId?: ValueTypes["OrderDirection"] | undefined | null,
	fakturoidData?: ValueTypes["OrderDirection"] | undefined | null,
	legacyUrl?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	address?: ValueTypes["AddressOrderBy"] | undefined | null,
	publicUrl?: ValueTypes["OrderDirection"] | undefined | null,
	billingSubject?: ValueTypes["BillingSubjectOrderBy"] | undefined | null
};
	["OrderPaymentMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	paymentUrl?: ValueTypes["OrderDirection"] | undefined | null,
	orderPayment?: ValueTypes["OrderPaymentOrderBy"] | undefined | null
};
	["BillingSubject"]: AliasType<{
	_meta?:ValueTypes["BillingSubjectMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	fullName?:boolean | `@${string}`,
	addressLine1?:boolean | `@${string}`,
	addressLine2?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	postalCode?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	companyName?:boolean | `@${string}`,
	companyIdentifier?:boolean | `@${string}`,
	vatIdentifier?:boolean | `@${string}`,
	externalIdentifier?:boolean | `@${string}`,
	hidden?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	deletedAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	fakturoidSubjectId?:boolean | `@${string}`,
country?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null},ValueTypes["Country"]],
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
invoices?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoiceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Invoice"]],
defaultBillingSubjectOfCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
orders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
invoicesByPublicKey?: [{	by: ValueTypes["BillingSubjectInvoicesByPublicKeyUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
invoicesByFakturoidId?: [{	by: ValueTypes["BillingSubjectInvoicesByFakturoidIdUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
invoicesByOrderPayments?: [{	by: ValueTypes["BillingSubjectInvoicesByOrderPaymentsUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
ordersBySeq?: [{	by: ValueTypes["BillingSubjectOrdersBySeqUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleshotLegacyId?: [{	by: ValueTypes["BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleShotLegacyNumber?: [{	by: ValueTypes["BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDiscounts?: [{	by: ValueTypes["BillingSubjectOrdersByDiscountsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDelivery?: [{	by: ValueTypes["BillingSubjectOrdersByDeliveryUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByItems?: [{	by: ValueTypes["BillingSubjectOrdersByItemsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByCart?: [{	by: ValueTypes["BillingSubjectOrdersByCartUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPriceLines?: [{	by: ValueTypes["BillingSubjectOrdersByPriceLinesUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPayments?: [{	by: ValueTypes["BillingSubjectOrdersByPaymentsUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersBySummary?: [{	by: ValueTypes["BillingSubjectOrdersBySummaryUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
paginateInvoices?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoiceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["InvoiceConnection"]],
paginateOrders?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BillingSubjectMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	fullName?:ValueTypes["FieldMeta"],
	addressLine1?:ValueTypes["FieldMeta"],
	addressLine2?:ValueTypes["FieldMeta"],
	city?:ValueTypes["FieldMeta"],
	postalCode?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	phone?:ValueTypes["FieldMeta"],
	companyName?:ValueTypes["FieldMeta"],
	companyIdentifier?:ValueTypes["FieldMeta"],
	vatIdentifier?:ValueTypes["FieldMeta"],
	externalIdentifier?:ValueTypes["FieldMeta"],
	hidden?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	deletedAt?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	fakturoidSubjectId?:ValueTypes["FieldMeta"],
	country?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	invoices?:ValueTypes["FieldMeta"],
	defaultBillingSubjectOfCustomer?:ValueTypes["FieldMeta"],
	orders?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Country"]: AliasType<{
	_meta?:ValueTypes["CountryMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CountryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BillingSubjectInvoicesByPublicKeyUniqueWhere"]: {
	publicKey?: string | undefined | null
};
	["BillingSubjectInvoicesByFakturoidIdUniqueWhere"]: {
	fakturoidId?: string | undefined | null
};
	["BillingSubjectInvoicesByOrderPaymentsUniqueWhere"]: {
	orderPayments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["BillingSubjectOrdersBySeqUniqueWhere"]: {
	seq?: number | undefined | null
};
	["BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
	doubleShotLegacyNumber?: string | undefined | null
};
	["BillingSubjectOrdersByDiscountsUniqueWhere"]: {
	discounts?: ValueTypes["OrderDiscountUniqueWhere"] | undefined | null
};
	["BillingSubjectOrdersByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["BillingSubjectOrdersByItemsUniqueWhere"]: {
	items?: ValueTypes["OrderItemUniqueWhere"] | undefined | null
};
	["BillingSubjectOrdersByCartUniqueWhere"]: {
	cart?: ValueTypes["CartUniqueWhere"] | undefined | null
};
	["BillingSubjectOrdersByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["OrderPriceLineUniqueWhere"] | undefined | null
};
	["BillingSubjectOrdersByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["BillingSubjectOrdersBySummaryUniqueWhere"]: {
	summary?: ValueTypes["OrderSummaryUniqueWhere"] | undefined | null
};
	["InvoiceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["InvoiceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["InvoiceEdge"]: AliasType<{
	node?:ValueTypes["Invoice"],
		__typename?: boolean | `@${string}`
}>;
	["OrderConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderEdge"]: AliasType<{
	node?:ValueTypes["Order"],
		__typename?: boolean | `@${string}`
}>;
	["InvoiceOrderPaymentsByMetaUniqueWhere"]: {
	meta?: ValueTypes["OrderPaymentMetadataUniqueWhere"] | undefined | null
};
	["OrderPaymentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderPaymentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentEdge"]: AliasType<{
	node?:ValueTypes["OrderPayment"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentMetadata"]: AliasType<{
	_meta?:ValueTypes["OrderPaymentMetadataMeta"],
	id?:boolean | `@${string}`,
	paymentUrl?:boolean | `@${string}`,
orderPayment?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentMetadataMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	paymentUrl?:ValueTypes["FieldMeta"],
	orderPayment?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPriceLineOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unitPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	orderItem?: ValueTypes["OrderItemOrderBy"] | undefined | null,
	orderDiscount?: ValueTypes["OrderDiscountOrderBy"] | undefined | null,
	orderPayment?: ValueTypes["OrderPaymentOrderBy"] | undefined | null,
	orderDelivery?: ValueTypes["OrderDeliveryOrderBy"] | undefined | null,
	vatRatePermille?: ValueTypes["OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	order?: ValueTypes["OrderOrderBy"] | undefined | null
};
	["OrderSummary"]: AliasType<{
	_meta?:ValueTypes["OrderSummaryMeta"],
	id?:boolean | `@${string}`,
	totalPriceCents?:boolean | `@${string}`,
	discountPriceCents?:boolean | `@${string}`,
	undiscountedPriceCents?:boolean | `@${string}`,
	itemsQuantity?:boolean | `@${string}`,
	orderUrl?:boolean | `@${string}`,
	orderNumber?:boolean | `@${string}`,
order?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderSummaryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	totalPriceCents?:ValueTypes["FieldMeta"],
	discountPriceCents?:ValueTypes["FieldMeta"],
	undiscountedPriceCents?:ValueTypes["FieldMeta"],
	itemsQuantity?:ValueTypes["FieldMeta"],
	orderUrl?:ValueTypes["FieldMeta"],
	orderNumber?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNoteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	highlight?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	emoji?: ValueTypes["OrderDirection"] | undefined | null
};
	["OrderDiscountsByVoucherRedemptionUniqueWhere"]: {
	voucherRedemption?: ValueTypes["VoucherRedemptionUniqueWhere"] | undefined | null
};
	["OrderItemsByVirtualProductEffectsUniqueWhere"]: {
	virtualProductEffects?: ValueTypes["VirtualProductEffectUniqueWhere"] | undefined | null
};
	["OrderPriceLinesByOrderItemUniqueWhere"]: {
	orderItem?: ValueTypes["OrderItemUniqueWhere"] | undefined | null
};
	["OrderPriceLinesByOrderDiscountUniqueWhere"]: {
	orderDiscount?: ValueTypes["OrderDiscountUniqueWhere"] | undefined | null
};
	["OrderPriceLinesByOrderPaymentUniqueWhere"]: {
	orderPayment?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["OrderPriceLinesByOrderDeliveryUniqueWhere"]: {
	orderDelivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["OrderPaymentsByMetaUniqueWhere"]: {
	meta?: ValueTypes["OrderPaymentMetadataUniqueWhere"] | undefined | null
};
	["OrderDiscountConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderDiscountEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDiscountEdge"]: AliasType<{
	node?:ValueTypes["OrderDiscount"],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemEdge"]: AliasType<{
	node?:ValueTypes["OrderItem"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPriceLineConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderPriceLineEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPriceLineEdge"]: AliasType<{
	node?:ValueTypes["OrderPriceLine"],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNoteConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FulfillmentNoteEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNoteEdge"]: AliasType<{
	node?:ValueTypes["FulfillmentNote"],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemVirtualProductEffectsByResultVouchersUniqueWhere"]: {
	resultVouchers?: ValueTypes["VoucherUniqueWhere"] | undefined | null
};
	["VirtualProductEffectConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VirtualProductEffectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VirtualProductEffectEdge"]: AliasType<{
	node?:ValueTypes["VirtualProductEffect"],
		__typename?: boolean | `@${string}`
}>;
	["CartItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CartItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CartItemEdge"]: AliasType<{
	node?:ValueTypes["CartItem"],
		__typename?: boolean | `@${string}`
}>;
	["CartPriceLine"]: AliasType<{
	_meta?:ValueTypes["CartPriceLineMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
item?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null},ValueTypes["CartItem"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
	unitPriceCents?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	vatRatePermille?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
cart?: [{	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
		__typename?: boolean | `@${string}`
}>;
	["CartPriceLineMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	item?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	unitPriceCents?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	vatRatePermille?:ValueTypes["FieldMeta"],
	vatRate?:ValueTypes["FieldMeta"],
	cart?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CartPriceLineOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	item?: ValueTypes["CartItemOrderBy"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	unitPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	vatRatePermille?: ValueTypes["OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["VatRateOrderBy"] | undefined | null,
	cart?: ValueTypes["CartOrderBy"] | undefined | null
};
	["CartSummary"]: AliasType<{
	_meta?:ValueTypes["CartSummaryMeta"],
	id?:boolean | `@${string}`,
	itemsPriceCents?:boolean | `@${string}`,
	deliveryPriceCents?:boolean | `@${string}`,
	totalPriceCents?:boolean | `@${string}`,
	itemsCount?:boolean | `@${string}`,
	itemsQuantity?:boolean | `@${string}`,
	checkoutUrl?:boolean | `@${string}`,
cart?: [{	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
		__typename?: boolean | `@${string}`
}>;
	["CartSummaryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	itemsPriceCents?:ValueTypes["FieldMeta"],
	deliveryPriceCents?:ValueTypes["FieldMeta"],
	totalPriceCents?:ValueTypes["FieldMeta"],
	itemsCount?:ValueTypes["FieldMeta"],
	itemsQuantity?:ValueTypes["FieldMeta"],
	checkoutUrl?:ValueTypes["FieldMeta"],
	cart?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CartItemsByProductUniqueWhere"]: {
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["CartPriceLineConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CartPriceLineEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CartPriceLineEdge"]: AliasType<{
	node?:ValueTypes["CartPriceLine"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelCartsByItemsUniqueWhere"]: {
	items?: ValueTypes["CartItemUniqueWhere"] | undefined | null
};
	["ChannelCartsByOrderUniqueWhere"]: {
	order?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["ChannelCartsByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["CartPriceLineUniqueWhere"] | undefined | null
};
	["ChannelCartsBySummaryUniqueWhere"]: {
	summary?: ValueTypes["CartSummaryUniqueWhere"] | undefined | null
};
	["CartConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CartEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CartEdge"]: AliasType<{
	node?:ValueTypes["Cart"],
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FreshingContainerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FreshingContainerEdge"]: AliasType<{
	node?:ValueTypes["FreshingContainer"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientCustomerRatingsByCustomerUniqueWhere"]: {
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["ProductIngredientLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientLocale"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AllergenEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AllergenEdge"]: AliasType<{
	node?:ValueTypes["Allergen"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerIngredientRatingConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CustomerIngredientRatingEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerIngredientRatingEdge"]: AliasType<{
	node?:ValueTypes["CustomerIngredientRating"],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocale"]: AliasType<{
	_meta?:ValueTypes["BlogLocaleMeta"],
	id?:boolean | `@${string}`,
	pageName?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	lead?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null},ValueTypes["Blog"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	pageName?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Blog"]: AliasType<{
	_meta?:ValueTypes["BlogMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
localesByLocale?: [{	by: ValueTypes["BlogLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	pageName?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	lead?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["BlogOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["BlogOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unique?: ValueTypes["OrderDirection"] | undefined | null
};
	["BlogLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogLocaleEdge"]: AliasType<{
	node?:ValueTypes["BlogLocale"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocale"]: AliasType<{
	_meta?:ValueTypes["BlogPostLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	availableForWeb?:boolean | `@${string}`,
	availableForMobile?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null},ValueTypes["BlogPost"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
cover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	availableForWeb?:ValueTypes["FieldMeta"],
	availableForMobile?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	cover?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPost"]: AliasType<{
	_meta?:ValueTypes["BlogPostMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
localesByLocale?: [{	by: ValueTypes["BlogPostLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesByCover?: [{	by: ValueTypes["BlogPostLocalesByCoverUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesByContent?: [{	by: ValueTypes["BlogPostLocalesByContentUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesByLink?: [{	by: ValueTypes["BlogPostLocalesByLinkUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
localesBySeo?: [{	by: ValueTypes["BlogPostLocalesBySeoUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
paginateLocales?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	availableForWeb?: ValueTypes["OrderDirection"] | undefined | null,
	availableForMobile?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["BlogPostOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	cover?: ValueTypes["CoverOrderBy"] | undefined | null,
	content?: ValueTypes["ContentOrderBy"] | undefined | null,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null
};
	["BlogPostOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null
};
	["CoverOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	primaryTitle?: ValueTypes["OrderDirection"] | undefined | null,
	secondaryTitle?: ValueTypes["OrderDirection"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	buttonColors?: ValueTypes["ColorPalleteOrderBy"] | undefined | null
};
	["MediumOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	colorTheme?: ValueTypes["OrderDirection"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	video?: ValueTypes["VideoOrderBy"] | undefined | null
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	src?: ValueTypes["OrderDirection"] | undefined | null,
	width?: ValueTypes["OrderDirection"] | undefined | null,
	height?: ValueTypes["OrderDirection"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	duration?: ValueTypes["OrderDirection"] | undefined | null,
	poster?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["ColorPalleteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	background?: ValueTypes["OrderDirection"] | undefined | null,
	text?: ValueTypes["OrderDirection"] | undefined | null
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	url?: ValueTypes["OrderDirection"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleOrderBy"] | undefined | null,
	page?: ValueTypes["PageLocaleOrderBy"] | undefined | null,
	redirect?: ValueTypes["RedirectOrderBy"] | undefined | null
};
	["PageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null,
	root?: ValueTypes["PageOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null,
	content?: ValueTypes["ContentOrderBy"] | undefined | null,
	cover?: ValueTypes["CoverOrderBy"] | undefined | null,
	theme?: ValueTypes["OrderDirection"] | undefined | null
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	ogTitle?: ValueTypes["OrderDirection"] | undefined | null,
	ogDescription?: ValueTypes["OrderDirection"] | undefined | null,
	ogImage?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null,
	target?: ValueTypes["LinkOrderBy"] | undefined | null
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	externalLink?: ValueTypes["OrderDirection"] | undefined | null,
	internalLink?: ValueTypes["LinkableOrderBy"] | undefined | null
};
	["BlogPostLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["BlogPostLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["BlogPostLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["BlogPostLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostLocaleEdge"]: AliasType<{
	node?:ValueTypes["BlogPostLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Cover"]: AliasType<{
	_meta?:ValueTypes["CoverMeta"],
	id?:boolean | `@${string}`,
	primaryTitle?:boolean | `@${string}`,
	secondaryTitle?:boolean | `@${string}`,
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
buttonColors?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
		__typename?: boolean | `@${string}`
}>;
	["CoverMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	primaryTitle?:ValueTypes["FieldMeta"],
	secondaryTitle?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	buttonColors?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Medium"]: AliasType<{
	_meta?:ValueTypes["MediumMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	colorTheme?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
video?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null},ValueTypes["Video"]],
		__typename?: boolean | `@${string}`
}>;
	["MediumMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	colorTheme?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	video?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Video"]: AliasType<{
	_meta?:ValueTypes["VideoMeta"],
	id?:boolean | `@${string}`,
	src?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
poster?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["VideoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	src?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	poster?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ColorPallete"]: AliasType<{
	_meta?:ValueTypes["ColorPalleteMeta"],
	id?:boolean | `@${string}`,
	background?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ColorPalleteMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	background?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Content"]: AliasType<{
	_meta?:ValueTypes["ContentMeta"],
	id?:boolean | `@${string}`,
blocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentBlock"]],
blocksByReferences?: [{	by: ValueTypes["ContentBlocksByReferencesUniqueWhere"],	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
paginateBlocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentBlockConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	blocks?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ValueTypes["ContentBlockMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	json?:boolean | `@${string}`,
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
references?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentReference"]],
referencesByProducts?: [{	by: ValueTypes["ContentBlockReferencesByProductsUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
referencesByProductBanners?: [{	by: ValueTypes["ContentBlockReferencesByProductBannersUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
referencesByDeliveryRegions?: [{	by: ValueTypes["ContentBlockReferencesByDeliveryRegionsUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
paginateReferences?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentReferenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	json?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	references?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReference"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	primaryText?:boolean | `@${string}`,
	secondaryText?:boolean | `@${string}`,
	jsonContent?:boolean | `@${string}`,
block?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
colorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
blogPosts?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
links?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
products?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
	contentSize?:boolean | `@${string}`,
	misc?:boolean | `@${string}`,
hero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null},ValueTypes["Hero"]],
gallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
socialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null},ValueTypes["SocialsAndApps"]],
productGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
productBanners?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
deliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
textList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
gridTiles?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null},ValueTypes["GridTiles"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	primaryText?:ValueTypes["FieldMeta"],
	secondaryText?:ValueTypes["FieldMeta"],
	jsonContent?:ValueTypes["FieldMeta"],
	block?:ValueTypes["FieldMeta"],
	colorPallete?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	blogPosts?:ValueTypes["FieldMeta"],
	links?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	contentSize?:ValueTypes["FieldMeta"],
	misc?:ValueTypes["FieldMeta"],
	hero?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
	socialsAndApps?:ValueTypes["FieldMeta"],
	productGrid?:ValueTypes["FieldMeta"],
	productBanners?:ValueTypes["FieldMeta"],
	deliveryRegions?:ValueTypes["FieldMeta"],
	textList?:ValueTypes["FieldMeta"],
	gridTiles?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Link"]: AliasType<{
	_meta?:ValueTypes["LinkMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	externalLink?:boolean | `@${string}`,
internalLink?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	externalLink?:ValueTypes["FieldMeta"],
	internalLink?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Linkable"]: AliasType<{
	_meta?:ValueTypes["LinkableMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
blogPost?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
page?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
redirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null},ValueTypes["Redirect"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkableMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	blogPost?:ValueTypes["FieldMeta"],
	page?:ValueTypes["FieldMeta"],
	redirect?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PageLocale"]: AliasType<{
	_meta?:ValueTypes["PageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
root?: [{	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
cover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
	theme?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	cover?:ValueTypes["FieldMeta"],
	theme?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Page"]: AliasType<{
	_meta?:ValueTypes["PageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
localesByLocale?: [{	by: ValueTypes["PageLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesByLink?: [{	by: ValueTypes["PageLocalesByLinkUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesBySeo?: [{	by: ValueTypes["PageLocalesBySeoUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesByContent?: [{	by: ValueTypes["PageLocalesByContentUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
localesByCover?: [{	by: ValueTypes["PageLocalesByCoverUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["PageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["PageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["PageLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["PageLocalesByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["PageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageLocaleEdge"]: AliasType<{
	node?:ValueTypes["PageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Seo"]: AliasType<{
	_meta?:ValueTypes["SeoMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	ogTitle?:boolean | `@${string}`,
	ogDescription?:boolean | `@${string}`,
ogImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	ogTitle?:ValueTypes["FieldMeta"],
	ogDescription?:ValueTypes["FieldMeta"],
	ogImage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Redirect"]: AliasType<{
	_meta?:ValueTypes["RedirectMeta"],
	id?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
target?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["RedirectMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	target?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostList"]: AliasType<{
	_meta?:ValueTypes["BlogPostListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostListItem"]],
paginateItems?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItem"]: AliasType<{
	_meta?:ValueTypes["BlogPostListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	caption?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
blogPost?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	caption?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	blogPost?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	caption?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["BlogPostListOrderBy"] | undefined | null,
	blogPost?: ValueTypes["BlogPostLocaleOrderBy"] | undefined | null
};
	["BlogPostListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["BlogPostListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListItemEdge"]: AliasType<{
	node?:ValueTypes["BlogPostListItem"],
		__typename?: boolean | `@${string}`
}>;
	["LinkList"]: AliasType<{
	_meta?:ValueTypes["LinkListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkListItem"]],
paginateItems?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItem"]: AliasType<{
	_meta?:ValueTypes["LinkListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	isPrimary?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	isPrimary?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	isPrimary?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["LinkListOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null
};
	["LinkListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["LinkListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListItemEdge"]: AliasType<{
	node?:ValueTypes["LinkListItem"],
		__typename?: boolean | `@${string}`
}>;
	["Hero"]: AliasType<{
	_meta?:ValueTypes["HeroMeta"],
	id?:boolean | `@${string}`,
	showLogo?:boolean | `@${string}`,
	middleContent?:boolean | `@${string}`,
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
		__typename?: boolean | `@${string}`
}>;
	["HeroMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	showLogo?:ValueTypes["FieldMeta"],
	middleContent?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Gallery"]: AliasType<{
	_meta?:ValueTypes["GalleryMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GalleryItem"]],
paginateItems?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItem"]: AliasType<{
	_meta?:ValueTypes["GalleryItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	caption?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	caption?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	caption?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["GalleryOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null
};
	["GalleryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["GalleryItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GalleryItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryItemEdge"]: AliasType<{
	node?:ValueTypes["GalleryItem"],
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndApps"]: AliasType<{
	_meta?:ValueTypes["SocialsAndAppsMeta"],
	id?:boolean | `@${string}`,
	instagram?:boolean | `@${string}`,
	appStore?:boolean | `@${string}`,
	googlePlay?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndAppsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	instagram?:ValueTypes["FieldMeta"],
	appStore?:ValueTypes["FieldMeta"],
	googlePlay?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGrid"]: AliasType<{
	_meta?:ValueTypes["ProductGridMeta"],
	id?:boolean | `@${string}`,
	isCarousel?:boolean | `@${string}`,
categories?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridCategory"]],
	type?:boolean | `@${string}`,
categoriesByItems?: [{	by: ValueTypes["ProductGridCategoriesByItemsUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
categoriesByImages?: [{	by: ValueTypes["ProductGridCategoriesByImagesUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
paginateCategories?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	isCarousel?:ValueTypes["FieldMeta"],
	categories?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategory"]: AliasType<{
	_meta?:ValueTypes["ProductGridCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
items?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridItem"]],
images?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
paginateItems?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	images?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItem"]: AliasType<{
	_meta?:ValueTypes["ProductGridItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	lead?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
category?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	lead?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	category?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	lead?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null,
	category?: ValueTypes["ProductGridCategoryOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductGridCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductGridOrderBy"] | undefined | null,
	images?: ValueTypes["ImageListOrderBy"] | undefined | null
};
	["ProductGridOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	isCarousel?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["ImageList"]: AliasType<{
	_meta?:ValueTypes["ImageListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageListItem"]],
itemsByImage?: [{	by: ValueTypes["ImageListItemsByImageUniqueWhere"],	filter?: ValueTypes["ImageListItemWhere"] | undefined | null},ValueTypes["ImageListItem"]],
paginateItems?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItem"]: AliasType<{
	_meta?:ValueTypes["ImageListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ImageListOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null
};
	["ImageListItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["ImageListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemEdge"]: AliasType<{
	node?:ValueTypes["ImageListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductGridItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridItemEdge"]: AliasType<{
	node?:ValueTypes["ProductGridItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategoriesByItemsUniqueWhere"]: {
	items?: ValueTypes["ProductGridItemUniqueWhere"] | undefined | null
};
	["ProductGridItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ProductGridCategoriesByImagesUniqueWhere"]: {
	images?: ValueTypes["ImageListUniqueWhere"] | undefined | null
};
	["ProductGridCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductGridCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridCategoryEdge"]: AliasType<{
	node?:ValueTypes["ProductGridCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerList"]: AliasType<{
	_meta?:ValueTypes["ProductBannerListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerItem"]],
paginateItems?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItem"]: AliasType<{
	_meta?:ValueTypes["ProductBannerItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
banner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null},ValueTypes["ProductBanner"]],
	size?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	banner?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBanner"]: AliasType<{
	_meta?:ValueTypes["ProductBannerMeta"],
	id?:boolean | `@${string}`,
	beforeTitle?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
button?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
backgroundImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
productImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	beforeTitle?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	button?:ValueTypes["FieldMeta"],
	backgroundImage?:ValueTypes["FieldMeta"],
	productImage?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["ProductBannerListOrderBy"] | undefined | null,
	banner?: ValueTypes["ProductBannerOrderBy"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductBannerListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductBannerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	beforeTitle?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	label?: ValueTypes["OrderDirection"] | undefined | null,
	button?: ValueTypes["LinkOrderBy"] | undefined | null,
	backgroundImage?: ValueTypes["ImageOrderBy"] | undefined | null,
	productImage?: ValueTypes["ImageOrderBy"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null
};
	["ProductBannerItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductBannerItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerItemEdge"]: AliasType<{
	node?:ValueTypes["ProductBannerItem"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegions"]: AliasType<{
	_meta?:ValueTypes["DeliveryRegionsMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegionsItem"]],
itemsByImage?: [{	by: ValueTypes["DeliveryRegionsItemsByImageUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
itemsByTextList?: [{	by: ValueTypes["DeliveryRegionsItemsByTextListUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
itemsByGallery?: [{	by: ValueTypes["DeliveryRegionsItemsByGalleryUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
paginateItems?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItem"]: AliasType<{
	_meta?:ValueTypes["DeliveryRegionsItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	offerLabel?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
textList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
gallery?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	offerLabel?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	textList?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextList"]: AliasType<{
	_meta?:ValueTypes["TextListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextItem"]],
paginateItems?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TextListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextItem"]: AliasType<{
	_meta?:ValueTypes["TextItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TextItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	text?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["TextListOrderBy"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null
};
	["TextListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["TextItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TextItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TextItemEdge"]: AliasType<{
	node?:ValueTypes["TextItem"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	offerLabel?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["DeliveryRegionsOrderBy"] | undefined | null,
	image?: ValueTypes["ImageOrderBy"] | undefined | null,
	textList?: ValueTypes["TextListOrderBy"] | undefined | null,
	gallery?: ValueTypes["ImageListOrderBy"] | undefined | null
};
	["DeliveryRegionsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["DeliveryRegionsItemsByImageUniqueWhere"]: {
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemsByTextListUniqueWhere"]: {
	textList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemsByGalleryUniqueWhere"]: {
	gallery?: ValueTypes["ImageListUniqueWhere"] | undefined | null
};
	["DeliveryRegionsItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryRegionsItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsItemEdge"]: AliasType<{
	node?:ValueTypes["DeliveryRegionsItem"],
		__typename?: boolean | `@${string}`
}>;
	["GridTiles"]: AliasType<{
	_meta?:ValueTypes["GridTilesMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GridTileItem"]],
itemsByLink?: [{	by: ValueTypes["GridTilesItemsByLinkUniqueWhere"],	filter?: ValueTypes["GridTileItemWhere"] | undefined | null},ValueTypes["GridTileItem"]],
paginateItems?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GridTileItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GridTilesMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GridTileItem"]: AliasType<{
	_meta?:ValueTypes["GridTileItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null},ValueTypes["GridTiles"]],
medium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["GridTileItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	medium?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GridTileItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	text?: ValueTypes["OrderDirection"] | undefined | null,
	list?: ValueTypes["GridTilesOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null
};
	["GridTilesOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["GridTilesItemsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null
};
	["LinkUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["GridTileItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GridTileItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GridTileItemEdge"]: AliasType<{
	node?:ValueTypes["GridTileItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	primaryText?: ValueTypes["OrderDirection"] | undefined | null,
	secondaryText?: ValueTypes["OrderDirection"] | undefined | null,
	jsonContent?: ValueTypes["OrderDirection"] | undefined | null,
	block?: ValueTypes["ContentBlockOrderBy"] | undefined | null,
	colorPallete?: ValueTypes["ColorPalleteOrderBy"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null,
	link?: ValueTypes["LinkOrderBy"] | undefined | null,
	blogPosts?: ValueTypes["BlogPostListOrderBy"] | undefined | null,
	links?: ValueTypes["LinkListOrderBy"] | undefined | null,
	products?: ValueTypes["ProductListOrderBy"] | undefined | null,
	contentSize?: ValueTypes["OrderDirection"] | undefined | null,
	misc?: ValueTypes["OrderDirection"] | undefined | null,
	hero?: ValueTypes["HeroOrderBy"] | undefined | null,
	gallery?: ValueTypes["GalleryOrderBy"] | undefined | null,
	socialsAndApps?: ValueTypes["SocialsAndAppsOrderBy"] | undefined | null,
	productGrid?: ValueTypes["ProductGridOrderBy"] | undefined | null,
	productBanners?: ValueTypes["ProductBannerListOrderBy"] | undefined | null,
	deliveryRegions?: ValueTypes["DeliveryRegionsOrderBy"] | undefined | null,
	textList?: ValueTypes["TextListOrderBy"] | undefined | null,
	gridTiles?: ValueTypes["GridTilesOrderBy"] | undefined | null
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	json?: ValueTypes["OrderDirection"] | undefined | null,
	content?: ValueTypes["ContentOrderBy"] | undefined | null
};
	["HeroOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	showLogo?: ValueTypes["OrderDirection"] | undefined | null,
	middleContent?: ValueTypes["OrderDirection"] | undefined | null,
	medium?: ValueTypes["MediumOrderBy"] | undefined | null
};
	["SocialsAndAppsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	instagram?: ValueTypes["OrderDirection"] | undefined | null,
	appStore?: ValueTypes["OrderDirection"] | undefined | null,
	googlePlay?: ValueTypes["OrderDirection"] | undefined | null
};
	["ContentBlockReferencesByProductsUniqueWhere"]: {
	products?: ValueTypes["ProductListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByProductBannersUniqueWhere"]: {
	productBanners?: ValueTypes["ProductBannerListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: {
	deliveryRegions?: ValueTypes["DeliveryRegionsUniqueWhere"] | undefined | null
};
	["ContentReferenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceEdge"]: AliasType<{
	node?:ValueTypes["ContentReference"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentBlockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ValueTypes["ContentBlock"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifier"]: AliasType<{
	_meta?:ValueTypes["TranslationCataloguesIdentifierMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
catalogueByDomain?: [{	by: ValueTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
catalogueByValues?: [{	by: ValueTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
paginateCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogue"]: AliasType<{
	_meta?:ValueTypes["TranslationCatalogueMeta"],
	id?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
fallback?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
values?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
identifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
valuesByKey?: [{	by: ValueTypes["TranslationCatalogueValuesByKeyUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	fallback?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomain"]: AliasType<{
	_meta?:ValueTypes["TranslationDomainMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
catalogues?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
keys?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationKey"]],
cataloguesByIdentifier?: [{	by: ValueTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
cataloguesByValues?: [{	by: ValueTypes["TranslationDomainCataloguesByValuesUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
keysByIdentifier?: [{	by: ValueTypes["TranslationDomainKeysByIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
keysByValues?: [{	by: ValueTypes["TranslationDomainKeysByValuesUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
paginateCatalogues?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
paginateKeys?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationKeyConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	catalogues?:ValueTypes["FieldMeta"],
	keys?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	domain?: ValueTypes["TranslationDomainOrderBy"] | undefined | null,
	fallback?: ValueTypes["TranslationCatalogueOrderBy"] | undefined | null,
	identifier?: ValueTypes["TranslationCataloguesIdentifierOrderBy"] | undefined | null
};
	["TranslationDomainOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	identifier?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null
};
	["TranslationKey"]: AliasType<{
	_meta?:ValueTypes["TranslationKeyMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
values?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
valuesByCatalogue?: [{	by: ValueTypes["TranslationKeyValuesByCatalogueUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	contentType?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValue"]: AliasType<{
	_meta?:ValueTypes["TranslationValueMeta"],
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
key?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	value?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
	key?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	value?: ValueTypes["OrderDirection"] | undefined | null,
	catalogue?: ValueTypes["TranslationCatalogueOrderBy"] | undefined | null,
	key?: ValueTypes["TranslationKeyOrderBy"] | undefined | null
};
	["TranslationKeyOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	identifier?: ValueTypes["OrderDirection"] | undefined | null,
	contentType?: ValueTypes["OrderDirection"] | undefined | null,
	note?: ValueTypes["OrderDirection"] | undefined | null,
	domain?: ValueTypes["TranslationDomainOrderBy"] | undefined | null
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null
};
	["TranslationValueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationValueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueEdge"]: AliasType<{
	node?:ValueTypes["TranslationValue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?: string | undefined | null
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCatalogueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueEdge"]: AliasType<{
	node?:ValueTypes["TranslationCatalogue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationKeyEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyEdge"]: AliasType<{
	node?:ValueTypes["TranslationKey"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["ProductPackingLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	shortName?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductPackingOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["ProductLocale"]: AliasType<{
	_meta?:ValueTypes["ProductLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
featureList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
	printableName?:boolean | `@${string}`,
	firstSticker?:boolean | `@${string}`,
	secondSticker?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	featureList?:ValueTypes["FieldMeta"],
	printableName?:ValueTypes["FieldMeta"],
	firstSticker?:ValueTypes["FieldMeta"],
	secondSticker?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	featureList?: ValueTypes["TextListOrderBy"] | undefined | null,
	printableName?: ValueTypes["OrderDirection"] | undefined | null,
	firstSticker?: ValueTypes["OrderDirection"] | undefined | null,
	secondSticker?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductRecipeLocale"]: AliasType<{
	_meta?:ValueTypes["ProductRecipeLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipe"]: AliasType<{
	_meta?:ValueTypes["ProductRecipeMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
	internalNote?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
ingredients?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItem"]],
	needsPrepping?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
	gutTuning?:boolean | `@${string}`,
freshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
	description?:boolean | `@${string}`,
productsByCode?: [{	by: ValueTypes["ProductRecipeProductsByCodeUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByStocks?: [{	by: ValueTypes["ProductRecipeProductsByStocksUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByGroupPrices?: [{	by: ValueTypes["ProductRecipeProductsByGroupPricesUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByCartItems?: [{	by: ValueTypes["ProductRecipeProductsByCartItemsUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByOrderItems?: [{	by: ValueTypes["ProductRecipeProductsByOrderItemsUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByLocales?: [{	by: ValueTypes["ProductRecipeProductsByLocalesUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByCustomerPrices?: [{	by: ValueTypes["ProductRecipeProductsByCustomerPricesUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByVirtualProduct?: [{	by: ValueTypes["ProductRecipeProductsByVirtualProductUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByMeta?: [{	by: ValueTypes["ProductRecipeProductsByMetaUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByPage?: [{	by: ValueTypes["ProductRecipeProductsByPageUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
localesByLocale?: [{	by: ValueTypes["ProductRecipeLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateIngredients?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	color?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
	ingredients?:ValueTypes["FieldMeta"],
	needsPrepping?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	gutTuning?:ValueTypes["FieldMeta"],
	freshingContainer?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItem"]: AliasType<{
	_meta?:ValueTypes["ProductIngredientItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	internalOrder?:boolean | `@${string}`,
recipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
ingredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	internalOrder?:ValueTypes["FieldMeta"],
	recipe?:ValueTypes["FieldMeta"],
	ingredient?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	internalOrder?: ValueTypes["OrderDirection"] | undefined | null,
	recipe?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	ingredient?: ValueTypes["ProductIngredientOrderBy"] | undefined | null
};
	["ProductRecipeLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["ProductRecipeOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductRecipeProductsByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["ProductRecipeProductsByStocksUniqueWhere"]: {
	stocks?: ValueTypes["ProductStockUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByGroupPricesUniqueWhere"]: {
	groupPrices?: ValueTypes["ProductGroupPriceUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByCartItemsUniqueWhere"]: {
	cartItems?: ValueTypes["CartItemUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByOrderItemsUniqueWhere"]: {
	orderItems?: ValueTypes["OrderItemUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByLocalesUniqueWhere"]: {
	locales?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByCustomerPricesUniqueWhere"]: {
	customerPrices?: ValueTypes["ProductCustomerPriceUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByVirtualProductUniqueWhere"]: {
	virtualProduct?: ValueTypes["VirtualProductUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByMetaUniqueWhere"]: {
	meta?: ValueTypes["ProductMetadataUniqueWhere"] | undefined | null
};
	["ProductRecipeProductsByPageUniqueWhere"]: {
	page?: ValueTypes["PageUniqueWhere"] | undefined | null
};
	["ProductRecipeLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductIngredientItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientItemEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductRecipeLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductRecipeLocale"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeLocale"]: AliasType<{
	_meta?:ValueTypes["IdentificationCodeLocaleMeta"],
	id?:boolean | `@${string}`,
	successMessage?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null},ValueTypes["IdentificationCode"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	successMessage?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCode"]: AliasType<{
	_meta?:ValueTypes["IdentificationCodeMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCodeLocale"]],
	internalName?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["IdentificationCodeLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null},ValueTypes["IdentificationCodeLocale"]],
paginateLocales?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	successMessage?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["IdentificationCodeOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null
};
	["IdentificationCodeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["OrderDirection"] | undefined | null,
	internalName?: ValueTypes["OrderDirection"] | undefined | null
};
	["IdentificationCodeLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["IdentificationCodeLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IdentificationCodeLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeLocaleEdge"]: AliasType<{
	node?:ValueTypes["IdentificationCodeLocale"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxLocale"]: AliasType<{
	_meta?:ValueTypes["SubscriptionBoxLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	stickerText?:boolean | `@${string}`,
	descriptionText?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null},ValueTypes["SubscriptionBox"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
	secondaryTitle?:boolean | `@${string}`,
texts?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
secondaryTexts?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	stickerText?:ValueTypes["FieldMeta"],
	descriptionText?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	secondaryTitle?:ValueTypes["FieldMeta"],
	texts?:ValueTypes["FieldMeta"],
	secondaryTexts?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBox"]: AliasType<{
	_meta?:ValueTypes["SubscriptionBoxMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
	unique?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["SubscriptionBoxLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
localesByTexts?: [{	by: ValueTypes["SubscriptionBoxLocalesByTextsUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
localesBySecondaryTexts?: [{	by: ValueTypes["SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
paginateLocales?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	stickerText?: ValueTypes["OrderDirection"] | undefined | null,
	descriptionText?: ValueTypes["OrderDirection"] | undefined | null,
	root?: ValueTypes["SubscriptionBoxOrderBy"] | undefined | null,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null,
	secondaryTitle?: ValueTypes["OrderDirection"] | undefined | null,
	texts?: ValueTypes["TextListOrderBy"] | undefined | null,
	secondaryTexts?: ValueTypes["TextListOrderBy"] | undefined | null
};
	["SubscriptionBoxOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	unique?: ValueTypes["OrderDirection"] | undefined | null
};
	["SubscriptionBoxLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["SubscriptionBoxLocalesByTextsUniqueWhere"]: {
	texts?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"]: {
	secondaryTexts?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["SubscriptionBoxLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SubscriptionBoxLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxLocaleEdge"]: AliasType<{
	node?:ValueTypes["SubscriptionBoxLocale"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleBusinessCategoriesByRootUniqueWhere"]: {
	root?: ValueTypes["BusinessCategoryUniqueWhere"] | undefined | null
};
	["LocaleProductCategoryListsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null
};
	["LocaleProductIngredientsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["LocaleOrderRecurrencesByRootUniqueWhere"]: {
	root?: ValueTypes["OrderRecurrenceUniqueWhere"] | undefined | null
};
	["LocaleBlogsByRootUniqueWhere"]: {
	root?: ValueTypes["BlogUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByRootUniqueWhere"]: {
	root?: ValueTypes["BlogPostUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleBlogPostsBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["LocalePagesByRootUniqueWhere"]: {
	root?: ValueTypes["PageUniqueWhere"] | undefined | null
};
	["LocalePagesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocalePagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null
};
	["LocalePagesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null
};
	["LocalePagesByCoverUniqueWhere"]: {
	cover?: ValueTypes["CoverUniqueWhere"] | undefined | null
};
	["LocaleAllergensByRootUniqueWhere"]: {
	root?: ValueTypes["AllergenUniqueWhere"] | undefined | null
};
	["LocaleProductPackingsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductPackingUniqueWhere"] | undefined | null
};
	["LocaleProductsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["LocaleProductsByFeatureListUniqueWhere"]: {
	featureList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["LocaleProductRecipesByRootUniqueWhere"]: {
	root?: ValueTypes["ProductRecipeUniqueWhere"] | undefined | null
};
	["LocaleDeliveryMethodsByRootUniqueWhere"]: {
	root?: ValueTypes["DeliveryMethodUniqueWhere"] | undefined | null
};
	["LocaleIdentificationCodesByRootUniqueWhere"]: {
	root?: ValueTypes["IdentificationCodeUniqueWhere"] | undefined | null
};
	["LocaleSubscriptionBoxesByRootUniqueWhere"]: {
	root?: ValueTypes["SubscriptionBoxUniqueWhere"] | undefined | null
};
	["LocaleSubscriptionBoxesByTextsUniqueWhere"]: {
	texts?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"]: {
	secondaryTexts?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["ProductPackingLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductPackingLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductPackingLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductStock"]: AliasType<{
	_meta?:ValueTypes["ProductStockMeta"],
	id?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
store?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null},ValueTypes["Store"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductStockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	quantity?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	store?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Store"]: AliasType<{
	_meta?:ValueTypes["StoreMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StoreMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	quantity?: ValueTypes["OrderDirection"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null,
	store?: ValueTypes["StoreOrderBy"] | undefined | null
};
	["StoreOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	name?: ValueTypes["OrderDirection"] | undefined | null,
	code?: ValueTypes["OrderDirection"] | undefined | null,
	description?: ValueTypes["OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["OrderDirection"] | undefined | null
};
	["ProductGroupPrice"]: AliasType<{
	_meta?:ValueTypes["ProductGroupPriceMeta"],
	id?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
group?: [{	filter?: ValueTypes["CustomerGroupWhere"] | undefined | null},ValueTypes["CustomerGroup"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductGroupPriceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	group?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerGroup"]: AliasType<{
	_meta?:ValueTypes["CustomerGroupMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
productPrices?: [{	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGroupPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGroupPrice"]],
customers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
	discountPermille?:boolean | `@${string}`,
productPricesByProduct?: [{	by: ValueTypes["CustomerGroupProductPricesByProductUniqueWhere"],	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null},ValueTypes["ProductGroupPrice"]],
customersByDoubleshotLegacyId?: [{	by: ValueTypes["CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByAddresses?: [{	by: ValueTypes["CustomerGroupCustomersByAddressesUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByDefaultBillingAddress?: [{	by: ValueTypes["CustomerGroupCustomersByDefaultBillingAddressUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByCredits?: [{	by: ValueTypes["CustomerGroupCustomersByCreditsUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByOrders?: [{	by: ValueTypes["CustomerGroupCustomersByOrdersUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByPermissions?: [{	by: ValueTypes["CustomerGroupCustomersByPermissionsUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByName?: [{	by: ValueTypes["CustomerGroupCustomersByNameUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByIngredientRatings?: [{	by: ValueTypes["CustomerGroupCustomersByIngredientRatingsUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByBusiness?: [{	by: ValueTypes["CustomerGroupCustomersByBusinessUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByCarts?: [{	by: ValueTypes["CustomerGroupCustomersByCartsUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByUser?: [{	by: ValueTypes["CustomerGroupCustomersByUserUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByInvoices?: [{	by: ValueTypes["CustomerGroupCustomersByInvoicesUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByInvoicingPlan?: [{	by: ValueTypes["CustomerGroupCustomersByInvoicingPlanUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByBillingSubjects?: [{	by: ValueTypes["CustomerGroupCustomersByBillingSubjectsUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByOfferedProducts?: [{	by: ValueTypes["CustomerGroupCustomersByOfferedProductsUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByDefaultDeliveryAddress?: [{	by: ValueTypes["CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByDefaultBillingSubject?: [{	by: ValueTypes["CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByAnonymousSession?: [{	by: ValueTypes["CustomerGroupCustomersByAnonymousSessionUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByBoughtVouchers?: [{	by: ValueTypes["CustomerGroupCustomersByBoughtVouchersUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByOwnsVouchers?: [{	by: ValueTypes["CustomerGroupCustomersByOwnsVouchersUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByRedeemedVouchers?: [{	by: ValueTypes["CustomerGroupCustomersByRedeemedVouchersUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
customersByMeta?: [{	by: ValueTypes["CustomerGroupCustomersByMetaUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
paginateProductPrices?: [{	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGroupPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGroupPriceConnection"]],
paginateCustomers?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CustomerGroupMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	productPrices?:ValueTypes["FieldMeta"],
	customers?:ValueTypes["FieldMeta"],
	discountPermille?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGroupPriceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	group?: ValueTypes["CustomerGroupOrderBy"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null
};
	["CustomerGroupProductPricesByProductUniqueWhere"]: {
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["CustomerGroupCustomersByAddressesUniqueWhere"]: {
	addresses?: ValueTypes["AddressUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByDefaultBillingAddressUniqueWhere"]: {
	defaultBillingAddress?: ValueTypes["AddressUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByCreditsUniqueWhere"]: {
	credits?: ValueTypes["CustomerCreditUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByOrdersUniqueWhere"]: {
	orders?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByPermissionsUniqueWhere"]: {
	permissions?: ValueTypes["CustomerPermissionsUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByNameUniqueWhere"]: {
	name?: ValueTypes["CustomerNameUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByIngredientRatingsUniqueWhere"]: {
	ingredientRatings?: ValueTypes["CustomerIngredientRatingUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByBusinessUniqueWhere"]: {
	business?: ValueTypes["BusinessCustomerUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByCartsUniqueWhere"]: {
	carts?: ValueTypes["CartUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByUserUniqueWhere"]: {
	user?: ValueTypes["UserUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByInvoicesUniqueWhere"]: {
	invoices?: ValueTypes["InvoiceUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByInvoicingPlanUniqueWhere"]: {
	invoicingPlan?: ValueTypes["InvoicingPlanUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByBillingSubjectsUniqueWhere"]: {
	billingSubjects?: ValueTypes["BillingSubjectUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByOfferedProductsUniqueWhere"]: {
	offeredProducts?: ValueTypes["ProductCustomerPriceUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere"]: {
	defaultDeliveryAddress?: ValueTypes["AddressUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere"]: {
	defaultBillingSubject?: ValueTypes["BillingSubjectUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByAnonymousSessionUniqueWhere"]: {
	anonymousSession?: ValueTypes["AnonymousSessionUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByBoughtVouchersUniqueWhere"]: {
	boughtVouchers?: ValueTypes["VoucherUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByOwnsVouchersUniqueWhere"]: {
	ownsVouchers?: ValueTypes["VoucherUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByRedeemedVouchersUniqueWhere"]: {
	redeemedVouchers?: ValueTypes["VoucherRedemptionUniqueWhere"] | undefined | null
};
	["CustomerGroupCustomersByMetaUniqueWhere"]: {
	meta?: ValueTypes["CustomerMetadataUniqueWhere"] | undefined | null
};
	["ProductGroupPriceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductGroupPriceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGroupPriceEdge"]: AliasType<{
	node?:ValueTypes["ProductGroupPrice"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCustomerPrice"]: AliasType<{
	_meta?:ValueTypes["ProductCustomerPriceMeta"],
	id?:boolean | `@${string}`,
	groupDiscountPermille?:boolean | `@${string}`,
	groupPriceCents?:boolean | `@${string}`,
	fullPriceCents?:boolean | `@${string}`,
	discountedPriceCents?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	offered?:boolean | `@${string}`,
	notDiscountable?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCustomerPriceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	groupDiscountPermille?:ValueTypes["FieldMeta"],
	groupPriceCents?:ValueTypes["FieldMeta"],
	fullPriceCents?:ValueTypes["FieldMeta"],
	discountedPriceCents?:ValueTypes["FieldMeta"],
	priceCents?:ValueTypes["FieldMeta"],
	offered?:ValueTypes["FieldMeta"],
	notDiscountable?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCustomerPriceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	groupDiscountPermille?: ValueTypes["OrderDirection"] | undefined | null,
	groupPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	fullPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	discountedPriceCents?: ValueTypes["OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["OrderDirection"] | undefined | null,
	offered?: ValueTypes["OrderDirection"] | undefined | null,
	notDiscountable?: ValueTypes["OrderDirection"] | undefined | null,
	customer?: ValueTypes["CustomerOrderBy"] | undefined | null,
	product?: ValueTypes["ProductOrderBy"] | undefined | null
};
	["ProductMetadata"]: AliasType<{
	_meta?:ValueTypes["ProductMetadataMeta"],
	id?:boolean | `@${string}`,
	archived?:boolean | `@${string}`,
	available?:boolean | `@${string}`,
	availableQuantity?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductMetadataMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	archived?:ValueTypes["FieldMeta"],
	available?:ValueTypes["FieldMeta"],
	availableQuantity?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStocksByStoreUniqueWhere"]: {
	store?: ValueTypes["StoreUniqueWhere"] | undefined | null
};
	["ProductGroupPricesByGroupUniqueWhere"]: {
	group?: ValueTypes["CustomerGroupUniqueWhere"] | undefined | null
};
	["ProductCartItemsByCartUniqueWhere"]: {
	cart?: ValueTypes["CartUniqueWhere"] | undefined | null
};
	["ProductOrderItemsByVirtualProductEffectsUniqueWhere"]: {
	virtualProductEffects?: ValueTypes["VirtualProductEffectUniqueWhere"] | undefined | null
};
	["ProductLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductLocalesByFeatureListUniqueWhere"]: {
	featureList?: ValueTypes["TextListUniqueWhere"] | undefined | null
};
	["ProductCustomerPricesByCustomerUniqueWhere"]: {
	customer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["ProductStockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductStockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductStockEdge"]: AliasType<{
	node?:ValueTypes["ProductStock"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCustomerPriceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCustomerPriceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCustomerPriceEdge"]: AliasType<{
	node?:ValueTypes["ProductCustomerPrice"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ChannelEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelEdge"]: AliasType<{
	node?:ValueTypes["Channel"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerPermissions"]: AliasType<{
	_meta?:ValueTypes["CustomerPermissionsMeta"],
	id?:boolean | `@${string}`,
	canPlaceOrder?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["CustomerPermissionsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	canPlaceOrder?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerName"]: AliasType<{
	_meta?:ValueTypes["CustomerNameMeta"],
	id?:boolean | `@${string}`,
	nominative?:boolean | `@${string}`,
	vocative?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["CustomerNameMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	nominative?:ValueTypes["FieldMeta"],
	vocative?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["User"]: AliasType<{
	_meta?:ValueTypes["UserMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null},ValueTypes["Staff"]],
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
	tenantPersonId?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	verifiedAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	deletionRequestedAt?:boolean | `@${string}`,
	deletionExpectedAt?:boolean | `@${string}`,
	deletedAt?:boolean | `@${string}`,
meta?: [{	filter?: ValueTypes["UserMetadataWhere"] | undefined | null},ValueTypes["UserMetadata"]],
		__typename?: boolean | `@${string}`
}>;
	["UserMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	staff?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	tenantPersonId?:ValueTypes["FieldMeta"],
	disabledAt?:ValueTypes["FieldMeta"],
	verifiedAt?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	deletionRequestedAt?:ValueTypes["FieldMeta"],
	deletionExpectedAt?:ValueTypes["FieldMeta"],
	deletedAt?:ValueTypes["FieldMeta"],
	meta?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Staff"]: AliasType<{
	_meta?:ValueTypes["StaffMeta"],
	id?:boolean | `@${string}`,
user?: [{	filter?: ValueTypes["UserWhere"] | undefined | null},ValueTypes["User"]],
photo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StaffMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	user?:ValueTypes["FieldMeta"],
	photo?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["UserMetadata"]: AliasType<{
	_meta?:ValueTypes["UserMetadataMeta"],
	id?:boolean | `@${string}`,
	userProfileUrl?:boolean | `@${string}`,
	tenantEmail?:boolean | `@${string}`,
	tenantPersonId?:boolean | `@${string}`,
	tenantIdentityId?:boolean | `@${string}`,
	globalRoles?:boolean | `@${string}`,
	projectRoles?:boolean | `@${string}`,
	projectRolesString?:boolean | `@${string}`,
	externalIdentifiers?:boolean | `@${string}`,
	externalIdentifiersString?:boolean | `@${string}`,
	idpSlugs?:boolean | `@${string}`,
	idpSlugsString?:boolean | `@${string}`,
user?: [{	filter?: ValueTypes["UserWhere"] | undefined | null},ValueTypes["User"]],
		__typename?: boolean | `@${string}`
}>;
	["UserMetadataMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	userProfileUrl?:ValueTypes["FieldMeta"],
	tenantEmail?:ValueTypes["FieldMeta"],
	tenantPersonId?:ValueTypes["FieldMeta"],
	tenantIdentityId?:ValueTypes["FieldMeta"],
	globalRoles?:ValueTypes["FieldMeta"],
	projectRoles?:ValueTypes["FieldMeta"],
	projectRolesString?:ValueTypes["FieldMeta"],
	externalIdentifiers?:ValueTypes["FieldMeta"],
	externalIdentifiersString?:ValueTypes["FieldMeta"],
	idpSlugs?:ValueTypes["FieldMeta"],
	idpSlugsString?:ValueTypes["FieldMeta"],
	user?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["InvoicingPlan"]: AliasType<{
	_meta?:ValueTypes["InvoicingPlanMeta"],
	id?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
preset?: [{	filter?: ValueTypes["InvoicingPresetWhere"] | undefined | null},ValueTypes["InvoicingPreset"]],
		__typename?: boolean | `@${string}`
}>;
	["InvoicingPlanMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
	preset?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["InvoicingPreset"]: AliasType<{
	_meta?:ValueTypes["InvoicingPresetMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	startsAt?:boolean | `@${string}`,
	endsAt?:boolean | `@${string}`,
	intervalOfDays?:boolean | `@${string}`,
invoicingPlan?: [{	filter?: ValueTypes["InvoicingPlanWhere"] | undefined | null},ValueTypes["InvoicingPlan"]],
		__typename?: boolean | `@${string}`
}>;
	["InvoicingPresetMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	startsAt?:ValueTypes["FieldMeta"],
	endsAt?:ValueTypes["FieldMeta"],
	intervalOfDays?:ValueTypes["FieldMeta"],
	invoicingPlan?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AnonymousSession"]: AliasType<{
	_meta?:ValueTypes["AnonymousSessionMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	lastActiveAt?:boolean | `@${string}`,
	anonymousSessionKey?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["AnonymousSessionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	lastActiveAt?:ValueTypes["FieldMeta"],
	anonymousSessionKey?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	internalNote?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerMetadata"]: AliasType<{
	_meta?:ValueTypes["CustomerMetadataMeta"],
	id?:boolean | `@${string}`,
	isBusiness?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	fallbackName?:boolean | `@${string}`,
	remainingCreditsCents?:boolean | `@${string}`,
	ordersTotalCents?:boolean | `@${string}`,
	customerProfileUrl?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["CustomerMetadataMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	isBusiness?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	fallbackName?:ValueTypes["FieldMeta"],
	remainingCreditsCents?:ValueTypes["FieldMeta"],
	ordersTotalCents?:ValueTypes["FieldMeta"],
	customerProfileUrl?:ValueTypes["FieldMeta"],
	customer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerAddressesByMetaUniqueWhere"]: {
	meta?: ValueTypes["AddressMetadataUniqueWhere"] | undefined | null
};
	["CustomerAddressesByGpsUniqueWhere"]: {
	gps?: ValueTypes["GpsUniqueWhere"] | undefined | null
};
	["CustomerAddressesByInvoicesUniqueWhere"]: {
	invoices?: ValueTypes["InvoiceUniqueWhere"] | undefined | null
};
	["CustomerAddressesByBillingAddressOfOrdersUniqueWhere"]: {
	billingAddressOfOrders?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
	deliveryAddressOfOrderDeliveries?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
	defaultDeliveryAddressOfCustomer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerCreditsByVoucherRedemptionUniqueWhere"]: {
	voucherRedemption?: ValueTypes["VoucherRedemptionUniqueWhere"] | undefined | null
};
	["CustomerOrdersBySeqUniqueWhere"]: {
	seq?: number | undefined | null
};
	["CustomerOrdersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
	doubleShotLegacyNumber?: string | undefined | null
};
	["CustomerOrdersByDiscountsUniqueWhere"]: {
	discounts?: ValueTypes["OrderDiscountUniqueWhere"] | undefined | null
};
	["CustomerOrdersByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["CustomerOrdersByItemsUniqueWhere"]: {
	items?: ValueTypes["OrderItemUniqueWhere"] | undefined | null
};
	["CustomerOrdersByCartUniqueWhere"]: {
	cart?: ValueTypes["CartUniqueWhere"] | undefined | null
};
	["CustomerOrdersByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["OrderPriceLineUniqueWhere"] | undefined | null
};
	["CustomerOrdersByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["CustomerOrdersBySummaryUniqueWhere"]: {
	summary?: ValueTypes["OrderSummaryUniqueWhere"] | undefined | null
};
	["CustomerIngredientRatingsByIngredientUniqueWhere"]: {
	ingredient?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["CustomerCartsByItemsUniqueWhere"]: {
	items?: ValueTypes["CartItemUniqueWhere"] | undefined | null
};
	["CustomerCartsByOrderUniqueWhere"]: {
	order?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["CustomerCartsByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["CartPriceLineUniqueWhere"] | undefined | null
};
	["CustomerCartsBySummaryUniqueWhere"]: {
	summary?: ValueTypes["CartSummaryUniqueWhere"] | undefined | null
};
	["CustomerInvoicesByPublicKeyUniqueWhere"]: {
	publicKey?: string | undefined | null
};
	["CustomerInvoicesByFakturoidIdUniqueWhere"]: {
	fakturoidId?: string | undefined | null
};
	["CustomerInvoicesByOrderPaymentsUniqueWhere"]: {
	orderPayments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["CustomerBillingSubjectsByInvoicesUniqueWhere"]: {
	invoices?: ValueTypes["InvoiceUniqueWhere"] | undefined | null
};
	["CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere"]: {
	defaultBillingSubjectOfCustomer?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerBillingSubjectsByOrdersUniqueWhere"]: {
	orders?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["CustomerOfferedProductsByProductUniqueWhere"]: {
	product?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["CustomerBoughtVouchersByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["CustomerBoughtVouchersByRedemptionUniqueWhere"]: {
	redemption?: ValueTypes["VoucherRedemptionUniqueWhere"] | undefined | null
};
	["CustomerOwnsVouchersByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["CustomerOwnsVouchersByRedemptionUniqueWhere"]: {
	redemption?: ValueTypes["VoucherRedemptionUniqueWhere"] | undefined | null
};
	["CustomerRedeemedVouchersByCustomerCreditUniqueWhere"]: {
	customerCredit?: ValueTypes["CustomerCreditUniqueWhere"] | undefined | null
};
	["CustomerRedeemedVouchersByOrderDiscountUniqueWhere"]: {
	orderDiscount?: ValueTypes["OrderDiscountUniqueWhere"] | undefined | null
};
	["CustomerRedeemedVouchersByVoucherUniqueWhere"]: {
	voucher?: ValueTypes["VoucherUniqueWhere"] | undefined | null
};
	["CustomerCreditConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CustomerCreditEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerCreditEdge"]: AliasType<{
	node?:ValueTypes["CustomerCredit"],
		__typename?: boolean | `@${string}`
}>;
	["BillingSubjectConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BillingSubjectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BillingSubjectEdge"]: AliasType<{
	node?:ValueTypes["BillingSubject"],
		__typename?: boolean | `@${string}`
}>;
	["VoucherRedemptionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VoucherRedemptionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VoucherRedemptionEdge"]: AliasType<{
	node?:ValueTypes["VoucherRedemption"],
		__typename?: boolean | `@${string}`
}>;
	["Gps"]: AliasType<{
	_meta?:ValueTypes["GpsMeta"],
	id?:boolean | `@${string}`,
	latitude?:boolean | `@${string}`,
	longitude?:boolean | `@${string}`,
address?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
		__typename?: boolean | `@${string}`
}>;
	["GpsMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	latitude?:ValueTypes["FieldMeta"],
	longitude?:ValueTypes["FieldMeta"],
	address?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AddressInvoicesByPublicKeyUniqueWhere"]: {
	publicKey?: string | undefined | null
};
	["AddressInvoicesByFakturoidIdUniqueWhere"]: {
	fakturoidId?: string | undefined | null
};
	["AddressInvoicesByOrderPaymentsUniqueWhere"]: {
	orderPayments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["AddressBillingAddressOfOrdersBySeqUniqueWhere"]: {
	seq?: number | undefined | null
};
	["AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
	doubleShotLegacyNumber?: string | undefined | null
};
	["AddressBillingAddressOfOrdersByDiscountsUniqueWhere"]: {
	discounts?: ValueTypes["OrderDiscountUniqueWhere"] | undefined | null
};
	["AddressBillingAddressOfOrdersByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null
};
	["AddressBillingAddressOfOrdersByItemsUniqueWhere"]: {
	items?: ValueTypes["OrderItemUniqueWhere"] | undefined | null
};
	["AddressBillingAddressOfOrdersByCartUniqueWhere"]: {
	cart?: ValueTypes["CartUniqueWhere"] | undefined | null
};
	["AddressBillingAddressOfOrdersByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["OrderPriceLineUniqueWhere"] | undefined | null
};
	["AddressBillingAddressOfOrdersByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["OrderPaymentUniqueWhere"] | undefined | null
};
	["AddressBillingAddressOfOrdersBySummaryUniqueWhere"]: {
	summary?: ValueTypes["OrderSummaryUniqueWhere"] | undefined | null
};
	["AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"]: {
	trackingCode?: string | undefined | null
};
	["AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"]: {
	order?: ValueTypes["OrderUniqueWhere"] | undefined | null
};
	["OrderDeliveryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderDeliveryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDeliveryEdge"]: AliasType<{
	node?:ValueTypes["OrderDelivery"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationResult"]: AliasType<{
	valid?:boolean | `@${string}`,
	errors?:ValueTypes["_ValidationError"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	message?:ValueTypes["_ValidationMessage"],
		__typename?: boolean | `@${string}`
}>;
	["_PathFragment"]: AliasType<{		["...on _FieldPathFragment"] : ValueTypes["_FieldPathFragment"],
		["...on _IndexPathFragment"] : ValueTypes["_IndexPathFragment"]
		__typename?: boolean | `@${string}`
}>;
	["_FieldPathFragment"]: AliasType<{
	field?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_IndexPathFragment"]: AliasType<{
	index?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_ValidationMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddressCreateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["AddressCreateCustomerEntityRelationInput"] | undefined | null,
	country?: ValueTypes["AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AddressCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["AddressCreateCountryEntityRelationInput"]: {
	connect?: ValueTypes["CountryUniqueWhere"] | undefined | null
};
	["CountryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["AddressCreateDeliveryZoneEntityRelationInput"]: {
	connect?: ValueTypes["DeliveryZoneUniqueWhere"] | undefined | null
};
	["DeliveryZoneUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	addresses?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	addressesMetadata?: ValueTypes["AddressMetadataUniqueWhere"] | undefined | null
};
	["AddressCreateGpsEntityRelationInput"]: {
	connect?: ValueTypes["GpsUniqueWhere"] | undefined | null,
	create?: ValueTypes["GpsWithoutAddressCreateInput"] | undefined | null
};
	["GpsWithoutAddressCreateInput"]: {
	latitude?: number | undefined | null,
	longitude?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AddressCreateBillingAddressOfOrdersEntityRelationInput"]: {
	connect?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["OrderWithoutBillingAddressCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["OrderWithoutBillingAddressCreateInput"]: {
	state?: ValueTypes["OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["OrderCreateChannelEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["OrderCreateItemsEntityRelationInput"]> | undefined | null,
	cart?: ValueTypes["OrderCreateCartEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	fulfillmentNote?: string | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["OrderCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["OrderCreateChannelEntityRelationInput"]: {
	connect?: ValueTypes["ChannelUniqueWhere"] | undefined | null
};
	["ChannelUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: ValueTypes["ChannelCode"] | undefined | null,
	payments?: ValueTypes["ChannelPaymentUniqueWhere"] | undefined | null,
	deliveries?: ValueTypes["ChannelDeliveryUniqueWhere"] | undefined | null,
	carts?: ValueTypes["CartUniqueWhere"] | undefined | null
};
	["ChannelPaymentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["OrderCreateDeliveryEntityRelationInput"]: {
	connect?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["OrderDeliveryWithoutOrderCreateInput"] | undefined | null
};
	["OrderDeliveryWithoutOrderCreateInput"]: {
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["OrderDeliveryCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["OrderDeliveryCreateVatRateEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	address?: ValueTypes["OrderDeliveryCreateAddressEntityRelationInput"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["OrderDeliveryCreateMethodEntityRelationInput"]: {
	connect?: ValueTypes["DeliveryMethodUniqueWhere"] | undefined | null
};
	["OrderDeliveryCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["VatRateUniqueWhere"] | undefined | null
};
	["VatRateUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	preset?: ValueTypes["VatRatePreset"] | undefined | null,
	channelDeliveries?: ValueTypes["ChannelDeliveryUniqueWhere"] | undefined | null,
	channelPayments?: ValueTypes["ChannelPaymentUniqueWhere"] | undefined | null
};
	["OrderDeliveryCreateAddressEntityRelationInput"]: {
	connect?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"] | undefined | null
};
	["AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["AddressCreateCustomerEntityRelationInput"] | undefined | null,
	country?: ValueTypes["AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["OrderCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["OrderItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["OrderItemWithoutOrderCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["OrderItemWithoutOrderCreateInput"]: {
	unitPriceCents?: number | undefined | null,
	quantity?: number | undefined | null,
	vatRate?: ValueTypes["OrderItemCreateVatRateEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	product?: ValueTypes["OrderItemCreateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["OrderItemCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["VatRateUniqueWhere"] | undefined | null
};
	["OrderItemCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["OrderCreateCartEntityRelationInput"]: {
	connect?: ValueTypes["CartUniqueWhere"] | undefined | null,
	create?: ValueTypes["CartWithoutOrderCreateInput"] | undefined | null
};
	["CartWithoutOrderCreateInput"]: {
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	items?: Array<ValueTypes["CartCreateItemsEntityRelationInput"]> | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	channel?: ValueTypes["CartCreateChannelEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["CartCreateCustomerEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["CartCreateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["CartCreateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["CartCreateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["CartCreatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["CartCreateBillingSubjectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CartCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["CartItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["CartItemWithoutCartCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["CartItemWithoutCartCreateInput"]: {
	quantity?: number | undefined | null,
	product?: ValueTypes["CartItemCreateProductEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CartItemCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["ProductUniqueWhere"] | undefined | null
};
	["CartCreateChannelEntityRelationInput"]: {
	connect?: ValueTypes["ChannelUniqueWhere"] | undefined | null
};
	["CartCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CartCreateDeliveryAddressEntityRelationInput"]: {
	connect?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["AddressCreateInput"] | undefined | null
};
	["CartCreateBillingAddressEntityRelationInput"]: {
	connect?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["AddressCreateInput"] | undefined | null
};
	["CartCreateDeliveryEntityRelationInput"]: {
	connect?: ValueTypes["ChannelDeliveryUniqueWhere"] | undefined | null
};
	["CartCreatePaymentEntityRelationInput"]: {
	connect?: ValueTypes["ChannelPaymentUniqueWhere"] | undefined | null
};
	["CartCreateBillingSubjectEntityRelationInput"]: {
	connect?: ValueTypes["BillingSubjectUniqueWhere"] | undefined | null,
	create?: ValueTypes["BillingSubjectCreateInput"] | undefined | null
};
	["BillingSubjectCreateInput"]: {
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["BillingSubjectCreateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["BillingSubjectCreateCustomerEntityRelationInput"] | undefined | null,
	orders?: Array<ValueTypes["BillingSubjectCreateOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["BillingSubjectCreateCountryEntityRelationInput"]: {
	connect?: ValueTypes["CountryUniqueWhere"] | undefined | null
};
	["BillingSubjectCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["BillingSubjectCreateOrdersEntityRelationInput"]: {
	connect?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["OrderWithoutBillingSubjectCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["OrderWithoutBillingSubjectCreateInput"]: {
	state?: ValueTypes["OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["OrderCreateChannelEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["OrderCreateItemsEntityRelationInput"]> | undefined | null,
	cart?: ValueTypes["OrderCreateCartEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	seq?: number | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	fulfillmentNote?: string | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["OrderCreateBillingAddressEntityRelationInput"]: {
	connect?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["AddressWithoutBillingAddressOfOrdersCreateInput"] | undefined | null
};
	["AddressWithoutBillingAddressOfOrdersCreateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["AddressCreateCustomerEntityRelationInput"] | undefined | null,
	country?: ValueTypes["AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]: {
	connect?: ValueTypes["OrderDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["OrderDeliveryWithoutAddressCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["OrderDeliveryWithoutAddressCreateInput"]: {
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["OrderDeliveryCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["OrderDeliveryCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["OrderDeliveryCreateOrderEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["OrderDeliveryCreateOrderEntityRelationInput"]: {
	connect?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["OrderWithoutDeliveryCreateInput"] | undefined | null
};
	["OrderWithoutDeliveryCreateInput"]: {
	state?: ValueTypes["OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["OrderCreateChannelEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["OrderCreateItemsEntityRelationInput"]> | undefined | null,
	cart?: ValueTypes["OrderCreateCartEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	fulfillmentNote?: string | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["OrderCreateBillingSubjectEntityRelationInput"]: {
	connect?: ValueTypes["BillingSubjectUniqueWhere"] | undefined | null,
	create?: ValueTypes["BillingSubjectWithoutOrdersCreateInput"] | undefined | null
};
	["BillingSubjectWithoutOrdersCreateInput"]: {
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["BillingSubjectCreateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["BillingSubjectCreateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AddressUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["AddressUpdateCustomerEntityRelationInput"] | undefined | null,
	country?: ValueTypes["AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["AddressUpdateDeliveryZoneEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["AddressUpdateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AddressUpdateCustomerEntityRelationInput"]: {
	update?: ValueTypes["CustomerWithoutAddressesUpdateInput"] | undefined | null,
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["CustomerWithoutAddressesUpdateInput"]: {
	defaultBillingAddress?: ValueTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	name?: ValueTypes["CustomerUpdateNameEntityRelationInput"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	billingSubjects?: Array<ValueTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	anonymousSession?: ValueTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerUpdateDefaultBillingAddressEntityRelationInput"]: {
	create?: ValueTypes["AddressCreateInput"] | undefined | null,
	update?: ValueTypes["AddressUpdateInput"] | undefined | null,
	upsert?: ValueTypes["CustomerUpsertDefaultBillingAddressRelationInput"] | undefined | null,
	connect?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["CustomerUpsertDefaultBillingAddressRelationInput"]: {
	update?: ValueTypes["AddressUpdateInput"] | undefined | null,
	create?: ValueTypes["AddressCreateInput"] | undefined | null
};
	["CustomerUpdateNameEntityRelationInput"]: {
	create?: ValueTypes["CustomerNameWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["CustomerNameWithoutCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["CustomerUpsertNameRelationInput"] | undefined | null,
	connect?: ValueTypes["CustomerNameUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["CustomerNameWithoutCustomerCreateInput"]: {
	nominative?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerNameWithoutCustomerUpdateInput"]: {
	nominative?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerUpsertNameRelationInput"]: {
	update?: ValueTypes["CustomerNameWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["CustomerNameWithoutCustomerCreateInput"] | undefined | null
};
	["CustomerUpdateIngredientRatingsEntityRelationInput"]: {
	create?: ValueTypes["CustomerIngredientRatingWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["CustomerUpdateIngredientRatingsRelationInput"] | undefined | null,
	upsert?: ValueTypes["CustomerUpsertIngredientRatingsRelationInput"] | undefined | null,
	connect?: ValueTypes["CustomerIngredientRatingUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["CustomerIngredientRatingUniqueWhere"] | undefined | null,
	delete?: ValueTypes["CustomerIngredientRatingUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["CustomerIngredientRatingWithoutCustomerCreateInput"]: {
	rating?: ValueTypes["CustomerIngredientRatingCreateRatingEntityRelationInput"] | undefined | null,
	ingredient?: ValueTypes["CustomerIngredientRatingCreateIngredientEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerIngredientRatingCreateRatingEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientRatingUniqueWhere"] | undefined | null
};
	["ProductIngredientRatingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["CustomerIngredientRatingCreateIngredientEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["CustomerUpdateIngredientRatingsRelationInput"]: {
	by?: ValueTypes["CustomerIngredientRatingUniqueWhere"] | undefined | null,
	data?: ValueTypes["CustomerIngredientRatingWithoutCustomerUpdateInput"] | undefined | null
};
	["CustomerIngredientRatingWithoutCustomerUpdateInput"]: {
	rating?: ValueTypes["CustomerIngredientRatingUpdateRatingEntityRelationInput"] | undefined | null,
	ingredient?: ValueTypes["CustomerIngredientRatingUpdateIngredientEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerIngredientRatingUpdateRatingEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientRatingUniqueWhere"] | undefined | null
};
	["CustomerIngredientRatingUpdateIngredientEntityRelationInput"]: {
	connect?: ValueTypes["ProductIngredientUniqueWhere"] | undefined | null
};
	["CustomerUpsertIngredientRatingsRelationInput"]: {
	by?: ValueTypes["CustomerIngredientRatingUniqueWhere"] | undefined | null,
	update?: ValueTypes["CustomerIngredientRatingWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["CustomerIngredientRatingWithoutCustomerCreateInput"] | undefined | null
};
	["CustomerUpdateBillingSubjectsEntityRelationInput"]: {
	create?: ValueTypes["BillingSubjectWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["CustomerUpdateBillingSubjectsRelationInput"] | undefined | null,
	upsert?: ValueTypes["CustomerUpsertBillingSubjectsRelationInput"] | undefined | null,
	connect?: ValueTypes["BillingSubjectUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["BillingSubjectUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["BillingSubjectWithoutCustomerCreateInput"]: {
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["BillingSubjectCreateCountryEntityRelationInput"] | undefined | null,
	orders?: Array<ValueTypes["BillingSubjectCreateOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerUpdateBillingSubjectsRelationInput"]: {
	by?: ValueTypes["BillingSubjectUniqueWhere"] | undefined | null,
	data?: ValueTypes["BillingSubjectWithoutCustomerUpdateInput"] | undefined | null
};
	["BillingSubjectWithoutCustomerUpdateInput"]: {
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["BillingSubjectUpdateCountryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["BillingSubjectUpdateCountryEntityRelationInput"]: {
	connect?: ValueTypes["CountryUniqueWhere"] | undefined | null
};
	["CustomerUpsertBillingSubjectsRelationInput"]: {
	by?: ValueTypes["BillingSubjectUniqueWhere"] | undefined | null,
	update?: ValueTypes["BillingSubjectWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["BillingSubjectWithoutCustomerCreateInput"] | undefined | null
};
	["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"]: {
	create?: ValueTypes["AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["CustomerUpsertDefaultDeliveryAddressRelationInput"] | undefined | null,
	connect?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["AddressCreateCustomerEntityRelationInput"] | undefined | null,
	country?: ValueTypes["AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["AddressUpdateCustomerEntityRelationInput"] | undefined | null,
	country?: ValueTypes["AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["AddressUpdateDeliveryZoneEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["AddressUpdateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AddressUpdateCountryEntityRelationInput"]: {
	connect?: ValueTypes["CountryUniqueWhere"] | undefined | null
};
	["AddressUpdateDeliveryZoneEntityRelationInput"]: {
	connect?: ValueTypes["DeliveryZoneUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["AddressUpdateGpsEntityRelationInput"]: {
	create?: ValueTypes["GpsWithoutAddressCreateInput"] | undefined | null,
	update?: ValueTypes["GpsWithoutAddressUpdateInput"] | undefined | null,
	upsert?: ValueTypes["AddressUpsertGpsRelationInput"] | undefined | null,
	connect?: ValueTypes["GpsUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["GpsWithoutAddressUpdateInput"]: {
	latitude?: number | undefined | null,
	longitude?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AddressUpsertGpsRelationInput"]: {
	update?: ValueTypes["GpsWithoutAddressUpdateInput"] | undefined | null,
	create?: ValueTypes["GpsWithoutAddressCreateInput"] | undefined | null
};
	["CustomerUpsertDefaultDeliveryAddressRelationInput"]: {
	update?: ValueTypes["AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"] | undefined | null
};
	["CustomerUpdateAnonymousSessionEntityRelationInput"]: {
	create?: ValueTypes["AnonymousSessionWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["AnonymousSessionWithoutCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["CustomerUpsertAnonymousSessionRelationInput"] | undefined | null,
	connect?: ValueTypes["AnonymousSessionUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["AnonymousSessionWithoutCustomerCreateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	anonymousSessionKey?: string | undefined | null,
	email?: string | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AnonymousSessionWithoutCustomerUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	anonymousSessionKey?: string | undefined | null,
	email?: string | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerUpsertAnonymousSessionRelationInput"]: {
	update?: ValueTypes["AnonymousSessionWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["AnonymousSessionWithoutCustomerCreateInput"] | undefined | null
};
	["AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"]: {
	update?: ValueTypes["CustomerWithoutDefaultDeliveryAddressUpdateInput"] | undefined | null,
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["CustomerWithoutDefaultDeliveryAddressUpdateInput"]: {
	addresses?: Array<ValueTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	name?: ValueTypes["CustomerUpdateNameEntityRelationInput"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	billingSubjects?: Array<ValueTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	anonymousSession?: ValueTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerUpdateAddressesEntityRelationInput"]: {
	create?: ValueTypes["AddressWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["CustomerUpdateAddressesRelationInput"] | undefined | null,
	upsert?: ValueTypes["CustomerUpsertAddressesRelationInput"] | undefined | null,
	connect?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	delete?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["AddressWithoutCustomerCreateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	country?: ValueTypes["AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerUpdateAddressesRelationInput"]: {
	by?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	data?: ValueTypes["AddressWithoutCustomerUpdateInput"] | undefined | null
};
	["AddressWithoutCustomerUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	country?: ValueTypes["AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["AddressUpdateDeliveryZoneEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["AddressUpdateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerUpsertAddressesRelationInput"]: {
	by?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	update?: ValueTypes["AddressWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["AddressWithoutCustomerCreateInput"] | undefined | null
};
	["CountryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CountryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CountryEdge"]: AliasType<{
	node?:ValueTypes["Country"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerUpdateInput"]: {
	addresses?: Array<ValueTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	name?: ValueTypes["CustomerUpdateNameEntityRelationInput"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	billingSubjects?: Array<ValueTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	anonymousSession?: ValueTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerGroupConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CustomerGroupEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerGroupEdge"]: AliasType<{
	node?:ValueTypes["CustomerGroup"],
		__typename?: boolean | `@${string}`
}>;
	["OrderCreateInput"]: {
	state?: ValueTypes["OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["OrderCreateChannelEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["OrderCreateItemsEntityRelationInput"]> | undefined | null,
	cart?: ValueTypes["OrderCreateCartEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	fulfillmentNote?: string | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["OrderDeliveryCreateInput"]: {
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["OrderDeliveryCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["OrderDeliveryCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["OrderDeliveryCreateOrderEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	address?: ValueTypes["OrderDeliveryCreateAddressEntityRelationInput"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["OrderItemCreateInput"]: {
	unitPriceCents?: number | undefined | null,
	quantity?: number | undefined | null,
	vatRate?: ValueTypes["OrderItemCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["OrderItemCreateOrderEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	product?: ValueTypes["OrderItemCreateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["OrderItemCreateOrderEntityRelationInput"]: {
	connect?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["OrderWithoutItemsCreateInput"] | undefined | null
};
	["OrderWithoutItemsCreateInput"]: {
	state?: ValueTypes["OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["OrderCreateChannelEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	cart?: ValueTypes["OrderCreateCartEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	fulfillmentNote?: string | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["PaymentMethodUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	channels?: ValueTypes["ChannelPaymentUniqueWhere"] | undefined | null
};
	["VatRateConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VatRateEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VatRateEdge"]: AliasType<{
	node?:ValueTypes["VatRate"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductPackingEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductPackingEdge"]: AliasType<{
	node?:ValueTypes["ProductPacking"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductRecipeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductRecipeEdge"]: AliasType<{
	node?:ValueTypes["ProductRecipe"],
		__typename?: boolean | `@${string}`
}>;
	["StoreConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StoreEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StoreEdge"]: AliasType<{
	node?:ValueTypes["Store"],
		__typename?: boolean | `@${string}`
}>;
	["TagUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["CustomerPermissionsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CustomerPermissionsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerPermissionsEdge"]: AliasType<{
	node?:ValueTypes["CustomerPermissions"],
		__typename?: boolean | `@${string}`
}>;
	["ImageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageEdge"]: AliasType<{
	node?:ValueTypes["Image"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductListEdge"]: AliasType<{
	node?:ValueTypes["ProductList"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryList"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleEdge"]: AliasType<{
	node?:ValueTypes["Locale"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerNameConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CustomerNameEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerNameEdge"]: AliasType<{
	node?:ValueTypes["CustomerName"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerNameCreateInput"]: {
	nominative?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerNameUpdateInput"]: {
	nominative?: string | undefined | null,
	customer?: ValueTypes["CustomerNameUpdateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerNameUpdateCustomerEntityRelationInput"]: {
	update?: ValueTypes["CustomerWithoutNameUpdateInput"] | undefined | null,
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["CustomerWithoutNameUpdateInput"]: {
	addresses?: Array<ValueTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	billingSubjects?: Array<ValueTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	anonymousSession?: ValueTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerIngredientRatingCreateInput"]: {
	customer?: ValueTypes["CustomerIngredientRatingCreateCustomerEntityRelationInput"] | undefined | null,
	rating?: ValueTypes["CustomerIngredientRatingCreateRatingEntityRelationInput"] | undefined | null,
	ingredient?: ValueTypes["CustomerIngredientRatingCreateIngredientEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerIngredientRatingCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerIngredientRatingUpdateInput"]: {
	customer?: ValueTypes["CustomerIngredientRatingUpdateCustomerEntityRelationInput"] | undefined | null,
	rating?: ValueTypes["CustomerIngredientRatingUpdateRatingEntityRelationInput"] | undefined | null,
	ingredient?: ValueTypes["CustomerIngredientRatingUpdateIngredientEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CustomerIngredientRatingUpdateCustomerEntityRelationInput"]: {
	update?: ValueTypes["CustomerWithoutIngredientRatingsUpdateInput"] | undefined | null,
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["CustomerWithoutIngredientRatingsUpdateInput"]: {
	addresses?: Array<ValueTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	name?: ValueTypes["CustomerUpdateNameEntityRelationInput"] | undefined | null,
	billingSubjects?: Array<ValueTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	anonymousSession?: ValueTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["ProductIngredientRatingConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductIngredientRatingEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductIngredientRatingEdge"]: AliasType<{
	node?:ValueTypes["ProductIngredientRating"],
		__typename?: boolean | `@${string}`
}>;
	["StaffConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StaffEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StaffEdge"]: AliasType<{
	node?:ValueTypes["Staff"],
		__typename?: boolean | `@${string}`
}>;
	["BlogConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogEdge"]: AliasType<{
	node?:ValueTypes["Blog"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostEdge"]: AliasType<{
	node?:ValueTypes["BlogPost"],
		__typename?: boolean | `@${string}`
}>;
	["ColorPalleteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["ColorPalleteConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ColorPalleteEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ColorPalleteEdge"]: AliasType<{
	node?:ValueTypes["ColorPallete"],
		__typename?: boolean | `@${string}`
}>;
	["ContentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentEdge"]: AliasType<{
	node?:ValueTypes["Content"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["BlogPostListItemUniqueWhere"] | undefined | null
};
	["BlogPostListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["BlogPostListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["BlogPostListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["BlogPostListEdge"]: AliasType<{
	node?:ValueTypes["BlogPostList"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["LinkListItemUniqueWhere"] | undefined | null
};
	["LinkListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["LinkListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListEdge"]: AliasType<{
	node?:ValueTypes["LinkList"],
		__typename?: boolean | `@${string}`
}>;
	["CoverConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CoverEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CoverEdge"]: AliasType<{
	node?:ValueTypes["Cover"],
		__typename?: boolean | `@${string}`
}>;
	["LinkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkEdge"]: AliasType<{
	node?:ValueTypes["Link"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkableEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableEdge"]: AliasType<{
	node?:ValueTypes["Linkable"],
		__typename?: boolean | `@${string}`
}>;
	["MediumUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["MediumConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MediumEdge"],
		__typename?: boolean | `@${string}`
}>;
	["MediumEdge"]: AliasType<{
	node?:ValueTypes["Medium"],
		__typename?: boolean | `@${string}`
}>;
	["VideoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["VideoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VideoEdge"]: AliasType<{
	node?:ValueTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoEdge"]: AliasType<{
	node?:ValueTypes["Seo"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationDomainEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainEdge"]: AliasType<{
	node?:ValueTypes["TranslationDomain"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCataloguesIdentifierEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierEdge"]: AliasType<{
	node?:ValueTypes["TranslationCataloguesIdentifier"],
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndAppsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["SocialsAndAppsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SocialsAndAppsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SocialsAndAppsEdge"]: AliasType<{
	node?:ValueTypes["SocialsAndApps"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	categories?: ValueTypes["ProductGridCategoryUniqueWhere"] | undefined | null
};
	["ProductGridCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["ProductGridItemUniqueWhere"] | undefined | null,
	images?: ValueTypes["ImageListUniqueWhere"] | undefined | null
};
	["ProductGridConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductGridEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductGridEdge"]: AliasType<{
	node?:ValueTypes["ProductGrid"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["GalleryItemUniqueWhere"] | undefined | null
};
	["GalleryItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["GalleryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GalleryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GalleryEdge"]: AliasType<{
	node?:ValueTypes["Gallery"],
		__typename?: boolean | `@${string}`
}>;
	["HeroUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["HeroConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeroEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeroEdge"]: AliasType<{
	node?:ValueTypes["Hero"],
		__typename?: boolean | `@${string}`
}>;
	["PageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageEdge"]: AliasType<{
	node?:ValueTypes["Page"],
		__typename?: boolean | `@${string}`
}>;
	["CartCreateInput"]: {
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	items?: Array<ValueTypes["CartCreateItemsEntityRelationInput"]> | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	channel?: ValueTypes["CartCreateChannelEntityRelationInput"] | undefined | null,
	order?: ValueTypes["CartCreateOrderEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["CartCreateCustomerEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["CartCreateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["CartCreateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["CartCreateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["CartCreatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["CartCreateBillingSubjectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CartCreateOrderEntityRelationInput"]: {
	connect?: ValueTypes["OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["OrderWithoutCartCreateInput"] | undefined | null
};
	["OrderWithoutCartCreateInput"]: {
	state?: ValueTypes["OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["OrderCreateChannelEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["OrderCreateItemsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	fulfillmentNote?: string | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CartItemCreateInput"]: {
	quantity?: number | undefined | null,
	cart?: ValueTypes["CartItemCreateCartEntityRelationInput"] | undefined | null,
	product?: ValueTypes["CartItemCreateProductEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CartItemCreateCartEntityRelationInput"]: {
	connect?: ValueTypes["CartUniqueWhere"] | undefined | null,
	create?: ValueTypes["CartWithoutItemsCreateInput"] | undefined | null
};
	["CartWithoutItemsCreateInput"]: {
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	channel?: ValueTypes["CartCreateChannelEntityRelationInput"] | undefined | null,
	order?: ValueTypes["CartCreateOrderEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["CartCreateCustomerEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["CartCreateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["CartCreateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["CartCreateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["CartCreatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["CartCreateBillingSubjectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CartItemUpdateInput"]: {
	quantity?: number | undefined | null,
	cart?: ValueTypes["CartItemUpdateCartEntityRelationInput"] | undefined | null,
	product?: ValueTypes["CartItemUpdateProductEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["CartItemUpdateCartEntityRelationInput"]: {
	create?: ValueTypes["CartWithoutItemsCreateInput"] | undefined | null,
	connect?: ValueTypes["CartUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["CartItemUpdateProductEntityRelationInput"]: {
	connect?: ValueTypes["ProductUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["ProductBannerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	button?: ValueTypes["LinkUniqueWhere"] | undefined | null
};
	["ProductBannerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductBannerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerEdge"]: AliasType<{
	node?:ValueTypes["ProductBanner"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductBannerListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductBannerListEdge"]: AliasType<{
	node?:ValueTypes["ProductBannerList"],
		__typename?: boolean | `@${string}`
}>;
	["UserConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["UserEdge"],
		__typename?: boolean | `@${string}`
}>;
	["UserEdge"]: AliasType<{
	node?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["GpsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GpsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GpsEdge"]: AliasType<{
	node?:ValueTypes["Gps"],
		__typename?: boolean | `@${string}`
}>;
	["GpsCreateInput"]: {
	latitude?: number | undefined | null,
	longitude?: number | undefined | null,
	address?: ValueTypes["GpsCreateAddressEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["GpsCreateAddressEntityRelationInput"]: {
	connect?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["AddressWithoutGpsCreateInput"] | undefined | null
};
	["AddressWithoutGpsCreateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["AddressCreateCustomerEntityRelationInput"] | undefined | null,
	country?: ValueTypes["AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["GpsUpdateInput"]: {
	latitude?: number | undefined | null,
	longitude?: number | undefined | null,
	address?: ValueTypes["GpsUpdateAddressEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["GpsUpdateAddressEntityRelationInput"]: {
	create?: ValueTypes["AddressWithoutGpsCreateInput"] | undefined | null,
	update?: ValueTypes["AddressWithoutGpsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["GpsUpsertAddressRelationInput"] | undefined | null,
	connect?: ValueTypes["AddressUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["AddressWithoutGpsUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["AddressUpdateCustomerEntityRelationInput"] | undefined | null,
	country?: ValueTypes["AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["AddressUpdateDeliveryZoneEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["GpsUpsertAddressRelationInput"]: {
	update?: ValueTypes["AddressWithoutGpsUpdateInput"] | undefined | null,
	create?: ValueTypes["AddressWithoutGpsCreateInput"] | undefined | null
};
	["WeekHoursUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["WeekHoursConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["WeekHoursEdge"],
		__typename?: boolean | `@${string}`
}>;
	["WeekHoursEdge"]: AliasType<{
	node?:ValueTypes["WeekHours"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryTimelinePresetConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryTimelinePresetEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryTimelinePresetEdge"]: AliasType<{
	node?:ValueTypes["DeliveryTimelinePreset"],
		__typename?: boolean | `@${string}`
}>;
	["InvoicingPlanConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["InvoicingPlanEdge"],
		__typename?: boolean | `@${string}`
}>;
	["InvoicingPlanEdge"]: AliasType<{
	node?:ValueTypes["InvoicingPlan"],
		__typename?: boolean | `@${string}`
}>;
	["InvoicingPresetConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["InvoicingPresetEdge"],
		__typename?: boolean | `@${string}`
}>;
	["InvoicingPresetEdge"]: AliasType<{
	node?:ValueTypes["InvoicingPreset"],
		__typename?: boolean | `@${string}`
}>;
	["BillingSubjectUpdateInput"]: {
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["BillingSubjectUpdateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["BillingSubjectUpdateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["BillingSubjectUpdateCustomerEntityRelationInput"]: {
	update?: ValueTypes["CustomerWithoutBillingSubjectsUpdateInput"] | undefined | null,
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["CustomerWithoutBillingSubjectsUpdateInput"]: {
	addresses?: Array<ValueTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	name?: ValueTypes["CustomerUpdateNameEntityRelationInput"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	anonymousSession?: ValueTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["VirtualProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VirtualProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VirtualProductEdge"]: AliasType<{
	node?:ValueTypes["VirtualProduct"],
		__typename?: boolean | `@${string}`
}>;
	["ProductMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductMetadataEdge"]: AliasType<{
	node?:ValueTypes["ProductMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["AnonymousSessionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AnonymousSessionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AnonymousSessionEdge"]: AliasType<{
	node?:ValueTypes["AnonymousSession"],
		__typename?: boolean | `@${string}`
}>;
	["AnonymousSessionCreateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	anonymousSessionKey?: string | undefined | null,
	email?: string | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	customer?: ValueTypes["AnonymousSessionCreateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AnonymousSessionCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null
};
	["AnonymousSessionUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	anonymousSessionKey?: string | undefined | null,
	email?: string | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	customer?: ValueTypes["AnonymousSessionUpdateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["AnonymousSessionUpdateCustomerEntityRelationInput"]: {
	update?: ValueTypes["CustomerWithoutAnonymousSessionUpdateInput"] | undefined | null,
	connect?: ValueTypes["CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["CustomerWithoutAnonymousSessionUpdateInput"]: {
	addresses?: Array<ValueTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	name?: ValueTypes["CustomerUpdateNameEntityRelationInput"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	billingSubjects?: Array<ValueTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["FreshingContainerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["TextListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TextListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TextListEdge"]: AliasType<{
	node?:ValueTypes["TextList"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["DeliveryRegionsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryRegionsEdge"]: AliasType<{
	node?:ValueTypes["DeliveryRegions"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListEdge"]: AliasType<{
	node?:ValueTypes["ImageList"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["RedirectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectEdge"]: AliasType<{
	node?:ValueTypes["Redirect"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["One"] | undefined | null,
	freshingContainers?: ValueTypes["FreshingContainerUniqueWhere"] | undefined | null,
	openingHours?: ValueTypes["WeekHoursUniqueWhere"] | undefined | null,
	workingHours?: ValueTypes["WeekHoursUniqueWhere"] | undefined | null,
	creditsProduct?: ValueTypes["VirtualProductUniqueWhere"] | undefined | null,
	discountVatRate?: ValueTypes["VatRateUniqueWhere"] | undefined | null
};
	["WorkspaceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["WorkspaceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["WorkspaceEdge"]: AliasType<{
	node?:ValueTypes["Workspace"],
		__typename?: boolean | `@${string}`
}>;
	["CartSummaryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CartSummaryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CartSummaryEdge"]: AliasType<{
	node?:ValueTypes["CartSummary"],
		__typename?: boolean | `@${string}`
}>;
	["OrderSummaryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderSummaryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderSummaryEdge"]: AliasType<{
	node?:ValueTypes["OrderSummary"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderPaymentMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentMetadataEdge"]: AliasType<{
	node?:ValueTypes["OrderPaymentMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["UserMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["UserMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["UserMetadataEdge"]: AliasType<{
	node?:ValueTypes["UserMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationAttemptCreateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	code?: ValueTypes["IdentificationAttemptCreateCodeEntityRelationInput"] | undefined | null,
	user?: ValueTypes["IdentificationAttemptCreateUserEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["IdentificationAttemptCreateCodeEntityRelationInput"]: {
	connect?: ValueTypes["IdentificationCodeUniqueWhere"] | undefined | null
};
	["IdentificationAttemptCreateUserEntityRelationInput"]: {
	connect?: ValueTypes["UserUniqueWhere"] | undefined | null
};
	["IdentificationCodeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IdentificationCodeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationCodeEdge"]: AliasType<{
	node?:ValueTypes["IdentificationCode"],
		__typename?: boolean | `@${string}`
}>;
	["FulfillmentNoteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["CustomerMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["CustomerMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerMetadataEdge"]: AliasType<{
	node?:ValueTypes["CustomerMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SubscriptionBoxEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SubscriptionBoxEdge"]: AliasType<{
	node?:ValueTypes["SubscriptionBox"],
		__typename?: boolean | `@${string}`
}>;
	["GridTileItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null
};
	["GridTilesUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["GridTileItemUniqueWhere"] | undefined | null
};
	["GridTilesConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GridTilesEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GridTilesEdge"]: AliasType<{
	node?:ValueTypes["GridTiles"],
		__typename?: boolean | `@${string}`
}>;
	["Position"]: AliasType<{
	_meta?:ValueTypes["PositionMeta"],
	id?:boolean | `@${string}`,
	x?:boolean | `@${string}`,
	y?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PositionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	x?:ValueTypes["FieldMeta"],
	y?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["PositionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["PositionWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	x?: ValueTypes["IntCondition"] | undefined | null,
	y?: ValueTypes["IntCondition"] | undefined | null,
	and?: Array<ValueTypes["PositionWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["PositionWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["PositionWhere"] | undefined | null
};
	["PositionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	x?: ValueTypes["OrderDirection"] | undefined | null,
	y?: ValueTypes["OrderDirection"] | undefined | null
};
	["PositionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PositionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PositionEdge"]: AliasType<{
	node?:ValueTypes["Position"],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidget"]: AliasType<{
	_meta?:ValueTypes["StatsWidgetMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	link?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	timeFrame?:boolean | `@${string}`,
layout?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null},ValueTypes["StatsWidgetsLayout"]],
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
channels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
productCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
paymentMethods?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
	hoursFrom?:boolean | `@${string}`,
	hoursTo?:boolean | `@${string}`,
paginateChannels?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
paginatePaymentMethods?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	timeFrame?:ValueTypes["FieldMeta"],
	layout?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
	channels?:ValueTypes["FieldMeta"],
	productCategory?:ValueTypes["FieldMeta"],
	paymentMethods?:ValueTypes["FieldMeta"],
	hoursFrom?:ValueTypes["FieldMeta"],
	hoursTo?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["StatsEnabledWidgetTypeType"]:StatsEnabledWidgetTypeType;
	["StatsWidgetTimeFrame"]:StatsWidgetTimeFrame;
	["StatsWidgetsLayout"]: AliasType<{
	_meta?:ValueTypes["StatsWidgetsLayoutMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsWidget"]],
paginateItems?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsWidgetConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetsLayoutMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	order?: ValueTypes["IntCondition"] | undefined | null,
	size?: ValueTypes["IntCondition"] | undefined | null,
	type?: ValueTypes["StatsEnabledWidgetTypeTypeCondition"] | undefined | null,
	link?: ValueTypes["StringCondition"] | undefined | null,
	title?: ValueTypes["StringCondition"] | undefined | null,
	timeFrame?: ValueTypes["StatsWidgetTimeFrameCondition"] | undefined | null,
	layout?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,
	icon?: ValueTypes["ImageWhere"] | undefined | null,
	channels?: ValueTypes["ChannelWhere"] | undefined | null,
	productCategory?: ValueTypes["ProductCategoryWhere"] | undefined | null,
	paymentMethods?: ValueTypes["PaymentMethodWhere"] | undefined | null,
	hoursFrom?: ValueTypes["StringCondition"] | undefined | null,
	hoursTo?: ValueTypes["StringCondition"] | undefined | null,
	and?: Array<ValueTypes["StatsWidgetWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StatsWidgetWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StatsWidgetWhere"] | undefined | null
};
	["StatsEnabledWidgetTypeTypeCondition"]: {
	and?: Array<ValueTypes["StatsEnabledWidgetTypeTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["StatsEnabledWidgetTypeTypeCondition"]> | undefined | null,
	not?: ValueTypes["StatsEnabledWidgetTypeTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null,
	notEq?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null,
	in?: Array<ValueTypes["StatsEnabledWidgetTypeType"]> | undefined | null,
	notIn?: Array<ValueTypes["StatsEnabledWidgetTypeType"]> | undefined | null,
	lt?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null,
	lte?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null,
	gt?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null,
	gte?: ValueTypes["StatsEnabledWidgetTypeType"] | undefined | null
};
	["StatsWidgetTimeFrameCondition"]: {
	and?: Array<ValueTypes["StatsWidgetTimeFrameCondition"]> | undefined | null,
	or?: Array<ValueTypes["StatsWidgetTimeFrameCondition"]> | undefined | null,
	not?: ValueTypes["StatsWidgetTimeFrameCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null,
	notEq?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null,
	in?: Array<ValueTypes["StatsWidgetTimeFrame"]> | undefined | null,
	notIn?: Array<ValueTypes["StatsWidgetTimeFrame"]> | undefined | null,
	lt?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null,
	lte?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null,
	gt?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null,
	gte?: ValueTypes["StatsWidgetTimeFrame"] | undefined | null
};
	["StatsWidgetsLayoutWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	items?: ValueTypes["StatsWidgetWhere"] | undefined | null,
	and?: Array<ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null
};
	["StatsWidgetOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	order?: ValueTypes["OrderDirection"] | undefined | null,
	size?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	link?: ValueTypes["OrderDirection"] | undefined | null,
	title?: ValueTypes["OrderDirection"] | undefined | null,
	timeFrame?: ValueTypes["OrderDirection"] | undefined | null,
	layout?: ValueTypes["StatsWidgetsLayoutOrderBy"] | undefined | null,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null,
	productCategory?: ValueTypes["ProductCategoryOrderBy"] | undefined | null,
	hoursFrom?: ValueTypes["OrderDirection"] | undefined | null,
	hoursTo?: ValueTypes["OrderDirection"] | undefined | null
};
	["StatsWidgetsLayoutOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null
};
	["StatsWidgetConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StatsWidgetEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetEdge"]: AliasType<{
	node?:ValueTypes["StatsWidget"],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["StatsWidgetsLayoutUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["StatsWidgetUniqueWhere"] | undefined | null
};
	["StatsWidgetsLayoutConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StatsWidgetsLayoutEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StatsWidgetsLayoutEdge"]: AliasType<{
	node?:ValueTypes["StatsWidgetsLayout"],
		__typename?: boolean | `@${string}`
}>;
	["StatsPage"]: AliasType<{
	_meta?:ValueTypes["StatsPageMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
widgets?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null},ValueTypes["StatsWidgetsLayout"]],
		__typename?: boolean | `@${string}`
}>;
	["StatsPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	enabled?:ValueTypes["FieldMeta"],
	widgets?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["StatsPageType"]:StatsPageType;
	["StatsPageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: ValueTypes["StatsPageType"] | undefined | null,
	widgets?: ValueTypes["StatsWidgetsLayoutUniqueWhere"] | undefined | null
};
	["StatsPageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null,
	type?: ValueTypes["StatsPageTypeCondition"] | undefined | null,
	enabled?: ValueTypes["BooleanCondition"] | undefined | null,
	widgets?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,
	and?: Array<ValueTypes["StatsPageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["StatsPageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["StatsPageWhere"] | undefined | null
};
	["StatsPageTypeCondition"]: {
	and?: Array<ValueTypes["StatsPageTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["StatsPageTypeCondition"]> | undefined | null,
	not?: ValueTypes["StatsPageTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["StatsPageType"] | undefined | null,
	notEq?: ValueTypes["StatsPageType"] | undefined | null,
	in?: Array<ValueTypes["StatsPageType"]> | undefined | null,
	notIn?: Array<ValueTypes["StatsPageType"]> | undefined | null,
	lt?: ValueTypes["StatsPageType"] | undefined | null,
	lte?: ValueTypes["StatsPageType"] | undefined | null,
	gt?: ValueTypes["StatsPageType"] | undefined | null,
	gte?: ValueTypes["StatsPageType"] | undefined | null
};
	["StatsPageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["OrderDirection"] | undefined | null,
	type?: ValueTypes["OrderDirection"] | undefined | null,
	enabled?: ValueTypes["OrderDirection"] | undefined | null,
	widgets?: ValueTypes["StatsWidgetsLayoutOrderBy"] | undefined | null
};
	["StatsPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StatsPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["StatsPageEdge"]: AliasType<{
	node?:ValueTypes["StatsPage"],
		__typename?: boolean | `@${string}`
}>;
	["QueryTransaction"]: AliasType<{
getAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["Address"]],
listAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Address"]],
paginateAddress?: [{	filter?: ValueTypes["AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressConnection"]],
validateCreateAddress?: [{	data: ValueTypes["AddressCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	data: ValueTypes["AddressUpdateInput"]},ValueTypes["_ValidationResult"]],
getCountry?: [{	by: ValueTypes["CountryUniqueWhere"],	filter?: ValueTypes["CountryWhere"] | undefined | null},ValueTypes["Country"]],
listCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Country"]],
paginateCountry?: [{	filter?: ValueTypes["CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CountryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CountryConnection"]],
getChannel?: [{	by: ValueTypes["ChannelUniqueWhere"],	filter?: ValueTypes["ChannelWhere"] | undefined | null},ValueTypes["Channel"]],
listChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Channel"]],
paginateChannel?: [{	filter?: ValueTypes["ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelConnection"]],
getChannelPayment?: [{	by: ValueTypes["ChannelPaymentUniqueWhere"],	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null},ValueTypes["ChannelPayment"]],
listChannelPayment?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelPayment"]],
paginateChannelPayment?: [{	filter?: ValueTypes["ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelPaymentConnection"]],
getChannelDelivery?: [{	by: ValueTypes["ChannelDeliveryUniqueWhere"],	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null},ValueTypes["ChannelDelivery"]],
listChannelDelivery?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelDelivery"]],
paginateChannelDelivery?: [{	filter?: ValueTypes["ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ChannelDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelDeliveryConnection"]],
getCustomer?: [{	by: ValueTypes["CustomerUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null},ValueTypes["Customer"]],
listCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Customer"]],
paginateCustomer?: [{	filter?: ValueTypes["CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerConnection"]],
validateUpdateCustomer?: [{	by: ValueTypes["CustomerUniqueWhere"],	data: ValueTypes["CustomerUpdateInput"]},ValueTypes["_ValidationResult"]],
getCustomerCredit?: [{	by: ValueTypes["CustomerCreditUniqueWhere"],	filter?: ValueTypes["CustomerCreditWhere"] | undefined | null},ValueTypes["CustomerCredit"]],
listCustomerCredit?: [{	filter?: ValueTypes["CustomerCreditWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerCreditOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerCredit"]],
paginateCustomerCredit?: [{	filter?: ValueTypes["CustomerCreditWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerCreditOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerCreditConnection"]],
getCustomerGroup?: [{	by: ValueTypes["CustomerGroupUniqueWhere"],	filter?: ValueTypes["CustomerGroupWhere"] | undefined | null},ValueTypes["CustomerGroup"]],
listCustomerGroup?: [{	filter?: ValueTypes["CustomerGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerGroupOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerGroup"]],
paginateCustomerGroup?: [{	filter?: ValueTypes["CustomerGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerGroupOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerGroupConnection"]],
getDeliveryMethod?: [{	by: ValueTypes["DeliveryMethodUniqueWhere"],	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
listDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
paginateDeliveryMethod?: [{	filter?: ValueTypes["DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
getOrder?: [{	by: ValueTypes["OrderUniqueWhere"],	filter?: ValueTypes["OrderWhere"] | undefined | null},ValueTypes["Order"]],
listOrder?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
paginateOrder?: [{	filter?: ValueTypes["OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
validateCreateOrder?: [{	data: ValueTypes["OrderCreateInput"]},ValueTypes["_ValidationResult"]],
getOrderDiscount?: [{	by: ValueTypes["OrderDiscountUniqueWhere"],	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null},ValueTypes["OrderDiscount"]],
listOrderDiscount?: [{	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDiscountOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDiscount"]],
paginateOrderDiscount?: [{	filter?: ValueTypes["OrderDiscountWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDiscountOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDiscountConnection"]],
getOrderPayment?: [{	by: ValueTypes["OrderPaymentUniqueWhere"],	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
listOrderPayment?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPayment"]],
paginateOrderPayment?: [{	filter?: ValueTypes["OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentConnection"]],
getOrderDelivery?: [{	by: ValueTypes["OrderDeliveryUniqueWhere"],	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
listOrderDelivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDelivery"]],
paginateOrderDelivery?: [{	filter?: ValueTypes["OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDeliveryConnection"]],
validateCreateOrderDelivery?: [{	data: ValueTypes["OrderDeliveryCreateInput"]},ValueTypes["_ValidationResult"]],
getOrderItem?: [{	by: ValueTypes["OrderItemUniqueWhere"],	filter?: ValueTypes["OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
listOrderItem?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
paginateOrderItem?: [{	filter?: ValueTypes["OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
validateCreateOrderItem?: [{	data: ValueTypes["OrderItemCreateInput"]},ValueTypes["_ValidationResult"]],
getPaymentMethod?: [{	by: ValueTypes["PaymentMethodUniqueWhere"],	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
listPaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
paginatePaymentMethod?: [{	filter?: ValueTypes["PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
getVatRate?: [{	by: ValueTypes["VatRateUniqueWhere"],	filter?: ValueTypes["VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
listVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VatRate"]],
paginateVatRate?: [{	filter?: ValueTypes["VatRateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VatRateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VatRateConnection"]],
getProduct?: [{	by: ValueTypes["ProductUniqueWhere"],	filter?: ValueTypes["ProductWhere"] | undefined | null},ValueTypes["Product"]],
listProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
getProductStock?: [{	by: ValueTypes["ProductStockUniqueWhere"],	filter?: ValueTypes["ProductStockWhere"] | undefined | null},ValueTypes["ProductStock"]],
listProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductStock"]],
paginateProductStock?: [{	filter?: ValueTypes["ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductStockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductStockConnection"]],
getProductPacking?: [{	by: ValueTypes["ProductPackingUniqueWhere"],	filter?: ValueTypes["ProductPackingWhere"] | undefined | null},ValueTypes["ProductPacking"]],
listProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPacking"]],
paginateProductPacking?: [{	filter?: ValueTypes["ProductPackingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingConnection"]],
getProductGroupPrice?: [{	by: ValueTypes["ProductGroupPriceUniqueWhere"],	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null},ValueTypes["ProductGroupPrice"]],
listProductGroupPrice?: [{	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGroupPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGroupPrice"]],
paginateProductGroupPrice?: [{	filter?: ValueTypes["ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGroupPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGroupPriceConnection"]],
getProductRecipe?: [{	by: ValueTypes["ProductRecipeUniqueWhere"],	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null},ValueTypes["ProductRecipe"]],
listProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipe"]],
paginateProductRecipe?: [{	filter?: ValueTypes["ProductRecipeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeConnection"]],
getProductCategory?: [{	by: ValueTypes["ProductCategoryUniqueWhere"],	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null},ValueTypes["ProductCategory"]],
listProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategory"]],
paginateProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryConnection"]],
getStore?: [{	by: ValueTypes["StoreUniqueWhere"],	filter?: ValueTypes["StoreWhere"] | undefined | null},ValueTypes["Store"]],
listStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Store"]],
paginateStore?: [{	filter?: ValueTypes["StoreWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StoreOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StoreConnection"]],
getTag?: [{	by: ValueTypes["TagUniqueWhere"],	filter?: ValueTypes["TagWhere"] | undefined | null},ValueTypes["Tag"]],
listTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Tag"]],
paginateTag?: [{	filter?: ValueTypes["TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TagConnection"]],
getCustomerPermissions?: [{	by: ValueTypes["CustomerPermissionsUniqueWhere"],	filter?: ValueTypes["CustomerPermissionsWhere"] | undefined | null},ValueTypes["CustomerPermissions"]],
listCustomerPermissions?: [{	filter?: ValueTypes["CustomerPermissionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerPermissionsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerPermissions"]],
paginateCustomerPermissions?: [{	filter?: ValueTypes["CustomerPermissionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerPermissionsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerPermissionsConnection"]],
getImage?: [{	by: ValueTypes["ImageUniqueWhere"],	filter?: ValueTypes["ImageWhere"] | undefined | null},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageConnection"]],
getBusinessCategory?: [{	by: ValueTypes["BusinessCategoryUniqueWhere"],	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null},ValueTypes["BusinessCategory"]],
listBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategory"]],
paginateBusinessCategory?: [{	filter?: ValueTypes["BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryConnection"]],
getProductList?: [{	by: ValueTypes["ProductListUniqueWhere"],	filter?: ValueTypes["ProductListWhere"] | undefined | null},ValueTypes["ProductList"]],
listProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductList"]],
paginateProductList?: [{	filter?: ValueTypes["ProductListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListConnection"]],
getProductListItem?: [{	by: ValueTypes["ProductListItemUniqueWhere"],	filter?: ValueTypes["ProductListItemWhere"] | undefined | null},ValueTypes["ProductListItem"]],
listProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductListItem"]],
paginateProductListItem?: [{	filter?: ValueTypes["ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductListItemConnection"]],
getProductCategoryList?: [{	by: ValueTypes["ProductCategoryListUniqueWhere"],	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null},ValueTypes["ProductCategoryList"]],
listProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryList"]],
paginateProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListConnection"]],
getProductCategoryListItem?: [{	by: ValueTypes["ProductCategoryListItemUniqueWhere"],	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null},ValueTypes["ProductCategoryListItem"]],
listProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListItem"]],
paginateProductCategoryListItem?: [{	filter?: ValueTypes["ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListItemConnection"]],
getBusinessCategoryLocale?: [{	by: ValueTypes["BusinessCategoryLocaleUniqueWhere"],	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["BusinessCategoryLocale"]],
listBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCategoryLocale"]],
paginateBusinessCategoryLocale?: [{	filter?: ValueTypes["BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCategoryLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"],	filter?: ValueTypes["LocaleWhere"] | undefined | null},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LocaleConnection"]],
getProductCategoryListLocale?: [{	by: ValueTypes["ProductCategoryListLocaleUniqueWhere"],	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["ProductCategoryListLocale"]],
listProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCategoryListLocale"]],
paginateProductCategoryListLocale?: [{	filter?: ValueTypes["ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCategoryListLocaleConnection"]],
getCustomerName?: [{	by: ValueTypes["CustomerNameUniqueWhere"],	filter?: ValueTypes["CustomerNameWhere"] | undefined | null},ValueTypes["CustomerName"]],
listCustomerName?: [{	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerNameOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerName"]],
paginateCustomerName?: [{	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerNameOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerNameConnection"]],
validateCreateCustomerName?: [{	data: ValueTypes["CustomerNameCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateCustomerName?: [{	by: ValueTypes["CustomerNameUniqueWhere"],	data: ValueTypes["CustomerNameUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductIngredientItem?: [{	by: ValueTypes["ProductIngredientItemUniqueWhere"],	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null},ValueTypes["ProductIngredientItem"]],
listProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientItem"]],
paginateProductIngredientItem?: [{	filter?: ValueTypes["ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientItemConnection"]],
getProductIngredient?: [{	by: ValueTypes["ProductIngredientUniqueWhere"],	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null},ValueTypes["ProductIngredient"]],
listProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredient"]],
paginateProductIngredient?: [{	filter?: ValueTypes["ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientConnection"]],
getCustomerIngredientRating?: [{	by: ValueTypes["CustomerIngredientRatingUniqueWhere"],	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null},ValueTypes["CustomerIngredientRating"]],
listCustomerIngredientRating?: [{	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerIngredientRating"]],
paginateCustomerIngredientRating?: [{	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerIngredientRatingConnection"]],
validateCreateCustomerIngredientRating?: [{	data: ValueTypes["CustomerIngredientRatingCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateCustomerIngredientRating?: [{	by: ValueTypes["CustomerIngredientRatingUniqueWhere"],	data: ValueTypes["CustomerIngredientRatingUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductIngredientRating?: [{	by: ValueTypes["ProductIngredientRatingUniqueWhere"],	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null},ValueTypes["ProductIngredientRating"]],
listProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientRating"]],
paginateProductIngredientRating?: [{	filter?: ValueTypes["ProductIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientRatingConnection"]],
getProductIngredientLocale?: [{	by: ValueTypes["ProductIngredientLocaleUniqueWhere"],	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["ProductIngredientLocale"]],
listProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductIngredientLocale"]],
paginateProductIngredientLocale?: [{	filter?: ValueTypes["ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductIngredientLocaleConnection"]],
getOrderRecurrence?: [{	by: ValueTypes["OrderRecurrenceUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null},ValueTypes["OrderRecurrence"]],
listOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrence"]],
paginateOrderRecurrence?: [{	filter?: ValueTypes["OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceConnection"]],
getOrderRecurrenceLocale?: [{	by: ValueTypes["OrderRecurrenceLocaleUniqueWhere"],	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
listOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderRecurrenceLocale"]],
paginateOrderRecurrenceLocale?: [{	filter?: ValueTypes["OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderRecurrenceLocaleConnection"]],
getBusinessCustomer?: [{	by: ValueTypes["BusinessCustomerUniqueWhere"],	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null},ValueTypes["BusinessCustomer"]],
listBusinessCustomer?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BusinessCustomer"]],
paginateBusinessCustomer?: [{	filter?: ValueTypes["BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BusinessCustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BusinessCustomerConnection"]],
getStaff?: [{	by: ValueTypes["StaffUniqueWhere"],	filter?: ValueTypes["StaffWhere"] | undefined | null},ValueTypes["Staff"]],
listStaff?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StaffOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Staff"]],
paginateStaff?: [{	filter?: ValueTypes["StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StaffOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StaffConnection"]],
getBlog?: [{	by: ValueTypes["BlogUniqueWhere"],	filter?: ValueTypes["BlogWhere"] | undefined | null},ValueTypes["Blog"]],
listBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Blog"]],
paginateBlog?: [{	filter?: ValueTypes["BlogWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogConnection"]],
getBlogLocale?: [{	by: ValueTypes["BlogLocaleUniqueWhere"],	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null},ValueTypes["BlogLocale"]],
listBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogLocale"]],
paginateBlogLocale?: [{	filter?: ValueTypes["BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogLocaleConnection"]],
getBlogPost?: [{	by: ValueTypes["BlogPostUniqueWhere"],	filter?: ValueTypes["BlogPostWhere"] | undefined | null},ValueTypes["BlogPost"]],
listBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPost"]],
paginateBlogPost?: [{	filter?: ValueTypes["BlogPostWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostConnection"]],
getBlogPostLocale?: [{	by: ValueTypes["BlogPostLocaleUniqueWhere"],	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null},ValueTypes["BlogPostLocale"]],
listBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostLocale"]],
paginateBlogPostLocale?: [{	filter?: ValueTypes["BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostLocaleConnection"]],
getColorPallete?: [{	by: ValueTypes["ColorPalleteUniqueWhere"],	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null},ValueTypes["ColorPallete"]],
listColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ColorPallete"]],
paginateColorPallete?: [{	filter?: ValueTypes["ColorPalleteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ColorPalleteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ColorPalleteConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"],	filter?: ValueTypes["ContentWhere"] | undefined | null},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"],	filter?: ValueTypes["ContentBlockWhere"] | undefined | null},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"],	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ContentReferenceConnection"]],
getBlogPostList?: [{	by: ValueTypes["BlogPostListUniqueWhere"],	filter?: ValueTypes["BlogPostListWhere"] | undefined | null},ValueTypes["BlogPostList"]],
listBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostList"]],
paginateBlogPostList?: [{	filter?: ValueTypes["BlogPostListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListConnection"]],
getBlogPostListItem?: [{	by: ValueTypes["BlogPostListItemUniqueWhere"],	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null},ValueTypes["BlogPostListItem"]],
listBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BlogPostListItem"]],
paginateBlogPostListItem?: [{	filter?: ValueTypes["BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BlogPostListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BlogPostListItemConnection"]],
getLinkList?: [{	by: ValueTypes["LinkListUniqueWhere"],	filter?: ValueTypes["LinkListWhere"] | undefined | null},ValueTypes["LinkList"]],
listLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkList"]],
paginateLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListConnection"]],
getLinkListItem?: [{	by: ValueTypes["LinkListItemUniqueWhere"],	filter?: ValueTypes["LinkListItemWhere"] | undefined | null},ValueTypes["LinkListItem"]],
listLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["LinkListItem"]],
paginateLinkListItem?: [{	filter?: ValueTypes["LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkListItemConnection"]],
getCover?: [{	by: ValueTypes["CoverUniqueWhere"],	filter?: ValueTypes["CoverWhere"] | undefined | null},ValueTypes["Cover"]],
listCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Cover"]],
paginateCover?: [{	filter?: ValueTypes["CoverWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CoverOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CoverConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"],	filter?: ValueTypes["LinkWhere"] | undefined | null},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"],	filter?: ValueTypes["LinkableWhere"] | undefined | null},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["LinkableConnection"]],
getMedium?: [{	by: ValueTypes["MediumUniqueWhere"],	filter?: ValueTypes["MediumWhere"] | undefined | null},ValueTypes["Medium"]],
listMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Medium"]],
paginateMedium?: [{	filter?: ValueTypes["MediumWhere"] | undefined | null,	orderBy?: Array<ValueTypes["MediumOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["MediumConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"],	filter?: ValueTypes["VideoWhere"] | undefined | null},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VideoConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"],	filter?: ValueTypes["SeoWhere"] | undefined | null},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SeoConnection"]],
getTranslationDomain?: [{	by: ValueTypes["TranslationDomainUniqueWhere"],	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationDomainConnection"]],
getTranslationCatalogue?: [{	by: ValueTypes["TranslationCatalogueUniqueWhere"],	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCatalogueConnection"]],
getTranslationKey?: [{	by: ValueTypes["TranslationKeyUniqueWhere"],	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ValueTypes["TranslationValueUniqueWhere"],	filter?: ValueTypes["TranslationValueWhere"] | undefined | null},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationValueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"],	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TranslationCataloguesIdentifierConnection"]],
getSocialsAndApps?: [{	by: ValueTypes["SocialsAndAppsUniqueWhere"],	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null},ValueTypes["SocialsAndApps"]],
listSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SocialsAndApps"]],
paginateSocialsAndApps?: [{	filter?: ValueTypes["SocialsAndAppsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SocialsAndAppsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SocialsAndAppsConnection"]],
getProductGrid?: [{	by: ValueTypes["ProductGridUniqueWhere"],	filter?: ValueTypes["ProductGridWhere"] | undefined | null},ValueTypes["ProductGrid"]],
listProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGrid"]],
paginateProductGrid?: [{	filter?: ValueTypes["ProductGridWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridConnection"]],
getProductGridItem?: [{	by: ValueTypes["ProductGridItemUniqueWhere"],	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null},ValueTypes["ProductGridItem"]],
listProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridItem"]],
paginateProductGridItem?: [{	filter?: ValueTypes["ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridItemConnection"]],
getGallery?: [{	by: ValueTypes["GalleryUniqueWhere"],	filter?: ValueTypes["GalleryWhere"] | undefined | null},ValueTypes["Gallery"]],
listGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Gallery"]],
paginateGallery?: [{	filter?: ValueTypes["GalleryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryConnection"]],
getGalleryItem?: [{	by: ValueTypes["GalleryItemUniqueWhere"],	filter?: ValueTypes["GalleryItemWhere"] | undefined | null},ValueTypes["GalleryItem"]],
listGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?: ValueTypes["GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GalleryItemConnection"]],
getHero?: [{	by: ValueTypes["HeroUniqueWhere"],	filter?: ValueTypes["HeroWhere"] | undefined | null},ValueTypes["Hero"]],
listHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Hero"]],
paginateHero?: [{	filter?: ValueTypes["HeroWhere"] | undefined | null,	orderBy?: Array<ValueTypes["HeroOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["HeroConnection"]],
getPage?: [{	by: ValueTypes["PageUniqueWhere"],	filter?: ValueTypes["PageWhere"] | undefined | null},ValueTypes["Page"]],
listPage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Page"]],
paginatePage?: [{	filter?: ValueTypes["PageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageConnection"]],
getPageLocale?: [{	by: ValueTypes["PageLocaleUniqueWhere"],	filter?: ValueTypes["PageLocaleWhere"] | undefined | null},ValueTypes["PageLocale"]],
listPageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PageLocale"]],
paginatePageLocale?: [{	filter?: ValueTypes["PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PageLocaleConnection"]],
getCart?: [{	by: ValueTypes["CartUniqueWhere"],	filter?: ValueTypes["CartWhere"] | undefined | null},ValueTypes["Cart"]],
listCart?: [{	filter?: ValueTypes["CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Cart"]],
paginateCart?: [{	filter?: ValueTypes["CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartConnection"]],
validateCreateCart?: [{	data: ValueTypes["CartCreateInput"]},ValueTypes["_ValidationResult"]],
getCartItem?: [{	by: ValueTypes["CartItemUniqueWhere"],	filter?: ValueTypes["CartItemWhere"] | undefined | null},ValueTypes["CartItem"]],
listCartItem?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CartItem"]],
paginateCartItem?: [{	filter?: ValueTypes["CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartItemConnection"]],
validateCreateCartItem?: [{	data: ValueTypes["CartItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateCartItem?: [{	by: ValueTypes["CartItemUniqueWhere"],	data: ValueTypes["CartItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductBanner?: [{	by: ValueTypes["ProductBannerUniqueWhere"],	filter?: ValueTypes["ProductBannerWhere"] | undefined | null},ValueTypes["ProductBanner"]],
listProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBanner"]],
paginateProductBanner?: [{	filter?: ValueTypes["ProductBannerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerConnection"]],
getProductBannerItem?: [{	by: ValueTypes["ProductBannerItemUniqueWhere"],	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null},ValueTypes["ProductBannerItem"]],
listProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerItem"]],
paginateProductBannerItem?: [{	filter?: ValueTypes["ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerItemConnection"]],
getProductBannerList?: [{	by: ValueTypes["ProductBannerListUniqueWhere"],	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null},ValueTypes["ProductBannerList"]],
listProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductBannerList"]],
paginateProductBannerList?: [{	filter?: ValueTypes["ProductBannerListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductBannerListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductBannerListConnection"]],
getUser?: [{	by: ValueTypes["UserUniqueWhere"],	filter?: ValueTypes["UserWhere"] | undefined | null},ValueTypes["User"]],
listUser?: [{	filter?: ValueTypes["UserWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UserOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["User"]],
paginateUser?: [{	filter?: ValueTypes["UserWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UserOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["UserConnection"]],
getAllergen?: [{	by: ValueTypes["AllergenUniqueWhere"],	filter?: ValueTypes["AllergenWhere"] | undefined | null},ValueTypes["Allergen"]],
listAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Allergen"]],
paginateAllergen?: [{	filter?: ValueTypes["AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenConnection"]],
getAllergenLocale?: [{	by: ValueTypes["AllergenLocaleUniqueWhere"],	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null},ValueTypes["AllergenLocale"]],
listAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AllergenLocale"]],
paginateAllergenLocale?: [{	filter?: ValueTypes["AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AllergenLocaleConnection"]],
getGps?: [{	by: ValueTypes["GpsUniqueWhere"],	filter?: ValueTypes["GpsWhere"] | undefined | null},ValueTypes["Gps"]],
listGps?: [{	filter?: ValueTypes["GpsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GpsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Gps"]],
paginateGps?: [{	filter?: ValueTypes["GpsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GpsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GpsConnection"]],
validateCreateGps?: [{	data: ValueTypes["GpsCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateGps?: [{	by: ValueTypes["GpsUniqueWhere"],	data: ValueTypes["GpsUpdateInput"]},ValueTypes["_ValidationResult"]],
getWeekHours?: [{	by: ValueTypes["WeekHoursUniqueWhere"],	filter?: ValueTypes["WeekHoursWhere"] | undefined | null},ValueTypes["WeekHours"]],
listWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["WeekHours"]],
paginateWeekHours?: [{	filter?: ValueTypes["WeekHoursWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WeekHoursOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WeekHoursConnection"]],
getDeliveryZone?: [{	by: ValueTypes["DeliveryZoneUniqueWhere"],	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null},ValueTypes["DeliveryZone"]],
listDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryZone"]],
paginateDeliveryZone?: [{	filter?: ValueTypes["DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryZoneConnection"]],
getAddressMetadata?: [{	by: ValueTypes["AddressMetadataUniqueWhere"],	filter?: ValueTypes["AddressMetadataWhere"] | undefined | null},ValueTypes["AddressMetadata"]],
listAddressMetadata?: [{	filter?: ValueTypes["AddressMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AddressMetadata"]],
paginateAddressMetadata?: [{	filter?: ValueTypes["AddressMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AddressMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressMetadataConnection"]],
getDeliveryTimelinePreset?: [{	by: ValueTypes["DeliveryTimelinePresetUniqueWhere"],	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
listDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryTimelinePreset"]],
paginateDeliveryTimelinePreset?: [{	filter?: ValueTypes["DeliveryTimelinePresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryTimelinePresetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryTimelinePresetConnection"]],
getInvoice?: [{	by: ValueTypes["InvoiceUniqueWhere"],	filter?: ValueTypes["InvoiceWhere"] | undefined | null},ValueTypes["Invoice"]],
listInvoice?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoiceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Invoice"]],
paginateInvoice?: [{	filter?: ValueTypes["InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoiceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["InvoiceConnection"]],
getInvoicingPlan?: [{	by: ValueTypes["InvoicingPlanUniqueWhere"],	filter?: ValueTypes["InvoicingPlanWhere"] | undefined | null},ValueTypes["InvoicingPlan"]],
listInvoicingPlan?: [{	filter?: ValueTypes["InvoicingPlanWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoicingPlanOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["InvoicingPlan"]],
paginateInvoicingPlan?: [{	filter?: ValueTypes["InvoicingPlanWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoicingPlanOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["InvoicingPlanConnection"]],
getProductPackingLocale?: [{	by: ValueTypes["ProductPackingLocaleUniqueWhere"],	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null},ValueTypes["ProductPackingLocale"]],
listProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductPackingLocale"]],
paginateProductPackingLocale?: [{	filter?: ValueTypes["ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductPackingLocaleConnection"]],
getProductLocale?: [{	by: ValueTypes["ProductLocaleUniqueWhere"],	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null},ValueTypes["ProductLocale"]],
listProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductLocaleConnection"]],
getProductRecipeLocale?: [{	by: ValueTypes["ProductRecipeLocaleUniqueWhere"],	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["ProductRecipeLocale"]],
listProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductRecipeLocale"]],
paginateProductRecipeLocale?: [{	filter?: ValueTypes["ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductRecipeLocaleConnection"]],
getInvoicingPreset?: [{	by: ValueTypes["InvoicingPresetUniqueWhere"],	filter?: ValueTypes["InvoicingPresetWhere"] | undefined | null},ValueTypes["InvoicingPreset"]],
listInvoicingPreset?: [{	filter?: ValueTypes["InvoicingPresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoicingPresetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["InvoicingPreset"]],
paginateInvoicingPreset?: [{	filter?: ValueTypes["InvoicingPresetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["InvoicingPresetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["InvoicingPresetConnection"]],
getBillingSubject?: [{	by: ValueTypes["BillingSubjectUniqueWhere"],	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null},ValueTypes["BillingSubject"]],
listBillingSubject?: [{	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BillingSubjectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["BillingSubject"]],
paginateBillingSubject?: [{	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["BillingSubjectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["BillingSubjectConnection"]],
validateCreateBillingSubject?: [{	data: ValueTypes["BillingSubjectCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateBillingSubject?: [{	by: ValueTypes["BillingSubjectUniqueWhere"],	data: ValueTypes["BillingSubjectUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductCustomerPrice?: [{	by: ValueTypes["ProductCustomerPriceUniqueWhere"],	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null},ValueTypes["ProductCustomerPrice"]],
listProductCustomerPrice?: [{	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCustomerPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductCustomerPrice"]],
paginateProductCustomerPrice?: [{	filter?: ValueTypes["ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductCustomerPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductCustomerPriceConnection"]],
getCartPriceLine?: [{	by: ValueTypes["CartPriceLineUniqueWhere"],	filter?: ValueTypes["CartPriceLineWhere"] | undefined | null},ValueTypes["CartPriceLine"]],
listCartPriceLine?: [{	filter?: ValueTypes["CartPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartPriceLineOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CartPriceLine"]],
paginateCartPriceLine?: [{	filter?: ValueTypes["CartPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartPriceLineOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartPriceLineConnection"]],
getVirtualProduct?: [{	by: ValueTypes["VirtualProductUniqueWhere"],	filter?: ValueTypes["VirtualProductWhere"] | undefined | null},ValueTypes["VirtualProduct"]],
listVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VirtualProduct"]],
paginateVirtualProduct?: [{	filter?: ValueTypes["VirtualProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VirtualProductConnection"]],
getVirtualProductEffect?: [{	by: ValueTypes["VirtualProductEffectUniqueWhere"],	filter?: ValueTypes["VirtualProductEffectWhere"] | undefined | null},ValueTypes["VirtualProductEffect"]],
listVirtualProductEffect?: [{	filter?: ValueTypes["VirtualProductEffectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductEffectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VirtualProductEffect"]],
paginateVirtualProductEffect?: [{	filter?: ValueTypes["VirtualProductEffectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VirtualProductEffectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VirtualProductEffectConnection"]],
getOrderPriceLine?: [{	by: ValueTypes["OrderPriceLineUniqueWhere"],	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null},ValueTypes["OrderPriceLine"]],
listOrderPriceLine?: [{	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPriceLineOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPriceLine"]],
paginateOrderPriceLine?: [{	filter?: ValueTypes["OrderPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPriceLineOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPriceLineConnection"]],
getProductMetadata?: [{	by: ValueTypes["ProductMetadataUniqueWhere"],	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null},ValueTypes["ProductMetadata"]],
listProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductMetadata"]],
paginateProductMetadata?: [{	filter?: ValueTypes["ProductMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductMetadataConnection"]],
getProductGridCategory?: [{	by: ValueTypes["ProductGridCategoryUniqueWhere"],	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null},ValueTypes["ProductGridCategory"]],
listProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ProductGridCategory"]],
paginateProductGridCategory?: [{	filter?: ValueTypes["ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ProductGridCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductGridCategoryConnection"]],
getAnonymousSession?: [{	by: ValueTypes["AnonymousSessionUniqueWhere"],	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null},ValueTypes["AnonymousSession"]],
listAnonymousSession?: [{	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AnonymousSessionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["AnonymousSession"]],
paginateAnonymousSession?: [{	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["AnonymousSessionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AnonymousSessionConnection"]],
validateCreateAnonymousSession?: [{	data: ValueTypes["AnonymousSessionCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateAnonymousSession?: [{	by: ValueTypes["AnonymousSessionUniqueWhere"],	data: ValueTypes["AnonymousSessionUpdateInput"]},ValueTypes["_ValidationResult"]],
getVoucher?: [{	by: ValueTypes["VoucherUniqueWhere"],	filter?: ValueTypes["VoucherWhere"] | undefined | null},ValueTypes["Voucher"]],
listVoucher?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Voucher"]],
paginateVoucher?: [{	filter?: ValueTypes["VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VoucherConnection"]],
getVoucherRedemption?: [{	by: ValueTypes["VoucherRedemptionUniqueWhere"],	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null},ValueTypes["VoucherRedemption"]],
listVoucherRedemption?: [{	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherRedemptionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["VoucherRedemption"]],
paginateVoucherRedemption?: [{	filter?: ValueTypes["VoucherRedemptionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["VoucherRedemptionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["VoucherRedemptionConnection"]],
getFreshingContainer?: [{	by: ValueTypes["FreshingContainerUniqueWhere"],	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null},ValueTypes["FreshingContainer"]],
listFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FreshingContainer"]],
paginateFreshingContainer?: [{	filter?: ValueTypes["FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FreshingContainerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FreshingContainerConnection"]],
getTextItem?: [{	by: ValueTypes["TextItemUniqueWhere"],	filter?: ValueTypes["TextItemWhere"] | undefined | null},ValueTypes["TextItem"]],
listTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextItem"]],
paginateTextItem?: [{	filter?: ValueTypes["TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextItemConnection"]],
getTextList?: [{	by: ValueTypes["TextListUniqueWhere"],	filter?: ValueTypes["TextListWhere"] | undefined | null},ValueTypes["TextList"]],
listTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["TextList"]],
paginateTextList?: [{	filter?: ValueTypes["TextListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["TextListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["TextListConnection"]],
getDeliveryRegions?: [{	by: ValueTypes["DeliveryRegionsUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null},ValueTypes["DeliveryRegions"]],
listDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegions"]],
paginateDeliveryRegions?: [{	filter?: ValueTypes["DeliveryRegionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsConnection"]],
getDeliveryRegionsItem?: [{	by: ValueTypes["DeliveryRegionsItemUniqueWhere"],	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["DeliveryRegionsItem"]],
listDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryRegionsItem"]],
paginateDeliveryRegionsItem?: [{	filter?: ValueTypes["DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryRegionsItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryRegionsItemConnection"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"],	filter?: ValueTypes["ImageListWhere"] | undefined | null},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"],	filter?: ValueTypes["ImageListItemWhere"] | undefined | null},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ImageListItemConnection"]],
getDeliveryMethodLocale?: [{	by: ValueTypes["DeliveryMethodLocaleUniqueWhere"],	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["DeliveryMethodLocale"]],
listDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethodLocale"]],
paginateDeliveryMethodLocale?: [{	filter?: ValueTypes["DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodLocaleConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"],	filter?: ValueTypes["RedirectWhere"] | undefined | null},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["RedirectConnection"]],
getWorkspace?: [{	by: ValueTypes["WorkspaceUniqueWhere"],	filter?: ValueTypes["WorkspaceWhere"] | undefined | null},ValueTypes["Workspace"]],
listWorkspace?: [{	filter?: ValueTypes["WorkspaceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WorkspaceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Workspace"]],
paginateWorkspace?: [{	filter?: ValueTypes["WorkspaceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["WorkspaceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["WorkspaceConnection"]],
getCartSummary?: [{	by: ValueTypes["CartSummaryUniqueWhere"],	filter?: ValueTypes["CartSummaryWhere"] | undefined | null},ValueTypes["CartSummary"]],
listCartSummary?: [{	filter?: ValueTypes["CartSummaryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartSummaryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CartSummary"]],
paginateCartSummary?: [{	filter?: ValueTypes["CartSummaryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CartSummaryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CartSummaryConnection"]],
getOrderSummary?: [{	by: ValueTypes["OrderSummaryUniqueWhere"],	filter?: ValueTypes["OrderSummaryWhere"] | undefined | null},ValueTypes["OrderSummary"]],
listOrderSummary?: [{	filter?: ValueTypes["OrderSummaryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderSummaryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderSummary"]],
paginateOrderSummary?: [{	filter?: ValueTypes["OrderSummaryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderSummaryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderSummaryConnection"]],
getOrderPaymentMetadata?: [{	by: ValueTypes["OrderPaymentMetadataUniqueWhere"],	filter?: ValueTypes["OrderPaymentMetadataWhere"] | undefined | null},ValueTypes["OrderPaymentMetadata"]],
listOrderPaymentMetadata?: [{	filter?: ValueTypes["OrderPaymentMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPaymentMetadata"]],
paginateOrderPaymentMetadata?: [{	filter?: ValueTypes["OrderPaymentMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["OrderPaymentMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentMetadataConnection"]],
getUserMetadata?: [{	by: ValueTypes["UserMetadataUniqueWhere"],	filter?: ValueTypes["UserMetadataWhere"] | undefined | null},ValueTypes["UserMetadata"]],
listUserMetadata?: [{	filter?: ValueTypes["UserMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UserMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["UserMetadata"]],
paginateUserMetadata?: [{	filter?: ValueTypes["UserMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["UserMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["UserMetadataConnection"]],
validateCreateIdentificationAttempt?: [{	data: ValueTypes["IdentificationAttemptCreateInput"]},ValueTypes["_ValidationResult"]],
getIdentificationCode?: [{	by: ValueTypes["IdentificationCodeUniqueWhere"],	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null},ValueTypes["IdentificationCode"]],
listIdentificationCode?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCode"]],
paginateIdentificationCode?: [{	filter?: ValueTypes["IdentificationCodeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeConnection"]],
getIdentificationCodeLocale?: [{	by: ValueTypes["IdentificationCodeLocaleUniqueWhere"],	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null},ValueTypes["IdentificationCodeLocale"]],
listIdentificationCodeLocale?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["IdentificationCodeLocale"]],
paginateIdentificationCodeLocale?: [{	filter?: ValueTypes["IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["IdentificationCodeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["IdentificationCodeLocaleConnection"]],
getFulfillmentNote?: [{	by: ValueTypes["FulfillmentNoteUniqueWhere"],	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null},ValueTypes["FulfillmentNote"]],
listFulfillmentNote?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["FulfillmentNote"]],
paginateFulfillmentNote?: [{	filter?: ValueTypes["FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["FulfillmentNoteConnection"]],
getCustomerMetadata?: [{	by: ValueTypes["CustomerMetadataUniqueWhere"],	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null},ValueTypes["CustomerMetadata"]],
listCustomerMetadata?: [{	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerMetadata"]],
paginateCustomerMetadata?: [{	filter?: ValueTypes["CustomerMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["CustomerMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerMetadataConnection"]],
getSubscriptionBox?: [{	by: ValueTypes["SubscriptionBoxUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null},ValueTypes["SubscriptionBox"]],
listSubscriptionBox?: [{	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBox"]],
paginateSubscriptionBox?: [{	filter?: ValueTypes["SubscriptionBoxWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxConnection"]],
getSubscriptionBoxLocale?: [{	by: ValueTypes["SubscriptionBoxLocaleUniqueWhere"],	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
listSubscriptionBoxLocale?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["SubscriptionBoxLocale"]],
paginateSubscriptionBoxLocale?: [{	filter?: ValueTypes["SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["SubscriptionBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["SubscriptionBoxLocaleConnection"]],
getGridTileItem?: [{	by: ValueTypes["GridTileItemUniqueWhere"],	filter?: ValueTypes["GridTileItemWhere"] | undefined | null},ValueTypes["GridTileItem"]],
listGridTileItem?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GridTileItem"]],
paginateGridTileItem?: [{	filter?: ValueTypes["GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTileItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GridTileItemConnection"]],
getGridTiles?: [{	by: ValueTypes["GridTilesUniqueWhere"],	filter?: ValueTypes["GridTilesWhere"] | undefined | null},ValueTypes["GridTiles"]],
listGridTiles?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTilesOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["GridTiles"]],
paginateGridTiles?: [{	filter?: ValueTypes["GridTilesWhere"] | undefined | null,	orderBy?: Array<ValueTypes["GridTilesOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["GridTilesConnection"]],
getPosition?: [{	by: ValueTypes["PositionUniqueWhere"],	filter?: ValueTypes["PositionWhere"] | undefined | null},ValueTypes["Position"]],
listPosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Position"]],
paginatePosition?: [{	filter?: ValueTypes["PositionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["PositionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PositionConnection"]],
getStatsWidget?: [{	by: ValueTypes["StatsWidgetUniqueWhere"],	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null},ValueTypes["StatsWidget"]],
listStatsWidget?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsWidget"]],
paginateStatsWidget?: [{	filter?: ValueTypes["StatsWidgetWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsWidgetConnection"]],
getStatsWidgetsLayout?: [{	by: ValueTypes["StatsWidgetsLayoutUniqueWhere"],	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null},ValueTypes["StatsWidgetsLayout"]],
listStatsWidgetsLayout?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetsLayoutOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsWidgetsLayout"]],
paginateStatsWidgetsLayout?: [{	filter?: ValueTypes["StatsWidgetsLayoutWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsWidgetsLayoutOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsWidgetsLayoutConnection"]],
getStatsPage?: [{	by: ValueTypes["StatsPageUniqueWhere"],	filter?: ValueTypes["StatsPageWhere"] | undefined | null},ValueTypes["StatsPage"]],
listStatsPage?: [{	filter?: ValueTypes["StatsPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["StatsPage"]],
paginateStatsPage?: [{	filter?: ValueTypes["StatsPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["StatsPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["StatsPageConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Info"]: AliasType<{
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createAddress?: [{	data: ValueTypes["AddressCreateInput"]},ValueTypes["AddressCreateResult"]],
deleteAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["AddressDeleteResult"]],
updateAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null,	data: ValueTypes["AddressUpdateInput"]},ValueTypes["AddressUpdateResult"]],
upsertAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null,	update: ValueTypes["AddressUpdateInput"],	create: ValueTypes["AddressCreateInput"]},ValueTypes["AddressUpsertResult"]],
updateCustomer?: [{	by: ValueTypes["CustomerUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null,	data: ValueTypes["CustomerUpdateInput"]},ValueTypes["CustomerUpdateResult"]],
createOrder?: [{	data: ValueTypes["OrderCreateInput"]},ValueTypes["OrderCreateResult"]],
createOrderDelivery?: [{	data: ValueTypes["OrderDeliveryCreateInput"]},ValueTypes["OrderDeliveryCreateResult"]],
createOrderItem?: [{	data: ValueTypes["OrderItemCreateInput"]},ValueTypes["OrderItemCreateResult"]],
createCustomerName?: [{	data: ValueTypes["CustomerNameCreateInput"]},ValueTypes["CustomerNameCreateResult"]],
updateCustomerName?: [{	by: ValueTypes["CustomerNameUniqueWhere"],	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	data: ValueTypes["CustomerNameUpdateInput"]},ValueTypes["CustomerNameUpdateResult"]],
upsertCustomerName?: [{	by: ValueTypes["CustomerNameUniqueWhere"],	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	update: ValueTypes["CustomerNameUpdateInput"],	create: ValueTypes["CustomerNameCreateInput"]},ValueTypes["CustomerNameUpsertResult"]],
createCustomerIngredientRating?: [{	data: ValueTypes["CustomerIngredientRatingCreateInput"]},ValueTypes["CustomerIngredientRatingCreateResult"]],
deleteCustomerIngredientRating?: [{	by: ValueTypes["CustomerIngredientRatingUniqueWhere"],	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null},ValueTypes["CustomerIngredientRatingDeleteResult"]],
updateCustomerIngredientRating?: [{	by: ValueTypes["CustomerIngredientRatingUniqueWhere"],	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	data: ValueTypes["CustomerIngredientRatingUpdateInput"]},ValueTypes["CustomerIngredientRatingUpdateResult"]],
upsertCustomerIngredientRating?: [{	by: ValueTypes["CustomerIngredientRatingUniqueWhere"],	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	update: ValueTypes["CustomerIngredientRatingUpdateInput"],	create: ValueTypes["CustomerIngredientRatingCreateInput"]},ValueTypes["CustomerIngredientRatingUpsertResult"]],
createCart?: [{	data: ValueTypes["CartCreateInput"]},ValueTypes["CartCreateResult"]],
createCartItem?: [{	data: ValueTypes["CartItemCreateInput"]},ValueTypes["CartItemCreateResult"]],
deleteCartItem?: [{	by: ValueTypes["CartItemUniqueWhere"],	filter?: ValueTypes["CartItemWhere"] | undefined | null},ValueTypes["CartItemDeleteResult"]],
updateCartItem?: [{	by: ValueTypes["CartItemUniqueWhere"],	filter?: ValueTypes["CartItemWhere"] | undefined | null,	data: ValueTypes["CartItemUpdateInput"]},ValueTypes["CartItemUpdateResult"]],
upsertCartItem?: [{	by: ValueTypes["CartItemUniqueWhere"],	filter?: ValueTypes["CartItemWhere"] | undefined | null,	update: ValueTypes["CartItemUpdateInput"],	create: ValueTypes["CartItemCreateInput"]},ValueTypes["CartItemUpsertResult"]],
createGps?: [{	data: ValueTypes["GpsCreateInput"]},ValueTypes["GpsCreateResult"]],
updateGps?: [{	by: ValueTypes["GpsUniqueWhere"],	filter?: ValueTypes["GpsWhere"] | undefined | null,	data: ValueTypes["GpsUpdateInput"]},ValueTypes["GpsUpdateResult"]],
upsertGps?: [{	by: ValueTypes["GpsUniqueWhere"],	filter?: ValueTypes["GpsWhere"] | undefined | null,	update: ValueTypes["GpsUpdateInput"],	create: ValueTypes["GpsCreateInput"]},ValueTypes["GpsUpsertResult"]],
createBillingSubject?: [{	data: ValueTypes["BillingSubjectCreateInput"]},ValueTypes["BillingSubjectCreateResult"]],
updateBillingSubject?: [{	by: ValueTypes["BillingSubjectUniqueWhere"],	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null,	data: ValueTypes["BillingSubjectUpdateInput"]},ValueTypes["BillingSubjectUpdateResult"]],
upsertBillingSubject?: [{	by: ValueTypes["BillingSubjectUniqueWhere"],	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null,	update: ValueTypes["BillingSubjectUpdateInput"],	create: ValueTypes["BillingSubjectCreateInput"]},ValueTypes["BillingSubjectUpsertResult"]],
createAnonymousSession?: [{	data: ValueTypes["AnonymousSessionCreateInput"]},ValueTypes["AnonymousSessionCreateResult"]],
deleteAnonymousSession?: [{	by: ValueTypes["AnonymousSessionUniqueWhere"],	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null},ValueTypes["AnonymousSessionDeleteResult"]],
updateAnonymousSession?: [{	by: ValueTypes["AnonymousSessionUniqueWhere"],	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null,	data: ValueTypes["AnonymousSessionUpdateInput"]},ValueTypes["AnonymousSessionUpdateResult"]],
upsertAnonymousSession?: [{	by: ValueTypes["AnonymousSessionUniqueWhere"],	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null,	update: ValueTypes["AnonymousSessionUpdateInput"],	create: ValueTypes["AnonymousSessionCreateInput"]},ValueTypes["AnonymousSessionUpsertResult"]],
createIdentificationAttempt?: [{	data: ValueTypes["IdentificationAttemptCreateInput"]},ValueTypes["IdentificationAttemptCreateResult"]],
transaction?: [{	options?: ValueTypes["MutationTransactionOptions"] | undefined | null},ValueTypes["MutationTransaction"]],
	query?:ValueTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["AddressCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Address"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationResult"]:AliasType<{
		ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"];
		['...on AddressCreateResult']?: Omit<ValueTypes["AddressCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on AddressDeleteResult']?: Omit<ValueTypes["AddressDeleteResult"],keyof ValueTypes["MutationResult"]>;
		['...on AddressUpdateResult']?: Omit<ValueTypes["AddressUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on AddressUpsertResult']?: Omit<ValueTypes["AddressUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on CustomerUpdateResult']?: Omit<ValueTypes["CustomerUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on OrderCreateResult']?: Omit<ValueTypes["OrderCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on OrderDeliveryCreateResult']?: Omit<ValueTypes["OrderDeliveryCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on OrderItemCreateResult']?: Omit<ValueTypes["OrderItemCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on CustomerNameCreateResult']?: Omit<ValueTypes["CustomerNameCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on CustomerNameUpdateResult']?: Omit<ValueTypes["CustomerNameUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on CustomerNameUpsertResult']?: Omit<ValueTypes["CustomerNameUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on CustomerIngredientRatingCreateResult']?: Omit<ValueTypes["CustomerIngredientRatingCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on CustomerIngredientRatingDeleteResult']?: Omit<ValueTypes["CustomerIngredientRatingDeleteResult"],keyof ValueTypes["MutationResult"]>;
		['...on CustomerIngredientRatingUpdateResult']?: Omit<ValueTypes["CustomerIngredientRatingUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on CustomerIngredientRatingUpsertResult']?: Omit<ValueTypes["CustomerIngredientRatingUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on CartCreateResult']?: Omit<ValueTypes["CartCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on CartItemCreateResult']?: Omit<ValueTypes["CartItemCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on CartItemDeleteResult']?: Omit<ValueTypes["CartItemDeleteResult"],keyof ValueTypes["MutationResult"]>;
		['...on CartItemUpdateResult']?: Omit<ValueTypes["CartItemUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on CartItemUpsertResult']?: Omit<ValueTypes["CartItemUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on GpsCreateResult']?: Omit<ValueTypes["GpsCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on GpsUpdateResult']?: Omit<ValueTypes["GpsUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on GpsUpsertResult']?: Omit<ValueTypes["GpsUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on BillingSubjectCreateResult']?: Omit<ValueTypes["BillingSubjectCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on BillingSubjectUpdateResult']?: Omit<ValueTypes["BillingSubjectUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on BillingSubjectUpsertResult']?: Omit<ValueTypes["BillingSubjectUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on AnonymousSessionCreateResult']?: Omit<ValueTypes["AnonymousSessionCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on AnonymousSessionDeleteResult']?: Omit<ValueTypes["AnonymousSessionDeleteResult"],keyof ValueTypes["MutationResult"]>;
		['...on AnonymousSessionUpdateResult']?: Omit<ValueTypes["AnonymousSessionUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on AnonymousSessionUpsertResult']?: Omit<ValueTypes["AnonymousSessionUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on IdentificationAttemptCreateResult']?: Omit<ValueTypes["IdentificationAttemptCreateResult"],keyof ValueTypes["MutationResult"]>;
		__typename?: boolean | `@${string}`
}>;
	["_MutationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	paths?:ValueTypes["_PathFragment"],
	type?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_MutationErrorType"]:_MutationErrorType;
	["AddressDeleteResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Address"],
		__typename?: boolean | `@${string}`
}>;
	["AddressUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Address"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["AddressUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Address"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Customer"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["OrderCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Order"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDeliveryCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["OrderDelivery"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["OrderItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerNameCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CustomerName"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerNameUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CustomerName"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerNameUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CustomerName"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerIngredientRatingCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CustomerIngredientRating"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerIngredientRatingDeleteResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CustomerIngredientRating"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerIngredientRatingUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CustomerIngredientRating"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerIngredientRatingUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CustomerIngredientRating"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CartCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Cart"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CartItemCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CartItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CartItemDeleteResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CartItem"],
		__typename?: boolean | `@${string}`
}>;
	["CartItemUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CartItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["CartItemUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["CartItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["GpsCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Gps"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["GpsUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Gps"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["GpsUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Gps"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["BillingSubjectCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["BillingSubject"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["BillingSubjectUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["BillingSubject"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["BillingSubjectUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["BillingSubject"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["AnonymousSessionCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AnonymousSession"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["AnonymousSessionDeleteResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AnonymousSession"],
		__typename?: boolean | `@${string}`
}>;
	["AnonymousSessionUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AnonymousSession"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["AnonymousSessionUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AnonymousSession"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["IdentificationAttemptCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransaction"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
createAddress?: [{	data: ValueTypes["AddressCreateInput"]},ValueTypes["AddressCreateResult"]],
deleteAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null},ValueTypes["AddressDeleteResult"]],
updateAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null,	data: ValueTypes["AddressUpdateInput"]},ValueTypes["AddressUpdateResult"]],
upsertAddress?: [{	by: ValueTypes["AddressUniqueWhere"],	filter?: ValueTypes["AddressWhere"] | undefined | null,	update: ValueTypes["AddressUpdateInput"],	create: ValueTypes["AddressCreateInput"]},ValueTypes["AddressUpsertResult"]],
updateCustomer?: [{	by: ValueTypes["CustomerUniqueWhere"],	filter?: ValueTypes["CustomerWhere"] | undefined | null,	data: ValueTypes["CustomerUpdateInput"]},ValueTypes["CustomerUpdateResult"]],
createOrder?: [{	data: ValueTypes["OrderCreateInput"]},ValueTypes["OrderCreateResult"]],
createOrderDelivery?: [{	data: ValueTypes["OrderDeliveryCreateInput"]},ValueTypes["OrderDeliveryCreateResult"]],
createOrderItem?: [{	data: ValueTypes["OrderItemCreateInput"]},ValueTypes["OrderItemCreateResult"]],
createCustomerName?: [{	data: ValueTypes["CustomerNameCreateInput"]},ValueTypes["CustomerNameCreateResult"]],
updateCustomerName?: [{	by: ValueTypes["CustomerNameUniqueWhere"],	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	data: ValueTypes["CustomerNameUpdateInput"]},ValueTypes["CustomerNameUpdateResult"]],
upsertCustomerName?: [{	by: ValueTypes["CustomerNameUniqueWhere"],	filter?: ValueTypes["CustomerNameWhere"] | undefined | null,	update: ValueTypes["CustomerNameUpdateInput"],	create: ValueTypes["CustomerNameCreateInput"]},ValueTypes["CustomerNameUpsertResult"]],
createCustomerIngredientRating?: [{	data: ValueTypes["CustomerIngredientRatingCreateInput"]},ValueTypes["CustomerIngredientRatingCreateResult"]],
deleteCustomerIngredientRating?: [{	by: ValueTypes["CustomerIngredientRatingUniqueWhere"],	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null},ValueTypes["CustomerIngredientRatingDeleteResult"]],
updateCustomerIngredientRating?: [{	by: ValueTypes["CustomerIngredientRatingUniqueWhere"],	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	data: ValueTypes["CustomerIngredientRatingUpdateInput"]},ValueTypes["CustomerIngredientRatingUpdateResult"]],
upsertCustomerIngredientRating?: [{	by: ValueTypes["CustomerIngredientRatingUniqueWhere"],	filter?: ValueTypes["CustomerIngredientRatingWhere"] | undefined | null,	update: ValueTypes["CustomerIngredientRatingUpdateInput"],	create: ValueTypes["CustomerIngredientRatingCreateInput"]},ValueTypes["CustomerIngredientRatingUpsertResult"]],
createCart?: [{	data: ValueTypes["CartCreateInput"]},ValueTypes["CartCreateResult"]],
createCartItem?: [{	data: ValueTypes["CartItemCreateInput"]},ValueTypes["CartItemCreateResult"]],
deleteCartItem?: [{	by: ValueTypes["CartItemUniqueWhere"],	filter?: ValueTypes["CartItemWhere"] | undefined | null},ValueTypes["CartItemDeleteResult"]],
updateCartItem?: [{	by: ValueTypes["CartItemUniqueWhere"],	filter?: ValueTypes["CartItemWhere"] | undefined | null,	data: ValueTypes["CartItemUpdateInput"]},ValueTypes["CartItemUpdateResult"]],
upsertCartItem?: [{	by: ValueTypes["CartItemUniqueWhere"],	filter?: ValueTypes["CartItemWhere"] | undefined | null,	update: ValueTypes["CartItemUpdateInput"],	create: ValueTypes["CartItemCreateInput"]},ValueTypes["CartItemUpsertResult"]],
createGps?: [{	data: ValueTypes["GpsCreateInput"]},ValueTypes["GpsCreateResult"]],
updateGps?: [{	by: ValueTypes["GpsUniqueWhere"],	filter?: ValueTypes["GpsWhere"] | undefined | null,	data: ValueTypes["GpsUpdateInput"]},ValueTypes["GpsUpdateResult"]],
upsertGps?: [{	by: ValueTypes["GpsUniqueWhere"],	filter?: ValueTypes["GpsWhere"] | undefined | null,	update: ValueTypes["GpsUpdateInput"],	create: ValueTypes["GpsCreateInput"]},ValueTypes["GpsUpsertResult"]],
createBillingSubject?: [{	data: ValueTypes["BillingSubjectCreateInput"]},ValueTypes["BillingSubjectCreateResult"]],
updateBillingSubject?: [{	by: ValueTypes["BillingSubjectUniqueWhere"],	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null,	data: ValueTypes["BillingSubjectUpdateInput"]},ValueTypes["BillingSubjectUpdateResult"]],
upsertBillingSubject?: [{	by: ValueTypes["BillingSubjectUniqueWhere"],	filter?: ValueTypes["BillingSubjectWhere"] | undefined | null,	update: ValueTypes["BillingSubjectUpdateInput"],	create: ValueTypes["BillingSubjectCreateInput"]},ValueTypes["BillingSubjectUpsertResult"]],
createAnonymousSession?: [{	data: ValueTypes["AnonymousSessionCreateInput"]},ValueTypes["AnonymousSessionCreateResult"]],
deleteAnonymousSession?: [{	by: ValueTypes["AnonymousSessionUniqueWhere"],	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null},ValueTypes["AnonymousSessionDeleteResult"]],
updateAnonymousSession?: [{	by: ValueTypes["AnonymousSessionUniqueWhere"],	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null,	data: ValueTypes["AnonymousSessionUpdateInput"]},ValueTypes["AnonymousSessionUpdateResult"]],
upsertAnonymousSession?: [{	by: ValueTypes["AnonymousSessionUniqueWhere"],	filter?: ValueTypes["AnonymousSessionWhere"] | undefined | null,	update: ValueTypes["AnonymousSessionUpdateInput"],	create: ValueTypes["AnonymousSessionCreateInput"]},ValueTypes["AnonymousSessionUpsertResult"]],
createIdentificationAttempt?: [{	data: ValueTypes["IdentificationAttemptCreateInput"]},ValueTypes["IdentificationAttemptCreateResult"]],
	query?:ValueTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined | null
};
	["_Schema"]: AliasType<{
	enums?:ValueTypes["_Enum"],
	entities?:ValueTypes["_Entity"],
		__typename?: boolean | `@${string}`
}>;
	["_Entity"]: AliasType<{
	name?:boolean | `@${string}`,
	customPrimaryAllowed?:boolean | `@${string}`,
	fields?:ValueTypes["_Field"],
	unique?:ValueTypes["_UniqueConstraint"],
		__typename?: boolean | `@${string}`
}>;
	["_UniqueConstraint"]: AliasType<{
	fields?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Field"]:AliasType<{
		name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"];
		['...on _Column']?: Omit<ValueTypes["_Column"],keyof ValueTypes["_Field"]>;
		['...on _Relation']?: Omit<ValueTypes["_Relation"],keyof ValueTypes["_Field"]>;
		__typename?: boolean | `@${string}`
}>;
	["_Column"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enumName?:boolean | `@${string}`,
	defaultValue?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_RelationSide"]:_RelationSide;
	["_OrderByDirection"]:_OrderByDirection;
	["_OrderBy"]: AliasType<{
	path?:boolean | `@${string}`,
	direction?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Relation"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	side?:boolean | `@${string}`,
	targetEntity?:boolean | `@${string}`,
	ownedBy?:boolean | `@${string}`,
	inversedBy?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	onDelete?:boolean | `@${string}`,
	orphanRemoval?:boolean | `@${string}`,
	orderBy?:ValueTypes["_OrderBy"],
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_Rule"]: AliasType<{
	message?:ValueTypes["_RuleMessage"],
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Validator"]: AliasType<{
	operation?:boolean | `@${string}`,
	arguments?:ValueTypes["_Argument"],
		__typename?: boolean | `@${string}`
}>;
	["_Argument"]: AliasType<{		["...on _ValidatorArgument"] : ValueTypes["_ValidatorArgument"],
		["...on _PathArgument"] : ValueTypes["_PathArgument"],
		["...on _LiteralArgument"] : ValueTypes["_LiteralArgument"]
		__typename?: boolean | `@${string}`
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_PathArgument"]: AliasType<{
	path?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_LiteralArgument"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_RuleMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Enum"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Query"]: {
		getAddress?: GraphQLTypes["Address"] | undefined,
	listAddress: Array<GraphQLTypes["Address"]>,
	paginateAddress: GraphQLTypes["AddressConnection"],
	validateCreateAddress: GraphQLTypes["_ValidationResult"],
	validateUpdateAddress: GraphQLTypes["_ValidationResult"],
	getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getChannelPayment?: GraphQLTypes["ChannelPayment"] | undefined,
	listChannelPayment: Array<GraphQLTypes["ChannelPayment"]>,
	paginateChannelPayment: GraphQLTypes["ChannelPaymentConnection"],
	getChannelDelivery?: GraphQLTypes["ChannelDelivery"] | undefined,
	listChannelDelivery: Array<GraphQLTypes["ChannelDelivery"]>,
	paginateChannelDelivery: GraphQLTypes["ChannelDeliveryConnection"],
	getCustomer?: GraphQLTypes["Customer"] | undefined,
	listCustomer: Array<GraphQLTypes["Customer"]>,
	paginateCustomer: GraphQLTypes["CustomerConnection"],
	validateUpdateCustomer: GraphQLTypes["_ValidationResult"],
	getCustomerCredit?: GraphQLTypes["CustomerCredit"] | undefined,
	listCustomerCredit: Array<GraphQLTypes["CustomerCredit"]>,
	paginateCustomerCredit: GraphQLTypes["CustomerCreditConnection"],
	getCustomerGroup?: GraphQLTypes["CustomerGroup"] | undefined,
	listCustomerGroup: Array<GraphQLTypes["CustomerGroup"]>,
	paginateCustomerGroup: GraphQLTypes["CustomerGroupConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getOrder?: GraphQLTypes["Order"] | undefined,
	listOrder: Array<GraphQLTypes["Order"]>,
	paginateOrder: GraphQLTypes["OrderConnection"],
	validateCreateOrder: GraphQLTypes["_ValidationResult"],
	getOrderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	listOrderDiscount: Array<GraphQLTypes["OrderDiscount"]>,
	paginateOrderDiscount: GraphQLTypes["OrderDiscountConnection"],
	getOrderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	listOrderPayment: Array<GraphQLTypes["OrderPayment"]>,
	paginateOrderPayment: GraphQLTypes["OrderPaymentConnection"],
	getOrderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	listOrderDelivery: Array<GraphQLTypes["OrderDelivery"]>,
	paginateOrderDelivery: GraphQLTypes["OrderDeliveryConnection"],
	validateCreateOrderDelivery: GraphQLTypes["_ValidationResult"],
	getOrderItem?: GraphQLTypes["OrderItem"] | undefined,
	listOrderItem: Array<GraphQLTypes["OrderItem"]>,
	paginateOrderItem: GraphQLTypes["OrderItemConnection"],
	validateCreateOrderItem: GraphQLTypes["_ValidationResult"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductGroupPrice?: GraphQLTypes["ProductGroupPrice"] | undefined,
	listProductGroupPrice: Array<GraphQLTypes["ProductGroupPrice"]>,
	paginateProductGroupPrice: GraphQLTypes["ProductGroupPriceConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getCustomerPermissions?: GraphQLTypes["CustomerPermissions"] | undefined,
	listCustomerPermissions: Array<GraphQLTypes["CustomerPermissions"]>,
	paginateCustomerPermissions: GraphQLTypes["CustomerPermissionsConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getCustomerName?: GraphQLTypes["CustomerName"] | undefined,
	listCustomerName: Array<GraphQLTypes["CustomerName"]>,
	paginateCustomerName: GraphQLTypes["CustomerNameConnection"],
	validateCreateCustomerName: GraphQLTypes["_ValidationResult"],
	validateUpdateCustomerName: GraphQLTypes["_ValidationResult"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getCustomerIngredientRating?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	listCustomerIngredientRating: Array<GraphQLTypes["CustomerIngredientRating"]>,
	paginateCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingConnection"],
	validateCreateCustomerIngredientRating: GraphQLTypes["_ValidationResult"],
	validateUpdateCustomerIngredientRating: GraphQLTypes["_ValidationResult"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getBusinessCustomer?: GraphQLTypes["BusinessCustomer"] | undefined,
	listBusinessCustomer: Array<GraphQLTypes["BusinessCustomer"]>,
	paginateBusinessCustomer: GraphQLTypes["BusinessCustomerConnection"],
	getStaff?: GraphQLTypes["Staff"] | undefined,
	listStaff: Array<GraphQLTypes["Staff"]>,
	paginateStaff: GraphQLTypes["StaffConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getCart?: GraphQLTypes["Cart"] | undefined,
	listCart: Array<GraphQLTypes["Cart"]>,
	paginateCart: GraphQLTypes["CartConnection"],
	validateCreateCart: GraphQLTypes["_ValidationResult"],
	getCartItem?: GraphQLTypes["CartItem"] | undefined,
	listCartItem: Array<GraphQLTypes["CartItem"]>,
	paginateCartItem: GraphQLTypes["CartItemConnection"],
	validateCreateCartItem: GraphQLTypes["_ValidationResult"],
	validateUpdateCartItem: GraphQLTypes["_ValidationResult"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getUser?: GraphQLTypes["User"] | undefined,
	listUser: Array<GraphQLTypes["User"]>,
	paginateUser: GraphQLTypes["UserConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getGps?: GraphQLTypes["Gps"] | undefined,
	listGps: Array<GraphQLTypes["Gps"]>,
	paginateGps: GraphQLTypes["GpsConnection"],
	validateCreateGps: GraphQLTypes["_ValidationResult"],
	validateUpdateGps: GraphQLTypes["_ValidationResult"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getAddressMetadata?: GraphQLTypes["AddressMetadata"] | undefined,
	listAddressMetadata: Array<GraphQLTypes["AddressMetadata"]>,
	paginateAddressMetadata: GraphQLTypes["AddressMetadataConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getInvoice?: GraphQLTypes["Invoice"] | undefined,
	listInvoice: Array<GraphQLTypes["Invoice"]>,
	paginateInvoice: GraphQLTypes["InvoiceConnection"],
	getInvoicingPlan?: GraphQLTypes["InvoicingPlan"] | undefined,
	listInvoicingPlan: Array<GraphQLTypes["InvoicingPlan"]>,
	paginateInvoicingPlan: GraphQLTypes["InvoicingPlanConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getInvoicingPreset?: GraphQLTypes["InvoicingPreset"] | undefined,
	listInvoicingPreset: Array<GraphQLTypes["InvoicingPreset"]>,
	paginateInvoicingPreset: GraphQLTypes["InvoicingPresetConnection"],
	getBillingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	listBillingSubject: Array<GraphQLTypes["BillingSubject"]>,
	paginateBillingSubject: GraphQLTypes["BillingSubjectConnection"],
	validateCreateBillingSubject: GraphQLTypes["_ValidationResult"],
	validateUpdateBillingSubject: GraphQLTypes["_ValidationResult"],
	getProductCustomerPrice?: GraphQLTypes["ProductCustomerPrice"] | undefined,
	listProductCustomerPrice: Array<GraphQLTypes["ProductCustomerPrice"]>,
	paginateProductCustomerPrice: GraphQLTypes["ProductCustomerPriceConnection"],
	getCartPriceLine?: GraphQLTypes["CartPriceLine"] | undefined,
	listCartPriceLine: Array<GraphQLTypes["CartPriceLine"]>,
	paginateCartPriceLine: GraphQLTypes["CartPriceLineConnection"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getVirtualProductEffect?: GraphQLTypes["VirtualProductEffect"] | undefined,
	listVirtualProductEffect: Array<GraphQLTypes["VirtualProductEffect"]>,
	paginateVirtualProductEffect: GraphQLTypes["VirtualProductEffectConnection"],
	getOrderPriceLine?: GraphQLTypes["OrderPriceLine"] | undefined,
	listOrderPriceLine: Array<GraphQLTypes["OrderPriceLine"]>,
	paginateOrderPriceLine: GraphQLTypes["OrderPriceLineConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getAnonymousSession?: GraphQLTypes["AnonymousSession"] | undefined,
	listAnonymousSession: Array<GraphQLTypes["AnonymousSession"]>,
	paginateAnonymousSession: GraphQLTypes["AnonymousSessionConnection"],
	validateCreateAnonymousSession: GraphQLTypes["_ValidationResult"],
	validateUpdateAnonymousSession: GraphQLTypes["_ValidationResult"],
	getVoucher?: GraphQLTypes["Voucher"] | undefined,
	listVoucher: Array<GraphQLTypes["Voucher"]>,
	paginateVoucher: GraphQLTypes["VoucherConnection"],
	getVoucherRedemption?: GraphQLTypes["VoucherRedemption"] | undefined,
	listVoucherRedemption: Array<GraphQLTypes["VoucherRedemption"]>,
	paginateVoucherRedemption: GraphQLTypes["VoucherRedemptionConnection"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getWorkspace?: GraphQLTypes["Workspace"] | undefined,
	listWorkspace: Array<GraphQLTypes["Workspace"]>,
	paginateWorkspace: GraphQLTypes["WorkspaceConnection"],
	getCartSummary?: GraphQLTypes["CartSummary"] | undefined,
	listCartSummary: Array<GraphQLTypes["CartSummary"]>,
	paginateCartSummary: GraphQLTypes["CartSummaryConnection"],
	getOrderSummary?: GraphQLTypes["OrderSummary"] | undefined,
	listOrderSummary: Array<GraphQLTypes["OrderSummary"]>,
	paginateOrderSummary: GraphQLTypes["OrderSummaryConnection"],
	getOrderPaymentMetadata?: GraphQLTypes["OrderPaymentMetadata"] | undefined,
	listOrderPaymentMetadata: Array<GraphQLTypes["OrderPaymentMetadata"]>,
	paginateOrderPaymentMetadata: GraphQLTypes["OrderPaymentMetadataConnection"],
	getUserMetadata?: GraphQLTypes["UserMetadata"] | undefined,
	listUserMetadata: Array<GraphQLTypes["UserMetadata"]>,
	paginateUserMetadata: GraphQLTypes["UserMetadataConnection"],
	validateCreateIdentificationAttempt: GraphQLTypes["_ValidationResult"],
	getIdentificationCode?: GraphQLTypes["IdentificationCode"] | undefined,
	listIdentificationCode: Array<GraphQLTypes["IdentificationCode"]>,
	paginateIdentificationCode: GraphQLTypes["IdentificationCodeConnection"],
	getIdentificationCodeLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	listIdentificationCodeLocale: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	paginateIdentificationCodeLocale: GraphQLTypes["IdentificationCodeLocaleConnection"],
	getFulfillmentNote?: GraphQLTypes["FulfillmentNote"] | undefined,
	listFulfillmentNote: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateFulfillmentNote: GraphQLTypes["FulfillmentNoteConnection"],
	getCustomerMetadata?: GraphQLTypes["CustomerMetadata"] | undefined,
	listCustomerMetadata: Array<GraphQLTypes["CustomerMetadata"]>,
	paginateCustomerMetadata: GraphQLTypes["CustomerMetadataConnection"],
	getSubscriptionBox?: GraphQLTypes["SubscriptionBox"] | undefined,
	listSubscriptionBox: Array<GraphQLTypes["SubscriptionBox"]>,
	paginateSubscriptionBox: GraphQLTypes["SubscriptionBoxConnection"],
	getSubscriptionBoxLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	listSubscriptionBoxLocale: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	paginateSubscriptionBoxLocale: GraphQLTypes["SubscriptionBoxLocaleConnection"],
	getGridTileItem?: GraphQLTypes["GridTileItem"] | undefined,
	listGridTileItem: Array<GraphQLTypes["GridTileItem"]>,
	paginateGridTileItem: GraphQLTypes["GridTileItemConnection"],
	getGridTiles?: GraphQLTypes["GridTiles"] | undefined,
	listGridTiles: Array<GraphQLTypes["GridTiles"]>,
	paginateGridTiles: GraphQLTypes["GridTilesConnection"],
	getPosition?: GraphQLTypes["Position"] | undefined,
	listPosition: Array<GraphQLTypes["Position"]>,
	paginatePosition: GraphQLTypes["PositionConnection"],
	getStatsWidget?: GraphQLTypes["StatsWidget"] | undefined,
	listStatsWidget: Array<GraphQLTypes["StatsWidget"]>,
	paginateStatsWidget: GraphQLTypes["StatsWidgetConnection"],
	getStatsWidgetsLayout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	listStatsWidgetsLayout: Array<GraphQLTypes["StatsWidgetsLayout"]>,
	paginateStatsWidgetsLayout: GraphQLTypes["StatsWidgetsLayoutConnection"],
	getStatsPage?: GraphQLTypes["StatsPage"] | undefined,
	listStatsPage: Array<GraphQLTypes["StatsPage"]>,
	paginateStatsPage: GraphQLTypes["StatsPageConnection"],
	transaction?: GraphQLTypes["QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Info"] | undefined,
	schema?: GraphQLTypes["_Schema"] | undefined
};
	["Address"]: {
		_meta?: GraphQLTypes["AddressMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden: boolean,
	customer?: GraphQLTypes["Customer"] | undefined,
	country?: GraphQLTypes["Country"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	meta?: GraphQLTypes["AddressMetadata"] | undefined,
	gps?: GraphQLTypes["Gps"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices: Array<GraphQLTypes["Invoice"]>,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders: Array<GraphQLTypes["Order"]>,
	deliveryAddressOfOrderDeliveries: Array<GraphQLTypes["OrderDelivery"]>,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Customer"] | undefined,
	invoicesByPublicKey?: GraphQLTypes["Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Invoice"] | undefined,
	billingAddressOfOrdersBySeq?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDiscounts?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDelivery?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByItems?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByCart?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByPriceLines?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByPayments?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersBySummary?: GraphQLTypes["Order"] | undefined,
	deliveryAddressOfOrderDeliveriesByTrackingCode?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByOrder?: GraphQLTypes["OrderDelivery"] | undefined,
	paginateInvoices: GraphQLTypes["InvoiceConnection"],
	paginateBillingAddressOfOrders: GraphQLTypes["OrderConnection"],
	paginateDeliveryAddressOfOrderDeliveries: GraphQLTypes["OrderDeliveryConnection"]
};
	["AddressMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	firstName?: GraphQLTypes["FieldMeta"] | undefined,
	lastName?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine2?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	companyName?: GraphQLTypes["FieldMeta"] | undefined,
	companyIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	vatIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	externalIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	hidden?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	country?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	googlePlaceId?: GraphQLTypes["FieldMeta"] | undefined,
	raw?: GraphQLTypes["FieldMeta"] | undefined,
	formatted?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeResponse?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZone?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	gps?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeValid?: GraphQLTypes["FieldMeta"] | undefined,
	fullName?: GraphQLTypes["FieldMeta"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["FieldMeta"] | undefined,
	invoices?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine1?: GraphQLTypes["FieldMeta"] | undefined,
	city?: GraphQLTypes["FieldMeta"] | undefined,
	postalCode?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["FieldMeta"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
		readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]:string;
	["Customer"]: {
		_meta?: GraphQLTypes["CustomerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	addresses: Array<GraphQLTypes["Address"]>,
	defaultBillingAddress?: GraphQLTypes["Address"] | undefined,
	tags: Array<GraphQLTypes["Tag"]>,
	credits: Array<GraphQLTypes["CustomerCredit"]>,
	orders: Array<GraphQLTypes["Order"]>,
	permissions?: GraphQLTypes["CustomerPermissions"] | undefined,
	group?: GraphQLTypes["CustomerGroup"] | undefined,
	photo?: GraphQLTypes["Image"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["CustomerName"] | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	ingredientRatings: Array<GraphQLTypes["CustomerIngredientRating"]>,
	business?: GraphQLTypes["BusinessCustomer"] | undefined,
	ownerOfBusinesses: Array<GraphQLTypes["BusinessCustomer"]>,
	memberOfBusinesses: Array<GraphQLTypes["BusinessCustomer"]>,
	carts: Array<GraphQLTypes["Cart"]>,
	user?: GraphQLTypes["User"] | undefined,
	channels: Array<GraphQLTypes["Channel"]>,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices: Array<GraphQLTypes["Invoice"]>,
	invoicingPlan?: GraphQLTypes["InvoicingPlan"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects: Array<GraphQLTypes["BillingSubject"]>,
	offeredProducts: Array<GraphQLTypes["ProductCustomerPrice"]>,
	defaultDeliveryAddress?: GraphQLTypes["Address"] | undefined,
	defaultBillingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	prefersPackingsWithoutCardboard: boolean,
	anonymousSession?: GraphQLTypes["AnonymousSession"] | undefined,
	boughtVouchers: Array<GraphQLTypes["Voucher"]>,
	ownsVouchers: Array<GraphQLTypes["Voucher"]>,
	redeemedVouchers: Array<GraphQLTypes["VoucherRedemption"]>,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["CustomerMetadata"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	addressesByMeta?: GraphQLTypes["Address"] | undefined,
	addressesByGps?: GraphQLTypes["Address"] | undefined,
	addressesByInvoices?: GraphQLTypes["Address"] | undefined,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	creditsByVoucherRedemption?: GraphQLTypes["CustomerCredit"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDiscounts?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByCart?: GraphQLTypes["Order"] | undefined,
	ordersByPriceLines?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	ordersBySummary?: GraphQLTypes["Order"] | undefined,
	ingredientRatingsByIngredient?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	cartsByItems?: GraphQLTypes["Cart"] | undefined,
	cartsByOrder?: GraphQLTypes["Cart"] | undefined,
	cartsByPriceLines?: GraphQLTypes["Cart"] | undefined,
	cartsBySummary?: GraphQLTypes["Cart"] | undefined,
	invoicesByPublicKey?: GraphQLTypes["Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Invoice"] | undefined,
	billingSubjectsByInvoices?: GraphQLTypes["BillingSubject"] | undefined,
	billingSubjectsByDefaultBillingSubjectOfCustomer?: GraphQLTypes["BillingSubject"] | undefined,
	billingSubjectsByOrders?: GraphQLTypes["BillingSubject"] | undefined,
	offeredProductsByProduct?: GraphQLTypes["ProductCustomerPrice"] | undefined,
	boughtVouchersByCode?: GraphQLTypes["Voucher"] | undefined,
	boughtVouchersByRedemption?: GraphQLTypes["Voucher"] | undefined,
	ownsVouchersByCode?: GraphQLTypes["Voucher"] | undefined,
	ownsVouchersByRedemption?: GraphQLTypes["Voucher"] | undefined,
	redeemedVouchersByCustomerCredit?: GraphQLTypes["VoucherRedemption"] | undefined,
	redeemedVouchersByOrderDiscount?: GraphQLTypes["VoucherRedemption"] | undefined,
	redeemedVouchersByVoucher?: GraphQLTypes["VoucherRedemption"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateCredits: GraphQLTypes["CustomerCreditConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"],
	paginateIngredientRatings: GraphQLTypes["CustomerIngredientRatingConnection"],
	paginateOwnerOfBusinesses: GraphQLTypes["BusinessCustomerConnection"],
	paginateMemberOfBusinesses: GraphQLTypes["BusinessCustomerConnection"],
	paginateCarts: GraphQLTypes["CartConnection"],
	paginateChannels: GraphQLTypes["ChannelConnection"],
	paginateInvoices: GraphQLTypes["InvoiceConnection"],
	paginateBillingSubjects: GraphQLTypes["BillingSubjectConnection"],
	paginateOfferedProducts: GraphQLTypes["ProductCustomerPriceConnection"],
	paginateBoughtVouchers: GraphQLTypes["VoucherConnection"],
	paginateOwnsVouchers: GraphQLTypes["VoucherConnection"],
	paginateRedeemedVouchers: GraphQLTypes["VoucherRedemptionConnection"]
};
	["CustomerMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	addresses?: GraphQLTypes["FieldMeta"] | undefined,
	defaultBillingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	credits?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined,
	permissions?: GraphQLTypes["FieldMeta"] | undefined,
	group?: GraphQLTypes["FieldMeta"] | undefined,
	photo?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	welcomeMessage?: GraphQLTypes["FieldMeta"] | undefined,
	familiarity?: GraphQLTypes["FieldMeta"] | undefined,
	ingredientRatings?: GraphQLTypes["FieldMeta"] | undefined,
	business?: GraphQLTypes["FieldMeta"] | undefined,
	ownerOfBusinesses?: GraphQLTypes["FieldMeta"] | undefined,
	memberOfBusinesses?: GraphQLTypes["FieldMeta"] | undefined,
	carts?: GraphQLTypes["FieldMeta"] | undefined,
	user?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	disabledAt?: GraphQLTypes["FieldMeta"] | undefined,
	invoices?: GraphQLTypes["FieldMeta"] | undefined,
	invoicingPlan?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	billingSubjects?: GraphQLTypes["FieldMeta"] | undefined,
	offeredProducts?: GraphQLTypes["FieldMeta"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	defaultBillingSubject?: GraphQLTypes["FieldMeta"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["FieldMeta"] | undefined,
	anonymousSession?: GraphQLTypes["FieldMeta"] | undefined,
	boughtVouchers?: GraphQLTypes["FieldMeta"] | undefined,
	ownsVouchers?: GraphQLTypes["FieldMeta"] | undefined,
	redeemedVouchers?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["AddressWhere"]: GraphQLTypes["AddressWhere"];
	["UUIDCondition"]: GraphQLTypes["UUIDCondition"];
	["StringCondition"]: GraphQLTypes["StringCondition"];
	["BooleanCondition"]: GraphQLTypes["BooleanCondition"];
	["CustomerWhere"]: GraphQLTypes["CustomerWhere"];
	["TagWhere"]: GraphQLTypes["TagWhere"];
	["ProductWhere"]: GraphQLTypes["ProductWhere"];
	["IntCondition"]: GraphQLTypes["IntCondition"];
	["ProductPackingWhere"]: GraphQLTypes["ProductPackingWhere"];
	["ImageWhere"]: GraphQLTypes["ImageWhere"];
	["ProductPackingLocaleWhere"]: GraphQLTypes["ProductPackingLocaleWhere"];
	["LocaleWhere"]: GraphQLTypes["LocaleWhere"];
	["BusinessCategoryLocaleWhere"]: GraphQLTypes["BusinessCategoryLocaleWhere"];
	["BusinessCategoryWhere"]: GraphQLTypes["BusinessCategoryWhere"];
	["BusinessCustomerWhere"]: GraphQLTypes["BusinessCustomerWhere"];
	["OrderRecurrenceWhere"]: GraphQLTypes["OrderRecurrenceWhere"];
	["OrderRecurrenceLocaleWhere"]: GraphQLTypes["OrderRecurrenceLocaleWhere"];
	["ProductListWhere"]: GraphQLTypes["ProductListWhere"];
	["ProductListItemWhere"]: GraphQLTypes["ProductListItemWhere"];
	["ProductCategoryListWhere"]: GraphQLTypes["ProductCategoryListWhere"];
	["ProductCategoryListItemWhere"]: GraphQLTypes["ProductCategoryListItemWhere"];
	["ProductCategoryWhere"]: GraphQLTypes["ProductCategoryWhere"];
	["FloatCondition"]: GraphQLTypes["FloatCondition"];
	["ProductCategoryListLocaleWhere"]: GraphQLTypes["ProductCategoryListLocaleWhere"];
	["ProductIngredientLocaleWhere"]: GraphQLTypes["ProductIngredientLocaleWhere"];
	["ProductIngredientWhere"]: GraphQLTypes["ProductIngredientWhere"];
	["AllergenWhere"]: GraphQLTypes["AllergenWhere"];
	["AllergenLocaleWhere"]: GraphQLTypes["AllergenLocaleWhere"];
	["CustomerIngredientRatingWhere"]: GraphQLTypes["CustomerIngredientRatingWhere"];
	["ProductIngredientRatingWhere"]: GraphQLTypes["ProductIngredientRatingWhere"];
	["FreshingContainerWhere"]: GraphQLTypes["FreshingContainerWhere"];
	["WorkspaceWhere"]: GraphQLTypes["WorkspaceWhere"];
	["OneCondition"]: GraphQLTypes["OneCondition"];
	["One"]: GraphQLTypes["One"];
	["WeekHoursWhere"]: GraphQLTypes["WeekHoursWhere"];
	["VirtualProductWhere"]: GraphQLTypes["VirtualProductWhere"];
	["VirtualProductTypeCondition"]: GraphQLTypes["VirtualProductTypeCondition"];
	["VirtualProductType"]: GraphQLTypes["VirtualProductType"];
	["VirtualProductPhysicalRepresentationTypeCondition"]: GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"];
	["VirtualProductPhysicalRepresentationType"]: GraphQLTypes["VirtualProductPhysicalRepresentationType"];
	["VatRateWhere"]: GraphQLTypes["VatRateWhere"];
	["ChannelDeliveryWhere"]: GraphQLTypes["ChannelDeliveryWhere"];
	["ChannelWhere"]: GraphQLTypes["ChannelWhere"];
	["ChannelPaymentWhere"]: GraphQLTypes["ChannelPaymentWhere"];
	["PaymentMethodWhere"]: GraphQLTypes["PaymentMethodWhere"];
	["DeliveryMethodWhere"]: GraphQLTypes["DeliveryMethodWhere"];
	["DeliveryZoneWhere"]: GraphQLTypes["DeliveryZoneWhere"];
	["DeliveryZoneTypeCondition"]: GraphQLTypes["DeliveryZoneTypeCondition"];
	["DeliveryZoneType"]: GraphQLTypes["DeliveryZoneType"];
	["AddressMetadataWhere"]: GraphQLTypes["AddressMetadataWhere"];
	["DeliveryMethodLocaleWhere"]: GraphQLTypes["DeliveryMethodLocaleWhere"];
	["DeliveryTimelinePresetWhere"]: GraphQLTypes["DeliveryTimelinePresetWhere"];
	["DeliveryTimelineTypeCondition"]: GraphQLTypes["DeliveryTimelineTypeCondition"];
	["DeliveryTimelineType"]: GraphQLTypes["DeliveryTimelineType"];
	["PaymentMethodTypeCondition"]: GraphQLTypes["PaymentMethodTypeCondition"];
	["PaymentMethodType"]: GraphQLTypes["PaymentMethodType"];
	["CartWhere"]: GraphQLTypes["CartWhere"];
	["DateTimeCondition"]: GraphQLTypes["DateTimeCondition"];
	["DateTime"]:string;
	["CartItemWhere"]: GraphQLTypes["CartItemWhere"];
	["FulfillmentNoteWhere"]: GraphQLTypes["FulfillmentNoteWhere"];
	["OrderItemWhere"]: GraphQLTypes["OrderItemWhere"];
	["OrderWhere"]: GraphQLTypes["OrderWhere"];
	["OrderStateCondition"]: GraphQLTypes["OrderStateCondition"];
	["OrderState"]: GraphQLTypes["OrderState"];
	["OrderDiscountWhere"]: GraphQLTypes["OrderDiscountWhere"];
	["OrderDiscountTypeCondition"]: GraphQLTypes["OrderDiscountTypeCondition"];
	["OrderDiscountType"]: GraphQLTypes["OrderDiscountType"];
	["VoucherRedemptionWhere"]: GraphQLTypes["VoucherRedemptionWhere"];
	["CustomerCreditWhere"]: GraphQLTypes["CustomerCreditWhere"];
	["VoucherWhere"]: GraphQLTypes["VoucherWhere"];
	["VoucherTypeCondition"]: GraphQLTypes["VoucherTypeCondition"];
	["VoucherType"]: GraphQLTypes["VoucherType"];
	["VirtualProductEffectWhere"]: GraphQLTypes["VirtualProductEffectWhere"];
	["OrderDeliveryWhere"]: GraphQLTypes["OrderDeliveryWhere"];
	["OrderDeliveryStateCondition"]: GraphQLTypes["OrderDeliveryStateCondition"];
	["OrderDeliveryState"]: GraphQLTypes["OrderDeliveryState"];
	["OrderPriceLineWhere"]: GraphQLTypes["OrderPriceLineWhere"];
	["OrderPriceLineTypeCondition"]: GraphQLTypes["OrderPriceLineTypeCondition"];
	["OrderPriceLineType"]: GraphQLTypes["OrderPriceLineType"];
	["OrderPaymentWhere"]: GraphQLTypes["OrderPaymentWhere"];
	["JsonCondition"]: GraphQLTypes["JsonCondition"];
	["OrderPaymentStateCondition"]: GraphQLTypes["OrderPaymentStateCondition"];
	["OrderPaymentState"]: GraphQLTypes["OrderPaymentState"];
	["InvoiceWhere"]: GraphQLTypes["InvoiceWhere"];
	["BillingSubjectWhere"]: GraphQLTypes["BillingSubjectWhere"];
	["CountryWhere"]: GraphQLTypes["CountryWhere"];
	["OrderPaymentMetadataWhere"]: GraphQLTypes["OrderPaymentMetadataWhere"];
	["OrderSummaryWhere"]: GraphQLTypes["OrderSummaryWhere"];
	["CartStateCondition"]: GraphQLTypes["CartStateCondition"];
	["CartState"]: GraphQLTypes["CartState"];
	["CartPriceLineWhere"]: GraphQLTypes["CartPriceLineWhere"];
	["CartPriceLineTypeCondition"]: GraphQLTypes["CartPriceLineTypeCondition"];
	["CartPriceLineType"]: GraphQLTypes["CartPriceLineType"];
	["CartSummaryWhere"]: GraphQLTypes["CartSummaryWhere"];
	["ChannelCodeCondition"]: GraphQLTypes["ChannelCodeCondition"];
	["ChannelCode"]: GraphQLTypes["ChannelCode"];
	["VatRatePresetCondition"]: GraphQLTypes["VatRatePresetCondition"];
	["VatRatePreset"]: GraphQLTypes["VatRatePreset"];
	["BlogLocaleWhere"]: GraphQLTypes["BlogLocaleWhere"];
	["BlogWhere"]: GraphQLTypes["BlogWhere"];
	["BlogPostLocaleWhere"]: GraphQLTypes["BlogPostLocaleWhere"];
	["BlogPostWhere"]: GraphQLTypes["BlogPostWhere"];
	["CoverWhere"]: GraphQLTypes["CoverWhere"];
	["MediumWhere"]: GraphQLTypes["MediumWhere"];
	["MediumTypeCondition"]: GraphQLTypes["MediumTypeCondition"];
	["MediumType"]: GraphQLTypes["MediumType"];
	["ColorThemeCondition"]: GraphQLTypes["ColorThemeCondition"];
	["ColorTheme"]: GraphQLTypes["ColorTheme"];
	["VideoWhere"]: GraphQLTypes["VideoWhere"];
	["ColorPalleteWhere"]: GraphQLTypes["ColorPalleteWhere"];
	["ContentWhere"]: GraphQLTypes["ContentWhere"];
	["ContentBlockWhere"]: GraphQLTypes["ContentBlockWhere"];
	["ContentReferenceWhere"]: GraphQLTypes["ContentReferenceWhere"];
	["ContentReferenceTypeCondition"]: GraphQLTypes["ContentReferenceTypeCondition"];
	["ContentReferenceType"]: GraphQLTypes["ContentReferenceType"];
	["LinkWhere"]: GraphQLTypes["LinkWhere"];
	["LinkTypeCondition"]: GraphQLTypes["LinkTypeCondition"];
	["LinkType"]: GraphQLTypes["LinkType"];
	["LinkableWhere"]: GraphQLTypes["LinkableWhere"];
	["PageLocaleWhere"]: GraphQLTypes["PageLocaleWhere"];
	["PageWhere"]: GraphQLTypes["PageWhere"];
	["SeoWhere"]: GraphQLTypes["SeoWhere"];
	["PageThemeCondition"]: GraphQLTypes["PageThemeCondition"];
	["PageTheme"]: GraphQLTypes["PageTheme"];
	["RedirectWhere"]: GraphQLTypes["RedirectWhere"];
	["BlogPostListWhere"]: GraphQLTypes["BlogPostListWhere"];
	["BlogPostListItemWhere"]: GraphQLTypes["BlogPostListItemWhere"];
	["LinkListWhere"]: GraphQLTypes["LinkListWhere"];
	["LinkListItemWhere"]: GraphQLTypes["LinkListItemWhere"];
	["ContentSizeCondition"]: GraphQLTypes["ContentSizeCondition"];
	["ContentSize"]: GraphQLTypes["ContentSize"];
	["HeroWhere"]: GraphQLTypes["HeroWhere"];
	["GalleryWhere"]: GraphQLTypes["GalleryWhere"];
	["GalleryItemWhere"]: GraphQLTypes["GalleryItemWhere"];
	["SocialsAndAppsWhere"]: GraphQLTypes["SocialsAndAppsWhere"];
	["ProductGridWhere"]: GraphQLTypes["ProductGridWhere"];
	["ProductGridCategoryWhere"]: GraphQLTypes["ProductGridCategoryWhere"];
	["ProductGridItemWhere"]: GraphQLTypes["ProductGridItemWhere"];
	["ImageListWhere"]: GraphQLTypes["ImageListWhere"];
	["ImageListItemWhere"]: GraphQLTypes["ImageListItemWhere"];
	["ProductGridTypeCondition"]: GraphQLTypes["ProductGridTypeCondition"];
	["ProductGridType"]: GraphQLTypes["ProductGridType"];
	["ProductBannerListWhere"]: GraphQLTypes["ProductBannerListWhere"];
	["ProductBannerItemWhere"]: GraphQLTypes["ProductBannerItemWhere"];
	["ProductBannerWhere"]: GraphQLTypes["ProductBannerWhere"];
	["ProductBannerItemSizeCondition"]: GraphQLTypes["ProductBannerItemSizeCondition"];
	["ProductBannerItemSize"]: GraphQLTypes["ProductBannerItemSize"];
	["DeliveryRegionsWhere"]: GraphQLTypes["DeliveryRegionsWhere"];
	["DeliveryRegionsItemWhere"]: GraphQLTypes["DeliveryRegionsItemWhere"];
	["TextListWhere"]: GraphQLTypes["TextListWhere"];
	["TextItemWhere"]: GraphQLTypes["TextItemWhere"];
	["GridTilesWhere"]: GraphQLTypes["GridTilesWhere"];
	["GridTileItemWhere"]: GraphQLTypes["GridTileItemWhere"];
	["TranslationCataloguesIdentifierWhere"]: GraphQLTypes["TranslationCataloguesIdentifierWhere"];
	["TranslationCatalogueWhere"]: GraphQLTypes["TranslationCatalogueWhere"];
	["TranslationDomainWhere"]: GraphQLTypes["TranslationDomainWhere"];
	["TranslationKeyWhere"]: GraphQLTypes["TranslationKeyWhere"];
	["TranslationContentTypeCondition"]: GraphQLTypes["TranslationContentTypeCondition"];
	["TranslationContentType"]: GraphQLTypes["TranslationContentType"];
	["TranslationValueWhere"]: GraphQLTypes["TranslationValueWhere"];
	["ProductLocaleWhere"]: GraphQLTypes["ProductLocaleWhere"];
	["ProductRecipeLocaleWhere"]: GraphQLTypes["ProductRecipeLocaleWhere"];
	["ProductRecipeWhere"]: GraphQLTypes["ProductRecipeWhere"];
	["ProductIngredientItemWhere"]: GraphQLTypes["ProductIngredientItemWhere"];
	["IdentificationCodeLocaleWhere"]: GraphQLTypes["IdentificationCodeLocaleWhere"];
	["IdentificationCodeWhere"]: GraphQLTypes["IdentificationCodeWhere"];
	["SubscriptionBoxLocaleWhere"]: GraphQLTypes["SubscriptionBoxLocaleWhere"];
	["SubscriptionBoxWhere"]: GraphQLTypes["SubscriptionBoxWhere"];
	["ProductStockWhere"]: GraphQLTypes["ProductStockWhere"];
	["StoreWhere"]: GraphQLTypes["StoreWhere"];
	["ProductGroupPriceWhere"]: GraphQLTypes["ProductGroupPriceWhere"];
	["CustomerGroupWhere"]: GraphQLTypes["CustomerGroupWhere"];
	["ProductAvailabilityCondition"]: GraphQLTypes["ProductAvailabilityCondition"];
	["ProductAvailability"]: GraphQLTypes["ProductAvailability"];
	["ProductCustomerPriceWhere"]: GraphQLTypes["ProductCustomerPriceWhere"];
	["ProductMetadataWhere"]: GraphQLTypes["ProductMetadataWhere"];
	["CustomerPermissionsWhere"]: GraphQLTypes["CustomerPermissionsWhere"];
	["CustomerNameWhere"]: GraphQLTypes["CustomerNameWhere"];
	["CustomerFamiliarityCondition"]: GraphQLTypes["CustomerFamiliarityCondition"];
	["CustomerFamiliarity"]: GraphQLTypes["CustomerFamiliarity"];
	["UserWhere"]: GraphQLTypes["UserWhere"];
	["StaffWhere"]: GraphQLTypes["StaffWhere"];
	["UserMetadataWhere"]: GraphQLTypes["UserMetadataWhere"];
	["InvoicingPlanWhere"]: GraphQLTypes["InvoicingPlanWhere"];
	["InvoicingPresetWhere"]: GraphQLTypes["InvoicingPresetWhere"];
	["InvoicingPresetTypeCondition"]: GraphQLTypes["InvoicingPresetTypeCondition"];
	["InvoicingPresetType"]: GraphQLTypes["InvoicingPresetType"];
	["AnonymousSessionWhere"]: GraphQLTypes["AnonymousSessionWhere"];
	["CustomerMetadataWhere"]: GraphQLTypes["CustomerMetadataWhere"];
	["GpsWhere"]: GraphQLTypes["GpsWhere"];
	["AddressOrderBy"]: GraphQLTypes["AddressOrderBy"];
	["OrderDirection"]: GraphQLTypes["OrderDirection"];
	["CustomerOrderBy"]: GraphQLTypes["CustomerOrderBy"];
	["CustomerPermissionsOrderBy"]: GraphQLTypes["CustomerPermissionsOrderBy"];
	["CustomerGroupOrderBy"]: GraphQLTypes["CustomerGroupOrderBy"];
	["ImageOrderBy"]: GraphQLTypes["ImageOrderBy"];
	["CustomerNameOrderBy"]: GraphQLTypes["CustomerNameOrderBy"];
	["BusinessCustomerOrderBy"]: GraphQLTypes["BusinessCustomerOrderBy"];
	["BusinessCategoryOrderBy"]: GraphQLTypes["BusinessCategoryOrderBy"];
	["ProductListOrderBy"]: GraphQLTypes["ProductListOrderBy"];
	["ProductCategoryListOrderBy"]: GraphQLTypes["ProductCategoryListOrderBy"];
	["UserOrderBy"]: GraphQLTypes["UserOrderBy"];
	["StaffOrderBy"]: GraphQLTypes["StaffOrderBy"];
	["UserMetadataOrderBy"]: GraphQLTypes["UserMetadataOrderBy"];
	["InvoicingPlanOrderBy"]: GraphQLTypes["InvoicingPlanOrderBy"];
	["InvoicingPresetOrderBy"]: GraphQLTypes["InvoicingPresetOrderBy"];
	["BillingSubjectOrderBy"]: GraphQLTypes["BillingSubjectOrderBy"];
	["CountryOrderBy"]: GraphQLTypes["CountryOrderBy"];
	["AnonymousSessionOrderBy"]: GraphQLTypes["AnonymousSessionOrderBy"];
	["CustomerMetadataOrderBy"]: GraphQLTypes["CustomerMetadataOrderBy"];
	["DeliveryZoneOrderBy"]: GraphQLTypes["DeliveryZoneOrderBy"];
	["AddressMetadataOrderBy"]: GraphQLTypes["AddressMetadataOrderBy"];
	["GpsOrderBy"]: GraphQLTypes["GpsOrderBy"];
	["Tag"]: {
		_meta?: GraphQLTypes["TagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	customers: Array<GraphQLTypes["Customer"]>,
	products: Array<GraphQLTypes["Product"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Channel"]>,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	publicInChannels: Array<GraphQLTypes["Channel"]>,
	allowChannelPayments: Array<GraphQLTypes["ChannelPayment"]>,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateCustomers: GraphQLTypes["CustomerConnection"],
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["ChannelConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginatePublicInChannels: GraphQLTypes["ChannelConnection"],
	paginateAllowChannelPayments: GraphQLTypes["ChannelPaymentConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["TagMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	customers?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	publicInChannels?: GraphQLTypes["FieldMeta"] | undefined,
	allowChannelPayments?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined
};
	["Product"]: {
		_meta?: GraphQLTypes["ProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	priceCents: number,
	notDiscountable: boolean,
	packing?: GraphQLTypes["ProductPacking"] | undefined,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	tags: Array<GraphQLTypes["Tag"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	stocks: Array<GraphQLTypes["ProductStock"]>,
	groupPrices: Array<GraphQLTypes["ProductGroupPrice"]>,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems: Array<GraphQLTypes["CartItem"]>,
	availability: GraphQLTypes["ProductAvailability"],
	order: number,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	maximumQuantityPerOrder?: number | undefined,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	locales: Array<GraphQLTypes["ProductLocale"]>,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices: Array<GraphQLTypes["ProductCustomerPrice"]>,
	virtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	meta?: GraphQLTypes["ProductMetadata"] | undefined,
	page?: GraphQLTypes["Page"] | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	hasSubscription: boolean,
	pointOfSaleTileColor?: string | undefined,
	stocksByStore?: GraphQLTypes["ProductStock"] | undefined,
	groupPricesByGroup?: GraphQLTypes["ProductGroupPrice"] | undefined,
	cartItemsByCart?: GraphQLTypes["CartItem"] | undefined,
	orderItemsByVirtualProductEffects?: GraphQLTypes["OrderItem"] | undefined,
	localesByLocale?: GraphQLTypes["ProductLocale"] | undefined,
	localesByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	customerPricesByCustomer?: GraphQLTypes["ProductCustomerPrice"] | undefined,
	paginateTags: GraphQLTypes["TagConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateStocks: GraphQLTypes["ProductStockConnection"],
	paginateGroupPrices: GraphQLTypes["ProductGroupPriceConnection"],
	paginateCartItems: GraphQLTypes["CartItemConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["ProductLocaleConnection"],
	paginateCustomerPrices: GraphQLTypes["ProductCustomerPriceConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["ProductMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["FieldMeta"] | undefined,
	packing?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	stocks?: GraphQLTypes["FieldMeta"] | undefined,
	groupPrices?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	cartItems?: GraphQLTypes["FieldMeta"] | undefined,
	availability?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["FieldMeta"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	archivedAt?: GraphQLTypes["FieldMeta"] | undefined,
	customerPrices?: GraphQLTypes["FieldMeta"] | undefined,
	virtualProduct?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	hasSubscription?: GraphQLTypes["FieldMeta"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPacking"]: {
		_meta?: GraphQLTypes["ProductPackingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	shortName?: string | undefined,
	locales: Array<GraphQLTypes["ProductPackingLocale"]>,
	isPackedInCardboard: boolean,
	localesByLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductPackingLocaleConnection"]
};
	["ProductPackingMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	isPackedInCardboard?: GraphQLTypes["FieldMeta"] | undefined
};
	["Image"]: {
		_meta?: GraphQLTypes["ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined
};
	["ImageMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	alt?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPackingLocale"]: {
		_meta?: GraphQLTypes["ProductPackingLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	shortName?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["ProductPacking"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductPackingLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["Locale"]: {
		_meta?: GraphQLTypes["LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	businessCategories: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	productCategoryLists: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	productIngredients: Array<GraphQLTypes["ProductIngredientLocale"]>,
	orderRecurrences: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	blogs: Array<GraphQLTypes["BlogLocale"]>,
	blogPosts: Array<GraphQLTypes["BlogPostLocale"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	pages: Array<GraphQLTypes["PageLocale"]>,
	allergens: Array<GraphQLTypes["AllergenLocale"]>,
	productPackings: Array<GraphQLTypes["ProductPackingLocale"]>,
	products: Array<GraphQLTypes["ProductLocale"]>,
	productRecipes: Array<GraphQLTypes["ProductRecipeLocale"]>,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	identificationCodes: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	subscriptionBoxes: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	businessCategoriesByRoot?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	productCategoryListsByRoot?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	productIngredientsByRoot?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	orderRecurrencesByRoot?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	blogsByRoot?: GraphQLTypes["BlogLocale"] | undefined,
	blogPostsByRoot?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	pagesByRoot?: GraphQLTypes["PageLocale"] | undefined,
	pagesByLink?: GraphQLTypes["PageLocale"] | undefined,
	pagesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	pagesByContent?: GraphQLTypes["PageLocale"] | undefined,
	pagesByCover?: GraphQLTypes["PageLocale"] | undefined,
	allergensByRoot?: GraphQLTypes["AllergenLocale"] | undefined,
	productPackingsByRoot?: GraphQLTypes["ProductPackingLocale"] | undefined,
	productsByRoot?: GraphQLTypes["ProductLocale"] | undefined,
	productsByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	productRecipesByRoot?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	deliveryMethodsByRoot?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	identificationCodesByRoot?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	subscriptionBoxesByRoot?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesByTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesBySecondaryTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	paginateBusinessCategories: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateProductCategoryLists: GraphQLTypes["ProductCategoryListLocaleConnection"],
	paginateProductIngredients: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateOrderRecurrences: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBlogs: GraphQLTypes["BlogLocaleConnection"],
	paginateBlogPosts: GraphQLTypes["BlogPostLocaleConnection"],
	paginatePages: GraphQLTypes["PageLocaleConnection"],
	paginateAllergens: GraphQLTypes["AllergenLocaleConnection"],
	paginateProductPackings: GraphQLTypes["ProductPackingLocaleConnection"],
	paginateProducts: GraphQLTypes["ProductLocaleConnection"],
	paginateProductRecipes: GraphQLTypes["ProductRecipeLocaleConnection"],
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodLocaleConnection"],
	paginateIdentificationCodes: GraphQLTypes["IdentificationCodeLocaleConnection"],
	paginateSubscriptionBoxes: GraphQLTypes["SubscriptionBoxLocaleConnection"]
};
	["LocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategories?: GraphQLTypes["FieldMeta"] | undefined,
	productCategoryLists?: GraphQLTypes["FieldMeta"] | undefined,
	productIngredients?: GraphQLTypes["FieldMeta"] | undefined,
	orderRecurrences?: GraphQLTypes["FieldMeta"] | undefined,
	blogs?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	pages?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	productPackings?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	productRecipes?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	identificationCodes?: GraphQLTypes["FieldMeta"] | undefined,
	subscriptionBoxes?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocale"]: {
		_meta?: GraphQLTypes["BusinessCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["BusinessCategory"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	title?: string | undefined
};
	["BusinessCategoryLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategory"]: {
		_meta?: GraphQLTypes["BusinessCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	visibleToNewCustomers: boolean,
	locales: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	internalName: string,
	businessCustomers: Array<GraphQLTypes["BusinessCustomer"]>,
	recurrences: Array<GraphQLTypes["OrderRecurrence"]>,
	specificProducts?: GraphQLTypes["ProductList"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	slug: string,
	localesByLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	businessCustomersByCustomer?: GraphQLTypes["BusinessCustomer"] | undefined,
	paginateLocales: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateBusinessCustomers: GraphQLTypes["BusinessCustomerConnection"],
	paginateRecurrences: GraphQLTypes["OrderRecurrenceConnection"]
};
	["BusinessCategoryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	businessCustomers?: GraphQLTypes["FieldMeta"] | undefined,
	recurrences?: GraphQLTypes["FieldMeta"] | undefined,
	specificProducts?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocaleOrderBy"]: GraphQLTypes["BusinessCategoryLocaleOrderBy"];
	["LocaleOrderBy"]: GraphQLTypes["LocaleOrderBy"];
	["TranslationCataloguesIdentifierOrderBy"]: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"];
	["BusinessCustomer"]: {
		_meta?: GraphQLTypes["BusinessCustomerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	customer?: GraphQLTypes["Customer"] | undefined,
	owners: Array<GraphQLTypes["Customer"]>,
	members: Array<GraphQLTypes["Customer"]>,
	category?: GraphQLTypes["BusinessCategory"] | undefined,
	logo?: GraphQLTypes["Image"] | undefined,
	note?: string | undefined,
	paginateOwners: GraphQLTypes["CustomerConnection"],
	paginateMembers: GraphQLTypes["CustomerConnection"]
};
	["BusinessCustomerMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	owners?: GraphQLTypes["FieldMeta"] | undefined,
	members?: GraphQLTypes["FieldMeta"] | undefined,
	category?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerEdge"]>
};
	["PageInfo"]: {
		totalCount: number
};
	["CustomerEdge"]: {
		node: GraphQLTypes["Customer"]
};
	["OrderRecurrence"]: {
		_meta?: GraphQLTypes["OrderRecurrenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	order: number,
	businessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	localesByLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	paginateLocales: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"]
};
	["OrderRecurrenceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocale"]: {
		_meta?: GraphQLTypes["OrderRecurrenceLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["OrderRecurrence"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["OrderRecurrenceLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocaleOrderBy"]: GraphQLTypes["OrderRecurrenceLocaleOrderBy"];
	["OrderRecurrenceOrderBy"]: GraphQLTypes["OrderRecurrenceOrderBy"];
	["OrderRecurrenceLocalesByLocaleUniqueWhere"]: GraphQLTypes["OrderRecurrenceLocalesByLocaleUniqueWhere"];
	["LocaleUniqueWhere"]: GraphQLTypes["LocaleUniqueWhere"];
	["BusinessCategoryLocaleUniqueWhere"]: GraphQLTypes["BusinessCategoryLocaleUniqueWhere"];
	["BusinessCategoryUniqueWhere"]: GraphQLTypes["BusinessCategoryUniqueWhere"];
	["BusinessCustomerUniqueWhere"]: GraphQLTypes["BusinessCustomerUniqueWhere"];
	["CustomerUniqueWhere"]: GraphQLTypes["CustomerUniqueWhere"];
	["AddressUniqueWhere"]: GraphQLTypes["AddressUniqueWhere"];
	["AddressMetadataUniqueWhere"]: GraphQLTypes["AddressMetadataUniqueWhere"];
	["GpsUniqueWhere"]: GraphQLTypes["GpsUniqueWhere"];
	["InvoiceUniqueWhere"]: GraphQLTypes["InvoiceUniqueWhere"];
	["OrderPaymentUniqueWhere"]: GraphQLTypes["OrderPaymentUniqueWhere"];
	["OrderPaymentMetadataUniqueWhere"]: GraphQLTypes["OrderPaymentMetadataUniqueWhere"];
	["OrderUniqueWhere"]: GraphQLTypes["OrderUniqueWhere"];
	["OrderDiscountUniqueWhere"]: GraphQLTypes["OrderDiscountUniqueWhere"];
	["VoucherRedemptionUniqueWhere"]: GraphQLTypes["VoucherRedemptionUniqueWhere"];
	["CustomerCreditUniqueWhere"]: GraphQLTypes["CustomerCreditUniqueWhere"];
	["VoucherUniqueWhere"]: GraphQLTypes["VoucherUniqueWhere"];
	["OrderDeliveryUniqueWhere"]: GraphQLTypes["OrderDeliveryUniqueWhere"];
	["OrderItemUniqueWhere"]: GraphQLTypes["OrderItemUniqueWhere"];
	["VirtualProductEffectUniqueWhere"]: GraphQLTypes["VirtualProductEffectUniqueWhere"];
	["CartUniqueWhere"]: GraphQLTypes["CartUniqueWhere"];
	["CartItemUniqueWhere"]: GraphQLTypes["CartItemUniqueWhere"];
	["ProductUniqueWhere"]: GraphQLTypes["ProductUniqueWhere"];
	["ProductStockUniqueWhere"]: GraphQLTypes["ProductStockUniqueWhere"];
	["StoreUniqueWhere"]: GraphQLTypes["StoreUniqueWhere"];
	["ProductGroupPriceUniqueWhere"]: GraphQLTypes["ProductGroupPriceUniqueWhere"];
	["CustomerGroupUniqueWhere"]: GraphQLTypes["CustomerGroupUniqueWhere"];
	["ProductLocaleUniqueWhere"]: GraphQLTypes["ProductLocaleUniqueWhere"];
	["TextListUniqueWhere"]: GraphQLTypes["TextListUniqueWhere"];
	["TextItemUniqueWhere"]: GraphQLTypes["TextItemUniqueWhere"];
	["ProductCustomerPriceUniqueWhere"]: GraphQLTypes["ProductCustomerPriceUniqueWhere"];
	["VirtualProductUniqueWhere"]: GraphQLTypes["VirtualProductUniqueWhere"];
	["ProductMetadataUniqueWhere"]: GraphQLTypes["ProductMetadataUniqueWhere"];
	["PageUniqueWhere"]: GraphQLTypes["PageUniqueWhere"];
	["PageLocaleUniqueWhere"]: GraphQLTypes["PageLocaleUniqueWhere"];
	["LinkableUniqueWhere"]: GraphQLTypes["LinkableUniqueWhere"];
	["BlogPostLocaleUniqueWhere"]: GraphQLTypes["BlogPostLocaleUniqueWhere"];
	["BlogPostUniqueWhere"]: GraphQLTypes["BlogPostUniqueWhere"];
	["CoverUniqueWhere"]: GraphQLTypes["CoverUniqueWhere"];
	["ContentUniqueWhere"]: GraphQLTypes["ContentUniqueWhere"];
	["ContentBlockUniqueWhere"]: GraphQLTypes["ContentBlockUniqueWhere"];
	["ContentReferenceUniqueWhere"]: GraphQLTypes["ContentReferenceUniqueWhere"];
	["ProductListUniqueWhere"]: GraphQLTypes["ProductListUniqueWhere"];
	["ProductListItemUniqueWhere"]: GraphQLTypes["ProductListItemUniqueWhere"];
	["ProductBannerListUniqueWhere"]: GraphQLTypes["ProductBannerListUniqueWhere"];
	["ProductBannerItemUniqueWhere"]: GraphQLTypes["ProductBannerItemUniqueWhere"];
	["DeliveryRegionsUniqueWhere"]: GraphQLTypes["DeliveryRegionsUniqueWhere"];
	["DeliveryRegionsItemUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemUniqueWhere"];
	["ImageUniqueWhere"]: GraphQLTypes["ImageUniqueWhere"];
	["ImageListUniqueWhere"]: GraphQLTypes["ImageListUniqueWhere"];
	["ImageListItemUniqueWhere"]: GraphQLTypes["ImageListItemUniqueWhere"];
	["SeoUniqueWhere"]: GraphQLTypes["SeoUniqueWhere"];
	["RedirectUniqueWhere"]: GraphQLTypes["RedirectUniqueWhere"];
	["CartPriceLineUniqueWhere"]: GraphQLTypes["CartPriceLineUniqueWhere"];
	["CartSummaryUniqueWhere"]: GraphQLTypes["CartSummaryUniqueWhere"];
	["OrderPriceLineUniqueWhere"]: GraphQLTypes["OrderPriceLineUniqueWhere"];
	["OrderSummaryUniqueWhere"]: GraphQLTypes["OrderSummaryUniqueWhere"];
	["CustomerPermissionsUniqueWhere"]: GraphQLTypes["CustomerPermissionsUniqueWhere"];
	["CustomerNameUniqueWhere"]: GraphQLTypes["CustomerNameUniqueWhere"];
	["CustomerIngredientRatingUniqueWhere"]: GraphQLTypes["CustomerIngredientRatingUniqueWhere"];
	["ProductIngredientUniqueWhere"]: GraphQLTypes["ProductIngredientUniqueWhere"];
	["ProductIngredientLocaleUniqueWhere"]: GraphQLTypes["ProductIngredientLocaleUniqueWhere"];
	["UserUniqueWhere"]: GraphQLTypes["UserUniqueWhere"];
	["StaffUniqueWhere"]: GraphQLTypes["StaffUniqueWhere"];
	["UserMetadataUniqueWhere"]: GraphQLTypes["UserMetadataUniqueWhere"];
	["InvoicingPlanUniqueWhere"]: GraphQLTypes["InvoicingPlanUniqueWhere"];
	["InvoicingPresetUniqueWhere"]: GraphQLTypes["InvoicingPresetUniqueWhere"];
	["BillingSubjectUniqueWhere"]: GraphQLTypes["BillingSubjectUniqueWhere"];
	["AnonymousSessionUniqueWhere"]: GraphQLTypes["AnonymousSessionUniqueWhere"];
	["CustomerMetadataUniqueWhere"]: GraphQLTypes["CustomerMetadataUniqueWhere"];
	["ProductCategoryListUniqueWhere"]: GraphQLTypes["ProductCategoryListUniqueWhere"];
	["ProductCategoryListItemUniqueWhere"]: GraphQLTypes["ProductCategoryListItemUniqueWhere"];
	["ProductCategoryListLocaleUniqueWhere"]: GraphQLTypes["ProductCategoryListLocaleUniqueWhere"];
	["OrderRecurrenceLocaleUniqueWhere"]: GraphQLTypes["OrderRecurrenceLocaleUniqueWhere"];
	["OrderRecurrenceUniqueWhere"]: GraphQLTypes["OrderRecurrenceUniqueWhere"];
	["BlogLocaleUniqueWhere"]: GraphQLTypes["BlogLocaleUniqueWhere"];
	["BlogUniqueWhere"]: GraphQLTypes["BlogUniqueWhere"];
	["TranslationCataloguesIdentifierUniqueWhere"]: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"];
	["TranslationCatalogueUniqueWhere"]: GraphQLTypes["TranslationCatalogueUniqueWhere"];
	["TranslationDomainUniqueWhere"]: GraphQLTypes["TranslationDomainUniqueWhere"];
	["TranslationKeyUniqueWhere"]: GraphQLTypes["TranslationKeyUniqueWhere"];
	["TranslationValueUniqueWhere"]: GraphQLTypes["TranslationValueUniqueWhere"];
	["AllergenLocaleUniqueWhere"]: GraphQLTypes["AllergenLocaleUniqueWhere"];
	["AllergenUniqueWhere"]: GraphQLTypes["AllergenUniqueWhere"];
	["ProductPackingLocaleUniqueWhere"]: GraphQLTypes["ProductPackingLocaleUniqueWhere"];
	["ProductPackingUniqueWhere"]: GraphQLTypes["ProductPackingUniqueWhere"];
	["ProductRecipeLocaleUniqueWhere"]: GraphQLTypes["ProductRecipeLocaleUniqueWhere"];
	["ProductRecipeUniqueWhere"]: GraphQLTypes["ProductRecipeUniqueWhere"];
	["ProductIngredientItemUniqueWhere"]: GraphQLTypes["ProductIngredientItemUniqueWhere"];
	["DeliveryMethodLocaleUniqueWhere"]: GraphQLTypes["DeliveryMethodLocaleUniqueWhere"];
	["DeliveryMethodUniqueWhere"]: GraphQLTypes["DeliveryMethodUniqueWhere"];
	["ChannelDeliveryUniqueWhere"]: GraphQLTypes["ChannelDeliveryUniqueWhere"];
	["DeliveryTimelinePresetUniqueWhere"]: GraphQLTypes["DeliveryTimelinePresetUniqueWhere"];
	["IdentificationCodeLocaleUniqueWhere"]: GraphQLTypes["IdentificationCodeLocaleUniqueWhere"];
	["IdentificationCodeUniqueWhere"]: GraphQLTypes["IdentificationCodeUniqueWhere"];
	["SubscriptionBoxLocaleUniqueWhere"]: GraphQLTypes["SubscriptionBoxLocaleUniqueWhere"];
	["SubscriptionBoxUniqueWhere"]: GraphQLTypes["SubscriptionBoxUniqueWhere"];
	["OrderRecurrenceLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceLocaleEdge"]>
};
	["OrderRecurrenceLocaleEdge"]: {
		node: GraphQLTypes["OrderRecurrenceLocale"]
};
	["BusinessCategoryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryEdge"]>
};
	["BusinessCategoryEdge"]: {
		node: GraphQLTypes["BusinessCategory"]
};
	["ProductList"]: {
		_meta?: GraphQLTypes["ProductListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductListItem"]>,
	businessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	paginateItems: GraphQLTypes["ProductListItemConnection"]
};
	["ProductListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItem"]: {
		_meta?: GraphQLTypes["ProductListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductList"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	defaultQuantityForOrder?: number | undefined
};
	["ProductListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItemOrderBy"]: GraphQLTypes["ProductListItemOrderBy"];
	["ProductOrderBy"]: GraphQLTypes["ProductOrderBy"];
	["ProductPackingOrderBy"]: GraphQLTypes["ProductPackingOrderBy"];
	["ProductRecipeOrderBy"]: GraphQLTypes["ProductRecipeOrderBy"];
	["FreshingContainerOrderBy"]: GraphQLTypes["FreshingContainerOrderBy"];
	["WorkspaceOrderBy"]: GraphQLTypes["WorkspaceOrderBy"];
	["WeekHoursOrderBy"]: GraphQLTypes["WeekHoursOrderBy"];
	["VirtualProductOrderBy"]: GraphQLTypes["VirtualProductOrderBy"];
	["VatRateOrderBy"]: GraphQLTypes["VatRateOrderBy"];
	["ProductMetadataOrderBy"]: GraphQLTypes["ProductMetadataOrderBy"];
	["PageOrderBy"]: GraphQLTypes["PageOrderBy"];
	["ProductListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListItemEdge"]>
};
	["ProductListItemEdge"]: {
		node: GraphQLTypes["ProductListItem"]
};
	["ProductCategoryList"]: {
		_meta?: GraphQLTypes["ProductCategoryListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductCategoryListItem"]>,
	locales: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	localesByLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	paginateItems: GraphQLTypes["ProductCategoryListItemConnection"],
	paginateLocales: GraphQLTypes["ProductCategoryListLocaleConnection"]
};
	["ProductCategoryListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListItem"]: {
		_meta?: GraphQLTypes["ProductCategoryListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductCategoryList"] | undefined,
	productCategory?: GraphQLTypes["ProductCategory"] | undefined,
	showRecipeImages: boolean,
	showPackingImages: boolean,
	showAllAvailableProducts: boolean
};
	["ProductCategoryListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["FieldMeta"] | undefined,
	showRecipeImages?: GraphQLTypes["FieldMeta"] | undefined,
	showPackingImages?: GraphQLTypes["FieldMeta"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategory"]: {
		_meta?: GraphQLTypes["ProductCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Tag"]>,
	parent?: GraphQLTypes["ProductCategory"] | undefined,
	children: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	order: number,
	childrenByCode?: GraphQLTypes["ProductCategory"] | undefined,
	childrenByChildren?: GraphQLTypes["ProductCategory"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateChildren: GraphQLTypes["ProductCategoryConnection"]
};
	["ProductCategoryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	parent?: GraphQLTypes["FieldMeta"] | undefined,
	children?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["TagOrderBy"]: GraphQLTypes["TagOrderBy"];
	["ProductCategoryOrderBy"]: GraphQLTypes["ProductCategoryOrderBy"];
	["ProductCategoryChildrenByCodeUniqueWhere"]: GraphQLTypes["ProductCategoryChildrenByCodeUniqueWhere"];
	["ProductCategoryChildrenByChildrenUniqueWhere"]: GraphQLTypes["ProductCategoryChildrenByChildrenUniqueWhere"];
	["ProductCategoryUniqueWhere"]: GraphQLTypes["ProductCategoryUniqueWhere"];
	["ProductConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductEdge"]>
};
	["ProductEdge"]: {
		node: GraphQLTypes["Product"]
};
	["TagConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TagEdge"]>
};
	["TagEdge"]: {
		node: GraphQLTypes["Tag"]
};
	["ProductCategoryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryEdge"]>
};
	["ProductCategoryEdge"]: {
		node: GraphQLTypes["ProductCategory"]
};
	["ProductCategoryListItemOrderBy"]: GraphQLTypes["ProductCategoryListItemOrderBy"];
	["ProductCategoryListLocale"]: {
		_meta?: GraphQLTypes["ProductCategoryListLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductCategoryList"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductCategoryListLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListLocaleOrderBy"]: GraphQLTypes["ProductCategoryListLocaleOrderBy"];
	["ProductCategoryListLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductCategoryListLocalesByLocaleUniqueWhere"];
	["ProductCategoryListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListItemEdge"]>
};
	["ProductCategoryListItemEdge"]: {
		node: GraphQLTypes["ProductCategoryListItem"]
};
	["ProductCategoryListLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListLocaleEdge"]>
};
	["ProductCategoryListLocaleEdge"]: {
		node: GraphQLTypes["ProductCategoryListLocale"]
};
	["BusinessCategoryLocalesByLocaleUniqueWhere"]: GraphQLTypes["BusinessCategoryLocalesByLocaleUniqueWhere"];
	["BusinessCategoryBusinessCustomersByCustomerUniqueWhere"]: GraphQLTypes["BusinessCategoryBusinessCustomersByCustomerUniqueWhere"];
	["BusinessCategoryLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryLocaleEdge"]>
};
	["BusinessCategoryLocaleEdge"]: {
		node: GraphQLTypes["BusinessCategoryLocale"]
};
	["BusinessCustomerConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCustomerEdge"]>
};
	["BusinessCustomerEdge"]: {
		node: GraphQLTypes["BusinessCustomer"]
};
	["OrderRecurrenceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceEdge"]>
};
	["OrderRecurrenceEdge"]: {
		node: GraphQLTypes["OrderRecurrence"]
};
	["ProductIngredientLocale"]: {
		_meta?: GraphQLTypes["ProductIngredientLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductIngredient"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductIngredientLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredient"]: {
		_meta?: GraphQLTypes["ProductIngredientMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["ProductIngredientLocale"]>,
	internalName: string,
	allergens: Array<GraphQLTypes["Allergen"]>,
	needsPrepping: boolean,
	allowCustomerRating?: boolean | undefined,
	customerRatings: Array<GraphQLTypes["CustomerIngredientRating"]>,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	incrementQuantityNumber: number,
	localesByLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	customerRatingsByCustomer?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	paginateLocales: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateAllergens: GraphQLTypes["AllergenConnection"],
	paginateCustomerRatings: GraphQLTypes["CustomerIngredientRatingConnection"]
};
	["ProductIngredientMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	allowCustomerRating?: GraphQLTypes["FieldMeta"] | undefined,
	customerRatings?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientLocaleOrderBy"]: GraphQLTypes["ProductIngredientLocaleOrderBy"];
	["ProductIngredientOrderBy"]: GraphQLTypes["ProductIngredientOrderBy"];
	["Allergen"]: {
		_meta?: GraphQLTypes["AllergenMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["AllergenLocale"]>,
	ingredients: Array<GraphQLTypes["ProductIngredient"]>,
	code: number,
	localesByLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	paginateLocales: GraphQLTypes["AllergenLocaleConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientConnection"]
};
	["AllergenMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocale"]: {
		_meta?: GraphQLTypes["AllergenLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	root?: GraphQLTypes["Allergen"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["AllergenLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocaleOrderBy"]: GraphQLTypes["AllergenLocaleOrderBy"];
	["AllergenOrderBy"]: GraphQLTypes["AllergenOrderBy"];
	["AllergenLocalesByLocaleUniqueWhere"]: GraphQLTypes["AllergenLocalesByLocaleUniqueWhere"];
	["AllergenLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenLocaleEdge"]>
};
	["AllergenLocaleEdge"]: {
		node: GraphQLTypes["AllergenLocale"]
};
	["ProductIngredientConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientEdge"]>
};
	["ProductIngredientEdge"]: {
		node: GraphQLTypes["ProductIngredient"]
};
	["CustomerIngredientRating"]: {
		_meta?: GraphQLTypes["CustomerIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	customer?: GraphQLTypes["Customer"] | undefined,
	rating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined
};
	["CustomerIngredientRatingMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	rating?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientRating"]: {
		_meta?: GraphQLTypes["ProductIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	icon?: GraphQLTypes["Image"] | undefined
};
	["ProductIngredientRatingMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerIngredientRatingOrderBy"]: GraphQLTypes["CustomerIngredientRatingOrderBy"];
	["ProductIngredientRatingOrderBy"]: GraphQLTypes["ProductIngredientRatingOrderBy"];
	["FreshingContainer"]: {
		_meta?: GraphQLTypes["FreshingContainerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	volumeMl: number,
	workspace?: GraphQLTypes["Workspace"] | undefined
};
	["FreshingContainerMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	workspace?: GraphQLTypes["FieldMeta"] | undefined
};
	["Workspace"]: {
		_meta?: GraphQLTypes["WorkspaceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	orderDeadlineTime?: string | undefined,
	ordersLoadingDurationHours: number,
	freshingContainerVolumeMl: number,
	freshingPrepareOrderByOffsetMinutes: number,
	checkoutUrlTemplate?: string | undefined,
	orderUrlTemplate?: string | undefined,
	paymentUrlTemplate?: string | undefined,
	customerProfileUrlTemplate?: string | undefined,
	userProfileUrlTemplate?: string | undefined,
	messengerUrl?: string | undefined,
	messengerIosUrl?: string | undefined,
	messengerAndroidUrl?: string | undefined,
	freshingContainers: Array<GraphQLTypes["FreshingContainer"]>,
	openingHours?: GraphQLTypes["WeekHours"] | undefined,
	workingHours?: GraphQLTypes["WeekHours"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	discountVatRate?: GraphQLTypes["VatRate"] | undefined,
	name: string,
	businessAddress?: string | undefined,
	paginateFreshingContainers: GraphQLTypes["FreshingContainerConnection"]
};
	["WorkspaceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	orderDeadlineTime?: GraphQLTypes["FieldMeta"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["FieldMeta"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	orderUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	messengerUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerIosUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainers?: GraphQLTypes["FieldMeta"] | undefined,
	openingHours?: GraphQLTypes["FieldMeta"] | undefined,
	workingHours?: GraphQLTypes["FieldMeta"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	creditsProduct?: GraphQLTypes["FieldMeta"] | undefined,
	discountVatRate?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	businessAddress?: GraphQLTypes["FieldMeta"] | undefined
};
	["WeekHours"]: {
		_meta?: GraphQLTypes["WeekHoursMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	mon?: string | undefined,
	tue?: string | undefined,
	wed?: string | undefined,
	thu?: string | undefined,
	fri?: string | undefined,
	sat?: string | undefined,
	sun?: string | undefined
};
	["WeekHoursMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	mon?: GraphQLTypes["FieldMeta"] | undefined,
	tue?: GraphQLTypes["FieldMeta"] | undefined,
	wed?: GraphQLTypes["FieldMeta"] | undefined,
	thu?: GraphQLTypes["FieldMeta"] | undefined,
	fri?: GraphQLTypes["FieldMeta"] | undefined,
	sat?: GraphQLTypes["FieldMeta"] | undefined,
	sun?: GraphQLTypes["FieldMeta"] | undefined
};
	["VirtualProduct"]: {
		_meta?: GraphQLTypes["VirtualProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["VirtualProductType"],
	product?: GraphQLTypes["Product"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation: GraphQLTypes["VirtualProductPhysicalRepresentationType"],
	recipientEmail?: boolean | undefined
};
	["VirtualProductMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	voucherCreditCents?: GraphQLTypes["FieldMeta"] | undefined,
	physicalRepresentation?: GraphQLTypes["FieldMeta"] | undefined,
	recipientEmail?: GraphQLTypes["FieldMeta"] | undefined
};
	["VatRate"]: {
		_meta?: GraphQLTypes["VatRateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	ratePermille: number,
	description?: string | undefined,
	internalNote?: string | undefined,
	channelDeliveries: Array<GraphQLTypes["ChannelDelivery"]>,
	channelPayments: Array<GraphQLTypes["ChannelPayment"]>,
	preset?: GraphQLTypes["VatRatePreset"] | undefined,
	paginateChannelDeliveries: GraphQLTypes["ChannelDeliveryConnection"],
	paginateChannelPayments: GraphQLTypes["ChannelPaymentConnection"]
};
	["VatRateMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	ratePermille?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	channelDeliveries?: GraphQLTypes["FieldMeta"] | undefined,
	channelPayments?: GraphQLTypes["FieldMeta"] | undefined,
	preset?: GraphQLTypes["FieldMeta"] | undefined
};
	["ChannelDelivery"]: {
		_meta?: GraphQLTypes["ChannelDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	priceCents: number,
	channel?: GraphQLTypes["Channel"] | undefined,
	method?: GraphQLTypes["DeliveryMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	defaultVisible: boolean
};
	["ChannelDeliveryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	defaultVisible?: GraphQLTypes["FieldMeta"] | undefined
};
	["Channel"]: {
		_meta?: GraphQLTypes["ChannelMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	disabled: boolean,
	payments: Array<GraphQLTypes["ChannelPayment"]>,
	deliveries: Array<GraphQLTypes["ChannelDelivery"]>,
	carts: Array<GraphQLTypes["Cart"]>,
	customers: Array<GraphQLTypes["Customer"]>,
	code?: GraphQLTypes["ChannelCode"] | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Tag"]>,
	publicTags: Array<GraphQLTypes["Tag"]>,
	cartsByItems?: GraphQLTypes["Cart"] | undefined,
	cartsByOrder?: GraphQLTypes["Cart"] | undefined,
	cartsByPriceLines?: GraphQLTypes["Cart"] | undefined,
	cartsBySummary?: GraphQLTypes["Cart"] | undefined,
	paginatePayments: GraphQLTypes["ChannelPaymentConnection"],
	paginateDeliveries: GraphQLTypes["ChannelDeliveryConnection"],
	paginateCarts: GraphQLTypes["CartConnection"],
	paginateCustomers: GraphQLTypes["CustomerConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["TagConnection"],
	paginatePublicTags: GraphQLTypes["TagConnection"]
};
	["ChannelMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	disabled?: GraphQLTypes["FieldMeta"] | undefined,
	payments?: GraphQLTypes["FieldMeta"] | undefined,
	deliveries?: GraphQLTypes["FieldMeta"] | undefined,
	carts?: GraphQLTypes["FieldMeta"] | undefined,
	customers?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	publicTags?: GraphQLTypes["FieldMeta"] | undefined
};
	["ChannelPayment"]: {
		_meta?: GraphQLTypes["ChannelPaymentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	channel?: GraphQLTypes["Channel"] | undefined,
	method?: GraphQLTypes["PaymentMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags: Array<GraphQLTypes["Tag"]>,
	paginateAllowOnlyForTags: GraphQLTypes["TagConnection"]
};
	["ChannelPaymentMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	requireBillingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	provideReceipt?: GraphQLTypes["FieldMeta"] | undefined,
	requireBillingSubject?: GraphQLTypes["FieldMeta"] | undefined,
	allowOnlyForTags?: GraphQLTypes["FieldMeta"] | undefined
};
	["PaymentMethod"]: {
		_meta?: GraphQLTypes["PaymentMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch: boolean,
	type?: GraphQLTypes["PaymentMethodType"] | undefined,
	channels: Array<GraphQLTypes["ChannelPayment"]>,
	reduceGratuityByPercentage: number,
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateChannels: GraphQLTypes["ChannelPaymentConnection"]
};
	["PaymentMethodMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	blockingDispatch?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethod"]: {
		_meta?: GraphQLTypes["DeliveryMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress: boolean,
	suitableForDeliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	channels: Array<GraphQLTypes["ChannelDelivery"]>,
	enableDeliveryTracking: boolean,
	locales: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	localesByLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"],
	paginateSuitableForDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateChannels: GraphQLTypes["ChannelDeliveryConnection"],
	paginateLocales: GraphQLTypes["DeliveryMethodLocaleConnection"]
};
	["DeliveryMethodMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	timelinePreset?: GraphQLTypes["FieldMeta"] | undefined
};
	["PaymentMethodOrderBy"]: GraphQLTypes["PaymentMethodOrderBy"];
	["DeliveryZone"]: {
		_meta?: GraphQLTypes["DeliveryZoneMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type: GraphQLTypes["DeliveryZoneType"],
	addresses: Array<GraphQLTypes["Address"]>,
	suitableDeliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	deliveryZonesOfTags: Array<GraphQLTypes["Tag"]>,
	deliveryZonesOfProducts: Array<GraphQLTypes["Product"]>,
	addressesMetadata: Array<GraphQLTypes["AddressMetadata"]>,
	addressesByMeta?: GraphQLTypes["Address"] | undefined,
	addressesByGps?: GraphQLTypes["Address"] | undefined,
	addressesByInvoices?: GraphQLTypes["Address"] | undefined,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	addressesMetadataByAddress?: GraphQLTypes["AddressMetadata"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateSuitableDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateDeliveryZonesOfTags: GraphQLTypes["TagConnection"],
	paginateDeliveryZonesOfProducts: GraphQLTypes["ProductConnection"],
	paginateAddressesMetadata: GraphQLTypes["AddressMetadataConnection"]
};
	["DeliveryZoneMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	addresses?: GraphQLTypes["FieldMeta"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["FieldMeta"] | undefined,
	addressesMetadata?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodOrderBy"]: GraphQLTypes["DeliveryMethodOrderBy"];
	["DeliveryTimelinePresetOrderBy"]: GraphQLTypes["DeliveryTimelinePresetOrderBy"];
	["AddressMetadata"]: {
		_meta?: GraphQLTypes["AddressMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	deliveryZoneType?: GraphQLTypes["DeliveryZoneType"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZone"] | undefined
};
	["AddressMetadataMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZoneType?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZone?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryZoneAddressesByMetaUniqueWhere"]: GraphQLTypes["DeliveryZoneAddressesByMetaUniqueWhere"];
	["DeliveryZoneAddressesByGpsUniqueWhere"]: GraphQLTypes["DeliveryZoneAddressesByGpsUniqueWhere"];
	["DeliveryZoneAddressesByInvoicesUniqueWhere"]: GraphQLTypes["DeliveryZoneAddressesByInvoicesUniqueWhere"];
	["DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"]: GraphQLTypes["DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"];
	["DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: GraphQLTypes["DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"];
	["DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: GraphQLTypes["DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"];
	["DeliveryZoneAddressesMetadataByAddressUniqueWhere"]: GraphQLTypes["DeliveryZoneAddressesMetadataByAddressUniqueWhere"];
	["AddressConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AddressEdge"]>
};
	["AddressEdge"]: {
		node: GraphQLTypes["Address"]
};
	["DeliveryMethodConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodEdge"]>
};
	["DeliveryMethodEdge"]: {
		node: GraphQLTypes["DeliveryMethod"]
};
	["AddressMetadataConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AddressMetadataEdge"]>
};
	["AddressMetadataEdge"]: {
		node: GraphQLTypes["AddressMetadata"]
};
	["ChannelDeliveryOrderBy"]: GraphQLTypes["ChannelDeliveryOrderBy"];
	["ChannelOrderBy"]: GraphQLTypes["ChannelOrderBy"];
	["DeliveryMethodLocale"]: {
		_meta?: GraphQLTypes["DeliveryMethodLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	root?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryMethodLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	processingTitle?: GraphQLTypes["FieldMeta"] | undefined,
	processingText?: GraphQLTypes["FieldMeta"] | undefined,
	processingIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedTitle?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedText?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledTitle?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledText?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodLocaleOrderBy"]: GraphQLTypes["DeliveryMethodLocaleOrderBy"];
	["DeliveryTimelinePreset"]: {
		_meta?: GraphQLTypes["DeliveryTimelinePresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["DeliveryTimelineType"],
	duration?: string | undefined,
	window?: string | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryTimelinePresetMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	window?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethod?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodLocalesByLocaleUniqueWhere"]: GraphQLTypes["DeliveryMethodLocalesByLocaleUniqueWhere"];
	["PaymentMethodConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PaymentMethodEdge"]>
};
	["PaymentMethodEdge"]: {
		node: GraphQLTypes["PaymentMethod"]
};
	["DeliveryZoneConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryZoneEdge"]>
};
	["DeliveryZoneEdge"]: {
		node: GraphQLTypes["DeliveryZone"]
};
	["ChannelDeliveryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ChannelDeliveryEdge"]>
};
	["ChannelDeliveryEdge"]: {
		node: GraphQLTypes["ChannelDelivery"]
};
	["DeliveryMethodLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodLocaleEdge"]>
};
	["DeliveryMethodLocaleEdge"]: {
		node: GraphQLTypes["DeliveryMethodLocale"]
};
	["ChannelPaymentOrderBy"]: GraphQLTypes["ChannelPaymentOrderBy"];
	["ChannelPaymentConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ChannelPaymentEdge"]>
};
	["ChannelPaymentEdge"]: {
		node: GraphQLTypes["ChannelPayment"]
};
	["Cart"]: {
		_meta?: GraphQLTypes["CartMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items: Array<GraphQLTypes["CartItem"]>,
	handle?: string | undefined,
	state: GraphQLTypes["CartState"],
	createdAt: GraphQLTypes["DateTime"],
	channel?: GraphQLTypes["Channel"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	deliveryAddress?: GraphQLTypes["Address"] | undefined,
	billingAddress?: GraphQLTypes["Address"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["ChannelDelivery"] | undefined,
	payment?: GraphQLTypes["ChannelPayment"] | undefined,
	billingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	priceLines: Array<GraphQLTypes["CartPriceLine"]>,
	summary?: GraphQLTypes["CartSummary"] | undefined,
	itemsByProduct?: GraphQLTypes["CartItem"] | undefined,
	paginateItems: GraphQLTypes["CartItemConnection"],
	paginatePriceLines: GraphQLTypes["CartPriceLineConnection"]
};
	["CartMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	confirmedAt?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	handle?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	billingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryOption?: GraphQLTypes["FieldMeta"] | undefined,
	paymentOption?: GraphQLTypes["FieldMeta"] | undefined,
	delivery?: GraphQLTypes["FieldMeta"] | undefined,
	payment?: GraphQLTypes["FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["FieldMeta"] | undefined,
	priceLines?: GraphQLTypes["FieldMeta"] | undefined,
	summary?: GraphQLTypes["FieldMeta"] | undefined
};
	["CartItem"]: {
		_meta?: GraphQLTypes["CartItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	cart?: GraphQLTypes["Cart"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	fulfillmentNote?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["CartItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	cart?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	updatedAt?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNote?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined
};
	["FulfillmentNote"]: {
		_meta?: GraphQLTypes["FulfillmentNoteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	note: string,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Tag"]>,
	cartItems: Array<GraphQLTypes["CartItem"]>,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	orders: Array<GraphQLTypes["Order"]>,
	highlight: boolean,
	createdAt: GraphQLTypes["DateTime"],
	emoji?: string | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateCartItems: GraphQLTypes["CartItemConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"]
};
	["FulfillmentNoteMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	cartItems?: GraphQLTypes["FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined,
	highlight?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	emoji?: GraphQLTypes["FieldMeta"] | undefined
};
	["CartItemOrderBy"]: GraphQLTypes["CartItemOrderBy"];
	["CartOrderBy"]: GraphQLTypes["CartOrderBy"];
	["OrderOrderBy"]: GraphQLTypes["OrderOrderBy"];
	["OrderDeliveryOrderBy"]: GraphQLTypes["OrderDeliveryOrderBy"];
	["OrderSummaryOrderBy"]: GraphQLTypes["OrderSummaryOrderBy"];
	["CartSummaryOrderBy"]: GraphQLTypes["CartSummaryOrderBy"];
	["OrderItem"]: {
		_meta?: GraphQLTypes["OrderItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unitPriceCents: number,
	quantity: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	virtualProductEffects: Array<GraphQLTypes["VirtualProductEffect"]>,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	product?: GraphQLTypes["Product"] | undefined,
	virtualProductEffectsByResultVouchers?: GraphQLTypes["VirtualProductEffect"] | undefined,
	paginateVirtualProductEffects: GraphQLTypes["VirtualProductEffectConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["OrderItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	virtualProductEffects?: GraphQLTypes["FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["Order"]: {
		_meta?: GraphQLTypes["OrderMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	state: GraphQLTypes["OrderState"],
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	channel?: GraphQLTypes["Channel"] | undefined,
	discounts: Array<GraphQLTypes["OrderDiscount"]>,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined,
	items: Array<GraphQLTypes["OrderItem"]>,
	cart?: GraphQLTypes["Cart"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	billingAddress?: GraphQLTypes["Address"] | undefined,
	priceLines: Array<GraphQLTypes["OrderPriceLine"]>,
	payments: Array<GraphQLTypes["OrderPayment"]>,
	seq: number,
	billingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	summary?: GraphQLTypes["OrderSummary"] | undefined,
	doubleshotLegacyId?: string | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	discountsByVoucherRedemption?: GraphQLTypes["OrderDiscount"] | undefined,
	itemsByVirtualProductEffects?: GraphQLTypes["OrderItem"] | undefined,
	priceLinesByOrderItem?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderDiscount?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderPayment?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderDelivery?: GraphQLTypes["OrderPriceLine"] | undefined,
	paymentsByMeta?: GraphQLTypes["OrderPayment"] | undefined,
	paginateDiscounts: GraphQLTypes["OrderDiscountConnection"],
	paginateItems: GraphQLTypes["OrderItemConnection"],
	paginatePriceLines: GraphQLTypes["OrderPriceLineConnection"],
	paginatePayments: GraphQLTypes["OrderPaymentConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["OrderMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledAt?: GraphQLTypes["FieldMeta"] | undefined,
	canceledAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	discounts?: GraphQLTypes["FieldMeta"] | undefined,
	delivery?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	cart?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	billingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	priceLines?: GraphQLTypes["FieldMeta"] | undefined,
	payments?: GraphQLTypes["FieldMeta"] | undefined,
	seq?: GraphQLTypes["FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["FieldMeta"] | undefined,
	summary?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNote?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["FieldMeta"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderDiscount"]: {
		_meta?: GraphQLTypes["OrderDiscountMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	discountCents: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	description?: string | undefined,
	type: GraphQLTypes["OrderDiscountType"],
	voucherRedemption?: GraphQLTypes["VoucherRedemption"] | undefined
};
	["OrderDiscountMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	discountCents?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	voucherRedemption?: GraphQLTypes["FieldMeta"] | undefined
};
	["VoucherRedemption"]: {
		_meta?: GraphQLTypes["VoucherRedemptionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	customerCredit?: GraphQLTypes["CustomerCredit"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	voucher?: GraphQLTypes["Voucher"] | undefined,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["VoucherRedemptionMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	manualRedemptionNote?: GraphQLTypes["FieldMeta"] | undefined,
	customerCredit?: GraphQLTypes["FieldMeta"] | undefined,
	orderDiscount?: GraphQLTypes["FieldMeta"] | undefined,
	voucher?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerCredit"]: {
		_meta?: GraphQLTypes["CustomerCreditMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	initialCreditCents: number,
	remainingCreditsCents: number,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	reason?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	voucherRedemption?: GraphQLTypes["VoucherRedemption"] | undefined,
	invoiceUrl?: string | undefined
};
	["CustomerCreditMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	initialCreditCents?: GraphQLTypes["FieldMeta"] | undefined,
	remainingCreditsCents?: GraphQLTypes["FieldMeta"] | undefined,
	expiresAt?: GraphQLTypes["FieldMeta"] | undefined,
	reason?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	voucherRedemption?: GraphQLTypes["FieldMeta"] | undefined,
	invoiceUrl?: GraphQLTypes["FieldMeta"] | undefined
};
	["Voucher"]: {
		_meta?: GraphQLTypes["VoucherMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	code?: string | undefined,
	type?: GraphQLTypes["VoucherType"] | undefined,
	creditCents?: number | undefined,
	buyer?: GraphQLTypes["Customer"] | undefined,
	owner?: GraphQLTypes["Customer"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	virtualProductEffect?: GraphQLTypes["VirtualProductEffect"] | undefined,
	redemption?: GraphQLTypes["VoucherRedemption"] | undefined
};
	["VoucherMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	activatedAt?: GraphQLTypes["FieldMeta"] | undefined,
	expiresAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	creditCents?: GraphQLTypes["FieldMeta"] | undefined,
	buyer?: GraphQLTypes["FieldMeta"] | undefined,
	owner?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	virtualProductEffect?: GraphQLTypes["FieldMeta"] | undefined,
	redemption?: GraphQLTypes["FieldMeta"] | undefined
};
	["VirtualProductEffect"]: {
		_meta?: GraphQLTypes["VirtualProductEffectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	succeededAt?: GraphQLTypes["DateTime"] | undefined,
	orderItem?: GraphQLTypes["OrderItem"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	revertedAt?: GraphQLTypes["DateTime"] | undefined,
	resultVouchers: Array<GraphQLTypes["Voucher"]>,
	resultVouchersByCode?: GraphQLTypes["Voucher"] | undefined,
	resultVouchersByRedemption?: GraphQLTypes["Voucher"] | undefined,
	paginateResultVouchers: GraphQLTypes["VoucherConnection"]
};
	["VirtualProductEffectMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	succeededAt?: GraphQLTypes["FieldMeta"] | undefined,
	orderItem?: GraphQLTypes["FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["FieldMeta"] | undefined,
	revertedAt?: GraphQLTypes["FieldMeta"] | undefined,
	resultVouchers?: GraphQLTypes["FieldMeta"] | undefined
};
	["VoucherOrderBy"]: GraphQLTypes["VoucherOrderBy"];
	["VirtualProductEffectOrderBy"]: GraphQLTypes["VirtualProductEffectOrderBy"];
	["OrderItemOrderBy"]: GraphQLTypes["OrderItemOrderBy"];
	["VoucherRedemptionOrderBy"]: GraphQLTypes["VoucherRedemptionOrderBy"];
	["CustomerCreditOrderBy"]: GraphQLTypes["CustomerCreditOrderBy"];
	["OrderDiscountOrderBy"]: GraphQLTypes["OrderDiscountOrderBy"];
	["VirtualProductEffectResultVouchersByCodeUniqueWhere"]: GraphQLTypes["VirtualProductEffectResultVouchersByCodeUniqueWhere"];
	["VirtualProductEffectResultVouchersByRedemptionUniqueWhere"]: GraphQLTypes["VirtualProductEffectResultVouchersByRedemptionUniqueWhere"];
	["VoucherConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VoucherEdge"]>
};
	["VoucherEdge"]: {
		node: GraphQLTypes["Voucher"]
};
	["OrderDelivery"]: {
		_meta?: GraphQLTypes["OrderDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	priceCents: number,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["DeliveryMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["OrderDeliveryState"],
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	trackingCode?: string | undefined
};
	["OrderDeliveryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	deliveredAt?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	expectedAt?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	loadingDurationHours?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchAt?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	window?: GraphQLTypes["FieldMeta"] | undefined,
	expectedEndAt?: GraphQLTypes["FieldMeta"] | undefined,
	processingAt?: GraphQLTypes["FieldMeta"] | undefined,
	deliveringAt?: GraphQLTypes["FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	prepareAt?: GraphQLTypes["FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	trackingCode?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPriceLine"]: {
		_meta?: GraphQLTypes["OrderPriceLineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unitPriceCents: number,
	quantity: number,
	type?: GraphQLTypes["OrderPriceLineType"] | undefined,
	orderItem?: GraphQLTypes["OrderItem"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	orderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	orderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	vatRatePermille: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined
};
	["OrderPriceLineMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	orderItem?: GraphQLTypes["FieldMeta"] | undefined,
	orderDiscount?: GraphQLTypes["FieldMeta"] | undefined,
	orderPayment?: GraphQLTypes["FieldMeta"] | undefined,
	orderDelivery?: GraphQLTypes["FieldMeta"] | undefined,
	vatRatePermille?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPayment"]: {
		_meta?: GraphQLTypes["OrderPaymentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	data?: GraphQLTypes["Json"] | undefined,
	paymentCents: number,
	method?: GraphQLTypes["PaymentMethod"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["OrderPaymentState"],
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	invoice?: GraphQLTypes["Invoice"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	meta?: GraphQLTypes["OrderPaymentMetadata"] | undefined,
	otherCurrencyPriceCents?: string | undefined
};
	["OrderPaymentMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	data?: GraphQLTypes["FieldMeta"] | undefined,
	paymentCents?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	approvedAt?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	invoice?: GraphQLTypes["FieldMeta"] | undefined,
	walletType?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	publicReceiptUrl?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["FieldMeta"] | undefined
};
	["Json"]:any;
	["Invoice"]: {
		_meta?: GraphQLTypes["InvoiceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	publicKey: string,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	orderPayments: Array<GraphQLTypes["OrderPayment"]>,
	address?: GraphQLTypes["Address"] | undefined,
	publicUrl?: string | undefined,
	billingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	orderPaymentsByMeta?: GraphQLTypes["OrderPayment"] | undefined,
	paginateOrderPayments: GraphQLTypes["OrderPaymentConnection"]
};
	["InvoiceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	publicKey?: GraphQLTypes["FieldMeta"] | undefined,
	fakturoidId?: GraphQLTypes["FieldMeta"] | undefined,
	fakturoidData?: GraphQLTypes["FieldMeta"] | undefined,
	legacyUrl?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	orderPayments?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	publicUrl?: GraphQLTypes["FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPaymentOrderBy"]: GraphQLTypes["OrderPaymentOrderBy"];
	["InvoiceOrderBy"]: GraphQLTypes["InvoiceOrderBy"];
	["OrderPaymentMetadataOrderBy"]: GraphQLTypes["OrderPaymentMetadataOrderBy"];
	["BillingSubject"]: {
		_meta?: GraphQLTypes["BillingSubjectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden: boolean,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Country"] | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	invoices: Array<GraphQLTypes["Invoice"]>,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Customer"] | undefined,
	orders: Array<GraphQLTypes["Order"]>,
	invoicesByPublicKey?: GraphQLTypes["Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Invoice"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDiscounts?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByCart?: GraphQLTypes["Order"] | undefined,
	ordersByPriceLines?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	ordersBySummary?: GraphQLTypes["Order"] | undefined,
	paginateInvoices: GraphQLTypes["InvoiceConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"]
};
	["BillingSubjectMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	fullName?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine1?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine2?: GraphQLTypes["FieldMeta"] | undefined,
	city?: GraphQLTypes["FieldMeta"] | undefined,
	postalCode?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	companyName?: GraphQLTypes["FieldMeta"] | undefined,
	companyIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	vatIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	externalIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	hidden?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["FieldMeta"] | undefined,
	country?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	invoices?: GraphQLTypes["FieldMeta"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined
};
	["Country"]: {
		_meta?: GraphQLTypes["CountryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	order: number
};
	["CountryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["BillingSubjectInvoicesByPublicKeyUniqueWhere"]: GraphQLTypes["BillingSubjectInvoicesByPublicKeyUniqueWhere"];
	["BillingSubjectInvoicesByFakturoidIdUniqueWhere"]: GraphQLTypes["BillingSubjectInvoicesByFakturoidIdUniqueWhere"];
	["BillingSubjectInvoicesByOrderPaymentsUniqueWhere"]: GraphQLTypes["BillingSubjectInvoicesByOrderPaymentsUniqueWhere"];
	["BillingSubjectOrdersBySeqUniqueWhere"]: GraphQLTypes["BillingSubjectOrdersBySeqUniqueWhere"];
	["BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere"];
	["BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere"]: GraphQLTypes["BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere"];
	["BillingSubjectOrdersByDiscountsUniqueWhere"]: GraphQLTypes["BillingSubjectOrdersByDiscountsUniqueWhere"];
	["BillingSubjectOrdersByDeliveryUniqueWhere"]: GraphQLTypes["BillingSubjectOrdersByDeliveryUniqueWhere"];
	["BillingSubjectOrdersByItemsUniqueWhere"]: GraphQLTypes["BillingSubjectOrdersByItemsUniqueWhere"];
	["BillingSubjectOrdersByCartUniqueWhere"]: GraphQLTypes["BillingSubjectOrdersByCartUniqueWhere"];
	["BillingSubjectOrdersByPriceLinesUniqueWhere"]: GraphQLTypes["BillingSubjectOrdersByPriceLinesUniqueWhere"];
	["BillingSubjectOrdersByPaymentsUniqueWhere"]: GraphQLTypes["BillingSubjectOrdersByPaymentsUniqueWhere"];
	["BillingSubjectOrdersBySummaryUniqueWhere"]: GraphQLTypes["BillingSubjectOrdersBySummaryUniqueWhere"];
	["InvoiceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["InvoiceEdge"]>
};
	["InvoiceEdge"]: {
		node: GraphQLTypes["Invoice"]
};
	["OrderConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderEdge"]>
};
	["OrderEdge"]: {
		node: GraphQLTypes["Order"]
};
	["InvoiceOrderPaymentsByMetaUniqueWhere"]: GraphQLTypes["InvoiceOrderPaymentsByMetaUniqueWhere"];
	["OrderPaymentConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPaymentEdge"]>
};
	["OrderPaymentEdge"]: {
		node: GraphQLTypes["OrderPayment"]
};
	["OrderPaymentMetadata"]: {
		_meta?: GraphQLTypes["OrderPaymentMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	paymentUrl: string,
	orderPayment?: GraphQLTypes["OrderPayment"] | undefined
};
	["OrderPaymentMetadataMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	paymentUrl?: GraphQLTypes["FieldMeta"] | undefined,
	orderPayment?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPriceLineOrderBy"]: GraphQLTypes["OrderPriceLineOrderBy"];
	["OrderSummary"]: {
		_meta?: GraphQLTypes["OrderSummaryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	totalPriceCents: number,
	discountPriceCents: number,
	undiscountedPriceCents: number,
	itemsQuantity: number,
	orderUrl: string,
	orderNumber: string,
	order?: GraphQLTypes["Order"] | undefined
};
	["OrderSummaryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	totalPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	discountPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	undiscountedPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	itemsQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	orderUrl?: GraphQLTypes["FieldMeta"] | undefined,
	orderNumber?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["FulfillmentNoteOrderBy"]: GraphQLTypes["FulfillmentNoteOrderBy"];
	["OrderDiscountsByVoucherRedemptionUniqueWhere"]: GraphQLTypes["OrderDiscountsByVoucherRedemptionUniqueWhere"];
	["OrderItemsByVirtualProductEffectsUniqueWhere"]: GraphQLTypes["OrderItemsByVirtualProductEffectsUniqueWhere"];
	["OrderPriceLinesByOrderItemUniqueWhere"]: GraphQLTypes["OrderPriceLinesByOrderItemUniqueWhere"];
	["OrderPriceLinesByOrderDiscountUniqueWhere"]: GraphQLTypes["OrderPriceLinesByOrderDiscountUniqueWhere"];
	["OrderPriceLinesByOrderPaymentUniqueWhere"]: GraphQLTypes["OrderPriceLinesByOrderPaymentUniqueWhere"];
	["OrderPriceLinesByOrderDeliveryUniqueWhere"]: GraphQLTypes["OrderPriceLinesByOrderDeliveryUniqueWhere"];
	["OrderPaymentsByMetaUniqueWhere"]: GraphQLTypes["OrderPaymentsByMetaUniqueWhere"];
	["OrderDiscountConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderDiscountEdge"]>
};
	["OrderDiscountEdge"]: {
		node: GraphQLTypes["OrderDiscount"]
};
	["OrderItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderItemEdge"]>
};
	["OrderItemEdge"]: {
		node: GraphQLTypes["OrderItem"]
};
	["OrderPriceLineConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPriceLineEdge"]>
};
	["OrderPriceLineEdge"]: {
		node: GraphQLTypes["OrderPriceLine"]
};
	["FulfillmentNoteConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FulfillmentNoteEdge"]>
};
	["FulfillmentNoteEdge"]: {
		node: GraphQLTypes["FulfillmentNote"]
};
	["OrderItemVirtualProductEffectsByResultVouchersUniqueWhere"]: GraphQLTypes["OrderItemVirtualProductEffectsByResultVouchersUniqueWhere"];
	["VirtualProductEffectConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VirtualProductEffectEdge"]>
};
	["VirtualProductEffectEdge"]: {
		node: GraphQLTypes["VirtualProductEffect"]
};
	["CartItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CartItemEdge"]>
};
	["CartItemEdge"]: {
		node: GraphQLTypes["CartItem"]
};
	["CartPriceLine"]: {
		_meta?: GraphQLTypes["CartPriceLineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["CartPriceLineType"] | undefined,
	item?: GraphQLTypes["CartItem"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	unitPriceCents: number,
	priceCents: number,
	quantity: number,
	vatRatePermille: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	cart?: GraphQLTypes["Cart"] | undefined
};
	["CartPriceLineMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	vatRatePermille?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	cart?: GraphQLTypes["FieldMeta"] | undefined
};
	["CartPriceLineOrderBy"]: GraphQLTypes["CartPriceLineOrderBy"];
	["CartSummary"]: {
		_meta?: GraphQLTypes["CartSummaryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	itemsPriceCents: number,
	deliveryPriceCents: number,
	totalPriceCents: number,
	itemsCount: number,
	itemsQuantity: number,
	checkoutUrl: string,
	cart?: GraphQLTypes["Cart"] | undefined
};
	["CartSummaryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	itemsPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	totalPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	itemsCount?: GraphQLTypes["FieldMeta"] | undefined,
	itemsQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	checkoutUrl?: GraphQLTypes["FieldMeta"] | undefined,
	cart?: GraphQLTypes["FieldMeta"] | undefined
};
	["CartItemsByProductUniqueWhere"]: GraphQLTypes["CartItemsByProductUniqueWhere"];
	["CartPriceLineConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CartPriceLineEdge"]>
};
	["CartPriceLineEdge"]: {
		node: GraphQLTypes["CartPriceLine"]
};
	["ChannelCartsByItemsUniqueWhere"]: GraphQLTypes["ChannelCartsByItemsUniqueWhere"];
	["ChannelCartsByOrderUniqueWhere"]: GraphQLTypes["ChannelCartsByOrderUniqueWhere"];
	["ChannelCartsByPriceLinesUniqueWhere"]: GraphQLTypes["ChannelCartsByPriceLinesUniqueWhere"];
	["ChannelCartsBySummaryUniqueWhere"]: GraphQLTypes["ChannelCartsBySummaryUniqueWhere"];
	["CartConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CartEdge"]>
};
	["CartEdge"]: {
		node: GraphQLTypes["Cart"]
};
	["FreshingContainerConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FreshingContainerEdge"]>
};
	["FreshingContainerEdge"]: {
		node: GraphQLTypes["FreshingContainer"]
};
	["ProductIngredientLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductIngredientLocalesByLocaleUniqueWhere"];
	["ProductIngredientCustomerRatingsByCustomerUniqueWhere"]: GraphQLTypes["ProductIngredientCustomerRatingsByCustomerUniqueWhere"];
	["ProductIngredientLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientLocaleEdge"]>
};
	["ProductIngredientLocaleEdge"]: {
		node: GraphQLTypes["ProductIngredientLocale"]
};
	["AllergenConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenEdge"]>
};
	["AllergenEdge"]: {
		node: GraphQLTypes["Allergen"]
};
	["CustomerIngredientRatingConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerIngredientRatingEdge"]>
};
	["CustomerIngredientRatingEdge"]: {
		node: GraphQLTypes["CustomerIngredientRating"]
};
	["BlogLocale"]: {
		_meta?: GraphQLTypes["BlogLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	pageName?: string | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	root?: GraphQLTypes["Blog"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["BlogLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	pageName?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["Blog"]: {
		_meta?: GraphQLTypes["BlogMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["BlogLocale"]>,
	localesByLocale?: GraphQLTypes["BlogLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogLocaleConnection"]
};
	["BlogMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogLocaleOrderBy"]: GraphQLTypes["BlogLocaleOrderBy"];
	["BlogOrderBy"]: GraphQLTypes["BlogOrderBy"];
	["BlogLocalesByLocaleUniqueWhere"]: GraphQLTypes["BlogLocalesByLocaleUniqueWhere"];
	["BlogLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogLocaleEdge"]>
};
	["BlogLocaleEdge"]: {
		node: GraphQLTypes["BlogLocale"]
};
	["BlogPostLocale"]: {
		_meta?: GraphQLTypes["BlogPostLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["BlogPost"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	products: Array<GraphQLTypes["Product"]>,
	paginateProducts: GraphQLTypes["ProductConnection"]
};
	["BlogPostLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	availableForWeb?: GraphQLTypes["FieldMeta"] | undefined,
	availableForMobile?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPost"]: {
		_meta?: GraphQLTypes["BlogPostMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	locales: Array<GraphQLTypes["BlogPostLocale"]>,
	localesByLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogPostLocaleConnection"]
};
	["BlogPostMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostLocaleOrderBy"]: GraphQLTypes["BlogPostLocaleOrderBy"];
	["BlogPostOrderBy"]: GraphQLTypes["BlogPostOrderBy"];
	["CoverOrderBy"]: GraphQLTypes["CoverOrderBy"];
	["MediumOrderBy"]: GraphQLTypes["MediumOrderBy"];
	["VideoOrderBy"]: GraphQLTypes["VideoOrderBy"];
	["ColorPalleteOrderBy"]: GraphQLTypes["ColorPalleteOrderBy"];
	["ContentOrderBy"]: GraphQLTypes["ContentOrderBy"];
	["LinkableOrderBy"]: GraphQLTypes["LinkableOrderBy"];
	["PageLocaleOrderBy"]: GraphQLTypes["PageLocaleOrderBy"];
	["SeoOrderBy"]: GraphQLTypes["SeoOrderBy"];
	["RedirectOrderBy"]: GraphQLTypes["RedirectOrderBy"];
	["LinkOrderBy"]: GraphQLTypes["LinkOrderBy"];
	["BlogPostLocalesByLocaleUniqueWhere"]: GraphQLTypes["BlogPostLocalesByLocaleUniqueWhere"];
	["BlogPostLocalesByCoverUniqueWhere"]: GraphQLTypes["BlogPostLocalesByCoverUniqueWhere"];
	["BlogPostLocalesByContentUniqueWhere"]: GraphQLTypes["BlogPostLocalesByContentUniqueWhere"];
	["BlogPostLocalesByLinkUniqueWhere"]: GraphQLTypes["BlogPostLocalesByLinkUniqueWhere"];
	["BlogPostLocalesBySeoUniqueWhere"]: GraphQLTypes["BlogPostLocalesBySeoUniqueWhere"];
	["BlogPostLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostLocaleEdge"]>
};
	["BlogPostLocaleEdge"]: {
		node: GraphQLTypes["BlogPostLocale"]
};
	["Cover"]: {
		_meta?: GraphQLTypes["CoverMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	primaryTitle?: string | undefined,
	secondaryTitle?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	buttonColors?: GraphQLTypes["ColorPallete"] | undefined
};
	["CoverMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	primaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	buttonColors?: GraphQLTypes["FieldMeta"] | undefined
};
	["Medium"]: {
		_meta?: GraphQLTypes["MediumMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["MediumType"] | undefined,
	colorTheme?: GraphQLTypes["ColorTheme"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	video?: GraphQLTypes["Video"] | undefined
};
	["MediumMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	colorTheme?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	video?: GraphQLTypes["FieldMeta"] | undefined
};
	["Video"]: {
		_meta?: GraphQLTypes["VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Image"] | undefined
};
	["VideoMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	src?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	poster?: GraphQLTypes["FieldMeta"] | undefined
};
	["ColorPallete"]: {
		_meta?: GraphQLTypes["ColorPalleteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	background?: string | undefined,
	text?: string | undefined
};
	["ColorPalleteMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	background?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined
};
	["Content"]: {
		_meta?: GraphQLTypes["ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	blocks?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
		_meta?: GraphQLTypes["ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Content"] | undefined,
	references: Array<GraphQLTypes["ContentReference"]>,
	referencesByProducts?: GraphQLTypes["ContentReference"] | undefined,
	referencesByProductBanners?: GraphQLTypes["ContentReference"] | undefined,
	referencesByDeliveryRegions?: GraphQLTypes["ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	json?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	references?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
		_meta?: GraphQLTypes["ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["ContentReferenceType"],
	primaryText?: string | undefined,
	secondaryText?: string | undefined,
	jsonContent?: string | undefined,
	block?: GraphQLTypes["ContentBlock"] | undefined,
	colorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostList"] | undefined,
	links?: GraphQLTypes["LinkList"] | undefined,
	products?: GraphQLTypes["ProductList"] | undefined,
	contentSize?: GraphQLTypes["ContentSize"] | undefined,
	misc?: string | undefined,
	hero?: GraphQLTypes["Hero"] | undefined,
	gallery?: GraphQLTypes["Gallery"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	productGrid?: GraphQLTypes["ProductGrid"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerList"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	gridTiles?: GraphQLTypes["GridTiles"] | undefined
};
	["ContentReferenceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["FieldMeta"] | undefined,
	jsonContent?: GraphQLTypes["FieldMeta"] | undefined,
	block?: GraphQLTypes["FieldMeta"] | undefined,
	colorPallete?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	contentSize?: GraphQLTypes["FieldMeta"] | undefined,
	misc?: GraphQLTypes["FieldMeta"] | undefined,
	hero?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["FieldMeta"] | undefined,
	productGrid?: GraphQLTypes["FieldMeta"] | undefined,
	productBanners?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	gridTiles?: GraphQLTypes["FieldMeta"] | undefined
};
	["Link"]: {
		_meta?: GraphQLTypes["LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Linkable"] | undefined
};
	["LinkMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
		_meta?: GraphQLTypes["LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined,
	page?: GraphQLTypes["PageLocale"] | undefined,
	redirect?: GraphQLTypes["Redirect"] | undefined
};
	["LinkableMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined,
	redirect?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocale"]: {
		_meta?: GraphQLTypes["PageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	root?: GraphQLTypes["Page"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	theme?: GraphQLTypes["PageTheme"] | undefined
};
	["PageLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined
};
	["Page"]: {
		_meta?: GraphQLTypes["PageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["PageLocale"]>,
	localesByLocale?: GraphQLTypes["PageLocale"] | undefined,
	localesByLink?: GraphQLTypes["PageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	localesByContent?: GraphQLTypes["PageLocale"] | undefined,
	localesByCover?: GraphQLTypes["PageLocale"] | undefined,
	paginateLocales: GraphQLTypes["PageLocaleConnection"]
};
	["PageMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocalesByLocaleUniqueWhere"]: GraphQLTypes["PageLocalesByLocaleUniqueWhere"];
	["PageLocalesByLinkUniqueWhere"]: GraphQLTypes["PageLocalesByLinkUniqueWhere"];
	["PageLocalesBySeoUniqueWhere"]: GraphQLTypes["PageLocalesBySeoUniqueWhere"];
	["PageLocalesByContentUniqueWhere"]: GraphQLTypes["PageLocalesByContentUniqueWhere"];
	["PageLocalesByCoverUniqueWhere"]: GraphQLTypes["PageLocalesByCoverUniqueWhere"];
	["PageLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageLocaleEdge"]>
};
	["PageLocaleEdge"]: {
		node: GraphQLTypes["PageLocale"]
};
	["Seo"]: {
		_meta?: GraphQLTypes["SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Image"] | undefined
};
	["SeoMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["Redirect"]: {
		_meta?: GraphQLTypes["RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"] | undefined,
	target?: GraphQLTypes["Link"] | undefined
};
	["RedirectMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	target?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostList"]: {
		_meta?: GraphQLTypes["BlogPostListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateItems: GraphQLTypes["BlogPostListItemConnection"]
};
	["BlogPostListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItem"]: {
		_meta?: GraphQLTypes["BlogPostListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["BlogPostList"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined
};
	["BlogPostListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItemOrderBy"]: GraphQLTypes["BlogPostListItemOrderBy"];
	["BlogPostListOrderBy"]: GraphQLTypes["BlogPostListOrderBy"];
	["BlogPostListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListItemEdge"]>
};
	["BlogPostListItemEdge"]: {
		node: GraphQLTypes["BlogPostListItem"]
};
	["LinkList"]: {
		_meta?: GraphQLTypes["LinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["LinkListItem"]>,
	paginateItems: GraphQLTypes["LinkListItemConnection"]
};
	["LinkListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItem"]: {
		_meta?: GraphQLTypes["LinkListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	isPrimary?: boolean | undefined,
	list?: GraphQLTypes["LinkList"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["LinkListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	isPrimary?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItemOrderBy"]: GraphQLTypes["LinkListItemOrderBy"];
	["LinkListOrderBy"]: GraphQLTypes["LinkListOrderBy"];
	["LinkListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListItemEdge"]>
};
	["LinkListItemEdge"]: {
		node: GraphQLTypes["LinkListItem"]
};
	["Hero"]: {
		_meta?: GraphQLTypes["HeroMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	showLogo?: boolean | undefined,
	middleContent?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["HeroMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	showLogo?: GraphQLTypes["FieldMeta"] | undefined,
	middleContent?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["Gallery"]: {
		_meta?: GraphQLTypes["GalleryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GalleryItem"]>,
	paginateItems: GraphQLTypes["GalleryItemConnection"]
};
	["GalleryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItem"]: {
		_meta?: GraphQLTypes["GalleryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["Gallery"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["GalleryItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItemOrderBy"]: GraphQLTypes["GalleryItemOrderBy"];
	["GalleryOrderBy"]: GraphQLTypes["GalleryOrderBy"];
	["GalleryItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryItemEdge"]>
};
	["GalleryItemEdge"]: {
		node: GraphQLTypes["GalleryItem"]
};
	["SocialsAndApps"]: {
		_meta?: GraphQLTypes["SocialsAndAppsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	instagram?: boolean | undefined,
	appStore?: boolean | undefined,
	googlePlay?: boolean | undefined
};
	["SocialsAndAppsMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	instagram?: GraphQLTypes["FieldMeta"] | undefined,
	appStore?: GraphQLTypes["FieldMeta"] | undefined,
	googlePlay?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGrid"]: {
		_meta?: GraphQLTypes["ProductGridMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isCarousel?: boolean | undefined,
	categories: Array<GraphQLTypes["ProductGridCategory"]>,
	type?: GraphQLTypes["ProductGridType"] | undefined,
	categoriesByItems?: GraphQLTypes["ProductGridCategory"] | undefined,
	categoriesByImages?: GraphQLTypes["ProductGridCategory"] | undefined,
	paginateCategories: GraphQLTypes["ProductGridCategoryConnection"]
};
	["ProductGridMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	isCarousel?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridCategory"]: {
		_meta?: GraphQLTypes["ProductGridCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	root?: GraphQLTypes["ProductGrid"] | undefined,
	items: Array<GraphQLTypes["ProductGridItem"]>,
	images?: GraphQLTypes["ImageList"] | undefined,
	paginateItems: GraphQLTypes["ProductGridItemConnection"]
};
	["ProductGridCategoryMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	images?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItem"]: {
		_meta?: GraphQLTypes["ProductGridItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	lead?: string | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	category?: GraphQLTypes["ProductGridCategory"] | undefined,
	description?: string | undefined
};
	["ProductGridItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	category?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItemOrderBy"]: GraphQLTypes["ProductGridItemOrderBy"];
	["ProductGridCategoryOrderBy"]: GraphQLTypes["ProductGridCategoryOrderBy"];
	["ProductGridOrderBy"]: GraphQLTypes["ProductGridOrderBy"];
	["ImageListOrderBy"]: GraphQLTypes["ImageListOrderBy"];
	["ImageList"]: {
		_meta?: GraphQLTypes["ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ImageListItem"]>,
	itemsByImage?: GraphQLTypes["ImageListItem"] | undefined,
	paginateItems: GraphQLTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
		_meta?: GraphQLTypes["ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ImageList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItemOrderBy"]: GraphQLTypes["ImageListItemOrderBy"];
	["ImageListItemsByImageUniqueWhere"]: GraphQLTypes["ImageListItemsByImageUniqueWhere"];
	["ImageListItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
		node: GraphQLTypes["ImageListItem"]
};
	["ProductGridItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridItemEdge"]>
};
	["ProductGridItemEdge"]: {
		node: GraphQLTypes["ProductGridItem"]
};
	["ProductGridCategoriesByItemsUniqueWhere"]: GraphQLTypes["ProductGridCategoriesByItemsUniqueWhere"];
	["ProductGridItemUniqueWhere"]: GraphQLTypes["ProductGridItemUniqueWhere"];
	["ProductGridCategoriesByImagesUniqueWhere"]: GraphQLTypes["ProductGridCategoriesByImagesUniqueWhere"];
	["ProductGridCategoryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridCategoryEdge"]>
};
	["ProductGridCategoryEdge"]: {
		node: GraphQLTypes["ProductGridCategory"]
};
	["ProductBannerList"]: {
		_meta?: GraphQLTypes["ProductBannerListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateItems: GraphQLTypes["ProductBannerItemConnection"]
};
	["ProductBannerListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItem"]: {
		_meta?: GraphQLTypes["ProductBannerItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductBannerList"] | undefined,
	banner?: GraphQLTypes["ProductBanner"] | undefined,
	size?: GraphQLTypes["ProductBannerItemSize"] | undefined
};
	["ProductBannerItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	banner?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBanner"]: {
		_meta?: GraphQLTypes["ProductBannerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	beforeTitle?: string | undefined,
	title?: string | undefined,
	label?: string | undefined,
	button?: GraphQLTypes["Link"] | undefined,
	backgroundImage?: GraphQLTypes["Image"] | undefined,
	productImage?: GraphQLTypes["Image"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductBannerMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	beforeTitle?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	button?: GraphQLTypes["FieldMeta"] | undefined,
	backgroundImage?: GraphQLTypes["FieldMeta"] | undefined,
	productImage?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItemOrderBy"]: GraphQLTypes["ProductBannerItemOrderBy"];
	["ProductBannerListOrderBy"]: GraphQLTypes["ProductBannerListOrderBy"];
	["ProductBannerOrderBy"]: GraphQLTypes["ProductBannerOrderBy"];
	["ProductBannerItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerItemEdge"]>
};
	["ProductBannerItemEdge"]: {
		node: GraphQLTypes["ProductBannerItem"]
};
	["DeliveryRegions"]: {
		_meta?: GraphQLTypes["DeliveryRegionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	itemsByImage?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByTextList?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByGallery?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	paginateItems: GraphQLTypes["DeliveryRegionsItemConnection"]
};
	["DeliveryRegionsMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryRegionsItem"]: {
		_meta?: GraphQLTypes["DeliveryRegionsItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	offerLabel?: string | undefined,
	list?: GraphQLTypes["DeliveryRegions"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	gallery?: GraphQLTypes["ImageList"] | undefined
};
	["DeliveryRegionsItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	offerLabel?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextList"]: {
		_meta?: GraphQLTypes["TextListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TextItem"]>,
	paginateItems: GraphQLTypes["TextItemConnection"]
};
	["TextListMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItem"]: {
		_meta?: GraphQLTypes["TextItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["TextList"] | undefined,
	title?: string | undefined
};
	["TextItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItemOrderBy"]: GraphQLTypes["TextItemOrderBy"];
	["TextListOrderBy"]: GraphQLTypes["TextListOrderBy"];
	["TextItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextItemEdge"]>
};
	["TextItemEdge"]: {
		node: GraphQLTypes["TextItem"]
};
	["DeliveryRegionsItemOrderBy"]: GraphQLTypes["DeliveryRegionsItemOrderBy"];
	["DeliveryRegionsOrderBy"]: GraphQLTypes["DeliveryRegionsOrderBy"];
	["DeliveryRegionsItemsByImageUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemsByImageUniqueWhere"];
	["DeliveryRegionsItemsByTextListUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemsByTextListUniqueWhere"];
	["DeliveryRegionsItemsByGalleryUniqueWhere"]: GraphQLTypes["DeliveryRegionsItemsByGalleryUniqueWhere"];
	["DeliveryRegionsItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsItemEdge"]>
};
	["DeliveryRegionsItemEdge"]: {
		node: GraphQLTypes["DeliveryRegionsItem"]
};
	["GridTiles"]: {
		_meta?: GraphQLTypes["GridTilesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GridTileItem"]>,
	itemsByLink?: GraphQLTypes["GridTileItem"] | undefined,
	paginateItems: GraphQLTypes["GridTileItemConnection"]
};
	["GridTilesMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GridTileItem"]: {
		_meta?: GraphQLTypes["GridTileItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["GridTiles"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["GridTileItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["GridTileItemOrderBy"]: GraphQLTypes["GridTileItemOrderBy"];
	["GridTilesOrderBy"]: GraphQLTypes["GridTilesOrderBy"];
	["GridTilesItemsByLinkUniqueWhere"]: GraphQLTypes["GridTilesItemsByLinkUniqueWhere"];
	["LinkUniqueWhere"]: GraphQLTypes["LinkUniqueWhere"];
	["GridTileItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GridTileItemEdge"]>
};
	["GridTileItemEdge"]: {
		node: GraphQLTypes["GridTileItem"]
};
	["ContentReferenceOrderBy"]: GraphQLTypes["ContentReferenceOrderBy"];
	["ContentBlockOrderBy"]: GraphQLTypes["ContentBlockOrderBy"];
	["HeroOrderBy"]: GraphQLTypes["HeroOrderBy"];
	["SocialsAndAppsOrderBy"]: GraphQLTypes["SocialsAndAppsOrderBy"];
	["ContentBlockReferencesByProductsUniqueWhere"]: GraphQLTypes["ContentBlockReferencesByProductsUniqueWhere"];
	["ContentBlockReferencesByProductBannersUniqueWhere"]: GraphQLTypes["ContentBlockReferencesByProductBannersUniqueWhere"];
	["ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: GraphQLTypes["ContentBlockReferencesByDeliveryRegionsUniqueWhere"];
	["ContentReferenceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
		node: GraphQLTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: GraphQLTypes["ContentBlocksByReferencesUniqueWhere"];
	["ContentBlockConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
		node: GraphQLTypes["ContentBlock"]
};
	["TranslationCataloguesIdentifier"]: {
		_meta?: GraphQLTypes["TranslationCataloguesIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	catalogueByDomain?: GraphQLTypes["TranslationCatalogue"] | undefined,
	catalogueByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	paginateCatalogue: GraphQLTypes["TranslationCatalogueConnection"]
};
	["TranslationCataloguesIdentifierMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogue"]: {
		_meta?: GraphQLTypes["TranslationCatalogueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogue"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	valuesByKey?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationCatalogueMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	fallback?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationDomain"]: {
		_meta?: GraphQLTypes["TranslationDomainMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<GraphQLTypes["TranslationCatalogue"]>,
	keys: Array<GraphQLTypes["TranslationKey"]>,
	cataloguesByIdentifier?: GraphQLTypes["TranslationCatalogue"] | undefined,
	cataloguesByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	keysByIdentifier?: GraphQLTypes["TranslationKey"] | undefined,
	keysByValues?: GraphQLTypes["TranslationKey"] | undefined,
	paginateCatalogues: GraphQLTypes["TranslationCatalogueConnection"],
	paginateKeys: GraphQLTypes["TranslationKeyConnection"]
};
	["TranslationDomainMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	catalogues?: GraphQLTypes["FieldMeta"] | undefined,
	keys?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogueOrderBy"]: GraphQLTypes["TranslationCatalogueOrderBy"];
	["TranslationDomainOrderBy"]: GraphQLTypes["TranslationDomainOrderBy"];
	["TranslationKey"]: {
		_meta?: GraphQLTypes["TranslationKeyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	contentType: GraphQLTypes["TranslationContentType"],
	note?: string | undefined,
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	valuesByCatalogue?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationKeyMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	contentType?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValue"]: {
		_meta?: GraphQLTypes["TranslationValueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	value: string,
	catalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	key?: GraphQLTypes["TranslationKey"] | undefined
};
	["TranslationValueMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined,
	key?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValueOrderBy"]: GraphQLTypes["TranslationValueOrderBy"];
	["TranslationKeyOrderBy"]: GraphQLTypes["TranslationKeyOrderBy"];
	["TranslationKeyValuesByCatalogueUniqueWhere"]: GraphQLTypes["TranslationKeyValuesByCatalogueUniqueWhere"];
	["TranslationValueConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationValueEdge"]>
};
	["TranslationValueEdge"]: {
		node: GraphQLTypes["TranslationValue"]
};
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: GraphQLTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"];
	["TranslationDomainCataloguesByValuesUniqueWhere"]: GraphQLTypes["TranslationDomainCataloguesByValuesUniqueWhere"];
	["TranslationDomainKeysByIdentifierUniqueWhere"]: GraphQLTypes["TranslationDomainKeysByIdentifierUniqueWhere"];
	["TranslationDomainKeysByValuesUniqueWhere"]: GraphQLTypes["TranslationDomainKeysByValuesUniqueWhere"];
	["TranslationCatalogueConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCatalogueEdge"]>
};
	["TranslationCatalogueEdge"]: {
		node: GraphQLTypes["TranslationCatalogue"]
};
	["TranslationKeyConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationKeyEdge"]>
};
	["TranslationKeyEdge"]: {
		node: GraphQLTypes["TranslationKey"]
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: GraphQLTypes["TranslationCatalogueValuesByKeyUniqueWhere"];
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: GraphQLTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"];
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: GraphQLTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"];
	["ProductPackingLocaleOrderBy"]: GraphQLTypes["ProductPackingLocaleOrderBy"];
	["ProductLocale"]: {
		_meta?: GraphQLTypes["ProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	root?: GraphQLTypes["Product"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	featureList?: GraphQLTypes["TextList"] | undefined,
	printableName?: string | undefined,
	firstSticker?: string | undefined,
	secondSticker?: string | undefined
};
	["ProductLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	featureList?: GraphQLTypes["FieldMeta"] | undefined,
	printableName?: GraphQLTypes["FieldMeta"] | undefined,
	firstSticker?: GraphQLTypes["FieldMeta"] | undefined,
	secondSticker?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductLocaleOrderBy"]: GraphQLTypes["ProductLocaleOrderBy"];
	["ProductRecipeLocale"]: {
		_meta?: GraphQLTypes["ProductRecipeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductRecipe"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	description?: string | undefined
};
	["ProductRecipeLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductRecipe"]: {
		_meta?: GraphQLTypes["ProductRecipeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	products: Array<GraphQLTypes["Product"]>,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	ingredients: Array<GraphQLTypes["ProductIngredientItem"]>,
	needsPrepping: boolean,
	locales: Array<GraphQLTypes["ProductRecipeLocale"]>,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	description?: string | undefined,
	productsByCode?: GraphQLTypes["Product"] | undefined,
	productsByStocks?: GraphQLTypes["Product"] | undefined,
	productsByGroupPrices?: GraphQLTypes["Product"] | undefined,
	productsByCartItems?: GraphQLTypes["Product"] | undefined,
	productsByOrderItems?: GraphQLTypes["Product"] | undefined,
	productsByLocales?: GraphQLTypes["Product"] | undefined,
	productsByCustomerPrices?: GraphQLTypes["Product"] | undefined,
	productsByVirtualProduct?: GraphQLTypes["Product"] | undefined,
	productsByMeta?: GraphQLTypes["Product"] | undefined,
	productsByPage?: GraphQLTypes["Product"] | undefined,
	localesByLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientItemConnection"],
	paginateLocales: GraphQLTypes["ProductRecipeLocaleConnection"]
};
	["ProductRecipeMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	gutTuning?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItem"]: {
		_meta?: GraphQLTypes["ProductIngredientItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	internalOrder: number,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined
};
	["ProductIngredientItemMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	internalOrder?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItemOrderBy"]: GraphQLTypes["ProductIngredientItemOrderBy"];
	["ProductRecipeLocaleOrderBy"]: GraphQLTypes["ProductRecipeLocaleOrderBy"];
	["ProductRecipeProductsByCodeUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByCodeUniqueWhere"];
	["ProductRecipeProductsByStocksUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByStocksUniqueWhere"];
	["ProductRecipeProductsByGroupPricesUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByGroupPricesUniqueWhere"];
	["ProductRecipeProductsByCartItemsUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByCartItemsUniqueWhere"];
	["ProductRecipeProductsByOrderItemsUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByOrderItemsUniqueWhere"];
	["ProductRecipeProductsByLocalesUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByLocalesUniqueWhere"];
	["ProductRecipeProductsByCustomerPricesUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByCustomerPricesUniqueWhere"];
	["ProductRecipeProductsByVirtualProductUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByVirtualProductUniqueWhere"];
	["ProductRecipeProductsByMetaUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByMetaUniqueWhere"];
	["ProductRecipeProductsByPageUniqueWhere"]: GraphQLTypes["ProductRecipeProductsByPageUniqueWhere"];
	["ProductRecipeLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductRecipeLocalesByLocaleUniqueWhere"];
	["ProductIngredientItemConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientItemEdge"]>
};
	["ProductIngredientItemEdge"]: {
		node: GraphQLTypes["ProductIngredientItem"]
};
	["ProductRecipeLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeLocaleEdge"]>
};
	["ProductRecipeLocaleEdge"]: {
		node: GraphQLTypes["ProductRecipeLocale"]
};
	["IdentificationCodeLocale"]: {
		_meta?: GraphQLTypes["IdentificationCodeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	successMessage?: string | undefined,
	root?: GraphQLTypes["IdentificationCode"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["IdentificationCodeLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	successMessage?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["IdentificationCode"]: {
		_meta?: GraphQLTypes["IdentificationCodeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	createdAt: GraphQLTypes["DateTime"],
	locales: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	internalName?: string | undefined,
	localesByLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	paginateLocales: GraphQLTypes["IdentificationCodeLocaleConnection"]
};
	["IdentificationCodeMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined
};
	["IdentificationCodeLocaleOrderBy"]: GraphQLTypes["IdentificationCodeLocaleOrderBy"];
	["IdentificationCodeOrderBy"]: GraphQLTypes["IdentificationCodeOrderBy"];
	["IdentificationCodeLocalesByLocaleUniqueWhere"]: GraphQLTypes["IdentificationCodeLocalesByLocaleUniqueWhere"];
	["IdentificationCodeLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IdentificationCodeLocaleEdge"]>
};
	["IdentificationCodeLocaleEdge"]: {
		node: GraphQLTypes["IdentificationCodeLocale"]
};
	["SubscriptionBoxLocale"]: {
		_meta?: GraphQLTypes["SubscriptionBoxLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	stickerText?: string | undefined,
	descriptionText?: string | undefined,
	root?: GraphQLTypes["SubscriptionBox"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	secondaryTitle?: string | undefined,
	texts?: GraphQLTypes["TextList"] | undefined,
	secondaryTexts?: GraphQLTypes["TextList"] | undefined
};
	["SubscriptionBoxLocaleMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	stickerText?: GraphQLTypes["FieldMeta"] | undefined,
	descriptionText?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	texts?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTexts?: GraphQLTypes["FieldMeta"] | undefined
};
	["SubscriptionBox"]: {
		_meta?: GraphQLTypes["SubscriptionBoxMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	unique: GraphQLTypes["One"],
	localesByLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	localesByTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	localesBySecondaryTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	paginateLocales: GraphQLTypes["SubscriptionBoxLocaleConnection"]
};
	["SubscriptionBoxMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined
};
	["SubscriptionBoxLocaleOrderBy"]: GraphQLTypes["SubscriptionBoxLocaleOrderBy"];
	["SubscriptionBoxOrderBy"]: GraphQLTypes["SubscriptionBoxOrderBy"];
	["SubscriptionBoxLocalesByLocaleUniqueWhere"]: GraphQLTypes["SubscriptionBoxLocalesByLocaleUniqueWhere"];
	["SubscriptionBoxLocalesByTextsUniqueWhere"]: GraphQLTypes["SubscriptionBoxLocalesByTextsUniqueWhere"];
	["SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"]: GraphQLTypes["SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"];
	["SubscriptionBoxLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SubscriptionBoxLocaleEdge"]>
};
	["SubscriptionBoxLocaleEdge"]: {
		node: GraphQLTypes["SubscriptionBoxLocale"]
};
	["LocaleBusinessCategoriesByRootUniqueWhere"]: GraphQLTypes["LocaleBusinessCategoriesByRootUniqueWhere"];
	["LocaleProductCategoryListsByRootUniqueWhere"]: GraphQLTypes["LocaleProductCategoryListsByRootUniqueWhere"];
	["LocaleProductIngredientsByRootUniqueWhere"]: GraphQLTypes["LocaleProductIngredientsByRootUniqueWhere"];
	["LocaleOrderRecurrencesByRootUniqueWhere"]: GraphQLTypes["LocaleOrderRecurrencesByRootUniqueWhere"];
	["LocaleBlogsByRootUniqueWhere"]: GraphQLTypes["LocaleBlogsByRootUniqueWhere"];
	["LocaleBlogPostsByRootUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByRootUniqueWhere"];
	["LocaleBlogPostsByCoverUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByCoverUniqueWhere"];
	["LocaleBlogPostsByContentUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByContentUniqueWhere"];
	["LocaleBlogPostsByLinkUniqueWhere"]: GraphQLTypes["LocaleBlogPostsByLinkUniqueWhere"];
	["LocaleBlogPostsBySeoUniqueWhere"]: GraphQLTypes["LocaleBlogPostsBySeoUniqueWhere"];
	["LocalePagesByRootUniqueWhere"]: GraphQLTypes["LocalePagesByRootUniqueWhere"];
	["LocalePagesByLinkUniqueWhere"]: GraphQLTypes["LocalePagesByLinkUniqueWhere"];
	["LocalePagesBySeoUniqueWhere"]: GraphQLTypes["LocalePagesBySeoUniqueWhere"];
	["LocalePagesByContentUniqueWhere"]: GraphQLTypes["LocalePagesByContentUniqueWhere"];
	["LocalePagesByCoverUniqueWhere"]: GraphQLTypes["LocalePagesByCoverUniqueWhere"];
	["LocaleAllergensByRootUniqueWhere"]: GraphQLTypes["LocaleAllergensByRootUniqueWhere"];
	["LocaleProductPackingsByRootUniqueWhere"]: GraphQLTypes["LocaleProductPackingsByRootUniqueWhere"];
	["LocaleProductsByRootUniqueWhere"]: GraphQLTypes["LocaleProductsByRootUniqueWhere"];
	["LocaleProductsByFeatureListUniqueWhere"]: GraphQLTypes["LocaleProductsByFeatureListUniqueWhere"];
	["LocaleProductRecipesByRootUniqueWhere"]: GraphQLTypes["LocaleProductRecipesByRootUniqueWhere"];
	["LocaleDeliveryMethodsByRootUniqueWhere"]: GraphQLTypes["LocaleDeliveryMethodsByRootUniqueWhere"];
	["LocaleIdentificationCodesByRootUniqueWhere"]: GraphQLTypes["LocaleIdentificationCodesByRootUniqueWhere"];
	["LocaleSubscriptionBoxesByRootUniqueWhere"]: GraphQLTypes["LocaleSubscriptionBoxesByRootUniqueWhere"];
	["LocaleSubscriptionBoxesByTextsUniqueWhere"]: GraphQLTypes["LocaleSubscriptionBoxesByTextsUniqueWhere"];
	["LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"]: GraphQLTypes["LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"];
	["ProductPackingLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingLocaleEdge"]>
};
	["ProductPackingLocaleEdge"]: {
		node: GraphQLTypes["ProductPackingLocale"]
};
	["ProductLocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductLocaleEdge"]>
};
	["ProductLocaleEdge"]: {
		node: GraphQLTypes["ProductLocale"]
};
	["ProductPackingLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductPackingLocalesByLocaleUniqueWhere"];
	["ProductStock"]: {
		_meta?: GraphQLTypes["ProductStockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	product?: GraphQLTypes["Product"] | undefined,
	store?: GraphQLTypes["Store"] | undefined
};
	["ProductStockMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	store?: GraphQLTypes["FieldMeta"] | undefined
};
	["Store"]: {
		_meta?: GraphQLTypes["StoreMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	description?: string | undefined,
	internalNote?: string | undefined
};
	["StoreMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStockOrderBy"]: GraphQLTypes["ProductStockOrderBy"];
	["StoreOrderBy"]: GraphQLTypes["StoreOrderBy"];
	["ProductGroupPrice"]: {
		_meta?: GraphQLTypes["ProductGroupPriceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	priceCents: number,
	group?: GraphQLTypes["CustomerGroup"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductGroupPriceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	group?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerGroup"]: {
		_meta?: GraphQLTypes["CustomerGroupMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	productPrices: Array<GraphQLTypes["ProductGroupPrice"]>,
	customers: Array<GraphQLTypes["Customer"]>,
	discountPermille?: number | undefined,
	productPricesByProduct?: GraphQLTypes["ProductGroupPrice"] | undefined,
	customersByDoubleshotLegacyId?: GraphQLTypes["Customer"] | undefined,
	customersByAddresses?: GraphQLTypes["Customer"] | undefined,
	customersByDefaultBillingAddress?: GraphQLTypes["Customer"] | undefined,
	customersByCredits?: GraphQLTypes["Customer"] | undefined,
	customersByOrders?: GraphQLTypes["Customer"] | undefined,
	customersByPermissions?: GraphQLTypes["Customer"] | undefined,
	customersByName?: GraphQLTypes["Customer"] | undefined,
	customersByIngredientRatings?: GraphQLTypes["Customer"] | undefined,
	customersByBusiness?: GraphQLTypes["Customer"] | undefined,
	customersByCarts?: GraphQLTypes["Customer"] | undefined,
	customersByUser?: GraphQLTypes["Customer"] | undefined,
	customersByInvoices?: GraphQLTypes["Customer"] | undefined,
	customersByInvoicingPlan?: GraphQLTypes["Customer"] | undefined,
	customersByBillingSubjects?: GraphQLTypes["Customer"] | undefined,
	customersByOfferedProducts?: GraphQLTypes["Customer"] | undefined,
	customersByDefaultDeliveryAddress?: GraphQLTypes["Customer"] | undefined,
	customersByDefaultBillingSubject?: GraphQLTypes["Customer"] | undefined,
	customersByAnonymousSession?: GraphQLTypes["Customer"] | undefined,
	customersByBoughtVouchers?: GraphQLTypes["Customer"] | undefined,
	customersByOwnsVouchers?: GraphQLTypes["Customer"] | undefined,
	customersByRedeemedVouchers?: GraphQLTypes["Customer"] | undefined,
	customersByMeta?: GraphQLTypes["Customer"] | undefined,
	paginateProductPrices: GraphQLTypes["ProductGroupPriceConnection"],
	paginateCustomers: GraphQLTypes["CustomerConnection"]
};
	["CustomerGroupMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	productPrices?: GraphQLTypes["FieldMeta"] | undefined,
	customers?: GraphQLTypes["FieldMeta"] | undefined,
	discountPermille?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGroupPriceOrderBy"]: GraphQLTypes["ProductGroupPriceOrderBy"];
	["CustomerGroupProductPricesByProductUniqueWhere"]: GraphQLTypes["CustomerGroupProductPricesByProductUniqueWhere"];
	["CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere"];
	["CustomerGroupCustomersByAddressesUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByAddressesUniqueWhere"];
	["CustomerGroupCustomersByDefaultBillingAddressUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByDefaultBillingAddressUniqueWhere"];
	["CustomerGroupCustomersByCreditsUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByCreditsUniqueWhere"];
	["CustomerGroupCustomersByOrdersUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByOrdersUniqueWhere"];
	["CustomerGroupCustomersByPermissionsUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByPermissionsUniqueWhere"];
	["CustomerGroupCustomersByNameUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByNameUniqueWhere"];
	["CustomerGroupCustomersByIngredientRatingsUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByIngredientRatingsUniqueWhere"];
	["CustomerGroupCustomersByBusinessUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByBusinessUniqueWhere"];
	["CustomerGroupCustomersByCartsUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByCartsUniqueWhere"];
	["CustomerGroupCustomersByUserUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByUserUniqueWhere"];
	["CustomerGroupCustomersByInvoicesUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByInvoicesUniqueWhere"];
	["CustomerGroupCustomersByInvoicingPlanUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByInvoicingPlanUniqueWhere"];
	["CustomerGroupCustomersByBillingSubjectsUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByBillingSubjectsUniqueWhere"];
	["CustomerGroupCustomersByOfferedProductsUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByOfferedProductsUniqueWhere"];
	["CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere"];
	["CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere"];
	["CustomerGroupCustomersByAnonymousSessionUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByAnonymousSessionUniqueWhere"];
	["CustomerGroupCustomersByBoughtVouchersUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByBoughtVouchersUniqueWhere"];
	["CustomerGroupCustomersByOwnsVouchersUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByOwnsVouchersUniqueWhere"];
	["CustomerGroupCustomersByRedeemedVouchersUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByRedeemedVouchersUniqueWhere"];
	["CustomerGroupCustomersByMetaUniqueWhere"]: GraphQLTypes["CustomerGroupCustomersByMetaUniqueWhere"];
	["ProductGroupPriceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGroupPriceEdge"]>
};
	["ProductGroupPriceEdge"]: {
		node: GraphQLTypes["ProductGroupPrice"]
};
	["ProductCustomerPrice"]: {
		_meta?: GraphQLTypes["ProductCustomerPriceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	groupDiscountPermille?: number | undefined,
	groupPriceCents?: number | undefined,
	fullPriceCents: number,
	discountedPriceCents?: number | undefined,
	priceCents: number,
	offered: boolean,
	notDiscountable: boolean,
	customer?: GraphQLTypes["Customer"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductCustomerPriceMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	groupDiscountPermille?: GraphQLTypes["FieldMeta"] | undefined,
	groupPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	fullPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	discountedPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	offered?: GraphQLTypes["FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCustomerPriceOrderBy"]: GraphQLTypes["ProductCustomerPriceOrderBy"];
	["ProductMetadata"]: {
		_meta?: GraphQLTypes["ProductMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	archived: boolean,
	available: boolean,
	availableQuantity: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductMetadataMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	archived?: GraphQLTypes["FieldMeta"] | undefined,
	available?: GraphQLTypes["FieldMeta"] | undefined,
	availableQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStocksByStoreUniqueWhere"]: GraphQLTypes["ProductStocksByStoreUniqueWhere"];
	["ProductGroupPricesByGroupUniqueWhere"]: GraphQLTypes["ProductGroupPricesByGroupUniqueWhere"];
	["ProductCartItemsByCartUniqueWhere"]: GraphQLTypes["ProductCartItemsByCartUniqueWhere"];
	["ProductOrderItemsByVirtualProductEffectsUniqueWhere"]: GraphQLTypes["ProductOrderItemsByVirtualProductEffectsUniqueWhere"];
	["ProductLocalesByLocaleUniqueWhere"]: GraphQLTypes["ProductLocalesByLocaleUniqueWhere"];
	["ProductLocalesByFeatureListUniqueWhere"]: GraphQLTypes["ProductLocalesByFeatureListUniqueWhere"];
	["ProductCustomerPricesByCustomerUniqueWhere"]: GraphQLTypes["ProductCustomerPricesByCustomerUniqueWhere"];
	["ProductStockConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductStockEdge"]>
};
	["ProductStockEdge"]: {
		node: GraphQLTypes["ProductStock"]
};
	["ProductCustomerPriceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCustomerPriceEdge"]>
};
	["ProductCustomerPriceEdge"]: {
		node: GraphQLTypes["ProductCustomerPrice"]
};
	["ChannelConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ChannelEdge"]>
};
	["ChannelEdge"]: {
		node: GraphQLTypes["Channel"]
};
	["CustomerPermissions"]: {
		_meta?: GraphQLTypes["CustomerPermissionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	canPlaceOrder?: boolean | undefined,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["CustomerPermissionsMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	canPlaceOrder?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerName"]: {
		_meta?: GraphQLTypes["CustomerNameMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	nominative: string,
	vocative?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["CustomerNameMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	nominative?: GraphQLTypes["FieldMeta"] | undefined,
	vocative?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["User"]: {
		_meta?: GraphQLTypes["UserMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	staff?: GraphQLTypes["Staff"] | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	tenantPersonId: GraphQLTypes["UUID"],
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["UserMetadata"] | undefined
};
	["UserMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	staff?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	tenantPersonId?: GraphQLTypes["FieldMeta"] | undefined,
	disabledAt?: GraphQLTypes["FieldMeta"] | undefined,
	verifiedAt?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	deletionRequestedAt?: GraphQLTypes["FieldMeta"] | undefined,
	deletionExpectedAt?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined
};
	["Staff"]: {
		_meta?: GraphQLTypes["StaffMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	user?: GraphQLTypes["User"] | undefined,
	photo?: GraphQLTypes["Image"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["StaffMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	user?: GraphQLTypes["FieldMeta"] | undefined,
	photo?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["UserMetadata"]: {
		_meta?: GraphQLTypes["UserMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	userProfileUrl: string,
	tenantEmail?: string | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	tenantIdentityId?: GraphQLTypes["UUID"] | undefined,
	globalRoles?: GraphQLTypes["Json"] | undefined,
	projectRoles?: GraphQLTypes["Json"] | undefined,
	projectRolesString?: string | undefined,
	externalIdentifiers?: GraphQLTypes["Json"] | undefined,
	externalIdentifiersString?: string | undefined,
	idpSlugs?: GraphQLTypes["Json"] | undefined,
	idpSlugsString?: string | undefined,
	user?: GraphQLTypes["User"] | undefined
};
	["UserMetadataMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	userProfileUrl?: GraphQLTypes["FieldMeta"] | undefined,
	tenantEmail?: GraphQLTypes["FieldMeta"] | undefined,
	tenantPersonId?: GraphQLTypes["FieldMeta"] | undefined,
	tenantIdentityId?: GraphQLTypes["FieldMeta"] | undefined,
	globalRoles?: GraphQLTypes["FieldMeta"] | undefined,
	projectRoles?: GraphQLTypes["FieldMeta"] | undefined,
	projectRolesString?: GraphQLTypes["FieldMeta"] | undefined,
	externalIdentifiers?: GraphQLTypes["FieldMeta"] | undefined,
	externalIdentifiersString?: GraphQLTypes["FieldMeta"] | undefined,
	idpSlugs?: GraphQLTypes["FieldMeta"] | undefined,
	idpSlugsString?: GraphQLTypes["FieldMeta"] | undefined,
	user?: GraphQLTypes["FieldMeta"] | undefined
};
	["InvoicingPlan"]: {
		_meta?: GraphQLTypes["InvoicingPlanMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	customer?: GraphQLTypes["Customer"] | undefined,
	preset?: GraphQLTypes["InvoicingPreset"] | undefined
};
	["InvoicingPlanMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	preset?: GraphQLTypes["FieldMeta"] | undefined
};
	["InvoicingPreset"]: {
		_meta?: GraphQLTypes["InvoicingPresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["InvoicingPresetType"] | undefined,
	startsAt?: GraphQLTypes["DateTime"] | undefined,
	endsAt?: GraphQLTypes["DateTime"] | undefined,
	intervalOfDays?: number | undefined,
	invoicingPlan?: GraphQLTypes["InvoicingPlan"] | undefined
};
	["InvoicingPresetMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	startsAt?: GraphQLTypes["FieldMeta"] | undefined,
	endsAt?: GraphQLTypes["FieldMeta"] | undefined,
	intervalOfDays?: GraphQLTypes["FieldMeta"] | undefined,
	invoicingPlan?: GraphQLTypes["FieldMeta"] | undefined
};
	["AnonymousSession"]: {
		_meta?: GraphQLTypes["AnonymousSessionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey: string,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["AnonymousSessionMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	lastActiveAt?: GraphQLTypes["FieldMeta"] | undefined,
	anonymousSessionKey?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerMetadata"]: {
		_meta?: GraphQLTypes["CustomerMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isBusiness: boolean,
	name?: string | undefined,
	fallbackName: string,
	remainingCreditsCents: number,
	ordersTotalCents: number,
	customerProfileUrl: string,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["CustomerMetadataMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	isBusiness?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	fallbackName?: GraphQLTypes["FieldMeta"] | undefined,
	remainingCreditsCents?: GraphQLTypes["FieldMeta"] | undefined,
	ordersTotalCents?: GraphQLTypes["FieldMeta"] | undefined,
	customerProfileUrl?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerAddressesByMetaUniqueWhere"]: GraphQLTypes["CustomerAddressesByMetaUniqueWhere"];
	["CustomerAddressesByGpsUniqueWhere"]: GraphQLTypes["CustomerAddressesByGpsUniqueWhere"];
	["CustomerAddressesByInvoicesUniqueWhere"]: GraphQLTypes["CustomerAddressesByInvoicesUniqueWhere"];
	["CustomerAddressesByBillingAddressOfOrdersUniqueWhere"]: GraphQLTypes["CustomerAddressesByBillingAddressOfOrdersUniqueWhere"];
	["CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: GraphQLTypes["CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"];
	["CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: GraphQLTypes["CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"];
	["CustomerCreditsByVoucherRedemptionUniqueWhere"]: GraphQLTypes["CustomerCreditsByVoucherRedemptionUniqueWhere"];
	["CustomerOrdersBySeqUniqueWhere"]: GraphQLTypes["CustomerOrdersBySeqUniqueWhere"];
	["CustomerOrdersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["CustomerOrdersByDoubleshotLegacyIdUniqueWhere"];
	["CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"]: GraphQLTypes["CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"];
	["CustomerOrdersByDiscountsUniqueWhere"]: GraphQLTypes["CustomerOrdersByDiscountsUniqueWhere"];
	["CustomerOrdersByDeliveryUniqueWhere"]: GraphQLTypes["CustomerOrdersByDeliveryUniqueWhere"];
	["CustomerOrdersByItemsUniqueWhere"]: GraphQLTypes["CustomerOrdersByItemsUniqueWhere"];
	["CustomerOrdersByCartUniqueWhere"]: GraphQLTypes["CustomerOrdersByCartUniqueWhere"];
	["CustomerOrdersByPriceLinesUniqueWhere"]: GraphQLTypes["CustomerOrdersByPriceLinesUniqueWhere"];
	["CustomerOrdersByPaymentsUniqueWhere"]: GraphQLTypes["CustomerOrdersByPaymentsUniqueWhere"];
	["CustomerOrdersBySummaryUniqueWhere"]: GraphQLTypes["CustomerOrdersBySummaryUniqueWhere"];
	["CustomerIngredientRatingsByIngredientUniqueWhere"]: GraphQLTypes["CustomerIngredientRatingsByIngredientUniqueWhere"];
	["CustomerCartsByItemsUniqueWhere"]: GraphQLTypes["CustomerCartsByItemsUniqueWhere"];
	["CustomerCartsByOrderUniqueWhere"]: GraphQLTypes["CustomerCartsByOrderUniqueWhere"];
	["CustomerCartsByPriceLinesUniqueWhere"]: GraphQLTypes["CustomerCartsByPriceLinesUniqueWhere"];
	["CustomerCartsBySummaryUniqueWhere"]: GraphQLTypes["CustomerCartsBySummaryUniqueWhere"];
	["CustomerInvoicesByPublicKeyUniqueWhere"]: GraphQLTypes["CustomerInvoicesByPublicKeyUniqueWhere"];
	["CustomerInvoicesByFakturoidIdUniqueWhere"]: GraphQLTypes["CustomerInvoicesByFakturoidIdUniqueWhere"];
	["CustomerInvoicesByOrderPaymentsUniqueWhere"]: GraphQLTypes["CustomerInvoicesByOrderPaymentsUniqueWhere"];
	["CustomerBillingSubjectsByInvoicesUniqueWhere"]: GraphQLTypes["CustomerBillingSubjectsByInvoicesUniqueWhere"];
	["CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere"]: GraphQLTypes["CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere"];
	["CustomerBillingSubjectsByOrdersUniqueWhere"]: GraphQLTypes["CustomerBillingSubjectsByOrdersUniqueWhere"];
	["CustomerOfferedProductsByProductUniqueWhere"]: GraphQLTypes["CustomerOfferedProductsByProductUniqueWhere"];
	["CustomerBoughtVouchersByCodeUniqueWhere"]: GraphQLTypes["CustomerBoughtVouchersByCodeUniqueWhere"];
	["CustomerBoughtVouchersByRedemptionUniqueWhere"]: GraphQLTypes["CustomerBoughtVouchersByRedemptionUniqueWhere"];
	["CustomerOwnsVouchersByCodeUniqueWhere"]: GraphQLTypes["CustomerOwnsVouchersByCodeUniqueWhere"];
	["CustomerOwnsVouchersByRedemptionUniqueWhere"]: GraphQLTypes["CustomerOwnsVouchersByRedemptionUniqueWhere"];
	["CustomerRedeemedVouchersByCustomerCreditUniqueWhere"]: GraphQLTypes["CustomerRedeemedVouchersByCustomerCreditUniqueWhere"];
	["CustomerRedeemedVouchersByOrderDiscountUniqueWhere"]: GraphQLTypes["CustomerRedeemedVouchersByOrderDiscountUniqueWhere"];
	["CustomerRedeemedVouchersByVoucherUniqueWhere"]: GraphQLTypes["CustomerRedeemedVouchersByVoucherUniqueWhere"];
	["CustomerCreditConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerCreditEdge"]>
};
	["CustomerCreditEdge"]: {
		node: GraphQLTypes["CustomerCredit"]
};
	["BillingSubjectConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BillingSubjectEdge"]>
};
	["BillingSubjectEdge"]: {
		node: GraphQLTypes["BillingSubject"]
};
	["VoucherRedemptionConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VoucherRedemptionEdge"]>
};
	["VoucherRedemptionEdge"]: {
		node: GraphQLTypes["VoucherRedemption"]
};
	["Gps"]: {
		_meta?: GraphQLTypes["GpsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	latitude: number,
	longitude: number,
	address?: GraphQLTypes["Address"] | undefined
};
	["GpsMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	latitude?: GraphQLTypes["FieldMeta"] | undefined,
	longitude?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined
};
	["AddressInvoicesByPublicKeyUniqueWhere"]: GraphQLTypes["AddressInvoicesByPublicKeyUniqueWhere"];
	["AddressInvoicesByFakturoidIdUniqueWhere"]: GraphQLTypes["AddressInvoicesByFakturoidIdUniqueWhere"];
	["AddressInvoicesByOrderPaymentsUniqueWhere"]: GraphQLTypes["AddressInvoicesByOrderPaymentsUniqueWhere"];
	["AddressBillingAddressOfOrdersBySeqUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersBySeqUniqueWhere"];
	["AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"];
	["AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"];
	["AddressBillingAddressOfOrdersByDiscountsUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByDiscountsUniqueWhere"];
	["AddressBillingAddressOfOrdersByDeliveryUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByDeliveryUniqueWhere"];
	["AddressBillingAddressOfOrdersByItemsUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByItemsUniqueWhere"];
	["AddressBillingAddressOfOrdersByCartUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByCartUniqueWhere"];
	["AddressBillingAddressOfOrdersByPriceLinesUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByPriceLinesUniqueWhere"];
	["AddressBillingAddressOfOrdersByPaymentsUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersByPaymentsUniqueWhere"];
	["AddressBillingAddressOfOrdersBySummaryUniqueWhere"]: GraphQLTypes["AddressBillingAddressOfOrdersBySummaryUniqueWhere"];
	["AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"]: GraphQLTypes["AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"];
	["AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"]: GraphQLTypes["AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"];
	["OrderDeliveryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderDeliveryEdge"]>
};
	["OrderDeliveryEdge"]: {
		node: GraphQLTypes["OrderDelivery"]
};
	["_ValidationResult"]: {
		valid: boolean,
	errors: Array<GraphQLTypes["_ValidationError"]>
};
	["_ValidationError"]: {
		path: Array<GraphQLTypes["_PathFragment"]>,
	message: GraphQLTypes["_ValidationMessage"]
};
	["_PathFragment"]:ModelTypes["_FieldPathFragment"] | ModelTypes["_IndexPathFragment"];
	["_FieldPathFragment"]: {
		field: string
};
	["_IndexPathFragment"]: {
		index: number,
	alias?: string | undefined
};
	["_ValidationMessage"]: {
		text: string
};
	["AddressCreateInput"]: GraphQLTypes["AddressCreateInput"];
	["AddressCreateCustomerEntityRelationInput"]: GraphQLTypes["AddressCreateCustomerEntityRelationInput"];
	["AddressCreateCountryEntityRelationInput"]: GraphQLTypes["AddressCreateCountryEntityRelationInput"];
	["CountryUniqueWhere"]: GraphQLTypes["CountryUniqueWhere"];
	["AddressCreateDeliveryZoneEntityRelationInput"]: GraphQLTypes["AddressCreateDeliveryZoneEntityRelationInput"];
	["DeliveryZoneUniqueWhere"]: GraphQLTypes["DeliveryZoneUniqueWhere"];
	["AddressCreateGpsEntityRelationInput"]: GraphQLTypes["AddressCreateGpsEntityRelationInput"];
	["GpsWithoutAddressCreateInput"]: GraphQLTypes["GpsWithoutAddressCreateInput"];
	["AddressCreateBillingAddressOfOrdersEntityRelationInput"]: GraphQLTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"];
	["OrderWithoutBillingAddressCreateInput"]: GraphQLTypes["OrderWithoutBillingAddressCreateInput"];
	["OrderCreateCustomerEntityRelationInput"]: GraphQLTypes["OrderCreateCustomerEntityRelationInput"];
	["OrderCreateChannelEntityRelationInput"]: GraphQLTypes["OrderCreateChannelEntityRelationInput"];
	["ChannelUniqueWhere"]: GraphQLTypes["ChannelUniqueWhere"];
	["ChannelPaymentUniqueWhere"]: GraphQLTypes["ChannelPaymentUniqueWhere"];
	["OrderCreateDeliveryEntityRelationInput"]: GraphQLTypes["OrderCreateDeliveryEntityRelationInput"];
	["OrderDeliveryWithoutOrderCreateInput"]: GraphQLTypes["OrderDeliveryWithoutOrderCreateInput"];
	["OrderDeliveryCreateMethodEntityRelationInput"]: GraphQLTypes["OrderDeliveryCreateMethodEntityRelationInput"];
	["OrderDeliveryCreateVatRateEntityRelationInput"]: GraphQLTypes["OrderDeliveryCreateVatRateEntityRelationInput"];
	["VatRateUniqueWhere"]: GraphQLTypes["VatRateUniqueWhere"];
	["OrderDeliveryCreateAddressEntityRelationInput"]: GraphQLTypes["OrderDeliveryCreateAddressEntityRelationInput"];
	["AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"]: GraphQLTypes["AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"];
	["OrderCreateItemsEntityRelationInput"]: GraphQLTypes["OrderCreateItemsEntityRelationInput"];
	["OrderItemWithoutOrderCreateInput"]: GraphQLTypes["OrderItemWithoutOrderCreateInput"];
	["OrderItemCreateVatRateEntityRelationInput"]: GraphQLTypes["OrderItemCreateVatRateEntityRelationInput"];
	["OrderItemCreateProductEntityRelationInput"]: GraphQLTypes["OrderItemCreateProductEntityRelationInput"];
	["OrderCreateCartEntityRelationInput"]: GraphQLTypes["OrderCreateCartEntityRelationInput"];
	["CartWithoutOrderCreateInput"]: GraphQLTypes["CartWithoutOrderCreateInput"];
	["CartCreateItemsEntityRelationInput"]: GraphQLTypes["CartCreateItemsEntityRelationInput"];
	["CartItemWithoutCartCreateInput"]: GraphQLTypes["CartItemWithoutCartCreateInput"];
	["CartItemCreateProductEntityRelationInput"]: GraphQLTypes["CartItemCreateProductEntityRelationInput"];
	["CartCreateChannelEntityRelationInput"]: GraphQLTypes["CartCreateChannelEntityRelationInput"];
	["CartCreateCustomerEntityRelationInput"]: GraphQLTypes["CartCreateCustomerEntityRelationInput"];
	["CartCreateDeliveryAddressEntityRelationInput"]: GraphQLTypes["CartCreateDeliveryAddressEntityRelationInput"];
	["CartCreateBillingAddressEntityRelationInput"]: GraphQLTypes["CartCreateBillingAddressEntityRelationInput"];
	["CartCreateDeliveryEntityRelationInput"]: GraphQLTypes["CartCreateDeliveryEntityRelationInput"];
	["CartCreatePaymentEntityRelationInput"]: GraphQLTypes["CartCreatePaymentEntityRelationInput"];
	["CartCreateBillingSubjectEntityRelationInput"]: GraphQLTypes["CartCreateBillingSubjectEntityRelationInput"];
	["BillingSubjectCreateInput"]: GraphQLTypes["BillingSubjectCreateInput"];
	["BillingSubjectCreateCountryEntityRelationInput"]: GraphQLTypes["BillingSubjectCreateCountryEntityRelationInput"];
	["BillingSubjectCreateCustomerEntityRelationInput"]: GraphQLTypes["BillingSubjectCreateCustomerEntityRelationInput"];
	["BillingSubjectCreateOrdersEntityRelationInput"]: GraphQLTypes["BillingSubjectCreateOrdersEntityRelationInput"];
	["OrderWithoutBillingSubjectCreateInput"]: GraphQLTypes["OrderWithoutBillingSubjectCreateInput"];
	["OrderCreateBillingAddressEntityRelationInput"]: GraphQLTypes["OrderCreateBillingAddressEntityRelationInput"];
	["AddressWithoutBillingAddressOfOrdersCreateInput"]: GraphQLTypes["AddressWithoutBillingAddressOfOrdersCreateInput"];
	["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]: GraphQLTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"];
	["OrderDeliveryWithoutAddressCreateInput"]: GraphQLTypes["OrderDeliveryWithoutAddressCreateInput"];
	["OrderDeliveryCreateOrderEntityRelationInput"]: GraphQLTypes["OrderDeliveryCreateOrderEntityRelationInput"];
	["OrderWithoutDeliveryCreateInput"]: GraphQLTypes["OrderWithoutDeliveryCreateInput"];
	["OrderCreateBillingSubjectEntityRelationInput"]: GraphQLTypes["OrderCreateBillingSubjectEntityRelationInput"];
	["BillingSubjectWithoutOrdersCreateInput"]: GraphQLTypes["BillingSubjectWithoutOrdersCreateInput"];
	["AddressUpdateInput"]: GraphQLTypes["AddressUpdateInput"];
	["AddressUpdateCustomerEntityRelationInput"]: GraphQLTypes["AddressUpdateCustomerEntityRelationInput"];
	["CustomerWithoutAddressesUpdateInput"]: GraphQLTypes["CustomerWithoutAddressesUpdateInput"];
	["CustomerUpdateDefaultBillingAddressEntityRelationInput"]: GraphQLTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"];
	["CustomerUpsertDefaultBillingAddressRelationInput"]: GraphQLTypes["CustomerUpsertDefaultBillingAddressRelationInput"];
	["CustomerUpdateNameEntityRelationInput"]: GraphQLTypes["CustomerUpdateNameEntityRelationInput"];
	["CustomerNameWithoutCustomerCreateInput"]: GraphQLTypes["CustomerNameWithoutCustomerCreateInput"];
	["CustomerNameWithoutCustomerUpdateInput"]: GraphQLTypes["CustomerNameWithoutCustomerUpdateInput"];
	["CustomerUpsertNameRelationInput"]: GraphQLTypes["CustomerUpsertNameRelationInput"];
	["CustomerUpdateIngredientRatingsEntityRelationInput"]: GraphQLTypes["CustomerUpdateIngredientRatingsEntityRelationInput"];
	["CustomerIngredientRatingWithoutCustomerCreateInput"]: GraphQLTypes["CustomerIngredientRatingWithoutCustomerCreateInput"];
	["CustomerIngredientRatingCreateRatingEntityRelationInput"]: GraphQLTypes["CustomerIngredientRatingCreateRatingEntityRelationInput"];
	["ProductIngredientRatingUniqueWhere"]: GraphQLTypes["ProductIngredientRatingUniqueWhere"];
	["CustomerIngredientRatingCreateIngredientEntityRelationInput"]: GraphQLTypes["CustomerIngredientRatingCreateIngredientEntityRelationInput"];
	["CustomerUpdateIngredientRatingsRelationInput"]: GraphQLTypes["CustomerUpdateIngredientRatingsRelationInput"];
	["CustomerIngredientRatingWithoutCustomerUpdateInput"]: GraphQLTypes["CustomerIngredientRatingWithoutCustomerUpdateInput"];
	["CustomerIngredientRatingUpdateRatingEntityRelationInput"]: GraphQLTypes["CustomerIngredientRatingUpdateRatingEntityRelationInput"];
	["CustomerIngredientRatingUpdateIngredientEntityRelationInput"]: GraphQLTypes["CustomerIngredientRatingUpdateIngredientEntityRelationInput"];
	["CustomerUpsertIngredientRatingsRelationInput"]: GraphQLTypes["CustomerUpsertIngredientRatingsRelationInput"];
	["CustomerUpdateBillingSubjectsEntityRelationInput"]: GraphQLTypes["CustomerUpdateBillingSubjectsEntityRelationInput"];
	["BillingSubjectWithoutCustomerCreateInput"]: GraphQLTypes["BillingSubjectWithoutCustomerCreateInput"];
	["CustomerUpdateBillingSubjectsRelationInput"]: GraphQLTypes["CustomerUpdateBillingSubjectsRelationInput"];
	["BillingSubjectWithoutCustomerUpdateInput"]: GraphQLTypes["BillingSubjectWithoutCustomerUpdateInput"];
	["BillingSubjectUpdateCountryEntityRelationInput"]: GraphQLTypes["BillingSubjectUpdateCountryEntityRelationInput"];
	["CustomerUpsertBillingSubjectsRelationInput"]: GraphQLTypes["CustomerUpsertBillingSubjectsRelationInput"];
	["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"]: GraphQLTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"];
	["AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"]: GraphQLTypes["AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"];
	["AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"]: GraphQLTypes["AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"];
	["AddressUpdateCountryEntityRelationInput"]: GraphQLTypes["AddressUpdateCountryEntityRelationInput"];
	["AddressUpdateDeliveryZoneEntityRelationInput"]: GraphQLTypes["AddressUpdateDeliveryZoneEntityRelationInput"];
	["AddressUpdateGpsEntityRelationInput"]: GraphQLTypes["AddressUpdateGpsEntityRelationInput"];
	["GpsWithoutAddressUpdateInput"]: GraphQLTypes["GpsWithoutAddressUpdateInput"];
	["AddressUpsertGpsRelationInput"]: GraphQLTypes["AddressUpsertGpsRelationInput"];
	["CustomerUpsertDefaultDeliveryAddressRelationInput"]: GraphQLTypes["CustomerUpsertDefaultDeliveryAddressRelationInput"];
	["CustomerUpdateAnonymousSessionEntityRelationInput"]: GraphQLTypes["CustomerUpdateAnonymousSessionEntityRelationInput"];
	["AnonymousSessionWithoutCustomerCreateInput"]: GraphQLTypes["AnonymousSessionWithoutCustomerCreateInput"];
	["AnonymousSessionWithoutCustomerUpdateInput"]: GraphQLTypes["AnonymousSessionWithoutCustomerUpdateInput"];
	["CustomerUpsertAnonymousSessionRelationInput"]: GraphQLTypes["CustomerUpsertAnonymousSessionRelationInput"];
	["AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"]: GraphQLTypes["AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"];
	["CustomerWithoutDefaultDeliveryAddressUpdateInput"]: GraphQLTypes["CustomerWithoutDefaultDeliveryAddressUpdateInput"];
	["CustomerUpdateAddressesEntityRelationInput"]: GraphQLTypes["CustomerUpdateAddressesEntityRelationInput"];
	["AddressWithoutCustomerCreateInput"]: GraphQLTypes["AddressWithoutCustomerCreateInput"];
	["CustomerUpdateAddressesRelationInput"]: GraphQLTypes["CustomerUpdateAddressesRelationInput"];
	["AddressWithoutCustomerUpdateInput"]: GraphQLTypes["AddressWithoutCustomerUpdateInput"];
	["CustomerUpsertAddressesRelationInput"]: GraphQLTypes["CustomerUpsertAddressesRelationInput"];
	["CountryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CountryEdge"]>
};
	["CountryEdge"]: {
		node: GraphQLTypes["Country"]
};
	["CustomerUpdateInput"]: GraphQLTypes["CustomerUpdateInput"];
	["CustomerGroupConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerGroupEdge"]>
};
	["CustomerGroupEdge"]: {
		node: GraphQLTypes["CustomerGroup"]
};
	["OrderCreateInput"]: GraphQLTypes["OrderCreateInput"];
	["OrderDeliveryCreateInput"]: GraphQLTypes["OrderDeliveryCreateInput"];
	["OrderItemCreateInput"]: GraphQLTypes["OrderItemCreateInput"];
	["OrderItemCreateOrderEntityRelationInput"]: GraphQLTypes["OrderItemCreateOrderEntityRelationInput"];
	["OrderWithoutItemsCreateInput"]: GraphQLTypes["OrderWithoutItemsCreateInput"];
	["PaymentMethodUniqueWhere"]: GraphQLTypes["PaymentMethodUniqueWhere"];
	["VatRateConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VatRateEdge"]>
};
	["VatRateEdge"]: {
		node: GraphQLTypes["VatRate"]
};
	["ProductPackingConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingEdge"]>
};
	["ProductPackingEdge"]: {
		node: GraphQLTypes["ProductPacking"]
};
	["ProductRecipeConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeEdge"]>
};
	["ProductRecipeEdge"]: {
		node: GraphQLTypes["ProductRecipe"]
};
	["StoreConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StoreEdge"]>
};
	["StoreEdge"]: {
		node: GraphQLTypes["Store"]
};
	["TagUniqueWhere"]: GraphQLTypes["TagUniqueWhere"];
	["CustomerPermissionsConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerPermissionsEdge"]>
};
	["CustomerPermissionsEdge"]: {
		node: GraphQLTypes["CustomerPermissions"]
};
	["ImageConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageEdge"]>
};
	["ImageEdge"]: {
		node: GraphQLTypes["Image"]
};
	["ProductListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListEdge"]>
};
	["ProductListEdge"]: {
		node: GraphQLTypes["ProductList"]
};
	["ProductCategoryListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListEdge"]>
};
	["ProductCategoryListEdge"]: {
		node: GraphQLTypes["ProductCategoryList"]
};
	["LocaleConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
		node: GraphQLTypes["Locale"]
};
	["CustomerNameConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerNameEdge"]>
};
	["CustomerNameEdge"]: {
		node: GraphQLTypes["CustomerName"]
};
	["CustomerNameCreateInput"]: GraphQLTypes["CustomerNameCreateInput"];
	["CustomerNameUpdateInput"]: GraphQLTypes["CustomerNameUpdateInput"];
	["CustomerNameUpdateCustomerEntityRelationInput"]: GraphQLTypes["CustomerNameUpdateCustomerEntityRelationInput"];
	["CustomerWithoutNameUpdateInput"]: GraphQLTypes["CustomerWithoutNameUpdateInput"];
	["CustomerIngredientRatingCreateInput"]: GraphQLTypes["CustomerIngredientRatingCreateInput"];
	["CustomerIngredientRatingCreateCustomerEntityRelationInput"]: GraphQLTypes["CustomerIngredientRatingCreateCustomerEntityRelationInput"];
	["CustomerIngredientRatingUpdateInput"]: GraphQLTypes["CustomerIngredientRatingUpdateInput"];
	["CustomerIngredientRatingUpdateCustomerEntityRelationInput"]: GraphQLTypes["CustomerIngredientRatingUpdateCustomerEntityRelationInput"];
	["CustomerWithoutIngredientRatingsUpdateInput"]: GraphQLTypes["CustomerWithoutIngredientRatingsUpdateInput"];
	["ProductIngredientRatingConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientRatingEdge"]>
};
	["ProductIngredientRatingEdge"]: {
		node: GraphQLTypes["ProductIngredientRating"]
};
	["StaffConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StaffEdge"]>
};
	["StaffEdge"]: {
		node: GraphQLTypes["Staff"]
};
	["BlogConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogEdge"]>
};
	["BlogEdge"]: {
		node: GraphQLTypes["Blog"]
};
	["BlogPostConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostEdge"]>
};
	["BlogPostEdge"]: {
		node: GraphQLTypes["BlogPost"]
};
	["ColorPalleteUniqueWhere"]: GraphQLTypes["ColorPalleteUniqueWhere"];
	["ColorPalleteConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ColorPalleteEdge"]>
};
	["ColorPalleteEdge"]: {
		node: GraphQLTypes["ColorPallete"]
};
	["ContentConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentEdge"]>
};
	["ContentEdge"]: {
		node: GraphQLTypes["Content"]
};
	["BlogPostListUniqueWhere"]: GraphQLTypes["BlogPostListUniqueWhere"];
	["BlogPostListItemUniqueWhere"]: GraphQLTypes["BlogPostListItemUniqueWhere"];
	["BlogPostListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListEdge"]>
};
	["BlogPostListEdge"]: {
		node: GraphQLTypes["BlogPostList"]
};
	["LinkListUniqueWhere"]: GraphQLTypes["LinkListUniqueWhere"];
	["LinkListItemUniqueWhere"]: GraphQLTypes["LinkListItemUniqueWhere"];
	["LinkListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListEdge"]>
};
	["LinkListEdge"]: {
		node: GraphQLTypes["LinkList"]
};
	["CoverConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CoverEdge"]>
};
	["CoverEdge"]: {
		node: GraphQLTypes["Cover"]
};
	["LinkConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkEdge"]>
};
	["LinkEdge"]: {
		node: GraphQLTypes["Link"]
};
	["LinkableConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
		node: GraphQLTypes["Linkable"]
};
	["MediumUniqueWhere"]: GraphQLTypes["MediumUniqueWhere"];
	["MediumConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MediumEdge"]>
};
	["MediumEdge"]: {
		node: GraphQLTypes["Medium"]
};
	["VideoUniqueWhere"]: GraphQLTypes["VideoUniqueWhere"];
	["VideoConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VideoEdge"]>
};
	["VideoEdge"]: {
		node: GraphQLTypes["Video"]
};
	["SeoConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoEdge"]>
};
	["SeoEdge"]: {
		node: GraphQLTypes["Seo"]
};
	["TranslationDomainConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationDomainEdge"]>
};
	["TranslationDomainEdge"]: {
		node: GraphQLTypes["TranslationDomain"]
};
	["TranslationCataloguesIdentifierConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCataloguesIdentifierEdge"]>
};
	["TranslationCataloguesIdentifierEdge"]: {
		node: GraphQLTypes["TranslationCataloguesIdentifier"]
};
	["SocialsAndAppsUniqueWhere"]: GraphQLTypes["SocialsAndAppsUniqueWhere"];
	["SocialsAndAppsConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SocialsAndAppsEdge"]>
};
	["SocialsAndAppsEdge"]: {
		node: GraphQLTypes["SocialsAndApps"]
};
	["ProductGridUniqueWhere"]: GraphQLTypes["ProductGridUniqueWhere"];
	["ProductGridCategoryUniqueWhere"]: GraphQLTypes["ProductGridCategoryUniqueWhere"];
	["ProductGridConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridEdge"]>
};
	["ProductGridEdge"]: {
		node: GraphQLTypes["ProductGrid"]
};
	["GalleryUniqueWhere"]: GraphQLTypes["GalleryUniqueWhere"];
	["GalleryItemUniqueWhere"]: GraphQLTypes["GalleryItemUniqueWhere"];
	["GalleryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryEdge"]>
};
	["GalleryEdge"]: {
		node: GraphQLTypes["Gallery"]
};
	["HeroUniqueWhere"]: GraphQLTypes["HeroUniqueWhere"];
	["HeroConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeroEdge"]>
};
	["HeroEdge"]: {
		node: GraphQLTypes["Hero"]
};
	["PageConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageEdge"]>
};
	["PageEdge"]: {
		node: GraphQLTypes["Page"]
};
	["CartCreateInput"]: GraphQLTypes["CartCreateInput"];
	["CartCreateOrderEntityRelationInput"]: GraphQLTypes["CartCreateOrderEntityRelationInput"];
	["OrderWithoutCartCreateInput"]: GraphQLTypes["OrderWithoutCartCreateInput"];
	["CartItemCreateInput"]: GraphQLTypes["CartItemCreateInput"];
	["CartItemCreateCartEntityRelationInput"]: GraphQLTypes["CartItemCreateCartEntityRelationInput"];
	["CartWithoutItemsCreateInput"]: GraphQLTypes["CartWithoutItemsCreateInput"];
	["CartItemUpdateInput"]: GraphQLTypes["CartItemUpdateInput"];
	["CartItemUpdateCartEntityRelationInput"]: GraphQLTypes["CartItemUpdateCartEntityRelationInput"];
	["CartItemUpdateProductEntityRelationInput"]: GraphQLTypes["CartItemUpdateProductEntityRelationInput"];
	["ProductBannerUniqueWhere"]: GraphQLTypes["ProductBannerUniqueWhere"];
	["ProductBannerConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerEdge"]>
};
	["ProductBannerEdge"]: {
		node: GraphQLTypes["ProductBanner"]
};
	["ProductBannerListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerListEdge"]>
};
	["ProductBannerListEdge"]: {
		node: GraphQLTypes["ProductBannerList"]
};
	["UserConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["UserEdge"]>
};
	["UserEdge"]: {
		node: GraphQLTypes["User"]
};
	["GpsConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GpsEdge"]>
};
	["GpsEdge"]: {
		node: GraphQLTypes["Gps"]
};
	["GpsCreateInput"]: GraphQLTypes["GpsCreateInput"];
	["GpsCreateAddressEntityRelationInput"]: GraphQLTypes["GpsCreateAddressEntityRelationInput"];
	["AddressWithoutGpsCreateInput"]: GraphQLTypes["AddressWithoutGpsCreateInput"];
	["GpsUpdateInput"]: GraphQLTypes["GpsUpdateInput"];
	["GpsUpdateAddressEntityRelationInput"]: GraphQLTypes["GpsUpdateAddressEntityRelationInput"];
	["AddressWithoutGpsUpdateInput"]: GraphQLTypes["AddressWithoutGpsUpdateInput"];
	["GpsUpsertAddressRelationInput"]: GraphQLTypes["GpsUpsertAddressRelationInput"];
	["WeekHoursUniqueWhere"]: GraphQLTypes["WeekHoursUniqueWhere"];
	["WeekHoursConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WeekHoursEdge"]>
};
	["WeekHoursEdge"]: {
		node: GraphQLTypes["WeekHours"]
};
	["DeliveryTimelinePresetConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryTimelinePresetEdge"]>
};
	["DeliveryTimelinePresetEdge"]: {
		node: GraphQLTypes["DeliveryTimelinePreset"]
};
	["InvoicingPlanConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["InvoicingPlanEdge"]>
};
	["InvoicingPlanEdge"]: {
		node: GraphQLTypes["InvoicingPlan"]
};
	["InvoicingPresetConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["InvoicingPresetEdge"]>
};
	["InvoicingPresetEdge"]: {
		node: GraphQLTypes["InvoicingPreset"]
};
	["BillingSubjectUpdateInput"]: GraphQLTypes["BillingSubjectUpdateInput"];
	["BillingSubjectUpdateCustomerEntityRelationInput"]: GraphQLTypes["BillingSubjectUpdateCustomerEntityRelationInput"];
	["CustomerWithoutBillingSubjectsUpdateInput"]: GraphQLTypes["CustomerWithoutBillingSubjectsUpdateInput"];
	["VirtualProductConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VirtualProductEdge"]>
};
	["VirtualProductEdge"]: {
		node: GraphQLTypes["VirtualProduct"]
};
	["ProductMetadataConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductMetadataEdge"]>
};
	["ProductMetadataEdge"]: {
		node: GraphQLTypes["ProductMetadata"]
};
	["AnonymousSessionConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AnonymousSessionEdge"]>
};
	["AnonymousSessionEdge"]: {
		node: GraphQLTypes["AnonymousSession"]
};
	["AnonymousSessionCreateInput"]: GraphQLTypes["AnonymousSessionCreateInput"];
	["AnonymousSessionCreateCustomerEntityRelationInput"]: GraphQLTypes["AnonymousSessionCreateCustomerEntityRelationInput"];
	["AnonymousSessionUpdateInput"]: GraphQLTypes["AnonymousSessionUpdateInput"];
	["AnonymousSessionUpdateCustomerEntityRelationInput"]: GraphQLTypes["AnonymousSessionUpdateCustomerEntityRelationInput"];
	["CustomerWithoutAnonymousSessionUpdateInput"]: GraphQLTypes["CustomerWithoutAnonymousSessionUpdateInput"];
	["FreshingContainerUniqueWhere"]: GraphQLTypes["FreshingContainerUniqueWhere"];
	["TextListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextListEdge"]>
};
	["TextListEdge"]: {
		node: GraphQLTypes["TextList"]
};
	["DeliveryRegionsConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsEdge"]>
};
	["DeliveryRegionsEdge"]: {
		node: GraphQLTypes["DeliveryRegions"]
};
	["ImageListConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
		node: GraphQLTypes["ImageList"]
};
	["RedirectConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
		node: GraphQLTypes["Redirect"]
};
	["WorkspaceUniqueWhere"]: GraphQLTypes["WorkspaceUniqueWhere"];
	["WorkspaceConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WorkspaceEdge"]>
};
	["WorkspaceEdge"]: {
		node: GraphQLTypes["Workspace"]
};
	["CartSummaryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CartSummaryEdge"]>
};
	["CartSummaryEdge"]: {
		node: GraphQLTypes["CartSummary"]
};
	["OrderSummaryConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderSummaryEdge"]>
};
	["OrderSummaryEdge"]: {
		node: GraphQLTypes["OrderSummary"]
};
	["OrderPaymentMetadataConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPaymentMetadataEdge"]>
};
	["OrderPaymentMetadataEdge"]: {
		node: GraphQLTypes["OrderPaymentMetadata"]
};
	["UserMetadataConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["UserMetadataEdge"]>
};
	["UserMetadataEdge"]: {
		node: GraphQLTypes["UserMetadata"]
};
	["IdentificationAttemptCreateInput"]: GraphQLTypes["IdentificationAttemptCreateInput"];
	["IdentificationAttemptCreateCodeEntityRelationInput"]: GraphQLTypes["IdentificationAttemptCreateCodeEntityRelationInput"];
	["IdentificationAttemptCreateUserEntityRelationInput"]: GraphQLTypes["IdentificationAttemptCreateUserEntityRelationInput"];
	["IdentificationCodeConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IdentificationCodeEdge"]>
};
	["IdentificationCodeEdge"]: {
		node: GraphQLTypes["IdentificationCode"]
};
	["FulfillmentNoteUniqueWhere"]: GraphQLTypes["FulfillmentNoteUniqueWhere"];
	["CustomerMetadataConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerMetadataEdge"]>
};
	["CustomerMetadataEdge"]: {
		node: GraphQLTypes["CustomerMetadata"]
};
	["SubscriptionBoxConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SubscriptionBoxEdge"]>
};
	["SubscriptionBoxEdge"]: {
		node: GraphQLTypes["SubscriptionBox"]
};
	["GridTileItemUniqueWhere"]: GraphQLTypes["GridTileItemUniqueWhere"];
	["GridTilesUniqueWhere"]: GraphQLTypes["GridTilesUniqueWhere"];
	["GridTilesConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GridTilesEdge"]>
};
	["GridTilesEdge"]: {
		node: GraphQLTypes["GridTiles"]
};
	["Position"]: {
		_meta?: GraphQLTypes["PositionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	x: number,
	y: number
};
	["PositionMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	x?: GraphQLTypes["FieldMeta"] | undefined,
	y?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionUniqueWhere"]: GraphQLTypes["PositionUniqueWhere"];
	["PositionWhere"]: GraphQLTypes["PositionWhere"];
	["PositionOrderBy"]: GraphQLTypes["PositionOrderBy"];
	["PositionConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionEdge"]>
};
	["PositionEdge"]: {
		node: GraphQLTypes["Position"]
};
	["StatsWidget"]: {
		_meta?: GraphQLTypes["StatsWidgetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	size: number,
	type: GraphQLTypes["StatsEnabledWidgetTypeType"],
	link?: string | undefined,
	title?: string | undefined,
	timeFrame: GraphQLTypes["StatsWidgetTimeFrame"],
	layout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	channels: Array<GraphQLTypes["Channel"]>,
	productCategory?: GraphQLTypes["ProductCategory"] | undefined,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	hoursFrom?: string | undefined,
	hoursTo?: string | undefined,
	paginateChannels: GraphQLTypes["ChannelConnection"],
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"]
};
	["StatsWidgetMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	timeFrame?: GraphQLTypes["FieldMeta"] | undefined,
	layout?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["FieldMeta"] | undefined,
	hoursFrom?: GraphQLTypes["FieldMeta"] | undefined,
	hoursTo?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsEnabledWidgetTypeType"]: GraphQLTypes["StatsEnabledWidgetTypeType"];
	["StatsWidgetTimeFrame"]: GraphQLTypes["StatsWidgetTimeFrame"];
	["StatsWidgetsLayout"]: {
		_meta?: GraphQLTypes["StatsWidgetsLayoutMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["StatsWidget"]>,
	paginateItems: GraphQLTypes["StatsWidgetConnection"]
};
	["StatsWidgetsLayoutMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsWidgetWhere"]: GraphQLTypes["StatsWidgetWhere"];
	["StatsEnabledWidgetTypeTypeCondition"]: GraphQLTypes["StatsEnabledWidgetTypeTypeCondition"];
	["StatsWidgetTimeFrameCondition"]: GraphQLTypes["StatsWidgetTimeFrameCondition"];
	["StatsWidgetsLayoutWhere"]: GraphQLTypes["StatsWidgetsLayoutWhere"];
	["StatsWidgetOrderBy"]: GraphQLTypes["StatsWidgetOrderBy"];
	["StatsWidgetsLayoutOrderBy"]: GraphQLTypes["StatsWidgetsLayoutOrderBy"];
	["StatsWidgetConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsWidgetEdge"]>
};
	["StatsWidgetEdge"]: {
		node: GraphQLTypes["StatsWidget"]
};
	["StatsWidgetUniqueWhere"]: GraphQLTypes["StatsWidgetUniqueWhere"];
	["StatsWidgetsLayoutUniqueWhere"]: GraphQLTypes["StatsWidgetsLayoutUniqueWhere"];
	["StatsWidgetsLayoutConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsWidgetsLayoutEdge"]>
};
	["StatsWidgetsLayoutEdge"]: {
		node: GraphQLTypes["StatsWidgetsLayout"]
};
	["StatsPage"]: {
		_meta?: GraphQLTypes["StatsPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["StatsPageType"],
	enabled: boolean,
	widgets?: GraphQLTypes["StatsWidgetsLayout"] | undefined
};
	["StatsPageMeta"]: {
		id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	widgets?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsPageType"]: GraphQLTypes["StatsPageType"];
	["StatsPageUniqueWhere"]: GraphQLTypes["StatsPageUniqueWhere"];
	["StatsPageWhere"]: GraphQLTypes["StatsPageWhere"];
	["StatsPageTypeCondition"]: GraphQLTypes["StatsPageTypeCondition"];
	["StatsPageOrderBy"]: GraphQLTypes["StatsPageOrderBy"];
	["StatsPageConnection"]: {
		pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsPageEdge"]>
};
	["StatsPageEdge"]: {
		node: GraphQLTypes["StatsPage"]
};
	["QueryTransaction"]: {
		getAddress?: GraphQLTypes["Address"] | undefined,
	listAddress: Array<GraphQLTypes["Address"]>,
	paginateAddress: GraphQLTypes["AddressConnection"],
	validateCreateAddress: GraphQLTypes["_ValidationResult"],
	validateUpdateAddress: GraphQLTypes["_ValidationResult"],
	getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getChannelPayment?: GraphQLTypes["ChannelPayment"] | undefined,
	listChannelPayment: Array<GraphQLTypes["ChannelPayment"]>,
	paginateChannelPayment: GraphQLTypes["ChannelPaymentConnection"],
	getChannelDelivery?: GraphQLTypes["ChannelDelivery"] | undefined,
	listChannelDelivery: Array<GraphQLTypes["ChannelDelivery"]>,
	paginateChannelDelivery: GraphQLTypes["ChannelDeliveryConnection"],
	getCustomer?: GraphQLTypes["Customer"] | undefined,
	listCustomer: Array<GraphQLTypes["Customer"]>,
	paginateCustomer: GraphQLTypes["CustomerConnection"],
	validateUpdateCustomer: GraphQLTypes["_ValidationResult"],
	getCustomerCredit?: GraphQLTypes["CustomerCredit"] | undefined,
	listCustomerCredit: Array<GraphQLTypes["CustomerCredit"]>,
	paginateCustomerCredit: GraphQLTypes["CustomerCreditConnection"],
	getCustomerGroup?: GraphQLTypes["CustomerGroup"] | undefined,
	listCustomerGroup: Array<GraphQLTypes["CustomerGroup"]>,
	paginateCustomerGroup: GraphQLTypes["CustomerGroupConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getOrder?: GraphQLTypes["Order"] | undefined,
	listOrder: Array<GraphQLTypes["Order"]>,
	paginateOrder: GraphQLTypes["OrderConnection"],
	validateCreateOrder: GraphQLTypes["_ValidationResult"],
	getOrderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	listOrderDiscount: Array<GraphQLTypes["OrderDiscount"]>,
	paginateOrderDiscount: GraphQLTypes["OrderDiscountConnection"],
	getOrderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	listOrderPayment: Array<GraphQLTypes["OrderPayment"]>,
	paginateOrderPayment: GraphQLTypes["OrderPaymentConnection"],
	getOrderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	listOrderDelivery: Array<GraphQLTypes["OrderDelivery"]>,
	paginateOrderDelivery: GraphQLTypes["OrderDeliveryConnection"],
	validateCreateOrderDelivery: GraphQLTypes["_ValidationResult"],
	getOrderItem?: GraphQLTypes["OrderItem"] | undefined,
	listOrderItem: Array<GraphQLTypes["OrderItem"]>,
	paginateOrderItem: GraphQLTypes["OrderItemConnection"],
	validateCreateOrderItem: GraphQLTypes["_ValidationResult"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductGroupPrice?: GraphQLTypes["ProductGroupPrice"] | undefined,
	listProductGroupPrice: Array<GraphQLTypes["ProductGroupPrice"]>,
	paginateProductGroupPrice: GraphQLTypes["ProductGroupPriceConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getCustomerPermissions?: GraphQLTypes["CustomerPermissions"] | undefined,
	listCustomerPermissions: Array<GraphQLTypes["CustomerPermissions"]>,
	paginateCustomerPermissions: GraphQLTypes["CustomerPermissionsConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getCustomerName?: GraphQLTypes["CustomerName"] | undefined,
	listCustomerName: Array<GraphQLTypes["CustomerName"]>,
	paginateCustomerName: GraphQLTypes["CustomerNameConnection"],
	validateCreateCustomerName: GraphQLTypes["_ValidationResult"],
	validateUpdateCustomerName: GraphQLTypes["_ValidationResult"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getCustomerIngredientRating?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	listCustomerIngredientRating: Array<GraphQLTypes["CustomerIngredientRating"]>,
	paginateCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingConnection"],
	validateCreateCustomerIngredientRating: GraphQLTypes["_ValidationResult"],
	validateUpdateCustomerIngredientRating: GraphQLTypes["_ValidationResult"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getBusinessCustomer?: GraphQLTypes["BusinessCustomer"] | undefined,
	listBusinessCustomer: Array<GraphQLTypes["BusinessCustomer"]>,
	paginateBusinessCustomer: GraphQLTypes["BusinessCustomerConnection"],
	getStaff?: GraphQLTypes["Staff"] | undefined,
	listStaff: Array<GraphQLTypes["Staff"]>,
	paginateStaff: GraphQLTypes["StaffConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getCart?: GraphQLTypes["Cart"] | undefined,
	listCart: Array<GraphQLTypes["Cart"]>,
	paginateCart: GraphQLTypes["CartConnection"],
	validateCreateCart: GraphQLTypes["_ValidationResult"],
	getCartItem?: GraphQLTypes["CartItem"] | undefined,
	listCartItem: Array<GraphQLTypes["CartItem"]>,
	paginateCartItem: GraphQLTypes["CartItemConnection"],
	validateCreateCartItem: GraphQLTypes["_ValidationResult"],
	validateUpdateCartItem: GraphQLTypes["_ValidationResult"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getUser?: GraphQLTypes["User"] | undefined,
	listUser: Array<GraphQLTypes["User"]>,
	paginateUser: GraphQLTypes["UserConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getGps?: GraphQLTypes["Gps"] | undefined,
	listGps: Array<GraphQLTypes["Gps"]>,
	paginateGps: GraphQLTypes["GpsConnection"],
	validateCreateGps: GraphQLTypes["_ValidationResult"],
	validateUpdateGps: GraphQLTypes["_ValidationResult"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getAddressMetadata?: GraphQLTypes["AddressMetadata"] | undefined,
	listAddressMetadata: Array<GraphQLTypes["AddressMetadata"]>,
	paginateAddressMetadata: GraphQLTypes["AddressMetadataConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getInvoice?: GraphQLTypes["Invoice"] | undefined,
	listInvoice: Array<GraphQLTypes["Invoice"]>,
	paginateInvoice: GraphQLTypes["InvoiceConnection"],
	getInvoicingPlan?: GraphQLTypes["InvoicingPlan"] | undefined,
	listInvoicingPlan: Array<GraphQLTypes["InvoicingPlan"]>,
	paginateInvoicingPlan: GraphQLTypes["InvoicingPlanConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getInvoicingPreset?: GraphQLTypes["InvoicingPreset"] | undefined,
	listInvoicingPreset: Array<GraphQLTypes["InvoicingPreset"]>,
	paginateInvoicingPreset: GraphQLTypes["InvoicingPresetConnection"],
	getBillingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	listBillingSubject: Array<GraphQLTypes["BillingSubject"]>,
	paginateBillingSubject: GraphQLTypes["BillingSubjectConnection"],
	validateCreateBillingSubject: GraphQLTypes["_ValidationResult"],
	validateUpdateBillingSubject: GraphQLTypes["_ValidationResult"],
	getProductCustomerPrice?: GraphQLTypes["ProductCustomerPrice"] | undefined,
	listProductCustomerPrice: Array<GraphQLTypes["ProductCustomerPrice"]>,
	paginateProductCustomerPrice: GraphQLTypes["ProductCustomerPriceConnection"],
	getCartPriceLine?: GraphQLTypes["CartPriceLine"] | undefined,
	listCartPriceLine: Array<GraphQLTypes["CartPriceLine"]>,
	paginateCartPriceLine: GraphQLTypes["CartPriceLineConnection"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getVirtualProductEffect?: GraphQLTypes["VirtualProductEffect"] | undefined,
	listVirtualProductEffect: Array<GraphQLTypes["VirtualProductEffect"]>,
	paginateVirtualProductEffect: GraphQLTypes["VirtualProductEffectConnection"],
	getOrderPriceLine?: GraphQLTypes["OrderPriceLine"] | undefined,
	listOrderPriceLine: Array<GraphQLTypes["OrderPriceLine"]>,
	paginateOrderPriceLine: GraphQLTypes["OrderPriceLineConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getAnonymousSession?: GraphQLTypes["AnonymousSession"] | undefined,
	listAnonymousSession: Array<GraphQLTypes["AnonymousSession"]>,
	paginateAnonymousSession: GraphQLTypes["AnonymousSessionConnection"],
	validateCreateAnonymousSession: GraphQLTypes["_ValidationResult"],
	validateUpdateAnonymousSession: GraphQLTypes["_ValidationResult"],
	getVoucher?: GraphQLTypes["Voucher"] | undefined,
	listVoucher: Array<GraphQLTypes["Voucher"]>,
	paginateVoucher: GraphQLTypes["VoucherConnection"],
	getVoucherRedemption?: GraphQLTypes["VoucherRedemption"] | undefined,
	listVoucherRedemption: Array<GraphQLTypes["VoucherRedemption"]>,
	paginateVoucherRedemption: GraphQLTypes["VoucherRedemptionConnection"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getWorkspace?: GraphQLTypes["Workspace"] | undefined,
	listWorkspace: Array<GraphQLTypes["Workspace"]>,
	paginateWorkspace: GraphQLTypes["WorkspaceConnection"],
	getCartSummary?: GraphQLTypes["CartSummary"] | undefined,
	listCartSummary: Array<GraphQLTypes["CartSummary"]>,
	paginateCartSummary: GraphQLTypes["CartSummaryConnection"],
	getOrderSummary?: GraphQLTypes["OrderSummary"] | undefined,
	listOrderSummary: Array<GraphQLTypes["OrderSummary"]>,
	paginateOrderSummary: GraphQLTypes["OrderSummaryConnection"],
	getOrderPaymentMetadata?: GraphQLTypes["OrderPaymentMetadata"] | undefined,
	listOrderPaymentMetadata: Array<GraphQLTypes["OrderPaymentMetadata"]>,
	paginateOrderPaymentMetadata: GraphQLTypes["OrderPaymentMetadataConnection"],
	getUserMetadata?: GraphQLTypes["UserMetadata"] | undefined,
	listUserMetadata: Array<GraphQLTypes["UserMetadata"]>,
	paginateUserMetadata: GraphQLTypes["UserMetadataConnection"],
	validateCreateIdentificationAttempt: GraphQLTypes["_ValidationResult"],
	getIdentificationCode?: GraphQLTypes["IdentificationCode"] | undefined,
	listIdentificationCode: Array<GraphQLTypes["IdentificationCode"]>,
	paginateIdentificationCode: GraphQLTypes["IdentificationCodeConnection"],
	getIdentificationCodeLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	listIdentificationCodeLocale: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	paginateIdentificationCodeLocale: GraphQLTypes["IdentificationCodeLocaleConnection"],
	getFulfillmentNote?: GraphQLTypes["FulfillmentNote"] | undefined,
	listFulfillmentNote: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateFulfillmentNote: GraphQLTypes["FulfillmentNoteConnection"],
	getCustomerMetadata?: GraphQLTypes["CustomerMetadata"] | undefined,
	listCustomerMetadata: Array<GraphQLTypes["CustomerMetadata"]>,
	paginateCustomerMetadata: GraphQLTypes["CustomerMetadataConnection"],
	getSubscriptionBox?: GraphQLTypes["SubscriptionBox"] | undefined,
	listSubscriptionBox: Array<GraphQLTypes["SubscriptionBox"]>,
	paginateSubscriptionBox: GraphQLTypes["SubscriptionBoxConnection"],
	getSubscriptionBoxLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	listSubscriptionBoxLocale: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	paginateSubscriptionBoxLocale: GraphQLTypes["SubscriptionBoxLocaleConnection"],
	getGridTileItem?: GraphQLTypes["GridTileItem"] | undefined,
	listGridTileItem: Array<GraphQLTypes["GridTileItem"]>,
	paginateGridTileItem: GraphQLTypes["GridTileItemConnection"],
	getGridTiles?: GraphQLTypes["GridTiles"] | undefined,
	listGridTiles: Array<GraphQLTypes["GridTiles"]>,
	paginateGridTiles: GraphQLTypes["GridTilesConnection"],
	getPosition?: GraphQLTypes["Position"] | undefined,
	listPosition: Array<GraphQLTypes["Position"]>,
	paginatePosition: GraphQLTypes["PositionConnection"],
	getStatsWidget?: GraphQLTypes["StatsWidget"] | undefined,
	listStatsWidget: Array<GraphQLTypes["StatsWidget"]>,
	paginateStatsWidget: GraphQLTypes["StatsWidgetConnection"],
	getStatsWidgetsLayout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	listStatsWidgetsLayout: Array<GraphQLTypes["StatsWidgetsLayout"]>,
	paginateStatsWidgetsLayout: GraphQLTypes["StatsWidgetsLayoutConnection"],
	getStatsPage?: GraphQLTypes["StatsPage"] | undefined,
	listStatsPage: Array<GraphQLTypes["StatsPage"]>,
	paginateStatsPage: GraphQLTypes["StatsPageConnection"]
};
	["Info"]: {
		description?: string | undefined
};
	["Mutation"]: {
		createAddress: GraphQLTypes["AddressCreateResult"],
	deleteAddress: GraphQLTypes["AddressDeleteResult"],
	updateAddress: GraphQLTypes["AddressUpdateResult"],
	upsertAddress: GraphQLTypes["AddressUpsertResult"],
	updateCustomer: GraphQLTypes["CustomerUpdateResult"],
	createOrder: GraphQLTypes["OrderCreateResult"],
	createOrderDelivery: GraphQLTypes["OrderDeliveryCreateResult"],
	createOrderItem: GraphQLTypes["OrderItemCreateResult"],
	createCustomerName: GraphQLTypes["CustomerNameCreateResult"],
	updateCustomerName: GraphQLTypes["CustomerNameUpdateResult"],
	upsertCustomerName: GraphQLTypes["CustomerNameUpsertResult"],
	createCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingCreateResult"],
	deleteCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingDeleteResult"],
	updateCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingUpdateResult"],
	upsertCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingUpsertResult"],
	createCart: GraphQLTypes["CartCreateResult"],
	createCartItem: GraphQLTypes["CartItemCreateResult"],
	deleteCartItem: GraphQLTypes["CartItemDeleteResult"],
	updateCartItem: GraphQLTypes["CartItemUpdateResult"],
	upsertCartItem: GraphQLTypes["CartItemUpsertResult"],
	createGps: GraphQLTypes["GpsCreateResult"],
	updateGps: GraphQLTypes["GpsUpdateResult"],
	upsertGps: GraphQLTypes["GpsUpsertResult"],
	createBillingSubject: GraphQLTypes["BillingSubjectCreateResult"],
	updateBillingSubject: GraphQLTypes["BillingSubjectUpdateResult"],
	upsertBillingSubject: GraphQLTypes["BillingSubjectUpsertResult"],
	createAnonymousSession: GraphQLTypes["AnonymousSessionCreateResult"],
	deleteAnonymousSession: GraphQLTypes["AnonymousSessionDeleteResult"],
	updateAnonymousSession: GraphQLTypes["AnonymousSessionUpdateResult"],
	upsertAnonymousSession: GraphQLTypes["AnonymousSessionUpsertResult"],
	createIdentificationAttempt: GraphQLTypes["IdentificationAttemptCreateResult"],
	transaction: GraphQLTypes["MutationTransaction"],
	query: GraphQLTypes["Query"]
};
	["AddressCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Address"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationResult"]: ModelTypes["AddressCreateResult"] | ModelTypes["AddressDeleteResult"] | ModelTypes["AddressUpdateResult"] | ModelTypes["AddressUpsertResult"] | ModelTypes["CustomerUpdateResult"] | ModelTypes["OrderCreateResult"] | ModelTypes["OrderDeliveryCreateResult"] | ModelTypes["OrderItemCreateResult"] | ModelTypes["CustomerNameCreateResult"] | ModelTypes["CustomerNameUpdateResult"] | ModelTypes["CustomerNameUpsertResult"] | ModelTypes["CustomerIngredientRatingCreateResult"] | ModelTypes["CustomerIngredientRatingDeleteResult"] | ModelTypes["CustomerIngredientRatingUpdateResult"] | ModelTypes["CustomerIngredientRatingUpsertResult"] | ModelTypes["CartCreateResult"] | ModelTypes["CartItemCreateResult"] | ModelTypes["CartItemDeleteResult"] | ModelTypes["CartItemUpdateResult"] | ModelTypes["CartItemUpsertResult"] | ModelTypes["GpsCreateResult"] | ModelTypes["GpsUpdateResult"] | ModelTypes["GpsUpsertResult"] | ModelTypes["BillingSubjectCreateResult"] | ModelTypes["BillingSubjectUpdateResult"] | ModelTypes["BillingSubjectUpsertResult"] | ModelTypes["AnonymousSessionCreateResult"] | ModelTypes["AnonymousSessionDeleteResult"] | ModelTypes["AnonymousSessionUpdateResult"] | ModelTypes["AnonymousSessionUpsertResult"] | ModelTypes["IdentificationAttemptCreateResult"];
	["_MutationError"]: {
		path: Array<GraphQLTypes["_PathFragment"]>,
	paths: Array<Array<GraphQLTypes["_PathFragment"]>>,
	type: GraphQLTypes["_MutationErrorType"],
	message?: string | undefined
};
	["_MutationErrorType"]: GraphQLTypes["_MutationErrorType"];
	["AddressDeleteResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Address"] | undefined
};
	["AddressUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Address"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["AddressUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Address"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Customer"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["OrderCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Order"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["OrderDeliveryCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["OrderDelivery"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["OrderItemCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["OrderItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerNameCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerName"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerNameUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerName"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerNameUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerName"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerIngredientRatingCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerIngredientRatingDeleteResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerIngredientRating"] | undefined
};
	["CustomerIngredientRatingUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerIngredientRatingUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CartCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Cart"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CartItemCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CartItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CartItemDeleteResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CartItem"] | undefined
};
	["CartItemUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CartItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CartItemUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CartItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["GpsCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Gps"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["GpsUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Gps"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["GpsUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Gps"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["BillingSubjectCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["BillingSubject"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["BillingSubjectUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["BillingSubject"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["BillingSubjectUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["BillingSubject"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["AnonymousSessionCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["AnonymousSession"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["AnonymousSessionDeleteResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["AnonymousSession"] | undefined
};
	["AnonymousSessionUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["AnonymousSession"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["AnonymousSessionUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["AnonymousSession"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["IdentificationAttemptCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationTransaction"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"],
	createAddress: GraphQLTypes["AddressCreateResult"],
	deleteAddress: GraphQLTypes["AddressDeleteResult"],
	updateAddress: GraphQLTypes["AddressUpdateResult"],
	upsertAddress: GraphQLTypes["AddressUpsertResult"],
	updateCustomer: GraphQLTypes["CustomerUpdateResult"],
	createOrder: GraphQLTypes["OrderCreateResult"],
	createOrderDelivery: GraphQLTypes["OrderDeliveryCreateResult"],
	createOrderItem: GraphQLTypes["OrderItemCreateResult"],
	createCustomerName: GraphQLTypes["CustomerNameCreateResult"],
	updateCustomerName: GraphQLTypes["CustomerNameUpdateResult"],
	upsertCustomerName: GraphQLTypes["CustomerNameUpsertResult"],
	createCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingCreateResult"],
	deleteCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingDeleteResult"],
	updateCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingUpdateResult"],
	upsertCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingUpsertResult"],
	createCart: GraphQLTypes["CartCreateResult"],
	createCartItem: GraphQLTypes["CartItemCreateResult"],
	deleteCartItem: GraphQLTypes["CartItemDeleteResult"],
	updateCartItem: GraphQLTypes["CartItemUpdateResult"],
	upsertCartItem: GraphQLTypes["CartItemUpsertResult"],
	createGps: GraphQLTypes["GpsCreateResult"],
	updateGps: GraphQLTypes["GpsUpdateResult"],
	upsertGps: GraphQLTypes["GpsUpsertResult"],
	createBillingSubject: GraphQLTypes["BillingSubjectCreateResult"],
	updateBillingSubject: GraphQLTypes["BillingSubjectUpdateResult"],
	upsertBillingSubject: GraphQLTypes["BillingSubjectUpsertResult"],
	createAnonymousSession: GraphQLTypes["AnonymousSessionCreateResult"],
	deleteAnonymousSession: GraphQLTypes["AnonymousSessionDeleteResult"],
	updateAnonymousSession: GraphQLTypes["AnonymousSessionUpdateResult"],
	upsertAnonymousSession: GraphQLTypes["AnonymousSessionUpsertResult"],
	createIdentificationAttempt: GraphQLTypes["IdentificationAttemptCreateResult"],
	query?: GraphQLTypes["Query"] | undefined
};
	["MutationTransactionOptions"]: GraphQLTypes["MutationTransactionOptions"];
	["_Schema"]: {
		enums: Array<GraphQLTypes["_Enum"]>,
	entities: Array<GraphQLTypes["_Entity"]>
};
	["_Entity"]: {
		name: string,
	customPrimaryAllowed: boolean,
	fields: Array<GraphQLTypes["_Field"]>,
	unique: Array<GraphQLTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
		fields: Array<string>
};
	["_Field"]: ModelTypes["_Column"] | ModelTypes["_Relation"];
	["_Column"]: {
		name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: GraphQLTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_OnDeleteBehaviour"]: GraphQLTypes["_OnDeleteBehaviour"];
	["_RelationSide"]: GraphQLTypes["_RelationSide"];
	["_OrderByDirection"]: GraphQLTypes["_OrderByDirection"];
	["_OrderBy"]: {
		path: Array<string>,
	direction: GraphQLTypes["_OrderByDirection"]
};
	["_Relation"]: {
		name: string,
	type: string,
	side: GraphQLTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: GraphQLTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<GraphQLTypes["_OrderBy"]> | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_Rule"]: {
		message?: GraphQLTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_Validator"]: {
		operation: string,
	arguments: Array<GraphQLTypes["_Argument"]>
};
	["_Argument"]:ModelTypes["_ValidatorArgument"] | ModelTypes["_PathArgument"] | ModelTypes["_LiteralArgument"];
	["_ValidatorArgument"]: {
		validator: number
};
	["_PathArgument"]: {
		path: Array<string>
};
	["_LiteralArgument"]: {
		value?: GraphQLTypes["Json"] | undefined
};
	["_RuleMessage"]: {
		text?: string | undefined
};
	["_Enum"]: {
		name: string,
	values: Array<string>
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	getAddress?: GraphQLTypes["Address"] | undefined,
	listAddress: Array<GraphQLTypes["Address"]>,
	paginateAddress: GraphQLTypes["AddressConnection"],
	validateCreateAddress: GraphQLTypes["_ValidationResult"],
	validateUpdateAddress: GraphQLTypes["_ValidationResult"],
	getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getChannelPayment?: GraphQLTypes["ChannelPayment"] | undefined,
	listChannelPayment: Array<GraphQLTypes["ChannelPayment"]>,
	paginateChannelPayment: GraphQLTypes["ChannelPaymentConnection"],
	getChannelDelivery?: GraphQLTypes["ChannelDelivery"] | undefined,
	listChannelDelivery: Array<GraphQLTypes["ChannelDelivery"]>,
	paginateChannelDelivery: GraphQLTypes["ChannelDeliveryConnection"],
	getCustomer?: GraphQLTypes["Customer"] | undefined,
	listCustomer: Array<GraphQLTypes["Customer"]>,
	paginateCustomer: GraphQLTypes["CustomerConnection"],
	validateUpdateCustomer: GraphQLTypes["_ValidationResult"],
	getCustomerCredit?: GraphQLTypes["CustomerCredit"] | undefined,
	listCustomerCredit: Array<GraphQLTypes["CustomerCredit"]>,
	paginateCustomerCredit: GraphQLTypes["CustomerCreditConnection"],
	getCustomerGroup?: GraphQLTypes["CustomerGroup"] | undefined,
	listCustomerGroup: Array<GraphQLTypes["CustomerGroup"]>,
	paginateCustomerGroup: GraphQLTypes["CustomerGroupConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getOrder?: GraphQLTypes["Order"] | undefined,
	listOrder: Array<GraphQLTypes["Order"]>,
	paginateOrder: GraphQLTypes["OrderConnection"],
	validateCreateOrder: GraphQLTypes["_ValidationResult"],
	getOrderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	listOrderDiscount: Array<GraphQLTypes["OrderDiscount"]>,
	paginateOrderDiscount: GraphQLTypes["OrderDiscountConnection"],
	getOrderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	listOrderPayment: Array<GraphQLTypes["OrderPayment"]>,
	paginateOrderPayment: GraphQLTypes["OrderPaymentConnection"],
	getOrderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	listOrderDelivery: Array<GraphQLTypes["OrderDelivery"]>,
	paginateOrderDelivery: GraphQLTypes["OrderDeliveryConnection"],
	validateCreateOrderDelivery: GraphQLTypes["_ValidationResult"],
	getOrderItem?: GraphQLTypes["OrderItem"] | undefined,
	listOrderItem: Array<GraphQLTypes["OrderItem"]>,
	paginateOrderItem: GraphQLTypes["OrderItemConnection"],
	validateCreateOrderItem: GraphQLTypes["_ValidationResult"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductGroupPrice?: GraphQLTypes["ProductGroupPrice"] | undefined,
	listProductGroupPrice: Array<GraphQLTypes["ProductGroupPrice"]>,
	paginateProductGroupPrice: GraphQLTypes["ProductGroupPriceConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getCustomerPermissions?: GraphQLTypes["CustomerPermissions"] | undefined,
	listCustomerPermissions: Array<GraphQLTypes["CustomerPermissions"]>,
	paginateCustomerPermissions: GraphQLTypes["CustomerPermissionsConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getCustomerName?: GraphQLTypes["CustomerName"] | undefined,
	listCustomerName: Array<GraphQLTypes["CustomerName"]>,
	paginateCustomerName: GraphQLTypes["CustomerNameConnection"],
	validateCreateCustomerName: GraphQLTypes["_ValidationResult"],
	validateUpdateCustomerName: GraphQLTypes["_ValidationResult"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getCustomerIngredientRating?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	listCustomerIngredientRating: Array<GraphQLTypes["CustomerIngredientRating"]>,
	paginateCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingConnection"],
	validateCreateCustomerIngredientRating: GraphQLTypes["_ValidationResult"],
	validateUpdateCustomerIngredientRating: GraphQLTypes["_ValidationResult"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getBusinessCustomer?: GraphQLTypes["BusinessCustomer"] | undefined,
	listBusinessCustomer: Array<GraphQLTypes["BusinessCustomer"]>,
	paginateBusinessCustomer: GraphQLTypes["BusinessCustomerConnection"],
	getStaff?: GraphQLTypes["Staff"] | undefined,
	listStaff: Array<GraphQLTypes["Staff"]>,
	paginateStaff: GraphQLTypes["StaffConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getCart?: GraphQLTypes["Cart"] | undefined,
	listCart: Array<GraphQLTypes["Cart"]>,
	paginateCart: GraphQLTypes["CartConnection"],
	validateCreateCart: GraphQLTypes["_ValidationResult"],
	getCartItem?: GraphQLTypes["CartItem"] | undefined,
	listCartItem: Array<GraphQLTypes["CartItem"]>,
	paginateCartItem: GraphQLTypes["CartItemConnection"],
	validateCreateCartItem: GraphQLTypes["_ValidationResult"],
	validateUpdateCartItem: GraphQLTypes["_ValidationResult"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getUser?: GraphQLTypes["User"] | undefined,
	listUser: Array<GraphQLTypes["User"]>,
	paginateUser: GraphQLTypes["UserConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getGps?: GraphQLTypes["Gps"] | undefined,
	listGps: Array<GraphQLTypes["Gps"]>,
	paginateGps: GraphQLTypes["GpsConnection"],
	validateCreateGps: GraphQLTypes["_ValidationResult"],
	validateUpdateGps: GraphQLTypes["_ValidationResult"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getAddressMetadata?: GraphQLTypes["AddressMetadata"] | undefined,
	listAddressMetadata: Array<GraphQLTypes["AddressMetadata"]>,
	paginateAddressMetadata: GraphQLTypes["AddressMetadataConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getInvoice?: GraphQLTypes["Invoice"] | undefined,
	listInvoice: Array<GraphQLTypes["Invoice"]>,
	paginateInvoice: GraphQLTypes["InvoiceConnection"],
	getInvoicingPlan?: GraphQLTypes["InvoicingPlan"] | undefined,
	listInvoicingPlan: Array<GraphQLTypes["InvoicingPlan"]>,
	paginateInvoicingPlan: GraphQLTypes["InvoicingPlanConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getInvoicingPreset?: GraphQLTypes["InvoicingPreset"] | undefined,
	listInvoicingPreset: Array<GraphQLTypes["InvoicingPreset"]>,
	paginateInvoicingPreset: GraphQLTypes["InvoicingPresetConnection"],
	getBillingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	listBillingSubject: Array<GraphQLTypes["BillingSubject"]>,
	paginateBillingSubject: GraphQLTypes["BillingSubjectConnection"],
	validateCreateBillingSubject: GraphQLTypes["_ValidationResult"],
	validateUpdateBillingSubject: GraphQLTypes["_ValidationResult"],
	getProductCustomerPrice?: GraphQLTypes["ProductCustomerPrice"] | undefined,
	listProductCustomerPrice: Array<GraphQLTypes["ProductCustomerPrice"]>,
	paginateProductCustomerPrice: GraphQLTypes["ProductCustomerPriceConnection"],
	getCartPriceLine?: GraphQLTypes["CartPriceLine"] | undefined,
	listCartPriceLine: Array<GraphQLTypes["CartPriceLine"]>,
	paginateCartPriceLine: GraphQLTypes["CartPriceLineConnection"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getVirtualProductEffect?: GraphQLTypes["VirtualProductEffect"] | undefined,
	listVirtualProductEffect: Array<GraphQLTypes["VirtualProductEffect"]>,
	paginateVirtualProductEffect: GraphQLTypes["VirtualProductEffectConnection"],
	getOrderPriceLine?: GraphQLTypes["OrderPriceLine"] | undefined,
	listOrderPriceLine: Array<GraphQLTypes["OrderPriceLine"]>,
	paginateOrderPriceLine: GraphQLTypes["OrderPriceLineConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getAnonymousSession?: GraphQLTypes["AnonymousSession"] | undefined,
	listAnonymousSession: Array<GraphQLTypes["AnonymousSession"]>,
	paginateAnonymousSession: GraphQLTypes["AnonymousSessionConnection"],
	validateCreateAnonymousSession: GraphQLTypes["_ValidationResult"],
	validateUpdateAnonymousSession: GraphQLTypes["_ValidationResult"],
	getVoucher?: GraphQLTypes["Voucher"] | undefined,
	listVoucher: Array<GraphQLTypes["Voucher"]>,
	paginateVoucher: GraphQLTypes["VoucherConnection"],
	getVoucherRedemption?: GraphQLTypes["VoucherRedemption"] | undefined,
	listVoucherRedemption: Array<GraphQLTypes["VoucherRedemption"]>,
	paginateVoucherRedemption: GraphQLTypes["VoucherRedemptionConnection"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getWorkspace?: GraphQLTypes["Workspace"] | undefined,
	listWorkspace: Array<GraphQLTypes["Workspace"]>,
	paginateWorkspace: GraphQLTypes["WorkspaceConnection"],
	getCartSummary?: GraphQLTypes["CartSummary"] | undefined,
	listCartSummary: Array<GraphQLTypes["CartSummary"]>,
	paginateCartSummary: GraphQLTypes["CartSummaryConnection"],
	getOrderSummary?: GraphQLTypes["OrderSummary"] | undefined,
	listOrderSummary: Array<GraphQLTypes["OrderSummary"]>,
	paginateOrderSummary: GraphQLTypes["OrderSummaryConnection"],
	getOrderPaymentMetadata?: GraphQLTypes["OrderPaymentMetadata"] | undefined,
	listOrderPaymentMetadata: Array<GraphQLTypes["OrderPaymentMetadata"]>,
	paginateOrderPaymentMetadata: GraphQLTypes["OrderPaymentMetadataConnection"],
	getUserMetadata?: GraphQLTypes["UserMetadata"] | undefined,
	listUserMetadata: Array<GraphQLTypes["UserMetadata"]>,
	paginateUserMetadata: GraphQLTypes["UserMetadataConnection"],
	validateCreateIdentificationAttempt: GraphQLTypes["_ValidationResult"],
	getIdentificationCode?: GraphQLTypes["IdentificationCode"] | undefined,
	listIdentificationCode: Array<GraphQLTypes["IdentificationCode"]>,
	paginateIdentificationCode: GraphQLTypes["IdentificationCodeConnection"],
	getIdentificationCodeLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	listIdentificationCodeLocale: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	paginateIdentificationCodeLocale: GraphQLTypes["IdentificationCodeLocaleConnection"],
	getFulfillmentNote?: GraphQLTypes["FulfillmentNote"] | undefined,
	listFulfillmentNote: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateFulfillmentNote: GraphQLTypes["FulfillmentNoteConnection"],
	getCustomerMetadata?: GraphQLTypes["CustomerMetadata"] | undefined,
	listCustomerMetadata: Array<GraphQLTypes["CustomerMetadata"]>,
	paginateCustomerMetadata: GraphQLTypes["CustomerMetadataConnection"],
	getSubscriptionBox?: GraphQLTypes["SubscriptionBox"] | undefined,
	listSubscriptionBox: Array<GraphQLTypes["SubscriptionBox"]>,
	paginateSubscriptionBox: GraphQLTypes["SubscriptionBoxConnection"],
	getSubscriptionBoxLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	listSubscriptionBoxLocale: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	paginateSubscriptionBoxLocale: GraphQLTypes["SubscriptionBoxLocaleConnection"],
	getGridTileItem?: GraphQLTypes["GridTileItem"] | undefined,
	listGridTileItem: Array<GraphQLTypes["GridTileItem"]>,
	paginateGridTileItem: GraphQLTypes["GridTileItemConnection"],
	getGridTiles?: GraphQLTypes["GridTiles"] | undefined,
	listGridTiles: Array<GraphQLTypes["GridTiles"]>,
	paginateGridTiles: GraphQLTypes["GridTilesConnection"],
	getPosition?: GraphQLTypes["Position"] | undefined,
	listPosition: Array<GraphQLTypes["Position"]>,
	paginatePosition: GraphQLTypes["PositionConnection"],
	getStatsWidget?: GraphQLTypes["StatsWidget"] | undefined,
	listStatsWidget: Array<GraphQLTypes["StatsWidget"]>,
	paginateStatsWidget: GraphQLTypes["StatsWidgetConnection"],
	getStatsWidgetsLayout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	listStatsWidgetsLayout: Array<GraphQLTypes["StatsWidgetsLayout"]>,
	paginateStatsWidgetsLayout: GraphQLTypes["StatsWidgetsLayoutConnection"],
	getStatsPage?: GraphQLTypes["StatsPage"] | undefined,
	listStatsPage: Array<GraphQLTypes["StatsPage"]>,
	paginateStatsPage: GraphQLTypes["StatsPageConnection"],
	transaction?: GraphQLTypes["QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Info"] | undefined,
	schema?: GraphQLTypes["_Schema"] | undefined
};
	["Address"]: {
	__typename: "Address",
	_meta?: GraphQLTypes["AddressMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden: boolean,
	customer?: GraphQLTypes["Customer"] | undefined,
	country?: GraphQLTypes["Country"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	meta?: GraphQLTypes["AddressMetadata"] | undefined,
	gps?: GraphQLTypes["Gps"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices: Array<GraphQLTypes["Invoice"]>,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders: Array<GraphQLTypes["Order"]>,
	deliveryAddressOfOrderDeliveries: Array<GraphQLTypes["OrderDelivery"]>,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Customer"] | undefined,
	invoicesByPublicKey?: GraphQLTypes["Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Invoice"] | undefined,
	billingAddressOfOrdersBySeq?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDiscounts?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDelivery?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByItems?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByCart?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByPriceLines?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByPayments?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersBySummary?: GraphQLTypes["Order"] | undefined,
	deliveryAddressOfOrderDeliveriesByTrackingCode?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByOrder?: GraphQLTypes["OrderDelivery"] | undefined,
	paginateInvoices: GraphQLTypes["InvoiceConnection"],
	paginateBillingAddressOfOrders: GraphQLTypes["OrderConnection"],
	paginateDeliveryAddressOfOrderDeliveries: GraphQLTypes["OrderDeliveryConnection"]
};
	["AddressMeta"]: {
	__typename: "AddressMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	firstName?: GraphQLTypes["FieldMeta"] | undefined,
	lastName?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine2?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	companyName?: GraphQLTypes["FieldMeta"] | undefined,
	companyIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	vatIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	externalIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	hidden?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	country?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	googlePlaceId?: GraphQLTypes["FieldMeta"] | undefined,
	raw?: GraphQLTypes["FieldMeta"] | undefined,
	formatted?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeResponse?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZone?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	gps?: GraphQLTypes["FieldMeta"] | undefined,
	geocodeValid?: GraphQLTypes["FieldMeta"] | undefined,
	fullName?: GraphQLTypes["FieldMeta"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["FieldMeta"] | undefined,
	invoices?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine1?: GraphQLTypes["FieldMeta"] | undefined,
	city?: GraphQLTypes["FieldMeta"] | undefined,
	postalCode?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["FieldMeta"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
	__typename: "FieldMeta",
	readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]: string;
	["Customer"]: {
	__typename: "Customer",
	_meta?: GraphQLTypes["CustomerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	addresses: Array<GraphQLTypes["Address"]>,
	defaultBillingAddress?: GraphQLTypes["Address"] | undefined,
	tags: Array<GraphQLTypes["Tag"]>,
	credits: Array<GraphQLTypes["CustomerCredit"]>,
	orders: Array<GraphQLTypes["Order"]>,
	permissions?: GraphQLTypes["CustomerPermissions"] | undefined,
	group?: GraphQLTypes["CustomerGroup"] | undefined,
	photo?: GraphQLTypes["Image"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["CustomerName"] | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	ingredientRatings: Array<GraphQLTypes["CustomerIngredientRating"]>,
	business?: GraphQLTypes["BusinessCustomer"] | undefined,
	ownerOfBusinesses: Array<GraphQLTypes["BusinessCustomer"]>,
	memberOfBusinesses: Array<GraphQLTypes["BusinessCustomer"]>,
	carts: Array<GraphQLTypes["Cart"]>,
	user?: GraphQLTypes["User"] | undefined,
	channels: Array<GraphQLTypes["Channel"]>,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices: Array<GraphQLTypes["Invoice"]>,
	invoicingPlan?: GraphQLTypes["InvoicingPlan"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects: Array<GraphQLTypes["BillingSubject"]>,
	offeredProducts: Array<GraphQLTypes["ProductCustomerPrice"]>,
	defaultDeliveryAddress?: GraphQLTypes["Address"] | undefined,
	defaultBillingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	prefersPackingsWithoutCardboard: boolean,
	anonymousSession?: GraphQLTypes["AnonymousSession"] | undefined,
	boughtVouchers: Array<GraphQLTypes["Voucher"]>,
	ownsVouchers: Array<GraphQLTypes["Voucher"]>,
	redeemedVouchers: Array<GraphQLTypes["VoucherRedemption"]>,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["CustomerMetadata"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	addressesByMeta?: GraphQLTypes["Address"] | undefined,
	addressesByGps?: GraphQLTypes["Address"] | undefined,
	addressesByInvoices?: GraphQLTypes["Address"] | undefined,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	creditsByVoucherRedemption?: GraphQLTypes["CustomerCredit"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDiscounts?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByCart?: GraphQLTypes["Order"] | undefined,
	ordersByPriceLines?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	ordersBySummary?: GraphQLTypes["Order"] | undefined,
	ingredientRatingsByIngredient?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	cartsByItems?: GraphQLTypes["Cart"] | undefined,
	cartsByOrder?: GraphQLTypes["Cart"] | undefined,
	cartsByPriceLines?: GraphQLTypes["Cart"] | undefined,
	cartsBySummary?: GraphQLTypes["Cart"] | undefined,
	invoicesByPublicKey?: GraphQLTypes["Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Invoice"] | undefined,
	billingSubjectsByInvoices?: GraphQLTypes["BillingSubject"] | undefined,
	billingSubjectsByDefaultBillingSubjectOfCustomer?: GraphQLTypes["BillingSubject"] | undefined,
	billingSubjectsByOrders?: GraphQLTypes["BillingSubject"] | undefined,
	offeredProductsByProduct?: GraphQLTypes["ProductCustomerPrice"] | undefined,
	boughtVouchersByCode?: GraphQLTypes["Voucher"] | undefined,
	boughtVouchersByRedemption?: GraphQLTypes["Voucher"] | undefined,
	ownsVouchersByCode?: GraphQLTypes["Voucher"] | undefined,
	ownsVouchersByRedemption?: GraphQLTypes["Voucher"] | undefined,
	redeemedVouchersByCustomerCredit?: GraphQLTypes["VoucherRedemption"] | undefined,
	redeemedVouchersByOrderDiscount?: GraphQLTypes["VoucherRedemption"] | undefined,
	redeemedVouchersByVoucher?: GraphQLTypes["VoucherRedemption"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateCredits: GraphQLTypes["CustomerCreditConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"],
	paginateIngredientRatings: GraphQLTypes["CustomerIngredientRatingConnection"],
	paginateOwnerOfBusinesses: GraphQLTypes["BusinessCustomerConnection"],
	paginateMemberOfBusinesses: GraphQLTypes["BusinessCustomerConnection"],
	paginateCarts: GraphQLTypes["CartConnection"],
	paginateChannels: GraphQLTypes["ChannelConnection"],
	paginateInvoices: GraphQLTypes["InvoiceConnection"],
	paginateBillingSubjects: GraphQLTypes["BillingSubjectConnection"],
	paginateOfferedProducts: GraphQLTypes["ProductCustomerPriceConnection"],
	paginateBoughtVouchers: GraphQLTypes["VoucherConnection"],
	paginateOwnsVouchers: GraphQLTypes["VoucherConnection"],
	paginateRedeemedVouchers: GraphQLTypes["VoucherRedemptionConnection"]
};
	["CustomerMeta"]: {
	__typename: "CustomerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	addresses?: GraphQLTypes["FieldMeta"] | undefined,
	defaultBillingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	credits?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined,
	permissions?: GraphQLTypes["FieldMeta"] | undefined,
	group?: GraphQLTypes["FieldMeta"] | undefined,
	photo?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	welcomeMessage?: GraphQLTypes["FieldMeta"] | undefined,
	familiarity?: GraphQLTypes["FieldMeta"] | undefined,
	ingredientRatings?: GraphQLTypes["FieldMeta"] | undefined,
	business?: GraphQLTypes["FieldMeta"] | undefined,
	ownerOfBusinesses?: GraphQLTypes["FieldMeta"] | undefined,
	memberOfBusinesses?: GraphQLTypes["FieldMeta"] | undefined,
	carts?: GraphQLTypes["FieldMeta"] | undefined,
	user?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	disabledAt?: GraphQLTypes["FieldMeta"] | undefined,
	invoices?: GraphQLTypes["FieldMeta"] | undefined,
	invoicingPlan?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	billingSubjects?: GraphQLTypes["FieldMeta"] | undefined,
	offeredProducts?: GraphQLTypes["FieldMeta"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	defaultBillingSubject?: GraphQLTypes["FieldMeta"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["FieldMeta"] | undefined,
	anonymousSession?: GraphQLTypes["FieldMeta"] | undefined,
	boughtVouchers?: GraphQLTypes["FieldMeta"] | undefined,
	ownsVouchers?: GraphQLTypes["FieldMeta"] | undefined,
	redeemedVouchers?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["AddressWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	firstName?: GraphQLTypes["StringCondition"] | undefined,
	lastName?: GraphQLTypes["StringCondition"] | undefined,
	addressLine2?: GraphQLTypes["StringCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	phone?: GraphQLTypes["StringCondition"] | undefined,
	companyName?: GraphQLTypes["StringCondition"] | undefined,
	companyIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	vatIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	externalIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	hidden?: GraphQLTypes["BooleanCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	country?: GraphQLTypes["CountryWhere"] | undefined,
	deletedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	googlePlaceId?: GraphQLTypes["StringCondition"] | undefined,
	raw?: GraphQLTypes["StringCondition"] | undefined,
	formatted?: GraphQLTypes["StringCondition"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	geocodeResponse?: GraphQLTypes["StringCondition"] | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	meta?: GraphQLTypes["AddressMetadataWhere"] | undefined,
	gps?: GraphQLTypes["GpsWhere"] | undefined,
	geocodeValid?: GraphQLTypes["BooleanCondition"] | undefined,
	fullName?: GraphQLTypes["StringCondition"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["StringCondition"] | undefined,
	invoices?: GraphQLTypes["InvoiceWhere"] | undefined,
	addressLine1?: GraphQLTypes["StringCondition"] | undefined,
	city?: GraphQLTypes["StringCondition"] | undefined,
	postalCode?: GraphQLTypes["StringCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["OrderWhere"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["OrderDeliveryWhere"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["AddressWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AddressWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AddressWhere"] | undefined
};
	["UUIDCondition"]: {
		and?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	or?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	not?: GraphQLTypes["UUIDCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["UUID"] | undefined,
	notEq?: GraphQLTypes["UUID"] | undefined,
	in?: Array<GraphQLTypes["UUID"]> | undefined,
	notIn?: Array<GraphQLTypes["UUID"]> | undefined,
	lt?: GraphQLTypes["UUID"] | undefined,
	lte?: GraphQLTypes["UUID"] | undefined,
	gt?: GraphQLTypes["UUID"] | undefined,
	gte?: GraphQLTypes["UUID"] | undefined
};
	["StringCondition"]: {
		and?: Array<GraphQLTypes["StringCondition"]> | undefined,
	or?: Array<GraphQLTypes["StringCondition"]> | undefined,
	not?: GraphQLTypes["StringCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: string | undefined,
	notEq?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	containsCI?: string | undefined,
	startsWithCI?: string | undefined,
	endsWithCI?: string | undefined
};
	["BooleanCondition"]: {
		and?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	or?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	not?: GraphQLTypes["BooleanCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: boolean | undefined,
	notEq?: boolean | undefined,
	in?: Array<boolean> | undefined,
	notIn?: Array<boolean> | undefined,
	lt?: boolean | undefined,
	lte?: boolean | undefined,
	gt?: boolean | undefined,
	gte?: boolean | undefined
};
	["CustomerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	addresses?: GraphQLTypes["AddressWhere"] | undefined,
	defaultBillingAddress?: GraphQLTypes["AddressWhere"] | undefined,
	tags?: GraphQLTypes["TagWhere"] | undefined,
	credits?: GraphQLTypes["CustomerCreditWhere"] | undefined,
	orders?: GraphQLTypes["OrderWhere"] | undefined,
	permissions?: GraphQLTypes["CustomerPermissionsWhere"] | undefined,
	group?: GraphQLTypes["CustomerGroupWhere"] | undefined,
	photo?: GraphQLTypes["ImageWhere"] | undefined,
	phone?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["CustomerNameWhere"] | undefined,
	welcomeMessage?: GraphQLTypes["StringCondition"] | undefined,
	familiarity?: GraphQLTypes["CustomerFamiliarityCondition"] | undefined,
	ingredientRatings?: GraphQLTypes["CustomerIngredientRatingWhere"] | undefined,
	business?: GraphQLTypes["BusinessCustomerWhere"] | undefined,
	ownerOfBusinesses?: GraphQLTypes["BusinessCustomerWhere"] | undefined,
	memberOfBusinesses?: GraphQLTypes["BusinessCustomerWhere"] | undefined,
	carts?: GraphQLTypes["CartWhere"] | undefined,
	user?: GraphQLTypes["UserWhere"] | undefined,
	channels?: GraphQLTypes["ChannelWhere"] | undefined,
	disabledAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	invoices?: GraphQLTypes["InvoiceWhere"] | undefined,
	invoicingPlan?: GraphQLTypes["InvoicingPlanWhere"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	billingSubjects?: GraphQLTypes["BillingSubjectWhere"] | undefined,
	offeredProducts?: GraphQLTypes["ProductCustomerPriceWhere"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["AddressWhere"] | undefined,
	defaultBillingSubject?: GraphQLTypes["BillingSubjectWhere"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["BooleanCondition"] | undefined,
	anonymousSession?: GraphQLTypes["AnonymousSessionWhere"] | undefined,
	boughtVouchers?: GraphQLTypes["VoucherWhere"] | undefined,
	ownsVouchers?: GraphQLTypes["VoucherWhere"] | undefined,
	redeemedVouchers?: GraphQLTypes["VoucherRedemptionWhere"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["StringCondition"] | undefined,
	meta?: GraphQLTypes["CustomerMetadataWhere"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["CustomerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CustomerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CustomerWhere"] | undefined
};
	["TagWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	customers?: GraphQLTypes["CustomerWhere"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	categories?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["ChannelWhere"] | undefined,
	deliveryZones?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	publicInChannels?: GraphQLTypes["ChannelWhere"] | undefined,
	allowChannelPayments?: GraphQLTypes["ChannelPaymentWhere"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FulfillmentNoteWhere"] | undefined,
	and?: Array<GraphQLTypes["TagWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TagWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TagWhere"] | undefined
};
	["ProductWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	notDiscountable?: GraphQLTypes["BooleanCondition"] | undefined,
	packing?: GraphQLTypes["ProductPackingWhere"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	tags?: GraphQLTypes["TagWhere"] | undefined,
	categories?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	stocks?: GraphQLTypes["ProductStockWhere"] | undefined,
	groupPrices?: GraphQLTypes["ProductGroupPriceWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	cartItems?: GraphQLTypes["CartItemWhere"] | undefined,
	availability?: GraphQLTypes["ProductAvailabilityCondition"] | undefined,
	order?: GraphQLTypes["FloatCondition"] | undefined,
	orderItems?: GraphQLTypes["OrderItemWhere"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["IntCondition"] | undefined,
	deliveryZones?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	locales?: GraphQLTypes["ProductLocaleWhere"] | undefined,
	archivedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	customerPrices?: GraphQLTypes["ProductCustomerPriceWhere"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProductWhere"] | undefined,
	meta?: GraphQLTypes["ProductMetadataWhere"] | undefined,
	page?: GraphQLTypes["PageWhere"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FulfillmentNoteWhere"] | undefined,
	hasSubscription?: GraphQLTypes["BooleanCondition"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductWhere"] | undefined
};
	["IntCondition"]: {
		and?: Array<GraphQLTypes["IntCondition"]> | undefined,
	or?: Array<GraphQLTypes["IntCondition"]> | undefined,
	not?: GraphQLTypes["IntCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ProductPackingWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	volumeMl?: GraphQLTypes["IntCondition"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	shortName?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["ProductPackingLocaleWhere"] | undefined,
	isPackedInCardboard?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductPackingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductPackingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductPackingWhere"] | undefined
};
	["ImageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	alt?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageWhere"] | undefined
};
	["ProductPackingLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	shortName?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductPackingWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductPackingLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductPackingLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductPackingLocaleWhere"] | undefined
};
	["LocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	businessCategories?: GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined,
	productCategoryLists?: GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined,
	productIngredients?: GraphQLTypes["ProductIngredientLocaleWhere"] | undefined,
	orderRecurrences?: GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined,
	blogs?: GraphQLTypes["BlogLocaleWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined,
	pages?: GraphQLTypes["PageLocaleWhere"] | undefined,
	allergens?: GraphQLTypes["AllergenLocaleWhere"] | undefined,
	productPackings?: GraphQLTypes["ProductPackingLocaleWhere"] | undefined,
	products?: GraphQLTypes["ProductLocaleWhere"] | undefined,
	productRecipes?: GraphQLTypes["ProductRecipeLocaleWhere"] | undefined,
	deliveryMethods?: GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined,
	identificationCodes?: GraphQLTypes["IdentificationCodeLocaleWhere"] | undefined,
	subscriptionBoxes?: GraphQLTypes["SubscriptionBoxLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LocaleWhere"] | undefined
};
	["BusinessCategoryLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined
};
	["BusinessCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["BooleanCondition"] | undefined,
	locales?: GraphQLTypes["BusinessCategoryLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	businessCustomers?: GraphQLTypes["BusinessCustomerWhere"] | undefined,
	recurrences?: GraphQLTypes["OrderRecurrenceWhere"] | undefined,
	specificProducts?: GraphQLTypes["ProductListWhere"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	slug?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BusinessCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessCategoryWhere"] | undefined
};
	["BusinessCustomerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	owners?: GraphQLTypes["CustomerWhere"] | undefined,
	members?: GraphQLTypes["CustomerWhere"] | undefined,
	category?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["BusinessCustomerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BusinessCustomerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BusinessCustomerWhere"] | undefined
};
	["OrderRecurrenceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderRecurrenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderRecurrenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderRecurrenceWhere"] | undefined
};
	["OrderRecurrenceLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["OrderRecurrenceWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderRecurrenceLocaleWhere"] | undefined
};
	["ProductListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProductListItemWhere"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductListWhere"] | undefined
};
	["ProductListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProductListWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductListItemWhere"] | undefined
};
	["ProductCategoryListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProductCategoryListItemWhere"] | undefined,
	locales?: GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryListWhere"] | undefined
};
	["ProductCategoryListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	productCategory?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	showRecipeImages?: GraphQLTypes["BooleanCondition"] | undefined,
	showPackingImages?: GraphQLTypes["BooleanCondition"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryListItemWhere"] | undefined
};
	["ProductCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	tags?: GraphQLTypes["TagWhere"] | undefined,
	parent?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	children?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	color?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	order?: GraphQLTypes["FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryWhere"] | undefined
};
	["FloatCondition"]: {
		and?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	or?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	not?: GraphQLTypes["FloatCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ProductCategoryListLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryListLocaleWhere"] | undefined
};
	["ProductIngredientLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientLocaleWhere"] | undefined
};
	["ProductIngredientWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["ProductIngredientLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	allergens?: GraphQLTypes["AllergenWhere"] | undefined,
	needsPrepping?: GraphQLTypes["BooleanCondition"] | undefined,
	allowCustomerRating?: GraphQLTypes["BooleanCondition"] | undefined,
	customerRatings?: GraphQLTypes["CustomerIngredientRatingWhere"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientWhere"] | undefined
};
	["AllergenWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	locales?: GraphQLTypes["AllergenLocaleWhere"] | undefined,
	ingredients?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	code?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["AllergenWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AllergenWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AllergenWhere"] | undefined
};
	["AllergenLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["AllergenWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["AllergenLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AllergenLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AllergenLocaleWhere"] | undefined
};
	["CustomerIngredientRatingWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	rating?: GraphQLTypes["ProductIngredientRatingWhere"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	and?: Array<GraphQLTypes["CustomerIngredientRatingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CustomerIngredientRatingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CustomerIngredientRatingWhere"] | undefined
};
	["ProductIngredientRatingWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientRatingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientRatingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientRatingWhere"] | undefined
};
	["FreshingContainerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	volumeMl?: GraphQLTypes["IntCondition"] | undefined,
	workspace?: GraphQLTypes["WorkspaceWhere"] | undefined,
	and?: Array<GraphQLTypes["FreshingContainerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FreshingContainerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FreshingContainerWhere"] | undefined
};
	["WorkspaceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	orderDeadlineTime?: GraphQLTypes["StringCondition"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["IntCondition"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["IntCondition"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["IntCondition"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	orderUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	messengerUrl?: GraphQLTypes["StringCondition"] | undefined,
	messengerIosUrl?: GraphQLTypes["StringCondition"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["StringCondition"] | undefined,
	freshingContainers?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	openingHours?: GraphQLTypes["WeekHoursWhere"] | undefined,
	workingHours?: GraphQLTypes["WeekHoursWhere"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProductWhere"] | undefined,
	discountVatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	businessAddress?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["WorkspaceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["WorkspaceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["WorkspaceWhere"] | undefined
};
	["OneCondition"]: {
		and?: Array<GraphQLTypes["OneCondition"]> | undefined,
	or?: Array<GraphQLTypes["OneCondition"]> | undefined,
	not?: GraphQLTypes["OneCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["One"] | undefined,
	notEq?: GraphQLTypes["One"] | undefined,
	in?: Array<GraphQLTypes["One"]> | undefined,
	notIn?: Array<GraphQLTypes["One"]> | undefined,
	lt?: GraphQLTypes["One"] | undefined,
	lte?: GraphQLTypes["One"] | undefined,
	gt?: GraphQLTypes["One"] | undefined,
	gte?: GraphQLTypes["One"] | undefined
};
	["One"]: One;
	["WeekHoursWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	mon?: GraphQLTypes["StringCondition"] | undefined,
	tue?: GraphQLTypes["StringCondition"] | undefined,
	wed?: GraphQLTypes["StringCondition"] | undefined,
	thu?: GraphQLTypes["StringCondition"] | undefined,
	fri?: GraphQLTypes["StringCondition"] | undefined,
	sat?: GraphQLTypes["StringCondition"] | undefined,
	sun?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["WeekHoursWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["WeekHoursWhere"] | undefined> | undefined,
	not?: GraphQLTypes["WeekHoursWhere"] | undefined
};
	["VirtualProductWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["VirtualProductTypeCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	voucherCreditCents?: GraphQLTypes["IntCondition"] | undefined,
	physicalRepresentation?: GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined,
	recipientEmail?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["VirtualProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VirtualProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VirtualProductWhere"] | undefined
};
	["VirtualProductTypeCondition"]: {
		and?: Array<GraphQLTypes["VirtualProductTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["VirtualProductTypeCondition"]> | undefined,
	not?: GraphQLTypes["VirtualProductTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["VirtualProductType"] | undefined,
	notEq?: GraphQLTypes["VirtualProductType"] | undefined,
	in?: Array<GraphQLTypes["VirtualProductType"]> | undefined,
	notIn?: Array<GraphQLTypes["VirtualProductType"]> | undefined,
	lt?: GraphQLTypes["VirtualProductType"] | undefined,
	lte?: GraphQLTypes["VirtualProductType"] | undefined,
	gt?: GraphQLTypes["VirtualProductType"] | undefined,
	gte?: GraphQLTypes["VirtualProductType"] | undefined
};
	["VirtualProductType"]: VirtualProductType;
	["VirtualProductPhysicalRepresentationTypeCondition"]: {
		and?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"]> | undefined,
	not?: GraphQLTypes["VirtualProductPhysicalRepresentationTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	notEq?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	in?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationType"]> | undefined,
	notIn?: Array<GraphQLTypes["VirtualProductPhysicalRepresentationType"]> | undefined,
	lt?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	lte?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	gt?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined,
	gte?: GraphQLTypes["VirtualProductPhysicalRepresentationType"] | undefined
};
	["VirtualProductPhysicalRepresentationType"]: VirtualProductPhysicalRepresentationType;
	["VatRateWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	ratePermille?: GraphQLTypes["IntCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	channelDeliveries?: GraphQLTypes["ChannelDeliveryWhere"] | undefined,
	channelPayments?: GraphQLTypes["ChannelPaymentWhere"] | undefined,
	preset?: GraphQLTypes["VatRatePresetCondition"] | undefined,
	and?: Array<GraphQLTypes["VatRateWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VatRateWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VatRateWhere"] | undefined
};
	["ChannelDeliveryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	channel?: GraphQLTypes["ChannelWhere"] | undefined,
	method?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	defaultVisible?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["ChannelDeliveryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ChannelDeliveryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ChannelDeliveryWhere"] | undefined
};
	["ChannelWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	disabled?: GraphQLTypes["BooleanCondition"] | undefined,
	payments?: GraphQLTypes["ChannelPaymentWhere"] | undefined,
	deliveries?: GraphQLTypes["ChannelDeliveryWhere"] | undefined,
	carts?: GraphQLTypes["CartWhere"] | undefined,
	customers?: GraphQLTypes["CustomerWhere"] | undefined,
	code?: GraphQLTypes["ChannelCodeCondition"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["TagWhere"] | undefined,
	publicTags?: GraphQLTypes["TagWhere"] | undefined,
	and?: Array<GraphQLTypes["ChannelWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ChannelWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ChannelWhere"] | undefined
};
	["ChannelPaymentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	channel?: GraphQLTypes["ChannelWhere"] | undefined,
	method?: GraphQLTypes["PaymentMethodWhere"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	requireBillingAddress?: GraphQLTypes["BooleanCondition"] | undefined,
	provideReceipt?: GraphQLTypes["BooleanCondition"] | undefined,
	requireBillingSubject?: GraphQLTypes["BooleanCondition"] | undefined,
	allowOnlyForTags?: GraphQLTypes["TagWhere"] | undefined,
	and?: Array<GraphQLTypes["ChannelPaymentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ChannelPaymentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ChannelPaymentWhere"] | undefined
};
	["PaymentMethodWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	enabled?: GraphQLTypes["BooleanCondition"] | undefined,
	deliveryMethods?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	blockingDispatch?: GraphQLTypes["BooleanCondition"] | undefined,
	type?: GraphQLTypes["PaymentMethodTypeCondition"] | undefined,
	channels?: GraphQLTypes["ChannelPaymentWhere"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["PaymentMethodWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PaymentMethodWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PaymentMethodWhere"] | undefined
};
	["DeliveryMethodWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	enabled?: GraphQLTypes["BooleanCondition"] | undefined,
	paymentMethods?: GraphQLTypes["PaymentMethodWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["BooleanCondition"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	channels?: GraphQLTypes["ChannelDeliveryWhere"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["BooleanCondition"] | undefined,
	locales?: GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryMethodWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryMethodWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryMethodWhere"] | undefined
};
	["DeliveryZoneWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	type?: GraphQLTypes["DeliveryZoneTypeCondition"] | undefined,
	addresses?: GraphQLTypes["AddressWhere"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["TagWhere"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["ProductWhere"] | undefined,
	addressesMetadata?: GraphQLTypes["AddressMetadataWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryZoneWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryZoneWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryZoneWhere"] | undefined
};
	["DeliveryZoneTypeCondition"]: {
		and?: Array<GraphQLTypes["DeliveryZoneTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DeliveryZoneTypeCondition"]> | undefined,
	not?: GraphQLTypes["DeliveryZoneTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DeliveryZoneType"] | undefined,
	notEq?: GraphQLTypes["DeliveryZoneType"] | undefined,
	in?: Array<GraphQLTypes["DeliveryZoneType"]> | undefined,
	notIn?: Array<GraphQLTypes["DeliveryZoneType"]> | undefined,
	lt?: GraphQLTypes["DeliveryZoneType"] | undefined,
	lte?: GraphQLTypes["DeliveryZoneType"] | undefined,
	gt?: GraphQLTypes["DeliveryZoneType"] | undefined,
	gte?: GraphQLTypes["DeliveryZoneType"] | undefined
};
	["DeliveryZoneType"]: DeliveryZoneType;
	["AddressMetadataWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	deliveryZoneType?: GraphQLTypes["DeliveryZoneTypeCondition"] | undefined,
	address?: GraphQLTypes["AddressWhere"] | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZoneWhere"] | undefined,
	and?: Array<GraphQLTypes["AddressMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AddressMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AddressMetadataWhere"] | undefined
};
	["DeliveryMethodLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["StringCondition"] | undefined,
	processingTitle?: GraphQLTypes["StringCondition"] | undefined,
	processingText?: GraphQLTypes["StringCondition"] | undefined,
	processingIconUrl?: GraphQLTypes["StringCondition"] | undefined,
	dispatchedTitle?: GraphQLTypes["StringCondition"] | undefined,
	dispatchedText?: GraphQLTypes["StringCondition"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["StringCondition"] | undefined,
	fulfilledTitle?: GraphQLTypes["StringCondition"] | undefined,
	fulfilledText?: GraphQLTypes["StringCondition"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["StringCondition"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	root?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryMethodLocaleWhere"] | undefined
};
	["DeliveryTimelinePresetWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["DeliveryTimelineTypeCondition"] | undefined,
	duration?: GraphQLTypes["StringCondition"] | undefined,
	window?: GraphQLTypes["StringCondition"] | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryTimelinePresetWhere"] | undefined
};
	["DeliveryTimelineTypeCondition"]: {
		and?: Array<GraphQLTypes["DeliveryTimelineTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DeliveryTimelineTypeCondition"]> | undefined,
	not?: GraphQLTypes["DeliveryTimelineTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	notEq?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	in?: Array<GraphQLTypes["DeliveryTimelineType"]> | undefined,
	notIn?: Array<GraphQLTypes["DeliveryTimelineType"]> | undefined,
	lt?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	lte?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	gt?: GraphQLTypes["DeliveryTimelineType"] | undefined,
	gte?: GraphQLTypes["DeliveryTimelineType"] | undefined
};
	["DeliveryTimelineType"]: DeliveryTimelineType;
	["PaymentMethodTypeCondition"]: {
		and?: Array<GraphQLTypes["PaymentMethodTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["PaymentMethodTypeCondition"]> | undefined,
	not?: GraphQLTypes["PaymentMethodTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["PaymentMethodType"] | undefined,
	notEq?: GraphQLTypes["PaymentMethodType"] | undefined,
	in?: Array<GraphQLTypes["PaymentMethodType"]> | undefined,
	notIn?: Array<GraphQLTypes["PaymentMethodType"]> | undefined,
	lt?: GraphQLTypes["PaymentMethodType"] | undefined,
	lte?: GraphQLTypes["PaymentMethodType"] | undefined,
	gt?: GraphQLTypes["PaymentMethodType"] | undefined,
	gte?: GraphQLTypes["PaymentMethodType"] | undefined
};
	["PaymentMethodType"]: PaymentMethodType;
	["CartWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	confirmedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	deletedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	items?: GraphQLTypes["CartItemWhere"] | undefined,
	handle?: GraphQLTypes["StringCondition"] | undefined,
	state?: GraphQLTypes["CartStateCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	channel?: GraphQLTypes["ChannelWhere"] | undefined,
	order?: GraphQLTypes["OrderWhere"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	deliveryAddress?: GraphQLTypes["AddressWhere"] | undefined,
	billingAddress?: GraphQLTypes["AddressWhere"] | undefined,
	deliveryOption?: GraphQLTypes["StringCondition"] | undefined,
	paymentOption?: GraphQLTypes["StringCondition"] | undefined,
	delivery?: GraphQLTypes["ChannelDeliveryWhere"] | undefined,
	payment?: GraphQLTypes["ChannelPaymentWhere"] | undefined,
	billingSubject?: GraphQLTypes["BillingSubjectWhere"] | undefined,
	priceLines?: GraphQLTypes["CartPriceLineWhere"] | undefined,
	summary?: GraphQLTypes["CartSummaryWhere"] | undefined,
	and?: Array<GraphQLTypes["CartWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CartWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CartWhere"] | undefined
};
	["DateTimeCondition"]: {
		and?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	not?: GraphQLTypes["DateTimeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DateTime"] | undefined,
	notEq?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined
};
	["DateTime"]: string;
	["CartItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	quantity?: GraphQLTypes["IntCondition"] | undefined,
	cart?: GraphQLTypes["CartWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	fulfillmentNote?: GraphQLTypes["StringCondition"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FulfillmentNoteWhere"] | undefined,
	and?: Array<GraphQLTypes["CartItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CartItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CartItemWhere"] | undefined
};
	["FulfillmentNoteWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	tags?: GraphQLTypes["TagWhere"] | undefined,
	cartItems?: GraphQLTypes["CartItemWhere"] | undefined,
	orderItems?: GraphQLTypes["OrderItemWhere"] | undefined,
	orders?: GraphQLTypes["OrderWhere"] | undefined,
	highlight?: GraphQLTypes["BooleanCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	emoji?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["FulfillmentNoteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FulfillmentNoteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FulfillmentNoteWhere"] | undefined
};
	["OrderItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unitPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	quantity?: GraphQLTypes["IntCondition"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	order?: GraphQLTypes["OrderWhere"] | undefined,
	virtualProductEffects?: GraphQLTypes["VirtualProductEffectWhere"] | undefined,
	pickedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FulfillmentNoteWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderItemWhere"] | undefined
};
	["OrderWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	state?: GraphQLTypes["OrderStateCondition"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	canceledAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	channel?: GraphQLTypes["ChannelWhere"] | undefined,
	discounts?: GraphQLTypes["OrderDiscountWhere"] | undefined,
	delivery?: GraphQLTypes["OrderDeliveryWhere"] | undefined,
	items?: GraphQLTypes["OrderItemWhere"] | undefined,
	cart?: GraphQLTypes["CartWhere"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	billingAddress?: GraphQLTypes["AddressWhere"] | undefined,
	priceLines?: GraphQLTypes["OrderPriceLineWhere"] | undefined,
	payments?: GraphQLTypes["OrderPaymentWhere"] | undefined,
	seq?: GraphQLTypes["IntCondition"] | undefined,
	billingSubject?: GraphQLTypes["BillingSubjectWhere"] | undefined,
	summary?: GraphQLTypes["OrderSummaryWhere"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["StringCondition"] | undefined,
	fulfillmentNote?: GraphQLTypes["StringCondition"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FulfillmentNoteWhere"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["IntCondition"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["StringCondition"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["OrderWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderWhere"] | undefined
};
	["OrderStateCondition"]: {
		and?: Array<GraphQLTypes["OrderStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["OrderStateCondition"]> | undefined,
	not?: GraphQLTypes["OrderStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["OrderState"] | undefined,
	notEq?: GraphQLTypes["OrderState"] | undefined,
	in?: Array<GraphQLTypes["OrderState"]> | undefined,
	notIn?: Array<GraphQLTypes["OrderState"]> | undefined,
	lt?: GraphQLTypes["OrderState"] | undefined,
	lte?: GraphQLTypes["OrderState"] | undefined,
	gt?: GraphQLTypes["OrderState"] | undefined,
	gte?: GraphQLTypes["OrderState"] | undefined
};
	["OrderState"]: OrderState;
	["OrderDiscountWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	discountCents?: GraphQLTypes["IntCondition"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	order?: GraphQLTypes["OrderWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	type?: GraphQLTypes["OrderDiscountTypeCondition"] | undefined,
	voucherRedemption?: GraphQLTypes["VoucherRedemptionWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderDiscountWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderDiscountWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderDiscountWhere"] | undefined
};
	["OrderDiscountTypeCondition"]: {
		and?: Array<GraphQLTypes["OrderDiscountTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["OrderDiscountTypeCondition"]> | undefined,
	not?: GraphQLTypes["OrderDiscountTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["OrderDiscountType"] | undefined,
	notEq?: GraphQLTypes["OrderDiscountType"] | undefined,
	in?: Array<GraphQLTypes["OrderDiscountType"]> | undefined,
	notIn?: Array<GraphQLTypes["OrderDiscountType"]> | undefined,
	lt?: GraphQLTypes["OrderDiscountType"] | undefined,
	lte?: GraphQLTypes["OrderDiscountType"] | undefined,
	gt?: GraphQLTypes["OrderDiscountType"] | undefined,
	gte?: GraphQLTypes["OrderDiscountType"] | undefined
};
	["OrderDiscountType"]: OrderDiscountType;
	["VoucherRedemptionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	manualRedemptionNote?: GraphQLTypes["StringCondition"] | undefined,
	customerCredit?: GraphQLTypes["CustomerCreditWhere"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscountWhere"] | undefined,
	voucher?: GraphQLTypes["VoucherWhere"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["VoucherRedemptionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VoucherRedemptionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VoucherRedemptionWhere"] | undefined
};
	["CustomerCreditWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	initialCreditCents?: GraphQLTypes["IntCondition"] | undefined,
	remainingCreditsCents?: GraphQLTypes["IntCondition"] | undefined,
	expiresAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	reason?: GraphQLTypes["StringCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	voucherRedemption?: GraphQLTypes["VoucherRedemptionWhere"] | undefined,
	invoiceUrl?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["CustomerCreditWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CustomerCreditWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CustomerCreditWhere"] | undefined
};
	["VoucherWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	activatedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	expiresAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	type?: GraphQLTypes["VoucherTypeCondition"] | undefined,
	creditCents?: GraphQLTypes["IntCondition"] | undefined,
	buyer?: GraphQLTypes["CustomerWhere"] | undefined,
	owner?: GraphQLTypes["CustomerWhere"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	virtualProductEffect?: GraphQLTypes["VirtualProductEffectWhere"] | undefined,
	redemption?: GraphQLTypes["VoucherRedemptionWhere"] | undefined,
	and?: Array<GraphQLTypes["VoucherWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VoucherWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VoucherWhere"] | undefined
};
	["VoucherTypeCondition"]: {
		and?: Array<GraphQLTypes["VoucherTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["VoucherTypeCondition"]> | undefined,
	not?: GraphQLTypes["VoucherTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["VoucherType"] | undefined,
	notEq?: GraphQLTypes["VoucherType"] | undefined,
	in?: Array<GraphQLTypes["VoucherType"]> | undefined,
	notIn?: Array<GraphQLTypes["VoucherType"]> | undefined,
	lt?: GraphQLTypes["VoucherType"] | undefined,
	lte?: GraphQLTypes["VoucherType"] | undefined,
	gt?: GraphQLTypes["VoucherType"] | undefined,
	gte?: GraphQLTypes["VoucherType"] | undefined
};
	["VoucherType"]: VoucherType;
	["VirtualProductEffectWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	succeededAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	orderItem?: GraphQLTypes["OrderItemWhere"] | undefined,
	failedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	revertedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	resultVouchers?: GraphQLTypes["VoucherWhere"] | undefined,
	and?: Array<GraphQLTypes["VirtualProductEffectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VirtualProductEffectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VirtualProductEffectWhere"] | undefined
};
	["OrderDeliveryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	deliveredAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	method?: GraphQLTypes["DeliveryMethodWhere"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	order?: GraphQLTypes["OrderWhere"] | undefined,
	expectedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	state?: GraphQLTypes["OrderDeliveryStateCondition"] | undefined,
	loadingDurationHours?: GraphQLTypes["IntCondition"] | undefined,
	dispatchAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	duration?: GraphQLTypes["StringCondition"] | undefined,
	window?: GraphQLTypes["StringCondition"] | undefined,
	expectedEndAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	processingAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	deliveringAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	failedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	prepareAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	pickedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	address?: GraphQLTypes["AddressWhere"] | undefined,
	trackingCode?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["OrderDeliveryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderDeliveryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderDeliveryWhere"] | undefined
};
	["OrderDeliveryStateCondition"]: {
		and?: Array<GraphQLTypes["OrderDeliveryStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["OrderDeliveryStateCondition"]> | undefined,
	not?: GraphQLTypes["OrderDeliveryStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["OrderDeliveryState"] | undefined,
	notEq?: GraphQLTypes["OrderDeliveryState"] | undefined,
	in?: Array<GraphQLTypes["OrderDeliveryState"]> | undefined,
	notIn?: Array<GraphQLTypes["OrderDeliveryState"]> | undefined,
	lt?: GraphQLTypes["OrderDeliveryState"] | undefined,
	lte?: GraphQLTypes["OrderDeliveryState"] | undefined,
	gt?: GraphQLTypes["OrderDeliveryState"] | undefined,
	gte?: GraphQLTypes["OrderDeliveryState"] | undefined
};
	["OrderDeliveryState"]: OrderDeliveryState;
	["OrderPriceLineWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unitPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	quantity?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["OrderPriceLineTypeCondition"] | undefined,
	orderItem?: GraphQLTypes["OrderItemWhere"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscountWhere"] | undefined,
	orderPayment?: GraphQLTypes["OrderPaymentWhere"] | undefined,
	orderDelivery?: GraphQLTypes["OrderDeliveryWhere"] | undefined,
	vatRatePermille?: GraphQLTypes["IntCondition"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	order?: GraphQLTypes["OrderWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderPriceLineWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderPriceLineWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderPriceLineWhere"] | undefined
};
	["OrderPriceLineTypeCondition"]: {
		and?: Array<GraphQLTypes["OrderPriceLineTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["OrderPriceLineTypeCondition"]> | undefined,
	not?: GraphQLTypes["OrderPriceLineTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["OrderPriceLineType"] | undefined,
	notEq?: GraphQLTypes["OrderPriceLineType"] | undefined,
	in?: Array<GraphQLTypes["OrderPriceLineType"]> | undefined,
	notIn?: Array<GraphQLTypes["OrderPriceLineType"]> | undefined,
	lt?: GraphQLTypes["OrderPriceLineType"] | undefined,
	lte?: GraphQLTypes["OrderPriceLineType"] | undefined,
	gt?: GraphQLTypes["OrderPriceLineType"] | undefined,
	gte?: GraphQLTypes["OrderPriceLineType"] | undefined
};
	["OrderPriceLineType"]: OrderPriceLineType;
	["OrderPaymentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	data?: GraphQLTypes["JsonCondition"] | undefined,
	paymentCents?: GraphQLTypes["IntCondition"] | undefined,
	method?: GraphQLTypes["PaymentMethodWhere"] | undefined,
	failedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	state?: GraphQLTypes["OrderPaymentStateCondition"] | undefined,
	approvedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	order?: GraphQLTypes["OrderWhere"] | undefined,
	invoice?: GraphQLTypes["InvoiceWhere"] | undefined,
	walletType?: GraphQLTypes["StringCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	publicReceiptUrl?: GraphQLTypes["StringCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	meta?: GraphQLTypes["OrderPaymentMetadataWhere"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["OrderPaymentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderPaymentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderPaymentWhere"] | undefined
};
	["JsonCondition"]: {
		and?: Array<GraphQLTypes["JsonCondition"]> | undefined,
	or?: Array<GraphQLTypes["JsonCondition"]> | undefined,
	not?: GraphQLTypes["JsonCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined
};
	["OrderPaymentStateCondition"]: {
		and?: Array<GraphQLTypes["OrderPaymentStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["OrderPaymentStateCondition"]> | undefined,
	not?: GraphQLTypes["OrderPaymentStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["OrderPaymentState"] | undefined,
	notEq?: GraphQLTypes["OrderPaymentState"] | undefined,
	in?: Array<GraphQLTypes["OrderPaymentState"]> | undefined,
	notIn?: Array<GraphQLTypes["OrderPaymentState"]> | undefined,
	lt?: GraphQLTypes["OrderPaymentState"] | undefined,
	lte?: GraphQLTypes["OrderPaymentState"] | undefined,
	gt?: GraphQLTypes["OrderPaymentState"] | undefined,
	gte?: GraphQLTypes["OrderPaymentState"] | undefined
};
	["OrderPaymentState"]: OrderPaymentState;
	["InvoiceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	publicKey?: GraphQLTypes["StringCondition"] | undefined,
	fakturoidId?: GraphQLTypes["StringCondition"] | undefined,
	fakturoidData?: GraphQLTypes["StringCondition"] | undefined,
	legacyUrl?: GraphQLTypes["StringCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	orderPayments?: GraphQLTypes["OrderPaymentWhere"] | undefined,
	address?: GraphQLTypes["AddressWhere"] | undefined,
	publicUrl?: GraphQLTypes["StringCondition"] | undefined,
	billingSubject?: GraphQLTypes["BillingSubjectWhere"] | undefined,
	and?: Array<GraphQLTypes["InvoiceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["InvoiceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["InvoiceWhere"] | undefined
};
	["BillingSubjectWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	fullName?: GraphQLTypes["StringCondition"] | undefined,
	addressLine1?: GraphQLTypes["StringCondition"] | undefined,
	addressLine2?: GraphQLTypes["StringCondition"] | undefined,
	city?: GraphQLTypes["StringCondition"] | undefined,
	postalCode?: GraphQLTypes["StringCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	phone?: GraphQLTypes["StringCondition"] | undefined,
	companyName?: GraphQLTypes["StringCondition"] | undefined,
	companyIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	vatIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	externalIdentifier?: GraphQLTypes["StringCondition"] | undefined,
	hidden?: GraphQLTypes["BooleanCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	deletedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["StringCondition"] | undefined,
	country?: GraphQLTypes["CountryWhere"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	invoices?: GraphQLTypes["InvoiceWhere"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["CustomerWhere"] | undefined,
	orders?: GraphQLTypes["OrderWhere"] | undefined,
	and?: Array<GraphQLTypes["BillingSubjectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BillingSubjectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BillingSubjectWhere"] | undefined
};
	["CountryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["CountryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CountryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CountryWhere"] | undefined
};
	["OrderPaymentMetadataWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	paymentUrl?: GraphQLTypes["StringCondition"] | undefined,
	orderPayment?: GraphQLTypes["OrderPaymentWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderPaymentMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderPaymentMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderPaymentMetadataWhere"] | undefined
};
	["OrderSummaryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	totalPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	discountPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	undiscountedPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	itemsQuantity?: GraphQLTypes["IntCondition"] | undefined,
	orderUrl?: GraphQLTypes["StringCondition"] | undefined,
	orderNumber?: GraphQLTypes["StringCondition"] | undefined,
	order?: GraphQLTypes["OrderWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderSummaryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderSummaryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderSummaryWhere"] | undefined
};
	["CartStateCondition"]: {
		and?: Array<GraphQLTypes["CartStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["CartStateCondition"]> | undefined,
	not?: GraphQLTypes["CartStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["CartState"] | undefined,
	notEq?: GraphQLTypes["CartState"] | undefined,
	in?: Array<GraphQLTypes["CartState"]> | undefined,
	notIn?: Array<GraphQLTypes["CartState"]> | undefined,
	lt?: GraphQLTypes["CartState"] | undefined,
	lte?: GraphQLTypes["CartState"] | undefined,
	gt?: GraphQLTypes["CartState"] | undefined,
	gte?: GraphQLTypes["CartState"] | undefined
};
	["CartState"]: CartState;
	["CartPriceLineWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["CartPriceLineTypeCondition"] | undefined,
	item?: GraphQLTypes["CartItemWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	unitPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	quantity?: GraphQLTypes["IntCondition"] | undefined,
	vatRatePermille?: GraphQLTypes["IntCondition"] | undefined,
	vatRate?: GraphQLTypes["VatRateWhere"] | undefined,
	cart?: GraphQLTypes["CartWhere"] | undefined,
	and?: Array<GraphQLTypes["CartPriceLineWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CartPriceLineWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CartPriceLineWhere"] | undefined
};
	["CartPriceLineTypeCondition"]: {
		and?: Array<GraphQLTypes["CartPriceLineTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["CartPriceLineTypeCondition"]> | undefined,
	not?: GraphQLTypes["CartPriceLineTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["CartPriceLineType"] | undefined,
	notEq?: GraphQLTypes["CartPriceLineType"] | undefined,
	in?: Array<GraphQLTypes["CartPriceLineType"]> | undefined,
	notIn?: Array<GraphQLTypes["CartPriceLineType"]> | undefined,
	lt?: GraphQLTypes["CartPriceLineType"] | undefined,
	lte?: GraphQLTypes["CartPriceLineType"] | undefined,
	gt?: GraphQLTypes["CartPriceLineType"] | undefined,
	gte?: GraphQLTypes["CartPriceLineType"] | undefined
};
	["CartPriceLineType"]: CartPriceLineType;
	["CartSummaryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	itemsPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	deliveryPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	totalPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	itemsCount?: GraphQLTypes["IntCondition"] | undefined,
	itemsQuantity?: GraphQLTypes["IntCondition"] | undefined,
	checkoutUrl?: GraphQLTypes["StringCondition"] | undefined,
	cart?: GraphQLTypes["CartWhere"] | undefined,
	and?: Array<GraphQLTypes["CartSummaryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CartSummaryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CartSummaryWhere"] | undefined
};
	["ChannelCodeCondition"]: {
		and?: Array<GraphQLTypes["ChannelCodeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ChannelCodeCondition"]> | undefined,
	not?: GraphQLTypes["ChannelCodeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ChannelCode"] | undefined,
	notEq?: GraphQLTypes["ChannelCode"] | undefined,
	in?: Array<GraphQLTypes["ChannelCode"]> | undefined,
	notIn?: Array<GraphQLTypes["ChannelCode"]> | undefined,
	lt?: GraphQLTypes["ChannelCode"] | undefined,
	lte?: GraphQLTypes["ChannelCode"] | undefined,
	gt?: GraphQLTypes["ChannelCode"] | undefined,
	gte?: GraphQLTypes["ChannelCode"] | undefined
};
	["ChannelCode"]: ChannelCode;
	["VatRatePresetCondition"]: {
		and?: Array<GraphQLTypes["VatRatePresetCondition"]> | undefined,
	or?: Array<GraphQLTypes["VatRatePresetCondition"]> | undefined,
	not?: GraphQLTypes["VatRatePresetCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["VatRatePreset"] | undefined,
	notEq?: GraphQLTypes["VatRatePreset"] | undefined,
	in?: Array<GraphQLTypes["VatRatePreset"]> | undefined,
	notIn?: Array<GraphQLTypes["VatRatePreset"]> | undefined,
	lt?: GraphQLTypes["VatRatePreset"] | undefined,
	lte?: GraphQLTypes["VatRatePreset"] | undefined,
	gt?: GraphQLTypes["VatRatePreset"] | undefined,
	gte?: GraphQLTypes["VatRatePreset"] | undefined
};
	["VatRatePreset"]: VatRatePreset;
	["BlogLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	pageName?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	lead?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["BlogWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogLocaleWhere"] | undefined
};
	["BlogWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["BlogLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogWhere"] | undefined
};
	["BlogPostLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	availableForWeb?: GraphQLTypes["BooleanCondition"] | undefined,
	availableForMobile?: GraphQLTypes["BooleanCondition"] | undefined,
	root?: GraphQLTypes["BlogPostWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	cover?: GraphQLTypes["CoverWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostLocaleWhere"] | undefined
};
	["BlogPostWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	locales?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostWhere"] | undefined
};
	["CoverWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	primaryTitle?: GraphQLTypes["StringCondition"] | undefined,
	secondaryTitle?: GraphQLTypes["StringCondition"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	buttonColors?: GraphQLTypes["ColorPalleteWhere"] | undefined,
	and?: Array<GraphQLTypes["CoverWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CoverWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CoverWhere"] | undefined
};
	["MediumWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["MediumTypeCondition"] | undefined,
	colorTheme?: GraphQLTypes["ColorThemeCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	video?: GraphQLTypes["VideoWhere"] | undefined,
	and?: Array<GraphQLTypes["MediumWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["MediumWhere"] | undefined> | undefined,
	not?: GraphQLTypes["MediumWhere"] | undefined
};
	["MediumTypeCondition"]: {
		and?: Array<GraphQLTypes["MediumTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["MediumTypeCondition"]> | undefined,
	not?: GraphQLTypes["MediumTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["MediumType"] | undefined,
	notEq?: GraphQLTypes["MediumType"] | undefined,
	in?: Array<GraphQLTypes["MediumType"]> | undefined,
	notIn?: Array<GraphQLTypes["MediumType"]> | undefined,
	lt?: GraphQLTypes["MediumType"] | undefined,
	lte?: GraphQLTypes["MediumType"] | undefined,
	gt?: GraphQLTypes["MediumType"] | undefined,
	gte?: GraphQLTypes["MediumType"] | undefined
};
	["MediumType"]: MediumType;
	["ColorThemeCondition"]: {
		and?: Array<GraphQLTypes["ColorThemeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ColorThemeCondition"]> | undefined,
	not?: GraphQLTypes["ColorThemeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ColorTheme"] | undefined,
	notEq?: GraphQLTypes["ColorTheme"] | undefined,
	in?: Array<GraphQLTypes["ColorTheme"]> | undefined,
	notIn?: Array<GraphQLTypes["ColorTheme"]> | undefined,
	lt?: GraphQLTypes["ColorTheme"] | undefined,
	lte?: GraphQLTypes["ColorTheme"] | undefined,
	gt?: GraphQLTypes["ColorTheme"] | undefined,
	gte?: GraphQLTypes["ColorTheme"] | undefined
};
	["ColorTheme"]: ColorTheme;
	["VideoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	src?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	duration?: GraphQLTypes["FloatCondition"] | undefined,
	poster?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VideoWhere"] | undefined
};
	["ColorPalleteWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	background?: GraphQLTypes["StringCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ColorPalleteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ColorPalleteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ColorPalleteWhere"] | undefined
};
	["ContentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	blocks?: GraphQLTypes["ContentBlockWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentWhere"] | undefined
};
	["ContentBlockWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	json?: GraphQLTypes["StringCondition"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	references?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentBlockWhere"] | undefined
};
	["ContentReferenceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	primaryText?: GraphQLTypes["StringCondition"] | undefined,
	secondaryText?: GraphQLTypes["StringCondition"] | undefined,
	jsonContent?: GraphQLTypes["StringCondition"] | undefined,
	block?: GraphQLTypes["ContentBlockWhere"] | undefined,
	colorPallete?: GraphQLTypes["ColorPalleteWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostListWhere"] | undefined,
	links?: GraphQLTypes["LinkListWhere"] | undefined,
	products?: GraphQLTypes["ProductListWhere"] | undefined,
	contentSize?: GraphQLTypes["ContentSizeCondition"] | undefined,
	misc?: GraphQLTypes["StringCondition"] | undefined,
	hero?: GraphQLTypes["HeroWhere"] | undefined,
	gallery?: GraphQLTypes["GalleryWhere"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndAppsWhere"] | undefined,
	productGrid?: GraphQLTypes["ProductGridWhere"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerListWhere"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsWhere"] | undefined,
	textList?: GraphQLTypes["TextListWhere"] | undefined,
	gridTiles?: GraphQLTypes["GridTilesWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferenceWhere"] | undefined
};
	["ContentReferenceTypeCondition"]: {
		and?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	not?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentReferenceType"] | undefined,
	notEq?: GraphQLTypes["ContentReferenceType"] | undefined,
	in?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	lt?: GraphQLTypes["ContentReferenceType"] | undefined,
	lte?: GraphQLTypes["ContentReferenceType"] | undefined,
	gt?: GraphQLTypes["ContentReferenceType"] | undefined,
	gte?: GraphQLTypes["ContentReferenceType"] | undefined
};
	["ContentReferenceType"]: ContentReferenceType;
	["LinkWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["LinkTypeCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	externalLink?: GraphQLTypes["StringCondition"] | undefined,
	internalLink?: GraphQLTypes["LinkableWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkWhere"] | undefined
};
	["LinkTypeCondition"]: {
		and?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	not?: GraphQLTypes["LinkTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["LinkType"] | undefined,
	notEq?: GraphQLTypes["LinkType"] | undefined,
	in?: Array<GraphQLTypes["LinkType"]> | undefined,
	notIn?: Array<GraphQLTypes["LinkType"]> | undefined,
	lt?: GraphQLTypes["LinkType"] | undefined,
	lte?: GraphQLTypes["LinkType"] | undefined,
	gt?: GraphQLTypes["LinkType"] | undefined,
	gte?: GraphQLTypes["LinkType"] | undefined
};
	["LinkType"]: LinkType;
	["LinkableWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	page?: GraphQLTypes["PageLocaleWhere"] | undefined,
	redirect?: GraphQLTypes["RedirectWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkableWhere"] | undefined
};
	["PageLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	root?: GraphQLTypes["PageWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	cover?: GraphQLTypes["CoverWhere"] | undefined,
	theme?: GraphQLTypes["PageThemeCondition"] | undefined,
	and?: Array<GraphQLTypes["PageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PageLocaleWhere"] | undefined
};
	["PageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["PageLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["PageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PageWhere"] | undefined
};
	["SeoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	ogTitle?: GraphQLTypes["StringCondition"] | undefined,
	ogDescription?: GraphQLTypes["StringCondition"] | undefined,
	ogImage?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SeoWhere"] | undefined
};
	["PageThemeCondition"]: {
		and?: Array<GraphQLTypes["PageThemeCondition"]> | undefined,
	or?: Array<GraphQLTypes["PageThemeCondition"]> | undefined,
	not?: GraphQLTypes["PageThemeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["PageTheme"] | undefined,
	notEq?: GraphQLTypes["PageTheme"] | undefined,
	in?: Array<GraphQLTypes["PageTheme"]> | undefined,
	notIn?: Array<GraphQLTypes["PageTheme"]> | undefined,
	lt?: GraphQLTypes["PageTheme"] | undefined,
	lte?: GraphQLTypes["PageTheme"] | undefined,
	gt?: GraphQLTypes["PageTheme"] | undefined,
	gte?: GraphQLTypes["PageTheme"] | undefined
};
	["PageTheme"]: PageTheme;
	["RedirectWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	target?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["RedirectWhere"] | undefined
};
	["BlogPostListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["BlogPostListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostListWhere"] | undefined
};
	["BlogPostListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	caption?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["BlogPostListWhere"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["BlogPostListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["BlogPostListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["BlogPostListItemWhere"] | undefined
};
	["LinkListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["LinkListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkListWhere"] | undefined
};
	["LinkListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	isPrimary?: GraphQLTypes["BooleanCondition"] | undefined,
	list?: GraphQLTypes["LinkListWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkListItemWhere"] | undefined
};
	["ContentSizeCondition"]: {
		and?: Array<GraphQLTypes["ContentSizeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentSizeCondition"]> | undefined,
	not?: GraphQLTypes["ContentSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentSize"] | undefined,
	notEq?: GraphQLTypes["ContentSize"] | undefined,
	in?: Array<GraphQLTypes["ContentSize"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentSize"]> | undefined,
	lt?: GraphQLTypes["ContentSize"] | undefined,
	lte?: GraphQLTypes["ContentSize"] | undefined,
	gt?: GraphQLTypes["ContentSize"] | undefined,
	gte?: GraphQLTypes["ContentSize"] | undefined
};
	["ContentSize"]: ContentSize;
	["HeroWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	showLogo?: GraphQLTypes["BooleanCondition"] | undefined,
	middleContent?: GraphQLTypes["StringCondition"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	and?: Array<GraphQLTypes["HeroWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HeroWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HeroWhere"] | undefined
};
	["GalleryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["GalleryItemWhere"] | undefined,
	and?: Array<GraphQLTypes["GalleryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GalleryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GalleryWhere"] | undefined
};
	["GalleryItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	caption?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["GalleryWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	and?: Array<GraphQLTypes["GalleryItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GalleryItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GalleryItemWhere"] | undefined
};
	["SocialsAndAppsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	instagram?: GraphQLTypes["BooleanCondition"] | undefined,
	appStore?: GraphQLTypes["BooleanCondition"] | undefined,
	googlePlay?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["SocialsAndAppsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SocialsAndAppsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SocialsAndAppsWhere"] | undefined
};
	["ProductGridWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	isCarousel?: GraphQLTypes["BooleanCondition"] | undefined,
	categories?: GraphQLTypes["ProductGridCategoryWhere"] | undefined,
	type?: GraphQLTypes["ProductGridTypeCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductGridWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductGridWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductGridWhere"] | undefined
};
	["ProductGridCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductGridWhere"] | undefined,
	items?: GraphQLTypes["ProductGridItemWhere"] | undefined,
	images?: GraphQLTypes["ImageListWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductGridCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductGridCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductGridCategoryWhere"] | undefined
};
	["ProductGridItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	lead?: GraphQLTypes["StringCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	category?: GraphQLTypes["ProductGridCategoryWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductGridItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductGridItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductGridItemWhere"] | undefined
};
	["ImageListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ImageListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListWhere"] | undefined
};
	["ImageListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ImageListWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListItemWhere"] | undefined
};
	["ProductGridTypeCondition"]: {
		and?: Array<GraphQLTypes["ProductGridTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ProductGridTypeCondition"]> | undefined,
	not?: GraphQLTypes["ProductGridTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ProductGridType"] | undefined,
	notEq?: GraphQLTypes["ProductGridType"] | undefined,
	in?: Array<GraphQLTypes["ProductGridType"]> | undefined,
	notIn?: Array<GraphQLTypes["ProductGridType"]> | undefined,
	lt?: GraphQLTypes["ProductGridType"] | undefined,
	lte?: GraphQLTypes["ProductGridType"] | undefined,
	gt?: GraphQLTypes["ProductGridType"] | undefined,
	gte?: GraphQLTypes["ProductGridType"] | undefined
};
	["ProductGridType"]: ProductGridType;
	["ProductBannerListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProductBannerItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductBannerListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductBannerListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductBannerListWhere"] | undefined
};
	["ProductBannerItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProductBannerListWhere"] | undefined,
	banner?: GraphQLTypes["ProductBannerWhere"] | undefined,
	size?: GraphQLTypes["ProductBannerItemSizeCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductBannerItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductBannerItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductBannerItemWhere"] | undefined
};
	["ProductBannerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	beforeTitle?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	button?: GraphQLTypes["LinkWhere"] | undefined,
	backgroundImage?: GraphQLTypes["ImageWhere"] | undefined,
	productImage?: GraphQLTypes["ImageWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductBannerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductBannerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductBannerWhere"] | undefined
};
	["ProductBannerItemSizeCondition"]: {
		and?: Array<GraphQLTypes["ProductBannerItemSizeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ProductBannerItemSizeCondition"]> | undefined,
	not?: GraphQLTypes["ProductBannerItemSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	notEq?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	in?: Array<GraphQLTypes["ProductBannerItemSize"]> | undefined,
	notIn?: Array<GraphQLTypes["ProductBannerItemSize"]> | undefined,
	lt?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	lte?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	gt?: GraphQLTypes["ProductBannerItemSize"] | undefined,
	gte?: GraphQLTypes["ProductBannerItemSize"] | undefined
};
	["ProductBannerItemSize"]: ProductBannerItemSize;
	["DeliveryRegionsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["DeliveryRegionsItemWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryRegionsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryRegionsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryRegionsWhere"] | undefined
};
	["DeliveryRegionsItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	offerLabel?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["DeliveryRegionsWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	textList?: GraphQLTypes["TextListWhere"] | undefined,
	gallery?: GraphQLTypes["ImageListWhere"] | undefined,
	and?: Array<GraphQLTypes["DeliveryRegionsItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["DeliveryRegionsItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["DeliveryRegionsItemWhere"] | undefined
};
	["TextListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["TextItemWhere"] | undefined,
	and?: Array<GraphQLTypes["TextListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TextListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TextListWhere"] | undefined
};
	["TextItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["TextListWhere"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["TextItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TextItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TextItemWhere"] | undefined
};
	["GridTilesWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["GridTileItemWhere"] | undefined,
	and?: Array<GraphQLTypes["GridTilesWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GridTilesWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GridTilesWhere"] | undefined
};
	["GridTileItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["GridTilesWhere"] | undefined,
	medium?: GraphQLTypes["MediumWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["GridTileItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GridTileItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GridTileItemWhere"] | undefined
};
	["TranslationCataloguesIdentifierWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined
};
	["TranslationCatalogueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	domain?: GraphQLTypes["TranslationDomainWhere"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationCatalogueWhere"] | undefined
};
	["TranslationDomainWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	catalogues?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	keys?: GraphQLTypes["TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationDomainWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationDomainWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationDomainWhere"] | undefined
};
	["TranslationKeyWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	contentType?: GraphQLTypes["TranslationContentTypeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	domain?: GraphQLTypes["TranslationDomainWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationKeyWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationKeyWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationKeyWhere"] | undefined
};
	["TranslationContentTypeCondition"]: {
		and?: Array<GraphQLTypes["TranslationContentTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["TranslationContentTypeCondition"]> | undefined,
	not?: GraphQLTypes["TranslationContentTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["TranslationContentType"] | undefined,
	notEq?: GraphQLTypes["TranslationContentType"] | undefined,
	in?: Array<GraphQLTypes["TranslationContentType"]> | undefined,
	notIn?: Array<GraphQLTypes["TranslationContentType"]> | undefined,
	lt?: GraphQLTypes["TranslationContentType"] | undefined,
	lte?: GraphQLTypes["TranslationContentType"] | undefined,
	gt?: GraphQLTypes["TranslationContentType"] | undefined,
	gte?: GraphQLTypes["TranslationContentType"] | undefined
};
	["TranslationContentType"]: TranslationContentType;
	["TranslationValueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	value?: GraphQLTypes["StringCondition"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	key?: GraphQLTypes["TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationValueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationValueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationValueWhere"] | undefined
};
	["ProductLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	featureList?: GraphQLTypes["TextListWhere"] | undefined,
	printableName?: GraphQLTypes["StringCondition"] | undefined,
	firstSticker?: GraphQLTypes["StringCondition"] | undefined,
	secondSticker?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductLocaleWhere"] | undefined
};
	["ProductRecipeLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductRecipeLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductRecipeLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductRecipeLocaleWhere"] | undefined
};
	["ProductRecipeWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	color?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	ingredients?: GraphQLTypes["ProductIngredientItemWhere"] | undefined,
	needsPrepping?: GraphQLTypes["BooleanCondition"] | undefined,
	locales?: GraphQLTypes["ProductRecipeLocaleWhere"] | undefined,
	gutTuning?: GraphQLTypes["FloatCondition"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerWhere"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductRecipeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductRecipeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductRecipeWhere"] | undefined
};
	["ProductIngredientItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	internalOrder?: GraphQLTypes["IntCondition"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeWhere"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductIngredientItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductIngredientItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductIngredientItemWhere"] | undefined
};
	["IdentificationCodeLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	successMessage?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["IdentificationCodeWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["IdentificationCodeLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["IdentificationCodeLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["IdentificationCodeLocaleWhere"] | undefined
};
	["IdentificationCodeWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	locales?: GraphQLTypes["IdentificationCodeLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["IdentificationCodeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["IdentificationCodeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["IdentificationCodeWhere"] | undefined
};
	["SubscriptionBoxLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	stickerText?: GraphQLTypes["StringCondition"] | undefined,
	descriptionText?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["SubscriptionBoxWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	secondaryTitle?: GraphQLTypes["StringCondition"] | undefined,
	texts?: GraphQLTypes["TextListWhere"] | undefined,
	secondaryTexts?: GraphQLTypes["TextListWhere"] | undefined,
	and?: Array<GraphQLTypes["SubscriptionBoxLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SubscriptionBoxLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SubscriptionBoxLocaleWhere"] | undefined
};
	["SubscriptionBoxWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["SubscriptionBoxLocaleWhere"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	and?: Array<GraphQLTypes["SubscriptionBoxWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SubscriptionBoxWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SubscriptionBoxWhere"] | undefined
};
	["ProductStockWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	quantity?: GraphQLTypes["IntCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	store?: GraphQLTypes["StoreWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductStockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductStockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductStockWhere"] | undefined
};
	["StoreWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["StoreWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StoreWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StoreWhere"] | undefined
};
	["ProductGroupPriceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	group?: GraphQLTypes["CustomerGroupWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductGroupPriceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductGroupPriceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductGroupPriceWhere"] | undefined
};
	["CustomerGroupWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	productPrices?: GraphQLTypes["ProductGroupPriceWhere"] | undefined,
	customers?: GraphQLTypes["CustomerWhere"] | undefined,
	discountPermille?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["CustomerGroupWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CustomerGroupWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CustomerGroupWhere"] | undefined
};
	["ProductAvailabilityCondition"]: {
		and?: Array<GraphQLTypes["ProductAvailabilityCondition"]> | undefined,
	or?: Array<GraphQLTypes["ProductAvailabilityCondition"]> | undefined,
	not?: GraphQLTypes["ProductAvailabilityCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ProductAvailability"] | undefined,
	notEq?: GraphQLTypes["ProductAvailability"] | undefined,
	in?: Array<GraphQLTypes["ProductAvailability"]> | undefined,
	notIn?: Array<GraphQLTypes["ProductAvailability"]> | undefined,
	lt?: GraphQLTypes["ProductAvailability"] | undefined,
	lte?: GraphQLTypes["ProductAvailability"] | undefined,
	gt?: GraphQLTypes["ProductAvailability"] | undefined,
	gte?: GraphQLTypes["ProductAvailability"] | undefined
};
	["ProductAvailability"]: ProductAvailability;
	["ProductCustomerPriceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	groupDiscountPermille?: GraphQLTypes["IntCondition"] | undefined,
	groupPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	fullPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	discountedPriceCents?: GraphQLTypes["IntCondition"] | undefined,
	priceCents?: GraphQLTypes["IntCondition"] | undefined,
	offered?: GraphQLTypes["BooleanCondition"] | undefined,
	notDiscountable?: GraphQLTypes["BooleanCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCustomerPriceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCustomerPriceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCustomerPriceWhere"] | undefined
};
	["ProductMetadataWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	archived?: GraphQLTypes["BooleanCondition"] | undefined,
	available?: GraphQLTypes["BooleanCondition"] | undefined,
	availableQuantity?: GraphQLTypes["IntCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductMetadataWhere"] | undefined
};
	["CustomerPermissionsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	canPlaceOrder?: GraphQLTypes["BooleanCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["CustomerPermissionsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CustomerPermissionsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CustomerPermissionsWhere"] | undefined
};
	["CustomerNameWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	nominative?: GraphQLTypes["StringCondition"] | undefined,
	vocative?: GraphQLTypes["StringCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["CustomerNameWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CustomerNameWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CustomerNameWhere"] | undefined
};
	["CustomerFamiliarityCondition"]: {
		and?: Array<GraphQLTypes["CustomerFamiliarityCondition"]> | undefined,
	or?: Array<GraphQLTypes["CustomerFamiliarityCondition"]> | undefined,
	not?: GraphQLTypes["CustomerFamiliarityCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	notEq?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	in?: Array<GraphQLTypes["CustomerFamiliarity"]> | undefined,
	notIn?: Array<GraphQLTypes["CustomerFamiliarity"]> | undefined,
	lt?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	lte?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	gt?: GraphQLTypes["CustomerFamiliarity"] | undefined,
	gte?: GraphQLTypes["CustomerFamiliarity"] | undefined
};
	["CustomerFamiliarity"]: CustomerFamiliarity;
	["UserWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	staff?: GraphQLTypes["StaffWhere"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	tenantPersonId?: GraphQLTypes["UUIDCondition"] | undefined,
	disabledAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	verifiedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	deletedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	meta?: GraphQLTypes["UserMetadataWhere"] | undefined,
	and?: Array<GraphQLTypes["UserWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["UserWhere"] | undefined> | undefined,
	not?: GraphQLTypes["UserWhere"] | undefined
};
	["StaffWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	user?: GraphQLTypes["UserWhere"] | undefined,
	photo?: GraphQLTypes["ImageWhere"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["StaffWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StaffWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StaffWhere"] | undefined
};
	["UserMetadataWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	userProfileUrl?: GraphQLTypes["StringCondition"] | undefined,
	tenantEmail?: GraphQLTypes["StringCondition"] | undefined,
	tenantPersonId?: GraphQLTypes["UUIDCondition"] | undefined,
	tenantIdentityId?: GraphQLTypes["UUIDCondition"] | undefined,
	globalRoles?: GraphQLTypes["JsonCondition"] | undefined,
	projectRoles?: GraphQLTypes["JsonCondition"] | undefined,
	projectRolesString?: GraphQLTypes["StringCondition"] | undefined,
	externalIdentifiers?: GraphQLTypes["JsonCondition"] | undefined,
	externalIdentifiersString?: GraphQLTypes["StringCondition"] | undefined,
	idpSlugs?: GraphQLTypes["JsonCondition"] | undefined,
	idpSlugsString?: GraphQLTypes["StringCondition"] | undefined,
	user?: GraphQLTypes["UserWhere"] | undefined,
	and?: Array<GraphQLTypes["UserMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["UserMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["UserMetadataWhere"] | undefined
};
	["InvoicingPlanWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	preset?: GraphQLTypes["InvoicingPresetWhere"] | undefined,
	and?: Array<GraphQLTypes["InvoicingPlanWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["InvoicingPlanWhere"] | undefined> | undefined,
	not?: GraphQLTypes["InvoicingPlanWhere"] | undefined
};
	["InvoicingPresetWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["InvoicingPresetTypeCondition"] | undefined,
	startsAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	endsAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	intervalOfDays?: GraphQLTypes["IntCondition"] | undefined,
	invoicingPlan?: GraphQLTypes["InvoicingPlanWhere"] | undefined,
	and?: Array<GraphQLTypes["InvoicingPresetWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["InvoicingPresetWhere"] | undefined> | undefined,
	not?: GraphQLTypes["InvoicingPresetWhere"] | undefined
};
	["InvoicingPresetTypeCondition"]: {
		and?: Array<GraphQLTypes["InvoicingPresetTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["InvoicingPresetTypeCondition"]> | undefined,
	not?: GraphQLTypes["InvoicingPresetTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["InvoicingPresetType"] | undefined,
	notEq?: GraphQLTypes["InvoicingPresetType"] | undefined,
	in?: Array<GraphQLTypes["InvoicingPresetType"]> | undefined,
	notIn?: Array<GraphQLTypes["InvoicingPresetType"]> | undefined,
	lt?: GraphQLTypes["InvoicingPresetType"] | undefined,
	lte?: GraphQLTypes["InvoicingPresetType"] | undefined,
	gt?: GraphQLTypes["InvoicingPresetType"] | undefined,
	gte?: GraphQLTypes["InvoicingPresetType"] | undefined
};
	["InvoicingPresetType"]: InvoicingPresetType;
	["AnonymousSessionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	anonymousSessionKey?: GraphQLTypes["StringCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	internalNote?: GraphQLTypes["StringCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["AnonymousSessionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AnonymousSessionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AnonymousSessionWhere"] | undefined
};
	["CustomerMetadataWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	isBusiness?: GraphQLTypes["BooleanCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	fallbackName?: GraphQLTypes["StringCondition"] | undefined,
	remainingCreditsCents?: GraphQLTypes["IntCondition"] | undefined,
	ordersTotalCents?: GraphQLTypes["IntCondition"] | undefined,
	customerProfileUrl?: GraphQLTypes["StringCondition"] | undefined,
	customer?: GraphQLTypes["CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["CustomerMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["CustomerMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["CustomerMetadataWhere"] | undefined
};
	["GpsWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	latitude?: GraphQLTypes["FloatCondition"] | undefined,
	longitude?: GraphQLTypes["FloatCondition"] | undefined,
	address?: GraphQLTypes["AddressWhere"] | undefined,
	and?: Array<GraphQLTypes["GpsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GpsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GpsWhere"] | undefined
};
	["AddressOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	firstName?: GraphQLTypes["OrderDirection"] | undefined,
	lastName?: GraphQLTypes["OrderDirection"] | undefined,
	addressLine2?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	phone?: GraphQLTypes["OrderDirection"] | undefined,
	companyName?: GraphQLTypes["OrderDirection"] | undefined,
	companyIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	vatIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	externalIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	hidden?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	country?: GraphQLTypes["CountryOrderBy"] | undefined,
	deletedAt?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	googlePlaceId?: GraphQLTypes["OrderDirection"] | undefined,
	raw?: GraphQLTypes["OrderDirection"] | undefined,
	formatted?: GraphQLTypes["OrderDirection"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["OrderDirection"] | undefined,
	geocodeResponse?: GraphQLTypes["OrderDirection"] | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZoneOrderBy"] | undefined,
	meta?: GraphQLTypes["AddressMetadataOrderBy"] | undefined,
	gps?: GraphQLTypes["GpsOrderBy"] | undefined,
	geocodeValid?: GraphQLTypes["OrderDirection"] | undefined,
	fullName?: GraphQLTypes["OrderDirection"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["OrderDirection"] | undefined,
	addressLine1?: GraphQLTypes["OrderDirection"] | undefined,
	city?: GraphQLTypes["OrderDirection"] | undefined,
	postalCode?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["CustomerOrderBy"] | undefined
};
	["OrderDirection"]: OrderDirection;
	["CustomerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	defaultBillingAddress?: GraphQLTypes["AddressOrderBy"] | undefined,
	permissions?: GraphQLTypes["CustomerPermissionsOrderBy"] | undefined,
	group?: GraphQLTypes["CustomerGroupOrderBy"] | undefined,
	photo?: GraphQLTypes["ImageOrderBy"] | undefined,
	phone?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["CustomerNameOrderBy"] | undefined,
	welcomeMessage?: GraphQLTypes["OrderDirection"] | undefined,
	familiarity?: GraphQLTypes["OrderDirection"] | undefined,
	business?: GraphQLTypes["BusinessCustomerOrderBy"] | undefined,
	user?: GraphQLTypes["UserOrderBy"] | undefined,
	disabledAt?: GraphQLTypes["OrderDirection"] | undefined,
	invoicingPlan?: GraphQLTypes["InvoicingPlanOrderBy"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["AddressOrderBy"] | undefined,
	defaultBillingSubject?: GraphQLTypes["BillingSubjectOrderBy"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["OrderDirection"] | undefined,
	anonymousSession?: GraphQLTypes["AnonymousSessionOrderBy"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["OrderDirection"] | undefined,
	meta?: GraphQLTypes["CustomerMetadataOrderBy"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["CustomerPermissionsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	canPlaceOrder?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined
};
	["CustomerGroupOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	discountPermille?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	alt?: GraphQLTypes["OrderDirection"] | undefined
};
	["CustomerNameOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	nominative?: GraphQLTypes["OrderDirection"] | undefined,
	vocative?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined
};
	["BusinessCustomerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	category?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined
};
	["BusinessCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	specificProducts?: GraphQLTypes["ProductListOrderBy"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	slug?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined
};
	["ProductCategoryListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined
};
	["UserOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	staff?: GraphQLTypes["StaffOrderBy"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	tenantPersonId?: GraphQLTypes["OrderDirection"] | undefined,
	disabledAt?: GraphQLTypes["OrderDirection"] | undefined,
	verifiedAt?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	deletionRequestedAt?: GraphQLTypes["OrderDirection"] | undefined,
	deletionExpectedAt?: GraphQLTypes["OrderDirection"] | undefined,
	deletedAt?: GraphQLTypes["OrderDirection"] | undefined,
	meta?: GraphQLTypes["UserMetadataOrderBy"] | undefined
};
	["StaffOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	user?: GraphQLTypes["UserOrderBy"] | undefined,
	photo?: GraphQLTypes["ImageOrderBy"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["UserMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	userProfileUrl?: GraphQLTypes["OrderDirection"] | undefined,
	tenantEmail?: GraphQLTypes["OrderDirection"] | undefined,
	tenantPersonId?: GraphQLTypes["OrderDirection"] | undefined,
	tenantIdentityId?: GraphQLTypes["OrderDirection"] | undefined,
	globalRoles?: GraphQLTypes["OrderDirection"] | undefined,
	projectRoles?: GraphQLTypes["OrderDirection"] | undefined,
	projectRolesString?: GraphQLTypes["OrderDirection"] | undefined,
	externalIdentifiers?: GraphQLTypes["OrderDirection"] | undefined,
	externalIdentifiersString?: GraphQLTypes["OrderDirection"] | undefined,
	idpSlugs?: GraphQLTypes["OrderDirection"] | undefined,
	idpSlugsString?: GraphQLTypes["OrderDirection"] | undefined,
	user?: GraphQLTypes["UserOrderBy"] | undefined
};
	["InvoicingPlanOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	preset?: GraphQLTypes["InvoicingPresetOrderBy"] | undefined
};
	["InvoicingPresetOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	startsAt?: GraphQLTypes["OrderDirection"] | undefined,
	endsAt?: GraphQLTypes["OrderDirection"] | undefined,
	intervalOfDays?: GraphQLTypes["OrderDirection"] | undefined,
	invoicingPlan?: GraphQLTypes["InvoicingPlanOrderBy"] | undefined
};
	["BillingSubjectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	fullName?: GraphQLTypes["OrderDirection"] | undefined,
	addressLine1?: GraphQLTypes["OrderDirection"] | undefined,
	addressLine2?: GraphQLTypes["OrderDirection"] | undefined,
	city?: GraphQLTypes["OrderDirection"] | undefined,
	postalCode?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	phone?: GraphQLTypes["OrderDirection"] | undefined,
	companyName?: GraphQLTypes["OrderDirection"] | undefined,
	companyIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	vatIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	externalIdentifier?: GraphQLTypes["OrderDirection"] | undefined,
	hidden?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	deletedAt?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["OrderDirection"] | undefined,
	country?: GraphQLTypes["CountryOrderBy"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["CustomerOrderBy"] | undefined
};
	["CountryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["AnonymousSessionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	lastActiveAt?: GraphQLTypes["OrderDirection"] | undefined,
	anonymousSessionKey?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined
};
	["CustomerMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	isBusiness?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	fallbackName?: GraphQLTypes["OrderDirection"] | undefined,
	remainingCreditsCents?: GraphQLTypes["OrderDirection"] | undefined,
	ordersTotalCents?: GraphQLTypes["OrderDirection"] | undefined,
	customerProfileUrl?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined
};
	["DeliveryZoneOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined
};
	["AddressMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	deliveryZoneType?: GraphQLTypes["OrderDirection"] | undefined,
	address?: GraphQLTypes["AddressOrderBy"] | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZoneOrderBy"] | undefined
};
	["GpsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	latitude?: GraphQLTypes["OrderDirection"] | undefined,
	longitude?: GraphQLTypes["OrderDirection"] | undefined,
	address?: GraphQLTypes["AddressOrderBy"] | undefined
};
	["Tag"]: {
	__typename: "Tag",
	_meta?: GraphQLTypes["TagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	customers: Array<GraphQLTypes["Customer"]>,
	products: Array<GraphQLTypes["Product"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Channel"]>,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	publicInChannels: Array<GraphQLTypes["Channel"]>,
	allowChannelPayments: Array<GraphQLTypes["ChannelPayment"]>,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateCustomers: GraphQLTypes["CustomerConnection"],
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["ChannelConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginatePublicInChannels: GraphQLTypes["ChannelConnection"],
	paginateAllowChannelPayments: GraphQLTypes["ChannelPaymentConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["TagMeta"]: {
	__typename: "TagMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	customers?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	publicInChannels?: GraphQLTypes["FieldMeta"] | undefined,
	allowChannelPayments?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined
};
	["Product"]: {
	__typename: "Product",
	_meta?: GraphQLTypes["ProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	priceCents: number,
	notDiscountable: boolean,
	packing?: GraphQLTypes["ProductPacking"] | undefined,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	tags: Array<GraphQLTypes["Tag"]>,
	categories: Array<GraphQLTypes["ProductCategory"]>,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	stocks: Array<GraphQLTypes["ProductStock"]>,
	groupPrices: Array<GraphQLTypes["ProductGroupPrice"]>,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems: Array<GraphQLTypes["CartItem"]>,
	availability: GraphQLTypes["ProductAvailability"],
	order: number,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	maximumQuantityPerOrder?: number | undefined,
	deliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	locales: Array<GraphQLTypes["ProductLocale"]>,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices: Array<GraphQLTypes["ProductCustomerPrice"]>,
	virtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	meta?: GraphQLTypes["ProductMetadata"] | undefined,
	page?: GraphQLTypes["Page"] | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	hasSubscription: boolean,
	pointOfSaleTileColor?: string | undefined,
	stocksByStore?: GraphQLTypes["ProductStock"] | undefined,
	groupPricesByGroup?: GraphQLTypes["ProductGroupPrice"] | undefined,
	cartItemsByCart?: GraphQLTypes["CartItem"] | undefined,
	orderItemsByVirtualProductEffects?: GraphQLTypes["OrderItem"] | undefined,
	localesByLocale?: GraphQLTypes["ProductLocale"] | undefined,
	localesByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	customerPricesByCustomer?: GraphQLTypes["ProductCustomerPrice"] | undefined,
	paginateTags: GraphQLTypes["TagConnection"],
	paginateCategories: GraphQLTypes["ProductCategoryConnection"],
	paginateStocks: GraphQLTypes["ProductStockConnection"],
	paginateGroupPrices: GraphQLTypes["ProductGroupPriceConnection"],
	paginateCartItems: GraphQLTypes["CartItemConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["ProductLocaleConnection"],
	paginateCustomerPrices: GraphQLTypes["ProductCustomerPriceConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["ProductMeta"]: {
	__typename: "ProductMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["FieldMeta"] | undefined,
	packing?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	stocks?: GraphQLTypes["FieldMeta"] | undefined,
	groupPrices?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	cartItems?: GraphQLTypes["FieldMeta"] | undefined,
	availability?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["FieldMeta"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	archivedAt?: GraphQLTypes["FieldMeta"] | undefined,
	customerPrices?: GraphQLTypes["FieldMeta"] | undefined,
	virtualProduct?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	hasSubscription?: GraphQLTypes["FieldMeta"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPacking"]: {
	__typename: "ProductPacking",
	_meta?: GraphQLTypes["ProductPackingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	image?: GraphQLTypes["Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	shortName?: string | undefined,
	locales: Array<GraphQLTypes["ProductPackingLocale"]>,
	isPackedInCardboard: boolean,
	localesByLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductPackingLocaleConnection"]
};
	["ProductPackingMeta"]: {
	__typename: "ProductPackingMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	isPackedInCardboard?: GraphQLTypes["FieldMeta"] | undefined
};
	["Image"]: {
	__typename: "Image",
	_meta?: GraphQLTypes["ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined
};
	["ImageMeta"]: {
	__typename: "ImageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	alt?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductPackingLocale"]: {
	__typename: "ProductPackingLocale",
	_meta?: GraphQLTypes["ProductPackingLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	shortName?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["ProductPacking"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductPackingLocaleMeta"]: {
	__typename: "ProductPackingLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	shortName?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["Locale"]: {
	__typename: "Locale",
	_meta?: GraphQLTypes["LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	businessCategories: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	productCategoryLists: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	productIngredients: Array<GraphQLTypes["ProductIngredientLocale"]>,
	orderRecurrences: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	blogs: Array<GraphQLTypes["BlogLocale"]>,
	blogPosts: Array<GraphQLTypes["BlogPostLocale"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	pages: Array<GraphQLTypes["PageLocale"]>,
	allergens: Array<GraphQLTypes["AllergenLocale"]>,
	productPackings: Array<GraphQLTypes["ProductPackingLocale"]>,
	products: Array<GraphQLTypes["ProductLocale"]>,
	productRecipes: Array<GraphQLTypes["ProductRecipeLocale"]>,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	identificationCodes: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	subscriptionBoxes: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	businessCategoriesByRoot?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	productCategoryListsByRoot?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	productIngredientsByRoot?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	orderRecurrencesByRoot?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	blogsByRoot?: GraphQLTypes["BlogLocale"] | undefined,
	blogPostsByRoot?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	blogPostsBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	pagesByRoot?: GraphQLTypes["PageLocale"] | undefined,
	pagesByLink?: GraphQLTypes["PageLocale"] | undefined,
	pagesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	pagesByContent?: GraphQLTypes["PageLocale"] | undefined,
	pagesByCover?: GraphQLTypes["PageLocale"] | undefined,
	allergensByRoot?: GraphQLTypes["AllergenLocale"] | undefined,
	productPackingsByRoot?: GraphQLTypes["ProductPackingLocale"] | undefined,
	productsByRoot?: GraphQLTypes["ProductLocale"] | undefined,
	productsByFeatureList?: GraphQLTypes["ProductLocale"] | undefined,
	productRecipesByRoot?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	deliveryMethodsByRoot?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	identificationCodesByRoot?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	subscriptionBoxesByRoot?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesByTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesBySecondaryTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	paginateBusinessCategories: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateProductCategoryLists: GraphQLTypes["ProductCategoryListLocaleConnection"],
	paginateProductIngredients: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateOrderRecurrences: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBlogs: GraphQLTypes["BlogLocaleConnection"],
	paginateBlogPosts: GraphQLTypes["BlogPostLocaleConnection"],
	paginatePages: GraphQLTypes["PageLocaleConnection"],
	paginateAllergens: GraphQLTypes["AllergenLocaleConnection"],
	paginateProductPackings: GraphQLTypes["ProductPackingLocaleConnection"],
	paginateProducts: GraphQLTypes["ProductLocaleConnection"],
	paginateProductRecipes: GraphQLTypes["ProductRecipeLocaleConnection"],
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodLocaleConnection"],
	paginateIdentificationCodes: GraphQLTypes["IdentificationCodeLocaleConnection"],
	paginateSubscriptionBoxes: GraphQLTypes["SubscriptionBoxLocaleConnection"]
};
	["LocaleMeta"]: {
	__typename: "LocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategories?: GraphQLTypes["FieldMeta"] | undefined,
	productCategoryLists?: GraphQLTypes["FieldMeta"] | undefined,
	productIngredients?: GraphQLTypes["FieldMeta"] | undefined,
	orderRecurrences?: GraphQLTypes["FieldMeta"] | undefined,
	blogs?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	pages?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	productPackings?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	productRecipes?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	identificationCodes?: GraphQLTypes["FieldMeta"] | undefined,
	subscriptionBoxes?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocale"]: {
	__typename: "BusinessCategoryLocale",
	_meta?: GraphQLTypes["BusinessCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["BusinessCategory"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	title?: string | undefined
};
	["BusinessCategoryLocaleMeta"]: {
	__typename: "BusinessCategoryLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategory"]: {
	__typename: "BusinessCategory",
	_meta?: GraphQLTypes["BusinessCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	visibleToNewCustomers: boolean,
	locales: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	internalName: string,
	businessCustomers: Array<GraphQLTypes["BusinessCustomer"]>,
	recurrences: Array<GraphQLTypes["OrderRecurrence"]>,
	specificProducts?: GraphQLTypes["ProductList"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	slug: string,
	localesByLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	businessCustomersByCustomer?: GraphQLTypes["BusinessCustomer"] | undefined,
	paginateLocales: GraphQLTypes["BusinessCategoryLocaleConnection"],
	paginateBusinessCustomers: GraphQLTypes["BusinessCustomerConnection"],
	paginateRecurrences: GraphQLTypes["OrderRecurrenceConnection"]
};
	["BusinessCategoryMeta"]: {
	__typename: "BusinessCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	businessCustomers?: GraphQLTypes["FieldMeta"] | undefined,
	recurrences?: GraphQLTypes["FieldMeta"] | undefined,
	specificProducts?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined
};
	["BusinessCategoryLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BusinessCategoryOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined
};
	["LocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"] | undefined
};
	["TranslationCataloguesIdentifierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["BusinessCustomer"]: {
	__typename: "BusinessCustomer",
	_meta?: GraphQLTypes["BusinessCustomerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	customer?: GraphQLTypes["Customer"] | undefined,
	owners: Array<GraphQLTypes["Customer"]>,
	members: Array<GraphQLTypes["Customer"]>,
	category?: GraphQLTypes["BusinessCategory"] | undefined,
	logo?: GraphQLTypes["Image"] | undefined,
	note?: string | undefined,
	paginateOwners: GraphQLTypes["CustomerConnection"],
	paginateMembers: GraphQLTypes["CustomerConnection"]
};
	["BusinessCustomerMeta"]: {
	__typename: "BusinessCustomerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	owners?: GraphQLTypes["FieldMeta"] | undefined,
	members?: GraphQLTypes["FieldMeta"] | undefined,
	category?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerConnection"]: {
	__typename: "CustomerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerEdge"]>
};
	["PageInfo"]: {
	__typename: "PageInfo",
	totalCount: number
};
	["CustomerEdge"]: {
	__typename: "CustomerEdge",
	node: GraphQLTypes["Customer"]
};
	["OrderRecurrence"]: {
	__typename: "OrderRecurrence",
	_meta?: GraphQLTypes["OrderRecurrenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	order: number,
	businessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	localesByLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	paginateLocales: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"]
};
	["OrderRecurrenceMeta"]: {
	__typename: "OrderRecurrenceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocale"]: {
	__typename: "OrderRecurrenceLocale",
	_meta?: GraphQLTypes["OrderRecurrenceLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["OrderRecurrence"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["OrderRecurrenceLocaleMeta"]: {
	__typename: "OrderRecurrenceLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderRecurrenceLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["OrderRecurrenceOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["OrderRecurrenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderRecurrenceLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["LocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: GraphQLTypes["BusinessCategoryLocaleUniqueWhere"] | undefined,
	productCategoryLists?: GraphQLTypes["ProductCategoryListLocaleUniqueWhere"] | undefined,
	productIngredients?: GraphQLTypes["ProductIngredientLocaleUniqueWhere"] | undefined,
	orderRecurrences?: GraphQLTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined,
	blogs?: GraphQLTypes["BlogLocaleUniqueWhere"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	pages?: GraphQLTypes["PageLocaleUniqueWhere"] | undefined,
	allergens?: GraphQLTypes["AllergenLocaleUniqueWhere"] | undefined,
	productPackings?: GraphQLTypes["ProductPackingLocaleUniqueWhere"] | undefined,
	products?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined,
	productRecipes?: GraphQLTypes["ProductRecipeLocaleUniqueWhere"] | undefined,
	deliveryMethods?: GraphQLTypes["DeliveryMethodLocaleUniqueWhere"] | undefined,
	identificationCodes?: GraphQLTypes["IdentificationCodeLocaleUniqueWhere"] | undefined,
	subscriptionBoxes?: GraphQLTypes["SubscriptionBoxLocaleUniqueWhere"] | undefined
};
	["BusinessCategoryLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BusinessCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	slug?: string | undefined,
	locales?: GraphQLTypes["BusinessCategoryLocaleUniqueWhere"] | undefined,
	businessCustomers?: GraphQLTypes["BusinessCustomerUniqueWhere"] | undefined,
	specificProducts?: GraphQLTypes["ProductListUniqueWhere"] | undefined,
	seasonalProductCategories?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined
};
	["BusinessCustomerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	doubleshotLegacyId?: string | undefined,
	addresses?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	defaultBillingAddress?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	credits?: GraphQLTypes["CustomerCreditUniqueWhere"] | undefined,
	orders?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	permissions?: GraphQLTypes["CustomerPermissionsUniqueWhere"] | undefined,
	name?: GraphQLTypes["CustomerNameUniqueWhere"] | undefined,
	ingredientRatings?: GraphQLTypes["CustomerIngredientRatingUniqueWhere"] | undefined,
	business?: GraphQLTypes["BusinessCustomerUniqueWhere"] | undefined,
	carts?: GraphQLTypes["CartUniqueWhere"] | undefined,
	user?: GraphQLTypes["UserUniqueWhere"] | undefined,
	invoices?: GraphQLTypes["InvoiceUniqueWhere"] | undefined,
	invoicingPlan?: GraphQLTypes["InvoicingPlanUniqueWhere"] | undefined,
	billingSubjects?: GraphQLTypes["BillingSubjectUniqueWhere"] | undefined,
	offeredProducts?: GraphQLTypes["ProductCustomerPriceUniqueWhere"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	defaultBillingSubject?: GraphQLTypes["BillingSubjectUniqueWhere"] | undefined,
	anonymousSession?: GraphQLTypes["AnonymousSessionUniqueWhere"] | undefined,
	boughtVouchers?: GraphQLTypes["VoucherUniqueWhere"] | undefined,
	ownsVouchers?: GraphQLTypes["VoucherUniqueWhere"] | undefined,
	redeemedVouchers?: GraphQLTypes["VoucherRedemptionUniqueWhere"] | undefined,
	meta?: GraphQLTypes["CustomerMetadataUniqueWhere"] | undefined
};
	["AddressUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	meta?: GraphQLTypes["AddressMetadataUniqueWhere"] | undefined,
	gps?: GraphQLTypes["GpsUniqueWhere"] | undefined,
	invoices?: GraphQLTypes["InvoiceUniqueWhere"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["AddressMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	address?: GraphQLTypes["AddressUniqueWhere"] | undefined
};
	["GpsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	address?: GraphQLTypes["AddressUniqueWhere"] | undefined
};
	["InvoiceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	publicKey?: string | undefined,
	fakturoidId?: string | undefined,
	orderPayments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["OrderPaymentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	meta?: GraphQLTypes["OrderPaymentMetadataUniqueWhere"] | undefined
};
	["OrderPaymentMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	orderPayment?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["OrderUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	seq?: number | undefined,
	doubleshotLegacyId?: string | undefined,
	doubleShotLegacyNumber?: string | undefined,
	discounts?: GraphQLTypes["OrderDiscountUniqueWhere"] | undefined,
	delivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined,
	items?: GraphQLTypes["OrderItemUniqueWhere"] | undefined,
	cart?: GraphQLTypes["CartUniqueWhere"] | undefined,
	priceLines?: GraphQLTypes["OrderPriceLineUniqueWhere"] | undefined,
	payments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined,
	summary?: GraphQLTypes["OrderSummaryUniqueWhere"] | undefined
};
	["OrderDiscountUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	voucherRedemption?: GraphQLTypes["VoucherRedemptionUniqueWhere"] | undefined
};
	["VoucherRedemptionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customerCredit?: GraphQLTypes["CustomerCreditUniqueWhere"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscountUniqueWhere"] | undefined,
	voucher?: GraphQLTypes["VoucherUniqueWhere"] | undefined
};
	["CustomerCreditUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	voucherRedemption?: GraphQLTypes["VoucherRedemptionUniqueWhere"] | undefined
};
	["VoucherUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	redemption?: GraphQLTypes["VoucherRedemptionUniqueWhere"] | undefined
};
	["OrderDeliveryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	trackingCode?: string | undefined,
	order?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["OrderItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	virtualProductEffects?: GraphQLTypes["VirtualProductEffectUniqueWhere"] | undefined
};
	["VirtualProductEffectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	resultVouchers?: GraphQLTypes["VoucherUniqueWhere"] | undefined
};
	["CartUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["CartItemUniqueWhere"] | undefined,
	order?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	priceLines?: GraphQLTypes["CartPriceLineUniqueWhere"] | undefined,
	summary?: GraphQLTypes["CartSummaryUniqueWhere"] | undefined
};
	["CartItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	cart?: GraphQLTypes["CartUniqueWhere"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["ProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	stocks?: GraphQLTypes["ProductStockUniqueWhere"] | undefined,
	groupPrices?: GraphQLTypes["ProductGroupPriceUniqueWhere"] | undefined,
	cartItems?: GraphQLTypes["CartItemUniqueWhere"] | undefined,
	orderItems?: GraphQLTypes["OrderItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined,
	customerPrices?: GraphQLTypes["ProductCustomerPriceUniqueWhere"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProductUniqueWhere"] | undefined,
	meta?: GraphQLTypes["ProductMetadataUniqueWhere"] | undefined,
	page?: GraphQLTypes["PageUniqueWhere"] | undefined
};
	["ProductStockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	store?: GraphQLTypes["StoreUniqueWhere"] | undefined
};
	["StoreUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["ProductGroupPriceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	group?: GraphQLTypes["CustomerGroupUniqueWhere"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["CustomerGroupUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	productPrices?: GraphQLTypes["ProductGroupPriceUniqueWhere"] | undefined,
	customers?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["ProductLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	featureList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["TextListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["TextItemUniqueWhere"] | undefined
};
	["TextItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductCustomerPriceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["VirtualProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["ProductMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["PageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["PageLocaleUniqueWhere"] | undefined
};
	["PageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["PageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["LinkableUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined,
	page?: GraphQLTypes["PageLocaleUniqueWhere"] | undefined,
	redirect?: GraphQLTypes["RedirectUniqueWhere"] | undefined
};
	["BlogPostLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BlogPostUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	cover?: GraphQLTypes["CoverUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["BlogPostUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["BlogPostLocaleUniqueWhere"] | undefined
};
	["CoverUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ContentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	blocks?: GraphQLTypes["ContentBlockUniqueWhere"] | undefined
};
	["ContentBlockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentReferenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	products?: GraphQLTypes["ProductListUniqueWhere"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerListUniqueWhere"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsUniqueWhere"] | undefined
};
	["ProductListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductListItemUniqueWhere"] | undefined,
	businessCategory?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined
};
	["ProductListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductBannerListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductBannerItemUniqueWhere"] | undefined
};
	["ProductBannerItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["DeliveryRegionsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["DeliveryRegionsItemUniqueWhere"] | undefined
};
	["DeliveryRegionsItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined,
	textList?: GraphQLTypes["TextListUniqueWhere"] | undefined,
	gallery?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["ImageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ImageListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ImageListItemUniqueWhere"] | undefined
};
	["ImageListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["SeoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	ogImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["RedirectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["CartPriceLineUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["CartSummaryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	cart?: GraphQLTypes["CartUniqueWhere"] | undefined
};
	["OrderPriceLineUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	orderItem?: GraphQLTypes["OrderItemUniqueWhere"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscountUniqueWhere"] | undefined,
	orderPayment?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined,
	orderDelivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["OrderSummaryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["CustomerPermissionsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerNameUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerIngredientRatingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["ProductIngredientUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["ProductIngredientLocaleUniqueWhere"] | undefined,
	customerRatings?: GraphQLTypes["CustomerIngredientRatingUniqueWhere"] | undefined
};
	["ProductIngredientLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["UserUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["StaffUniqueWhere"] | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined,
	meta?: GraphQLTypes["UserMetadataUniqueWhere"] | undefined
};
	["StaffUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	user?: GraphQLTypes["UserUniqueWhere"] | undefined
};
	["UserMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	tenantEmail?: string | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	tenantIdentityId?: GraphQLTypes["UUID"] | undefined,
	user?: GraphQLTypes["UserUniqueWhere"] | undefined
};
	["InvoicingPlanUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined,
	preset?: GraphQLTypes["InvoicingPresetUniqueWhere"] | undefined
};
	["InvoicingPresetUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	invoicingPlan?: GraphQLTypes["InvoicingPlanUniqueWhere"] | undefined
};
	["BillingSubjectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	invoices?: GraphQLTypes["InvoiceUniqueWhere"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["CustomerUniqueWhere"] | undefined,
	orders?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["AnonymousSessionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	anonymousSessionKey?: string | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["ProductCategoryListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductCategoryListItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["ProductCategoryListLocaleUniqueWhere"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined
};
	["ProductCategoryListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductCategoryListLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["OrderRecurrenceLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["OrderRecurrenceUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["OrderRecurrenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["OrderRecurrenceLocaleUniqueWhere"] | undefined
};
	["BlogLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["BlogUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["BlogLocaleUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationCatalogueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	catalogues?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined,
	keys?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationKeyUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: string | undefined,
	values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationValueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined,
	key?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["AllergenLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["AllergenUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["AllergenUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: number | undefined,
	locales?: GraphQLTypes["AllergenLocaleUniqueWhere"] | undefined
};
	["ProductPackingLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductPackingUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductPackingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["ProductPackingLocaleUniqueWhere"] | undefined
};
	["ProductRecipeLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductRecipeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	products?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	ingredients?: GraphQLTypes["ProductIngredientItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["ProductRecipeLocaleUniqueWhere"] | undefined
};
	["ProductIngredientItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["DeliveryMethodLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	root?: GraphQLTypes["DeliveryMethodUniqueWhere"] | undefined
};
	["DeliveryMethodUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	channels?: GraphQLTypes["ChannelDeliveryUniqueWhere"] | undefined,
	locales?: GraphQLTypes["DeliveryMethodLocaleUniqueWhere"] | undefined,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePresetUniqueWhere"] | undefined
};
	["ChannelDeliveryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["DeliveryTimelinePresetUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethodUniqueWhere"] | undefined
};
	["IdentificationCodeLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["IdentificationCodeUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["IdentificationCodeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	locales?: GraphQLTypes["IdentificationCodeLocaleUniqueWhere"] | undefined
};
	["SubscriptionBoxLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["SubscriptionBoxUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	texts?: GraphQLTypes["TextListUniqueWhere"] | undefined,
	secondaryTexts?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["SubscriptionBoxUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["SubscriptionBoxLocaleUniqueWhere"] | undefined
};
	["OrderRecurrenceLocaleConnection"]: {
	__typename: "OrderRecurrenceLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceLocaleEdge"]>
};
	["OrderRecurrenceLocaleEdge"]: {
	__typename: "OrderRecurrenceLocaleEdge",
	node: GraphQLTypes["OrderRecurrenceLocale"]
};
	["BusinessCategoryConnection"]: {
	__typename: "BusinessCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryEdge"]>
};
	["BusinessCategoryEdge"]: {
	__typename: "BusinessCategoryEdge",
	node: GraphQLTypes["BusinessCategory"]
};
	["ProductList"]: {
	__typename: "ProductList",
	_meta?: GraphQLTypes["ProductListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductListItem"]>,
	businessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	paginateItems: GraphQLTypes["ProductListItemConnection"]
};
	["ProductListMeta"]: {
	__typename: "ProductListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItem"]: {
	__typename: "ProductListItem",
	_meta?: GraphQLTypes["ProductListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductList"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	defaultQuantityForOrder?: number | undefined
};
	["ProductListItemMeta"]: {
	__typename: "ProductListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProductListOrderBy"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	notDiscountable?: GraphQLTypes["OrderDirection"] | undefined,
	packing?: GraphQLTypes["ProductPackingOrderBy"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	availability?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["OrderDirection"] | undefined,
	archivedAt?: GraphQLTypes["OrderDirection"] | undefined,
	virtualProduct?: GraphQLTypes["VirtualProductOrderBy"] | undefined,
	meta?: GraphQLTypes["ProductMetadataOrderBy"] | undefined,
	page?: GraphQLTypes["PageOrderBy"] | undefined,
	hasSubscription?: GraphQLTypes["OrderDirection"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductPackingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	volumeMl?: GraphQLTypes["OrderDirection"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined,
	shortName?: GraphQLTypes["OrderDirection"] | undefined,
	isPackedInCardboard?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductRecipeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	color?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined,
	needsPrepping?: GraphQLTypes["OrderDirection"] | undefined,
	gutTuning?: GraphQLTypes["OrderDirection"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["FreshingContainerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	volumeMl?: GraphQLTypes["OrderDirection"] | undefined,
	workspace?: GraphQLTypes["WorkspaceOrderBy"] | undefined
};
	["WorkspaceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	orderDeadlineTime?: GraphQLTypes["OrderDirection"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["OrderDirection"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["OrderDirection"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["OrderDirection"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	orderUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	messengerUrl?: GraphQLTypes["OrderDirection"] | undefined,
	messengerIosUrl?: GraphQLTypes["OrderDirection"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["OrderDirection"] | undefined,
	openingHours?: GraphQLTypes["WeekHoursOrderBy"] | undefined,
	workingHours?: GraphQLTypes["WeekHoursOrderBy"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainerOrderBy"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProductOrderBy"] | undefined,
	discountVatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	businessAddress?: GraphQLTypes["OrderDirection"] | undefined
};
	["WeekHoursOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	mon?: GraphQLTypes["OrderDirection"] | undefined,
	tue?: GraphQLTypes["OrderDirection"] | undefined,
	wed?: GraphQLTypes["OrderDirection"] | undefined,
	thu?: GraphQLTypes["OrderDirection"] | undefined,
	fri?: GraphQLTypes["OrderDirection"] | undefined,
	sat?: GraphQLTypes["OrderDirection"] | undefined,
	sun?: GraphQLTypes["OrderDirection"] | undefined
};
	["VirtualProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	voucherCreditCents?: GraphQLTypes["OrderDirection"] | undefined,
	physicalRepresentation?: GraphQLTypes["OrderDirection"] | undefined,
	recipientEmail?: GraphQLTypes["OrderDirection"] | undefined
};
	["VatRateOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	ratePermille?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	preset?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	archived?: GraphQLTypes["OrderDirection"] | undefined,
	available?: GraphQLTypes["OrderDirection"] | undefined,
	availableQuantity?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["PageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductListItemConnection"]: {
	__typename: "ProductListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListItemEdge"]>
};
	["ProductListItemEdge"]: {
	__typename: "ProductListItemEdge",
	node: GraphQLTypes["ProductListItem"]
};
	["ProductCategoryList"]: {
	__typename: "ProductCategoryList",
	_meta?: GraphQLTypes["ProductCategoryListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductCategoryListItem"]>,
	locales: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	localesByLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	paginateItems: GraphQLTypes["ProductCategoryListItemConnection"],
	paginateLocales: GraphQLTypes["ProductCategoryListLocaleConnection"]
};
	["ProductCategoryListMeta"]: {
	__typename: "ProductCategoryListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListItem"]: {
	__typename: "ProductCategoryListItem",
	_meta?: GraphQLTypes["ProductCategoryListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductCategoryList"] | undefined,
	productCategory?: GraphQLTypes["ProductCategory"] | undefined,
	showRecipeImages: boolean,
	showPackingImages: boolean,
	showAllAvailableProducts: boolean
};
	["ProductCategoryListItemMeta"]: {
	__typename: "ProductCategoryListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["FieldMeta"] | undefined,
	showRecipeImages?: GraphQLTypes["FieldMeta"] | undefined,
	showPackingImages?: GraphQLTypes["FieldMeta"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategory"]: {
	__typename: "ProductCategory",
	_meta?: GraphQLTypes["ProductCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Tag"]>,
	parent?: GraphQLTypes["ProductCategory"] | undefined,
	children: Array<GraphQLTypes["ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	order: number,
	childrenByCode?: GraphQLTypes["ProductCategory"] | undefined,
	childrenByChildren?: GraphQLTypes["ProductCategory"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateChildren: GraphQLTypes["ProductCategoryConnection"]
};
	["ProductCategoryMeta"]: {
	__typename: "ProductCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	parent?: GraphQLTypes["FieldMeta"] | undefined,
	children?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["TagOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	parent?: GraphQLTypes["ProductCategoryOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	color?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryChildrenByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["ProductCategoryChildrenByChildrenUniqueWhere"]: {
		children?: GraphQLTypes["ProductCategoryUniqueWhere"] | undefined
};
	["ProductCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	children?: GraphQLTypes["ProductCategoryUniqueWhere"] | undefined
};
	["ProductConnection"]: {
	__typename: "ProductConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductEdge"]>
};
	["ProductEdge"]: {
	__typename: "ProductEdge",
	node: GraphQLTypes["Product"]
};
	["TagConnection"]: {
	__typename: "TagConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TagEdge"]>
};
	["TagEdge"]: {
	__typename: "TagEdge",
	node: GraphQLTypes["Tag"]
};
	["ProductCategoryConnection"]: {
	__typename: "ProductCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryEdge"]>
};
	["ProductCategoryEdge"]: {
	__typename: "ProductCategoryEdge",
	node: GraphQLTypes["ProductCategory"]
};
	["ProductCategoryListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	productCategory?: GraphQLTypes["ProductCategoryOrderBy"] | undefined,
	showRecipeImages?: GraphQLTypes["OrderDirection"] | undefined,
	showPackingImages?: GraphQLTypes["OrderDirection"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryListLocale"]: {
	__typename: "ProductCategoryListLocale",
	_meta?: GraphQLTypes["ProductCategoryListLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductCategoryList"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductCategoryListLocaleMeta"]: {
	__typename: "ProductCategoryListLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryListLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductCategoryListLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductCategoryListItemConnection"]: {
	__typename: "ProductCategoryListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListItemEdge"]>
};
	["ProductCategoryListItemEdge"]: {
	__typename: "ProductCategoryListItemEdge",
	node: GraphQLTypes["ProductCategoryListItem"]
};
	["ProductCategoryListLocaleConnection"]: {
	__typename: "ProductCategoryListLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListLocaleEdge"]>
};
	["ProductCategoryListLocaleEdge"]: {
	__typename: "ProductCategoryListLocaleEdge",
	node: GraphQLTypes["ProductCategoryListLocale"]
};
	["BusinessCategoryLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BusinessCategoryBusinessCustomersByCustomerUniqueWhere"]: {
		customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["BusinessCategoryLocaleConnection"]: {
	__typename: "BusinessCategoryLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCategoryLocaleEdge"]>
};
	["BusinessCategoryLocaleEdge"]: {
	__typename: "BusinessCategoryLocaleEdge",
	node: GraphQLTypes["BusinessCategoryLocale"]
};
	["BusinessCustomerConnection"]: {
	__typename: "BusinessCustomerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BusinessCustomerEdge"]>
};
	["BusinessCustomerEdge"]: {
	__typename: "BusinessCustomerEdge",
	node: GraphQLTypes["BusinessCustomer"]
};
	["OrderRecurrenceConnection"]: {
	__typename: "OrderRecurrenceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderRecurrenceEdge"]>
};
	["OrderRecurrenceEdge"]: {
	__typename: "OrderRecurrenceEdge",
	node: GraphQLTypes["OrderRecurrence"]
};
	["ProductIngredientLocale"]: {
	__typename: "ProductIngredientLocale",
	_meta?: GraphQLTypes["ProductIngredientLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductIngredient"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductIngredientLocaleMeta"]: {
	__typename: "ProductIngredientLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredient"]: {
	__typename: "ProductIngredient",
	_meta?: GraphQLTypes["ProductIngredientMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["ProductIngredientLocale"]>,
	internalName: string,
	allergens: Array<GraphQLTypes["Allergen"]>,
	needsPrepping: boolean,
	allowCustomerRating?: boolean | undefined,
	customerRatings: Array<GraphQLTypes["CustomerIngredientRating"]>,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	incrementQuantityNumber: number,
	localesByLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	customerRatingsByCustomer?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	paginateLocales: GraphQLTypes["ProductIngredientLocaleConnection"],
	paginateAllergens: GraphQLTypes["AllergenConnection"],
	paginateCustomerRatings: GraphQLTypes["CustomerIngredientRatingConnection"]
};
	["ProductIngredientMeta"]: {
	__typename: "ProductIngredientMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	allergens?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	allowCustomerRating?: GraphQLTypes["FieldMeta"] | undefined,
	customerRatings?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductIngredientOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductIngredientOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	needsPrepping?: GraphQLTypes["OrderDirection"] | undefined,
	allowCustomerRating?: GraphQLTypes["OrderDirection"] | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainerOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["OrderDirection"] | undefined
};
	["Allergen"]: {
	__typename: "Allergen",
	_meta?: GraphQLTypes["AllergenMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["AllergenLocale"]>,
	ingredients: Array<GraphQLTypes["ProductIngredient"]>,
	code: number,
	localesByLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	paginateLocales: GraphQLTypes["AllergenLocaleConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientConnection"]
};
	["AllergenMeta"]: {
	__typename: "AllergenMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocale"]: {
	__typename: "AllergenLocale",
	_meta?: GraphQLTypes["AllergenLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	root?: GraphQLTypes["Allergen"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["AllergenLocaleMeta"]: {
	__typename: "AllergenLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["AllergenLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["AllergenOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["AllergenOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["AllergenLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["AllergenLocaleConnection"]: {
	__typename: "AllergenLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenLocaleEdge"]>
};
	["AllergenLocaleEdge"]: {
	__typename: "AllergenLocaleEdge",
	node: GraphQLTypes["AllergenLocale"]
};
	["ProductIngredientConnection"]: {
	__typename: "ProductIngredientConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientEdge"]>
};
	["ProductIngredientEdge"]: {
	__typename: "ProductIngredientEdge",
	node: GraphQLTypes["ProductIngredient"]
};
	["CustomerIngredientRating"]: {
	__typename: "CustomerIngredientRating",
	_meta?: GraphQLTypes["CustomerIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	customer?: GraphQLTypes["Customer"] | undefined,
	rating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined
};
	["CustomerIngredientRatingMeta"]: {
	__typename: "CustomerIngredientRatingMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	rating?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientRating"]: {
	__typename: "ProductIngredientRating",
	_meta?: GraphQLTypes["ProductIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	icon?: GraphQLTypes["Image"] | undefined
};
	["ProductIngredientRatingMeta"]: {
	__typename: "ProductIngredientRatingMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerIngredientRatingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	rating?: GraphQLTypes["ProductIngredientRatingOrderBy"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientOrderBy"] | undefined
};
	["ProductIngredientRatingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["FreshingContainer"]: {
	__typename: "FreshingContainer",
	_meta?: GraphQLTypes["FreshingContainerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	volumeMl: number,
	workspace?: GraphQLTypes["Workspace"] | undefined
};
	["FreshingContainerMeta"]: {
	__typename: "FreshingContainerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	workspace?: GraphQLTypes["FieldMeta"] | undefined
};
	["Workspace"]: {
	__typename: "Workspace",
	_meta?: GraphQLTypes["WorkspaceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	orderDeadlineTime?: string | undefined,
	ordersLoadingDurationHours: number,
	freshingContainerVolumeMl: number,
	freshingPrepareOrderByOffsetMinutes: number,
	checkoutUrlTemplate?: string | undefined,
	orderUrlTemplate?: string | undefined,
	paymentUrlTemplate?: string | undefined,
	customerProfileUrlTemplate?: string | undefined,
	userProfileUrlTemplate?: string | undefined,
	messengerUrl?: string | undefined,
	messengerIosUrl?: string | undefined,
	messengerAndroidUrl?: string | undefined,
	freshingContainers: Array<GraphQLTypes["FreshingContainer"]>,
	openingHours?: GraphQLTypes["WeekHours"] | undefined,
	workingHours?: GraphQLTypes["WeekHours"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	discountVatRate?: GraphQLTypes["VatRate"] | undefined,
	name: string,
	businessAddress?: string | undefined,
	paginateFreshingContainers: GraphQLTypes["FreshingContainerConnection"]
};
	["WorkspaceMeta"]: {
	__typename: "WorkspaceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	orderDeadlineTime?: GraphQLTypes["FieldMeta"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["FieldMeta"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["FieldMeta"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	orderUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	messengerUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerIosUrl?: GraphQLTypes["FieldMeta"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainers?: GraphQLTypes["FieldMeta"] | undefined,
	openingHours?: GraphQLTypes["FieldMeta"] | undefined,
	workingHours?: GraphQLTypes["FieldMeta"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	creditsProduct?: GraphQLTypes["FieldMeta"] | undefined,
	discountVatRate?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	businessAddress?: GraphQLTypes["FieldMeta"] | undefined
};
	["WeekHours"]: {
	__typename: "WeekHours",
	_meta?: GraphQLTypes["WeekHoursMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	mon?: string | undefined,
	tue?: string | undefined,
	wed?: string | undefined,
	thu?: string | undefined,
	fri?: string | undefined,
	sat?: string | undefined,
	sun?: string | undefined
};
	["WeekHoursMeta"]: {
	__typename: "WeekHoursMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	mon?: GraphQLTypes["FieldMeta"] | undefined,
	tue?: GraphQLTypes["FieldMeta"] | undefined,
	wed?: GraphQLTypes["FieldMeta"] | undefined,
	thu?: GraphQLTypes["FieldMeta"] | undefined,
	fri?: GraphQLTypes["FieldMeta"] | undefined,
	sat?: GraphQLTypes["FieldMeta"] | undefined,
	sun?: GraphQLTypes["FieldMeta"] | undefined
};
	["VirtualProduct"]: {
	__typename: "VirtualProduct",
	_meta?: GraphQLTypes["VirtualProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["VirtualProductType"],
	product?: GraphQLTypes["Product"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation: GraphQLTypes["VirtualProductPhysicalRepresentationType"],
	recipientEmail?: boolean | undefined
};
	["VirtualProductMeta"]: {
	__typename: "VirtualProductMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	voucherCreditCents?: GraphQLTypes["FieldMeta"] | undefined,
	physicalRepresentation?: GraphQLTypes["FieldMeta"] | undefined,
	recipientEmail?: GraphQLTypes["FieldMeta"] | undefined
};
	["VatRate"]: {
	__typename: "VatRate",
	_meta?: GraphQLTypes["VatRateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	ratePermille: number,
	description?: string | undefined,
	internalNote?: string | undefined,
	channelDeliveries: Array<GraphQLTypes["ChannelDelivery"]>,
	channelPayments: Array<GraphQLTypes["ChannelPayment"]>,
	preset?: GraphQLTypes["VatRatePreset"] | undefined,
	paginateChannelDeliveries: GraphQLTypes["ChannelDeliveryConnection"],
	paginateChannelPayments: GraphQLTypes["ChannelPaymentConnection"]
};
	["VatRateMeta"]: {
	__typename: "VatRateMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	ratePermille?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	channelDeliveries?: GraphQLTypes["FieldMeta"] | undefined,
	channelPayments?: GraphQLTypes["FieldMeta"] | undefined,
	preset?: GraphQLTypes["FieldMeta"] | undefined
};
	["ChannelDelivery"]: {
	__typename: "ChannelDelivery",
	_meta?: GraphQLTypes["ChannelDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	priceCents: number,
	channel?: GraphQLTypes["Channel"] | undefined,
	method?: GraphQLTypes["DeliveryMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	defaultVisible: boolean
};
	["ChannelDeliveryMeta"]: {
	__typename: "ChannelDeliveryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	defaultVisible?: GraphQLTypes["FieldMeta"] | undefined
};
	["Channel"]: {
	__typename: "Channel",
	_meta?: GraphQLTypes["ChannelMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	disabled: boolean,
	payments: Array<GraphQLTypes["ChannelPayment"]>,
	deliveries: Array<GraphQLTypes["ChannelDelivery"]>,
	carts: Array<GraphQLTypes["Cart"]>,
	customers: Array<GraphQLTypes["Customer"]>,
	code?: GraphQLTypes["ChannelCode"] | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Tag"]>,
	publicTags: Array<GraphQLTypes["Tag"]>,
	cartsByItems?: GraphQLTypes["Cart"] | undefined,
	cartsByOrder?: GraphQLTypes["Cart"] | undefined,
	cartsByPriceLines?: GraphQLTypes["Cart"] | undefined,
	cartsBySummary?: GraphQLTypes["Cart"] | undefined,
	paginatePayments: GraphQLTypes["ChannelPaymentConnection"],
	paginateDeliveries: GraphQLTypes["ChannelDeliveryConnection"],
	paginateCarts: GraphQLTypes["CartConnection"],
	paginateCustomers: GraphQLTypes["CustomerConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["TagConnection"],
	paginatePublicTags: GraphQLTypes["TagConnection"]
};
	["ChannelMeta"]: {
	__typename: "ChannelMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	disabled?: GraphQLTypes["FieldMeta"] | undefined,
	payments?: GraphQLTypes["FieldMeta"] | undefined,
	deliveries?: GraphQLTypes["FieldMeta"] | undefined,
	carts?: GraphQLTypes["FieldMeta"] | undefined,
	customers?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["FieldMeta"] | undefined,
	publicTags?: GraphQLTypes["FieldMeta"] | undefined
};
	["ChannelPayment"]: {
	__typename: "ChannelPayment",
	_meta?: GraphQLTypes["ChannelPaymentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	channel?: GraphQLTypes["Channel"] | undefined,
	method?: GraphQLTypes["PaymentMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags: Array<GraphQLTypes["Tag"]>,
	paginateAllowOnlyForTags: GraphQLTypes["TagConnection"]
};
	["ChannelPaymentMeta"]: {
	__typename: "ChannelPaymentMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	requireBillingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	provideReceipt?: GraphQLTypes["FieldMeta"] | undefined,
	requireBillingSubject?: GraphQLTypes["FieldMeta"] | undefined,
	allowOnlyForTags?: GraphQLTypes["FieldMeta"] | undefined
};
	["PaymentMethod"]: {
	__typename: "PaymentMethod",
	_meta?: GraphQLTypes["PaymentMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch: boolean,
	type?: GraphQLTypes["PaymentMethodType"] | undefined,
	channels: Array<GraphQLTypes["ChannelPayment"]>,
	reduceGratuityByPercentage: number,
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateChannels: GraphQLTypes["ChannelPaymentConnection"]
};
	["PaymentMethodMeta"]: {
	__typename: "PaymentMethodMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	blockingDispatch?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethod"]: {
	__typename: "DeliveryMethod",
	_meta?: GraphQLTypes["DeliveryMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress: boolean,
	suitableForDeliveryZones: Array<GraphQLTypes["DeliveryZone"]>,
	channels: Array<GraphQLTypes["ChannelDelivery"]>,
	enableDeliveryTracking: boolean,
	locales: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	localesByLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"],
	paginateSuitableForDeliveryZones: GraphQLTypes["DeliveryZoneConnection"],
	paginateChannels: GraphQLTypes["ChannelDeliveryConnection"],
	paginateLocales: GraphQLTypes["DeliveryMethodLocaleConnection"]
};
	["DeliveryMethodMeta"]: {
	__typename: "DeliveryMethodMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	timelinePreset?: GraphQLTypes["FieldMeta"] | undefined
};
	["PaymentMethodOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	enabled?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	blockingDispatch?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryZone"]: {
	__typename: "DeliveryZone",
	_meta?: GraphQLTypes["DeliveryZoneMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type: GraphQLTypes["DeliveryZoneType"],
	addresses: Array<GraphQLTypes["Address"]>,
	suitableDeliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	deliveryZonesOfTags: Array<GraphQLTypes["Tag"]>,
	deliveryZonesOfProducts: Array<GraphQLTypes["Product"]>,
	addressesMetadata: Array<GraphQLTypes["AddressMetadata"]>,
	addressesByMeta?: GraphQLTypes["Address"] | undefined,
	addressesByGps?: GraphQLTypes["Address"] | undefined,
	addressesByInvoices?: GraphQLTypes["Address"] | undefined,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	addressesMetadataByAddress?: GraphQLTypes["AddressMetadata"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateSuitableDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateDeliveryZonesOfTags: GraphQLTypes["TagConnection"],
	paginateDeliveryZonesOfProducts: GraphQLTypes["ProductConnection"],
	paginateAddressesMetadata: GraphQLTypes["AddressMetadataConnection"]
};
	["DeliveryZoneMeta"]: {
	__typename: "DeliveryZoneMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	addresses?: GraphQLTypes["FieldMeta"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["FieldMeta"] | undefined,
	addressesMetadata?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	enabled?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["OrderDirection"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["OrderDirection"] | undefined,
	timelinePreset?: GraphQLTypes["DeliveryTimelinePresetOrderBy"] | undefined
};
	["DeliveryTimelinePresetOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	window?: GraphQLTypes["OrderDirection"] | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethodOrderBy"] | undefined
};
	["AddressMetadata"]: {
	__typename: "AddressMetadata",
	_meta?: GraphQLTypes["AddressMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	deliveryZoneType?: GraphQLTypes["DeliveryZoneType"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	deliveryZone?: GraphQLTypes["DeliveryZone"] | undefined
};
	["AddressMetadataMeta"]: {
	__typename: "AddressMetadataMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZoneType?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryZone?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryZoneAddressesByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["AddressMetadataUniqueWhere"] | undefined
};
	["DeliveryZoneAddressesByGpsUniqueWhere"]: {
		gps?: GraphQLTypes["GpsUniqueWhere"] | undefined
};
	["DeliveryZoneAddressesByInvoicesUniqueWhere"]: {
		invoices?: GraphQLTypes["InvoiceUniqueWhere"] | undefined
};
	["DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"]: {
		billingAddressOfOrders?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
		deliveryAddressOfOrderDeliveries?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
		defaultDeliveryAddressOfCustomer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["DeliveryZoneAddressesMetadataByAddressUniqueWhere"]: {
		address?: GraphQLTypes["AddressUniqueWhere"] | undefined
};
	["AddressConnection"]: {
	__typename: "AddressConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AddressEdge"]>
};
	["AddressEdge"]: {
	__typename: "AddressEdge",
	node: GraphQLTypes["Address"]
};
	["DeliveryMethodConnection"]: {
	__typename: "DeliveryMethodConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodEdge"]>
};
	["DeliveryMethodEdge"]: {
	__typename: "DeliveryMethodEdge",
	node: GraphQLTypes["DeliveryMethod"]
};
	["AddressMetadataConnection"]: {
	__typename: "AddressMetadataConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AddressMetadataEdge"]>
};
	["AddressMetadataEdge"]: {
	__typename: "AddressMetadataEdge",
	node: GraphQLTypes["AddressMetadata"]
};
	["ChannelDeliveryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	channel?: GraphQLTypes["ChannelOrderBy"] | undefined,
	method?: GraphQLTypes["DeliveryMethodOrderBy"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	defaultVisible?: GraphQLTypes["OrderDirection"] | undefined
};
	["ChannelOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	disabled?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryMethodLocale"]: {
	__typename: "DeliveryMethodLocale",
	_meta?: GraphQLTypes["DeliveryMethodLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	root?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryMethodLocaleMeta"]: {
	__typename: "DeliveryMethodLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["FieldMeta"] | undefined,
	processingTitle?: GraphQLTypes["FieldMeta"] | undefined,
	processingText?: GraphQLTypes["FieldMeta"] | undefined,
	processingIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedTitle?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedText?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledTitle?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledText?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["OrderDirection"] | undefined,
	processingTitle?: GraphQLTypes["OrderDirection"] | undefined,
	processingText?: GraphQLTypes["OrderDirection"] | undefined,
	processingIconUrl?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchedTitle?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchedText?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledTitle?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledText?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["OrderDirection"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	root?: GraphQLTypes["DeliveryMethodOrderBy"] | undefined
};
	["DeliveryTimelinePreset"]: {
	__typename: "DeliveryTimelinePreset",
	_meta?: GraphQLTypes["DeliveryTimelinePresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["DeliveryTimelineType"],
	duration?: string | undefined,
	window?: string | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["DeliveryTimelinePresetMeta"]: {
	__typename: "DeliveryTimelinePresetMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	window?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryMethod?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryMethodLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["PaymentMethodConnection"]: {
	__typename: "PaymentMethodConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PaymentMethodEdge"]>
};
	["PaymentMethodEdge"]: {
	__typename: "PaymentMethodEdge",
	node: GraphQLTypes["PaymentMethod"]
};
	["DeliveryZoneConnection"]: {
	__typename: "DeliveryZoneConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryZoneEdge"]>
};
	["DeliveryZoneEdge"]: {
	__typename: "DeliveryZoneEdge",
	node: GraphQLTypes["DeliveryZone"]
};
	["ChannelDeliveryConnection"]: {
	__typename: "ChannelDeliveryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ChannelDeliveryEdge"]>
};
	["ChannelDeliveryEdge"]: {
	__typename: "ChannelDeliveryEdge",
	node: GraphQLTypes["ChannelDelivery"]
};
	["DeliveryMethodLocaleConnection"]: {
	__typename: "DeliveryMethodLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodLocaleEdge"]>
};
	["DeliveryMethodLocaleEdge"]: {
	__typename: "DeliveryMethodLocaleEdge",
	node: GraphQLTypes["DeliveryMethodLocale"]
};
	["ChannelPaymentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	channel?: GraphQLTypes["ChannelOrderBy"] | undefined,
	method?: GraphQLTypes["PaymentMethodOrderBy"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	requireBillingAddress?: GraphQLTypes["OrderDirection"] | undefined,
	provideReceipt?: GraphQLTypes["OrderDirection"] | undefined,
	requireBillingSubject?: GraphQLTypes["OrderDirection"] | undefined
};
	["ChannelPaymentConnection"]: {
	__typename: "ChannelPaymentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ChannelPaymentEdge"]>
};
	["ChannelPaymentEdge"]: {
	__typename: "ChannelPaymentEdge",
	node: GraphQLTypes["ChannelPayment"]
};
	["Cart"]: {
	__typename: "Cart",
	_meta?: GraphQLTypes["CartMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items: Array<GraphQLTypes["CartItem"]>,
	handle?: string | undefined,
	state: GraphQLTypes["CartState"],
	createdAt: GraphQLTypes["DateTime"],
	channel?: GraphQLTypes["Channel"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	deliveryAddress?: GraphQLTypes["Address"] | undefined,
	billingAddress?: GraphQLTypes["Address"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["ChannelDelivery"] | undefined,
	payment?: GraphQLTypes["ChannelPayment"] | undefined,
	billingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	priceLines: Array<GraphQLTypes["CartPriceLine"]>,
	summary?: GraphQLTypes["CartSummary"] | undefined,
	itemsByProduct?: GraphQLTypes["CartItem"] | undefined,
	paginateItems: GraphQLTypes["CartItemConnection"],
	paginatePriceLines: GraphQLTypes["CartPriceLineConnection"]
};
	["CartMeta"]: {
	__typename: "CartMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	confirmedAt?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	handle?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	billingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryOption?: GraphQLTypes["FieldMeta"] | undefined,
	paymentOption?: GraphQLTypes["FieldMeta"] | undefined,
	delivery?: GraphQLTypes["FieldMeta"] | undefined,
	payment?: GraphQLTypes["FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["FieldMeta"] | undefined,
	priceLines?: GraphQLTypes["FieldMeta"] | undefined,
	summary?: GraphQLTypes["FieldMeta"] | undefined
};
	["CartItem"]: {
	__typename: "CartItem",
	_meta?: GraphQLTypes["CartItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	cart?: GraphQLTypes["Cart"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	fulfillmentNote?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["CartItemMeta"]: {
	__typename: "CartItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	cart?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	updatedAt?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNote?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined
};
	["FulfillmentNote"]: {
	__typename: "FulfillmentNote",
	_meta?: GraphQLTypes["FulfillmentNoteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	note: string,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Tag"]>,
	cartItems: Array<GraphQLTypes["CartItem"]>,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	orders: Array<GraphQLTypes["Order"]>,
	highlight: boolean,
	createdAt: GraphQLTypes["DateTime"],
	emoji?: string | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["TagConnection"],
	paginateCartItems: GraphQLTypes["CartItemConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"]
};
	["FulfillmentNoteMeta"]: {
	__typename: "FulfillmentNoteMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	tags?: GraphQLTypes["FieldMeta"] | undefined,
	cartItems?: GraphQLTypes["FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined,
	highlight?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	emoji?: GraphQLTypes["FieldMeta"] | undefined
};
	["CartItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	cart?: GraphQLTypes["CartOrderBy"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	updatedAt?: GraphQLTypes["OrderDirection"] | undefined,
	fulfillmentNote?: GraphQLTypes["OrderDirection"] | undefined
};
	["CartOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	confirmedAt?: GraphQLTypes["OrderDirection"] | undefined,
	deletedAt?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	handle?: GraphQLTypes["OrderDirection"] | undefined,
	state?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	channel?: GraphQLTypes["ChannelOrderBy"] | undefined,
	order?: GraphQLTypes["OrderOrderBy"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	deliveryAddress?: GraphQLTypes["AddressOrderBy"] | undefined,
	billingAddress?: GraphQLTypes["AddressOrderBy"] | undefined,
	deliveryOption?: GraphQLTypes["OrderDirection"] | undefined,
	paymentOption?: GraphQLTypes["OrderDirection"] | undefined,
	delivery?: GraphQLTypes["ChannelDeliveryOrderBy"] | undefined,
	payment?: GraphQLTypes["ChannelPaymentOrderBy"] | undefined,
	billingSubject?: GraphQLTypes["BillingSubjectOrderBy"] | undefined,
	summary?: GraphQLTypes["CartSummaryOrderBy"] | undefined
};
	["OrderOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	state?: GraphQLTypes["OrderDirection"] | undefined,
	fulfilledAt?: GraphQLTypes["OrderDirection"] | undefined,
	canceledAt?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	channel?: GraphQLTypes["ChannelOrderBy"] | undefined,
	delivery?: GraphQLTypes["OrderDeliveryOrderBy"] | undefined,
	cart?: GraphQLTypes["CartOrderBy"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	billingAddress?: GraphQLTypes["AddressOrderBy"] | undefined,
	seq?: GraphQLTypes["OrderDirection"] | undefined,
	billingSubject?: GraphQLTypes["BillingSubjectOrderBy"] | undefined,
	summary?: GraphQLTypes["OrderSummaryOrderBy"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["OrderDirection"] | undefined,
	fulfillmentNote?: GraphQLTypes["OrderDirection"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["OrderDirection"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["OrderDirection"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["OrderDirection"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderDeliveryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	deliveredAt?: GraphQLTypes["OrderDirection"] | undefined,
	method?: GraphQLTypes["DeliveryMethodOrderBy"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	order?: GraphQLTypes["OrderOrderBy"] | undefined,
	expectedAt?: GraphQLTypes["OrderDirection"] | undefined,
	state?: GraphQLTypes["OrderDirection"] | undefined,
	loadingDurationHours?: GraphQLTypes["OrderDirection"] | undefined,
	dispatchAt?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	window?: GraphQLTypes["OrderDirection"] | undefined,
	expectedEndAt?: GraphQLTypes["OrderDirection"] | undefined,
	processingAt?: GraphQLTypes["OrderDirection"] | undefined,
	deliveringAt?: GraphQLTypes["OrderDirection"] | undefined,
	failedAt?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined,
	prepareAt?: GraphQLTypes["OrderDirection"] | undefined,
	pickedAt?: GraphQLTypes["OrderDirection"] | undefined,
	address?: GraphQLTypes["AddressOrderBy"] | undefined,
	trackingCode?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderSummaryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	totalPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	discountPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	undiscountedPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	itemsQuantity?: GraphQLTypes["OrderDirection"] | undefined,
	orderUrl?: GraphQLTypes["OrderDirection"] | undefined,
	orderNumber?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderOrderBy"] | undefined
};
	["CartSummaryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	itemsPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	deliveryPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	totalPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	itemsCount?: GraphQLTypes["OrderDirection"] | undefined,
	itemsQuantity?: GraphQLTypes["OrderDirection"] | undefined,
	checkoutUrl?: GraphQLTypes["OrderDirection"] | undefined,
	cart?: GraphQLTypes["CartOrderBy"] | undefined
};
	["OrderItem"]: {
	__typename: "OrderItem",
	_meta?: GraphQLTypes["OrderItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unitPriceCents: number,
	quantity: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	virtualProductEffects: Array<GraphQLTypes["VirtualProductEffect"]>,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	product?: GraphQLTypes["Product"] | undefined,
	virtualProductEffectsByResultVouchers?: GraphQLTypes["VirtualProductEffect"] | undefined,
	paginateVirtualProductEffects: GraphQLTypes["VirtualProductEffectConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["OrderItemMeta"]: {
	__typename: "OrderItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	virtualProductEffects?: GraphQLTypes["FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["Order"]: {
	__typename: "Order",
	_meta?: GraphQLTypes["OrderMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	state: GraphQLTypes["OrderState"],
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	channel?: GraphQLTypes["Channel"] | undefined,
	discounts: Array<GraphQLTypes["OrderDiscount"]>,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined,
	items: Array<GraphQLTypes["OrderItem"]>,
	cart?: GraphQLTypes["Cart"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	billingAddress?: GraphQLTypes["Address"] | undefined,
	priceLines: Array<GraphQLTypes["OrderPriceLine"]>,
	payments: Array<GraphQLTypes["OrderPayment"]>,
	seq: number,
	billingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	summary?: GraphQLTypes["OrderSummary"] | undefined,
	doubleshotLegacyId?: string | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["FulfillmentNote"]>,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	discountsByVoucherRedemption?: GraphQLTypes["OrderDiscount"] | undefined,
	itemsByVirtualProductEffects?: GraphQLTypes["OrderItem"] | undefined,
	priceLinesByOrderItem?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderDiscount?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderPayment?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderDelivery?: GraphQLTypes["OrderPriceLine"] | undefined,
	paymentsByMeta?: GraphQLTypes["OrderPayment"] | undefined,
	paginateDiscounts: GraphQLTypes["OrderDiscountConnection"],
	paginateItems: GraphQLTypes["OrderItemConnection"],
	paginatePriceLines: GraphQLTypes["OrderPriceLineConnection"],
	paginatePayments: GraphQLTypes["OrderPaymentConnection"],
	paginateFulfillmentNotes: GraphQLTypes["FulfillmentNoteConnection"]
};
	["OrderMeta"]: {
	__typename: "OrderMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	fulfilledAt?: GraphQLTypes["FieldMeta"] | undefined,
	canceledAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	channel?: GraphQLTypes["FieldMeta"] | undefined,
	discounts?: GraphQLTypes["FieldMeta"] | undefined,
	delivery?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	cart?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	billingAddress?: GraphQLTypes["FieldMeta"] | undefined,
	priceLines?: GraphQLTypes["FieldMeta"] | undefined,
	payments?: GraphQLTypes["FieldMeta"] | undefined,
	seq?: GraphQLTypes["FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["FieldMeta"] | undefined,
	summary?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNote?: GraphQLTypes["FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["FieldMeta"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["FieldMeta"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderDiscount"]: {
	__typename: "OrderDiscount",
	_meta?: GraphQLTypes["OrderDiscountMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	discountCents: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	description?: string | undefined,
	type: GraphQLTypes["OrderDiscountType"],
	voucherRedemption?: GraphQLTypes["VoucherRedemption"] | undefined
};
	["OrderDiscountMeta"]: {
	__typename: "OrderDiscountMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	discountCents?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	voucherRedemption?: GraphQLTypes["FieldMeta"] | undefined
};
	["VoucherRedemption"]: {
	__typename: "VoucherRedemption",
	_meta?: GraphQLTypes["VoucherRedemptionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	customerCredit?: GraphQLTypes["CustomerCredit"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	voucher?: GraphQLTypes["Voucher"] | undefined,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["VoucherRedemptionMeta"]: {
	__typename: "VoucherRedemptionMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	manualRedemptionNote?: GraphQLTypes["FieldMeta"] | undefined,
	customerCredit?: GraphQLTypes["FieldMeta"] | undefined,
	orderDiscount?: GraphQLTypes["FieldMeta"] | undefined,
	voucher?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerCredit"]: {
	__typename: "CustomerCredit",
	_meta?: GraphQLTypes["CustomerCreditMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	initialCreditCents: number,
	remainingCreditsCents: number,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	reason?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	voucherRedemption?: GraphQLTypes["VoucherRedemption"] | undefined,
	invoiceUrl?: string | undefined
};
	["CustomerCreditMeta"]: {
	__typename: "CustomerCreditMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	initialCreditCents?: GraphQLTypes["FieldMeta"] | undefined,
	remainingCreditsCents?: GraphQLTypes["FieldMeta"] | undefined,
	expiresAt?: GraphQLTypes["FieldMeta"] | undefined,
	reason?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	voucherRedemption?: GraphQLTypes["FieldMeta"] | undefined,
	invoiceUrl?: GraphQLTypes["FieldMeta"] | undefined
};
	["Voucher"]: {
	__typename: "Voucher",
	_meta?: GraphQLTypes["VoucherMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	code?: string | undefined,
	type?: GraphQLTypes["VoucherType"] | undefined,
	creditCents?: number | undefined,
	buyer?: GraphQLTypes["Customer"] | undefined,
	owner?: GraphQLTypes["Customer"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	virtualProductEffect?: GraphQLTypes["VirtualProductEffect"] | undefined,
	redemption?: GraphQLTypes["VoucherRedemption"] | undefined
};
	["VoucherMeta"]: {
	__typename: "VoucherMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	activatedAt?: GraphQLTypes["FieldMeta"] | undefined,
	expiresAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	creditCents?: GraphQLTypes["FieldMeta"] | undefined,
	buyer?: GraphQLTypes["FieldMeta"] | undefined,
	owner?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	virtualProductEffect?: GraphQLTypes["FieldMeta"] | undefined,
	redemption?: GraphQLTypes["FieldMeta"] | undefined
};
	["VirtualProductEffect"]: {
	__typename: "VirtualProductEffect",
	_meta?: GraphQLTypes["VirtualProductEffectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	succeededAt?: GraphQLTypes["DateTime"] | undefined,
	orderItem?: GraphQLTypes["OrderItem"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	revertedAt?: GraphQLTypes["DateTime"] | undefined,
	resultVouchers: Array<GraphQLTypes["Voucher"]>,
	resultVouchersByCode?: GraphQLTypes["Voucher"] | undefined,
	resultVouchersByRedemption?: GraphQLTypes["Voucher"] | undefined,
	paginateResultVouchers: GraphQLTypes["VoucherConnection"]
};
	["VirtualProductEffectMeta"]: {
	__typename: "VirtualProductEffectMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	succeededAt?: GraphQLTypes["FieldMeta"] | undefined,
	orderItem?: GraphQLTypes["FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["FieldMeta"] | undefined,
	revertedAt?: GraphQLTypes["FieldMeta"] | undefined,
	resultVouchers?: GraphQLTypes["FieldMeta"] | undefined
};
	["VoucherOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	activatedAt?: GraphQLTypes["OrderDirection"] | undefined,
	expiresAt?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	creditCents?: GraphQLTypes["OrderDirection"] | undefined,
	buyer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	owner?: GraphQLTypes["CustomerOrderBy"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	virtualProductEffect?: GraphQLTypes["VirtualProductEffectOrderBy"] | undefined,
	redemption?: GraphQLTypes["VoucherRedemptionOrderBy"] | undefined
};
	["VirtualProductEffectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	succeededAt?: GraphQLTypes["OrderDirection"] | undefined,
	orderItem?: GraphQLTypes["OrderItemOrderBy"] | undefined,
	failedAt?: GraphQLTypes["OrderDirection"] | undefined,
	revertedAt?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unitPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	order?: GraphQLTypes["OrderOrderBy"] | undefined,
	pickedAt?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["VoucherRedemptionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	manualRedemptionNote?: GraphQLTypes["OrderDirection"] | undefined,
	customerCredit?: GraphQLTypes["CustomerCreditOrderBy"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscountOrderBy"] | undefined,
	voucher?: GraphQLTypes["VoucherOrderBy"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined
};
	["CustomerCreditOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	initialCreditCents?: GraphQLTypes["OrderDirection"] | undefined,
	remainingCreditsCents?: GraphQLTypes["OrderDirection"] | undefined,
	expiresAt?: GraphQLTypes["OrderDirection"] | undefined,
	reason?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	voucherRedemption?: GraphQLTypes["VoucherRedemptionOrderBy"] | undefined,
	invoiceUrl?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderDiscountOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	discountCents?: GraphQLTypes["OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	order?: GraphQLTypes["OrderOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	voucherRedemption?: GraphQLTypes["VoucherRedemptionOrderBy"] | undefined
};
	["VirtualProductEffectResultVouchersByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["VirtualProductEffectResultVouchersByRedemptionUniqueWhere"]: {
		redemption?: GraphQLTypes["VoucherRedemptionUniqueWhere"] | undefined
};
	["VoucherConnection"]: {
	__typename: "VoucherConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VoucherEdge"]>
};
	["VoucherEdge"]: {
	__typename: "VoucherEdge",
	node: GraphQLTypes["Voucher"]
};
	["OrderDelivery"]: {
	__typename: "OrderDelivery",
	_meta?: GraphQLTypes["OrderDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	priceCents: number,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["DeliveryMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["OrderDeliveryState"],
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	trackingCode?: string | undefined
};
	["OrderDeliveryMeta"]: {
	__typename: "OrderDeliveryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	deliveredAt?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	expectedAt?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	loadingDurationHours?: GraphQLTypes["FieldMeta"] | undefined,
	dispatchAt?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	window?: GraphQLTypes["FieldMeta"] | undefined,
	expectedEndAt?: GraphQLTypes["FieldMeta"] | undefined,
	processingAt?: GraphQLTypes["FieldMeta"] | undefined,
	deliveringAt?: GraphQLTypes["FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	prepareAt?: GraphQLTypes["FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	trackingCode?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPriceLine"]: {
	__typename: "OrderPriceLine",
	_meta?: GraphQLTypes["OrderPriceLineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unitPriceCents: number,
	quantity: number,
	type?: GraphQLTypes["OrderPriceLineType"] | undefined,
	orderItem?: GraphQLTypes["OrderItem"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	orderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	orderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	vatRatePermille: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined
};
	["OrderPriceLineMeta"]: {
	__typename: "OrderPriceLineMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	orderItem?: GraphQLTypes["FieldMeta"] | undefined,
	orderDiscount?: GraphQLTypes["FieldMeta"] | undefined,
	orderPayment?: GraphQLTypes["FieldMeta"] | undefined,
	orderDelivery?: GraphQLTypes["FieldMeta"] | undefined,
	vatRatePermille?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPayment"]: {
	__typename: "OrderPayment",
	_meta?: GraphQLTypes["OrderPaymentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	data?: GraphQLTypes["Json"] | undefined,
	paymentCents: number,
	method?: GraphQLTypes["PaymentMethod"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["OrderPaymentState"],
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	invoice?: GraphQLTypes["Invoice"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	meta?: GraphQLTypes["OrderPaymentMetadata"] | undefined,
	otherCurrencyPriceCents?: string | undefined
};
	["OrderPaymentMeta"]: {
	__typename: "OrderPaymentMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	data?: GraphQLTypes["FieldMeta"] | undefined,
	paymentCents?: GraphQLTypes["FieldMeta"] | undefined,
	method?: GraphQLTypes["FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["FieldMeta"] | undefined,
	state?: GraphQLTypes["FieldMeta"] | undefined,
	approvedAt?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	invoice?: GraphQLTypes["FieldMeta"] | undefined,
	walletType?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	publicReceiptUrl?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["FieldMeta"] | undefined
};
	["Json"]: any;
	["Invoice"]: {
	__typename: "Invoice",
	_meta?: GraphQLTypes["InvoiceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	publicKey: string,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	orderPayments: Array<GraphQLTypes["OrderPayment"]>,
	address?: GraphQLTypes["Address"] | undefined,
	publicUrl?: string | undefined,
	billingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	orderPaymentsByMeta?: GraphQLTypes["OrderPayment"] | undefined,
	paginateOrderPayments: GraphQLTypes["OrderPaymentConnection"]
};
	["InvoiceMeta"]: {
	__typename: "InvoiceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	publicKey?: GraphQLTypes["FieldMeta"] | undefined,
	fakturoidId?: GraphQLTypes["FieldMeta"] | undefined,
	fakturoidData?: GraphQLTypes["FieldMeta"] | undefined,
	legacyUrl?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	orderPayments?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined,
	publicUrl?: GraphQLTypes["FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPaymentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	data?: GraphQLTypes["OrderDirection"] | undefined,
	paymentCents?: GraphQLTypes["OrderDirection"] | undefined,
	method?: GraphQLTypes["PaymentMethodOrderBy"] | undefined,
	failedAt?: GraphQLTypes["OrderDirection"] | undefined,
	state?: GraphQLTypes["OrderDirection"] | undefined,
	approvedAt?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderOrderBy"] | undefined,
	invoice?: GraphQLTypes["InvoiceOrderBy"] | undefined,
	walletType?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	publicReceiptUrl?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	meta?: GraphQLTypes["OrderPaymentMetadataOrderBy"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["OrderDirection"] | undefined
};
	["InvoiceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	publicKey?: GraphQLTypes["OrderDirection"] | undefined,
	fakturoidId?: GraphQLTypes["OrderDirection"] | undefined,
	fakturoidData?: GraphQLTypes["OrderDirection"] | undefined,
	legacyUrl?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	address?: GraphQLTypes["AddressOrderBy"] | undefined,
	publicUrl?: GraphQLTypes["OrderDirection"] | undefined,
	billingSubject?: GraphQLTypes["BillingSubjectOrderBy"] | undefined
};
	["OrderPaymentMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	paymentUrl?: GraphQLTypes["OrderDirection"] | undefined,
	orderPayment?: GraphQLTypes["OrderPaymentOrderBy"] | undefined
};
	["BillingSubject"]: {
	__typename: "BillingSubject",
	_meta?: GraphQLTypes["BillingSubjectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden: boolean,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Country"] | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	invoices: Array<GraphQLTypes["Invoice"]>,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Customer"] | undefined,
	orders: Array<GraphQLTypes["Order"]>,
	invoicesByPublicKey?: GraphQLTypes["Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Invoice"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDiscounts?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByCart?: GraphQLTypes["Order"] | undefined,
	ordersByPriceLines?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	ordersBySummary?: GraphQLTypes["Order"] | undefined,
	paginateInvoices: GraphQLTypes["InvoiceConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"]
};
	["BillingSubjectMeta"]: {
	__typename: "BillingSubjectMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	fullName?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine1?: GraphQLTypes["FieldMeta"] | undefined,
	addressLine2?: GraphQLTypes["FieldMeta"] | undefined,
	city?: GraphQLTypes["FieldMeta"] | undefined,
	postalCode?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	phone?: GraphQLTypes["FieldMeta"] | undefined,
	companyName?: GraphQLTypes["FieldMeta"] | undefined,
	companyIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	vatIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	externalIdentifier?: GraphQLTypes["FieldMeta"] | undefined,
	hidden?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["FieldMeta"] | undefined,
	country?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	invoices?: GraphQLTypes["FieldMeta"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["FieldMeta"] | undefined,
	orders?: GraphQLTypes["FieldMeta"] | undefined
};
	["Country"]: {
	__typename: "Country",
	_meta?: GraphQLTypes["CountryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	order: number
};
	["CountryMeta"]: {
	__typename: "CountryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["BillingSubjectInvoicesByPublicKeyUniqueWhere"]: {
		publicKey?: string | undefined
};
	["BillingSubjectInvoicesByFakturoidIdUniqueWhere"]: {
		fakturoidId?: string | undefined
};
	["BillingSubjectInvoicesByOrderPaymentsUniqueWhere"]: {
		orderPayments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["BillingSubjectOrdersBySeqUniqueWhere"]: {
		seq?: number | undefined
};
	["BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
		doubleShotLegacyNumber?: string | undefined
};
	["BillingSubjectOrdersByDiscountsUniqueWhere"]: {
		discounts?: GraphQLTypes["OrderDiscountUniqueWhere"] | undefined
};
	["BillingSubjectOrdersByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["BillingSubjectOrdersByItemsUniqueWhere"]: {
		items?: GraphQLTypes["OrderItemUniqueWhere"] | undefined
};
	["BillingSubjectOrdersByCartUniqueWhere"]: {
		cart?: GraphQLTypes["CartUniqueWhere"] | undefined
};
	["BillingSubjectOrdersByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["OrderPriceLineUniqueWhere"] | undefined
};
	["BillingSubjectOrdersByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["BillingSubjectOrdersBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["OrderSummaryUniqueWhere"] | undefined
};
	["InvoiceConnection"]: {
	__typename: "InvoiceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["InvoiceEdge"]>
};
	["InvoiceEdge"]: {
	__typename: "InvoiceEdge",
	node: GraphQLTypes["Invoice"]
};
	["OrderConnection"]: {
	__typename: "OrderConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderEdge"]>
};
	["OrderEdge"]: {
	__typename: "OrderEdge",
	node: GraphQLTypes["Order"]
};
	["InvoiceOrderPaymentsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["OrderPaymentMetadataUniqueWhere"] | undefined
};
	["OrderPaymentConnection"]: {
	__typename: "OrderPaymentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPaymentEdge"]>
};
	["OrderPaymentEdge"]: {
	__typename: "OrderPaymentEdge",
	node: GraphQLTypes["OrderPayment"]
};
	["OrderPaymentMetadata"]: {
	__typename: "OrderPaymentMetadata",
	_meta?: GraphQLTypes["OrderPaymentMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	paymentUrl: string,
	orderPayment?: GraphQLTypes["OrderPayment"] | undefined
};
	["OrderPaymentMetadataMeta"]: {
	__typename: "OrderPaymentMetadataMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	paymentUrl?: GraphQLTypes["FieldMeta"] | undefined,
	orderPayment?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPriceLineOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unitPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	orderItem?: GraphQLTypes["OrderItemOrderBy"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscountOrderBy"] | undefined,
	orderPayment?: GraphQLTypes["OrderPaymentOrderBy"] | undefined,
	orderDelivery?: GraphQLTypes["OrderDeliveryOrderBy"] | undefined,
	vatRatePermille?: GraphQLTypes["OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	order?: GraphQLTypes["OrderOrderBy"] | undefined
};
	["OrderSummary"]: {
	__typename: "OrderSummary",
	_meta?: GraphQLTypes["OrderSummaryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	totalPriceCents: number,
	discountPriceCents: number,
	undiscountedPriceCents: number,
	itemsQuantity: number,
	orderUrl: string,
	orderNumber: string,
	order?: GraphQLTypes["Order"] | undefined
};
	["OrderSummaryMeta"]: {
	__typename: "OrderSummaryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	totalPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	discountPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	undiscountedPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	itemsQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	orderUrl?: GraphQLTypes["FieldMeta"] | undefined,
	orderNumber?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined
};
	["FulfillmentNoteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	highlight?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	emoji?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderDiscountsByVoucherRedemptionUniqueWhere"]: {
		voucherRedemption?: GraphQLTypes["VoucherRedemptionUniqueWhere"] | undefined
};
	["OrderItemsByVirtualProductEffectsUniqueWhere"]: {
		virtualProductEffects?: GraphQLTypes["VirtualProductEffectUniqueWhere"] | undefined
};
	["OrderPriceLinesByOrderItemUniqueWhere"]: {
		orderItem?: GraphQLTypes["OrderItemUniqueWhere"] | undefined
};
	["OrderPriceLinesByOrderDiscountUniqueWhere"]: {
		orderDiscount?: GraphQLTypes["OrderDiscountUniqueWhere"] | undefined
};
	["OrderPriceLinesByOrderPaymentUniqueWhere"]: {
		orderPayment?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["OrderPriceLinesByOrderDeliveryUniqueWhere"]: {
		orderDelivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["OrderPaymentsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["OrderPaymentMetadataUniqueWhere"] | undefined
};
	["OrderDiscountConnection"]: {
	__typename: "OrderDiscountConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderDiscountEdge"]>
};
	["OrderDiscountEdge"]: {
	__typename: "OrderDiscountEdge",
	node: GraphQLTypes["OrderDiscount"]
};
	["OrderItemConnection"]: {
	__typename: "OrderItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderItemEdge"]>
};
	["OrderItemEdge"]: {
	__typename: "OrderItemEdge",
	node: GraphQLTypes["OrderItem"]
};
	["OrderPriceLineConnection"]: {
	__typename: "OrderPriceLineConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPriceLineEdge"]>
};
	["OrderPriceLineEdge"]: {
	__typename: "OrderPriceLineEdge",
	node: GraphQLTypes["OrderPriceLine"]
};
	["FulfillmentNoteConnection"]: {
	__typename: "FulfillmentNoteConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FulfillmentNoteEdge"]>
};
	["FulfillmentNoteEdge"]: {
	__typename: "FulfillmentNoteEdge",
	node: GraphQLTypes["FulfillmentNote"]
};
	["OrderItemVirtualProductEffectsByResultVouchersUniqueWhere"]: {
		resultVouchers?: GraphQLTypes["VoucherUniqueWhere"] | undefined
};
	["VirtualProductEffectConnection"]: {
	__typename: "VirtualProductEffectConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VirtualProductEffectEdge"]>
};
	["VirtualProductEffectEdge"]: {
	__typename: "VirtualProductEffectEdge",
	node: GraphQLTypes["VirtualProductEffect"]
};
	["CartItemConnection"]: {
	__typename: "CartItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CartItemEdge"]>
};
	["CartItemEdge"]: {
	__typename: "CartItemEdge",
	node: GraphQLTypes["CartItem"]
};
	["CartPriceLine"]: {
	__typename: "CartPriceLine",
	_meta?: GraphQLTypes["CartPriceLineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["CartPriceLineType"] | undefined,
	item?: GraphQLTypes["CartItem"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	unitPriceCents: number,
	priceCents: number,
	quantity: number,
	vatRatePermille: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	cart?: GraphQLTypes["Cart"] | undefined
};
	["CartPriceLineMeta"]: {
	__typename: "CartPriceLineMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	vatRatePermille?: GraphQLTypes["FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["FieldMeta"] | undefined,
	cart?: GraphQLTypes["FieldMeta"] | undefined
};
	["CartPriceLineOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	item?: GraphQLTypes["CartItemOrderBy"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	unitPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	vatRatePermille?: GraphQLTypes["OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["VatRateOrderBy"] | undefined,
	cart?: GraphQLTypes["CartOrderBy"] | undefined
};
	["CartSummary"]: {
	__typename: "CartSummary",
	_meta?: GraphQLTypes["CartSummaryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	itemsPriceCents: number,
	deliveryPriceCents: number,
	totalPriceCents: number,
	itemsCount: number,
	itemsQuantity: number,
	checkoutUrl: string,
	cart?: GraphQLTypes["Cart"] | undefined
};
	["CartSummaryMeta"]: {
	__typename: "CartSummaryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	itemsPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	totalPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	itemsCount?: GraphQLTypes["FieldMeta"] | undefined,
	itemsQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	checkoutUrl?: GraphQLTypes["FieldMeta"] | undefined,
	cart?: GraphQLTypes["FieldMeta"] | undefined
};
	["CartItemsByProductUniqueWhere"]: {
		product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["CartPriceLineConnection"]: {
	__typename: "CartPriceLineConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CartPriceLineEdge"]>
};
	["CartPriceLineEdge"]: {
	__typename: "CartPriceLineEdge",
	node: GraphQLTypes["CartPriceLine"]
};
	["ChannelCartsByItemsUniqueWhere"]: {
		items?: GraphQLTypes["CartItemUniqueWhere"] | undefined
};
	["ChannelCartsByOrderUniqueWhere"]: {
		order?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["ChannelCartsByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["CartPriceLineUniqueWhere"] | undefined
};
	["ChannelCartsBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["CartSummaryUniqueWhere"] | undefined
};
	["CartConnection"]: {
	__typename: "CartConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CartEdge"]>
};
	["CartEdge"]: {
	__typename: "CartEdge",
	node: GraphQLTypes["Cart"]
};
	["FreshingContainerConnection"]: {
	__typename: "FreshingContainerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FreshingContainerEdge"]>
};
	["FreshingContainerEdge"]: {
	__typename: "FreshingContainerEdge",
	node: GraphQLTypes["FreshingContainer"]
};
	["ProductIngredientLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientCustomerRatingsByCustomerUniqueWhere"]: {
		customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["ProductIngredientLocaleConnection"]: {
	__typename: "ProductIngredientLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientLocaleEdge"]>
};
	["ProductIngredientLocaleEdge"]: {
	__typename: "ProductIngredientLocaleEdge",
	node: GraphQLTypes["ProductIngredientLocale"]
};
	["AllergenConnection"]: {
	__typename: "AllergenConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AllergenEdge"]>
};
	["AllergenEdge"]: {
	__typename: "AllergenEdge",
	node: GraphQLTypes["Allergen"]
};
	["CustomerIngredientRatingConnection"]: {
	__typename: "CustomerIngredientRatingConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerIngredientRatingEdge"]>
};
	["CustomerIngredientRatingEdge"]: {
	__typename: "CustomerIngredientRatingEdge",
	node: GraphQLTypes["CustomerIngredientRating"]
};
	["BlogLocale"]: {
	__typename: "BlogLocale",
	_meta?: GraphQLTypes["BlogLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	pageName?: string | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	root?: GraphQLTypes["Blog"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["BlogLocaleMeta"]: {
	__typename: "BlogLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	pageName?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["Blog"]: {
	__typename: "Blog",
	_meta?: GraphQLTypes["BlogMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["BlogLocale"]>,
	localesByLocale?: GraphQLTypes["BlogLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogLocaleConnection"]
};
	["BlogMeta"]: {
	__typename: "BlogMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	pageName?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	lead?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BlogOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["BlogOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["BlogLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogLocaleConnection"]: {
	__typename: "BlogLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogLocaleEdge"]>
};
	["BlogLocaleEdge"]: {
	__typename: "BlogLocaleEdge",
	node: GraphQLTypes["BlogLocale"]
};
	["BlogPostLocale"]: {
	__typename: "BlogPostLocale",
	_meta?: GraphQLTypes["BlogPostLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["BlogPost"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	products: Array<GraphQLTypes["Product"]>,
	paginateProducts: GraphQLTypes["ProductConnection"]
};
	["BlogPostLocaleMeta"]: {
	__typename: "BlogPostLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	availableForWeb?: GraphQLTypes["FieldMeta"] | undefined,
	availableForMobile?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPost"]: {
	__typename: "BlogPost",
	_meta?: GraphQLTypes["BlogPostMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	locales: Array<GraphQLTypes["BlogPostLocale"]>,
	localesByLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByCover?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByContent?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesByLink?: GraphQLTypes["BlogPostLocale"] | undefined,
	localesBySeo?: GraphQLTypes["BlogPostLocale"] | undefined,
	paginateLocales: GraphQLTypes["BlogPostLocaleConnection"]
};
	["BlogPostMeta"]: {
	__typename: "BlogPostMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	availableForWeb?: GraphQLTypes["OrderDirection"] | undefined,
	availableForMobile?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["BlogPostOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	cover?: GraphQLTypes["CoverOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined
};
	["BlogPostOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["CoverOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	primaryTitle?: GraphQLTypes["OrderDirection"] | undefined,
	secondaryTitle?: GraphQLTypes["OrderDirection"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	buttonColors?: GraphQLTypes["ColorPalleteOrderBy"] | undefined
};
	["MediumOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	colorTheme?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	video?: GraphQLTypes["VideoOrderBy"] | undefined
};
	["VideoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	src?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	poster?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ColorPalleteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	background?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkableOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleOrderBy"] | undefined,
	page?: GraphQLTypes["PageLocaleOrderBy"] | undefined,
	redirect?: GraphQLTypes["RedirectOrderBy"] | undefined
};
	["PageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	root?: GraphQLTypes["PageOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined,
	cover?: GraphQLTypes["CoverOrderBy"] | undefined,
	theme?: GraphQLTypes["OrderDirection"] | undefined
};
	["SeoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	ogTitle?: GraphQLTypes["OrderDirection"] | undefined,
	ogDescription?: GraphQLTypes["OrderDirection"] | undefined,
	ogImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["RedirectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	target?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["LinkOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	externalLink?: GraphQLTypes["OrderDirection"] | undefined,
	internalLink?: GraphQLTypes["LinkableOrderBy"] | undefined
};
	["BlogPostLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["BlogPostLocalesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["BlogPostLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["BlogPostLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["BlogPostLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["BlogPostLocaleConnection"]: {
	__typename: "BlogPostLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostLocaleEdge"]>
};
	["BlogPostLocaleEdge"]: {
	__typename: "BlogPostLocaleEdge",
	node: GraphQLTypes["BlogPostLocale"]
};
	["Cover"]: {
	__typename: "Cover",
	_meta?: GraphQLTypes["CoverMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	primaryTitle?: string | undefined,
	secondaryTitle?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	buttonColors?: GraphQLTypes["ColorPallete"] | undefined
};
	["CoverMeta"]: {
	__typename: "CoverMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	primaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	buttonColors?: GraphQLTypes["FieldMeta"] | undefined
};
	["Medium"]: {
	__typename: "Medium",
	_meta?: GraphQLTypes["MediumMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["MediumType"] | undefined,
	colorTheme?: GraphQLTypes["ColorTheme"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	video?: GraphQLTypes["Video"] | undefined
};
	["MediumMeta"]: {
	__typename: "MediumMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	colorTheme?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	video?: GraphQLTypes["FieldMeta"] | undefined
};
	["Video"]: {
	__typename: "Video",
	_meta?: GraphQLTypes["VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Image"] | undefined
};
	["VideoMeta"]: {
	__typename: "VideoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	src?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	poster?: GraphQLTypes["FieldMeta"] | undefined
};
	["ColorPallete"]: {
	__typename: "ColorPallete",
	_meta?: GraphQLTypes["ColorPalleteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	background?: string | undefined,
	text?: string | undefined
};
	["ColorPalleteMeta"]: {
	__typename: "ColorPalleteMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	background?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined
};
	["Content"]: {
	__typename: "Content",
	_meta?: GraphQLTypes["ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
	__typename: "ContentMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	blocks?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
	__typename: "ContentBlock",
	_meta?: GraphQLTypes["ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Content"] | undefined,
	references: Array<GraphQLTypes["ContentReference"]>,
	referencesByProducts?: GraphQLTypes["ContentReference"] | undefined,
	referencesByProductBanners?: GraphQLTypes["ContentReference"] | undefined,
	referencesByDeliveryRegions?: GraphQLTypes["ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
	__typename: "ContentBlockMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	json?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	references?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
	__typename: "ContentReference",
	_meta?: GraphQLTypes["ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["ContentReferenceType"],
	primaryText?: string | undefined,
	secondaryText?: string | undefined,
	jsonContent?: string | undefined,
	block?: GraphQLTypes["ContentBlock"] | undefined,
	colorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostList"] | undefined,
	links?: GraphQLTypes["LinkList"] | undefined,
	products?: GraphQLTypes["ProductList"] | undefined,
	contentSize?: GraphQLTypes["ContentSize"] | undefined,
	misc?: string | undefined,
	hero?: GraphQLTypes["Hero"] | undefined,
	gallery?: GraphQLTypes["Gallery"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	productGrid?: GraphQLTypes["ProductGrid"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerList"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	gridTiles?: GraphQLTypes["GridTiles"] | undefined
};
	["ContentReferenceMeta"]: {
	__typename: "ContentReferenceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["FieldMeta"] | undefined,
	jsonContent?: GraphQLTypes["FieldMeta"] | undefined,
	block?: GraphQLTypes["FieldMeta"] | undefined,
	colorPallete?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["FieldMeta"] | undefined,
	links?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	contentSize?: GraphQLTypes["FieldMeta"] | undefined,
	misc?: GraphQLTypes["FieldMeta"] | undefined,
	hero?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["FieldMeta"] | undefined,
	productGrid?: GraphQLTypes["FieldMeta"] | undefined,
	productBanners?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	gridTiles?: GraphQLTypes["FieldMeta"] | undefined
};
	["Link"]: {
	__typename: "Link",
	_meta?: GraphQLTypes["LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Linkable"] | undefined
};
	["LinkMeta"]: {
	__typename: "LinkMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
	__typename: "Linkable",
	_meta?: GraphQLTypes["LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined,
	page?: GraphQLTypes["PageLocale"] | undefined,
	redirect?: GraphQLTypes["Redirect"] | undefined
};
	["LinkableMeta"]: {
	__typename: "LinkableMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined,
	page?: GraphQLTypes["FieldMeta"] | undefined,
	redirect?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocale"]: {
	__typename: "PageLocale",
	_meta?: GraphQLTypes["PageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	root?: GraphQLTypes["Page"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	cover?: GraphQLTypes["Cover"] | undefined,
	theme?: GraphQLTypes["PageTheme"] | undefined
};
	["PageLocaleMeta"]: {
	__typename: "PageLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	cover?: GraphQLTypes["FieldMeta"] | undefined,
	theme?: GraphQLTypes["FieldMeta"] | undefined
};
	["Page"]: {
	__typename: "Page",
	_meta?: GraphQLTypes["PageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["PageLocale"]>,
	localesByLocale?: GraphQLTypes["PageLocale"] | undefined,
	localesByLink?: GraphQLTypes["PageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["PageLocale"] | undefined,
	localesByContent?: GraphQLTypes["PageLocale"] | undefined,
	localesByCover?: GraphQLTypes["PageLocale"] | undefined,
	paginateLocales: GraphQLTypes["PageLocaleConnection"]
};
	["PageMeta"]: {
	__typename: "PageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["PageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["PageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["PageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["PageLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["PageLocalesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["PageLocaleConnection"]: {
	__typename: "PageLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageLocaleEdge"]>
};
	["PageLocaleEdge"]: {
	__typename: "PageLocaleEdge",
	node: GraphQLTypes["PageLocale"]
};
	["Seo"]: {
	__typename: "Seo",
	_meta?: GraphQLTypes["SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Image"] | undefined
};
	["SeoMeta"]: {
	__typename: "SeoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["Redirect"]: {
	__typename: "Redirect",
	_meta?: GraphQLTypes["RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"] | undefined,
	target?: GraphQLTypes["Link"] | undefined
};
	["RedirectMeta"]: {
	__typename: "RedirectMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	target?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostList"]: {
	__typename: "BlogPostList",
	_meta?: GraphQLTypes["BlogPostListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateItems: GraphQLTypes["BlogPostListItemConnection"]
};
	["BlogPostListMeta"]: {
	__typename: "BlogPostListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItem"]: {
	__typename: "BlogPostListItem",
	_meta?: GraphQLTypes["BlogPostListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["BlogPostList"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocale"] | undefined
};
	["BlogPostListItemMeta"]: {
	__typename: "BlogPostListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["FieldMeta"] | undefined
};
	["BlogPostListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	caption?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["BlogPostListOrderBy"] | undefined,
	blogPost?: GraphQLTypes["BlogPostLocaleOrderBy"] | undefined
};
	["BlogPostListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["BlogPostListItemConnection"]: {
	__typename: "BlogPostListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListItemEdge"]>
};
	["BlogPostListItemEdge"]: {
	__typename: "BlogPostListItemEdge",
	node: GraphQLTypes["BlogPostListItem"]
};
	["LinkList"]: {
	__typename: "LinkList",
	_meta?: GraphQLTypes["LinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["LinkListItem"]>,
	paginateItems: GraphQLTypes["LinkListItemConnection"]
};
	["LinkListMeta"]: {
	__typename: "LinkListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItem"]: {
	__typename: "LinkListItem",
	_meta?: GraphQLTypes["LinkListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	isPrimary?: boolean | undefined,
	list?: GraphQLTypes["LinkList"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["LinkListItemMeta"]: {
	__typename: "LinkListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	isPrimary?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	isPrimary?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["LinkListOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["LinkListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkListItemConnection"]: {
	__typename: "LinkListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListItemEdge"]>
};
	["LinkListItemEdge"]: {
	__typename: "LinkListItemEdge",
	node: GraphQLTypes["LinkListItem"]
};
	["Hero"]: {
	__typename: "Hero",
	_meta?: GraphQLTypes["HeroMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	showLogo?: boolean | undefined,
	middleContent?: string | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["HeroMeta"]: {
	__typename: "HeroMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	showLogo?: GraphQLTypes["FieldMeta"] | undefined,
	middleContent?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["Gallery"]: {
	__typename: "Gallery",
	_meta?: GraphQLTypes["GalleryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GalleryItem"]>,
	paginateItems: GraphQLTypes["GalleryItemConnection"]
};
	["GalleryMeta"]: {
	__typename: "GalleryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItem"]: {
	__typename: "GalleryItem",
	_meta?: GraphQLTypes["GalleryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["Gallery"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined
};
	["GalleryItemMeta"]: {
	__typename: "GalleryItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	caption?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined
};
	["GalleryItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	caption?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["GalleryOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined
};
	["GalleryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["GalleryItemConnection"]: {
	__typename: "GalleryItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryItemEdge"]>
};
	["GalleryItemEdge"]: {
	__typename: "GalleryItemEdge",
	node: GraphQLTypes["GalleryItem"]
};
	["SocialsAndApps"]: {
	__typename: "SocialsAndApps",
	_meta?: GraphQLTypes["SocialsAndAppsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	instagram?: boolean | undefined,
	appStore?: boolean | undefined,
	googlePlay?: boolean | undefined
};
	["SocialsAndAppsMeta"]: {
	__typename: "SocialsAndAppsMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	instagram?: GraphQLTypes["FieldMeta"] | undefined,
	appStore?: GraphQLTypes["FieldMeta"] | undefined,
	googlePlay?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGrid"]: {
	__typename: "ProductGrid",
	_meta?: GraphQLTypes["ProductGridMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isCarousel?: boolean | undefined,
	categories: Array<GraphQLTypes["ProductGridCategory"]>,
	type?: GraphQLTypes["ProductGridType"] | undefined,
	categoriesByItems?: GraphQLTypes["ProductGridCategory"] | undefined,
	categoriesByImages?: GraphQLTypes["ProductGridCategory"] | undefined,
	paginateCategories: GraphQLTypes["ProductGridCategoryConnection"]
};
	["ProductGridMeta"]: {
	__typename: "ProductGridMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	isCarousel?: GraphQLTypes["FieldMeta"] | undefined,
	categories?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridCategory"]: {
	__typename: "ProductGridCategory",
	_meta?: GraphQLTypes["ProductGridCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	root?: GraphQLTypes["ProductGrid"] | undefined,
	items: Array<GraphQLTypes["ProductGridItem"]>,
	images?: GraphQLTypes["ImageList"] | undefined,
	paginateItems: GraphQLTypes["ProductGridItemConnection"]
};
	["ProductGridCategoryMeta"]: {
	__typename: "ProductGridCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined,
	images?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItem"]: {
	__typename: "ProductGridItem",
	_meta?: GraphQLTypes["ProductGridItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	lead?: string | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	category?: GraphQLTypes["ProductGridCategory"] | undefined,
	description?: string | undefined
};
	["ProductGridItemMeta"]: {
	__typename: "ProductGridItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	lead?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	category?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGridItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	lead?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	category?: GraphQLTypes["ProductGridCategoryOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductGridCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductGridOrderBy"] | undefined,
	images?: GraphQLTypes["ImageListOrderBy"] | undefined
};
	["ProductGridOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	isCarousel?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageList"]: {
	__typename: "ImageList",
	_meta?: GraphQLTypes["ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ImageListItem"]>,
	itemsByImage?: GraphQLTypes["ImageListItem"] | undefined,
	paginateItems: GraphQLTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
	__typename: "ImageListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
	__typename: "ImageListItem",
	_meta?: GraphQLTypes["ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ImageList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
	__typename: "ImageListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ImageListOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ImageListItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["ImageListItemConnection"]: {
	__typename: "ImageListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
	__typename: "ImageListItemEdge",
	node: GraphQLTypes["ImageListItem"]
};
	["ProductGridItemConnection"]: {
	__typename: "ProductGridItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridItemEdge"]>
};
	["ProductGridItemEdge"]: {
	__typename: "ProductGridItemEdge",
	node: GraphQLTypes["ProductGridItem"]
};
	["ProductGridCategoriesByItemsUniqueWhere"]: {
		items?: GraphQLTypes["ProductGridItemUniqueWhere"] | undefined
};
	["ProductGridItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductGridCategoriesByImagesUniqueWhere"]: {
		images?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["ProductGridCategoryConnection"]: {
	__typename: "ProductGridCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridCategoryEdge"]>
};
	["ProductGridCategoryEdge"]: {
	__typename: "ProductGridCategoryEdge",
	node: GraphQLTypes["ProductGridCategory"]
};
	["ProductBannerList"]: {
	__typename: "ProductBannerList",
	_meta?: GraphQLTypes["ProductBannerListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateItems: GraphQLTypes["ProductBannerItemConnection"]
};
	["ProductBannerListMeta"]: {
	__typename: "ProductBannerListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItem"]: {
	__typename: "ProductBannerItem",
	_meta?: GraphQLTypes["ProductBannerItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductBannerList"] | undefined,
	banner?: GraphQLTypes["ProductBanner"] | undefined,
	size?: GraphQLTypes["ProductBannerItemSize"] | undefined
};
	["ProductBannerItemMeta"]: {
	__typename: "ProductBannerItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	banner?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBanner"]: {
	__typename: "ProductBanner",
	_meta?: GraphQLTypes["ProductBannerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	beforeTitle?: string | undefined,
	title?: string | undefined,
	label?: string | undefined,
	button?: GraphQLTypes["Link"] | undefined,
	backgroundImage?: GraphQLTypes["Image"] | undefined,
	productImage?: GraphQLTypes["Image"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductBannerMeta"]: {
	__typename: "ProductBannerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	beforeTitle?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	button?: GraphQLTypes["FieldMeta"] | undefined,
	backgroundImage?: GraphQLTypes["FieldMeta"] | undefined,
	productImage?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductBannerItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProductBannerListOrderBy"] | undefined,
	banner?: GraphQLTypes["ProductBannerOrderBy"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductBannerListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductBannerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	beforeTitle?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined,
	button?: GraphQLTypes["LinkOrderBy"] | undefined,
	backgroundImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	productImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["ProductBannerItemConnection"]: {
	__typename: "ProductBannerItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerItemEdge"]>
};
	["ProductBannerItemEdge"]: {
	__typename: "ProductBannerItemEdge",
	node: GraphQLTypes["ProductBannerItem"]
};
	["DeliveryRegions"]: {
	__typename: "DeliveryRegions",
	_meta?: GraphQLTypes["DeliveryRegionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	itemsByImage?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByTextList?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	itemsByGallery?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	paginateItems: GraphQLTypes["DeliveryRegionsItemConnection"]
};
	["DeliveryRegionsMeta"]: {
	__typename: "DeliveryRegionsMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["DeliveryRegionsItem"]: {
	__typename: "DeliveryRegionsItem",
	_meta?: GraphQLTypes["DeliveryRegionsItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	offerLabel?: string | undefined,
	list?: GraphQLTypes["DeliveryRegions"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	textList?: GraphQLTypes["TextList"] | undefined,
	gallery?: GraphQLTypes["ImageList"] | undefined
};
	["DeliveryRegionsItemMeta"]: {
	__typename: "DeliveryRegionsItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	offerLabel?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	textList?: GraphQLTypes["FieldMeta"] | undefined,
	gallery?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextList"]: {
	__typename: "TextList",
	_meta?: GraphQLTypes["TextListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["TextItem"]>,
	paginateItems: GraphQLTypes["TextItemConnection"]
};
	["TextListMeta"]: {
	__typename: "TextListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItem"]: {
	__typename: "TextItem",
	_meta?: GraphQLTypes["TextItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["TextList"] | undefined,
	title?: string | undefined
};
	["TextItemMeta"]: {
	__typename: "TextItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["TextItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["TextListOrderBy"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined
};
	["TextListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["TextItemConnection"]: {
	__typename: "TextItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextItemEdge"]>
};
	["TextItemEdge"]: {
	__typename: "TextItemEdge",
	node: GraphQLTypes["TextItem"]
};
	["DeliveryRegionsItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	offerLabel?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["DeliveryRegionsOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	textList?: GraphQLTypes["TextListOrderBy"] | undefined,
	gallery?: GraphQLTypes["ImageListOrderBy"] | undefined
};
	["DeliveryRegionsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["DeliveryRegionsItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["DeliveryRegionsItemsByTextListUniqueWhere"]: {
		textList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["DeliveryRegionsItemsByGalleryUniqueWhere"]: {
		gallery?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["DeliveryRegionsItemConnection"]: {
	__typename: "DeliveryRegionsItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsItemEdge"]>
};
	["DeliveryRegionsItemEdge"]: {
	__typename: "DeliveryRegionsItemEdge",
	node: GraphQLTypes["DeliveryRegionsItem"]
};
	["GridTiles"]: {
	__typename: "GridTiles",
	_meta?: GraphQLTypes["GridTilesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["GridTileItem"]>,
	itemsByLink?: GraphQLTypes["GridTileItem"] | undefined,
	paginateItems: GraphQLTypes["GridTileItemConnection"]
};
	["GridTilesMeta"]: {
	__typename: "GridTilesMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["GridTileItem"]: {
	__typename: "GridTileItem",
	_meta?: GraphQLTypes["GridTileItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["GridTiles"] | undefined,
	medium?: GraphQLTypes["Medium"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["GridTileItemMeta"]: {
	__typename: "GridTileItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	medium?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["GridTileItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["GridTilesOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["GridTilesOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["GridTilesItemsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LinkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["GridTileItemConnection"]: {
	__typename: "GridTileItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GridTileItemEdge"]>
};
	["GridTileItemEdge"]: {
	__typename: "GridTileItemEdge",
	node: GraphQLTypes["GridTileItem"]
};
	["ContentReferenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	primaryText?: GraphQLTypes["OrderDirection"] | undefined,
	secondaryText?: GraphQLTypes["OrderDirection"] | undefined,
	jsonContent?: GraphQLTypes["OrderDirection"] | undefined,
	block?: GraphQLTypes["ContentBlockOrderBy"] | undefined,
	colorPallete?: GraphQLTypes["ColorPalleteOrderBy"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	blogPosts?: GraphQLTypes["BlogPostListOrderBy"] | undefined,
	links?: GraphQLTypes["LinkListOrderBy"] | undefined,
	products?: GraphQLTypes["ProductListOrderBy"] | undefined,
	contentSize?: GraphQLTypes["OrderDirection"] | undefined,
	misc?: GraphQLTypes["OrderDirection"] | undefined,
	hero?: GraphQLTypes["HeroOrderBy"] | undefined,
	gallery?: GraphQLTypes["GalleryOrderBy"] | undefined,
	socialsAndApps?: GraphQLTypes["SocialsAndAppsOrderBy"] | undefined,
	productGrid?: GraphQLTypes["ProductGridOrderBy"] | undefined,
	productBanners?: GraphQLTypes["ProductBannerListOrderBy"] | undefined,
	deliveryRegions?: GraphQLTypes["DeliveryRegionsOrderBy"] | undefined,
	textList?: GraphQLTypes["TextListOrderBy"] | undefined,
	gridTiles?: GraphQLTypes["GridTilesOrderBy"] | undefined
};
	["ContentBlockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	json?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined
};
	["HeroOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	showLogo?: GraphQLTypes["OrderDirection"] | undefined,
	middleContent?: GraphQLTypes["OrderDirection"] | undefined,
	medium?: GraphQLTypes["MediumOrderBy"] | undefined
};
	["SocialsAndAppsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	instagram?: GraphQLTypes["OrderDirection"] | undefined,
	appStore?: GraphQLTypes["OrderDirection"] | undefined,
	googlePlay?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentBlockReferencesByProductsUniqueWhere"]: {
		products?: GraphQLTypes["ProductListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByProductBannersUniqueWhere"]: {
		productBanners?: GraphQLTypes["ProductBannerListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: {
		deliveryRegions?: GraphQLTypes["DeliveryRegionsUniqueWhere"] | undefined
};
	["ContentReferenceConnection"]: {
	__typename: "ContentReferenceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
	__typename: "ContentReferenceEdge",
	node: GraphQLTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: {
		references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentBlockConnection"]: {
	__typename: "ContentBlockConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
	__typename: "ContentBlockEdge",
	node: GraphQLTypes["ContentBlock"]
};
	["TranslationCataloguesIdentifier"]: {
	__typename: "TranslationCataloguesIdentifier",
	_meta?: GraphQLTypes["TranslationCataloguesIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	catalogueByDomain?: GraphQLTypes["TranslationCatalogue"] | undefined,
	catalogueByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	paginateCatalogue: GraphQLTypes["TranslationCatalogueConnection"]
};
	["TranslationCataloguesIdentifierMeta"]: {
	__typename: "TranslationCataloguesIdentifierMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogue"]: {
	__typename: "TranslationCatalogue",
	_meta?: GraphQLTypes["TranslationCatalogueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogue"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	valuesByKey?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationCatalogueMeta"]: {
	__typename: "TranslationCatalogueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	fallback?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationDomain"]: {
	__typename: "TranslationDomain",
	_meta?: GraphQLTypes["TranslationDomainMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<GraphQLTypes["TranslationCatalogue"]>,
	keys: Array<GraphQLTypes["TranslationKey"]>,
	cataloguesByIdentifier?: GraphQLTypes["TranslationCatalogue"] | undefined,
	cataloguesByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	keysByIdentifier?: GraphQLTypes["TranslationKey"] | undefined,
	keysByValues?: GraphQLTypes["TranslationKey"] | undefined,
	paginateCatalogues: GraphQLTypes["TranslationCatalogueConnection"],
	paginateKeys: GraphQLTypes["TranslationKeyConnection"]
};
	["TranslationDomainMeta"]: {
	__typename: "TranslationDomainMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	catalogues?: GraphQLTypes["FieldMeta"] | undefined,
	keys?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	domain?: GraphQLTypes["TranslationDomainOrderBy"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogueOrderBy"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"] | undefined
};
	["TranslationDomainOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined
};
	["TranslationKey"]: {
	__typename: "TranslationKey",
	_meta?: GraphQLTypes["TranslationKeyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	contentType: GraphQLTypes["TranslationContentType"],
	note?: string | undefined,
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	valuesByCatalogue?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationKeyMeta"]: {
	__typename: "TranslationKeyMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	contentType?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValue"]: {
	__typename: "TranslationValue",
	_meta?: GraphQLTypes["TranslationValueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	value: string,
	catalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	key?: GraphQLTypes["TranslationKey"] | undefined
};
	["TranslationValueMeta"]: {
	__typename: "TranslationValueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined,
	key?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	value?: GraphQLTypes["OrderDirection"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueOrderBy"] | undefined,
	key?: GraphQLTypes["TranslationKeyOrderBy"] | undefined
};
	["TranslationKeyOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	contentType?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	domain?: GraphQLTypes["TranslationDomainOrderBy"] | undefined
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
		catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationValueConnection"]: {
	__typename: "TranslationValueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationValueEdge"]>
};
	["TranslationValueEdge"]: {
	__typename: "TranslationValueEdge",
	node: GraphQLTypes["TranslationValue"]
};
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
		identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
		identifier?: string | undefined
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueConnection"]: {
	__typename: "TranslationCatalogueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCatalogueEdge"]>
};
	["TranslationCatalogueEdge"]: {
	__typename: "TranslationCatalogueEdge",
	node: GraphQLTypes["TranslationCatalogue"]
};
	["TranslationKeyConnection"]: {
	__typename: "TranslationKeyConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationKeyEdge"]>
};
	["TranslationKeyEdge"]: {
	__typename: "TranslationKeyEdge",
	node: GraphQLTypes["TranslationKey"]
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
		key?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
		domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["ProductPackingLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	shortName?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductPackingOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductLocale"]: {
	__typename: "ProductLocale",
	_meta?: GraphQLTypes["ProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	root?: GraphQLTypes["Product"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	featureList?: GraphQLTypes["TextList"] | undefined,
	printableName?: string | undefined,
	firstSticker?: string | undefined,
	secondSticker?: string | undefined
};
	["ProductLocaleMeta"]: {
	__typename: "ProductLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	featureList?: GraphQLTypes["FieldMeta"] | undefined,
	printableName?: GraphQLTypes["FieldMeta"] | undefined,
	firstSticker?: GraphQLTypes["FieldMeta"] | undefined,
	secondSticker?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	featureList?: GraphQLTypes["TextListOrderBy"] | undefined,
	printableName?: GraphQLTypes["OrderDirection"] | undefined,
	firstSticker?: GraphQLTypes["OrderDirection"] | undefined,
	secondSticker?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductRecipeLocale"]: {
	__typename: "ProductRecipeLocale",
	_meta?: GraphQLTypes["ProductRecipeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductRecipe"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	description?: string | undefined
};
	["ProductRecipeLocaleMeta"]: {
	__typename: "ProductRecipeLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductRecipe"]: {
	__typename: "ProductRecipe",
	_meta?: GraphQLTypes["ProductRecipeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	products: Array<GraphQLTypes["Product"]>,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	ingredients: Array<GraphQLTypes["ProductIngredientItem"]>,
	needsPrepping: boolean,
	locales: Array<GraphQLTypes["ProductRecipeLocale"]>,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	description?: string | undefined,
	productsByCode?: GraphQLTypes["Product"] | undefined,
	productsByStocks?: GraphQLTypes["Product"] | undefined,
	productsByGroupPrices?: GraphQLTypes["Product"] | undefined,
	productsByCartItems?: GraphQLTypes["Product"] | undefined,
	productsByOrderItems?: GraphQLTypes["Product"] | undefined,
	productsByLocales?: GraphQLTypes["Product"] | undefined,
	productsByCustomerPrices?: GraphQLTypes["Product"] | undefined,
	productsByVirtualProduct?: GraphQLTypes["Product"] | undefined,
	productsByMeta?: GraphQLTypes["Product"] | undefined,
	productsByPage?: GraphQLTypes["Product"] | undefined,
	localesByLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateIngredients: GraphQLTypes["ProductIngredientItemConnection"],
	paginateLocales: GraphQLTypes["ProductRecipeLocaleConnection"]
};
	["ProductRecipeMeta"]: {
	__typename: "ProductRecipeMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	color?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	gutTuning?: GraphQLTypes["FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItem"]: {
	__typename: "ProductIngredientItem",
	_meta?: GraphQLTypes["ProductIngredientItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	internalOrder: number,
	recipe?: GraphQLTypes["ProductRecipe"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredient"] | undefined
};
	["ProductIngredientItemMeta"]: {
	__typename: "ProductIngredientItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	internalOrder?: GraphQLTypes["FieldMeta"] | undefined,
	recipe?: GraphQLTypes["FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductIngredientItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	internalOrder?: GraphQLTypes["OrderDirection"] | undefined,
	recipe?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	ingredient?: GraphQLTypes["ProductIngredientOrderBy"] | undefined
};
	["ProductRecipeLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductRecipeOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductRecipeProductsByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["ProductRecipeProductsByStocksUniqueWhere"]: {
		stocks?: GraphQLTypes["ProductStockUniqueWhere"] | undefined
};
	["ProductRecipeProductsByGroupPricesUniqueWhere"]: {
		groupPrices?: GraphQLTypes["ProductGroupPriceUniqueWhere"] | undefined
};
	["ProductRecipeProductsByCartItemsUniqueWhere"]: {
		cartItems?: GraphQLTypes["CartItemUniqueWhere"] | undefined
};
	["ProductRecipeProductsByOrderItemsUniqueWhere"]: {
		orderItems?: GraphQLTypes["OrderItemUniqueWhere"] | undefined
};
	["ProductRecipeProductsByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined
};
	["ProductRecipeProductsByCustomerPricesUniqueWhere"]: {
		customerPrices?: GraphQLTypes["ProductCustomerPriceUniqueWhere"] | undefined
};
	["ProductRecipeProductsByVirtualProductUniqueWhere"]: {
		virtualProduct?: GraphQLTypes["VirtualProductUniqueWhere"] | undefined
};
	["ProductRecipeProductsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["ProductMetadataUniqueWhere"] | undefined
};
	["ProductRecipeProductsByPageUniqueWhere"]: {
		page?: GraphQLTypes["PageUniqueWhere"] | undefined
};
	["ProductRecipeLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductIngredientItemConnection"]: {
	__typename: "ProductIngredientItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientItemEdge"]>
};
	["ProductIngredientItemEdge"]: {
	__typename: "ProductIngredientItemEdge",
	node: GraphQLTypes["ProductIngredientItem"]
};
	["ProductRecipeLocaleConnection"]: {
	__typename: "ProductRecipeLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeLocaleEdge"]>
};
	["ProductRecipeLocaleEdge"]: {
	__typename: "ProductRecipeLocaleEdge",
	node: GraphQLTypes["ProductRecipeLocale"]
};
	["IdentificationCodeLocale"]: {
	__typename: "IdentificationCodeLocale",
	_meta?: GraphQLTypes["IdentificationCodeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	successMessage?: string | undefined,
	root?: GraphQLTypes["IdentificationCode"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["IdentificationCodeLocaleMeta"]: {
	__typename: "IdentificationCodeLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	successMessage?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["IdentificationCode"]: {
	__typename: "IdentificationCode",
	_meta?: GraphQLTypes["IdentificationCodeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	createdAt: GraphQLTypes["DateTime"],
	locales: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	internalName?: string | undefined,
	localesByLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	paginateLocales: GraphQLTypes["IdentificationCodeLocaleConnection"]
};
	["IdentificationCodeMeta"]: {
	__typename: "IdentificationCodeMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	internalName?: GraphQLTypes["FieldMeta"] | undefined
};
	["IdentificationCodeLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	successMessage?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["IdentificationCodeOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["IdentificationCodeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["OrderDirection"] | undefined,
	internalName?: GraphQLTypes["OrderDirection"] | undefined
};
	["IdentificationCodeLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["IdentificationCodeLocaleConnection"]: {
	__typename: "IdentificationCodeLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IdentificationCodeLocaleEdge"]>
};
	["IdentificationCodeLocaleEdge"]: {
	__typename: "IdentificationCodeLocaleEdge",
	node: GraphQLTypes["IdentificationCodeLocale"]
};
	["SubscriptionBoxLocale"]: {
	__typename: "SubscriptionBoxLocale",
	_meta?: GraphQLTypes["SubscriptionBoxLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	stickerText?: string | undefined,
	descriptionText?: string | undefined,
	root?: GraphQLTypes["SubscriptionBox"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	secondaryTitle?: string | undefined,
	texts?: GraphQLTypes["TextList"] | undefined,
	secondaryTexts?: GraphQLTypes["TextList"] | undefined
};
	["SubscriptionBoxLocaleMeta"]: {
	__typename: "SubscriptionBoxLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	stickerText?: GraphQLTypes["FieldMeta"] | undefined,
	descriptionText?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["FieldMeta"] | undefined,
	texts?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryTexts?: GraphQLTypes["FieldMeta"] | undefined
};
	["SubscriptionBox"]: {
	__typename: "SubscriptionBox",
	_meta?: GraphQLTypes["SubscriptionBoxMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	unique: GraphQLTypes["One"],
	localesByLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	localesByTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	localesBySecondaryTexts?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	paginateLocales: GraphQLTypes["SubscriptionBoxLocaleConnection"]
};
	["SubscriptionBoxMeta"]: {
	__typename: "SubscriptionBoxMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined
};
	["SubscriptionBoxLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	stickerText?: GraphQLTypes["OrderDirection"] | undefined,
	descriptionText?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["SubscriptionBoxOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	secondaryTitle?: GraphQLTypes["OrderDirection"] | undefined,
	texts?: GraphQLTypes["TextListOrderBy"] | undefined,
	secondaryTexts?: GraphQLTypes["TextListOrderBy"] | undefined
};
	["SubscriptionBoxOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["SubscriptionBoxLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["SubscriptionBoxLocalesByTextsUniqueWhere"]: {
		texts?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"]: {
		secondaryTexts?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["SubscriptionBoxLocaleConnection"]: {
	__typename: "SubscriptionBoxLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SubscriptionBoxLocaleEdge"]>
};
	["SubscriptionBoxLocaleEdge"]: {
	__typename: "SubscriptionBoxLocaleEdge",
	node: GraphQLTypes["SubscriptionBoxLocale"]
};
	["LocaleBusinessCategoriesByRootUniqueWhere"]: {
		root?: GraphQLTypes["BusinessCategoryUniqueWhere"] | undefined
};
	["LocaleProductCategoryListsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined
};
	["LocaleProductIngredientsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["LocaleOrderRecurrencesByRootUniqueWhere"]: {
		root?: GraphQLTypes["OrderRecurrenceUniqueWhere"] | undefined
};
	["LocaleBlogsByRootUniqueWhere"]: {
		root?: GraphQLTypes["BlogUniqueWhere"] | undefined
};
	["LocaleBlogPostsByRootUniqueWhere"]: {
		root?: GraphQLTypes["BlogPostUniqueWhere"] | undefined
};
	["LocaleBlogPostsByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["LocaleBlogPostsByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleBlogPostsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleBlogPostsBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocalePagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["PageUniqueWhere"] | undefined
};
	["LocalePagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocalePagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocalePagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocalePagesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["CoverUniqueWhere"] | undefined
};
	["LocaleAllergensByRootUniqueWhere"]: {
		root?: GraphQLTypes["AllergenUniqueWhere"] | undefined
};
	["LocaleProductPackingsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductPackingUniqueWhere"] | undefined
};
	["LocaleProductsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["LocaleProductsByFeatureListUniqueWhere"]: {
		featureList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["LocaleProductRecipesByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductRecipeUniqueWhere"] | undefined
};
	["LocaleDeliveryMethodsByRootUniqueWhere"]: {
		root?: GraphQLTypes["DeliveryMethodUniqueWhere"] | undefined
};
	["LocaleIdentificationCodesByRootUniqueWhere"]: {
		root?: GraphQLTypes["IdentificationCodeUniqueWhere"] | undefined
};
	["LocaleSubscriptionBoxesByRootUniqueWhere"]: {
		root?: GraphQLTypes["SubscriptionBoxUniqueWhere"] | undefined
};
	["LocaleSubscriptionBoxesByTextsUniqueWhere"]: {
		texts?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"]: {
		secondaryTexts?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["ProductPackingLocaleConnection"]: {
	__typename: "ProductPackingLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingLocaleEdge"]>
};
	["ProductPackingLocaleEdge"]: {
	__typename: "ProductPackingLocaleEdge",
	node: GraphQLTypes["ProductPackingLocale"]
};
	["ProductLocaleConnection"]: {
	__typename: "ProductLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductLocaleEdge"]>
};
	["ProductLocaleEdge"]: {
	__typename: "ProductLocaleEdge",
	node: GraphQLTypes["ProductLocale"]
};
	["ProductPackingLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductStock"]: {
	__typename: "ProductStock",
	_meta?: GraphQLTypes["ProductStockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	product?: GraphQLTypes["Product"] | undefined,
	store?: GraphQLTypes["Store"] | undefined
};
	["ProductStockMeta"]: {
	__typename: "ProductStockMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	quantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	store?: GraphQLTypes["FieldMeta"] | undefined
};
	["Store"]: {
	__typename: "Store",
	_meta?: GraphQLTypes["StoreMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	description?: string | undefined,
	internalNote?: string | undefined
};
	["StoreMeta"]: {
	__typename: "StoreMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	quantity?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	store?: GraphQLTypes["StoreOrderBy"] | undefined
};
	["StoreOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductGroupPrice"]: {
	__typename: "ProductGroupPrice",
	_meta?: GraphQLTypes["ProductGroupPriceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	priceCents: number,
	group?: GraphQLTypes["CustomerGroup"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductGroupPriceMeta"]: {
	__typename: "ProductGroupPriceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	group?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerGroup"]: {
	__typename: "CustomerGroup",
	_meta?: GraphQLTypes["CustomerGroupMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	productPrices: Array<GraphQLTypes["ProductGroupPrice"]>,
	customers: Array<GraphQLTypes["Customer"]>,
	discountPermille?: number | undefined,
	productPricesByProduct?: GraphQLTypes["ProductGroupPrice"] | undefined,
	customersByDoubleshotLegacyId?: GraphQLTypes["Customer"] | undefined,
	customersByAddresses?: GraphQLTypes["Customer"] | undefined,
	customersByDefaultBillingAddress?: GraphQLTypes["Customer"] | undefined,
	customersByCredits?: GraphQLTypes["Customer"] | undefined,
	customersByOrders?: GraphQLTypes["Customer"] | undefined,
	customersByPermissions?: GraphQLTypes["Customer"] | undefined,
	customersByName?: GraphQLTypes["Customer"] | undefined,
	customersByIngredientRatings?: GraphQLTypes["Customer"] | undefined,
	customersByBusiness?: GraphQLTypes["Customer"] | undefined,
	customersByCarts?: GraphQLTypes["Customer"] | undefined,
	customersByUser?: GraphQLTypes["Customer"] | undefined,
	customersByInvoices?: GraphQLTypes["Customer"] | undefined,
	customersByInvoicingPlan?: GraphQLTypes["Customer"] | undefined,
	customersByBillingSubjects?: GraphQLTypes["Customer"] | undefined,
	customersByOfferedProducts?: GraphQLTypes["Customer"] | undefined,
	customersByDefaultDeliveryAddress?: GraphQLTypes["Customer"] | undefined,
	customersByDefaultBillingSubject?: GraphQLTypes["Customer"] | undefined,
	customersByAnonymousSession?: GraphQLTypes["Customer"] | undefined,
	customersByBoughtVouchers?: GraphQLTypes["Customer"] | undefined,
	customersByOwnsVouchers?: GraphQLTypes["Customer"] | undefined,
	customersByRedeemedVouchers?: GraphQLTypes["Customer"] | undefined,
	customersByMeta?: GraphQLTypes["Customer"] | undefined,
	paginateProductPrices: GraphQLTypes["ProductGroupPriceConnection"],
	paginateCustomers: GraphQLTypes["CustomerConnection"]
};
	["CustomerGroupMeta"]: {
	__typename: "CustomerGroupMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	productPrices?: GraphQLTypes["FieldMeta"] | undefined,
	customers?: GraphQLTypes["FieldMeta"] | undefined,
	discountPermille?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductGroupPriceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	group?: GraphQLTypes["CustomerGroupOrderBy"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["CustomerGroupProductPricesByProductUniqueWhere"]: {
		product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["CustomerGroupCustomersByAddressesUniqueWhere"]: {
		addresses?: GraphQLTypes["AddressUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByDefaultBillingAddressUniqueWhere"]: {
		defaultBillingAddress?: GraphQLTypes["AddressUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByCreditsUniqueWhere"]: {
		credits?: GraphQLTypes["CustomerCreditUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByOrdersUniqueWhere"]: {
		orders?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByPermissionsUniqueWhere"]: {
		permissions?: GraphQLTypes["CustomerPermissionsUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByNameUniqueWhere"]: {
		name?: GraphQLTypes["CustomerNameUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByIngredientRatingsUniqueWhere"]: {
		ingredientRatings?: GraphQLTypes["CustomerIngredientRatingUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByBusinessUniqueWhere"]: {
		business?: GraphQLTypes["BusinessCustomerUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByCartsUniqueWhere"]: {
		carts?: GraphQLTypes["CartUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByUserUniqueWhere"]: {
		user?: GraphQLTypes["UserUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByInvoicesUniqueWhere"]: {
		invoices?: GraphQLTypes["InvoiceUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByInvoicingPlanUniqueWhere"]: {
		invoicingPlan?: GraphQLTypes["InvoicingPlanUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByBillingSubjectsUniqueWhere"]: {
		billingSubjects?: GraphQLTypes["BillingSubjectUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByOfferedProductsUniqueWhere"]: {
		offeredProducts?: GraphQLTypes["ProductCustomerPriceUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere"]: {
		defaultDeliveryAddress?: GraphQLTypes["AddressUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere"]: {
		defaultBillingSubject?: GraphQLTypes["BillingSubjectUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByAnonymousSessionUniqueWhere"]: {
		anonymousSession?: GraphQLTypes["AnonymousSessionUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByBoughtVouchersUniqueWhere"]: {
		boughtVouchers?: GraphQLTypes["VoucherUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByOwnsVouchersUniqueWhere"]: {
		ownsVouchers?: GraphQLTypes["VoucherUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByRedeemedVouchersUniqueWhere"]: {
		redeemedVouchers?: GraphQLTypes["VoucherRedemptionUniqueWhere"] | undefined
};
	["CustomerGroupCustomersByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["CustomerMetadataUniqueWhere"] | undefined
};
	["ProductGroupPriceConnection"]: {
	__typename: "ProductGroupPriceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGroupPriceEdge"]>
};
	["ProductGroupPriceEdge"]: {
	__typename: "ProductGroupPriceEdge",
	node: GraphQLTypes["ProductGroupPrice"]
};
	["ProductCustomerPrice"]: {
	__typename: "ProductCustomerPrice",
	_meta?: GraphQLTypes["ProductCustomerPriceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	groupDiscountPermille?: number | undefined,
	groupPriceCents?: number | undefined,
	fullPriceCents: number,
	discountedPriceCents?: number | undefined,
	priceCents: number,
	offered: boolean,
	notDiscountable: boolean,
	customer?: GraphQLTypes["Customer"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductCustomerPriceMeta"]: {
	__typename: "ProductCustomerPriceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	groupDiscountPermille?: GraphQLTypes["FieldMeta"] | undefined,
	groupPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	fullPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	discountedPriceCents?: GraphQLTypes["FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["FieldMeta"] | undefined,
	offered?: GraphQLTypes["FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCustomerPriceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	groupDiscountPermille?: GraphQLTypes["OrderDirection"] | undefined,
	groupPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	fullPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	discountedPriceCents?: GraphQLTypes["OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["OrderDirection"] | undefined,
	offered?: GraphQLTypes["OrderDirection"] | undefined,
	notDiscountable?: GraphQLTypes["OrderDirection"] | undefined,
	customer?: GraphQLTypes["CustomerOrderBy"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["ProductMetadata"]: {
	__typename: "ProductMetadata",
	_meta?: GraphQLTypes["ProductMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	archived: boolean,
	available: boolean,
	availableQuantity: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["ProductMetadataMeta"]: {
	__typename: "ProductMetadataMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	archived?: GraphQLTypes["FieldMeta"] | undefined,
	available?: GraphQLTypes["FieldMeta"] | undefined,
	availableQuantity?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductStocksByStoreUniqueWhere"]: {
		store?: GraphQLTypes["StoreUniqueWhere"] | undefined
};
	["ProductGroupPricesByGroupUniqueWhere"]: {
		group?: GraphQLTypes["CustomerGroupUniqueWhere"] | undefined
};
	["ProductCartItemsByCartUniqueWhere"]: {
		cart?: GraphQLTypes["CartUniqueWhere"] | undefined
};
	["ProductOrderItemsByVirtualProductEffectsUniqueWhere"]: {
		virtualProductEffects?: GraphQLTypes["VirtualProductEffectUniqueWhere"] | undefined
};
	["ProductLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductLocalesByFeatureListUniqueWhere"]: {
		featureList?: GraphQLTypes["TextListUniqueWhere"] | undefined
};
	["ProductCustomerPricesByCustomerUniqueWhere"]: {
		customer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["ProductStockConnection"]: {
	__typename: "ProductStockConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductStockEdge"]>
};
	["ProductStockEdge"]: {
	__typename: "ProductStockEdge",
	node: GraphQLTypes["ProductStock"]
};
	["ProductCustomerPriceConnection"]: {
	__typename: "ProductCustomerPriceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCustomerPriceEdge"]>
};
	["ProductCustomerPriceEdge"]: {
	__typename: "ProductCustomerPriceEdge",
	node: GraphQLTypes["ProductCustomerPrice"]
};
	["ChannelConnection"]: {
	__typename: "ChannelConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ChannelEdge"]>
};
	["ChannelEdge"]: {
	__typename: "ChannelEdge",
	node: GraphQLTypes["Channel"]
};
	["CustomerPermissions"]: {
	__typename: "CustomerPermissions",
	_meta?: GraphQLTypes["CustomerPermissionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	canPlaceOrder?: boolean | undefined,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["CustomerPermissionsMeta"]: {
	__typename: "CustomerPermissionsMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	canPlaceOrder?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerName"]: {
	__typename: "CustomerName",
	_meta?: GraphQLTypes["CustomerNameMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	nominative: string,
	vocative?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["CustomerNameMeta"]: {
	__typename: "CustomerNameMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	nominative?: GraphQLTypes["FieldMeta"] | undefined,
	vocative?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["User"]: {
	__typename: "User",
	_meta?: GraphQLTypes["UserMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	staff?: GraphQLTypes["Staff"] | undefined,
	customer?: GraphQLTypes["Customer"] | undefined,
	tenantPersonId: GraphQLTypes["UUID"],
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["UserMetadata"] | undefined
};
	["UserMeta"]: {
	__typename: "UserMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	staff?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	tenantPersonId?: GraphQLTypes["FieldMeta"] | undefined,
	disabledAt?: GraphQLTypes["FieldMeta"] | undefined,
	verifiedAt?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	deletionRequestedAt?: GraphQLTypes["FieldMeta"] | undefined,
	deletionExpectedAt?: GraphQLTypes["FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["FieldMeta"] | undefined,
	meta?: GraphQLTypes["FieldMeta"] | undefined
};
	["Staff"]: {
	__typename: "Staff",
	_meta?: GraphQLTypes["StaffMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	user?: GraphQLTypes["User"] | undefined,
	photo?: GraphQLTypes["Image"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["StaffMeta"]: {
	__typename: "StaffMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	user?: GraphQLTypes["FieldMeta"] | undefined,
	photo?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined
};
	["UserMetadata"]: {
	__typename: "UserMetadata",
	_meta?: GraphQLTypes["UserMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	userProfileUrl: string,
	tenantEmail?: string | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	tenantIdentityId?: GraphQLTypes["UUID"] | undefined,
	globalRoles?: GraphQLTypes["Json"] | undefined,
	projectRoles?: GraphQLTypes["Json"] | undefined,
	projectRolesString?: string | undefined,
	externalIdentifiers?: GraphQLTypes["Json"] | undefined,
	externalIdentifiersString?: string | undefined,
	idpSlugs?: GraphQLTypes["Json"] | undefined,
	idpSlugsString?: string | undefined,
	user?: GraphQLTypes["User"] | undefined
};
	["UserMetadataMeta"]: {
	__typename: "UserMetadataMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	userProfileUrl?: GraphQLTypes["FieldMeta"] | undefined,
	tenantEmail?: GraphQLTypes["FieldMeta"] | undefined,
	tenantPersonId?: GraphQLTypes["FieldMeta"] | undefined,
	tenantIdentityId?: GraphQLTypes["FieldMeta"] | undefined,
	globalRoles?: GraphQLTypes["FieldMeta"] | undefined,
	projectRoles?: GraphQLTypes["FieldMeta"] | undefined,
	projectRolesString?: GraphQLTypes["FieldMeta"] | undefined,
	externalIdentifiers?: GraphQLTypes["FieldMeta"] | undefined,
	externalIdentifiersString?: GraphQLTypes["FieldMeta"] | undefined,
	idpSlugs?: GraphQLTypes["FieldMeta"] | undefined,
	idpSlugsString?: GraphQLTypes["FieldMeta"] | undefined,
	user?: GraphQLTypes["FieldMeta"] | undefined
};
	["InvoicingPlan"]: {
	__typename: "InvoicingPlan",
	_meta?: GraphQLTypes["InvoicingPlanMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	customer?: GraphQLTypes["Customer"] | undefined,
	preset?: GraphQLTypes["InvoicingPreset"] | undefined
};
	["InvoicingPlanMeta"]: {
	__typename: "InvoicingPlanMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined,
	preset?: GraphQLTypes["FieldMeta"] | undefined
};
	["InvoicingPreset"]: {
	__typename: "InvoicingPreset",
	_meta?: GraphQLTypes["InvoicingPresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["InvoicingPresetType"] | undefined,
	startsAt?: GraphQLTypes["DateTime"] | undefined,
	endsAt?: GraphQLTypes["DateTime"] | undefined,
	intervalOfDays?: number | undefined,
	invoicingPlan?: GraphQLTypes["InvoicingPlan"] | undefined
};
	["InvoicingPresetMeta"]: {
	__typename: "InvoicingPresetMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	startsAt?: GraphQLTypes["FieldMeta"] | undefined,
	endsAt?: GraphQLTypes["FieldMeta"] | undefined,
	intervalOfDays?: GraphQLTypes["FieldMeta"] | undefined,
	invoicingPlan?: GraphQLTypes["FieldMeta"] | undefined
};
	["AnonymousSession"]: {
	__typename: "AnonymousSession",
	_meta?: GraphQLTypes["AnonymousSessionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey: string,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["AnonymousSessionMeta"]: {
	__typename: "AnonymousSessionMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["FieldMeta"] | undefined,
	lastActiveAt?: GraphQLTypes["FieldMeta"] | undefined,
	anonymousSessionKey?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerMetadata"]: {
	__typename: "CustomerMetadata",
	_meta?: GraphQLTypes["CustomerMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isBusiness: boolean,
	name?: string | undefined,
	fallbackName: string,
	remainingCreditsCents: number,
	ordersTotalCents: number,
	customerProfileUrl: string,
	customer?: GraphQLTypes["Customer"] | undefined
};
	["CustomerMetadataMeta"]: {
	__typename: "CustomerMetadataMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	isBusiness?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	fallbackName?: GraphQLTypes["FieldMeta"] | undefined,
	remainingCreditsCents?: GraphQLTypes["FieldMeta"] | undefined,
	ordersTotalCents?: GraphQLTypes["FieldMeta"] | undefined,
	customerProfileUrl?: GraphQLTypes["FieldMeta"] | undefined,
	customer?: GraphQLTypes["FieldMeta"] | undefined
};
	["CustomerAddressesByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["AddressMetadataUniqueWhere"] | undefined
};
	["CustomerAddressesByGpsUniqueWhere"]: {
		gps?: GraphQLTypes["GpsUniqueWhere"] | undefined
};
	["CustomerAddressesByInvoicesUniqueWhere"]: {
		invoices?: GraphQLTypes["InvoiceUniqueWhere"] | undefined
};
	["CustomerAddressesByBillingAddressOfOrdersUniqueWhere"]: {
		billingAddressOfOrders?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
		deliveryAddressOfOrderDeliveries?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
		defaultDeliveryAddressOfCustomer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerCreditsByVoucherRedemptionUniqueWhere"]: {
		voucherRedemption?: GraphQLTypes["VoucherRedemptionUniqueWhere"] | undefined
};
	["CustomerOrdersBySeqUniqueWhere"]: {
		seq?: number | undefined
};
	["CustomerOrdersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
		doubleShotLegacyNumber?: string | undefined
};
	["CustomerOrdersByDiscountsUniqueWhere"]: {
		discounts?: GraphQLTypes["OrderDiscountUniqueWhere"] | undefined
};
	["CustomerOrdersByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["CustomerOrdersByItemsUniqueWhere"]: {
		items?: GraphQLTypes["OrderItemUniqueWhere"] | undefined
};
	["CustomerOrdersByCartUniqueWhere"]: {
		cart?: GraphQLTypes["CartUniqueWhere"] | undefined
};
	["CustomerOrdersByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["OrderPriceLineUniqueWhere"] | undefined
};
	["CustomerOrdersByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["CustomerOrdersBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["OrderSummaryUniqueWhere"] | undefined
};
	["CustomerIngredientRatingsByIngredientUniqueWhere"]: {
		ingredient?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["CustomerCartsByItemsUniqueWhere"]: {
		items?: GraphQLTypes["CartItemUniqueWhere"] | undefined
};
	["CustomerCartsByOrderUniqueWhere"]: {
		order?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["CustomerCartsByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["CartPriceLineUniqueWhere"] | undefined
};
	["CustomerCartsBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["CartSummaryUniqueWhere"] | undefined
};
	["CustomerInvoicesByPublicKeyUniqueWhere"]: {
		publicKey?: string | undefined
};
	["CustomerInvoicesByFakturoidIdUniqueWhere"]: {
		fakturoidId?: string | undefined
};
	["CustomerInvoicesByOrderPaymentsUniqueWhere"]: {
		orderPayments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["CustomerBillingSubjectsByInvoicesUniqueWhere"]: {
		invoices?: GraphQLTypes["InvoiceUniqueWhere"] | undefined
};
	["CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere"]: {
		defaultBillingSubjectOfCustomer?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerBillingSubjectsByOrdersUniqueWhere"]: {
		orders?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["CustomerOfferedProductsByProductUniqueWhere"]: {
		product?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["CustomerBoughtVouchersByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["CustomerBoughtVouchersByRedemptionUniqueWhere"]: {
		redemption?: GraphQLTypes["VoucherRedemptionUniqueWhere"] | undefined
};
	["CustomerOwnsVouchersByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["CustomerOwnsVouchersByRedemptionUniqueWhere"]: {
		redemption?: GraphQLTypes["VoucherRedemptionUniqueWhere"] | undefined
};
	["CustomerRedeemedVouchersByCustomerCreditUniqueWhere"]: {
		customerCredit?: GraphQLTypes["CustomerCreditUniqueWhere"] | undefined
};
	["CustomerRedeemedVouchersByOrderDiscountUniqueWhere"]: {
		orderDiscount?: GraphQLTypes["OrderDiscountUniqueWhere"] | undefined
};
	["CustomerRedeemedVouchersByVoucherUniqueWhere"]: {
		voucher?: GraphQLTypes["VoucherUniqueWhere"] | undefined
};
	["CustomerCreditConnection"]: {
	__typename: "CustomerCreditConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerCreditEdge"]>
};
	["CustomerCreditEdge"]: {
	__typename: "CustomerCreditEdge",
	node: GraphQLTypes["CustomerCredit"]
};
	["BillingSubjectConnection"]: {
	__typename: "BillingSubjectConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BillingSubjectEdge"]>
};
	["BillingSubjectEdge"]: {
	__typename: "BillingSubjectEdge",
	node: GraphQLTypes["BillingSubject"]
};
	["VoucherRedemptionConnection"]: {
	__typename: "VoucherRedemptionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VoucherRedemptionEdge"]>
};
	["VoucherRedemptionEdge"]: {
	__typename: "VoucherRedemptionEdge",
	node: GraphQLTypes["VoucherRedemption"]
};
	["Gps"]: {
	__typename: "Gps",
	_meta?: GraphQLTypes["GpsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	latitude: number,
	longitude: number,
	address?: GraphQLTypes["Address"] | undefined
};
	["GpsMeta"]: {
	__typename: "GpsMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	latitude?: GraphQLTypes["FieldMeta"] | undefined,
	longitude?: GraphQLTypes["FieldMeta"] | undefined,
	address?: GraphQLTypes["FieldMeta"] | undefined
};
	["AddressInvoicesByPublicKeyUniqueWhere"]: {
		publicKey?: string | undefined
};
	["AddressInvoicesByFakturoidIdUniqueWhere"]: {
		fakturoidId?: string | undefined
};
	["AddressInvoicesByOrderPaymentsUniqueWhere"]: {
		orderPayments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["AddressBillingAddressOfOrdersBySeqUniqueWhere"]: {
		seq?: number | undefined
};
	["AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
		doubleShotLegacyNumber?: string | undefined
};
	["AddressBillingAddressOfOrdersByDiscountsUniqueWhere"]: {
		discounts?: GraphQLTypes["OrderDiscountUniqueWhere"] | undefined
};
	["AddressBillingAddressOfOrdersByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined
};
	["AddressBillingAddressOfOrdersByItemsUniqueWhere"]: {
		items?: GraphQLTypes["OrderItemUniqueWhere"] | undefined
};
	["AddressBillingAddressOfOrdersByCartUniqueWhere"]: {
		cart?: GraphQLTypes["CartUniqueWhere"] | undefined
};
	["AddressBillingAddressOfOrdersByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["OrderPriceLineUniqueWhere"] | undefined
};
	["AddressBillingAddressOfOrdersByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["OrderPaymentUniqueWhere"] | undefined
};
	["AddressBillingAddressOfOrdersBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["OrderSummaryUniqueWhere"] | undefined
};
	["AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"]: {
		trackingCode?: string | undefined
};
	["AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"]: {
		order?: GraphQLTypes["OrderUniqueWhere"] | undefined
};
	["OrderDeliveryConnection"]: {
	__typename: "OrderDeliveryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderDeliveryEdge"]>
};
	["OrderDeliveryEdge"]: {
	__typename: "OrderDeliveryEdge",
	node: GraphQLTypes["OrderDelivery"]
};
	["_ValidationResult"]: {
	__typename: "_ValidationResult",
	valid: boolean,
	errors: Array<GraphQLTypes["_ValidationError"]>
};
	["_ValidationError"]: {
	__typename: "_ValidationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	message: GraphQLTypes["_ValidationMessage"]
};
	["_PathFragment"]:{
        	__typename:"_FieldPathFragment" | "_IndexPathFragment"
        	['...on _FieldPathFragment']: '__union' & GraphQLTypes["_FieldPathFragment"];
	['...on _IndexPathFragment']: '__union' & GraphQLTypes["_IndexPathFragment"];
};
	["_FieldPathFragment"]: {
	__typename: "_FieldPathFragment",
	field: string
};
	["_IndexPathFragment"]: {
	__typename: "_IndexPathFragment",
	index: number,
	alias?: string | undefined
};
	["_ValidationMessage"]: {
	__typename: "_ValidationMessage",
	text: string
};
	["AddressCreateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["AddressCreateCustomerEntityRelationInput"] | undefined,
	country?: GraphQLTypes["AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["AddressCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["AddressCreateCountryEntityRelationInput"]: {
		connect?: GraphQLTypes["CountryUniqueWhere"] | undefined
};
	["CountryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["AddressCreateDeliveryZoneEntityRelationInput"]: {
		connect?: GraphQLTypes["DeliveryZoneUniqueWhere"] | undefined
};
	["DeliveryZoneUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	addresses?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	addressesMetadata?: GraphQLTypes["AddressMetadataUniqueWhere"] | undefined
};
	["AddressCreateGpsEntityRelationInput"]: {
		connect?: GraphQLTypes["GpsUniqueWhere"] | undefined,
	create?: GraphQLTypes["GpsWithoutAddressCreateInput"] | undefined
};
	["GpsWithoutAddressCreateInput"]: {
		latitude?: number | undefined,
	longitude?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["AddressCreateBillingAddressOfOrdersEntityRelationInput"]: {
		connect?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["OrderWithoutBillingAddressCreateInput"] | undefined,
	alias?: string | undefined
};
	["OrderWithoutBillingAddressCreateInput"]: {
		state?: GraphQLTypes["OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["OrderCreateChannelEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["OrderCreateItemsEntityRelationInput"]> | undefined,
	cart?: GraphQLTypes["OrderCreateCartEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	fulfillmentNote?: string | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["OrderCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["OrderCreateChannelEntityRelationInput"]: {
		connect?: GraphQLTypes["ChannelUniqueWhere"] | undefined
};
	["ChannelUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: GraphQLTypes["ChannelCode"] | undefined,
	payments?: GraphQLTypes["ChannelPaymentUniqueWhere"] | undefined,
	deliveries?: GraphQLTypes["ChannelDeliveryUniqueWhere"] | undefined,
	carts?: GraphQLTypes["CartUniqueWhere"] | undefined
};
	["ChannelPaymentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["OrderCreateDeliveryEntityRelationInput"]: {
		connect?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["OrderDeliveryWithoutOrderCreateInput"] | undefined
};
	["OrderDeliveryWithoutOrderCreateInput"]: {
		priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["OrderDeliveryCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["OrderDeliveryCreateVatRateEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["OrderDeliveryCreateAddressEntityRelationInput"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["OrderDeliveryCreateMethodEntityRelationInput"]: {
		connect?: GraphQLTypes["DeliveryMethodUniqueWhere"] | undefined
};
	["OrderDeliveryCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["VatRateUniqueWhere"] | undefined
};
	["VatRateUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	preset?: GraphQLTypes["VatRatePreset"] | undefined,
	channelDeliveries?: GraphQLTypes["ChannelDeliveryUniqueWhere"] | undefined,
	channelPayments?: GraphQLTypes["ChannelPaymentUniqueWhere"] | undefined
};
	["OrderDeliveryCreateAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"] | undefined
};
	["AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["AddressCreateCustomerEntityRelationInput"] | undefined,
	country?: GraphQLTypes["AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["OrderCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["OrderItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["OrderItemWithoutOrderCreateInput"] | undefined,
	alias?: string | undefined
};
	["OrderItemWithoutOrderCreateInput"]: {
		unitPriceCents?: number | undefined,
	quantity?: number | undefined,
	vatRate?: GraphQLTypes["OrderItemCreateVatRateEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	product?: GraphQLTypes["OrderItemCreateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["OrderItemCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["VatRateUniqueWhere"] | undefined
};
	["OrderItemCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["OrderCreateCartEntityRelationInput"]: {
		connect?: GraphQLTypes["CartUniqueWhere"] | undefined,
	create?: GraphQLTypes["CartWithoutOrderCreateInput"] | undefined
};
	["CartWithoutOrderCreateInput"]: {
		confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items?: Array<GraphQLTypes["CartCreateItemsEntityRelationInput"]> | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	channel?: GraphQLTypes["CartCreateChannelEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["CartCreateCustomerEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["CartCreateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["CartCreateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["CartCreateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["CartCreatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["CartCreateBillingSubjectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CartCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["CartItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["CartItemWithoutCartCreateInput"] | undefined,
	alias?: string | undefined
};
	["CartItemWithoutCartCreateInput"]: {
		quantity?: number | undefined,
	product?: GraphQLTypes["CartItemCreateProductEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	fulfillmentNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["CartItemCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["CartCreateChannelEntityRelationInput"]: {
		connect?: GraphQLTypes["ChannelUniqueWhere"] | undefined
};
	["CartCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CartCreateDeliveryAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["AddressCreateInput"] | undefined
};
	["CartCreateBillingAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["AddressCreateInput"] | undefined
};
	["CartCreateDeliveryEntityRelationInput"]: {
		connect?: GraphQLTypes["ChannelDeliveryUniqueWhere"] | undefined
};
	["CartCreatePaymentEntityRelationInput"]: {
		connect?: GraphQLTypes["ChannelPaymentUniqueWhere"] | undefined
};
	["CartCreateBillingSubjectEntityRelationInput"]: {
		connect?: GraphQLTypes["BillingSubjectUniqueWhere"] | undefined,
	create?: GraphQLTypes["BillingSubjectCreateInput"] | undefined
};
	["BillingSubjectCreateInput"]: {
		name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["BillingSubjectCreateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["BillingSubjectCreateCustomerEntityRelationInput"] | undefined,
	orders?: Array<GraphQLTypes["BillingSubjectCreateOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["BillingSubjectCreateCountryEntityRelationInput"]: {
		connect?: GraphQLTypes["CountryUniqueWhere"] | undefined
};
	["BillingSubjectCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["BillingSubjectCreateOrdersEntityRelationInput"]: {
		connect?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["OrderWithoutBillingSubjectCreateInput"] | undefined,
	alias?: string | undefined
};
	["OrderWithoutBillingSubjectCreateInput"]: {
		state?: GraphQLTypes["OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["OrderCreateChannelEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["OrderCreateItemsEntityRelationInput"]> | undefined,
	cart?: GraphQLTypes["OrderCreateCartEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["OrderCreateBillingAddressEntityRelationInput"] | undefined,
	seq?: number | undefined,
	doubleshotLegacyId?: string | undefined,
	fulfillmentNote?: string | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["OrderCreateBillingAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["AddressWithoutBillingAddressOfOrdersCreateInput"] | undefined
};
	["AddressWithoutBillingAddressOfOrdersCreateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["AddressCreateCustomerEntityRelationInput"] | undefined,
	country?: GraphQLTypes["AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]: {
		connect?: GraphQLTypes["OrderDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["OrderDeliveryWithoutAddressCreateInput"] | undefined,
	alias?: string | undefined
};
	["OrderDeliveryWithoutAddressCreateInput"]: {
		priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["OrderDeliveryCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["OrderDeliveryCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["OrderDeliveryCreateOrderEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["OrderDeliveryCreateOrderEntityRelationInput"]: {
		connect?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["OrderWithoutDeliveryCreateInput"] | undefined
};
	["OrderWithoutDeliveryCreateInput"]: {
		state?: GraphQLTypes["OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["OrderCreateChannelEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["OrderCreateItemsEntityRelationInput"]> | undefined,
	cart?: GraphQLTypes["OrderCreateCartEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["OrderCreateBillingAddressEntityRelationInput"] | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	fulfillmentNote?: string | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["OrderCreateBillingSubjectEntityRelationInput"]: {
		connect?: GraphQLTypes["BillingSubjectUniqueWhere"] | undefined,
	create?: GraphQLTypes["BillingSubjectWithoutOrdersCreateInput"] | undefined
};
	["BillingSubjectWithoutOrdersCreateInput"]: {
		name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["BillingSubjectCreateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["BillingSubjectCreateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["AddressUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["AddressUpdateCustomerEntityRelationInput"] | undefined,
	country?: GraphQLTypes["AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["AddressUpdateDeliveryZoneEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["AddressUpdateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["AddressUpdateCustomerEntityRelationInput"]: {
		update?: GraphQLTypes["CustomerWithoutAddressesUpdateInput"] | undefined,
	connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["CustomerWithoutAddressesUpdateInput"]: {
		defaultBillingAddress?: GraphQLTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	name?: GraphQLTypes["CustomerUpdateNameEntityRelationInput"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	billingSubjects?: Array<GraphQLTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	anonymousSession?: GraphQLTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerUpdateDefaultBillingAddressEntityRelationInput"]: {
		create?: GraphQLTypes["AddressCreateInput"] | undefined,
	update?: GraphQLTypes["AddressUpdateInput"] | undefined,
	upsert?: GraphQLTypes["CustomerUpsertDefaultBillingAddressRelationInput"] | undefined,
	connect?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["CustomerUpsertDefaultBillingAddressRelationInput"]: {
		update?: GraphQLTypes["AddressUpdateInput"] | undefined,
	create?: GraphQLTypes["AddressCreateInput"] | undefined
};
	["CustomerUpdateNameEntityRelationInput"]: {
		create?: GraphQLTypes["CustomerNameWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["CustomerNameWithoutCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["CustomerUpsertNameRelationInput"] | undefined,
	connect?: GraphQLTypes["CustomerNameUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["CustomerNameWithoutCustomerCreateInput"]: {
		nominative?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerNameWithoutCustomerUpdateInput"]: {
		nominative?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerUpsertNameRelationInput"]: {
		update?: GraphQLTypes["CustomerNameWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["CustomerNameWithoutCustomerCreateInput"] | undefined
};
	["CustomerUpdateIngredientRatingsEntityRelationInput"]: {
		create?: GraphQLTypes["CustomerIngredientRatingWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["CustomerUpdateIngredientRatingsRelationInput"] | undefined,
	upsert?: GraphQLTypes["CustomerUpsertIngredientRatingsRelationInput"] | undefined,
	connect?: GraphQLTypes["CustomerIngredientRatingUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["CustomerIngredientRatingUniqueWhere"] | undefined,
	delete?: GraphQLTypes["CustomerIngredientRatingUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["CustomerIngredientRatingWithoutCustomerCreateInput"]: {
		rating?: GraphQLTypes["CustomerIngredientRatingCreateRatingEntityRelationInput"] | undefined,
	ingredient?: GraphQLTypes["CustomerIngredientRatingCreateIngredientEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerIngredientRatingCreateRatingEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientRatingUniqueWhere"] | undefined
};
	["ProductIngredientRatingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["CustomerIngredientRatingCreateIngredientEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["CustomerUpdateIngredientRatingsRelationInput"]: {
		by?: GraphQLTypes["CustomerIngredientRatingUniqueWhere"] | undefined,
	data?: GraphQLTypes["CustomerIngredientRatingWithoutCustomerUpdateInput"] | undefined
};
	["CustomerIngredientRatingWithoutCustomerUpdateInput"]: {
		rating?: GraphQLTypes["CustomerIngredientRatingUpdateRatingEntityRelationInput"] | undefined,
	ingredient?: GraphQLTypes["CustomerIngredientRatingUpdateIngredientEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerIngredientRatingUpdateRatingEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientRatingUniqueWhere"] | undefined
};
	["CustomerIngredientRatingUpdateIngredientEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductIngredientUniqueWhere"] | undefined
};
	["CustomerUpsertIngredientRatingsRelationInput"]: {
		by?: GraphQLTypes["CustomerIngredientRatingUniqueWhere"] | undefined,
	update?: GraphQLTypes["CustomerIngredientRatingWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["CustomerIngredientRatingWithoutCustomerCreateInput"] | undefined
};
	["CustomerUpdateBillingSubjectsEntityRelationInput"]: {
		create?: GraphQLTypes["BillingSubjectWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["CustomerUpdateBillingSubjectsRelationInput"] | undefined,
	upsert?: GraphQLTypes["CustomerUpsertBillingSubjectsRelationInput"] | undefined,
	connect?: GraphQLTypes["BillingSubjectUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["BillingSubjectUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["BillingSubjectWithoutCustomerCreateInput"]: {
		name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["BillingSubjectCreateCountryEntityRelationInput"] | undefined,
	orders?: Array<GraphQLTypes["BillingSubjectCreateOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerUpdateBillingSubjectsRelationInput"]: {
		by?: GraphQLTypes["BillingSubjectUniqueWhere"] | undefined,
	data?: GraphQLTypes["BillingSubjectWithoutCustomerUpdateInput"] | undefined
};
	["BillingSubjectWithoutCustomerUpdateInput"]: {
		name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["BillingSubjectUpdateCountryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["BillingSubjectUpdateCountryEntityRelationInput"]: {
		connect?: GraphQLTypes["CountryUniqueWhere"] | undefined
};
	["CustomerUpsertBillingSubjectsRelationInput"]: {
		by?: GraphQLTypes["BillingSubjectUniqueWhere"] | undefined,
	update?: GraphQLTypes["BillingSubjectWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["BillingSubjectWithoutCustomerCreateInput"] | undefined
};
	["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"]: {
		create?: GraphQLTypes["AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["CustomerUpsertDefaultDeliveryAddressRelationInput"] | undefined,
	connect?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["AddressCreateCustomerEntityRelationInput"] | undefined,
	country?: GraphQLTypes["AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["AddressUpdateCustomerEntityRelationInput"] | undefined,
	country?: GraphQLTypes["AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["AddressUpdateDeliveryZoneEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["AddressUpdateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["AddressUpdateCountryEntityRelationInput"]: {
		connect?: GraphQLTypes["CountryUniqueWhere"] | undefined
};
	["AddressUpdateDeliveryZoneEntityRelationInput"]: {
		connect?: GraphQLTypes["DeliveryZoneUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["AddressUpdateGpsEntityRelationInput"]: {
		create?: GraphQLTypes["GpsWithoutAddressCreateInput"] | undefined,
	update?: GraphQLTypes["GpsWithoutAddressUpdateInput"] | undefined,
	upsert?: GraphQLTypes["AddressUpsertGpsRelationInput"] | undefined,
	connect?: GraphQLTypes["GpsUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["GpsWithoutAddressUpdateInput"]: {
		latitude?: number | undefined,
	longitude?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["AddressUpsertGpsRelationInput"]: {
		update?: GraphQLTypes["GpsWithoutAddressUpdateInput"] | undefined,
	create?: GraphQLTypes["GpsWithoutAddressCreateInput"] | undefined
};
	["CustomerUpsertDefaultDeliveryAddressRelationInput"]: {
		update?: GraphQLTypes["AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"] | undefined
};
	["CustomerUpdateAnonymousSessionEntityRelationInput"]: {
		create?: GraphQLTypes["AnonymousSessionWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["AnonymousSessionWithoutCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["CustomerUpsertAnonymousSessionRelationInput"] | undefined,
	connect?: GraphQLTypes["AnonymousSessionUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["AnonymousSessionWithoutCustomerCreateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey?: string | undefined,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["AnonymousSessionWithoutCustomerUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey?: string | undefined,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerUpsertAnonymousSessionRelationInput"]: {
		update?: GraphQLTypes["AnonymousSessionWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["AnonymousSessionWithoutCustomerCreateInput"] | undefined
};
	["AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"]: {
		update?: GraphQLTypes["CustomerWithoutDefaultDeliveryAddressUpdateInput"] | undefined,
	connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["CustomerWithoutDefaultDeliveryAddressUpdateInput"]: {
		addresses?: Array<GraphQLTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	name?: GraphQLTypes["CustomerUpdateNameEntityRelationInput"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	billingSubjects?: Array<GraphQLTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	anonymousSession?: GraphQLTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerUpdateAddressesEntityRelationInput"]: {
		create?: GraphQLTypes["AddressWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["CustomerUpdateAddressesRelationInput"] | undefined,
	upsert?: GraphQLTypes["CustomerUpsertAddressesRelationInput"] | undefined,
	connect?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	delete?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["AddressWithoutCustomerCreateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	country?: GraphQLTypes["AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerUpdateAddressesRelationInput"]: {
		by?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	data?: GraphQLTypes["AddressWithoutCustomerUpdateInput"] | undefined
};
	["AddressWithoutCustomerUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	country?: GraphQLTypes["AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["AddressUpdateDeliveryZoneEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["AddressUpdateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerUpsertAddressesRelationInput"]: {
		by?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	update?: GraphQLTypes["AddressWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["AddressWithoutCustomerCreateInput"] | undefined
};
	["CountryConnection"]: {
	__typename: "CountryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CountryEdge"]>
};
	["CountryEdge"]: {
	__typename: "CountryEdge",
	node: GraphQLTypes["Country"]
};
	["CustomerUpdateInput"]: {
		addresses?: Array<GraphQLTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	name?: GraphQLTypes["CustomerUpdateNameEntityRelationInput"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	billingSubjects?: Array<GraphQLTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	anonymousSession?: GraphQLTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerGroupConnection"]: {
	__typename: "CustomerGroupConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerGroupEdge"]>
};
	["CustomerGroupEdge"]: {
	__typename: "CustomerGroupEdge",
	node: GraphQLTypes["CustomerGroup"]
};
	["OrderCreateInput"]: {
		state?: GraphQLTypes["OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["OrderCreateChannelEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["OrderCreateItemsEntityRelationInput"]> | undefined,
	cart?: GraphQLTypes["OrderCreateCartEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["OrderCreateBillingAddressEntityRelationInput"] | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	fulfillmentNote?: string | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["OrderDeliveryCreateInput"]: {
		priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["OrderDeliveryCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["OrderDeliveryCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["OrderDeliveryCreateOrderEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["OrderDeliveryCreateAddressEntityRelationInput"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["OrderItemCreateInput"]: {
		unitPriceCents?: number | undefined,
	quantity?: number | undefined,
	vatRate?: GraphQLTypes["OrderItemCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["OrderItemCreateOrderEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	product?: GraphQLTypes["OrderItemCreateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["OrderItemCreateOrderEntityRelationInput"]: {
		connect?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["OrderWithoutItemsCreateInput"] | undefined
};
	["OrderWithoutItemsCreateInput"]: {
		state?: GraphQLTypes["OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["OrderCreateChannelEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["OrderCreateDeliveryEntityRelationInput"] | undefined,
	cart?: GraphQLTypes["OrderCreateCartEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["OrderCreateBillingAddressEntityRelationInput"] | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	fulfillmentNote?: string | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["PaymentMethodUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	channels?: GraphQLTypes["ChannelPaymentUniqueWhere"] | undefined
};
	["VatRateConnection"]: {
	__typename: "VatRateConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VatRateEdge"]>
};
	["VatRateEdge"]: {
	__typename: "VatRateEdge",
	node: GraphQLTypes["VatRate"]
};
	["ProductPackingConnection"]: {
	__typename: "ProductPackingConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductPackingEdge"]>
};
	["ProductPackingEdge"]: {
	__typename: "ProductPackingEdge",
	node: GraphQLTypes["ProductPacking"]
};
	["ProductRecipeConnection"]: {
	__typename: "ProductRecipeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductRecipeEdge"]>
};
	["ProductRecipeEdge"]: {
	__typename: "ProductRecipeEdge",
	node: GraphQLTypes["ProductRecipe"]
};
	["StoreConnection"]: {
	__typename: "StoreConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StoreEdge"]>
};
	["StoreEdge"]: {
	__typename: "StoreEdge",
	node: GraphQLTypes["Store"]
};
	["TagUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["CustomerPermissionsConnection"]: {
	__typename: "CustomerPermissionsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerPermissionsEdge"]>
};
	["CustomerPermissionsEdge"]: {
	__typename: "CustomerPermissionsEdge",
	node: GraphQLTypes["CustomerPermissions"]
};
	["ImageConnection"]: {
	__typename: "ImageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageEdge"]>
};
	["ImageEdge"]: {
	__typename: "ImageEdge",
	node: GraphQLTypes["Image"]
};
	["ProductListConnection"]: {
	__typename: "ProductListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductListEdge"]>
};
	["ProductListEdge"]: {
	__typename: "ProductListEdge",
	node: GraphQLTypes["ProductList"]
};
	["ProductCategoryListConnection"]: {
	__typename: "ProductCategoryListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListEdge"]>
};
	["ProductCategoryListEdge"]: {
	__typename: "ProductCategoryListEdge",
	node: GraphQLTypes["ProductCategoryList"]
};
	["LocaleConnection"]: {
	__typename: "LocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
	__typename: "LocaleEdge",
	node: GraphQLTypes["Locale"]
};
	["CustomerNameConnection"]: {
	__typename: "CustomerNameConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerNameEdge"]>
};
	["CustomerNameEdge"]: {
	__typename: "CustomerNameEdge",
	node: GraphQLTypes["CustomerName"]
};
	["CustomerNameCreateInput"]: {
		nominative?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerNameUpdateInput"]: {
		nominative?: string | undefined,
	customer?: GraphQLTypes["CustomerNameUpdateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerNameUpdateCustomerEntityRelationInput"]: {
		update?: GraphQLTypes["CustomerWithoutNameUpdateInput"] | undefined,
	connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["CustomerWithoutNameUpdateInput"]: {
		addresses?: Array<GraphQLTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	billingSubjects?: Array<GraphQLTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	anonymousSession?: GraphQLTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerIngredientRatingCreateInput"]: {
		customer?: GraphQLTypes["CustomerIngredientRatingCreateCustomerEntityRelationInput"] | undefined,
	rating?: GraphQLTypes["CustomerIngredientRatingCreateRatingEntityRelationInput"] | undefined,
	ingredient?: GraphQLTypes["CustomerIngredientRatingCreateIngredientEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerIngredientRatingCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerIngredientRatingUpdateInput"]: {
		customer?: GraphQLTypes["CustomerIngredientRatingUpdateCustomerEntityRelationInput"] | undefined,
	rating?: GraphQLTypes["CustomerIngredientRatingUpdateRatingEntityRelationInput"] | undefined,
	ingredient?: GraphQLTypes["CustomerIngredientRatingUpdateIngredientEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CustomerIngredientRatingUpdateCustomerEntityRelationInput"]: {
		update?: GraphQLTypes["CustomerWithoutIngredientRatingsUpdateInput"] | undefined,
	connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["CustomerWithoutIngredientRatingsUpdateInput"]: {
		addresses?: Array<GraphQLTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	name?: GraphQLTypes["CustomerUpdateNameEntityRelationInput"] | undefined,
	billingSubjects?: Array<GraphQLTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	anonymousSession?: GraphQLTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["ProductIngredientRatingConnection"]: {
	__typename: "ProductIngredientRatingConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductIngredientRatingEdge"]>
};
	["ProductIngredientRatingEdge"]: {
	__typename: "ProductIngredientRatingEdge",
	node: GraphQLTypes["ProductIngredientRating"]
};
	["StaffConnection"]: {
	__typename: "StaffConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StaffEdge"]>
};
	["StaffEdge"]: {
	__typename: "StaffEdge",
	node: GraphQLTypes["Staff"]
};
	["BlogConnection"]: {
	__typename: "BlogConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogEdge"]>
};
	["BlogEdge"]: {
	__typename: "BlogEdge",
	node: GraphQLTypes["Blog"]
};
	["BlogPostConnection"]: {
	__typename: "BlogPostConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostEdge"]>
};
	["BlogPostEdge"]: {
	__typename: "BlogPostEdge",
	node: GraphQLTypes["BlogPost"]
};
	["ColorPalleteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ColorPalleteConnection"]: {
	__typename: "ColorPalleteConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ColorPalleteEdge"]>
};
	["ColorPalleteEdge"]: {
	__typename: "ColorPalleteEdge",
	node: GraphQLTypes["ColorPallete"]
};
	["ContentConnection"]: {
	__typename: "ContentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentEdge"]>
};
	["ContentEdge"]: {
	__typename: "ContentEdge",
	node: GraphQLTypes["Content"]
};
	["BlogPostListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["BlogPostListItemUniqueWhere"] | undefined
};
	["BlogPostListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["BlogPostListConnection"]: {
	__typename: "BlogPostListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["BlogPostListEdge"]>
};
	["BlogPostListEdge"]: {
	__typename: "BlogPostListEdge",
	node: GraphQLTypes["BlogPostList"]
};
	["LinkListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["LinkListItemUniqueWhere"] | undefined
};
	["LinkListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["LinkListConnection"]: {
	__typename: "LinkListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListEdge"]>
};
	["LinkListEdge"]: {
	__typename: "LinkListEdge",
	node: GraphQLTypes["LinkList"]
};
	["CoverConnection"]: {
	__typename: "CoverConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CoverEdge"]>
};
	["CoverEdge"]: {
	__typename: "CoverEdge",
	node: GraphQLTypes["Cover"]
};
	["LinkConnection"]: {
	__typename: "LinkConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkEdge"]>
};
	["LinkEdge"]: {
	__typename: "LinkEdge",
	node: GraphQLTypes["Link"]
};
	["LinkableConnection"]: {
	__typename: "LinkableConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
	__typename: "LinkableEdge",
	node: GraphQLTypes["Linkable"]
};
	["MediumUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["MediumConnection"]: {
	__typename: "MediumConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["MediumEdge"]>
};
	["MediumEdge"]: {
	__typename: "MediumEdge",
	node: GraphQLTypes["Medium"]
};
	["VideoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["VideoConnection"]: {
	__typename: "VideoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VideoEdge"]>
};
	["VideoEdge"]: {
	__typename: "VideoEdge",
	node: GraphQLTypes["Video"]
};
	["SeoConnection"]: {
	__typename: "SeoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoEdge"]>
};
	["SeoEdge"]: {
	__typename: "SeoEdge",
	node: GraphQLTypes["Seo"]
};
	["TranslationDomainConnection"]: {
	__typename: "TranslationDomainConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationDomainEdge"]>
};
	["TranslationDomainEdge"]: {
	__typename: "TranslationDomainEdge",
	node: GraphQLTypes["TranslationDomain"]
};
	["TranslationCataloguesIdentifierConnection"]: {
	__typename: "TranslationCataloguesIdentifierConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCataloguesIdentifierEdge"]>
};
	["TranslationCataloguesIdentifierEdge"]: {
	__typename: "TranslationCataloguesIdentifierEdge",
	node: GraphQLTypes["TranslationCataloguesIdentifier"]
};
	["SocialsAndAppsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["SocialsAndAppsConnection"]: {
	__typename: "SocialsAndAppsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SocialsAndAppsEdge"]>
};
	["SocialsAndAppsEdge"]: {
	__typename: "SocialsAndAppsEdge",
	node: GraphQLTypes["SocialsAndApps"]
};
	["ProductGridUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	categories?: GraphQLTypes["ProductGridCategoryUniqueWhere"] | undefined
};
	["ProductGridCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductGridItemUniqueWhere"] | undefined,
	images?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["ProductGridConnection"]: {
	__typename: "ProductGridConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductGridEdge"]>
};
	["ProductGridEdge"]: {
	__typename: "ProductGridEdge",
	node: GraphQLTypes["ProductGrid"]
};
	["GalleryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["GalleryItemUniqueWhere"] | undefined
};
	["GalleryItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["GalleryConnection"]: {
	__typename: "GalleryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GalleryEdge"]>
};
	["GalleryEdge"]: {
	__typename: "GalleryEdge",
	node: GraphQLTypes["Gallery"]
};
	["HeroUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["HeroConnection"]: {
	__typename: "HeroConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeroEdge"]>
};
	["HeroEdge"]: {
	__typename: "HeroEdge",
	node: GraphQLTypes["Hero"]
};
	["PageConnection"]: {
	__typename: "PageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PageEdge"]>
};
	["PageEdge"]: {
	__typename: "PageEdge",
	node: GraphQLTypes["Page"]
};
	["CartCreateInput"]: {
		confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items?: Array<GraphQLTypes["CartCreateItemsEntityRelationInput"]> | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	channel?: GraphQLTypes["CartCreateChannelEntityRelationInput"] | undefined,
	order?: GraphQLTypes["CartCreateOrderEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["CartCreateCustomerEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["CartCreateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["CartCreateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["CartCreateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["CartCreatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["CartCreateBillingSubjectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CartCreateOrderEntityRelationInput"]: {
		connect?: GraphQLTypes["OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["OrderWithoutCartCreateInput"] | undefined
};
	["OrderWithoutCartCreateInput"]: {
		state?: GraphQLTypes["OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["OrderCreateChannelEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["OrderCreateItemsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["OrderCreateBillingAddressEntityRelationInput"] | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	fulfillmentNote?: string | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["CartItemCreateInput"]: {
		quantity?: number | undefined,
	cart?: GraphQLTypes["CartItemCreateCartEntityRelationInput"] | undefined,
	product?: GraphQLTypes["CartItemCreateProductEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	fulfillmentNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["CartItemCreateCartEntityRelationInput"]: {
		connect?: GraphQLTypes["CartUniqueWhere"] | undefined,
	create?: GraphQLTypes["CartWithoutItemsCreateInput"] | undefined
};
	["CartWithoutItemsCreateInput"]: {
		confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	channel?: GraphQLTypes["CartCreateChannelEntityRelationInput"] | undefined,
	order?: GraphQLTypes["CartCreateOrderEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["CartCreateCustomerEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["CartCreateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["CartCreateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["CartCreateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["CartCreatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["CartCreateBillingSubjectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["CartItemUpdateInput"]: {
		quantity?: number | undefined,
	cart?: GraphQLTypes["CartItemUpdateCartEntityRelationInput"] | undefined,
	product?: GraphQLTypes["CartItemUpdateProductEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	fulfillmentNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["CartItemUpdateCartEntityRelationInput"]: {
		create?: GraphQLTypes["CartWithoutItemsCreateInput"] | undefined,
	connect?: GraphQLTypes["CartUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["CartItemUpdateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["ProductBannerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	button?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["ProductBannerConnection"]: {
	__typename: "ProductBannerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerEdge"]>
};
	["ProductBannerEdge"]: {
	__typename: "ProductBannerEdge",
	node: GraphQLTypes["ProductBanner"]
};
	["ProductBannerListConnection"]: {
	__typename: "ProductBannerListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductBannerListEdge"]>
};
	["ProductBannerListEdge"]: {
	__typename: "ProductBannerListEdge",
	node: GraphQLTypes["ProductBannerList"]
};
	["UserConnection"]: {
	__typename: "UserConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["UserEdge"]>
};
	["UserEdge"]: {
	__typename: "UserEdge",
	node: GraphQLTypes["User"]
};
	["GpsConnection"]: {
	__typename: "GpsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GpsEdge"]>
};
	["GpsEdge"]: {
	__typename: "GpsEdge",
	node: GraphQLTypes["Gps"]
};
	["GpsCreateInput"]: {
		latitude?: number | undefined,
	longitude?: number | undefined,
	address?: GraphQLTypes["GpsCreateAddressEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["GpsCreateAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["AddressWithoutGpsCreateInput"] | undefined
};
	["AddressWithoutGpsCreateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["AddressCreateCustomerEntityRelationInput"] | undefined,
	country?: GraphQLTypes["AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["GpsUpdateInput"]: {
		latitude?: number | undefined,
	longitude?: number | undefined,
	address?: GraphQLTypes["GpsUpdateAddressEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["GpsUpdateAddressEntityRelationInput"]: {
		create?: GraphQLTypes["AddressWithoutGpsCreateInput"] | undefined,
	update?: GraphQLTypes["AddressWithoutGpsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["GpsUpsertAddressRelationInput"] | undefined,
	connect?: GraphQLTypes["AddressUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["AddressWithoutGpsUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["AddressUpdateCustomerEntityRelationInput"] | undefined,
	country?: GraphQLTypes["AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["AddressUpdateDeliveryZoneEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["GpsUpsertAddressRelationInput"]: {
		update?: GraphQLTypes["AddressWithoutGpsUpdateInput"] | undefined,
	create?: GraphQLTypes["AddressWithoutGpsCreateInput"] | undefined
};
	["WeekHoursUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["WeekHoursConnection"]: {
	__typename: "WeekHoursConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WeekHoursEdge"]>
};
	["WeekHoursEdge"]: {
	__typename: "WeekHoursEdge",
	node: GraphQLTypes["WeekHours"]
};
	["DeliveryTimelinePresetConnection"]: {
	__typename: "DeliveryTimelinePresetConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryTimelinePresetEdge"]>
};
	["DeliveryTimelinePresetEdge"]: {
	__typename: "DeliveryTimelinePresetEdge",
	node: GraphQLTypes["DeliveryTimelinePreset"]
};
	["InvoicingPlanConnection"]: {
	__typename: "InvoicingPlanConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["InvoicingPlanEdge"]>
};
	["InvoicingPlanEdge"]: {
	__typename: "InvoicingPlanEdge",
	node: GraphQLTypes["InvoicingPlan"]
};
	["InvoicingPresetConnection"]: {
	__typename: "InvoicingPresetConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["InvoicingPresetEdge"]>
};
	["InvoicingPresetEdge"]: {
	__typename: "InvoicingPresetEdge",
	node: GraphQLTypes["InvoicingPreset"]
};
	["BillingSubjectUpdateInput"]: {
		name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["BillingSubjectUpdateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["BillingSubjectUpdateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["BillingSubjectUpdateCustomerEntityRelationInput"]: {
		update?: GraphQLTypes["CustomerWithoutBillingSubjectsUpdateInput"] | undefined,
	connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["CustomerWithoutBillingSubjectsUpdateInput"]: {
		addresses?: Array<GraphQLTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	name?: GraphQLTypes["CustomerUpdateNameEntityRelationInput"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	anonymousSession?: GraphQLTypes["CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["VirtualProductConnection"]: {
	__typename: "VirtualProductConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VirtualProductEdge"]>
};
	["VirtualProductEdge"]: {
	__typename: "VirtualProductEdge",
	node: GraphQLTypes["VirtualProduct"]
};
	["ProductMetadataConnection"]: {
	__typename: "ProductMetadataConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductMetadataEdge"]>
};
	["ProductMetadataEdge"]: {
	__typename: "ProductMetadataEdge",
	node: GraphQLTypes["ProductMetadata"]
};
	["AnonymousSessionConnection"]: {
	__typename: "AnonymousSessionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AnonymousSessionEdge"]>
};
	["AnonymousSessionEdge"]: {
	__typename: "AnonymousSessionEdge",
	node: GraphQLTypes["AnonymousSession"]
};
	["AnonymousSessionCreateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey?: string | undefined,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	customer?: GraphQLTypes["AnonymousSessionCreateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["AnonymousSessionCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined
};
	["AnonymousSessionUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey?: string | undefined,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	customer?: GraphQLTypes["AnonymousSessionUpdateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["AnonymousSessionUpdateCustomerEntityRelationInput"]: {
		update?: GraphQLTypes["CustomerWithoutAnonymousSessionUpdateInput"] | undefined,
	connect?: GraphQLTypes["CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined
};
	["CustomerWithoutAnonymousSessionUpdateInput"]: {
		addresses?: Array<GraphQLTypes["CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	name?: GraphQLTypes["CustomerUpdateNameEntityRelationInput"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	billingSubjects?: Array<GraphQLTypes["CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["FreshingContainerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["TextListConnection"]: {
	__typename: "TextListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TextListEdge"]>
};
	["TextListEdge"]: {
	__typename: "TextListEdge",
	node: GraphQLTypes["TextList"]
};
	["DeliveryRegionsConnection"]: {
	__typename: "DeliveryRegionsConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["DeliveryRegionsEdge"]>
};
	["DeliveryRegionsEdge"]: {
	__typename: "DeliveryRegionsEdge",
	node: GraphQLTypes["DeliveryRegions"]
};
	["ImageListConnection"]: {
	__typename: "ImageListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
	__typename: "ImageListEdge",
	node: GraphQLTypes["ImageList"]
};
	["RedirectConnection"]: {
	__typename: "RedirectConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
	__typename: "RedirectEdge",
	node: GraphQLTypes["Redirect"]
};
	["WorkspaceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	freshingContainers?: GraphQLTypes["FreshingContainerUniqueWhere"] | undefined,
	openingHours?: GraphQLTypes["WeekHoursUniqueWhere"] | undefined,
	workingHours?: GraphQLTypes["WeekHoursUniqueWhere"] | undefined,
	creditsProduct?: GraphQLTypes["VirtualProductUniqueWhere"] | undefined,
	discountVatRate?: GraphQLTypes["VatRateUniqueWhere"] | undefined
};
	["WorkspaceConnection"]: {
	__typename: "WorkspaceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["WorkspaceEdge"]>
};
	["WorkspaceEdge"]: {
	__typename: "WorkspaceEdge",
	node: GraphQLTypes["Workspace"]
};
	["CartSummaryConnection"]: {
	__typename: "CartSummaryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CartSummaryEdge"]>
};
	["CartSummaryEdge"]: {
	__typename: "CartSummaryEdge",
	node: GraphQLTypes["CartSummary"]
};
	["OrderSummaryConnection"]: {
	__typename: "OrderSummaryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderSummaryEdge"]>
};
	["OrderSummaryEdge"]: {
	__typename: "OrderSummaryEdge",
	node: GraphQLTypes["OrderSummary"]
};
	["OrderPaymentMetadataConnection"]: {
	__typename: "OrderPaymentMetadataConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPaymentMetadataEdge"]>
};
	["OrderPaymentMetadataEdge"]: {
	__typename: "OrderPaymentMetadataEdge",
	node: GraphQLTypes["OrderPaymentMetadata"]
};
	["UserMetadataConnection"]: {
	__typename: "UserMetadataConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["UserMetadataEdge"]>
};
	["UserMetadataEdge"]: {
	__typename: "UserMetadataEdge",
	node: GraphQLTypes["UserMetadata"]
};
	["IdentificationAttemptCreateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	code?: GraphQLTypes["IdentificationAttemptCreateCodeEntityRelationInput"] | undefined,
	user?: GraphQLTypes["IdentificationAttemptCreateUserEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["IdentificationAttemptCreateCodeEntityRelationInput"]: {
		connect?: GraphQLTypes["IdentificationCodeUniqueWhere"] | undefined
};
	["IdentificationAttemptCreateUserEntityRelationInput"]: {
		connect?: GraphQLTypes["UserUniqueWhere"] | undefined
};
	["IdentificationCodeConnection"]: {
	__typename: "IdentificationCodeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["IdentificationCodeEdge"]>
};
	["IdentificationCodeEdge"]: {
	__typename: "IdentificationCodeEdge",
	node: GraphQLTypes["IdentificationCode"]
};
	["FulfillmentNoteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["CustomerMetadataConnection"]: {
	__typename: "CustomerMetadataConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["CustomerMetadataEdge"]>
};
	["CustomerMetadataEdge"]: {
	__typename: "CustomerMetadataEdge",
	node: GraphQLTypes["CustomerMetadata"]
};
	["SubscriptionBoxConnection"]: {
	__typename: "SubscriptionBoxConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SubscriptionBoxEdge"]>
};
	["SubscriptionBoxEdge"]: {
	__typename: "SubscriptionBoxEdge",
	node: GraphQLTypes["SubscriptionBox"]
};
	["GridTileItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["GridTilesUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["GridTileItemUniqueWhere"] | undefined
};
	["GridTilesConnection"]: {
	__typename: "GridTilesConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GridTilesEdge"]>
};
	["GridTilesEdge"]: {
	__typename: "GridTilesEdge",
	node: GraphQLTypes["GridTiles"]
};
	["Position"]: {
	__typename: "Position",
	_meta?: GraphQLTypes["PositionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	x: number,
	y: number
};
	["PositionMeta"]: {
	__typename: "PositionMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	x?: GraphQLTypes["FieldMeta"] | undefined,
	y?: GraphQLTypes["FieldMeta"] | undefined
};
	["PositionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["PositionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	x?: GraphQLTypes["IntCondition"] | undefined,
	y?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["PositionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["PositionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["PositionWhere"] | undefined
};
	["PositionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	x?: GraphQLTypes["OrderDirection"] | undefined,
	y?: GraphQLTypes["OrderDirection"] | undefined
};
	["PositionConnection"]: {
	__typename: "PositionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["PositionEdge"]>
};
	["PositionEdge"]: {
	__typename: "PositionEdge",
	node: GraphQLTypes["Position"]
};
	["StatsWidget"]: {
	__typename: "StatsWidget",
	_meta?: GraphQLTypes["StatsWidgetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	size: number,
	type: GraphQLTypes["StatsEnabledWidgetTypeType"],
	link?: string | undefined,
	title?: string | undefined,
	timeFrame: GraphQLTypes["StatsWidgetTimeFrame"],
	layout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	channels: Array<GraphQLTypes["Channel"]>,
	productCategory?: GraphQLTypes["ProductCategory"] | undefined,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	hoursFrom?: string | undefined,
	hoursTo?: string | undefined,
	paginateChannels: GraphQLTypes["ChannelConnection"],
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"]
};
	["StatsWidgetMeta"]: {
	__typename: "StatsWidgetMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	timeFrame?: GraphQLTypes["FieldMeta"] | undefined,
	layout?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	channels?: GraphQLTypes["FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["FieldMeta"] | undefined,
	hoursFrom?: GraphQLTypes["FieldMeta"] | undefined,
	hoursTo?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsEnabledWidgetTypeType"]: StatsEnabledWidgetTypeType;
	["StatsWidgetTimeFrame"]: StatsWidgetTimeFrame;
	["StatsWidgetsLayout"]: {
	__typename: "StatsWidgetsLayout",
	_meta?: GraphQLTypes["StatsWidgetsLayoutMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["StatsWidget"]>,
	paginateItems: GraphQLTypes["StatsWidgetConnection"]
};
	["StatsWidgetsLayoutMeta"]: {
	__typename: "StatsWidgetsLayoutMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsWidgetWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StatsEnabledWidgetTypeTypeCondition"] | undefined,
	link?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	timeFrame?: GraphQLTypes["StatsWidgetTimeFrameCondition"] | undefined,
	layout?: GraphQLTypes["StatsWidgetsLayoutWhere"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	channels?: GraphQLTypes["ChannelWhere"] | undefined,
	productCategory?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	paymentMethods?: GraphQLTypes["PaymentMethodWhere"] | undefined,
	hoursFrom?: GraphQLTypes["StringCondition"] | undefined,
	hoursTo?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["StatsWidgetWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StatsWidgetWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StatsWidgetWhere"] | undefined
};
	["StatsEnabledWidgetTypeTypeCondition"]: {
		and?: Array<GraphQLTypes["StatsEnabledWidgetTypeTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["StatsEnabledWidgetTypeTypeCondition"]> | undefined,
	not?: GraphQLTypes["StatsEnabledWidgetTypeTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined,
	notEq?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined,
	in?: Array<GraphQLTypes["StatsEnabledWidgetTypeType"]> | undefined,
	notIn?: Array<GraphQLTypes["StatsEnabledWidgetTypeType"]> | undefined,
	lt?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined,
	lte?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined,
	gt?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined,
	gte?: GraphQLTypes["StatsEnabledWidgetTypeType"] | undefined
};
	["StatsWidgetTimeFrameCondition"]: {
		and?: Array<GraphQLTypes["StatsWidgetTimeFrameCondition"]> | undefined,
	or?: Array<GraphQLTypes["StatsWidgetTimeFrameCondition"]> | undefined,
	not?: GraphQLTypes["StatsWidgetTimeFrameCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined,
	notEq?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined,
	in?: Array<GraphQLTypes["StatsWidgetTimeFrame"]> | undefined,
	notIn?: Array<GraphQLTypes["StatsWidgetTimeFrame"]> | undefined,
	lt?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined,
	lte?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined,
	gt?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined,
	gte?: GraphQLTypes["StatsWidgetTimeFrame"] | undefined
};
	["StatsWidgetsLayoutWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["StatsWidgetWhere"] | undefined,
	and?: Array<GraphQLTypes["StatsWidgetsLayoutWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StatsWidgetsLayoutWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StatsWidgetsLayoutWhere"] | undefined
};
	["StatsWidgetOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	timeFrame?: GraphQLTypes["OrderDirection"] | undefined,
	layout?: GraphQLTypes["StatsWidgetsLayoutOrderBy"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined,
	productCategory?: GraphQLTypes["ProductCategoryOrderBy"] | undefined,
	hoursFrom?: GraphQLTypes["OrderDirection"] | undefined,
	hoursTo?: GraphQLTypes["OrderDirection"] | undefined
};
	["StatsWidgetsLayoutOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["StatsWidgetConnection"]: {
	__typename: "StatsWidgetConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsWidgetEdge"]>
};
	["StatsWidgetEdge"]: {
	__typename: "StatsWidgetEdge",
	node: GraphQLTypes["StatsWidget"]
};
	["StatsWidgetUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["StatsWidgetsLayoutUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["StatsWidgetUniqueWhere"] | undefined
};
	["StatsWidgetsLayoutConnection"]: {
	__typename: "StatsWidgetsLayoutConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsWidgetsLayoutEdge"]>
};
	["StatsWidgetsLayoutEdge"]: {
	__typename: "StatsWidgetsLayoutEdge",
	node: GraphQLTypes["StatsWidgetsLayout"]
};
	["StatsPage"]: {
	__typename: "StatsPage",
	_meta?: GraphQLTypes["StatsPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["StatsPageType"],
	enabled: boolean,
	widgets?: GraphQLTypes["StatsWidgetsLayout"] | undefined
};
	["StatsPageMeta"]: {
	__typename: "StatsPageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	enabled?: GraphQLTypes["FieldMeta"] | undefined,
	widgets?: GraphQLTypes["FieldMeta"] | undefined
};
	["StatsPageType"]: StatsPageType;
	["StatsPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: GraphQLTypes["StatsPageType"] | undefined,
	widgets?: GraphQLTypes["StatsWidgetsLayoutUniqueWhere"] | undefined
};
	["StatsPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["StatsPageTypeCondition"] | undefined,
	enabled?: GraphQLTypes["BooleanCondition"] | undefined,
	widgets?: GraphQLTypes["StatsWidgetsLayoutWhere"] | undefined,
	and?: Array<GraphQLTypes["StatsPageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["StatsPageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["StatsPageWhere"] | undefined
};
	["StatsPageTypeCondition"]: {
		and?: Array<GraphQLTypes["StatsPageTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["StatsPageTypeCondition"]> | undefined,
	not?: GraphQLTypes["StatsPageTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["StatsPageType"] | undefined,
	notEq?: GraphQLTypes["StatsPageType"] | undefined,
	in?: Array<GraphQLTypes["StatsPageType"]> | undefined,
	notIn?: Array<GraphQLTypes["StatsPageType"]> | undefined,
	lt?: GraphQLTypes["StatsPageType"] | undefined,
	lte?: GraphQLTypes["StatsPageType"] | undefined,
	gt?: GraphQLTypes["StatsPageType"] | undefined,
	gte?: GraphQLTypes["StatsPageType"] | undefined
};
	["StatsPageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	enabled?: GraphQLTypes["OrderDirection"] | undefined,
	widgets?: GraphQLTypes["StatsWidgetsLayoutOrderBy"] | undefined
};
	["StatsPageConnection"]: {
	__typename: "StatsPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["StatsPageEdge"]>
};
	["StatsPageEdge"]: {
	__typename: "StatsPageEdge",
	node: GraphQLTypes["StatsPage"]
};
	["QueryTransaction"]: {
	__typename: "QueryTransaction",
	getAddress?: GraphQLTypes["Address"] | undefined,
	listAddress: Array<GraphQLTypes["Address"]>,
	paginateAddress: GraphQLTypes["AddressConnection"],
	validateCreateAddress: GraphQLTypes["_ValidationResult"],
	validateUpdateAddress: GraphQLTypes["_ValidationResult"],
	getCountry?: GraphQLTypes["Country"] | undefined,
	listCountry: Array<GraphQLTypes["Country"]>,
	paginateCountry: GraphQLTypes["CountryConnection"],
	getChannel?: GraphQLTypes["Channel"] | undefined,
	listChannel: Array<GraphQLTypes["Channel"]>,
	paginateChannel: GraphQLTypes["ChannelConnection"],
	getChannelPayment?: GraphQLTypes["ChannelPayment"] | undefined,
	listChannelPayment: Array<GraphQLTypes["ChannelPayment"]>,
	paginateChannelPayment: GraphQLTypes["ChannelPaymentConnection"],
	getChannelDelivery?: GraphQLTypes["ChannelDelivery"] | undefined,
	listChannelDelivery: Array<GraphQLTypes["ChannelDelivery"]>,
	paginateChannelDelivery: GraphQLTypes["ChannelDeliveryConnection"],
	getCustomer?: GraphQLTypes["Customer"] | undefined,
	listCustomer: Array<GraphQLTypes["Customer"]>,
	paginateCustomer: GraphQLTypes["CustomerConnection"],
	validateUpdateCustomer: GraphQLTypes["_ValidationResult"],
	getCustomerCredit?: GraphQLTypes["CustomerCredit"] | undefined,
	listCustomerCredit: Array<GraphQLTypes["CustomerCredit"]>,
	paginateCustomerCredit: GraphQLTypes["CustomerCreditConnection"],
	getCustomerGroup?: GraphQLTypes["CustomerGroup"] | undefined,
	listCustomerGroup: Array<GraphQLTypes["CustomerGroup"]>,
	paginateCustomerGroup: GraphQLTypes["CustomerGroupConnection"],
	getDeliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined,
	listDeliveryMethod: Array<GraphQLTypes["DeliveryMethod"]>,
	paginateDeliveryMethod: GraphQLTypes["DeliveryMethodConnection"],
	getOrder?: GraphQLTypes["Order"] | undefined,
	listOrder: Array<GraphQLTypes["Order"]>,
	paginateOrder: GraphQLTypes["OrderConnection"],
	validateCreateOrder: GraphQLTypes["_ValidationResult"],
	getOrderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	listOrderDiscount: Array<GraphQLTypes["OrderDiscount"]>,
	paginateOrderDiscount: GraphQLTypes["OrderDiscountConnection"],
	getOrderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	listOrderPayment: Array<GraphQLTypes["OrderPayment"]>,
	paginateOrderPayment: GraphQLTypes["OrderPaymentConnection"],
	getOrderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	listOrderDelivery: Array<GraphQLTypes["OrderDelivery"]>,
	paginateOrderDelivery: GraphQLTypes["OrderDeliveryConnection"],
	validateCreateOrderDelivery: GraphQLTypes["_ValidationResult"],
	getOrderItem?: GraphQLTypes["OrderItem"] | undefined,
	listOrderItem: Array<GraphQLTypes["OrderItem"]>,
	paginateOrderItem: GraphQLTypes["OrderItemConnection"],
	validateCreateOrderItem: GraphQLTypes["_ValidationResult"],
	getPaymentMethod?: GraphQLTypes["PaymentMethod"] | undefined,
	listPaymentMethod: Array<GraphQLTypes["PaymentMethod"]>,
	paginatePaymentMethod: GraphQLTypes["PaymentMethodConnection"],
	getVatRate?: GraphQLTypes["VatRate"] | undefined,
	listVatRate: Array<GraphQLTypes["VatRate"]>,
	paginateVatRate: GraphQLTypes["VatRateConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductStock?: GraphQLTypes["ProductStock"] | undefined,
	listProductStock: Array<GraphQLTypes["ProductStock"]>,
	paginateProductStock: GraphQLTypes["ProductStockConnection"],
	getProductPacking?: GraphQLTypes["ProductPacking"] | undefined,
	listProductPacking: Array<GraphQLTypes["ProductPacking"]>,
	paginateProductPacking: GraphQLTypes["ProductPackingConnection"],
	getProductGroupPrice?: GraphQLTypes["ProductGroupPrice"] | undefined,
	listProductGroupPrice: Array<GraphQLTypes["ProductGroupPrice"]>,
	paginateProductGroupPrice: GraphQLTypes["ProductGroupPriceConnection"],
	getProductRecipe?: GraphQLTypes["ProductRecipe"] | undefined,
	listProductRecipe: Array<GraphQLTypes["ProductRecipe"]>,
	paginateProductRecipe: GraphQLTypes["ProductRecipeConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getStore?: GraphQLTypes["Store"] | undefined,
	listStore: Array<GraphQLTypes["Store"]>,
	paginateStore: GraphQLTypes["StoreConnection"],
	getTag?: GraphQLTypes["Tag"] | undefined,
	listTag: Array<GraphQLTypes["Tag"]>,
	paginateTag: GraphQLTypes["TagConnection"],
	getCustomerPermissions?: GraphQLTypes["CustomerPermissions"] | undefined,
	listCustomerPermissions: Array<GraphQLTypes["CustomerPermissions"]>,
	paginateCustomerPermissions: GraphQLTypes["CustomerPermissionsConnection"],
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getBusinessCategory?: GraphQLTypes["BusinessCategory"] | undefined,
	listBusinessCategory: Array<GraphQLTypes["BusinessCategory"]>,
	paginateBusinessCategory: GraphQLTypes["BusinessCategoryConnection"],
	getProductList?: GraphQLTypes["ProductList"] | undefined,
	listProductList: Array<GraphQLTypes["ProductList"]>,
	paginateProductList: GraphQLTypes["ProductListConnection"],
	getProductListItem?: GraphQLTypes["ProductListItem"] | undefined,
	listProductListItem: Array<GraphQLTypes["ProductListItem"]>,
	paginateProductListItem: GraphQLTypes["ProductListItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getProductCategoryListItem?: GraphQLTypes["ProductCategoryListItem"] | undefined,
	listProductCategoryListItem: Array<GraphQLTypes["ProductCategoryListItem"]>,
	paginateProductCategoryListItem: GraphQLTypes["ProductCategoryListItemConnection"],
	getBusinessCategoryLocale?: GraphQLTypes["BusinessCategoryLocale"] | undefined,
	listBusinessCategoryLocale: Array<GraphQLTypes["BusinessCategoryLocale"]>,
	paginateBusinessCategoryLocale: GraphQLTypes["BusinessCategoryLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getProductCategoryListLocale?: GraphQLTypes["ProductCategoryListLocale"] | undefined,
	listProductCategoryListLocale: Array<GraphQLTypes["ProductCategoryListLocale"]>,
	paginateProductCategoryListLocale: GraphQLTypes["ProductCategoryListLocaleConnection"],
	getCustomerName?: GraphQLTypes["CustomerName"] | undefined,
	listCustomerName: Array<GraphQLTypes["CustomerName"]>,
	paginateCustomerName: GraphQLTypes["CustomerNameConnection"],
	validateCreateCustomerName: GraphQLTypes["_ValidationResult"],
	validateUpdateCustomerName: GraphQLTypes["_ValidationResult"],
	getProductIngredientItem?: GraphQLTypes["ProductIngredientItem"] | undefined,
	listProductIngredientItem: Array<GraphQLTypes["ProductIngredientItem"]>,
	paginateProductIngredientItem: GraphQLTypes["ProductIngredientItemConnection"],
	getProductIngredient?: GraphQLTypes["ProductIngredient"] | undefined,
	listProductIngredient: Array<GraphQLTypes["ProductIngredient"]>,
	paginateProductIngredient: GraphQLTypes["ProductIngredientConnection"],
	getCustomerIngredientRating?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	listCustomerIngredientRating: Array<GraphQLTypes["CustomerIngredientRating"]>,
	paginateCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingConnection"],
	validateCreateCustomerIngredientRating: GraphQLTypes["_ValidationResult"],
	validateUpdateCustomerIngredientRating: GraphQLTypes["_ValidationResult"],
	getProductIngredientRating?: GraphQLTypes["ProductIngredientRating"] | undefined,
	listProductIngredientRating: Array<GraphQLTypes["ProductIngredientRating"]>,
	paginateProductIngredientRating: GraphQLTypes["ProductIngredientRatingConnection"],
	getProductIngredientLocale?: GraphQLTypes["ProductIngredientLocale"] | undefined,
	listProductIngredientLocale: Array<GraphQLTypes["ProductIngredientLocale"]>,
	paginateProductIngredientLocale: GraphQLTypes["ProductIngredientLocaleConnection"],
	getOrderRecurrence?: GraphQLTypes["OrderRecurrence"] | undefined,
	listOrderRecurrence: Array<GraphQLTypes["OrderRecurrence"]>,
	paginateOrderRecurrence: GraphQLTypes["OrderRecurrenceConnection"],
	getOrderRecurrenceLocale?: GraphQLTypes["OrderRecurrenceLocale"] | undefined,
	listOrderRecurrenceLocale: Array<GraphQLTypes["OrderRecurrenceLocale"]>,
	paginateOrderRecurrenceLocale: GraphQLTypes["OrderRecurrenceLocaleConnection"],
	getBusinessCustomer?: GraphQLTypes["BusinessCustomer"] | undefined,
	listBusinessCustomer: Array<GraphQLTypes["BusinessCustomer"]>,
	paginateBusinessCustomer: GraphQLTypes["BusinessCustomerConnection"],
	getStaff?: GraphQLTypes["Staff"] | undefined,
	listStaff: Array<GraphQLTypes["Staff"]>,
	paginateStaff: GraphQLTypes["StaffConnection"],
	getBlog?: GraphQLTypes["Blog"] | undefined,
	listBlog: Array<GraphQLTypes["Blog"]>,
	paginateBlog: GraphQLTypes["BlogConnection"],
	getBlogLocale?: GraphQLTypes["BlogLocale"] | undefined,
	listBlogLocale: Array<GraphQLTypes["BlogLocale"]>,
	paginateBlogLocale: GraphQLTypes["BlogLocaleConnection"],
	getBlogPost?: GraphQLTypes["BlogPost"] | undefined,
	listBlogPost: Array<GraphQLTypes["BlogPost"]>,
	paginateBlogPost: GraphQLTypes["BlogPostConnection"],
	getBlogPostLocale?: GraphQLTypes["BlogPostLocale"] | undefined,
	listBlogPostLocale: Array<GraphQLTypes["BlogPostLocale"]>,
	paginateBlogPostLocale: GraphQLTypes["BlogPostLocaleConnection"],
	getColorPallete?: GraphQLTypes["ColorPallete"] | undefined,
	listColorPallete: Array<GraphQLTypes["ColorPallete"]>,
	paginateColorPallete: GraphQLTypes["ColorPalleteConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getBlogPostList?: GraphQLTypes["BlogPostList"] | undefined,
	listBlogPostList: Array<GraphQLTypes["BlogPostList"]>,
	paginateBlogPostList: GraphQLTypes["BlogPostListConnection"],
	getBlogPostListItem?: GraphQLTypes["BlogPostListItem"] | undefined,
	listBlogPostListItem: Array<GraphQLTypes["BlogPostListItem"]>,
	paginateBlogPostListItem: GraphQLTypes["BlogPostListItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getLinkListItem?: GraphQLTypes["LinkListItem"] | undefined,
	listLinkListItem: Array<GraphQLTypes["LinkListItem"]>,
	paginateLinkListItem: GraphQLTypes["LinkListItemConnection"],
	getCover?: GraphQLTypes["Cover"] | undefined,
	listCover: Array<GraphQLTypes["Cover"]>,
	paginateCover: GraphQLTypes["CoverConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getMedium?: GraphQLTypes["Medium"] | undefined,
	listMedium: Array<GraphQLTypes["Medium"]>,
	paginateMedium: GraphQLTypes["MediumConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getSocialsAndApps?: GraphQLTypes["SocialsAndApps"] | undefined,
	listSocialsAndApps: Array<GraphQLTypes["SocialsAndApps"]>,
	paginateSocialsAndApps: GraphQLTypes["SocialsAndAppsConnection"],
	getProductGrid?: GraphQLTypes["ProductGrid"] | undefined,
	listProductGrid: Array<GraphQLTypes["ProductGrid"]>,
	paginateProductGrid: GraphQLTypes["ProductGridConnection"],
	getProductGridItem?: GraphQLTypes["ProductGridItem"] | undefined,
	listProductGridItem: Array<GraphQLTypes["ProductGridItem"]>,
	paginateProductGridItem: GraphQLTypes["ProductGridItemConnection"],
	getGallery?: GraphQLTypes["Gallery"] | undefined,
	listGallery: Array<GraphQLTypes["Gallery"]>,
	paginateGallery: GraphQLTypes["GalleryConnection"],
	getGalleryItem?: GraphQLTypes["GalleryItem"] | undefined,
	listGalleryItem: Array<GraphQLTypes["GalleryItem"]>,
	paginateGalleryItem: GraphQLTypes["GalleryItemConnection"],
	getHero?: GraphQLTypes["Hero"] | undefined,
	listHero: Array<GraphQLTypes["Hero"]>,
	paginateHero: GraphQLTypes["HeroConnection"],
	getPage?: GraphQLTypes["Page"] | undefined,
	listPage: Array<GraphQLTypes["Page"]>,
	paginatePage: GraphQLTypes["PageConnection"],
	getPageLocale?: GraphQLTypes["PageLocale"] | undefined,
	listPageLocale: Array<GraphQLTypes["PageLocale"]>,
	paginatePageLocale: GraphQLTypes["PageLocaleConnection"],
	getCart?: GraphQLTypes["Cart"] | undefined,
	listCart: Array<GraphQLTypes["Cart"]>,
	paginateCart: GraphQLTypes["CartConnection"],
	validateCreateCart: GraphQLTypes["_ValidationResult"],
	getCartItem?: GraphQLTypes["CartItem"] | undefined,
	listCartItem: Array<GraphQLTypes["CartItem"]>,
	paginateCartItem: GraphQLTypes["CartItemConnection"],
	validateCreateCartItem: GraphQLTypes["_ValidationResult"],
	validateUpdateCartItem: GraphQLTypes["_ValidationResult"],
	getProductBanner?: GraphQLTypes["ProductBanner"] | undefined,
	listProductBanner: Array<GraphQLTypes["ProductBanner"]>,
	paginateProductBanner: GraphQLTypes["ProductBannerConnection"],
	getProductBannerItem?: GraphQLTypes["ProductBannerItem"] | undefined,
	listProductBannerItem: Array<GraphQLTypes["ProductBannerItem"]>,
	paginateProductBannerItem: GraphQLTypes["ProductBannerItemConnection"],
	getProductBannerList?: GraphQLTypes["ProductBannerList"] | undefined,
	listProductBannerList: Array<GraphQLTypes["ProductBannerList"]>,
	paginateProductBannerList: GraphQLTypes["ProductBannerListConnection"],
	getUser?: GraphQLTypes["User"] | undefined,
	listUser: Array<GraphQLTypes["User"]>,
	paginateUser: GraphQLTypes["UserConnection"],
	getAllergen?: GraphQLTypes["Allergen"] | undefined,
	listAllergen: Array<GraphQLTypes["Allergen"]>,
	paginateAllergen: GraphQLTypes["AllergenConnection"],
	getAllergenLocale?: GraphQLTypes["AllergenLocale"] | undefined,
	listAllergenLocale: Array<GraphQLTypes["AllergenLocale"]>,
	paginateAllergenLocale: GraphQLTypes["AllergenLocaleConnection"],
	getGps?: GraphQLTypes["Gps"] | undefined,
	listGps: Array<GraphQLTypes["Gps"]>,
	paginateGps: GraphQLTypes["GpsConnection"],
	validateCreateGps: GraphQLTypes["_ValidationResult"],
	validateUpdateGps: GraphQLTypes["_ValidationResult"],
	getWeekHours?: GraphQLTypes["WeekHours"] | undefined,
	listWeekHours: Array<GraphQLTypes["WeekHours"]>,
	paginateWeekHours: GraphQLTypes["WeekHoursConnection"],
	getDeliveryZone?: GraphQLTypes["DeliveryZone"] | undefined,
	listDeliveryZone: Array<GraphQLTypes["DeliveryZone"]>,
	paginateDeliveryZone: GraphQLTypes["DeliveryZoneConnection"],
	getAddressMetadata?: GraphQLTypes["AddressMetadata"] | undefined,
	listAddressMetadata: Array<GraphQLTypes["AddressMetadata"]>,
	paginateAddressMetadata: GraphQLTypes["AddressMetadataConnection"],
	getDeliveryTimelinePreset?: GraphQLTypes["DeliveryTimelinePreset"] | undefined,
	listDeliveryTimelinePreset: Array<GraphQLTypes["DeliveryTimelinePreset"]>,
	paginateDeliveryTimelinePreset: GraphQLTypes["DeliveryTimelinePresetConnection"],
	getInvoice?: GraphQLTypes["Invoice"] | undefined,
	listInvoice: Array<GraphQLTypes["Invoice"]>,
	paginateInvoice: GraphQLTypes["InvoiceConnection"],
	getInvoicingPlan?: GraphQLTypes["InvoicingPlan"] | undefined,
	listInvoicingPlan: Array<GraphQLTypes["InvoicingPlan"]>,
	paginateInvoicingPlan: GraphQLTypes["InvoicingPlanConnection"],
	getProductPackingLocale?: GraphQLTypes["ProductPackingLocale"] | undefined,
	listProductPackingLocale: Array<GraphQLTypes["ProductPackingLocale"]>,
	paginateProductPackingLocale: GraphQLTypes["ProductPackingLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductRecipeLocale?: GraphQLTypes["ProductRecipeLocale"] | undefined,
	listProductRecipeLocale: Array<GraphQLTypes["ProductRecipeLocale"]>,
	paginateProductRecipeLocale: GraphQLTypes["ProductRecipeLocaleConnection"],
	getInvoicingPreset?: GraphQLTypes["InvoicingPreset"] | undefined,
	listInvoicingPreset: Array<GraphQLTypes["InvoicingPreset"]>,
	paginateInvoicingPreset: GraphQLTypes["InvoicingPresetConnection"],
	getBillingSubject?: GraphQLTypes["BillingSubject"] | undefined,
	listBillingSubject: Array<GraphQLTypes["BillingSubject"]>,
	paginateBillingSubject: GraphQLTypes["BillingSubjectConnection"],
	validateCreateBillingSubject: GraphQLTypes["_ValidationResult"],
	validateUpdateBillingSubject: GraphQLTypes["_ValidationResult"],
	getProductCustomerPrice?: GraphQLTypes["ProductCustomerPrice"] | undefined,
	listProductCustomerPrice: Array<GraphQLTypes["ProductCustomerPrice"]>,
	paginateProductCustomerPrice: GraphQLTypes["ProductCustomerPriceConnection"],
	getCartPriceLine?: GraphQLTypes["CartPriceLine"] | undefined,
	listCartPriceLine: Array<GraphQLTypes["CartPriceLine"]>,
	paginateCartPriceLine: GraphQLTypes["CartPriceLineConnection"],
	getVirtualProduct?: GraphQLTypes["VirtualProduct"] | undefined,
	listVirtualProduct: Array<GraphQLTypes["VirtualProduct"]>,
	paginateVirtualProduct: GraphQLTypes["VirtualProductConnection"],
	getVirtualProductEffect?: GraphQLTypes["VirtualProductEffect"] | undefined,
	listVirtualProductEffect: Array<GraphQLTypes["VirtualProductEffect"]>,
	paginateVirtualProductEffect: GraphQLTypes["VirtualProductEffectConnection"],
	getOrderPriceLine?: GraphQLTypes["OrderPriceLine"] | undefined,
	listOrderPriceLine: Array<GraphQLTypes["OrderPriceLine"]>,
	paginateOrderPriceLine: GraphQLTypes["OrderPriceLineConnection"],
	getProductMetadata?: GraphQLTypes["ProductMetadata"] | undefined,
	listProductMetadata: Array<GraphQLTypes["ProductMetadata"]>,
	paginateProductMetadata: GraphQLTypes["ProductMetadataConnection"],
	getProductGridCategory?: GraphQLTypes["ProductGridCategory"] | undefined,
	listProductGridCategory: Array<GraphQLTypes["ProductGridCategory"]>,
	paginateProductGridCategory: GraphQLTypes["ProductGridCategoryConnection"],
	getAnonymousSession?: GraphQLTypes["AnonymousSession"] | undefined,
	listAnonymousSession: Array<GraphQLTypes["AnonymousSession"]>,
	paginateAnonymousSession: GraphQLTypes["AnonymousSessionConnection"],
	validateCreateAnonymousSession: GraphQLTypes["_ValidationResult"],
	validateUpdateAnonymousSession: GraphQLTypes["_ValidationResult"],
	getVoucher?: GraphQLTypes["Voucher"] | undefined,
	listVoucher: Array<GraphQLTypes["Voucher"]>,
	paginateVoucher: GraphQLTypes["VoucherConnection"],
	getVoucherRedemption?: GraphQLTypes["VoucherRedemption"] | undefined,
	listVoucherRedemption: Array<GraphQLTypes["VoucherRedemption"]>,
	paginateVoucherRedemption: GraphQLTypes["VoucherRedemptionConnection"],
	getFreshingContainer?: GraphQLTypes["FreshingContainer"] | undefined,
	listFreshingContainer: Array<GraphQLTypes["FreshingContainer"]>,
	paginateFreshingContainer: GraphQLTypes["FreshingContainerConnection"],
	getTextItem?: GraphQLTypes["TextItem"] | undefined,
	listTextItem: Array<GraphQLTypes["TextItem"]>,
	paginateTextItem: GraphQLTypes["TextItemConnection"],
	getTextList?: GraphQLTypes["TextList"] | undefined,
	listTextList: Array<GraphQLTypes["TextList"]>,
	paginateTextList: GraphQLTypes["TextListConnection"],
	getDeliveryRegions?: GraphQLTypes["DeliveryRegions"] | undefined,
	listDeliveryRegions: Array<GraphQLTypes["DeliveryRegions"]>,
	paginateDeliveryRegions: GraphQLTypes["DeliveryRegionsConnection"],
	getDeliveryRegionsItem?: GraphQLTypes["DeliveryRegionsItem"] | undefined,
	listDeliveryRegionsItem: Array<GraphQLTypes["DeliveryRegionsItem"]>,
	paginateDeliveryRegionsItem: GraphQLTypes["DeliveryRegionsItemConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getDeliveryMethodLocale?: GraphQLTypes["DeliveryMethodLocale"] | undefined,
	listDeliveryMethodLocale: Array<GraphQLTypes["DeliveryMethodLocale"]>,
	paginateDeliveryMethodLocale: GraphQLTypes["DeliveryMethodLocaleConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getWorkspace?: GraphQLTypes["Workspace"] | undefined,
	listWorkspace: Array<GraphQLTypes["Workspace"]>,
	paginateWorkspace: GraphQLTypes["WorkspaceConnection"],
	getCartSummary?: GraphQLTypes["CartSummary"] | undefined,
	listCartSummary: Array<GraphQLTypes["CartSummary"]>,
	paginateCartSummary: GraphQLTypes["CartSummaryConnection"],
	getOrderSummary?: GraphQLTypes["OrderSummary"] | undefined,
	listOrderSummary: Array<GraphQLTypes["OrderSummary"]>,
	paginateOrderSummary: GraphQLTypes["OrderSummaryConnection"],
	getOrderPaymentMetadata?: GraphQLTypes["OrderPaymentMetadata"] | undefined,
	listOrderPaymentMetadata: Array<GraphQLTypes["OrderPaymentMetadata"]>,
	paginateOrderPaymentMetadata: GraphQLTypes["OrderPaymentMetadataConnection"],
	getUserMetadata?: GraphQLTypes["UserMetadata"] | undefined,
	listUserMetadata: Array<GraphQLTypes["UserMetadata"]>,
	paginateUserMetadata: GraphQLTypes["UserMetadataConnection"],
	validateCreateIdentificationAttempt: GraphQLTypes["_ValidationResult"],
	getIdentificationCode?: GraphQLTypes["IdentificationCode"] | undefined,
	listIdentificationCode: Array<GraphQLTypes["IdentificationCode"]>,
	paginateIdentificationCode: GraphQLTypes["IdentificationCodeConnection"],
	getIdentificationCodeLocale?: GraphQLTypes["IdentificationCodeLocale"] | undefined,
	listIdentificationCodeLocale: Array<GraphQLTypes["IdentificationCodeLocale"]>,
	paginateIdentificationCodeLocale: GraphQLTypes["IdentificationCodeLocaleConnection"],
	getFulfillmentNote?: GraphQLTypes["FulfillmentNote"] | undefined,
	listFulfillmentNote: Array<GraphQLTypes["FulfillmentNote"]>,
	paginateFulfillmentNote: GraphQLTypes["FulfillmentNoteConnection"],
	getCustomerMetadata?: GraphQLTypes["CustomerMetadata"] | undefined,
	listCustomerMetadata: Array<GraphQLTypes["CustomerMetadata"]>,
	paginateCustomerMetadata: GraphQLTypes["CustomerMetadataConnection"],
	getSubscriptionBox?: GraphQLTypes["SubscriptionBox"] | undefined,
	listSubscriptionBox: Array<GraphQLTypes["SubscriptionBox"]>,
	paginateSubscriptionBox: GraphQLTypes["SubscriptionBoxConnection"],
	getSubscriptionBoxLocale?: GraphQLTypes["SubscriptionBoxLocale"] | undefined,
	listSubscriptionBoxLocale: Array<GraphQLTypes["SubscriptionBoxLocale"]>,
	paginateSubscriptionBoxLocale: GraphQLTypes["SubscriptionBoxLocaleConnection"],
	getGridTileItem?: GraphQLTypes["GridTileItem"] | undefined,
	listGridTileItem: Array<GraphQLTypes["GridTileItem"]>,
	paginateGridTileItem: GraphQLTypes["GridTileItemConnection"],
	getGridTiles?: GraphQLTypes["GridTiles"] | undefined,
	listGridTiles: Array<GraphQLTypes["GridTiles"]>,
	paginateGridTiles: GraphQLTypes["GridTilesConnection"],
	getPosition?: GraphQLTypes["Position"] | undefined,
	listPosition: Array<GraphQLTypes["Position"]>,
	paginatePosition: GraphQLTypes["PositionConnection"],
	getStatsWidget?: GraphQLTypes["StatsWidget"] | undefined,
	listStatsWidget: Array<GraphQLTypes["StatsWidget"]>,
	paginateStatsWidget: GraphQLTypes["StatsWidgetConnection"],
	getStatsWidgetsLayout?: GraphQLTypes["StatsWidgetsLayout"] | undefined,
	listStatsWidgetsLayout: Array<GraphQLTypes["StatsWidgetsLayout"]>,
	paginateStatsWidgetsLayout: GraphQLTypes["StatsWidgetsLayoutConnection"],
	getStatsPage?: GraphQLTypes["StatsPage"] | undefined,
	listStatsPage: Array<GraphQLTypes["StatsPage"]>,
	paginateStatsPage: GraphQLTypes["StatsPageConnection"]
};
	["Info"]: {
	__typename: "Info",
	description?: string | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	createAddress: GraphQLTypes["AddressCreateResult"],
	deleteAddress: GraphQLTypes["AddressDeleteResult"],
	updateAddress: GraphQLTypes["AddressUpdateResult"],
	upsertAddress: GraphQLTypes["AddressUpsertResult"],
	updateCustomer: GraphQLTypes["CustomerUpdateResult"],
	createOrder: GraphQLTypes["OrderCreateResult"],
	createOrderDelivery: GraphQLTypes["OrderDeliveryCreateResult"],
	createOrderItem: GraphQLTypes["OrderItemCreateResult"],
	createCustomerName: GraphQLTypes["CustomerNameCreateResult"],
	updateCustomerName: GraphQLTypes["CustomerNameUpdateResult"],
	upsertCustomerName: GraphQLTypes["CustomerNameUpsertResult"],
	createCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingCreateResult"],
	deleteCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingDeleteResult"],
	updateCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingUpdateResult"],
	upsertCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingUpsertResult"],
	createCart: GraphQLTypes["CartCreateResult"],
	createCartItem: GraphQLTypes["CartItemCreateResult"],
	deleteCartItem: GraphQLTypes["CartItemDeleteResult"],
	updateCartItem: GraphQLTypes["CartItemUpdateResult"],
	upsertCartItem: GraphQLTypes["CartItemUpsertResult"],
	createGps: GraphQLTypes["GpsCreateResult"],
	updateGps: GraphQLTypes["GpsUpdateResult"],
	upsertGps: GraphQLTypes["GpsUpsertResult"],
	createBillingSubject: GraphQLTypes["BillingSubjectCreateResult"],
	updateBillingSubject: GraphQLTypes["BillingSubjectUpdateResult"],
	upsertBillingSubject: GraphQLTypes["BillingSubjectUpsertResult"],
	createAnonymousSession: GraphQLTypes["AnonymousSessionCreateResult"],
	deleteAnonymousSession: GraphQLTypes["AnonymousSessionDeleteResult"],
	updateAnonymousSession: GraphQLTypes["AnonymousSessionUpdateResult"],
	upsertAnonymousSession: GraphQLTypes["AnonymousSessionUpsertResult"],
	createIdentificationAttempt: GraphQLTypes["IdentificationAttemptCreateResult"],
	transaction: GraphQLTypes["MutationTransaction"],
	query: GraphQLTypes["Query"]
};
	["AddressCreateResult"]: {
	__typename: "AddressCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Address"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationResult"]: {
	__typename:"AddressCreateResult" | "AddressDeleteResult" | "AddressUpdateResult" | "AddressUpsertResult" | "CustomerUpdateResult" | "OrderCreateResult" | "OrderDeliveryCreateResult" | "OrderItemCreateResult" | "CustomerNameCreateResult" | "CustomerNameUpdateResult" | "CustomerNameUpsertResult" | "CustomerIngredientRatingCreateResult" | "CustomerIngredientRatingDeleteResult" | "CustomerIngredientRatingUpdateResult" | "CustomerIngredientRatingUpsertResult" | "CartCreateResult" | "CartItemCreateResult" | "CartItemDeleteResult" | "CartItemUpdateResult" | "CartItemUpsertResult" | "GpsCreateResult" | "GpsUpdateResult" | "GpsUpsertResult" | "BillingSubjectCreateResult" | "BillingSubjectUpdateResult" | "BillingSubjectUpsertResult" | "AnonymousSessionCreateResult" | "AnonymousSessionDeleteResult" | "AnonymousSessionUpdateResult" | "AnonymousSessionUpsertResult" | "IdentificationAttemptCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>
	['...on AddressCreateResult']: '__union' & GraphQLTypes["AddressCreateResult"];
	['...on AddressDeleteResult']: '__union' & GraphQLTypes["AddressDeleteResult"];
	['...on AddressUpdateResult']: '__union' & GraphQLTypes["AddressUpdateResult"];
	['...on AddressUpsertResult']: '__union' & GraphQLTypes["AddressUpsertResult"];
	['...on CustomerUpdateResult']: '__union' & GraphQLTypes["CustomerUpdateResult"];
	['...on OrderCreateResult']: '__union' & GraphQLTypes["OrderCreateResult"];
	['...on OrderDeliveryCreateResult']: '__union' & GraphQLTypes["OrderDeliveryCreateResult"];
	['...on OrderItemCreateResult']: '__union' & GraphQLTypes["OrderItemCreateResult"];
	['...on CustomerNameCreateResult']: '__union' & GraphQLTypes["CustomerNameCreateResult"];
	['...on CustomerNameUpdateResult']: '__union' & GraphQLTypes["CustomerNameUpdateResult"];
	['...on CustomerNameUpsertResult']: '__union' & GraphQLTypes["CustomerNameUpsertResult"];
	['...on CustomerIngredientRatingCreateResult']: '__union' & GraphQLTypes["CustomerIngredientRatingCreateResult"];
	['...on CustomerIngredientRatingDeleteResult']: '__union' & GraphQLTypes["CustomerIngredientRatingDeleteResult"];
	['...on CustomerIngredientRatingUpdateResult']: '__union' & GraphQLTypes["CustomerIngredientRatingUpdateResult"];
	['...on CustomerIngredientRatingUpsertResult']: '__union' & GraphQLTypes["CustomerIngredientRatingUpsertResult"];
	['...on CartCreateResult']: '__union' & GraphQLTypes["CartCreateResult"];
	['...on CartItemCreateResult']: '__union' & GraphQLTypes["CartItemCreateResult"];
	['...on CartItemDeleteResult']: '__union' & GraphQLTypes["CartItemDeleteResult"];
	['...on CartItemUpdateResult']: '__union' & GraphQLTypes["CartItemUpdateResult"];
	['...on CartItemUpsertResult']: '__union' & GraphQLTypes["CartItemUpsertResult"];
	['...on GpsCreateResult']: '__union' & GraphQLTypes["GpsCreateResult"];
	['...on GpsUpdateResult']: '__union' & GraphQLTypes["GpsUpdateResult"];
	['...on GpsUpsertResult']: '__union' & GraphQLTypes["GpsUpsertResult"];
	['...on BillingSubjectCreateResult']: '__union' & GraphQLTypes["BillingSubjectCreateResult"];
	['...on BillingSubjectUpdateResult']: '__union' & GraphQLTypes["BillingSubjectUpdateResult"];
	['...on BillingSubjectUpsertResult']: '__union' & GraphQLTypes["BillingSubjectUpsertResult"];
	['...on AnonymousSessionCreateResult']: '__union' & GraphQLTypes["AnonymousSessionCreateResult"];
	['...on AnonymousSessionDeleteResult']: '__union' & GraphQLTypes["AnonymousSessionDeleteResult"];
	['...on AnonymousSessionUpdateResult']: '__union' & GraphQLTypes["AnonymousSessionUpdateResult"];
	['...on AnonymousSessionUpsertResult']: '__union' & GraphQLTypes["AnonymousSessionUpsertResult"];
	['...on IdentificationAttemptCreateResult']: '__union' & GraphQLTypes["IdentificationAttemptCreateResult"];
};
	["_MutationError"]: {
	__typename: "_MutationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	paths: Array<Array<GraphQLTypes["_PathFragment"]>>,
	type: GraphQLTypes["_MutationErrorType"],
	message?: string | undefined
};
	["_MutationErrorType"]: _MutationErrorType;
	["AddressDeleteResult"]: {
	__typename: "AddressDeleteResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Address"] | undefined
};
	["AddressUpdateResult"]: {
	__typename: "AddressUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Address"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["AddressUpsertResult"]: {
	__typename: "AddressUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Address"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerUpdateResult"]: {
	__typename: "CustomerUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Customer"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["OrderCreateResult"]: {
	__typename: "OrderCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Order"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["OrderDeliveryCreateResult"]: {
	__typename: "OrderDeliveryCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["OrderDelivery"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["OrderItemCreateResult"]: {
	__typename: "OrderItemCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["OrderItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerNameCreateResult"]: {
	__typename: "CustomerNameCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerName"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerNameUpdateResult"]: {
	__typename: "CustomerNameUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerName"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerNameUpsertResult"]: {
	__typename: "CustomerNameUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerName"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerIngredientRatingCreateResult"]: {
	__typename: "CustomerIngredientRatingCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerIngredientRatingDeleteResult"]: {
	__typename: "CustomerIngredientRatingDeleteResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerIngredientRating"] | undefined
};
	["CustomerIngredientRatingUpdateResult"]: {
	__typename: "CustomerIngredientRatingUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CustomerIngredientRatingUpsertResult"]: {
	__typename: "CustomerIngredientRatingUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CustomerIngredientRating"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CartCreateResult"]: {
	__typename: "CartCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Cart"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CartItemCreateResult"]: {
	__typename: "CartItemCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CartItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CartItemDeleteResult"]: {
	__typename: "CartItemDeleteResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CartItem"] | undefined
};
	["CartItemUpdateResult"]: {
	__typename: "CartItemUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CartItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["CartItemUpsertResult"]: {
	__typename: "CartItemUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["CartItem"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["GpsCreateResult"]: {
	__typename: "GpsCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Gps"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["GpsUpdateResult"]: {
	__typename: "GpsUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Gps"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["GpsUpsertResult"]: {
	__typename: "GpsUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["Gps"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["BillingSubjectCreateResult"]: {
	__typename: "BillingSubjectCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["BillingSubject"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["BillingSubjectUpdateResult"]: {
	__typename: "BillingSubjectUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["BillingSubject"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["BillingSubjectUpsertResult"]: {
	__typename: "BillingSubjectUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["BillingSubject"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["AnonymousSessionCreateResult"]: {
	__typename: "AnonymousSessionCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["AnonymousSession"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["AnonymousSessionDeleteResult"]: {
	__typename: "AnonymousSessionDeleteResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["AnonymousSession"] | undefined
};
	["AnonymousSessionUpdateResult"]: {
	__typename: "AnonymousSessionUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["AnonymousSession"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["AnonymousSessionUpsertResult"]: {
	__typename: "AnonymousSessionUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["AnonymousSession"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["IdentificationAttemptCreateResult"]: {
	__typename: "IdentificationAttemptCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationTransaction"]: {
	__typename: "MutationTransaction",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"],
	createAddress: GraphQLTypes["AddressCreateResult"],
	deleteAddress: GraphQLTypes["AddressDeleteResult"],
	updateAddress: GraphQLTypes["AddressUpdateResult"],
	upsertAddress: GraphQLTypes["AddressUpsertResult"],
	updateCustomer: GraphQLTypes["CustomerUpdateResult"],
	createOrder: GraphQLTypes["OrderCreateResult"],
	createOrderDelivery: GraphQLTypes["OrderDeliveryCreateResult"],
	createOrderItem: GraphQLTypes["OrderItemCreateResult"],
	createCustomerName: GraphQLTypes["CustomerNameCreateResult"],
	updateCustomerName: GraphQLTypes["CustomerNameUpdateResult"],
	upsertCustomerName: GraphQLTypes["CustomerNameUpsertResult"],
	createCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingCreateResult"],
	deleteCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingDeleteResult"],
	updateCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingUpdateResult"],
	upsertCustomerIngredientRating: GraphQLTypes["CustomerIngredientRatingUpsertResult"],
	createCart: GraphQLTypes["CartCreateResult"],
	createCartItem: GraphQLTypes["CartItemCreateResult"],
	deleteCartItem: GraphQLTypes["CartItemDeleteResult"],
	updateCartItem: GraphQLTypes["CartItemUpdateResult"],
	upsertCartItem: GraphQLTypes["CartItemUpsertResult"],
	createGps: GraphQLTypes["GpsCreateResult"],
	updateGps: GraphQLTypes["GpsUpdateResult"],
	upsertGps: GraphQLTypes["GpsUpsertResult"],
	createBillingSubject: GraphQLTypes["BillingSubjectCreateResult"],
	updateBillingSubject: GraphQLTypes["BillingSubjectUpdateResult"],
	upsertBillingSubject: GraphQLTypes["BillingSubjectUpsertResult"],
	createAnonymousSession: GraphQLTypes["AnonymousSessionCreateResult"],
	deleteAnonymousSession: GraphQLTypes["AnonymousSessionDeleteResult"],
	updateAnonymousSession: GraphQLTypes["AnonymousSessionUpdateResult"],
	upsertAnonymousSession: GraphQLTypes["AnonymousSessionUpsertResult"],
	createIdentificationAttempt: GraphQLTypes["IdentificationAttemptCreateResult"],
	query?: GraphQLTypes["Query"] | undefined
};
	["MutationTransactionOptions"]: {
		deferForeignKeyConstraints?: boolean | undefined
};
	["_Schema"]: {
	__typename: "_Schema",
	enums: Array<GraphQLTypes["_Enum"]>,
	entities: Array<GraphQLTypes["_Entity"]>
};
	["_Entity"]: {
	__typename: "_Entity",
	name: string,
	customPrimaryAllowed: boolean,
	fields: Array<GraphQLTypes["_Field"]>,
	unique: Array<GraphQLTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
	__typename: "_UniqueConstraint",
	fields: Array<string>
};
	["_Field"]: {
	__typename:"_Column" | "_Relation",
	name: string,
	type: string,
	nullable?: boolean | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
	['...on _Column']: '__union' & GraphQLTypes["_Column"];
	['...on _Relation']: '__union' & GraphQLTypes["_Relation"];
};
	["_Column"]: {
	__typename: "_Column",
	name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: GraphQLTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_OnDeleteBehaviour"]: _OnDeleteBehaviour;
	["_RelationSide"]: _RelationSide;
	["_OrderByDirection"]: _OrderByDirection;
	["_OrderBy"]: {
	__typename: "_OrderBy",
	path: Array<string>,
	direction: GraphQLTypes["_OrderByDirection"]
};
	["_Relation"]: {
	__typename: "_Relation",
	name: string,
	type: string,
	side: GraphQLTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: GraphQLTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<GraphQLTypes["_OrderBy"]> | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_Rule"]: {
	__typename: "_Rule",
	message?: GraphQLTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_Validator"]: {
	__typename: "_Validator",
	operation: string,
	arguments: Array<GraphQLTypes["_Argument"]>
};
	["_Argument"]:{
        	__typename:"_ValidatorArgument" | "_PathArgument" | "_LiteralArgument"
        	['...on _ValidatorArgument']: '__union' & GraphQLTypes["_ValidatorArgument"];
	['...on _PathArgument']: '__union' & GraphQLTypes["_PathArgument"];
	['...on _LiteralArgument']: '__union' & GraphQLTypes["_LiteralArgument"];
};
	["_ValidatorArgument"]: {
	__typename: "_ValidatorArgument",
	validator: number
};
	["_PathArgument"]: {
	__typename: "_PathArgument",
	path: Array<string>
};
	["_LiteralArgument"]: {
	__typename: "_LiteralArgument",
	value?: GraphQLTypes["Json"] | undefined
};
	["_RuleMessage"]: {
	__typename: "_RuleMessage",
	text?: string | undefined
};
	["_Enum"]: {
	__typename: "_Enum",
	name: string,
	values: Array<string>
}
    }
export type One = "One";

export type VirtualProductType = "Credits" | "Voucher";

export type VirtualProductPhysicalRepresentationType = "None" | "Optional" | "Required";

export type DeliveryZoneType = "Shop" | "Prague" | "CZ" | "Abroad" | "Virtual";

export type DeliveryTimelineType = "asap" | "day" | "halfAnHour";

export type PaymentMethodType = "credit" | "stripe" | "cash" | "invoice" | "import";

export type OrderState = "created" | "waitingForPayment" | "processing" | "dispatched" | "fulfilled" | "canceled";

export type OrderDiscountType = "Manual" | "Credits" | "Voucher";

export type VoucherType = "Credits";

export type OrderDeliveryState = "processing" | "picked" | "delivering" | "delivered" | "failed";

export type OrderPriceLineType = "discount" | "item" | "delivery";

export type OrderPaymentState = "created" | "approved" | "failed";

export type CartState = "created" | "confirmed" | "deleted";

export type CartPriceLineType = "item";

export type ChannelCode = "b2b" | "eshop" | "app" | "pos" | "web" | "all" | "legacy";

export type VatRatePreset = "zero" | "default";

export type MediumType = "image" | "video";

export type ColorTheme = "light" | "dark";

export type ContentReferenceType = "button" | "medium" | "products" | "productBanners" | "links" | "link" | "blogPosts" | "content" | "gallery" | "hero" | "hr" | "marquee" | "productGrid" | "relatedBlogPosts" | "socialsAndApps" | "titleWithImage" | "deliveryRegions" | "collapsibleTexts" | "gridTiles";

export type LinkType = "internal" | "external" | "download";

export type PageTheme = "goodlok" | "childish";

export type ContentSize = "small" | "medium" | "large";

export type ProductGridType = "default" | "carousel" | "smallGrid";

export type ProductBannerItemSize = "small" | "medium" | "large" | "fullWidth";

export type TranslationContentType = "textPlain" | "textHtml";

export type ProductAvailability = "stockDependent" | "available" | "unavailable";

export type CustomerFamiliarity = "cizi" | "pratelskeVykani" | "slusneTykani" | "tykani" | "sikana";

export type InvoicingPresetType = "AdHoc" | "WhenFulfilled" | "FirstDayOfMonth" | "IntervalOfDays";

export type OrderDirection = "asc" | "desc" | "ascNullsFirst" | "descNullsLast";

export type StatsEnabledWidgetTypeType = "revenue" | "numberOfCustomers" | "numberOfCustomersHourly" | "averageCustomerSpending" | "topProduct" | "dailyGratuity" | "salesByProductCategories" | "productSales" | "cashRegisterBalance" | "revenueByHours" | "revenueWeeklyComparison";

export type StatsWidgetTimeFrame = "day" | "week" | "month" | "year" | "allTime";

export type StatsPageType = "dashboard" | "sales" | "b2b" | "employees";

export type _MutationErrorType = "NotNullConstraintViolation" | "UniqueConstraintViolation" | "ForeignKeyConstraintViolation" | "NotFoundOrDenied" | "NonUniqueWhereInput" | "InvalidDataInput" | "SqlError";

export type _OnDeleteBehaviour = "restrict" | "cascade" | "setNull";

export type _RelationSide = "owning" | "inverse";

export type _OrderByDirection = "asc" | "desc";

export const createZeusVariables = useZeusVariables

export type FragmentResult<K extends keyof GraphQLTypes, Q extends unknown | ((...args: any[]) => unknown)> = InputType<
	GraphQLTypes[K],
	Q extends (...args: any[]) => unknown ? ReturnType<Q> : Q
>